import React, { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Image } from "react-bootstrap"
import { Row, Col, Modal, ModalFooter, ModalHeader, ModalBody, Button, Label, Alert, Spinner, InputGroup, InputGroupText, DropdownMenu, Dropdown } from "reactstrap"
import { AvForm, AvField, AvInput, AvFeedback, AvGroup } from "availity-reactstrap-validation"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { fromUnixTime, getUnixTime, addDays, format as dateFnsFormat, differenceInHours, isBefore, endOfDay } from 'date-fns'
import { Editor } from '@tinymce/tinymce-react';
import { TagsInput } from "react-tag-input-component";


// import components
import ImageUploader from  '../../../../components/Common/imageUploader'
import TicketTitleSuggestion from "./voucherTitleSuggestion";
import CreateTerms from './createTerms'
import OffSymbol from './offSymbol'
import OnSymbol from "./onSymbol"


// import images / files
import duplicateWarningIcon from '../../../../assets/images/duplicateWarning.png'
import thumbsup from '../../../../assets/images/thumbsup.png'
 

// import graphql functions
import  { getBrandInfo } from  '../../../../helpers/GraphQL/brands'
import {updateTags} from 'helpers/GraphQL/tags.js'

// import helpers
import { getConfig, sendEmail, getEnv, createEventTicket, updateEventTicket, getVoucherGroup, getVoucherGroupDeals, getEventTicket, invalidateCloudFrontCache } from 'helpers/apiHelper'

// import utils
import { getAssetUrl, guidGenerator, handleImageUpload, generatePromoCodes, convertToCSV, isValidHttpUrl, isValidURL } from '../../../../utils'
import { MANDATORY_TERMS, MANDATORY_TERMS_SUBTITLE_EVENT, ALPHANUMERIC_REGEX , NUMBERONLY_REGEX, URL_REGEX} from "utils/constants";


// import store actions
import { setConfig, setEnv, setAmplitudeEvent } from '../../../../store/actions'
import TicketPreview from "./ticketPreview";


const SellEventTicketsForm = (props) => {
    // console.log('SellEventTicketsForm ',props.sellEventTicket)
    const dispatch = useDispatch()
    const selectedMerchant = useSelector(state => state.Merchant.selectedMerchant)
    const cmsConfig = useSelector(state => state.Config)
    const user = useSelector(state => state.User.user)
    const editorRef = useRef(null);
    const [processing, setProcessing] = useState(false)
    const [transactionMessage, setTransactionMessage] = useState(null)
    const [selectedStoredIds, setSelectedStoreIds] = useState([])
    const [croppedImages, setCroppedImages] = useState({})
    const [sellEventTicket, setSellEventTicket] =useState({})
    const [recommendedEndDateMin, setRecommendedEndDateMin] = useState('2022-03-28')
    const [recommendedRedemptionEndDateMin, setRecommendedRedemptionEndDateMin] = useState('2022-03-28')
    const [sellingEndDateError, setSellingEndDateError] = useState(null)
    const [displayEndDateError, setDisplayEndDateError] = useState(null)
    const [redemptionEndDateError, setRedemptionEndDateError] = useState()
    const quantityInputRef = useRef(null);
    const [validUrl, setValidUrl] = useState(true)
    const [mandatoryTermsSubTitle, setMandatoryTermsSubtitle] = useState('')
    const [allTerms, setAllTerms] = useState([])
    const [additionalInfo, setAdditionalInfo] = useState([])
    // const [combineTerms, setCombineTerms] = useState('')
    const [disableEditing, setDisableEditing] = useState(false)

    //Jet added
    const [collectForAll, setCollectForAll] = useState(false)
    const [ticketCategoryList, setTicketCategoryList] = useState([])
    const [eventList, setEventList] = useState([])
    const [selectedEvent, setSelectedEvent] = useState("")
    const [selectedGroupName, setSelectedGroupName] = useState('')
    const [selectedGroupDeals, setSelectedGroupDeals] = useState({})
    const [tags, setTags] = useState([]);
    const [selectedEventTitle, setSelectedEventTitle] = useState("")
    const [additionalText, setAdditionalText] = useState("")

    useEffect(async () => {
        await getCMSConfig()
    }, [])

    useEffect(() => {
      const ignoreScroll = (e) => {
        e.preventDefault();
      };
      quantityInputRef.current && quantityInputRef.current.addEventListener("wheel", ignoreScroll);
    }, [quantityInputRef]);
    
    useEffect(async () => {

        const disabled = props.mode==='update' && (props.sellEventTicket.valid==='Y') && isBefore(fromUnixTime(props.sellEventTicket.selling_startTimestamp), new Date())
        setDisableEditing(disabled)
        setSellEventTicket({...props.sellEventTicket})
        setEventList(props.eventList)
        setSelectedStoreIds([props.outlet.store_id])
        setTags(props.sellEventTicket.TR_tags || [])
        handleTermsManualInput(props.sellEventTicket.promotion)
        setRecommendedEndDateMin(dateFnsFormat(addDays(new Date(), 1), "yyyy-MM-dd 00:00:00"))
        setRecommendedRedemptionEndDateMin(dateFnsFormat(addDays(new Date(), 1), "yyyy-MM-dd 23:59:59"))

        if (props.mode==='create') {
            setSelectedEvent(props.eventList[0].pk ?? "")            
            setMandatoryTermsSubtitle(MANDATORY_TERMS_SUBTITLE_EVENT)
        } else {
            try {
                let _voucherGroup = await getVoucherGroup(props.sellEventTicket.pk, "deal_id")
                const voucherGroupDeals = await getVoucherGroupDeals(props.sellEventTicket.pk, "deal_id")
                const selectedVoucherGroupDeals = voucherGroupDeals.data.filter(item => item.voucherGroupId === _voucherGroup.data.id)
                setSelectedGroupDeals(selectedVoucherGroupDeals[0])
                setSelectedGroupName(_voucherGroup.data.id)
                setMandatoryTermsSubtitle(MANDATORY_TERMS_SUBTITLE_EVENT)
                setSelectedEvent(_voucherGroup.data.eventId)
            } catch(e) {
                console.error('error on getVoucherMasterByDeal',e)
                setTransactionMessage({msg: 'Please report to the Administrator.[Voucher retrieval issue]', type: 'danger'})
                dispatch(setAmplitudeEvent('CMS Sell Event Tickets page - Error Voucher retrieval issue', {'details': JSON.stringify(e)}))
            }
        }
    }, [props.sellEventTicket])

    useEffect(async()=>{
        if(selectedEvent){
            try{
                let list = await getVoucherGroup(selectedEvent)
                if(list.statusCode === '200' && list.data) {
                    setTicketCategoryList(list.data)
                    if(!selectedGroupName){
                        setSelectedGroupName(list.data.length > 0 ? list.data[0].id : '')
                    }else{
                        let checkIfNewEventSelected = list.data.filter(ticketCategory => ticketCategory.id === selectedGroupName)
                        checkIfNewEventSelected.length < 1 ? setSelectedGroupName(list.data.length > 0 ? list.data[0].id : '') : null
                    }
                    setSelectedEventTitle(eventList.filter(event => event.pk === selectedEvent)[0].uniqueString || "")
                }
            }catch(error){
                console.error('error on getVoucherGroup',error)
            }
        }
    }, [selectedEvent])
    
    // get all the list of voucher terms for a given type(shopfront/online)
    // useEffect(async () => {
    //     if (Object.keys(sellEventTicket).length === 0) return
        
    //     try {
    //         const additionalTerms = await initVoucherTerms(sellEventTicket.additionalPromotionInfo || [])
    //         const terms = await getTermsByType('events')
    //         const temp = terms.map(item => {
    //             let ON = false
    //             let termsText = item.termsText
    //             if (additionalTerms[item.id]) {
    //                 ON = true
    //                 termsText = additionalTerms[item.id].termsText
    //             }
    //             return {...item, ON, termsText, totalChars: item.order === '1' ? termsText.length : `${termsText.length}/250`}
    //         })
    //         setAllTerms(terms)
    //         setAdditionalInfo(temp) // we will use this as session of the form manipulation on the terms switch items
    //     } catch(e) {
    //         console.error('error getting voucher list', e)
    //         setTransactionMessage({msg: e, type: 'danger'})
    //         dispatch(setAmplitudeEvent('CMS Sell Event Tickets page - error initializing event ticket form', {'details': JSON.stringify(e)}))
    //     }
    // }, [sellEventTicket.is_online])

    // function initVoucherTerms(terms) {
    //     const temp = {}
    //     terms.forEach(item => {
    //         temp[item.id] = {...item}
    //     })
    //     return temp
    // }

    async function getCMSConfig() {
        try {
            
            if (!cmsConfig || !cmsConfig.cmsv2) {
                // console.log('no config so retrieving from source')
                const config = await getConfig()

                const env = getEnv()
                
                dispatch(setConfig(config))
                dispatch(setEnv(env))
            }
        } catch(error) {
            console.error('error on retrieving config file', error)
            dispatch(setAmplitudeEvent('CMS Sell Event Tickets page - Sell Event ticket page getCMSConfig function error', {'details': JSON.stringify(error)}))
        }
    }


    function handleChange(e, fieldName) {
        setTransactionMessage(null)

        let item = { ...sellEventTicket }
        if (e.target.type === 'checkbox') {
            if (fieldName === 'valid') {
                item[fieldName] = e.target.checked ? 'Y' : 'N'
            } else {
                item[fieldName] = e.target.checked ? 'True' : 'False';
            }
        } else {
            let value = e.target.value

            if(fieldName==='promotion_caption'){
                item[fieldName] = value
                item['uniqueString'] = value.replaceAll(" ", "-").replaceAll(/[^a-zA-Z0-9-]/gi, '')
            } else if(fieldName==='uniqueString'){
                item[fieldName] = value
            } else {
                item[fieldName] = value
            }

            if (['actualPrice', 'purchasePrice', 'discountPrice'].includes(fieldName)) {
                item = autoComputeSellFields(item, fieldName)
            }

        }
       
        setTransactionMessage(null)
        setSellEventTicket(item)
    }

    function autoComputeSellFields(item, fieldName) {
        try {
            if (fieldName === 'actualPrice') {
                item['purchasePrice'] = (item['actualPrice'] - item['discountPrice']).toFixed(2)
                item['discountPrice'] = (item['actualPrice'] - item['purchasePrice']).toFixed(2)
            } else if (fieldName === 'purchasePrice') {
                item['discountPrice'] = (item['actualPrice'] - item['purchasePrice']).toFixed(2)
            } else if (fieldName === 'discountPrice') {
                item['purchasePrice'] = (item['actualPrice'] - item['discountPrice']).toFixed(2)
            }
            item['discount'] = ((item['actualPrice'] - item['purchasePrice']) / item['actualPrice']).toFixed(2) 
        } catch(e) {
            // ignore
        } finally {
            // eslint-disable-next-line no-unsafe-finally
            return item
        }
            
    }


    // function filterTurnOnTerms (terms) {
    //     let filteredTerms = []
    //     let bulletedTerms =''
    //     let promotionDetails = ''
    //     const newLine = '\n'

    //     Object.keys(terms).forEach((key) => {
    //         let item = terms[key]
    //         if (item.ON) {
    //             filteredTerms.push( {
    //                 id: item.id,
    //                 termsText: item.termsText,
    //                 iconUrl: item.iconUrl
    //             } )
    //             promotionDetails += `${item.termsText} `
    //             bulletedTerms += (bulletedTerms?newLine:'') + '● ' + item.termsText
    //         }
    //     })
        
    //     return { filteredTerms, bulletedTerms, promotionDetails }
    // }

    async function handleSubmit(e) {
        e.preventDefault()
        e.stopPropagation()
        setProcessing(true)
        console.log('submit click')
        // get the updated merchant info from DB in case webhook is updating stripe and other info and the user has not logout yet causing the store to be an old-data
        const brand = await getBrandInfo(selectedMerchant.merchant_id)
        if (!brand.stripeExpressConnectedAccountId) {
            setTransactionMessage({ msg: `Cannot ${props.mode} voucher. Please report to the Admin to resolve your account`, type: 'danger'})
            setProcessing(false)
            return
        }


        try{
            const checkEventTicketUniqueString = await getEventTicket({uniqueString:sellEventTicket.uniqueString})
            if ((checkEventTicketUniqueString?.error !== 'ticket_not_found') && (checkEventTicketUniqueString?.data?.ticket?.pk !== sellEventTicket.pk)){
                setTransactionMessage({ msg: "Event Ticket URL already exist, please manually update the URL parameter.", type: 'danger' })
                setProcessing(false)
                return
            }
        }catch(error){
            setProcessing(false)
        }


        const isPast = isBefore(fromUnixTime(sellEventTicket.start_timestamp), new Date()) && isBefore(fromUnixTime(sellEventTicket.selling_startTimestamp), new Date())
        const redemptionHrsDiff = differenceInHours(fromUnixTime(sellEventTicket.redemption_endTimestamp), fromUnixTime(sellEventTicket.selling_endTimestamp))
        const displayHrsDiff = differenceInHours(fromUnixTime(sellEventTicket.end_timestamp), fromUnixTime(sellEventTicket.start_timestamp))
        if (    (!sellEventTicket.image_url && !croppedImages.dealImage) || 
                (   !sellEventTicket.start_timestamp || !sellEventTicket.end_timestamp || 
                    !sellEventTicket.selling_startTimestamp || !sellEventTicket.selling_endTimestamp || 
                    !sellEventTicket.redemption_startTimestamp || !sellEventTicket.redemption_endTimestamp)) {
                let missingField = (!sellEventTicket.image_url && !croppedImages.dealImage) ? "Image" : ""
                missingField += (!sellEventTicket.start_timestamp) ? missingField ? ", Display Start Time" : "Display Start Time" : ""
                missingField += (!sellEventTicket.end_timestamp) ? missingField ? ", Display End Time" : "Display End Time" : ""
                missingField += (!sellEventTicket.selling_startTimestamp) ? missingField ? ", Selling Start Time" : "Selling Start Time" : ""
                missingField += (!sellEventTicket.selling_endTimestamp) ? missingField ? ", Selling End Time" : "Selling End Time" : ""
                missingField += (!sellEventTicket.redemption_startTimestamp) ? missingField ? ", Redemption Start Time" : "Redemption Start Time" : ""
                missingField += (!sellEventTicket.redemption_endTimestamp) ? missingField ? ", Redemption End Time" : "Redemption End Time" : ""
                setTransactionMessage({ msg: `Missing required fields (${missingField}). Please check fields mark with *`, type: 'danger' })
                setProcessing(false)
            return
        } else if (redemptionHrsDiff < 24) {
            setTransactionMessage({ msg: "Redemption End Date & Time should be at least 24hrs from Selling End Date ", type: 'danger' })
            setProcessing(false)
            return
        } else if (selectedStoredIds.length === 0) {
            setTransactionMessage({ msg: "Please select an outlet for this voucher", type: 'danger' })
            setProcessing(false)
            return
        } else if (displayHrsDiff < 24) {
            setTransactionMessage({ msg: "Display End Date & Time should be at least 24hrs from Display Start Date", type: 'danger' })
            setProcessing(false)
            return
        }else if (isPast && props.mode==='create') {
            setTransactionMessage({ msg: "Display/Selling Start Date & Time should be future date/time", type: 'danger' })
            setProcessing(false)
            setProcessing(false)
            return
        }else if (!selectedGroupName) {
            setTransactionMessage({ msg: "Please select an event category for the ticket", type: 'danger' })
            setProcessing(false)
            return
        }

        // massage the data to be saved
        let _sellEventTicket = {...sellEventTicket, promotion: e.target?.elements?.additionalTerms?.value || "", terms: e.target?.elements?.mandatoryTerms?.value}

        //Create VoucherGroupDeal
        let _voucherGroupDeal = {   id: selectedGroupDeals.id || guidGenerator() ,
                                    voucherGroupId : selectedGroupName,
                                    valid: "1",
                                    dealId: _sellEventTicket.pk}
        setTransactionMessage({msg: "Processing...", type:'info'})
        
        try {
            if (props.mode === 'create') {
                console.log('create mode')
                await handleCreate(_sellEventTicket, _voucherGroupDeal)
            } else if (props.mode === 'update') {
                await updateTags(_sellEventTicket.TR_tags || [], tags, _sellEventTicket)
                await handleUpdate(_sellEventTicket, _voucherGroupDeal)
            }
            close('forceReload')
        } catch (error) {
            console.error('error on handleSubmit ', error)
            setTransactionMessage({msg: 'Please report this error to Administrator.', type: 'danger'})
            setProcessing(false)
        }
    }

    // async function sendEmailToFirstVoucherOfBrand(sellEventTicket) {
    //     try {
    //         // we send when this is the first time when a sell voucher is created in a brand level
    //         if (props.isNoSellVoucher && cmsConfig.cmsv2 && cmsConfig.cmsv2.emailReceiver) {
    //             const body = `New voucher has been added on ${sellEventTicket.createdAt}
    //                             Company name: ${user.corpName}
    //                             Brand name: ${sellEventTicket.merchant_name}
    //                             Business rep: ${user.firstName} ${user.lastName}
    //                             Deal title: ${sellEventTicket.promotion_caption}`
                
    //             await sendEmail({
    //                 to: [...cmsConfig.cmsv2.emailReceiver],
    //                 content: body,
    //                 subject: `First voucher added by ${sellEventTicket.merchant_name}`
    //             })
    //         } else {
    //             console.error('No email sent. Either not first time voucher created or config was not retrieve')
    //         }
    //     } catch (e) {
    //         console.error('error on sendEmail', e)
    //         dispatch(setAmplitudeEvent('CMS Sell Event Tickets page - Sell Event ticket page sendEmailToFirstVoucherOfBrand function error', {'details': JSON.stringify(e) }))
    //     }
    // }

    async function handleCreate(_sellEventTicket, _voucherGroupDeal) {
        console.log('handleCreate ', _sellEventTicket, _voucherGroupDeal)
        // eslint-disable-next-line no-useless-catch
        try {
            let imagePath
            // massage the prices to correct decimal
            _sellEventTicket.actualPrice = Number(_sellEventTicket.actualPrice).toFixed(2)
            _sellEventTicket.purchasePrice = Number(_sellEventTicket.purchasePrice).toFixed(2)
            _sellEventTicket.discountPrice = Number(_sellEventTicket.discountPrice).toFixed(2)
            
            _sellEventTicket.merchant_unique_id = props.outlet.merchant_unique_id
            _sellEventTicket.store_id = props.outlet.store_id

            if (croppedImages.dealImage) {
                imagePath = `DealImages/${_sellEventTicket.merchant_unique_id}/${_sellEventTicket.pk}${croppedImages.dealImage.ext}`
                _sellEventTicket.image_url = await getAssetUrl(imagePath)
            }

            // cast int to str for sellEventTicket
            _sellEventTicket.start_timestamp = _sellEventTicket.start_timestamp.toString()
            _sellEventTicket.end_timestamp = _sellEventTicket.end_timestamp.toString()
            _sellEventTicket.redemption_endTimestamp = _sellEventTicket.redemption_endTimestamp.toString()
            _sellEventTicket.redemption_startTimestamp = _sellEventTicket.redemption_startTimestamp.toString()
            _sellEventTicket.selling_endTimestamp = _sellEventTicket.selling_endTimestamp.toString()
            _sellEventTicket.selling_startTimestamp = _sellEventTicket.selling_startTimestamp.toString()
            _sellEventTicket.purchaseLimitPerCustomer = _sellEventTicket.purchaseLimitPerCustomer.toString()
            
            const createdSellEventTicket= await createSellEventTicket(_sellEventTicket, _voucherGroupDeal)

            if (imagePath) await handleImageUpload(imagePath, croppedImages.dealImage)
            
            return createdSellEventTicket
        } catch (e) {
            dispatch(setAmplitudeEvent('CMS Sell Event Tickets page - Sell Event ticket page handleCreate function error', {'details': JSON.stringify(e) }))
            throw e
        }
    }

    async function handleUpdate(_sellEventTicket, _voucherGroupDeal) {
        try {
            // const _sellEventTicket = { ...sellEventTicket, terms: combineTerms }
            
            const invalidation = []

            let imagePath, imageAssetPath
 
            if (croppedImages.dealImage) {
                imagePath = `DealImages/${_sellEventTicket.merchant_unique_id}/${_sellEventTicket.pk}${croppedImages.dealImage.ext}`
                imageAssetPath = await getAssetUrl(imagePath)
                _sellEventTicket.image_url = imageAssetPath
                invalidation.push('image_url')
            }
            _sellEventTicket.showInOutletIds = selectedStoredIds
            _sellEventTicket.TR_tags = tags
            
            // delete custom fields before updating
            delete _sellEventTicket.store_ids
            delete _sellEventTicket.valid_store_ids
            delete _sellEventTicket.deal_ids
            delete _sellEventTicket.outlet_name
            delete _sellEventTicket.tags
            delete _sellEventTicket.approval
            
            console.log('for updating ', _sellEventTicket, _voucherGroupDeal)
            const updatedSellEventTicket = await updateEventTicket({ticket:_sellEventTicket, voucherGroupDeals:_voucherGroupDeal})

            if (imagePath){
                await handleImageUpload(imagePath, croppedImages.dealImage)
                await invalidateCloudFrontCache(imageAssetPath)
            }
            

            return updatedSellEventTicket
        } catch (error) {
            console.error('error handleUpdate ', error)
            dispatch(setAmplitudeEvent('CMS Sell Event Tickets page - Sell Event ticket page handleUpdate function error', {'details': JSON.stringify(error) }))
            throw error
        }
    }

    async function createSellEventTicket(_sellEventTicket, _voucherGroupDeal) {
        try{
            let createdEventTicket = await createEventTicket({ticket:_sellEventTicket, voucherGroupDeals:_voucherGroupDeal, eventId:selectedEvent})
            if(createdEventTicket.statusCode ==='200' && tags.length > 0){
                await updateTags([], tags, createdEventTicket.data.ticket)
            }
            return createdEventTicket;
        } catch(error){
            console.error('error createDeal ', error)
            dispatch(setAmplitudeEvent('CMS Create Sell Event Ticket page - sell event ticket form createEventTicket function error', {'details': JSON.stringify(error) }))
            throw error
        }
    }

    function close(action) {
        // do clean up since this is a modal and it might show old message when open again
        setProcessing(false)
        setTransactionMessage(null)
        
        if (action==='forceReload')
            props.close(sellEventTicket, false, 'SellEventTicketsForm')
        else 
            props.close(null, false)
    }

    async function getCroppedImage(cropped, fieldName) {
        let _croppedImages = croppedImages ?? {}
        _croppedImages[fieldName] = cropped

        setCroppedImages({ ..._croppedImages })
    }


    async function setPurchaseLimitPerCustomer(value) {
        const newValue = parseInt(sellEventTicket.purchaseLimitPerCustomer) + value
        if (isNaN(newValue)) return
        if (newValue < 1) return 
        if (newValue > 100) return

        sellEventTicket.purchaseLimitPerCustomer = newValue + ""
        setSellEventTicket({...sellEventTicket})
    }

    async function setMinQtyTx(value) {
        const newValue = parseInt(sellEventTicket.minQtyTx) + value
        if (isNaN(newValue)) return
        if (newValue < 1) return 
        if (newValue > 100) return

        sellEventTicket.minQtyTx = newValue + ""
        setSellEventTicket({...sellEventTicket})
    }


    async function validatePriceValue(value, ctx, input, cb) {
        if (!value) {
            cb('Field is required')
        } else if (value.startsWith('0') && !value.charAt(1) && value.charAt(1)!=='.'){
            // 02 should be treated as invalid; 0.00 and 0 is OK
            cb('Invalid entry')
        } else if (value.startsWith('-')) {
            cb('Negative value is not allowed')
        } else {
            cb(true)
        }
    }

    function handleTermsManualInput (term){
        let additionalTerms = !term ? [""] : term.split("\n")
        additionalTerms = additionalTerms.map(term => {return term ? "● "+term+"\n" : ""} )
        setAdditionalText(additionalTerms.join(""))
    }

    // trigger when the user toggle the switch of each term item
    const switchTermsState = (item, index) => {
        additionalInfo[index] = item
        setAdditionalInfo([...additionalInfo])
    }

    // trigger when the user change the value for the term input boxes
    const handleInputChange = (e, item, index) => {
        const { value } = e.target;
        const _item = {...item}

        _item.termsText = value
        _item.totalChars = item.order === '1'? value.length : `${value.length}/250`
        additionalInfo[index] = _item
        setAdditionalInfo([...additionalInfo])
    }

    return (
        <Modal
            size="xl"
            isOpen={props.modalSellEventTicketsForm}
            toggle={() => {
                close(null,false)
            }}
            centered={true}
        >   
            <AvForm className="needs-validation" onValidSubmit={(e, v) => handleSubmit(e, v)}>
                <ModalHeader toggle={() => close(null, false)}>
                    {props.mode === 'create' ? 'Sell event ticket' : `Edit event ticket`}
                </ModalHeader>

                <ModalBody>
                    <Row>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Label className="groupTitle">Event Ticket Details</Label>   
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <div className="form-check">
                                        <input
                                            disabled={false}
                                            className="form-check-input"
                                            type="checkbox"
                                            name="visible"
                                            checked={sellEventTicket.valid === 'Y'}
                                            onChange={(e) => handleChange(e, 'valid')}
                                        />
                                        <label className="form-check-label" htmlFor="visible">
                                            <p>Visible</p>
                                        </label>
                                    </div>
                                </Col>
                            </Row>
                            <Label className="subTitle">Ticket Image* <span className="subTitleLightPrimary">Landscape orientation recommended.</span></Label>
                            <p>
                                <span>Image will be scaled and cropped to display 375 by 266 pixels.</span><br/>
                                <span>(You may upload larger images and use the cropping tool.)</span>
                            </p>
                            <ImageUploader
                                width={437}
                                height={310}
                                required={true}
                                label="Deal Image"
                                onOk={(croppedImage) => getCroppedImage(croppedImage, 'dealImage')}
                                dropType={true}
                                //disableEditing={disableEditing}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={5} xl={5}>
                            <Label className="groupTitle mt-3">Ticket Image Preview</Label>
                            <div>
                                {(croppedImages.dealImage && croppedImages.dealImage.url) || sellEventTicket.image_url ? 
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                            <TicketPreview type="app" deal={sellEventTicket} imgSrc={croppedImages.dealImage ? croppedImages.dealImage.url : `${sellEventTicket.image_url}?timestamp=${Date.now()}`} />
                                        </Col>
                                        <Col>
                                            <TicketPreview type="ticket" deal={sellEventTicket} imgSrc={croppedImages.dealImage ? croppedImages.dealImage.url : `${sellEventTicket.image_url}?timestamp=${Date.now()}`} />
                                        </Col>
                                    {/* <Image fluid src={croppedImages.dealImage ? croppedImages.dealImage.url : `${sellEventTicket.image_url}?timestamp=${Date.now()}`} /> */}
                                    
                                    </Row>
                                    : null}
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="mt-3" xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Label htmlFor="promotion_details" className="subTitle m-0">Ticket Title*</Label>
                            <p className="subTitleLight m-0">Indicate what you are selling e.g. type of food/beverage, type of services (time limit, pax per voucher). Here are some examples for you to make your deal more attractive.</p>
                            <div className='my-1'>
                                <TicketTitleSuggestion onClick={(e)=> sellEventTicket.promotion_caption = e}/>
                            </div>
                            <AvField
                                name="voucherTitle"
                                placeholder=""
                                type="text"
                                errorMessage="This field is required"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="voucherTitle"
                                value={sellEventTicket.promotion_caption}
                                onChange={(e) => handleChange(e, 'promotion_caption')}
                                disabled={disableEditing}
                            />

                            <Row className='mt-3'>
                                <Label htmlFor="eventUniqueString" className="subTitle">Event Ticket URL*</Label>
                                <Label className="subTitleLight">This link is generated based on the event ticket title. Replace the text below if you wish to change the URL.</Label>
                                <Col xs={12}>
                                <AvGroup>
                                    <InputGroup>
                                    <InputGroupText className="mb-3 url-label" style={{maxWidth:"100%", textOverflow: 'ellipsis', overflow: 'hidden'}}>{`https://biz.${cmsConfig.env}.cardspal.com/event/${selectedEventTitle || "{event title}"}/item/`}</InputGroupText>
                                        <AvInput
                                            name="uniqueString" 
                                            placeholder="{your-event-ticket-title}" 
                                            value={props.mode==='update' ? sellEventTicket.uniqueString  : sellEventTicket.promotion_caption ? sellEventTicket.promotion_caption .replaceAll(" ", "-").replaceAll(/[^a-zA-Z0-9-]/gi, '') : ""} 
                                            required
                                            id="uniqueString"
                                            className={"mb-3"}
                                            style={{borderTopRightRadius : "0.25em", borderBottomRightRadius: "0.25em"}}
                                            onKeyDown={(e)=>{
                                                if (!ALPHANUMERIC_REGEX.test(e.key)) {
                                                    e.preventDefault();
                                                    return false;
                                                }
                                            }}
                                            onChange={(e) => handleChange(e, 'uniqueString')} />
                                            <AvFeedback style={{textAlign:"right", top:"30px"}}>This field is required</AvFeedback>
                                    </InputGroup>
                                </AvGroup>
                                </Col>


                            </Row>

                            <Row className='mt-3'>
                                    <Label htmlFor="promotionTitle" className="subTitle">Ticket Information*</Label>
                                    <Col xs={12}>
                                    <Editor
                                        tinymceScriptSrc="https://cdn.tiny.cloud/1/no-api-key/tinymce/5/tinymce.min.js" 
                                        onInit={(evt, editor) => editorRef.current = editor}
                                        initialValue= {props.sellEventTicket.ticketInfo || "<p>Type here for ticket information.</p>"}
                                        init={{
                                            height: 500,
                                            menubar: false,
                                            branding: false,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],
                                            toolbar: 'undo redo | formatselect | ' +
                                            'bold italic backcolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                            statusbar: false,
                                            paste_data_images: false,
                                            paste_block_drop: true,
                                            autohide:true,
                                            toolbar_mode: 'sliding',
                                            smart_paste: false,
                                            paste_as_text: true,
                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                        }}
                                        onEditorChange = {(e)=> sellEventTicket.ticketInfo = e}
                                        required
                                    />
                                    </Col>
                            </Row>

                            <Row className='mt-3'>
                                <Col>
                                    <Label className="subTitle">Tag to which event*</Label>
                                    <AvField type="select" name="eventTag" value={selectedEvent || ""}  onChange={(e)=>{setSelectedEvent(e.target.value)}} required disabled={disableEditing}>

                                    {eventList.map(event =>
                                        <option key={event.pk} value={event.pk} >{event.name}</option>)};

                                    </AvField>
                                </Col>
                                <Col>
                                    <Label className="subTitle">Group Name*</Label>
                                    <AvField type="select" name="categoryTag" value={selectedGroupName || ""} onChange={(e)=>setSelectedGroupName(e.target.value)} disabled={disableEditing}>
                                    {ticketCategoryList.map(category =>
                                        <option key={category.id} value={category.id}>{category.categoryName}</option>)};
                                    </AvField>
                                </Col>
                            </Row>
                        
                            {/* <Label htmlFor="promotion_details" className="subTitle mt-3">Voucher Additional Details / T&Cs *</Label>
                            <Label>Select minimum 1 and update accordingly. You may add you own description or T&Cs by selecting the ‘free text’ option. Highly recommend those clauses with purple thumbsup icon. <Image fluid src={thumbsup} /></Label>
                
                            <CreateTerms handleInputChange={handleInputChange} additionalInfo={additionalInfo} allTerms={allTerms} switchTermsState={switchTermsState} /> */}
                        
                            <Label htmlFor="terms" className="subTitle mt-3">Terms and Conditions*</Label>
                            <Label htmlFor="terms" className="subTitleLight">{mandatoryTermsSubTitle}</Label>
                            <AvField
                                name="additionalTerms"
                                placeholder=""
                                type="textarea"
                                rows={8}
                                value={sellEventTicket.promotion || ""}
                                errorMessage="This field is required"
                                className="form-control"
                                id="additionalTerms"
                                onChange= {(e)=>{handleTermsManualInput(e.target.value)}}
                            />
                            <AvField
                                name="mandatoryTerms"
                                placeholder=""
                                type="textarea"
                                rows={8}
                                errorMessage="This field is required"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="mandatoryTerms"
                                value={additionalText + MANDATORY_TERMS}
                                disabled
                            />


                            {
                            //TODO phase 2 addtion
                            /* <Row className="mt-3">
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                        <Label htmlFor="terms" className="subTitle">Registration details required</Label>
                                        <Label htmlFor="terms" className="subTitleLight">Select any details required. Once selected, the field will be mandatory for input.</Label>
                                        
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                <div className="form-check">
                                                    <input
                                                        disabled={disableEditing}
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="firstName"
                                                        //onChange={(e) => handleChange(e, 'is_online')}
                                                    />
                                                    <label className="form-check-label" htmlFor="firstName">
                                                        <p>First Name</p>
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        disabled={disableEditing}
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="lastName"
                                                        //onChange={(e) => handleChange(e, 'is_online')}
                                                    />
                                                    <label className="form-check-label" htmlFor="lastName">
                                                        <p>Last Name</p>
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        disabled={disableEditing}
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="ccEmail"
                                                        //onChange={(e) => handleChange(e, 'is_online')}
                                                    />
                                                    <label className="form-check-label" htmlFor="ccEmail">
                                                        <p>CC to another email</p>
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        disabled={disableEditing}
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="others"
                                                        //onChange={(e) => handleChange(e, 'is_online')}
                                                    />
                                                    <label className="form-check-label" htmlFor="others">
                                                        <p>Others</p>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="form-check">
                                                    <input
                                                        disabled={disableEditing}
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="phoneNumber"
                                                        //onChange={(e) => handleChange(e, 'is_online')}
                                                    />
                                                    <label className="form-check-label" htmlFor="phoneNumber">
                                                        <p>Phone Number</p>
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        disabled={disableEditing}
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="email"
                                                        //onChange={(e) => handleChange(e, 'is_online')}
                                                    />
                                                    <label className="form-check-label" htmlFor="email">
                                                        <p>Email</p>
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                
                                            </Col>
                                        </Row>
                                    
                                    </Col>
                                    <Col>
                                        <Row>
                                                <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                                <Label className="subTitle">Collect for all participants</Label>
                                                </Col>
                                                <Col>
                                                <Switch
                                                    uncheckedIcon={<OffSymbol />}
                                                    checkedIcon={<OnSymbol />}
                                                    onColor="#02a499"
                                                    onChange={() => {
                                                        setCollectForAll(()=>{return !collectForAll})
                                                    }}
                                                    checked={collectForAll}
                                                />
                                                </Col>
                                        </Row>
                                    </Col>

                                </Row>
                                

                            </Row> */}

                            <Row>
                                <Col>
                                <Label htmlFor="website" className="subTitle mt-3">Ticketing event website</Label>
                                    <AvField
                                        name="website"
                                        placeholder=""
                                        type="text"
                                        className="form-control"
                                        id="website"
                                        value={sellEventTicket.website}
                                        validate={{ pattern: { value: URL_REGEX, errorMessage: 'Not a proper URL'}}}
                                        onChange={(e) => handleChange(e, 'website')}
                                        errorMessage={validUrl.message}
                                        />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                <Label htmlFor="tags" className="subTitle mt-3">Tags <TicketTitleSuggestion type="tags" onClick={(e)=> {setTags([...tags, e])}}/></Label>
                                          <TagsInput
                                            value={tags || []}
                                            onChange={setTags}
                                            name="tags"
                                            placeHolder="enter tags"
                                        />         
                                </Col>
                            </Row>
{/*                         //TODO phase 2
                            <Row>
                                <Col>
                                <Label htmlFor="terms" className="subTitle mt-3">Keywords for search</Label>
                                    <AvField
                                        name="website"
                                        placeholder=""
                                        type="text"
                                        className="form-control mb-3"
                                        id="website"
                                        value={sellEventTicket.keywords || ''}
                                        onChange={(e) => handleChange(e, 'keywords')}
                                    />               
                                </Col>
                            </Row>                            */}

                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={6} className="d-flex flex-column justify-content-between mt-3">
                                    <Label className="subTitle">Display Start Date & Time*</Label>
                                    <InputGroup>
                                        <Flatpickr
                                            disabled={disableEditing}
                                            className={disableEditing? "form-control d-block greyBg" :"form-control d-block whiteBg"}
                                            placeholder="Select a date"
                                            value={sellEventTicket.start_timestamp ? fromUnixTime(sellEventTicket.start_timestamp) : ""}
                                            options={{
                                                disableMobile: true,
                                                minDate: props.mode==='create'?"today":'' ,
                                                enableTime: true,
                                                enableSeconds: true,
                                                time_24hr: true,
                                                defaultHour: 0,
                                                defaultMinute: 0,
                                                defaultSeconds: 0,
                                                dateFormat: "Y-m-d H:i:S",
                                                onChange: function (selectedDates, dateStr, instance) {
                                                    try {
                                                        const dt = selectedDates[0]
                                                        sellEventTicket['start_timestamp'] = getUnixTime(new Date(dt))
                                                        
                                                        const tomorrow = dateFnsFormat(addDays(new Date(dt), 1), "yyyy-MM-dd 23:59:59");
                                                        setRecommendedEndDateMin(tomorrow)
                                                        
                                                        // set redeem start date to be the same
                                                        sellEventTicket['selling_startTimestamp'] = getUnixTime(new Date(dt))
                                                        sellEventTicket['redemption_startTimestamp'] = getUnixTime(new Date(dt))
                                                        setSellEventTicket({ ...sellEventTicket })
                                                    } catch (e) {
                                                        console.error(e)
                                                    }
                                                }
                                            }}
                                            
                                        />
                                        <span className="input-group-text mdi mdi-calendar"></span>
                                    </InputGroup>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className='selling-end-date mt-3'>
                                    <Label className="subTitle">Display End Date & Time*</Label>
                                    <br/>
                                    <a href="#" onClick={ () => {sellEventTicket.end_timestamp=getUnixTime(endOfDay(fromUnixTime(sellEventTicket.end_timestamp))); setSellEventTicket(sellEventTicket)} }>Default to 23:59</a>
                                    <InputGroup>
                                        <Flatpickr
                                            disabled={disableEditing}
                                            className={disableEditing ? "form-control d-block greyBg" :"form-control d-block whiteBg"}
                                            placeholder="Select a date"
                                            value={sellEventTicket.end_timestamp ? fromUnixTime(sellEventTicket.end_timestamp) : ""}
                                            options={{
                                                minDate: props.mode==='create'?recommendedEndDateMin:"" ,
                                                enableTime: true,
                                                enableSeconds: true,
                                                time_24hr: true,
                                                defaultHour: 23,
                                                defaultMinute: 59,
                                                defaultSeconds: 59,
                                                dateFormat: "Y-m-d H:i:S",
                                                onChange: function (selectedDates, dateStr, instance) {
                                                    try {
                                                        const dt = selectedDates[0]
                                                        sellEventTicket['end_timestamp'] = getUnixTime(new Date(dt))
                                                        //setSellEventTicket({ ...sellEventTicket })

                                                        const redemptionEndDate = dateFnsFormat(addDays(new Date(dt), 1), "yyyy-MM-dd");
                                                        setRecommendedRedemptionEndDateMin(redemptionEndDate)

                                                        sellEventTicket['selling_endTimestamp'] = getUnixTime(new Date(dt))
                                                        sellEventTicket['redemption_endTimestamp'] = getUnixTime(addDays(new Date(dt), 1))
                                                        setSellEventTicket({ ...sellEventTicket })

                                                        const hrsDiff = differenceInHours(fromUnixTime(sellEventTicket.end_timestamp), fromUnixTime(sellEventTicket.start_timestamp))
                                                        if (hrsDiff<24) {
                                                            setDisplayEndDateError('Date has to be after display start date.')
                                                        } else {
                                                            setDisplayEndDateError('')
                                                        }
                                                    } catch(e) {
                                                        console.error(e)
                                                        dispatch(setAmplitudeEvent('CMS Sell Event Tickets page - Selling End Date & Time error', {'details': JSON.stringify(e) }))
                                                    }
                                                }
                                            }}

                                        />
                                        <span className="input-group-text mdi mdi-calendar"></span>
                                    </InputGroup>
                                    <span className="subLabelWarning">{displayEndDateError}</span>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="d-flex flex-column justify-content-between mt-3">
                                    <Label className="subTitle">Selling Start Date & Time*</Label>
                                    <InputGroup>
                                        <Flatpickr
                                            disabled={disableEditing}
                                            className={disableEditing? "form-control d-block greyBg" :"form-control d-block whiteBg"}
                                            placeholder="Select a date"
                                            value={sellEventTicket.selling_startTimestamp ? fromUnixTime(sellEventTicket.selling_startTimestamp) : ""}
                                            options={{
                                                disableMobile: true,
                                                minDate: props.mode==='create'?"today":'' ,
                                                enableTime: true,
                                                enableSeconds: true,
                                                time_24hr: true,
                                                defaultHour: 0,
                                                defaultMinute: 0,
                                                defaultSeconds: 0,
                                                dateFormat: "Y-m-d H:i:S",
                                                onChange: function (selectedDates, dateStr, instance) {
                                                    try {
                                                        const dt = selectedDates[0]
                                                        sellEventTicket['selling_startTimestamp'] = getUnixTime(new Date(dt))
                                                        // set redeem start date to be the same
                                                        // sellEventTicket['redemption_startTimestamp'] = getUnixTime(new Date(dt))
                                                        setSellEventTicket({ ...sellEventTicket })
                                                    } catch (e) {
                                                        console.error(e)
                                                    }
                                                }
                                            }}
                                            
                                        />
                                        <span className="input-group-text mdi mdi-calendar"></span>
                                    </InputGroup>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className='selling-end-date mt-3'>
                                    <Label className="subTitle">Selling End Date & Time*</Label>
                                    <br/>
                                    <a href="#" onClick={ () => {sellEventTicket.selling_endTimestamp=getUnixTime(endOfDay(fromUnixTime(sellEventTicket.selling_endTimestamp))); setSellEventTicket(sellEventTicket)} }>Default to 23:59</a>
                                    <InputGroup>
                                        <Flatpickr
                                            disabled={disableEditing}
                                            className={disableEditing ? "form-control d-block greyBg" :"form-control d-block whiteBg"}
                                            placeholder="Select a date"
                                            value={sellEventTicket.selling_endTimestamp ? fromUnixTime(sellEventTicket.selling_endTimestamp) : ""}
                                            options={{
                                                minDate: props.mode==='create'?recommendedEndDateMin:"" ,
                                                enableTime: true,
                                                enableSeconds: true,
                                                time_24hr: true,
                                                defaultHour: 23,
                                                defaultMinute: 59,
                                                defaultSeconds: 59,
                                                dateFormat: "Y-m-d H:i:S",
                                                onChange: function (selectedDates, dateStr, instance) {
                                                    try {
                                                        const dt = selectedDates[0]
                                                        sellEventTicket['selling_endTimestamp'] = getUnixTime(new Date(dt))
                                                        setSellEventTicket({ ...sellEventTicket })

                                                        const hrsDiff = differenceInHours(fromUnixTime(sellEventTicket.selling_endTimestamp), fromUnixTime(sellEventTicket.selling_startTimestamp))
                                                        if (hrsDiff<24) {
                                                            setSellingEndDateError('Date has to be after selling start date.')
                                                        } else {
                                                            setSellingEndDateError('')
                                                        }
                                                    } catch(e) {
                                                        console.error(e)
                                                        dispatch(setAmplitudeEvent('CMS Sell Event Tickets page - Selling End Date & Time error', {'details': JSON.stringify(e) }))
                                                    }
                                                }
                                            }}

                                        />
                                        <span className="input-group-text mdi mdi-calendar"></span>
                                    </InputGroup>
                                    <span className="subLabelWarning">{sellingEndDateError}</span>
                                </Col>
                                
                                <Col xs={12} sm={6} md={6} lg={12} xl={6} className='mt-3'>
                                    <Label className="subTitle">Actual Price*</Label>
                                    <InputGroup>
                                        <InputGroupText style={{ height:'100%' }}>                                
                                            S$
                                        </InputGroupText>
                                        <AvField
                                            ref={quantityInputRef}
                                            onWheel={(e) => e.target.blur()}
                                            min="0"
                                            id="actualPrice"
                                            disabled={disableEditing}
                                            name="actualPrice"
                                            placeholder=""
                                            type="number"
                                            errorMessage="This field is required"
                                            className="form-control"
                                            validate={{validatePriceValue}}
                                            value={sellEventTicket.actualPrice}
                                            onChange={(e) => handleChange(e, 'actualPrice')}
                                            />
                                    </InputGroup>
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={12} xl={6} className='mt-3'>
                                    <Label className="subTitle">Discount Amount*</Label>
                                    <InputGroup>
                                        <InputGroupText style={{ height:'100%' }}>
                                        S$
                                        </InputGroupText>
                                        <AvField
                                            ref={quantityInputRef}
                                            onWheel={(e) => e.target.blur()}
                                            min="0"
                                            disabled={disableEditing}
                                            id="discountPrice"
                                            name="discountPrice"
                                            placeholder=""
                                            type="number"
                                            errorMessage="This field is required"
                                            className="form-control"
                                            validate={{validatePriceValue}}
                                            value={sellEventTicket.discountPrice}
                                            onChange={(e) => handleChange(e, 'discountPrice')}
                                            />
                                    </InputGroup>
                                </Col>

                                <Col xs={12} sm={6} md={6} lg={12} xl={6} className='mt-3'>
                                    <Label className="subTitle">Selling Price*</Label>
                                    <InputGroup>
                                        <InputGroupText style={{ height:'100%' }}>
                                        S$
                                        </InputGroupText>
                                            <AvField
                                            ref={quantityInputRef}
                                            onWheel={(e) => e.target.blur()}
                                            min="0"
                                            disabled={disableEditing}
                                            id="purchasePrice"
                                            name="purchasePrice"
                                            placeholder=""
                                            type="number"
                                            errorMessage="This field is required"
                                            className="form-control"
                                            validate={{validatePriceValue}}
                                            value={sellEventTicket.purchasePrice}
                                            onChange={(e) => handleChange(e, 'purchasePrice')}
                                            />
                                    </InputGroup>
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={12} xl={6} className='mt-3'>
                                    <Label className="subTitle">Discount Tag</Label>
                                    <InputGroup>
                                        <AvField
                                        disabled={disableEditing}
                                        id="discount"
                                        name="discount"
                                        placeholder=""
                                        type="text"
                                        readOnly
                                        className="form-control"
                                        value={(sellEventTicket.discount * 100).toFixed(2)}
                                        onChange={(e) => handleChange(e, 'discount')}
                                        />
                                        <InputGroupText style={{ height:'100%' }}>
                                        %
                                        </InputGroupText>
                                    </InputGroup>
                                </Col>
                                <Col xs={8} sm={8} md={8} lg={8} xl={8} className='mt-3'>
                                    <Label className="subTitle">Purchase Limit per customer*</Label>
                                    <InputGroup style={{ maxWidth:'196px' }} >
                                        <Button type="button" color="primary" onClick={() => { setPurchaseLimitPerCustomer(- 1)}}>
                                        <i className="mdi mdi-minus" />
                                        </Button>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={sellEventTicket.purchaseLimitPerCustomer}
                                            placeholder="1"
                                            onChange={(e) => {
                                                const v = e.target.value
                                                if (isNaN(v) || v.startsWith('0')) {
                                                    return
                                                } else if (parseInt(v) < 1 || parseInt(v) > 100) {
                                                    return
                                                } else {
                                                    setSellEventTicket({...sellEventTicket, purchaseLimitPerCustomer:v})}
                                                }
                                            }
                                        />
                                        <Button type="button" onClick={() => { setPurchaseLimitPerCustomer(1)}} color="primary" >
                                        <i className="mdi mdi-plus" />
                                        </Button>
                                    </InputGroup>
                                </Col>

                                <Col xs={8} sm={8} md={8} lg={8} xl={8} className='mt-3'>
                                    <Label className="subTitle">Minimum set for purchase per transaction*</Label>
                                    <InputGroup style={{ maxWidth:'196px' }} >
                                        <Button type="button" color="primary" onClick={() => { setMinQtyTx(- 1)}}>
                                        <i className="mdi mdi-minus" />
                                        </Button>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={sellEventTicket.minQtyTx}
                                            placeholder="1"
                                            onChange={(e) => {
                                                const v = e.target.value
                                                if (isNaN(v) || v.startsWith('0')) {
                                                    return
                                                } else if (parseInt(v) < 1 || parseInt(v) > 100) {
                                                    return
                                                } else {
                                                    setSellEventTicket({...sellEventTicket, minQtyTx:v})}
                                                }
                                            }
                                        />
                                        <Button type="button" onClick={() => { setMinQtyTx(1)}} color="primary" >
                                        <i className="mdi mdi-plus" />
                                        </Button>
                                    </InputGroup>
                                </Col>
              

                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className='mt-3'>
                                    <Row>
                                        <Col>
                                        <Label className="subTitle">Redemption Start Date & Time*</Label>
                                    <p className='m-0'></p>
                                    <InputGroup className='redemption-start-date'>
                                    <Flatpickr
                                        id="redemption_start_timestamp"
                                        className="form-control d-block greyBg"
                                        placeholder="Select a date"
                                        disabled={true}
                                        value={sellEventTicket.redemption_startTimestamp ? fromUnixTime(sellEventTicket.redemption_startTimestamp) : ""}
                                        options={{
                                            enableTime: true,
                                            enableSeconds: true,
                                            time_24hr: true,
                                            defaultHour: 0,
                                            defaultMinute: 0,
                                            defaultSeconds: 0,
                                            dateFormat: "Y-m-d H:i:S",
                                            onChange: function (selectedDates) {
                                                try {
                                                    const dt = selectedDates[0]
                                                    sellEventTicket['redemption_startTimestamp']= getUnixTime(new Date(dt))
                                                    sellEventTicket({ ...sellEventTicket })
                                                } catch (e) {
                                                    console.error(e)
                                                    dispatch(setAmplitudeEvent('CMS Sell Event Tickets page - Redemption Start Date & Time error', {'details': JSON.stringify(e) }))
                                                }
                                            }
                                        }}
                                        
                                    />
                                    <span className="input-group-text mdi mdi-calendar"></span>
                                    </InputGroup>
                                        </Col>
                                    </Row>
                                   <Row>
                                        <Col>
                                            <div className="form-check mt-1">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={ true }
                                                    disabled={ true }
                                                />
                                                <label className="form-check-label fontWeightLight">Same as selling start date</label>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className='selling-end-date mt-3'>
                                    <Row>
                                        <Col>
                                        <Label className="subTitle m-0">Redemption End Date & Time*</Label> 
                                        <br/>
                                        <a href="#" onClick={ () => {sellEventTicket['redemption_endTimestamp'] = getUnixTime(endOfDay(fromUnixTime(sellEventTicket.redemption_endTimestamp))); setSellEventTicket(sellEventTicket)} }>Default to 23:59</a>
                                        <p className='m-0'>Recommended 14 days from selling end date.</p>
                                        <InputGroup>
                                            <Flatpickr
                                                id="redemption_end_timestamp"
                                                className={disableEditing ? "form-control d-block greyBg" :"form-control d-block whiteBg"}
                                                placeholder="Select a date"
                                                disabled={disableEditing}
                                                value={sellEventTicket.redemption_endTimestamp ? fromUnixTime(sellEventTicket.redemption_endTimestamp) : ""}
                                                options={{
                                                    minDate: props.mode==='create'?recommendedRedemptionEndDateMin : '',
                                                    enableTime: true,
                                                    enableSeconds: true,
                                                    time_24hr: true,
                                                    defaultHour: 23,
                                                    defaultMinute: 59,
                                                    defaultSeconds: 59,
                                                    dateFormat: "Y-m-d H:i:S",
                                                    onChange: function (selectedDates, dateStr, instance) {
                                                        try {
                                                            sellEventTicket['redemption_endTimestamp'] = getUnixTime(new Date(dateStr))
                                                            const hrsDiff = differenceInHours(fromUnixTime(sellEventTicket.redemption_endTimestamp), fromUnixTime(sellEventTicket.end_timestamp))
                                                            if (hrsDiff<24) {
                                                                setRedemptionEndDateError('Date has to be at least 24hrs from Selling End Date')
                                                            } else {
                                                                setRedemptionEndDateError('')
                                                            }
                                                            setSellEventTicket({ ...sellEventTicket })
                                                        } catch(e) {
                                                            console.error(e)
                                                            dispatch(setAmplitudeEvent('CMS Sell Event Tickets page - Redemption End Date & Time error', {'details': JSON.stringify(e) }))
                                                        }
                                                    }
                                                }}

                                            />
                                            <span className="input-group-text mdi mdi-calendar"></span>
                                        </InputGroup>
                                        <span className="subLabelWarning">{redemptionEndDateError}</span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ModalBody>

                <ModalFooter>
                    {transactionMessage ?
                        <Alert color={transactionMessage.type}>
                            {transactionMessage.msg}
                        </Alert>
                        : null}

                    {processing ?
                        <Spinner />
                        : null}

                    <Button
                        disabled={processing}
                        color="primary"
                        type="submit"
                    >
                        {props.mode==='create'? 'Add event ticket' :'Update event ticket'}
                    </Button>
                    {' '}
                    <Button disabled={processing} onClick={() => close(null,false)}>
                        Cancel
                    </Button>
                </ModalFooter>
            </AvForm>
        </Modal>
    )
}

export default SellEventTicketsForm;