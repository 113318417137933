import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect } from "react"
import { Row, Col, Alert, Card, CardBody, Container, Spinner } from "reactstrap"
import { connect, useDispatch } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { userForgetPassword, setAmplitudeEvent } from "../../store/actions"
import logoLightPng from "../../assets/images/logo-onboarding.png"
import { getAmplifyBackend } from "helpers/authHelper";
import { amplitudeUserProperty, openInNewTab } from 'utils';

const ForgetPasswordPage = props => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const amplifyBackend = getAmplifyBackend()
  const dispatch = useDispatch()
  
  const handleValidSubmit = async (event, values) => {
    let email = values['email']
    let response = await amplifyBackend.forgetPassword(email)
    setLoading(true)
    if (response.CodeDeliveryDetails){
        props.userForgetPassword(email)
        props.history.push('/password-reset');
    }else {
      setError(response.message)
      setLoading(false)
    }
    dispatch(setAmplitudeEvent('CMS reset password page - send code button', {'registration status': 'N'})) 
    amplitudeUserProperty('registration status', 'N')
  }

  useEffect(() => {
    dispatch(setAmplitudeEvent('CMS reset password page - page load', {'registration status': 'N'})) 
    amplitudeUserProperty('registration status', 'N')
  }, [])
  
  const gotoSignIn = () =>{
    dispatch(setAmplitudeEvent('CMS reset password page - sign in here button', {'registration status': 'N'})) 
    amplitudeUserProperty('registration status', 'N')
  }

  return (
    <React.Fragment>
      <MetaTags>
      <title>Reset Password | CardsPal Business</title>
      </MetaTags>
      <div className="account-pages login-background" >
        <div style={{ position:'relative' }}>
          <a href='#' onClick={()=>openInNewTab('https://cardspal.com/wp-content/uploads/2022/03/CardsPal-Merchant-Guide_Mar-2022.pdf')} className="viewUserGuide">View merchant guide</a>
        </div>
        <Container>
          <Row className="justify-content-center vertical-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden mt-5">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-2">
                    <Link to="/" className="d-block auth-logo">
                      <img src={logoLightPng} alt="" height="50" className="auth-logo-dark" />
                    </Link>
                  </h3>
                  <div className="p-1">
                  <h4 className="text-muted text-center mb-3">Reset Password</h4>
                    <Alert color="info">
                        <p className="mb-0">
                          Enter your Email and OTP will be sent to you!
                        </p>
                    </Alert>
                    {typeof error === "string" ? (
                        <Alert color="danger">{error}</Alert>
                      ) : null}
                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          type="email"
                          required
                          errorMessage="This field is required"
                          autoComplete = 'off'
                          autoCapitalize="none"
                        />
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                        {loading?<Spinner size="sm" style={{"marginRight":"10px"}}/>:null}
                          <button disabled={loading}
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                           Send Code
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Remember It ?{" "}
                  <Link to="login" className="text-primary" onClick={()=>gotoSignIn()}>
                    Sign In Here
                  </Link>{" "}
                </p>
                <p>
                  <Link to="#" onClick={()=>openInNewTab('https://cardspal.com/support/')}><span>Support Center</span></Link><span className="separator">|</span>
                  <Link to="#" onClick={()=>openInNewTab('https://cardspal.com/merchant_terms/')}><span>Merchant T&C</span></Link><span className="separator">|</span>
                  <Link to="#" onClick={()=>openInNewTab('https://cardspal.com/terms-of-use/')}><span>Terms of Use</span></Link><span className="separator">|</span>
                  <Link to="#" onClick={()=>openInNewTab('https://cardspal.com/privacy-policy/')}><span>Privacy Policy</span></Link>
                </p>
                <p>
                © {new Date().getFullYear()} CardsPal Business
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func
}

const mapStatetoProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword
  return { forgetError, forgetSuccessMsg }
}

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(ForgetPasswordPage)
)
