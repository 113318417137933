import React, { useEffect, useState } from "react"
import { Card, CardBody, CardImg, Button, CardSubtitle, Badge } from "reactstrap"

// import components
import StripePrompt from '../../../../components/Common/stripePrompt'

// import utils
import { openInNewTab  } from "../../../../utils";
import { STRIPE_STATUS } from  '../../../../utils/constants'

import stripeLogo from '../../../../assets/images/stripe.png'

const Brands = (props)=> {
    const { brand, banner, logo, stripeStatus, link, buttonLabel, needPrompt  } = props
    const [modalStripePromptStatus, setModalStripePromptStatus] = useState(false)
    const [statusColor, setStatusColor] = useState('')

    useEffect(() => {
        switch (stripeStatus) {
            case STRIPE_STATUS.NOT_ONBOARDED:
                setStatusColor('blueBg')  // blue
                break;
            case STRIPE_STATUS.COMPLETED:
                setStatusColor('greenBg') // green
                break;
            default:
                setStatusColor('purpleBg') // purple
                break;
        }
    })

    function close() {
        setModalStripePromptStatus(false)
    }

    function viewStripe(link, needPrompt) {
        if (needPrompt) {
            setModalStripePromptStatus(true)
        } else {
            openInNewTab(link)
        }
    }

    return(
        <Card className="text-center">
            <div>
                <CardImg top height='100px' width='100%' src={`${banner}?t=${new Date().getTime()}`} style={{objectFit:'cover'}} />
                <div style={{ position: "absolute", right: 0, left:0, top: 50 }}>
                    <img className="border rounded-0" style={{backgroundColor:'#FFF', height:'75px', width:'75px'}} src={logo}/>
                </div>
            </div>
            <CardBody>
                <CardSubtitle className="mt-3">{brand}</CardSubtitle>
                <img style={{ width:'60px' }} src={stripeLogo}/>
                <p style={{ fontSize: 10 }}><Badge className={`${statusColor} rounded bg-primary`}>{stripeStatus}</Badge></p>
                <Button color='primary' outline={!needPrompt} onClick={() => viewStripe(link, needPrompt)} >{buttonLabel}</Button>
            </CardBody>
            {modalStripePromptStatus ? <StripePrompt cta="manage profile page" stripeLink={link} modalStripePromptStatus={modalStripePromptStatus} close={() => close()} /> : null}
        </Card>
             
    )
}

export default Brands