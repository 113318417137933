import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { useSelector, useDispatch } from "react-redux";
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardImg, Spinner, Button } from "reactstrap"
import LinesEllipsis from 'react-lines-ellipsis'
import { getUnixTime } from 'date-fns'

import RBAC from '../../../components/Common/rbac'

//import components
import StripePrompt from "../../../components/Common/stripePrompt";
import EmptyState from './Components/emptyState'
import SellVoucherForm from  './Components/sellVouchersForm'
import ImageOverlay from '../../../components/Common/imageOverlay'
import VoucherOutlet from './Components/voucherOutlets'
import StripeOnboarding from "../../../components/Common/stripeOnboarding";
import sellVoucherStepImg from '../../../assets/images/sellVoucherStepsC.png'

// import utils
import { formatDateTime, isDealExpiring, openInNewTab, guidGenerator, capitalizeFirstLetter, convertToCSV } from '../../../utils'
import { STRIPE_STATUS, SELL_VOUCHERS_TERMS_TEMPLATE_SHOPFRONT, VOUCHER_CODE_TEMPLATE_FILE, ON_BOARDING } from '../../../utils/constants'
import { getStripeStatusColor } from "utils/stripe";

//import store actions
import { setBreadcrumbItems, setAlert, setConfig, setEnv, setAmplitudeEvent  } from "../../../store/actions";

// import graphql
import { listVouchersByDealId } from "../../../helpers/GraphQL/voucherMaster"
import { listPromotionsByMerchantId } from "../../../helpers/GraphQL/promotions"
import { listOutletsByMerchantId } from '../../../helpers/GraphQL/outlet'
import { getBrandInfo } from '../../../helpers/GraphQL/brands'
import { getMerchantStripeAccount, updateStripeAccount, createStripeAccount } from "../../../helpers/GraphQL/userStripeAccount"
import { getVoucherUserByCode } from "helpers/GraphQL/voucherUser";

// import helpers
import { stripeCreateOnboardingLink, stripeRetrieveKey, stripeRetrieveAccount, stripeCreateLoginLink, getConfig, getEnv  } from '../../../helpers/apiHelper'

const SellVouchers = () => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.User.user)
    const cmsConfig = useSelector(state => state.Config)
    const selectedMerchant = useSelector(state => state.Merchant.selectedMerchant)
    const [refreshUrl, setRefreshUrl] = useState('https://biz.cardspal.com')
    const [returnUrl, setReturnUrl] = useState('https://biz.cardspal.com')
    const [createStripeButtonDisabled, setCreateStripeButtonDisabled] = useState(true)
    const [stripeKey, setStripeKey] = useState(null)
    const [stripeLink, setStripeLink] = useState()
    const [buttonLabel, setButtonLabel] = useState('Connect to our Stripe Account')
    const [stripeAccountStatus, setStripeAccountStatus] = useState('')
    const [userInfo, setUserInfo] = useState()
    const [modalStripePromptStatus, setModalStripePromptStatus] = useState(false)
    const [needPrompt, setNeedPrompt] = useState(false)
    const [stripeStatus, setStripeStatus] = useState('')
    const [statusColor, setStatusColor] = useState('')
    const [loadingStatus, setLoadingStatus] = useState(false)
    const [modalSellVoucherFormStatus, setModalSellVoucherFormStatus] = useState(false)
    const [modalImageOverlayStatus, setModalImageOverlayStatus] = useState(false)
    const [mode, setMode] = useState('')
    const [sellVoucher, setSellVoucher] = useState({})
    const [reload, setReload] = useState('')
    const [outlets, setOutlets] =useState([])
    const [isNoSellVoucher, setIsNoSellVoucher] = useState(true)
    const [downloadingCSV, setDownloadingCSV] = useState(false)
    const [modalVoucherOutletStatus, setModalVoucherOutletStatus] = useState(false)
    const [canCreateVoucher, setCanCreateVoucher] = useState(true)

    const data = {
        columns: [
            {
                label: "ID",
                field: "id",
                width: 50,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            },
            {
                label: "Edit",
                field: "edit",
                sort: 'disabled',
                width: 50,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            },
            {
                label: "Deal Image",
                field: "dealImage",
                width: 80,
                
            },
            {
                label: "Visible on app",
                field: "visible",
                width: 70,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            },
            {
                label: "No. of vouchers",
                field: "numVouchers",
                width: 100,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            },
            {
                label: "Uploaded / Generated",
                field: "codeType",
                width: 110,
            },
            {
                label: "Promo Type",
                field: "promoType",
                width: 100,
            },
            {
                label: "Voucher Title",
                field: "voucherTitle",
                width: 200,
                sort: "asc",
            },
            {
                label: "Voucher Additional Details / T&Cs",
                field: "tc",
                width: 300,
                sort: 'disabled',
            },
            {
                label: "Valid till",
                field: "validTill",
                sort: 'disabled',
                width: 120,
            },
            {
                label: "Actual Price",
                field: "actualPrice",
                sort: 'asc',
                width: 100,
            },
            {
                label: "Purchase Price",
                field: "purchasePrice",
                sort: 'disabled',
                width: 100,
            },
            {
                label: "Discount Amount",
                field: "discountAmount",
                sort: 'disabled',
                width: 100,
            },
            {
                label: "Discount Tag",
                field: "discountTag",
                sort: 'disabled',
                width: 100,
            },
            {
                label: "Purchase Limit",
                field: "purchaseLimit",
                sort: 'disabled',
                width: 100,
                
            },
            {
                label: "Status",
                field: "status",
                sort: 'disabled',
                width: 100,
                
            },
            {
                label: "Outlet",
                field: "outlet",
                sort: 'disabled',
                width: 100,
                
            },
            {
                label: "Start Date/Time",
                field: "sDate",
                sort: 'disabled',
                width: 120,
                
            },
            {
                label: "End Date/Time",
                field: "eDate",
                sort: 'disabled',
                width: 120,
                
            },
            {
                label: "Deal Website",
                field: "website",
                sort: 'disabled',
                width: 150,
                
            }

        ],
        rows: []
    }

    const [dataTable, setDataTable] = useState(data)

    const breadcrumbItems = [
        { title: "CardsPal", link: "#" },
        { title: selectedMerchant.display_merchant_name, link: "#" },
        { title: "Tools", link: "#" },
        { title: "Sell Vouchers", link: "#" },
    ]

    useEffect(() => {
        dispatch(setAlert(''))
        dispatch(setBreadcrumbItems('Sell vouchers', breadcrumbItems))
    }, [selectedMerchant.display_merchant_name])

    useEffect(async () => {
        if (!selectedMerchant || !selectedMerchant.merchant_id) return 
        
        // get the updated merchant info from DB in case webhook is updating stripe and other info and the user has not logout yet causing the store to be an old-data
        const brand = await getBrandInfo(selectedMerchant.merchant_id)
        // update the stripe attribute with latest to make sure we restrict if needed
        selectedMerchant.stripeExpressConnectedAccountId = brand.stripeExpressConnectedAccountId
        // if stripeExpressConnectedAccountId is not in merchant or empty or null, disable button to add voucher
        setCanCreateVoucher(!brand.stripeExpressConnectedAccountId)
    }, [selectedMerchant])

    useEffect(() =>{
        if (cmsConfig && cmsConfig.cmsv2) setConfigVariables(cmsConfig.cmsv2.stripeVariables)
    }, [cmsConfig])

    useEffect(async () => {
        try {
            const _outlets = await listOutletsByMerchantId(selectedMerchant.merchant_id)
            setOutlets([..._outlets])
        } catch (e) {
            dispatch(setAlert(''))
        }
    }, [selectedMerchant])

    useEffect(() => {
        setStatusColor(getStripeStatusColor(stripeStatus))
    }, [stripeStatus])

    useEffect(async () => {
        setLoadingStatus(true)
        dispatch(setAlert('Initializing...Please wait while we finish getting updates', 'info'))

        try {
            // get user info
            const user = await JSON.parse(await localStorage.getItem('user'))
            setUserInfo(user)
            
            
            // retrieve the stripe key from secret manager
            const key = await stripeRetrieveKey()
            if (!key) {
                dispatch(setAlert(`Error loading this page. Please report to admin immediately.`, 'danger'))
                return
            } else {
                setStripeKey(key.stripeSecret)
            }

            await initializeStripeLink(user, key?.stripeSecret)
            
        } catch (error) {
            console.error(error)
            setLoadingStatus(false)
            dispatch(setAlert(`Error loading this page. Please report to admin immediately.`, 'danger'))
        }
    }, [])

    useEffect(() => {
        setButtonLabel('Connect to our Stripe Account')
        initializeStripeLink(userInfo, stripeKey)
    }, [selectedMerchant])

    useEffect(async () => {

        if (!user || !selectedMerchant.merchant_id || Object.keys(user).length === 0) return 

        setLoadingStatus(true)
        data.rows = [] // clear the display items on the table first
        setDataTable(data)

        const promos = await listPromotionsByMerchantId(selectedMerchant.merchant_id, {onlyPurchasable: true})
        if (promos && promos.length > 0) setIsNoSellVoucher(false)
        
        const dataRows = []
        for (let i=0; i < promos.length; i++){
            const _item = promos[i]
            const vouchers =  await listVouchersByDealId(_item.pk)
            
            dataRows.push({
                id: i+1,
                edit: <a href="#" onClick={() => updateVoucher(_item, i)}><i className="mdi mdi-pencil" style={{ color: "#7a6fbe" }} /></a>,
                dealImage: <a href="#" onClick={() => viewImg(_item)}>View</a>,
                visible: getDealStatus(_item, i),
                numVouchers: downloadSellVouchers(vouchers),
                codeType: vouchers.length > 0 ? capitalizeFirstLetter(vouchers[0].codeType) : 'N/A',
                promoType: _item.is_online==='True' ? 'Promo Code/URL' : 'Shopfront',
                voucherTitle: <LinesEllipsis text={_item.promotion_caption} maxLine={3} ellipsis="..." />,
                tc: <LinesEllipsis text={_item.terms} maxLine={3} ellipsis="..." />,
                validTill: vouchers.length > 0 ? formatDateTime(vouchers[0].endTimestamp):'N/A',
                actualPrice: _item.actualPrice,
                purchasePrice: _item.purchasePrice,
                discountAmount: _item.discountPrice,
                discountTag: `${_item.discount*100}%`,
                purchaseLimit: _item.purchaseLimitPerCustomer,
                status: decidePromoStatus(_item),
                outlet: <a href="#" onClick={() => viewVoucherOutlets(_item)}>View</a>,
                sDate: formatDateTime(_item.start_timestamp).toString(),
                eDate: formatDateTime(_item.end_timestamp).toString(),
                website: <a href="#" onClick={() => openInNewTab(_item.website)}>{_item.website}</a>
            })
        }
        
        // set the data table values
        data.rows = dataRows
        setDataTable({...data})
        
        // TODO :: confirm parameters
        let _ampTrack = {
            'merchant id': selectedMerchant.merchant_id,
            'merchant name':  selectedMerchant.merchant_name,
            'mainCat': selectedMerchant.mainCat
        }
        let trackEmpty = {empty:promos && promos.length > 0? 'N' :'Y'}
        dispatch(setAmplitudeEvent('CMS Sell Vouchers page - page load', {..._ampTrack, ...trackEmpty}))
        
        setLoadingStatus(false)
    }, [selectedMerchant, user, reload])

    useEffect(async () => {
        await getCMSConfig()
    }, [])
    
    function viewImg(_item) {
        setSellVoucher(_item)
        setModalImageOverlayStatus(true)
    }

    function downloadSellVouchers(vouchers) {
        return <span>{vouchers.length}<a onClick={()=>downloadVoucherCode(vouchers)} className="mdi mdi-download p-2"/></span>
    }

    const downloadVoucherCode = async(vouchers) => {
        setDownloadingCSV(true)
        let dealId = vouchers[0].dealId
        let temp = []

        const codes = await listVouchersByDealId(dealId)
        
        for (let i=0; i < codes.length; i++) {
            const item = codes[i]
            const voucherUser = await getVoucherUserByCode(item.code)
            if (item) {
                temp.push({
                    id: i+1,
                    code: item.code,
                    status: item.status==='0'? 'Active':'Sold',
                    redemption: voucherUser.items && voucherUser.items.length > 0 && voucherUser.items[0].status === '1'? 'Redeemed': 'Unused'
                })
            }
        }
        
        if (temp.length>0) {
            let arrCode = []
            let objCode = {}
            temp.map((e)=>{
                let id = e.id
                let code = e.code
                let status = e.status
                let redemption = e.redemption

                objCode = {'ID': id, 'Voucher Code':code, 'Status':status, 'Redemption': redemption}
                arrCode.push(objCode)
            })
            convertToCSV(arrCode, 'voucher-code-template')
            setDownloadingCSV(false)
        }   
    }


    function viewVoucherOutlets(item) {
        setSellVoucher({...item})
        setModalVoucherOutletStatus(true)
    }

    function decidePromoStatus(item) {
        if (!item.approval) {
            return 'Approved'
        } else if (item.approval && item.approval.isApproved) {
            if (item.valid === 'N') {
                return 'Inactive'
            } else if (item.end_timestamp && isDealExpiring(item.end_timestamp)) {
                return 'Expiring'
            } else  {
                return 'Active'
            }
        } else {
            return 'Pending approval'
        }
    }

    function getDealStatus(item) {
        if (item.valid === 'Y') {
            return (
                // <a href="#"  >
                <i className="mdi mdi-eye" style={{ color: "#58DB83" }} />
                // </a>
            )
        } else {
            return (
                // <a href="#" >
                <i className="mdi mdi-eye-off" style={{ color: "#EC536C" }} />
                // </a>
            )
        }
    }

    async function getCMSConfig() {
        try {
            
            if (!cmsConfig || !cmsConfig.cmsv2) {
                const config = await getConfig()

                const env = getEnv()
                
                dispatch(setConfig(config))
                dispatch(setEnv(env))
            }
        } catch(error) {
            console.error('error on retrieving config file', error)
            dispatch(setAmplitudeEvent('CMS Sell Vouchers page - getCMSConfig function error', {'details': JSON.stringify(error)}))
        }
    }
    
    async function setConfigVariables(variables) {
        setRefreshUrl(variables.refreshUrl)
        setReturnUrl(variables.returnUrl)
    }

    async function initializeStripeLink(userInfo, stripeKey) {
        try {

            if (!userInfo || !stripeKey) return

            // check user on-boarding status
            const stripeAccount = await getMerchantStripeAccount(selectedMerchant.merchant_id)
            
            let stripeInfo
            let status // status: completed, pending-verification, not-completed
            let link
            
            if (stripeAccount && stripeAccount.type === ON_BOARDING) {
            
                if (stripeAccount.status === STRIPE_STATUS.COMPLETED){
                    console.debug('completed stripe account ')
                    status = STRIPE_STATUS.COMPLETED
                    
                    if (!selectedMerchant.stripeExpressConnectedAccountId) {
                        dispatch(setAlert('Cannot create voucher. Please report to the Admin to resolve your account', 'danger'))
                    } else {
                        dispatch(setAlert(''))
                    }
                    
                    setButtonLabel('View Stripe Platform')
                } else {
                    // means user triggered on-boarding before but did not finish, so we retrieve info from stripe api again
                    stripeInfo = await stripeRetrieveAccount({ stripeKey, accountId: stripeAccount.accountId })

                    if (!stripeInfo.charges_enabled) {
                        // dispatch(setAlert(''))
                        status = stripeAccount.status
                        console.debug('not completed so create account link')
                        const stripeInfo = await stripeCreateOnboardingLink({ email: userInfo.email, stripeKey, refreshUrl, returnUrl, merchantId: selectedMerchant.merchant_id, accountId: stripeAccount.accountId })
                        link = stripeInfo.accountLink.url
                        setNeedPrompt(true)
                    } else {
                        status = await stripeStatusLogic(stripeInfo)
                        console.debug('not completed so create login link')
                        const stripeLoginLink = await stripeCreateLoginLink({ stripeKey, accountId: stripeAccount.accountId })
                        link = stripeLoginLink.url
                    }
                    dispatch(setAlert(''))
                    
                    await updateStripeAccount({
                        accountId: stripeAccount.accountId,
                        merchantId: selectedMerchant.merchant_id,
                        email: userInfo.email,
                        type: 'on-boarding',
                        status
                    })
                }
            } else {
                // first time to on-board to stripe
                status = STRIPE_STATUS.NOT_ONBOARDED
                stripeInfo = await stripeCreateOnboardingLink({ email: userInfo.email, stripeKey, refreshUrl, returnUrl, merchantId: selectedMerchant.merchant_id })
                link = stripeInfo.accountLink.url

                setNeedPrompt(true)
                dispatch(setAlert(''))
                
                await createStripeAccount({
                    accountId: stripeInfo.account.id,
                    merchantId: selectedMerchant.merchant_id,
                    email: userInfo.email,
                    type: 'on-boarding',
                    status
                })
            }
            
            setStripeAccountStatus(status)
            setStripeLink(link)
            setStripeStatus(status)
            setCreateStripeButtonDisabled(false)

        } catch(error) {
            console.error(error)
            dispatch(setAmplitudeEvent('CMS Sell Vouchers page - initializeStripeLink function error', {'details': JSON.stringify(error)}))
        }
    }

    function stripeStatusLogic(stripeAccount) {
        let status
        if (stripeAccount.charges_enabled) {
            // fully on-boarded
            status = STRIPE_STATUS.COMPLETED
            dispatch(setAlert(<span>Great! Stripe account set up has been approved. You may start selling vouchers for ${selectedMerchant.merchant_name} now.</span>, 'success'))
            setButtonLabel('View Stripe Platform')
        } else if (stripeAccount.details_submitted) {
            // completed onboarding, pending stripe verification
            status = STRIPE_STATUS.PENDING_VERIFICATION
            dispatch(setAlert(<span>Stripe set up is pending approval <b>within the next 48 hours.</b> We will alert you when the set up is done.</span>, 'info'))
            setButtonLabel('View Stripe Platform')
        } else if (!stripeAccount.details_submitted) {
            // on-boarding not completed, need to create login link
            status = STRIPE_STATUS.IN_PROGRESS
            dispatch(setAlert(''))
        }
        return status
    }

    function openStripeLink() {
        if (needPrompt) {
            setModalStripePromptStatus(true)
        } else {
            setCreateStripeButtonDisabled(true)
            openInNewTab(stripeLink)
        }
    }

    function closeForm(item, state) {
        setModalStripePromptStatus(state || false)
        setModalSellVoucherFormStatus(state || false)
        setModalImageOverlayStatus(state || false)
        setModalVoucherOutletStatus(state || false)
        
        if (item) {
            setReload(new Date())
        }
    }

    function createVouchers() {
        if (outlets.length===0) {
            dispatch(setAlert(<span>You need to add at least 1 outlet first. Click <a href="/manage-outlets">here</a> to add outlet</span>, 'danger'))
            return
        }
        setModalSellVoucherFormStatus(true)
        setMode('create')
        setSellVoucher({
            terms: SELL_VOUCHERS_TERMS_TEMPLATE_SHOPFRONT,
            is_online:  'False',
            bank: 'DEALS FOR EVERYONE',
            deal_handler: 'exclusive_all',
            cc_buddy_category: selectedMerchant.mainCat,
            ccbuddy_card_name: 'NULL',
            country: 'SGP',
            valid: 'N',
            merchant_id: selectedMerchant.merchant_id,
            merchant_name: selectedMerchant.merchant_name,
            merchant_name_lowercase: selectedMerchant.merchant_name_lowercase,
            mainCat: selectedMerchant.mainCat,
            display_merchant_name: selectedMerchant.merchant_name,
            start_timestamp: getUnixTime(new Date('yyy-MM-dd 00:00')), //Date.now("yyyy-MM-dd 00:00") / 1000 | 0,
            pk: guidGenerator(),
            purchaseLimitPerCustomer: "1",
            purchasable: "1",
            discount: "",
            actualPrice: "",
            discountPrice: "",
            purchasePrice: ""
        })
    }

    function updateVoucher(_item) {
        if (!selectedMerchant.stripeExpressConnectedAccountId) {
            dispatch(setAlert('Cannot edit voucher. Please report to the Admin to resolve your account.', 'danger'))
            dispatch(setAmplitudeEvent('CMS Sell Vouchers page - updateVoucher function error', {'details': 'stripeExpressConnectedAccountId field is missing', ..._item}))
        } else {
            setModalSellVoucherFormStatus(true)
            setMode('update')
            setSellVoucher(_item)
        }
    }

    return (
        <React.Fragment>
            <RBAC merchantId={selectedMerchant.merchant_id} roles={['admin']}>

                <MetaTags>
                    <title>Sell Vouchers</title>
                </MetaTags>


                {
                    loadingStatus ?
                    <Row style={{minHeight:"400px", justifyContent:"center", alignContent:"center"}}>
                        <Spinner />
                    </Row>
                    : stripeAccountStatus !== STRIPE_STATUS.COMPLETED ?
                        <Row className="justify-content-center">
                            <Col xs={12} sm={12} md={6} lg={5} xl={5}>
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardImg
                                                src={sellVoucherStepImg}
                                                top
                                                width="100%"
                                                style={{ objectFit:'contain', marginTop:'20px' }}
                                            />
                                        </Card>
                                    </Col>
                                </Row>
                                            
                                <Row>
                                    <Col>
                                        {[STRIPE_STATUS.PENDING_VERIFICATION, STRIPE_STATUS.IN_PROGRESS].includes(stripeAccountStatus) ?
                                        <div>
                                            <StripeOnboarding
                                                stripeStatus={stripeStatus}
                                                statusColor={statusColor}
                                                disabled={createStripeButtonDisabled}
                                                buttonLabel={buttonLabel}
                                                cta="sell vouchers page"
                                                onClick={() => openStripeLink()}
                                                outline = {true}
                                            />
                                        </div>:

                                        <div>
                                            <StripeOnboarding
                                                stripeStatus={stripeStatus}
                                                statusColor={statusColor}
                                                disabled={createStripeButtonDisabled}
                                                buttonLabel={buttonLabel}
                                                cta="sell vouchers page"
                                                onClick={() => openStripeLink()}
                                                outline = {false}
                                            />
                                        </div>}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    : dataTable.rows.length > 0 ?
                    <Row>
                    <Col className="col-12">
                        <Card>
                        <CardBody>
                        
                                <div style={{ display: "flex", justifyContent: "flex-end", top: "21px", position: "relative" }} >
                                    <a href={VOUCHER_CODE_TEMPLATE_FILE} download><Button style={{marginRight:"8px", zIndex:1000}} color="primary" type="button" size="sm" >Download csv template</Button></a>
                                    <Button color="primary" disabled={canCreateVoucher} type="button" size="sm" onClick={() => createVouchers()}>Add voucher</Button>
                                </div>
                            
                                <MDBDataTable className="sellVouchers" fixed={true} entries={10} displayEntries={false} hover pagesAmount={10} scrollX  data={{ columns: dataTable.columns, rows: dataTable.rows }} noBottomColumns={true} />
                        
                            {downloadingCSV &&
                            <div style={{position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
                            <Spinner/>
                        </div>}
                        </CardBody>
                        </Card>
                    </Col>
                    </Row>
                    :
                    <EmptyState disabled={canCreateVoucher} createVouchers={createVouchers}/> 
                    
                }
                {modalStripePromptStatus ? <StripePrompt cta="sell vouchers page" disableStripeButtonLInk={() => setCreateStripeButtonDisabled(true)} stripeLink={stripeLink} modalStripePromptStatus={modalStripePromptStatus} close={(item, state, type) => closeForm(item, state, type)} /> : null}
                {modalSellVoucherFormStatus ? <SellVoucherForm isNoSellVoucher={isNoSellVoucher} sellVoucher={sellVoucher} mode={mode} modalSellVoucherFormStatus={modalSellVoucherFormStatus} close={(item, state, type) => closeForm(item, state, type)} /> : null}
                {modalImageOverlayStatus ? <ImageOverlay state={modalImageOverlayStatus} caption={sellVoucher.promotion_caption} close={(item, state, type) => closeForm(item, state, type)} imageUrl={sellVoucher.image_url} /> : null}
                {modalVoucherOutletStatus ? <VoucherOutlet sellVoucher={sellVoucher} modalVoucherOutletStatus={modalVoucherOutletStatus} close={(item, state, type) => closeForm(item, state, type)}  /> : null}
            </RBAC>
        </React.Fragment>
    )

}


export default SellVouchers;