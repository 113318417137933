import {
    SET_AMPLITUDE_EVENT
} from './actionTypes';

export const setAmplitudeEvent = (eventName, eventProperties) => ({
    type: SET_AMPLITUDE_EVENT,
    payload: {
        eventName,
        eventProperties
    }
});