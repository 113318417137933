/* eslint-disable react/no-unescaped-entities */
import React from "react"
import { Card, CardBody, CardTitle } from "reactstrap"
import freeTrialPlanImg from '../../../../assets/images/freeTrial.png'
import { FREE_PLAN } from "utils/constants"
import checkSubPlan from '../../../../assets/images/checkSubPlan.png'

const FreeTrialPlan = (props)=> {
   
    return(
        

        <Card className="text-center plan-card-height">
            <div className="mt-5">
            <img style={{ width:'60px', height:'80px', objectFit:'cover' }} src={freeTrialPlanImg}/>
            <div style={{ position: "absolute", right: 0, left:0, top: -20 }}>
                {!props.subPlan || props.subPlan === FREE_PLAN &&
                    <img style={{ height:'45px', width:'45px'}} src={checkSubPlan}/>}
                </div>  
            </div>
            <CardBody>
                <CardTitle><strong>Free Trial</strong></CardTitle>
                <p>Phasing out by 31st Dec 2022</p>

                <div style={{ textAlign:'left' }}>
                    <p>✅ Listing of 1 deal on CardsPal app (limited to 1 brand)</p>
                    <p>✅ Be featured in &apos;Nearby Deals&apos;</p>
                    <p>✅ Be featured in relevant categories and search results</p>
                    <p>✅ Additional shoutout for <strong>new merchants</strong></p>
                    <p>✅ Manage your deal across multiple outlets</p>
                    <p>✅ Be informed of the latest campaigns to engage our users</p>
                </div>
                
            </CardBody>
        </Card>
             
    )
}

export default FreeTrialPlan