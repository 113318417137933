import { API, graphqlOperation } from 'aws-amplify'
import { stripeAccountByMerchantId } from '../../graphql/queries'
import { createCMSUserStripeAccount, updateCMSUserStripeAccount } from '../../graphql/mutations'
import amplitude from 'amplitude-js';

export const createStripeAccount = async (item) => {
    try {
        const existingAccount = await getMerchantStripeAccount(item.merchantId)
        let result

        if (existingAccount) {
            // return await updateStripeAccount(item)
            return existingAccount
        } else {
            result = await API.graphql(graphqlOperation(createCMSUserStripeAccount, { input: item }))
            return result.data.createCMSUserStripeAccount
        }
    } catch (e) {
        amplitude.getInstance().logEventWithGroups('Error - createStripeAccount', { 'details': e, merchantId:  item.merchantId})
        console.error('Error on createStripeAccount', e)
        throw e ? e.message : 'Please contact administrator'
    }
}


export const updateStripeAccount = async (item) => {
    try {
        delete item.email // lets not update the email field for this stripe account cause some admin takes hold of the account to investigate
        const result = await API.graphql(graphqlOperation(updateCMSUserStripeAccount, { input: item }))
        return result.data.updateCMSUserStripeAccount
    } catch (e) {
        amplitude.getInstance().logEventWithGroups('Error - updateStripeAccount', { 'details': e,  merchantId:  item.merchantId })
        console.error('Error on updateStripeAccount', e)
        throw e ? e.message : 'Please contact administrator'
    }
}

export const getMerchantStripeAccount = async (merchantId) => {
    try {
        const result = await API.graphql(graphqlOperation(stripeAccountByMerchantId, { merchantId }))
        return result.data.stripeAccountByMerchantId.items.length > 0 ? result.data.stripeAccountByMerchantId.items[0] : null
    } catch (e) {
        amplitude.getInstance().logEventWithGroups('Error - getMerchantStripeAccount', { 'details': e, merchantId })
        console.error('Error on stripeAccountByMerchantId', e)
        throw e ? e.message : 'Please contact administrator'
    }
}