import { Button, Form, Image } from 'react-bootstrap';
import { Card } from "reactstrap"
import styled from 'styled-components';
import { MdLocationPin } from 'react-icons/md';
import { AiOutlineExclamationCircle } from 'react-icons/ai';

const PriceLabel = styled.p`
font-weight: 700;
font-size: 14px;
text-align: right;
margin-right: 10px;
`
const rowContainer = styled.div`
display: flex;
flex-direction: row;
`

const SubTitle = styled.p`
    color: #0F172A;
    font-weight: 700;
    font-size: 16px;
`

const SubText = styled.div`
    color: #6B6C7E;
    font-weight: 400;
    font-size: 12px;
    margin-bottom:0px;
    padding-bottom:0px;
`
const Caption = styled.p`
    color: #0F172A;
    font-weight: 300;
    font-size: 14px;
`

const Description = styled.div`
    white-space: pre-line;
`
const RowGap15 = styled.div`
    width: 100%;
    border: 0
    margin-top: 15px;
    margin-bottom: 15px;
` 

const Underline = styled.div`
    width: 100%;
    border: 0.5px solid #CDCED9;
    margin-top: 15px;
    margin-bottom: 15px;
`

const HeaderLine = styled.div`
    width: 92%;
    border: 1px solid #f5b940;
    background-color: #f5b940;
    margin-top: 15px;
    margin-inline:15px;
    align-self: center;
    margin-bottom: 15px;
`
const Title = styled.p`
    color: #0F172A;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 0px;
`
const CardContainer = styled(Card)`
    background-color: #f4f4f4;
`
const DateTimeData = styled.p`
    color: #0F172A;
    font-weight: 700;
    font-size: 13px;
    text-align: right;
`
const SeatingInfo = styled.span`
    text-align: right;
    display: block;
`
const TicketType = styled.p`
    color: #0F172A;
    font-weight: 700;
    font-size: 13px;
    text-align: end;
`
const ErrMsg = styled.p`
    color: red;
    margin: 0;
`
const Disclaimer = styled.p`
    font-size: 10px;
    margin: 0;
`
const Sup = styled.sup`
    color: purple;
`
const SubmitButton = styled(Button)`
    width: 85%;
    height: 50px;
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: #7a6fbe !important
`

const SearchButton = styled(Button)`
    width: 100%;
    height: 50px;
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: #7a6fbe !important
`

const DeclineButton = styled(Button)`
    width: 85%;
    height: 50px;
    margin-top: 30px;
    color: red;
    border-color: transparent;
    background: transparent !important;
    border-color: transparent !important;

    &:disabled {
        background: transparent !important;
        border-color: transparent !important;
        color: #531DAB;
    }
    
    &:hover {
        color: #fff;
        background-color: #7a6fbe !important;
    }
  
`

const Terms = styled.div`
    margin-left: 20px;
    margin-right: 12px;
    margin-bottom: 12px;
    .form-check [type=checkbox] {
    transform: scale(1.5);
    border-color: #531DAB;
    border-width: 0.9px;
  }
  label {
    margin-left: 10px;
  }
`
const TimerDigit = styled.div`
    background-color: #000;
    border: 1px solid;
    border-radius: 5px;
    width: 22px;
    height: 22px;
    margin-right: 3px;
    p {
        text-align: center;
        color: #fff;
        font-size: 12px;
    }  
`
const TimerLabel = styled.p`
    font-size: 8px;
    text-align: center;
`
const SpinnerDiv = styled.div`
    position: fixed;
    z-index: 99;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`
const GMap = styled.div`
    height: 35vh;
    margin: 3vh;
        
`
const MapWrapper = styled.div`
    alignSelf: 'center'
`

const MapIcon = styled(MdLocationPin)`
  color: #722ED1;
  font-size: 4rem;
  cursor: pointer;
`

const MapH2 = styled.h2`
text-transform: uppercase;
font-size: 1rem;
padding: 20px;
padding-left: 10px;
text-align: center;
`

const MapPin = styled.div`
display: flex;
align-items: center;
width: 180px;
color: var(--main-blue);
transform: translate(-14%, -50%);
`

const MapPinText = styled.div`
font-size: 1.1em;
white-space: pre-line;
`
const boxStyling = {background:"#FFFFFF", boxShadow: "2px 4px 12px rgba(0, 0, 0, 0.15)", borderRadius: "14px", padding:"4%"}
const boxStylingNoPadding = {background:"#FFFFFF", boxShadow: "2px 4px 12px rgba(0, 0, 0, 0.15)", borderRadius: "14px", padding:"2%"}

const P  = styled.p`
  color: red;
  margin: 0;
`

const ToolTipIcon = styled(AiOutlineExclamationCircle)`
  color: #722ED1;
  font-size: 1rem;
  cursor: pointer;
`

const GroupP  = styled.p`
  color: #3672F8;
  line-height: 1.25rem;
  margin: 0;
  margin-top: 5px;
`

const BtnOtp = styled.button `
    background-color: #722ED1 !important;  
    color: #fff;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 5;
    border: 0;
    width: 80px;

    &:disabled {
        background-color: #7A6FBE !important;
    }
`

const BtnVerify = styled.button `
    background-color: ${props => props.verified ? "#2AD2C9" : "#722ED1"} !important;  
    border-color: ${props => props.verified ? "#2AD2C9" : "#722ED1"} !important;
    color: #fff;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 5;
    border: 0;
    width: 80px;
`

export {
    Title, SubTitle, Underline, HeaderLine, CardContainer, DateTimeData, SeatingInfo, SpinnerDiv, Caption,
    TicketType, ErrMsg, Sup, SubmitButton, Terms, TimerDigit, TimerLabel, DeclineButton, GMap, MapWrapper, MapIcon, MapH2, MapPin, MapPinText, Description, Disclaimer, PriceLabel, rowContainer, boxStyling, boxStylingNoPadding,
    SubText, P, ToolTipIcon, GroupP, RowGap15, BtnOtp, BtnVerify, SearchButton
}