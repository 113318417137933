import React from "react"
import { Card, CardBody, Button , Spinner} from "reactstrap"
import { useSelector, useDispatch } from "react-redux";
import {Tooltip, OverlayTrigger} from 'react-bootstrap';
// import store actions
import { setAmplitudeEvent } from '../../store/actions'

const boxWidget = props => {
  const dispatch = useDispatch()
  const selectedMerchant = useSelector(state => state.Merchant.selectedMerchant)

  const details = props.details

  const tooltip = (text) => (
    <Tooltip id="tooltip">
      <strong>{text}</strong> 
    </Tooltip>
  );

  function handleOnClick(route) {
    track()
    props.history.push(route)
  }

  function track() {
    let _ampTrack = {
      'cta location': props.cta,
      'merchant id': selectedMerchant.merchant_id,
      'merchant name':  selectedMerchant.merchant_name,
      'mainCat': selectedMerchant.mainCat,
      ...props.others
    }
    dispatch(setAmplitudeEvent(`CMS ${screen} page - ${details.buttonLabel} button`, {..._ampTrack}))
  }

  return (
    <React.Fragment>
        <Card className="mini-stat" style={{backgroundColor: details.bgColor, height:"90%"}}>
          <CardBody className="card-body mini-stat-img">
            <div className="mini-stat-icon">
              <i className={"float-end mdi mdi-" + details.iconClass}></i>
            </div>
            <div className="text-white">
              <h6 className="text-uppercase mb-3 font-size-16 text-white" style={{minHeight:"38px"}}>{details.title}</h6>
              <h2 className="mb-4 text-white">{details.total===null ? <Spinner size="xs" /> : details.total}</h2>
              {
                props.hideButton ? null
              :
                <Button onClick={() => handleOnClick(details.link)} className="highlight boxWidget-buttoon" outline >{details.buttonLabel}</Button>
              }
              {
                !props.tooltip ? null
              :
              <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end"}}>
                <OverlayTrigger placement="right" overlay={tooltip(props.tooltip)}>
                  <i style={{fontSize:"2em"}} className="highlight mdi mdi-information"></i>
                </OverlayTrigger>
              </div>
              }
            </div>
          </CardBody>
        </Card>
    </React.Fragment>
  )

}

export default boxWidget
