import React, { useState, useEffect, Suspense, lazy } from 'react'
import { remoteConfig, getHappeningOnFullInfo } from 'utils';
import { getDealInfo, reserveVoucher, getEnv, getVoucherGroupDeals, getEvents } from 'helpers/apiHelper';
import AccessDenied from 'components/Common/accessDenied';
import amplitude from 'amplitude-js';
import EventTicket from "../../components/Common/EventTicket_template"
import styled from "styled-components"
import { Row, Col, Container, Spinner } from "reactstrap"
import { Image } from 'react-bootstrap';
import FooterImage from '../../assets/images/PoweredByFooter.png'
import shoppingPallyImg from '../../assets/images/shoppingPally.png'
import { Button } from "reactstrap"

import { SpinnerDiv } from './Components/Styled';
const LazyBanner = React.lazy(() => import('./Components/Banner'))
import ReactPixel from 'react-facebook-pixel';
import { getUTMParams, getUTMQueryString } from 'helpers/urlHelper';
import parse from 'html-react-parser'

const ExternalComponent = (props) => {

    const utmParams = getUTMParams(location);
    const utmQueryString = getUTMQueryString(utmParams)

    const eventUniqueString = props.match.params.eventUniqueString;
    const dealId = props.match.params.dealId
    const webformId = props.match.params.webFormId
    const imagePath = `https://assets.${getEnv()}.cardspal.com/public/Events/banner/`
    const faviconPath = `https://assets.${getEnv()}.cardspal.com/public/Events/favicon/`

    const [isBusy, setIsBusy] = useState(false)
    const [dealInfo, setDealInfo] = useState([])
    const [tickets, setTickets] = useState([])
    const [eventInfo, setEventInfo] = useState([])
    const [isAccessDenied, setAccessDenied] = useState(null);
    const [happeningOn, setHappeningOn] = useState('')
    const [invalid, setInvalid] = useState(true)
    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    })
    const [custom, setCustom] = useState([])
    const [scrollPosition, setScrollPosition] = useState(0);

    const descriptionStyling = { fontFamily: 'Rubik', fontStyle: "normal", fontWeight: "400", fontSize: "18px", lineHeight: "15px", color: "#394452", marginTop: "3vh", marginLeft: "2vh", marginBottom: "1vh", maxWidth: "100%" }

    const getCustomSettings = async (event) => {
        let cust = await remoteConfig("b2bCustom")
        if (cust._value) {
            let parseCustJson = JSON.parse(cust._value)
            setCustom(parseCustJson[event?.pk])
            return (parseCustJson[event?.pk])
        } else return null
    }

    const MOBILE_WIDTH = 768

    const handleResize = () => {
        let adjustment = window.document.body.offsetHeight
        setDimensions({
            height: adjustment,
            width: window.innerWidth
        })
        window.parent.postMessage({ cpHeight: adjustment }, '*')
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)

        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        // link.href = `${faviconPath}${eventId}_favicon.ico`;

        return _ => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(async () => {
        setIsBusy(true)
        await loadEventDeal()
    }, [])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const backToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    const loadEventDeal = async () => {
        let eventData = await getEvents(eventUniqueString, 'events_unique_string')
        let event = eventData?.data?.events
        setEventInfo(event)

        let customData = await getCustomSettings(event)


        if (customData?.fbPixel) {
            const advancedMatching = {};
            const options = {
                autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
                debug: true, // enable logs
            };
            ReactPixel.init(customData.fbPixel, advancedMatching, options);
            ReactPixel.pageView(); // For tracking page view
        }


        let VoucherGroupDeals = await getVoucherGroupDeals(event?.pk, "events_id")

        let eventDealIds = VoucherGroupDeals?.data?.map(item => item.dealId)

        if (event?.pk) {
            let _datetime = getHappeningOnFullInfo(event?.startTimestamp, event?.endTimestamp)
            setHappeningOn(_datetime)
            if (eventDealIds && eventDealIds.length > 0) {
                let ticketList = await Promise.all(eventDealIds.map(async (item, index) => {
                    let deal = await getDealInfo(item)
                    let dealCustom = customData?.dealInfo[deal.data.pk]
                    let customPromo = dealCustom?.promoText
                    deal?.data ? deal.data.dealLink = `/event/${event?.uniqueString}/item/${deal?.data?.uniqueString}?${utmQueryString}` : null
                    if (deal?.data?.valid == "Y")
                        return <EventTicket key={`ticket-${index}`} deal={deal?.data} bundle={dealCustom?.type === 'bundle'} promotion={false} issueQty={dealCustom?.issueQty ?? 1} customPromoText={dealCustom?.customPromoText ?? ""} />
                    else
                        return null
                }))
                ticketList = ticketList.filter(item => item !== null)
                setTickets(ticketList)
            }
            setAccessDenied(false)
        } else {
            setAccessDenied(true)
        }
        setIsBusy(false)
        setInvalid(false)
    }

    const loadTickets = (tickets) => {
        let render = []
        for (let x = 0; x < tickets.length; x += 2) {
            x + 1 === tickets.length ?
                render.push(<Row key={"tixRow-" + x} ><Col xs={12} sm={12} md={6} lg={6} xl={6}>{tickets[x]}</Col><Col></Col></Row>)
                :
                render.push(<Row key={"tixRow-" + x}><Col style={{ marginBottom: "2%" }} xs={12} sm={12} md={6} lg={6} xl={6}>{tickets[x]}</Col><Col style={{ marginBottom: "2%" }} xs={12} sm={12} md={6} lg={6} xl={6}>{tickets[x + 1]}</Col></Row>)
        }
        return render
    }

    return (
        <React.Fragment>
            {isBusy ? <SpinnerDiv><Spinner /></SpinnerDiv> :
                (isAccessDenied === null) || invalid ? <></> :
                    (isAccessDenied ? <AccessDenied screen="B2B" showBackToDashboard={false} /> :
                        <>
                            <BannerImageContainer>
                                <Suspense fallback={<SpinnerDiv><Spinner /></SpinnerDiv>}>
                                    <LazyBanner imagePath={eventInfo?.eventBannerUrl} custom={custom} />
                                </Suspense>
                            </BannerImageContainer>
                            <FrameContainer id='cpframeContainer' >
                                <BannerContainer id="cpBannerContainer">

                                    <Row>
                                        {dimensions.width > MOBILE_WIDTH ?
                                            <>
                                                <Col className='col-8'>
                                                    <Title mobileWidth={MOBILE_WIDTH}> {eventInfo?.name} </Title>
                                                    <Caption mobileWidth={MOBILE_WIDTH}> {happeningOn} </Caption>
                                                </Col>
                                                <Col className='col-4'></Col>
                                            </>
                                            :
                                            <>
                                                <Col >
                                                    <Title mobileWidth={MOBILE_WIDTH}> {eventInfo?.name} </Title>
                                                    <Caption mobileWidth={MOBILE_WIDTH}> {happeningOn} </Caption>
                                                </Col>
                                            </>
                                        }

                                    </Row>
                                    <Divider></Divider>
                                </BannerContainer>
                                <Container fluid style={{ marginTop: 0, flexDirection: 'row', flexGrow: 1 }}>
                                    {loadTickets(tickets)}
                                </Container>
                                {eventInfo?.description ?
                                    <div style={{ maxWidth: "100%" }}>
                                        <Description style={descriptionStyling ?? {}}>{parse(eventInfo?.description)}</Description>
                                    </div>
                                    : null}
                                <FooterContainer id="cpFooterContainer" >
                                    <Image onLoad={handleResize} fluid style={{ minHeight: "3vh", maxHeight: "5vh", marginBottom: "5vh" }} src={FooterImage} />
                                </FooterContainer>
                            </FrameContainer>
                            {custom?.customFooter &&
                                <>
                                    <Row className="justify-content-center mt-5" display={{ backgroundColor: "#F7F8F9" }}>Our Partners</Row>
                                    <Row className="justify-content-center mt-3 mx-3">
                                        <Col className="col-sm-6 pd-3" align="center">
                                        <Image style={{ maxHeight: "10vh", marginBottom: "3vh" }} fluid src={imagePath + eventInfo?.pk + "_footer.png"} />
                                        </Col>
                                    </Row>
                                </>
                            }
                            {scrollPosition > 200 ?
                            <PallyImage>
                                <Image onLoad={handleResize} onClick={() => backToTop()} fluid style={{ height: "70px", objectFit: "contain" }} src={shoppingPallyImg} />
                                <Button onClick={() => backToTop()} style={{ borderRadius: 12 }} className="btn-purple">Buy now!</Button>
                            </PallyImage>
                                : null
                            }
                        </>
                    )
            }
        </React.Fragment>

    )
}

const FrameContainer = styled.div`
        display: flex;
        flex-direction: column;
        background-color: white;
        height:100%;
        padding:2%;
        padding-left:5%;
        padding-right:5%;
        padding-bottom:1%;
        flex:1;
    `
const FooterContainer = styled.div`
        margin-top:5%;
        padding-left: 1%;
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:center;

    `
const BannerContainer = styled.div`
        display: inline-block;
        margin-top: 1%;
        padding-top: 1%;
        padding-bottom: 1%;
        padding-left: 2%;
        padding-right: 2%;
        width: 100%;
        cursor: pointer;
    `

const Title = styled.div`
        font-size: 3vw;
        color:black;
        font-weight:600;
        font-style:normal;
        font-family:Rubik;
        @media (max-width: ${props => props.mobileWidth}px) {
            text-align:center;
            font-size: 4.5vw;
        }
    `
const Caption = styled.div`
        font-size: 1.8vw;
        color:black;
        font-weight:400;
        font-style:normal;
        font-family:Rubik;
        @media (max-width: ${props => props.mobileWidth}px) {
            text-align:center;
            font-size: 4vw;
        }
    `
const Divider = styled.div`
        color: #E7E7ED;
        background-color: #E7E7ED;
        height: 5px;
        width: 100%;
        margin-bottom:5%;
        margin-top:3%;
    `
const Description = styled.div`
    white-space: pre-line;
`
const PallyImage = styled.div`
    position: fixed;
    display:flex;
    flex-direction:column;
    bottom: 40px;
    right: 25px;
    z-index: 20;
`
const BannerImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export default ExternalComponent;