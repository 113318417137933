import React, { useEffect, useState, useRef } from "react"
import MetaTags from 'react-meta-tags';
import { connect, useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, CardImg, Spinner, Button, CardBody} from "reactstrap"
import {InputGroup, Form} from 'react-bootstrap';
import Select from 'react-select'
import { MDBDataTable } from "mdbreact"
import { fromUnixTime, format } from 'date-fns';
import { BsFilter } from 'react-icons/bs';

import RBAC from '../../components/Common/rbac'

// import components
import StripePrompt from "components/Common/stripePrompt";
import StripeOnboarding from "components/Common/stripeOnboarding";
import BoxWidget from "components/Common/boxWidget";
import EmptyState from  "./Components/emptyState"

// import utils
import { evaluateStripeStatus, openInNewTab, computeSoldVouchers, convertToCSV } from '../../utils'
import  {getStripeStatusColor} from '../../utils/stripe'
import { STRIPE_STATUS, GENERIC_ERROR_MESSAGE, STRIPE_BUTTON_LABEL, DEFAULT_REFRESH_URL, DEFAULT_RETURN_URL } from '../../utils/constants'

//import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems, setAlert, setAmplitudeEvent,  setConfig, setEnv } from "../../store/actions";

// import helpers
import { stripeCreateOnboardingLink, stripeRetrieveKey, stripeRetrieveAccount, stripeCreateLoginLink, getConfig, getEnv } from '../../helpers/apiHelper'
import { getMerchantStripeAccount, updateStripeAccount, createStripeAccount } from "../../helpers/GraphQL/userStripeAccount"
import { getSalesSummaryDealsList, getDealById } from '../../helpers/GraphQL/promotions'
import { listVouchersByDealId } from '../../helpers/GraphQL/voucherMaster'
import { getRedeemVouchersByDeal } from  '../../helpers/GraphQL/voucherUser'
import { getTotalRevenueByDeal,getTotalDiscountByDeal } from  '../../helpers/GraphQL/transactions'
import { getCheckinTickets } from "helpers/GraphQL/checkinTicket"
import { getScanHistoryByVoucherId} from "helpers/GraphQL/scanHistory"
import { getTransactionById, getAllTransactionsByDealIds } from 'helpers/GraphQL/transactions';

// import assets
import sellVoucherStepImg from '../../assets/images/sellVoucherStepsC.png'



const SalesSummary = (props) => {
    const dispatch = useDispatch()
    const selectedMerchant = useSelector(state => state.Merchant.selectedMerchant)
    const cmsConfig = useSelector(state => state.Config)
    const userInfo = useSelector(state => state.User.user)

    const [stripeKey, setStripeKey] = useState(null)
    const [stripeLink, setStripeLink] = useState()
    const [stripeAccountStatus, setStripeAccountStatus] = useState('')
    const [modalStripePromptStatus, setModalStripePromptStatus] = useState(false)

    const [totalRevenue, setTotalRevenue] = useState(null)
    const [soldVouchers, setSoldVouchers] = useState(null)
    const [totalDiscount, setTotalDiscount] = useState(null)
    const [redeemVouchers, setRedeemVouchers] = useState(null)
    const [stripeChecking, setStripeChecking] = useState(true)
    const [refreshUrl, setRefreshUrl] = useState(null)
    const [returnUrl, setReturnUrl] = useState(null)
    const [needPrompt, setNeedPrompt] = useState(null)
    const [createStripeButtonDisabled, setCreateStripeButtonDisabled] = useState(true)
    const [stripeButtonLabel, setStripeButtonLabel] = useState('')
    const [stripeAccount, setStripeAccount] = useState({})
    const [dealVouchers, setDealVouchers] = useState(null)
    const [selectedDealVoucher, setSelectedDealVoucher] = useState({})
    const [checkins, setCheckins] = useState(null)
    const [isEvent, setIsEvent] = useState(false)
    
    const EVENTS_DEAL_TAGS = {
        'events-ticket': 'eventsticket',
        'events-fnb': 'eventsfnb'
      }

    const breadcrumbItems = [
        { title: "CardsPal", link: "#" },
        { title: selectedMerchant.display_merchant_name, link: "#" },
        { title: "Tools", link: "#" },
        { title: "Sell Summary", link: "#" },
    ]

    const dataContainer = useRef(null)

    const data = {
        columns: [
            {
                label: "ID",
                field: "id",
                width: 40,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Transaction ID",
                field: "transactionId",
                width: 80,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Transaction Date and Time",
                field: "transactionDateTime",
                width: 100,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Voucher",
                field: "voucher",
                width: 70,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Discount Code",
                field: "discountCode",
                width: 70,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Discounted Value",
                field: "discountValue",
                width: 70,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Status",
                field: "status",
                width: 70,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Redemption",
                field: "redemption",
                sort: "disabled",
                width: 100,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            },
            {
                label: "Redeemed by",
                field: "redeemedBy",
                sort:'disabled',
                width: 80,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            },
            {
                label: "Redemption date and time",
                field: "redeemDateTime",
                sort: 'disabled',
                width: 100,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            },
            {
                label: "Check-in",
                field: "checkIn",
                sort: 'disabled',
                width: 80,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "First Name",
                field: "firstName",
                sort: 'disabled',
                width: 70,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            },
            {
                label: "Last Name",
                field: "lastName",
                sort: 'disabled',
                width: 80,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            },
            {
                label: "Email Address",
                field: "email",
                sort: 'disabled',
                width: 180,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            },
            {
                label: "Mobile Number",
                field: "mobile",
                sort: 'disabled',
                width: 70,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            }
        ],
        rows: []
    }

    const dataEvent = {
        columns: [
            {
                label: "ID",
                field: "id",
                width: 40,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Transaction ID",
                field: "transactionId",
                width: 80,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Transaction Date and Time",
                field: "transactionDateTime",
                width: 100,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Voucher",
                field: "voucher",
                width: 70,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Discount Code",
                field: "discountCode",
                width: 70,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Discounted Value",
                field: "discountValue",
                width: 70,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Confimation ID",
                field: "confirmationId",
                width: 100,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Status",
                field: "status",
                width: 70,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Redemption",
                field: "redemption",
                sort: "disabled",
                width: 100,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            },
            {
                label: "Redeemed by",
                field: "redeemedBy",
                sort:'disabled',
                width: 180,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            },
            {
                label: "Redemption date and time",
                field: "redeemDateTime",
                sort: 'disabled',
                width: 100,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            },
            {
                label: "Check-in",
                field: "checkIn",
                sort: 'disabled',
                width: 80,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "First Name",
                field: "firstName",
                sort: 'disabled',
                width: 70,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            },
            {
                label: "Last Name",
                field: "lastName",
                sort: 'disabled',
                width: 70,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            },
            {
                label: "Email Address",
                field: "email",
                sort: 'disabled',
                width: 180,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            },
            {
                label: "Mobile Number",
                field: "mobile",
                sort: 'disabled',
                width: 70,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            }
        ],
        rows: []
    }

    const dataEventNoCheckin = {columns:[...dataEvent.columns.slice(0,11)], rows:[...dataEvent.rows]}
    const dataNoCheckin = {columns:[...data.columns.slice(0,10)], rows:[...data.rows]}

    const [dataTable, setDataTable] = useState(data)
    const [mainDataTable, setMainDataTable] = useState(data)
    const [downloadingCSV, setDownloadingCSV] = useState(false)
    //  const [saleDateFrom, setSaleDateFrom] = useState(format(new Date(),"yyyy-MM-dd"));
    //  const [saleDateTo, setSaleDateTo] = useState(format(new Date(),'yyyy-MM-dd'));
    const [saleDateFrom, setSaleDateFrom] = useState("");
    const [saleDateTo, setSaleDateTo] = useState("");
    const [redemptionDateFrom, setRedemptionDateFrom] = useState("");
    const [redemptionDateTo, setRedemptionDateTo] = useState("");
    const [dataLoading, setDataLoading] = useState(false)


    useEffect(async () => {
        props.setBreadcrumbItems('Sales Summary', breadcrumbItems)
    }, [selectedMerchant])

    useEffect(async () => {
        dispatch(setAlert('Initializing...Please wait while we finish getting updates', 'info'))
        const key = await stripeRetrieveKey()
        if (!key) {
            dispatch(setAlert(`Error loading this page. Please report to admin immediately.`, 'danger'))
            return
        } else {
            setStripeKey(key.stripeSecret)
        }
    }, [])

    useEffect(async () => {
        await getCMSConfig()
    }, [])

    // initialize if stripe has been on-boarded before
    useEffect(async () => {
        if (!stripeKey || !selectedMerchant || !cmsConfig.cmsv2) return 
    
        setStripeChecking(true)
        let _stripeAccount = await getMerchantStripeAccount(selectedMerchant.merchant_id)
        
        if (_stripeAccount && Object.keys(_stripeAccount).length!==0) {
            setStripeAccount(_stripeAccount)
            if (_stripeAccount.status !== STRIPE_STATUS.COMPLETED) {
                await retrieveStripeLink(_stripeAccount, stripeKey)
            } else {
                await retrieveStripeLink(_stripeAccount, stripeKey)
            }
        } else {
            // first time 
            _stripeAccount = await createStripeAccountLink(stripeKey)
            setStripeAccount(_stripeAccount)
        }

        setCreateStripeButtonDisabled(false)
        setStripeChecking(false)
        dispatch(setAlert(''))
    }, [selectedMerchant, stripeKey])

    // retrieve all vouchers
    useEffect(async () => {
        try {
            if (!selectedMerchant.merchant_id) return
            
            const tempPurchasableDeals = []

            const dealIds = []

            // get purchasable deals that are valid only
            const purchasableDeals = await getSalesSummaryDealsList(selectedMerchant.merchant_id)

            for (let i=0; i < purchasableDeals.length; i++) {
                const deal = purchasableDeals[i]
                
                if (dealIds.indexOf(deal.pk) === -1) {
                    dealIds.push(deal.pk) // collect only unique deal ids
                    tempPurchasableDeals.push(deal)
                }
            }

            const massageDealVouchers = tempPurchasableDeals.map((item, index) => {
                return {
                    value: item.pk,
                    label: `${index+1} - ${item.promotion_caption}`,
                    caption: item.promotion_caption
                }
            })

            setDealVouchers(massageDealVouchers)
            setSelectedDealVoucher({...massageDealVouchers[0]})

            let _ampTrack = {
                'empty': massageDealVouchers.length===0 ? 'Y' : 'N',
                'merchant id': selectedMerchant.merchant_id,
                'merchant name':  selectedMerchant.merchant_name,
                'mainCat': selectedMerchant.mainCat,
                'deal id': massageDealVouchers.length===0 ? '' :massageDealVouchers[0].pk,
                'promo title': massageDealVouchers.length===0 ? '' :massageDealVouchers[0].promotion_caption
            }
            dispatch(setAmplitudeEvent('CMS Sales Summary page - page load', {..._ampTrack}))
        } catch(e) {
            console.error(e)
            dispatch(setAmplitudeEvent('CMS Sales Summary page - retrieve all vouchers error', {'details': JSON.stringify(e)}))
            
        }
    }, [selectedMerchant])

    // get config from s3 in case its not retrieve or lost from the redux store
    async function getCMSConfig() {
        try {
          if (!cmsConfig || !cmsConfig.cmsv2) {
    
            const config = await getConfig()
            const env = getEnv()
            
            setRefreshUrl(cmsConfig.cmsv2.stripeVariables.refreshUrl || DEFAULT_REFRESH_URL)
            setReturnUrl(cmsConfig.cmsv2.stripeVariables.returnUrl || DEFAULT_RETURN_URL)
            dispatch(setConfig(config))
            dispatch(setEnv(env))
          } 
          setRefreshUrl(cmsConfig.cmsv2.stripeVariables.refreshUrl || DEFAULT_REFRESH_URL)
          setReturnUrl(cmsConfig.cmsv2.stripeVariables.returnUrl || DEFAULT_RETURN_URL)
        } catch(e) {
          // TODO: we might need to re-route user or ask the user to refresh
          console.error('error on retrieving config file', e)
          dispatch(setAmplitudeEvent('CMS Sales Summary page - cms getConfig error', {'details': JSON.stringify(e)} ))
        }
    }

    // query totals based on voucher code
    useEffect(async () => {
        try {
            if (!selectedDealVoucher || Object.keys(selectedDealVoucher).length===0) return 

            setIsEvent(false)
            // get total revenue
            const dealId = selectedDealVoucher.value
            setTotalRevenue(await getTotalRevenueByDeal(dealId))
            setTotalDiscount(await getTotalDiscountByDeal(dealId))

            // get sold vouchers
            const allVoucherCodes = await listVouchersByDealId(dealId)
            const {sold, total} = computeSoldVouchers(allVoucherCodes)
            setSoldVouchers(`${sold}/${total}`)

            // get total redeem vouchers
            const redeem = await getRedeemVouchersByDeal(dealId)
            setRedeemVouchers(redeem.length)

            // set Checkins
            const checkins = await getCheckinTickets(dealId)
            // const checkins = checkinsList && checkinsList?.length>0 ? checkinsList.filter(ele=> ele.dealId=== dealId) : []
            setCheckins(checkins.length)

            // Show Summary
            let count = 0
            let dataRows = []
            const vouchers =  await listVouchersByDealId(dealId)
            const selectedDeal = await getDealById(dealId)
            const checkIsEvent = (selectedDeal?.TR_tags?.indexOf(EVENTS_DEAL_TAGS['events-ticket']) >= 0) 
            setIsEvent(checkIsEvent)
            const dataType = checkIsEvent ? checkins.length > 0 ? dataEvent : dataEventNoCheckin : checkins.length > 0 ? data : dataNoCheckin

            await Promise.all(vouchers.map(async (voucher) => {
              try {
                const redeemedBy = await getScanHistoryByVoucherId(voucher.id)  ?? []//change to scanHistory Name
                const redeemed = redeem.find(item => item.code === voucher.code)         
                const checkedIn = checkins.find(item => item.masterId === voucher.id)
                const transactionDetails = voucher.transactionId ? await getTransactionById(voucher.transactionId) : null
                dataRows.push({
                    id: ++count,
                    transactionId: voucher.transactionId ?? "-",
                    transactionDateTime: transactionDetails ? format(fromUnixTime(Number(transactionDetails.timestamp)) , "dd/MM/yyyy, HH:mm:ss"): '-',
                    confirmationId: redeemedBy.length > 0 ? "#"+ redeemedBy[0].voucherId.substring(redeemedBy[0].voucherId.length-8) :voucher.transactionId ? '#' + voucher.id.substring(voucher.id.length-8) : '-',
                    voucher: voucher.code,
                    discountCode: transactionDetails?.discountCode ?? '-',
                    discountValue: transactionDetails?.extraDiscount ? "$"+transactionDetails?.extraDiscount : '-',
                    status: voucher.status==='0'?  <div style={{color:'#58DB83', textAlign:"center"}} value='Active'>Active</div>: <div style={{color:'#EC536C', textAlign:"center"}} value='Sold'>Sold</div>,
                    redemption: redeemedBy.length > 0  ? <div style={{color:'#EC536C'}} value='Redeemed'>Redeemed</div>:redeemed ? <div style={{color:'#EC536C'}} value='Redeem'>Redeemed</div>:<div style={{color:'#58DB83'}} value='Unused'>Unused</div>,
                    redeemedBy: redeemedBy.length > 0 ? redeemedBy[0].createdBy : redeemed? 'User' : '-',
                    redeemDateTime: redeemedBy.length > 0 ? (new Date(redeemedBy[0].createdAt).toLocaleString()) : redeemed? format(fromUnixTime(Number(redeemed.redeemTimestamp)) , "dd/MM/yyyy, HH:mm:ss"):'-',
                    checkIn: checkedIn ? 'Yes' : 'No',
                    firstName: checkedIn ? checkedIn.firstName : '-',
                    lastName: checkedIn ? checkedIn.lastName : '-',
                    email: checkedIn ? checkedIn.email : '-',
                    mobile: checkedIn ? checkedIn.mobile : '-',
                })
              } catch (error) {
                console.log('error'+ error);
              }
            }))          
            dataType.rows = dataRows
            //console.log(dataType)
            setDataTable({...dataType})
            setMainDataTable({...dataType})

            let _ampTrack = {
                'merchant id': selectedMerchant.merchant_id,
                'merchant name':  selectedMerchant.merchant_name,
                'mainCat': selectedMerchant.mainCat,
                'deal id': selectedDealVoucher.pk,
                'promo title': selectedDealVoucher.promotion_caption
            }
            dispatch(setAmplitudeEvent('CMS Sales Summary page - drop down menu clickthrough', {..._ampTrack}))
        } catch(e) {
            dispatch(setAlert(e, 'danger'))
            dispatch(setAmplitudeEvent('CMS Sales Summary page - get voucher totals error', {'details': JSON.stringify(e)}))
        }
    }, [selectedDealVoucher])

    useEffect( () => {

        let sDateFrom = saleDateFrom ?  new Date(saleDateFrom) : null
        let sDateTo = saleDateTo ? new Date(saleDateTo) : null

        if(sDateFrom || sDateTo){
            setDataLoading(true)
            let dataRows = mainDataTable.rows
            dataRows = dataRows.filter((e) => {
                if(e.transactionDateTime && e.transactionDateTime != '-'){
                    let format = e.transactionDateTime.substring(6,10)+"-"+e.transactionDateTime.substring(3,5)+"-"+e.transactionDateTime.substring(0,2)
                    let tDate = new Date(format)
                    if (sDateFrom && sDateTo)
                        return (tDate >= sDateFrom) && (tDate <= sDateTo)
                    else if(sDateFrom)
                        return tDate >= sDateFrom
                    else if(sDateTo)
                        return tDate <= sDateTo
                }
                    
            })
            dataTable.rows = dataRows
            setTimeout(() => {
                setDataTable({...dataTable})
              }, 500);
        }else{
            setDataLoading(true)
            setDataTable({...mainDataTable})
        }
    },[saleDateFrom, saleDateTo])

    useEffect( () => {
        let rDateFrom = redemptionDateFrom ?  new Date(redemptionDateFrom) : null
        let rDateTo = redemptionDateTo ? new Date(redemptionDateTo) : null

        if(rDateFrom || rDateTo){
            setDataLoading(true)
            let dataRows = mainDataTable.rows
            dataRows = dataRows.filter((e) => {
                if(e.redeemDateTime && e.redeemDateTime != '-'){

                    let format = e.redeemDateTime.substring(6,10)+"-"+e.redeemDateTime.substring(3,5)+"-"+e.redeemDateTime.substring(0,2)
                    let rDate = new Date(format)
                    //console.log(rDate)
                    if (rDateFrom && rDateTo)
                        return (rDate >= rDateFrom) && (rDate <= rDateTo)
                    else if(rDateFrom)
                        return rDate >= rDateFrom
                    else if(rDateTo)
                        return rDate <= rDateTo
                }
                    
            })
            dataTable.rows = dataRows
            setTimeout(() => {
                setDataTable({...dataTable})
              }, 500);
        }else{
            setDataLoading(true)
            setDataTable({...mainDataTable})
        }
    },[redemptionDateFrom, redemptionDateTo])


    useEffect(() => {
        setDataLoading(false)
    },[dataTable])


    async function retrieveStripeLink(_stripeAccount, stripeKey) {
        try {
            const stripeInfo = await stripeRetrieveAccount({ stripeKey, accountId: _stripeAccount.accountId })
            let status
            let link
            let btnLabel
        
            if (!stripeInfo.charges_enabled) {
                
                status = _stripeAccount.status
                const stripeInfo = await stripeCreateOnboardingLink({ email: userInfo.email, 
                    stripeKey, 
                    refreshUrl, 
                    returnUrl, 
                    merchantId: selectedMerchant.merchant_id, 
                    accountId: _stripeAccount.accountId 
                })
                link = stripeInfo.accountLink.url
                setStripeButtonLabel(STRIPE_BUTTON_LABEL.CONNECT_TO_STRIPE)
                setNeedPrompt(true)
            } else {
                ({status, btnLabel} = await evaluateStripeStatus(stripeInfo))
                
                const stripeLoginLink = await stripeCreateLoginLink({ stripeKey, accountId: _stripeAccount.accountId })
                link = stripeLoginLink.url
                setStripeButtonLabel(btnLabel)
                setNeedPrompt(false)
            }
            setStripeAccountStatus(status)
            setStripeLink(link)
        
            // update user stripe db record
            await updateStripeAccount({
                accountId: _stripeAccount.accountId,
                merchantId: selectedMerchant.merchant_id,
                email: userInfo.email,
                type: 'on-boarding',
                status
            })
        } catch(e) {
            console.error('error on retrieveStripeLink', e)
            dispatch(setAlert(`${GENERIC_ERROR_MESSAGE}[Stripe link retrieval issue]`, 'danger'))
            dispatch(setAmplitudeEvent('CMS Sales Summary page - retrieve stripe link error', {'details': JSON.stringify(e)}))
        }
    }
    
    async function createStripeAccountLink(stripeKey) {
        // first time to on-board to stripe
        
        const stripeInfo = await stripeCreateOnboardingLink({ email: userInfo.email, stripeKey, refreshUrl, returnUrl, merchantId: selectedMerchant.merchant_id })

        setStripeAccountStatus(STRIPE_STATUS.NOT_ONBOARDED)
        setStripeLink(stripeInfo.accountLink.url)
        setStripeButtonLabel(STRIPE_BUTTON_LABEL.CONNECT_TO_STRIPE)
        setNeedPrompt(true)

        // create a user stripe record in db
        const stripeAccount = await createStripeAccount({
            accountId: stripeInfo.account.id,
            merchantId: selectedMerchant.merchant_id,
            email: userInfo.email,
            type: 'on-boarding',
            status: STRIPE_STATUS.NOT_ONBOARDED
        })
        return stripeAccount
    }

    function openStripeLink() {
        if (needPrompt) {
            setModalStripePromptStatus(true)
        } else {
            setCreateStripeButtonDisabled(true)
            openInNewTab(stripeLink)
        }
    }

    function closeForm(state) {
        setModalStripePromptStatus(state)
    }

    function handleDealVoucherChange(v) {
        setSelectedDealVoucher({...v})
    }

    const downloadVouchersTable = () => {
         if (dataTable.rows.length>0) {

            let arr = []
            isEvent ?
            checkins > 0 ?
                dataTable.rows.map((e)=>{
                    let item = {'ID': e.id, 'Transaction ID': e.transactionId, 'Transaction date and time': e.transactionDateTime, 'Confirmation Id': e.confirmationId, 'Voucher': e.voucher, 'Discount Code': e.discountCode, 'Discounted Value': e.discountValue, 'Status': e.status.props.value, 'Redemption': e.redemption.props.value,
                                'Redeemed By': e.redeemedBy,'Redemption Date and Time': e.redeemDateTime, 'Check-in': e.checkIn,
                                'First Name': e.firstName, 'Last Name': e.lastName, 'Email Address': e.email, "Mobile": e.mobile}
                    arr.push(item)
                })
                :
                dataTable.rows.map((e)=>{
                    let item = {'ID': e.id, 'Transaction ID': e.transactionId, 'Transaction date and time': e.transactionDateTime, 'Confirmation Id': e.confirmationId, 'Voucher': e.voucher, 'Discount Code': e.discountCode, 'Discounted Value': e.discountValue, 'Status': e.status.props.value, 'Redemption': e.redemption.props.value,
                                'Redeemed By': e.redeemedBy,'Redemption Date and Time': e.redeemDateTime}
                    arr.push(item)
                })  
            :
                checkins > 0 ?
                dataTable.rows.map((e)=>{
                    let item = {'ID': e.id, 'Transaction ID': e.transactionId, 'Transaction date and time': e.transactionDateTime, 'Voucher': e.voucher, 'Discount Code': e.discountCode, 'Discounted Value': e.discountValue, 'Status': e.status.props.value, 'Redemption': e.redemption.props.value,
                                'Redeemed By': e.redeemedBy,'Redemption Date and Time': e.redeemDateTime, 'Check-in': e.checkIn,
                                'First Name': e.firstName, 'Last Name': e.lastName, 'Email Address': e.email, "Mobile": e.mobile}
                    arr.push(item)
                 })
                :
                dataTable.rows.map((e)=>{
                    let item = {'ID': e.id, 'Transaction ID': e.transactionId, 'Transaction date and time': e.transactionDateTime, 'Voucher': e.voucher, 'Discount Code': e.discountCode, 'Discounted Value': e.discountValue, 'Status': e.status.props.value, 'Redemption': e.redemption.props.value,
                                'Redeemed By': e.redeemedBy,'Redemption Date and Time': e.redeemDateTime}
                    arr.push(item)
                })

             convertToCSV(arr, selectedDealVoucher.caption + " Voucher(s) Summary")
             setDownloadingCSV(false)
        }   
    }

    const totalRevenueObj = { title: "Total Revenue", iconClass: "cellphone-sound", total: totalRevenue, bgColor: "#7A6FBE", buttonLabel: "View voucher details", link: '/sell-vouchers' }
    const soldVouchersObj = { title: "Number of vouchers sold", iconClass: "ticket-confirmation", total: soldVouchers, bgColor: "#0DCAF0", buttonLabel: "View voucher details", link: '/sell-vouchers' }
    const redeemVouchersObj = { title: "Vouchers redeemed", iconClass: "pencil", total: redeemVouchers, bgColor: "#F5B225", buttonLabel: "View voucher details", link: '/sell-vouchers' }
    const checkinsObj = { title: "Check-Ins", iconClass: "pencil", total: checkins, bgColor: "#46b521", buttonLabel: "View voucher details", link: '/sell-vouchers' }
    const discountCodeObj = { title: "Discount Code Value", iconClass: "cellphone-sound", total: totalDiscount, bgColor: "#DA5E6E", buttonLabel: "View voucher details", link: '/sell-vouchers' }
    const others = {"deal id": selectedDealVoucher.pk, "promotion title": selectedDealVoucher.promotion_caption}

    return (

        <React.Fragment >
            <RBAC merchantId={selectedMerchant.merchant_id} roles={['admin']}>
            <MetaTags>
                <title>Sales Summary</title>
            </MetaTags>
            
            
            <Row className="sale-summary-card justify-content-center" >
            { stripeChecking ? 
                <div className="text-center" >
                  <Spinner/> 
                </div>
              : stripeAccount.status !== STRIPE_STATUS.COMPLETED ?
                <Row className='justify-content-center' >
                    <Col xs={12} sm={12} md={6} lg={5} xl={5}>
                        <Card>
                            <CardImg
                                src={sellVoucherStepImg}
                                top
                                width="100%"
                                style={{ objectFit:'contain', marginTop:'20px' }}
                            />
                        </Card>
                        <StripeOnboarding 
                            stripeStatus={stripeAccountStatus}
                            statusColor={getStripeStatusColor(stripeAccountStatus)}
                            disabled={createStripeButtonDisabled}
                            buttonLabel={stripeButtonLabel}
                            onClick={() => openStripeLink()}
                            outline = {[STRIPE_STATUS.PENDING_VERIFICATION, STRIPE_STATUS.COMPLETED].includes(stripeAccountStatus)?true:false}
                            cta="sales summary"
                        />
                    </Col>
                </Row>
              : dealVouchers && dealVouchers.length===0 ?
                <Row className='justify-content-center'>
                    <Col xs={12} sm={12} md={6} lg={5} xl={5}>
                        {/* <Button onClick={() => openStripeLink()} color="primary" className="open-stripe-dashboard">Open Stripe Dashboard</Button> */}
                        <EmptyState history={props.history}/>  
                    </Col>
                </Row>
              :
                <Card style={{padding: 15}}>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Select value={selectedDealVoucher}
                                options={dealVouchers} 
                                onChange={(v) => handleDealVoucherChange(v)} />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Button onClick={() => openStripeLink()} color="primary" className="open-stripe-dashboard">Open Stripe Dashboard</Button>
                        </Col>
                    </Row>
                    <Row className="mt-3 mb-3">
                        <span>Note: The redemption statuses may subject to changes from users’ input on 
                        app, especially for online vouchers. Strongly suggest merchants to track the redemption statuses on their end.</span>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                            <BoxWidget hideButton={true} cta="total revenue" screen="Sales Summary" others={others} history={props.history} details={totalRevenueObj}/>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                            <BoxWidget hideButton={true} cta="number of voucher sold" screen="Sales Summary" history={props.history} details={soldVouchersObj}/>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                            <BoxWidget hideButton={true} cta="total revenue" screen="Sales Summary" history={props.history} details={redeemVouchersObj}/>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                            <BoxWidget hideButton={true} cta="check-ins" screen="Sales Summary" history={props.history} details={checkinsObj}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                            <BoxWidget hideButton={true} cta="discount code value" screen="Sales Summary" others={others} history={props.history} details={discountCodeObj}/>
                        </Col>
                    </Row>
                </Card>
            }
            </Row>
            { stripeChecking ? 
               null
                : (dealVouchers && dealVouchers.length >0) && (stripeAccount.status === STRIPE_STATUS.COMPLETED)?
                dataLoading ? 
                    <div className="text-center" >
                        <Spinner/> 
                    </div>
                :
                <Row>
                    <Card>
                        <CardBody>
                            <Row style={{ display: "flex", justifyContent: "flex-end", top: "21px", position:"relative", zIndex:"99"}}>
                                <Col  md={7} className="d-none d-md-block">
                                    <Row>
                                        <Col>
                                            Filter By:
                                        </Col>
                                    </Row>
                                    <br/>

                                    <Row xs={12}>
                                        <Col >
                                            <Row xs={12}>
                                                <Col>
                                                Selling Period
                                                </Col>
                                            </Row>
                                            <Row xs={12}>
                                                <Col>
                                                    <InputGroup>
                                                    <Form.Control
                                                        style={{borderWidth: 1, maxWidth:"50%" }}
                                                        type="date"
                                                        name="saleFrom"
                                                        value={saleDateFrom}
                                                        onChange={(e) => setSaleDateFrom(e.target.value)}
                                                    />
                                                    <Form.Control
                                                        style={{ borderWidth: 1, maxWidth:"50%" }}
                                                        type="date"
                                                        name="saleTo"
                                                        value={saleDateTo}
                                                        onChange={(e) => setSaleDateTo(e.target.value)}
                                                    />                                         
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row sm={12}>
                                                <Col>
                                                Redemption Period
                                                </Col>
                                            </Row>
                                            <Row sm={12}>
                                                <Col>
                                            <InputGroup>
                                            <Form.Control
                                                style={{borderWidth: 1, maxWidth:"50%" }}
                                                type="date"
                                                name="redemptionFrom"
                                                value={redemptionDateFrom}
                                                onChange={(e) => setRedemptionDateFrom(e.target.value)}
                                            />
                                            <Form.Control
                                                style={{ borderWidth: 1, maxWidth:"50%" }}
                                                type="date"
                                                name="redemptionTo"
                                                value={redemptionDateTo}
                                                onChange={(e) => setRedemptionDateTo(e.target.value)}
                                            />                                         
                                            </InputGroup>
                                            </Col>
                                            </Row>
                                        </Col>

                                    </Row>
                                </Col>
                                {/* <Col style={{display:"flex",alignSelf:"flex-end", justifyContent:"flex-start"}}>
                                    <Button color="primary" type="button" size="sm" onClick={()=>downloadVouchersTable()}><BsFilter/> Filter</Button>
                                </Col> */}
                                
                                <Col style={{display:"flex",alignSelf:"flex-end", justifyContent:"flex-end"}}>
                                    <div>
                                        <Row sm={12} className="d-sm-block d-md-none">
                                        <Col>
                                        <Row>
                                        <Col>
                                            Filter By:
                                        </Col>
                                    </Row>
                                    <br/>

                                    <Row xs={12}>
                                        <Col >
                                            <Row xs={12}>
                                                <Col>
                                                Selling Period
                                                </Col>
                                            </Row>
                                            <Row xs={12}>
                                                <Col>
                                                    <InputGroup>
                                                    <Form.Control
                                                        style={{borderWidth: 1, maxWidth:"50%" }}
                                                        type="date"
                                                        name="saleFrom"
                                                        value={saleDateFrom}
                                                        onChange={(e) => setSaleDateFrom(e.target.value)}
                                                    />
                                                    <Form.Control
                                                        style={{ borderWidth: 1, maxWidth:"50%" }}
                                                        type="date"
                                                        name="saleTo"
                                                        value={saleDateTo}
                                                        onChange={(e) => setSaleDateTo(e.target.value)}
                                                    />                                         
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row xs={12}>
                                                <Col>
                                                Redemption Period
                                                </Col>
                                            </Row>
                                            <Row xs={12}>
                                                <Col>
                                            <InputGroup>
                                            <Form.Control
                                                style={{borderWidth: 1, maxWidth:"50%" }}
                                                type="date"
                                                name="redemptionFrom"
                                                value={redemptionDateFrom}
                                                onChange={(e) => setRedemptionDateFrom(e.target.value)}
                                            />
                                            <Form.Control
                                                style={{ borderWidth: 1, maxWidth:"50%" }}
                                                type="date"
                                                name="redemptionTo"
                                                value={redemptionDateTo}
                                                onChange={(e) => setRedemptionDateTo(e.target.value)}
                                            />                                         
                                            </InputGroup>
                                            </Col>
                                            </Row>
                                        </Col>

                                    </Row>
                                            <br/>
                                            <br/>
                                            <br/>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col style={{ display:"flex",justifyContent: "flex-end"}}>
                                            <Button color="primary" type="button" size="sm" style={{fontSize:"0.65rem"}}  onClick={()=>downloadVouchersTable()}>Export as CSV Document</Button>
                                        </Col>
                                    </Row>
                                    </div>
                                </Col>
                            </Row>
                            <div ref={dataContainer}>
                            <MDBDataTable  className="salesSummary" entries={10}  displayEntries={false} hover pagesAmount={10} fixed={true} scrollX data={{ columns: dataTable.columns, rows: dataTable.rows }} noBottomColumns={true}/>
                            {downloadingCSV &&
                                <div  >
                                <Spinner/>
                                </div>
                            }
                            </div>
                        </CardBody>
                    </Card>
                </Row>
                : null
            }
            
            {modalStripePromptStatus ? <StripePrompt stripeLink={stripeLink} modalStripePromptStatus={modalStripePromptStatus} close={(item, type) => closeForm(item, type)} /> : null}
            </RBAC>

        </React.Fragment>
    )

}

export default connect(null, { setBreadcrumbItems })(SalesSummary);
