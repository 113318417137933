import React, { Component } from 'react'
import { ListGroup } from "react-bootstrap"
import { debounce } from 'lodash'
import { AvField } from "availity-reactstrap-validation"

// import apiHelpers
import { getAddressSuggestion, getLatLng } from '../../../../helpers/apiHelper' 

class CMSAddress extends Component {

    constructor(props) {
        super(props)

        this.state = {
            address: this.props.value ?? '',
            places: []
        }
    }

    debounceLookupAddress = debounce((address) => {
        this.lookupAddress(address)
    }, 1000)

    async lookupAddress(address) {
        // IMPT :: should not have body object, otherwise api will throw InvalidSignatureException
        // http://5.9.10.113/48086977/aws-amplify-react-native-throwing-403-invalidsignatureexception-when-passing-da
        this.props.processing(true)

        if (this.props.showList) {
            const result = await getAddressSuggestion({address})
            if (result.status === 'OK') {

                const places = result.predictions
    
                this.setState({
                    places: places
                })
            }
        }
            
        if (this.props.onChange) {
            const latlng = await this.getLatLng(address)
            
            this.props.onChange(address, latlng)
        }
        this.props.processing(false)
    }

    async handleAddressChange(e) {
        e.preventDefault()
        e.stopPropagation()

        const address = e.target.value
        if (address.length >= this.props.addressLength) {
            this.debounceLookupAddress(address)
            this.setState({ address: address })
        }else if (address.length === 0){
            this.setState({ address: address })
        }
    }

    async handlePlaceSelect(e, place) {
        e.preventDefault()
        e.stopPropagation()

        const address = place.description
        this.setState({
            address: address,
            places: []
        })

        if (this.props.onChange) {
            const latlng = await this.getLatLng(address)
            this.props.onChange(address, latlng)
        }
    }

    async getLatLng(address) {
        // IMPT :: should not have body object, otherwise api will throw InvalidSignatureException
        // http://5.9.10.113/48086977/aws-amplify-react-native-throwing-403-invalidsignatureexception-when-passing-da
        const result = await getLatLng({ address })

        if (result.status === 'OK') {
            const loc = result.results[0].geometry.location
            return loc
        }
    }

    hidePlaces(e) {
        e.preventDefault()
        e.stopPropagation()

        this.setState({
            places: []
        })
    }

    render() {
        return (
            <div>
                {/* <Form.Control type="text" disabled={this.props.disabled} readOnly={this.props.readOnly} value={this.state.address} onChange={(e) => this.handleAddressChange(e)} onBlur={(e) => this.hidePlaces(e)} /> */}
                <AvField
                    name={this.props.name || 'fieldname'}
                    placeholder={this.props.placeholder}
                    type="text"
                    errorMessage={this.props.errorMessage}
                    className="form-control"
                    validate={this.props.validate}
                    id={this.props.id}
                    value={this.props.value}
                    onChange={(e) => this.handleAddressChange(e)}
                    autoComplete='off'
                    disabled={this.props.disabled}
                                />
                { this.props.showList && 
                <ListGroup variant="flush">
                    {this.state.places?this.state.places.map((place) =>
                        <ListGroup.Item key={`list-addresses-${place.place_id}`} action onMouseDown={(e) => this.handlePlaceSelect(e, place)}>
                            {place.description}
                        </ListGroup.Item>
                    ):null}
                </ListGroup> 
                }
            </div>
        )
    }
}

export default CMSAddress