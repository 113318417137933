/* eslint-disable react/no-unescaped-entities */
import React from "react"
import { Card, CardBody, Button, CardTitle } from "reactstrap"
import starterPlanImg from '../../../../assets/images/starterPlan.png'
import { FREE_PLAN, STARTER_PLAN_1, STARTER_PLAN_2, STARTER_PLAN_3, PROFESSIONAL_PLAN } from "utils/constants"
import checkSubPlan from '../../../../assets/images/checkSubPlan.png'

const StarterPlan = (props)=> {
    const { isProcessing } = props
    const starterPlans = [STARTER_PLAN_1, STARTER_PLAN_2, STARTER_PLAN_3]
    const allPlans = [FREE_PLAN, STARTER_PLAN_1, STARTER_PLAN_2, STARTER_PLAN_3, PROFESSIONAL_PLAN]

    return(
        
        <Card className="text-center plan-card-height">
            <div className="mt-5">
                <img style={{ width:'60px', height:'80px', objectFit:'cover' }} src={starterPlanImg}/>
                <div style={{ position: "absolute", right: 0, left:0, top: -20 }}>
                    {starterPlans.includes(props.subPlan) &&
                    <img style={{ height:'45px', width:'45px'}} src={checkSubPlan}/>}
                </div>
            </div>
            <CardBody>
                <CardTitle><strong>Starter Plan</strong></CardTitle>
                <p>Suitable for business with 1-3 brands</p>

                <div style={{ textAlign:'left' }}>
                    <p>✅ Listing of <strong>unlimited deals</strong> on CardsPal app (limited to 1 brand) - Additional brand at $100 </p>
                    <p>✅ Be featured in <strong>&apos;Nearby Deals&apos;</strong></p>
                    <p>✅ Be featured in relevant <strong>categories and search</strong> results</p>
                    <p>✅ Additional shoutout for <strong>new merchants</strong></p>
                    <p>✅ Be informed of the <strong>latest campaigns</strong> to engage our users</p>
                    <p>✅ <strong>Welcome gift</strong> - 1x feature in EDM (non-dedicated)</p>
                    <p>✅ Total entitlements <strong>worth S$499</strong></p>
                </div>
                {(props.subPlan === FREE_PLAN || !allPlans.includes(props.subPlan))&&
                <Button disabled ={isProcessing} color='primary' onClick={()=> props.getStarted()} >Get Started</Button>}
            </CardBody>
        </Card>
             
    )
}

export default StarterPlan