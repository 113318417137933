import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useForm, Controller } from "react-hook-form";
import { Button, Form, Image } from 'react-bootstrap';
import { Row, Col, CardBody, Card, Alert, Container, Spinner } from "reactstrap"
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Title, SubTitle, Underline, CardContainer, DateTimeData, 
         TicketType, ErrMsg, Sup, SubmitButton, Terms, Disclaimer } from './Styled';
import AlternateInfo from './AlternateInfo';


const PersonalInfo = (props) => {

    const initialValues = {
        salutation: '',
        firstName: '',
        lastName: '',
        nationality: '',
        organization: '',
        designation: '',
        terms: false,
        marketingConsent: false,
        privacyPolicy: false,
        altSalutation: '',
        altFirstName: '',
        altLastName: '',
        altEmail:'',
        altCountryCode:'+65',
        altPhoneNumber:''
      }

    const {custom} = props
    const personalInfoCustomization = custom?.personalInfoCustomization ?? null
    let dynamicKeys = {};
    
    personalInfoCustomization ?
    Object.keys(personalInfoCustomization).forEach((key) => {
        dynamicKeys[personalInfoCustomization[key].name] = personalInfoCustomization[key].name.toLowerCase().includes("email") ? 
                                                            personalInfoCustomization[key].required ? Yup.string().email('Invalid email address').required('Required') : 
                                                            Yup.string().email('Invalid email address') : 
                                                            personalInfoCustomization[key].required ? Yup.string().required("Required") : Yup.string()
        initialValues[key] = ''
    }) : null

    const schema = Yup.object().shape({
        salutation: personalInfoCustomization? null : Yup.string().required("Required"),  
        nationality: personalInfoCustomization ? null : Yup.string().required("Required"),
        organization: personalInfoCustomization ? null : Yup.string().required("Required"),
        designation: personalInfoCustomization ? null : Yup.string().required("Required"),
        terms: props.custom?.tnc ? Yup.boolean()
            .required("Required")
            .oneOf([true], "Please accept terms and conditions") : null,
        marketingConsent: props.custom?.marketingConsent ? Yup.boolean() : null,
        ...dynamicKeys
    })



    
    const [state, setState] = useState(initialValues);
    const viewRender =[]
    const { register, getValues, setValue, handleSubmit, reset, clearErrors, control, formState: { errors,isSubmitSuccessful, isValid} } = useForm ({
        mode: "onChange", 
        reValidateMode: "onChange",
        defaultValues: state,
        resolver: yupResolver(schema)
      });

    const onChange = (e) => {
        //console.log('---onChange', e)
    }

    const onSubmit = (e) => {
        //console.log('---submit',  e)
    }

    const onBlur = (e) => {
        //console.log('---blur', e)
    }

    const onError = (e) => {
        //console.log('---error', e)
    }

    return(
        <React.Fragment>
                <Row className='justify-content-center'>
                    <Col>
                        <Form onSubmit={handleSubmit(props.onSubmit, onError)} onChange={(e)=>onChange(e)} onBlur={(e)=>onBlur(e)}>
                            <fieldset disabled={props.isDisabled}>
                                <CardContainer>
                                    <CardBody>
                                        <SubTitle>Personal Info</SubTitle>
                                        <Underline className='mb-3'/>
                                        {!personalInfoCustomization &&
                                        <>
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Form.Label>Salutation<Sup>*</Sup></Form.Label>
                                            </Col>
                                            <Col xs={12} sm={3} md={3} lg={3} xl={3}>
                                                <Controller
                                                    name='salutation' 
                                                    control={control}
                                                    render={({ field: { onChange, value, name, ref } }) => {
                                                        const handleSelectChange = (e) => {
                                                            onChange(e?.value);
                                                        };
                                                        return(
                                                            <Select
                                                                inputRef={ref}
                                                                value={props.salutationList.find((e) => e.value === value)} 
                                                                options={props.salutationList}
                                                                placeholder='Select...'
                                                                onChange={handleSelectChange}
                                                                styles={{
                                                                    control: base => ({
                                                                    ...base,
                                                                    width: 120
                                                                    })
                                                                }}
                                                                isDisabled={props.isDisabled}
                                                            />
                                                        )}}
                                                    />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="salutation"
                                                    render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                                />
                                            </Col>
                                        </Row>
                                        <Underline className='mb-3'/>
                                        </>
                                        }

                                        {/* Custom setup */}
                                        {personalInfoCustomization &&
                                        Object.keys(personalInfoCustomization).forEach((key,i) => {
                                            viewRender.push(      
                                                <div key={"custom_"+i}>
                                                <Row>
                                                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                        <Form.Label>{key}<Sup>{personalInfoCustomization[key].required ? "*" : null}</Sup></Form.Label>
                                                        {personalInfoCustomization[key].disclaimer &&
                                                        <>
                                                        <br/>
                                                         <Disclaimer>{personalInfoCustomization[key].disclaimer}</Disclaimer>                  
                                                        </>
                                                  }
                                                    </Col>
                                                    {personalInfoCustomization[key]?.type === "select" &&
                                                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                        <Controller
                                                            name={personalInfoCustomization[key].name} 
                                                            control={control}
                                                            render={({ field: { onChange, value, name, ref } }) => {
                                                                const handleSelectChange = (e) => {
                                                                    onChange(e?.value);
                                                                };
                                                                return(
                                                                    <Select
                                                                        inputRef={ref}
                                                                        value={personalInfoCustomization[key]?.options.find((e) => e.value === value)} 
                                                                        options={personalInfoCustomization[key]?.options}
                                                                        placeholder='Select...'
                                                                        onChange={handleSelectChange}
                                                                        isDisabled={props.isDisabled}
                                                                    />
                                                                )}}
                                                            />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name={personalInfoCustomization[key].name}
                                                            render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                                        />
                                                    </Col>
                                                    }
                                                    {personalInfoCustomization[key]?.type === "text" &&
                                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                            <Form.Control 
                                                                type="text" 
                                                                name={personalInfoCustomization[key].name}
                                                                placeholder={"Enter your " + key.toLowerCase()}
                                                                autoComplete='off' 
                                                                className={errors[personalInfoCustomization[key].name] && "error"}
                                                                {...register(personalInfoCustomization[key].name)}
                                                            />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name={personalInfoCustomization[key].name}
                                                            render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                                        />
                                                    </Col>
                                                    }
                                                </Row>

                                                <Underline className='mb-3'/>
                                            </div>
                                            )
                                        })
                                        }
                                        {viewRender}
                                        {!personalInfoCustomization &&
                                        <>
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Form.Label>Nationality<Sup>*</Sup></Form.Label>
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Controller
                                                    name='nationality' 
                                                    control={control}
                                                    render={({ field: { onChange, value, name, ref } }) => {
                                                        const handleSelectChange = (e) => {
                                                            onChange(e?.value);
                                                        };
                                                        return(
                                                            <Select
                                                                inputRef={ref}
                                                                value={props.nationality.find((e) => e.value === value)} 
                                                                options={props.nationality}
                                                                placeholder='Select...'
                                                                onChange={handleSelectChange}
                                                                isDisabled={props.isDisabled}
                                                            />
                                                        )}}
                                                    />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="nationality"
                                                    render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                                />
                                            </Col>
                                        </Row>
                                        <Underline className='mb-3'/>
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Form.Label>Organization<Sup>*</Sup></Form.Label>
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Form.Control 
                                                    type="text" 
                                                    name="organization"
                                                    placeholder="Enter your organization"
                                                    autoComplete='off' 
                                                    className={errors.organization && "error"}
                                                    {...register("organization")}
                                                    />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="organization"
                                                    render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                                />
                                            </Col>
                                        </Row>
                                        <Underline className='mb-3'/>
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Form.Label>Designation<Sup>*</Sup></Form.Label>
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Form.Control 
                                                    type="text" 
                                                    name="designation"
                                                    placeholder="Enter your designation"
                                                    autoComplete='off' 
                                                    className={errors.designation && "error"}
                                                    {...register("designation")}
                                                    />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="designation"
                                                    render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                                />
                                            </Col>
                                        </Row>
                                        <Underline/>
                                        </>
                                        }
                                    </CardBody>
                                </CardContainer>
                                {custom.alternateInfo && 
                                <AlternateInfo
                                    salutationList={props.salutationList}
                                    nationality={props.nationality}
                                    countryCode={props.countryCode}
                                    schema={schema}
                                    register={register}
                                    control={control}
                                    errors={errors}
                                    state={state}
                                    custom={custom}
                                    updateInitial = {(e)=> setState(e)}
                                />}
                                 {props.custom?.tnc &&
                                <Terms>
                                    <Form.Check
                                        name="terms"
                                        type="checkbox" 
                                        label={<div>I have read and agree to the <a href='https://cardspal.com/ticket-sales-terms-and-conditions/' target='_blank' rel='noreferrer'>T&Cs of Ticket Sales</a> and <a href='https://cardspal.com/privacy-policy/
                                        ' target='_blank' rel='noreferrer'>CardsPal&apos;s Data Privacy Policy</a>.</div>}
                                        {...register("terms")}
                                        />
                                    <ErrorMessage
                                        errors={errors}
                                        name="terms"
                                        render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                        />
                                </Terms>
                                }
                                {props.custom?.marketingConsent &&
                                <Terms>
                                    <Form.Check
                                        name="marketingConsent"
                                        type="checkbox" 
                                        label="I wish to receive marketing materials (such as news on other exciting events) from the organisers, and consent to the disclosure of my personal data to the organiser for such purpose."
                                        {...register("marketingConsent")}
                                        />
                                    <ErrorMessage
                                        errors={errors}
                                        name="marketingConsent"
                                        render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                        />
                                </Terms>
                                }
                                
                                <Row className='justify-content-center'>
                                    <SubmitButton 
                                        type="submit" 
                                        className="btn btn-primary btn-block"
                                        disabled={
                                            !isValid ||
                                            Object.keys(errors).length > 0
                                        }
                                    >Register now</SubmitButton>
                                </Row>
                            </fieldset>
                        </Form>
                    </Col>
                </Row>    
        </React.Fragment>

    )
}

export default PersonalInfo