import React from 'react'
import GoogleMapReact from 'google-map-react'
import { MapIcon, GMap , MapH2, MapPin, MapPinText} from './Styled'

let circle;
 const Map =  ({ location, zoomLevel }) => {
    
    const apiIsLoaded = (map, maps) => {
        circle = new maps.Circle({
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.3,
          map,
          center: location,
          radius: 10
        });
      };
  
    return (
        <div>
        <GMap style = {{'overflow': 'hidden', 'borderRadius': '2vh'}}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: location.key }}
            defaultCenter={location}
            defaultZoom={zoomLevel}
          >
            <LocationPin
              lat={location.lat}
              lng={location.lng}
              text={location.address}
            />
          </GoogleMapReact>
        </GMap>
      </div>
      )
 }




  const LocationPin = ({ text }) => (
    <MapPin>
      <MapIcon id='mapIcon' />
      <MapPinText>{text}</MapPinText>
    </MapPin>
  )

  export default Map;