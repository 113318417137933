/* eslint-disable no-unused-vars */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LOGOUT_USER, LOGIN_USER, LOGIN_SUCCESS } from "./actionTypes"
import { apiError} from "./actions"
import { setUserMerchants, setSelectedMerchant } from '../../merchant/actions'
import { setUser } from '../../user/actions'

// amplify
import { getAmplifyBackend } from "../../../helpers/authHelper"
import { getBrandByMerchantIds } from '../../../helpers/GraphQL/brands'
import { getUser } from '../../../helpers/GraphQL/user'


const amplifyBackend = getAmplifyBackend()

function* getInitialUserDetails(payload)  {
  try {
    if (!payload) return
    
    // we get the user record from CMSUser and set to localStorage to access this info easily
    const { payload: { user } } = payload
    const userInfo = yield call(getUser, user.email)
    
    localStorage.setItem('user', JSON.stringify(userInfo))
    const roles = userInfo.roles ? JSON.parse(userInfo.roles) : {}
    const jsonUser = {
      ...userInfo,
      roles
    }
    yield put(setUser(jsonUser))
    
    if (roles.merchants && roles.merchants.length > 0) {
      const merchantIds = roles.merchants.map(item => item.merchantId)
      
      // here we get all merchants of the user and massage the data to easily access later before putting in storage/store
      const merchants = yield call(getBrandByMerchantIds, merchantIds)
      if (merchants) {
        // lets massage the format to it will be Object Keys and easy to update when merchant object needs update
        // Expected structure: { merchantId: { ....merchant_details } }
        let target = {}
        merchants.forEach((item) => {
          delete item.outlets
          const source ={
            [item.merchant_id]: { ...item}
          }
          target = Object.assign(target, source)
        })
        localStorage.setItem("userMerchants", JSON.stringify(target))
        yield put(setUserMerchants(target))
      }
      
      // here we pre-select the first merchant and set the localstorage/store
      if (merchants && merchants.length > 0) {
        const merchant = merchants[0] // get the first merchant to be set as default
        delete merchant.outlets // we dont need the outlets so remove it
        localStorage.setItem('selectedMerchant', JSON.stringify(merchant))
        yield put(setSelectedMerchant(merchant))
      }
    }
    
  } catch (error) {
    console.error('getInitialUserDetails ', error)
    yield put(apiError(error))
  }
}

function* loginUser({ payload: { user, history } }) {
  
  try {
//     console.log('sage loginUser ',{user})
//     // if (process.env.REACT_APP_DEFAULTAUTH === 'amplify') {
//       // const response = yield call(amplifyBackend.signin, 
//       //   user.email,
//       //   user.password,
//       // )
//       // console.log('login done ', response)
//       // localStorage.setItem("authUser", JSON.stringify(response))
//       // yield put(loginSuccess(response))

//       // we get all the merchant_ids attached to this cognito user
//       /* const merchantIds = yield call(getMerchantsFromGroups, response['cognito:groups'])
      
//       // get the merchant details for each merchant_ids
//       const merchants = yield call(getBrandByMerchantIds, merchantIds)
//       localStorage.setItem("userMerchants", JSON.stringify(merchants))
//       yield put(setUserMerchants(merchants))

//       if (merchants && merchants.length > 0){
//         const merchant = merchants[0] // get the first merchant to be set as default
//         delete merchant.outlets // we dont need the outlets so remove it
//         localStorage.setItem('selectedMerchant', JSON.stringify(merchant))
//         yield put(setSelectedMerchant(merchant))
//       } */
//     // }
//     // history.push("/dashboard")
  } catch (error) {
    console.error('login saga ',error)
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.clear();
    amplifyBackend.signOut()
    history.push("/login")
    location.reload(true)
    
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_SUCCESS, getInitialUserDetails)
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
