import { API, graphqlOperation } from 'aws-amplify'
import amplitude from 'amplitude-js';
 import { GENERIC_ERROR_MESSAGE } from '../../utils/constants'
import { transactionByDealId, getTransaction } from '../../graphql/queries'
import Decimal from 'decimal.js';
import { nullLiteral } from '@babel/types';

export const getTransactionsByDeal = async (dealId, filter) => {
    try {
        let result = []
        const params = {
            limit: 1000,
            dealId
        }

        if (filter) {
            params.filter = {...filter}
        }
        // console.log('getTransactionsByDeal ', {params})

        do {
            let transaction
            try {
                transaction = await API.graphql(graphqlOperation(transactionByDealId, params))
            } catch (e) {
                transaction = e
            } finally {
                const items = transaction.data.transactionByDealId.items
                if (items.length > 0) {
                    result = result.concat(items)
                }
        
                const nextToken = transaction.data.transactionByDealId.nextToken
                params.nextToken = nextToken
            }
            
        } while (params.nextToken)

        return result
    } catch (e) {
        amplitude.getInstance().logEventWithGroups('Error - getTransactionsByDeal', { 'details': e })
        console.error('Error on getTransactionsByDeal', e)
        return []
        // throw e ? e.message :`${GENERIC_ERROR_MESSAGE}[Retrieve Transaction By Deal] `
    }
}

export const getAllTransactionsByDealIds = async (dealIds, filter) => {
    try {
        let temp = []
        for (let i=0; i<dealIds.length; i++) {
            const result = await getTransactionsByDeal(dealIds[i], filter)
            temp = temp.concat(result)
        }

        return temp
    } catch (e) {
        amplitude.getInstance().logEventWithGroups('Error - getAllTransactionsByDealId', { 'details': e })
        console.error('Error on getAllTransactionsByDealId', e)
        throw e ? e.message :`${GENERIC_ERROR_MESSAGE}[Retrieve Transaction By Deal] `
    }
}


export const getTotalRevenueByDeal = async(dealId) => {
    try {
        const transactions = await getTransactionsByDeal(dealId, {status: {eq: "0"}})
        let totalRevenue = 0
        transactions.forEach((item) => {
            totalRevenue = Decimal(totalRevenue).plus(Decimal(item.purchasePrice).times(item.quantity))
        })
        return `SGD ${totalRevenue.toFixed(2)}`
    } catch(e) {
        throw e ? e.message :`${GENERIC_ERROR_MESSAGE}[Total Revenue Computation] `
    }
}

export const getTotalDiscountByDeal = async(dealId) => {
    try {
        const transactions = await getTransactionsByDeal(dealId, {status: {eq: "0"}})
        let totalDiscount = 0
        transactions.forEach((item) => {
            totalDiscount = item.extraDiscount ? Decimal(totalDiscount).plus(Decimal(item.extraDiscount)) : totalDiscount
        })

        return `SGD ${totalDiscount.toFixed(2)}`
    } catch(e) {
        throw e ? e.message :`${GENERIC_ERROR_MESSAGE}[Total Revenue Computation] `
    }
}

export const getTransactionById = async (id) => {
    let dataResult = []
    try {
        const result = await API.graphql(graphqlOperation(getTransaction, {id}))
        dataResult = result.data.getTransaction
    } catch (e) {
        amplitude.getInstance().logEventWithGroups('Error - getTransactionById', { 'details': e })
        console.error('Error on getTransactionById', e)
        dataResult = e.data.getTransaction
    } 
    return dataResult
}
