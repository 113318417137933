import React from "react"
import { Card, CardBody, CardImg, CardText, Button, CardSubtitle } from "reactstrap"
import upgradePlan from '../../assets/images/upgradePlan.png'

const UpgradePlan = (props)=> {
    
    function gotoPlan() { 
        //props.history.push("/manage-plans")
        props.onClick()
    }

    return(
        <Card className="boxStripeDashboard" style={{paddingLeft: "20px", paddingRight: "20px"}}>
            <CardImg
            src={upgradePlan}
            top
            width="100px"
            height="100px"
            style={{ objectFit:'contain', marginTop:'40px' }}
            />
            <CardBody className="text-center m-0 p-0">
                <CardSubtitle
                    className="mb-2 mt-4 text-muted"
                    style={{ fontSize:12 }}>
                    <h5><strong>Start promoting your deals now</strong></h5>
                </CardSubtitle>
                <CardText className="p-0 mb-4" style={{ fontSize:12 }}>Upgrade to add promotions</CardText>
                <Button className="mb-5 mt-4" color='primary' disabled={props.disabled} outline={props.outline} onClick={() => gotoPlan()}>
                    Upgrade my plan
                </Button>
            </CardBody>
        </Card>
             
    )
}

export default UpgradePlan