import React, { useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { PHONE_REGEX, MARKETING_TEXT, TERMS } from 'utils/constants';
import platform from 'platform';
import styled from 'styled-components';
import { Row, Col } from "reactstrap"
import Select from 'react-select';
import { SubTitle, ErrMsg } from '../Components/Styled';
import { UncontrolledTooltip } from 'reactstrap';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { checkDuplicateEmail } from 'helpers/apiHelper'

const Appform_Contact_Legacy = (props) => {

  const initialValues = {
    email: props.prefill?.email ?? '',
    countryCode: props.prefill?.countryCode ?? '+65',
    phoneNumber: props.prefill?.phone ?? '',
    firstName: props.prefill?.firstName ?? '',
    lastName: props.prefill?.lastName ?? '',
    otpCountryCode: '+65',
    webFormId: props.webFormId ? props.webFormId : "0007"
  }

  const infoFields = ["email", "countryCode", "phoneNumber", "firstName", "lastName"]

  let dynamicKeys = {};
  if (props.qty && props.qty > 1) {
    for (let x = 2; x <= props.qty; x++) {
      Object.keys(infoFields).forEach((key) => {
        dynamicKeys[infoFields[key] + x] = infoFields[key].includes("email") ? Yup.string().email('Invalid email address').required('Required') : infoFields[key].includes("phoneNumber") ? Yup.string().required("Required") : Yup.string().required("Required")
        initialValues[infoFields[key] + x] = infoFields[key].includes("countryCode") ? '+65' : ''
      })

    }
  }
  if (props.custom?.customClause) {
    dynamicKeys[props.custom?.customClause.name] = props.custom?.customClause.required ? Yup.boolean()
      .required("Required")
      .oneOf([true], "Please accept terms and conditions") : null
  }

  const schema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Required'),
    countryCode: Yup.string().required("Required"),
    phoneNumber: Yup.string().required("Required"),
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    terms: props.custom?.tnc ? Yup.boolean()
      .required("Required")
      .oneOf([true], "Please accept terms and conditions") : null,
    marketingConsent: props.custom?.marketingConsent ? Yup.boolean() : null,
    ...dynamicKeys
  })

  const [state] = useState(initialValues);

  const terms = () => {
    const OS = platform.os.family
    const mobileOS = OS === 'iOS' || OS === 'Android'

    if (mobileOS || !mobileOS) { //open in app & ext browser
      window.open(TERMS, '_blank').focus()
    }
  }

  const postMessage = (output) => {
    try {
      window.ReactNativeWebView && window.ReactNativeWebView.postMessage(output);
    } catch (e) {
      console.error(e);
    }
  }

  const onChange = async (e) => {
    if (e.target.name.startsWith("email") && !errors[e.target.name]) {
      let loop = props.qty || 1
      const values = getValues()
      let duplicateFound = false
      let emailList = [e.target.value]

      for (let x = 0; x < loop; x++) {
        let index = x === 0 ? "" : x
        if (e.target.name !== "email" + index && e.target.value.length > 0) {
          if (e?.target?.value?.toLowerCase() === values["email" + index]?.toLowerCase()) {
            duplicateFound = true
          }
        }
      }
      if (duplicateFound) {
        setError(e.target.name, { type: "custom", message: "Duplicate email found" })
      }

      // const duplicateFoundinDB = await checkDuplicateEmail("0007", emailList)
      // if (duplicateFoundinDB) {
      //   setError(e.target.name, { type: "custom", message: "This email address has been registered, please provide another email address." })
      // }
    }
  }

  const onBlur = async (e) => {
    if (e.target.name.startsWith("email")) {
      let loop = props.qty || 1
      const values = getValues()
      let duplicateFound = false
      let emailList = [e.target.value]

      for (let x = 0; x < loop; x++) {
        let index = x === 0 ? "" : x
        if (e.target.name !== "email" + index && e.target.value.length > 0) {
          if (e?.target?.value?.toLowerCase() === values["email" + index]?.toLowerCase()) {
            duplicateFound = true
          }
        }
      }
      if (duplicateFound) {
        setError(e.target.name, { type: "custom", message: "Duplicate email found" })
      }

      // const duplicateFoundinDB = await checkDuplicateEmail("0007", emailList)
      // if (duplicateFoundinDB) {
      //   setError(e.target.name, { type: "custom", message: "This email address has been registered, please provide another email address." })
      // }
    }
  }

  const onSubmit = (e) => {

    let phoneNumber = e.phoneNumber ? `${e.countryCode}${e.phoneNumber}` : '+65'

    Object.keys(e).forEach((key) => {   //remove empty values
      if (!e[key] && typeof e[key] !== 'boolean') delete e[key]
    })

    e['phoneNumber'] = phoneNumber

    //remove non saving form values
    delete e['countryCode']
    delete e['otpVerify']
    delete e['otpCountryCode']
    delete e['otpPhoneNumber']


    if (props.qty && props.qty > 1) {
      e['participants'] = []
      for (let x = 2; x <= props.qty; x++) {
        let phoneNumberTemp = e['phoneNumber' + x] ? `${e['countryCode' + x]}${e['phoneNumber' + x]}` : '+65'
        e['phoneNumber' + x] = phoneNumberTemp
        let details = {
          "firstName": e['firstName' + x],
          "lastName": e['lastName' + x],
          "phoneNumber": e['phoneNumber' + x],
          "email": e['email' + x]
        }
        delete e['firstName' + x]
        delete e['lastName' + x]
        delete e['phoneNumber' + x]
        delete e['email' + x]
        e.participants.push(details)
        delete e['countryCode' + x]
      }
    }
    const output = JSON.stringify(e)
    postMessage(output);

    setTimeout(() => {
      reset()
    }, 1000);
  }

  const onError = (errors) => {
    console.error(errors);
  }

  const { register, handleSubmit, reset, getValues, setError, control, formState: { isValid, errors } } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: state,
    resolver: yupResolver(schema),
  });


  const contactTemplate = (value) => {
    let render = []
    let loop = value ? value : 1
    for (let x = 0; x < loop; x++) {
      let index = value ? x === 0 ? "" : (x + 1) : ""
      render.push(
        <div key={"contactInfo-" + x}>
          {value && (
            x === 0 ?
              <SubTitle>Participant 1 (Myself)</SubTitle>
              :
              <SubTitle style={{ marginTop: 10 }}>Participant {x + 1}</SubTitle>
          )
          }

          <FormField>
            <Form.Label>First Name<sup>*</sup></Form.Label>
            <Form.Control
              type="text"
              name={"firstName" + index}
              placeholder="Input your first name"
              // className={errors.firstName && "error"}
              {...register("firstName" + index)}
            />
            <ErrorMessage
              errors={errors}
              name={"firstName" + index}
              render={({ message }) => <ErrMsg>{message}</ErrMsg>}
            />
          </FormField>

          <FormField>
            <Form.Label>Last Name<sup>*</sup></Form.Label>
            <Form.Control
              type="text"
              name={"lastName" + index}
              placeholder="Input your last name"
              // className={errors.lastName && "error"}
              {...register("lastName" + index)}
            />
            <ErrorMessage
              errors={errors}
              name={"lastName" + index}
              render={({ message }) => <ErrMsg>{message}</ErrMsg>}
            />
          </FormField>

          <FormField>
            <Form.Label>Email Address<sup>* </sup><Form.Label style={{ fontSize: 12, fontWeight: "200" }}> (Tickets will be sent to this email)</Form.Label></Form.Label>
            <Form.Control
              type="text"
              autoCapitalize='none'
              autoCorrect='off'
              name={"email" + index}
              placeholder="Input your email address"
              className={errors.email && "error"}
              {...register("email" + index)}
            />
            <ErrorMessage
              errors={errors}
              name={"email" + index}
              render={({ message }) => <ErrMsg>{message}</ErrMsg>}
            />
          </FormField>

          <FormField>
            <Form.Label>Phone number<sup>* </sup>
              {/* <ToolTipIcon id='second' /> */}
              {/* <UncontrolledTooltip
                placement='right'
                target='second'
              >
                <p style={{ textAlign: 'left', marginBottom: 0 }}>
                  For marketing and payout purposes via paynow. Registration is opened to Singapore users only.
                </p>
              </UncontrolledTooltip> */}
            </Form.Label>
            <Col >
              <Row>
                <Col xs={"auto"}>
                  <Controller
                    name={"countryCode" + index}
                    control={control}
                    render={({ field: { onChange, value, name, ref } }) => {
                      const handleSelectChange = (e) => {
                        onChange(e?.value);
                      };
                      return (
                        <Select
                          inputRef={ref}
                          value={props.countryCode.find((e) => e.value === value)}
                          options={props.countryCode}
                          placeholder='Select...'
                          onChange={handleSelectChange}
                          styles={{
                            control: base => ({
                              ...base,
                              height: 34,
                              minHeight: 34,
                              width: 120,
                            })
                          }}
                          isDisabled={props.isDisabled}
                        />
                      )
                    }}
                  />
                  <ErrorMessage
                    errors={errors}
                    name={"countryCode" + index}
                    render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                  />
                </Col>
                <Col  >
                  <Form.Control
                    type="tel"
                    name={"phoneNumber" + index}
                    placeholder="Input your phone number"
                    // className={errors.phoneNumber && "error"}
                    {...register("phoneNumber" + index)}
                  />
                  <ErrorMessage
                    errors={errors}
                    name={"phoneNumber" + index}
                    render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                  />

                </Col>
              </Row>
            </Col>
          </FormField>
        </div>
      )
    }
    return render
  }
  return (
    <FormWrap onSubmit={handleSubmit(onSubmit, onError)} onChange={(e) => onChange(e)} onBlur={(e) => onBlur(e)}>
      <H2>Please fill in your contact info</H2>
      {contactTemplate(props.qty)}
      {props.custom?.customClause &&
        <FormFieldTerms>
          <Form.Check
            name={props.custom?.customClause.name}
            type="checkbox"
            className={errors[props.custom?.customClause.name] && "error"}
            label={
              props.custom?.customClause?.link ?
                <div style={{ color: "#272833" }}>I have read and agree to the <a href={props.custom?.customClause?.link} target='_blank' rel='noreferrer'>{props.custom?.customClause?.text}</a>.{props.custom?.customClause.required ? '*' : null}</div>
                :
                <div style={{ color: "#272833" }}>{props.custom?.customClause?.text}{props.custom?.customClause.required ? '*' : null}</div>
            }
            {...register(props.custom?.customClause.name)} />
          <ErrorMessage
            errors={errors}
            name={props.custom?.customClause.name}
            render={({ message }) => <ErrMsg>{message}</ErrMsg>}
          />
        </FormFieldTerms>
      }
      <FormFieldTerms>
        <Form.Check
          name="terms"
          type="checkbox"
          label={<div style={{ color: "#272833" }}>I have read and agree to the <a href='https://cardspal.com/ticket-sales-terms-and-conditions/' target='_blank' rel='noreferrer'>T&Cs of Ticket Sales</a> and <a href='https://cardspal.com/privacy-policy/' target='_blank' rel='noreferrer'>CardsPal&apos;s Data Privacy Policy</a>.*</div>}
          {...register("terms")}
        />
        <ErrorMessage
          errors={errors}
          name="terms"
          render={({ message }) => <ErrMsg>{message}</ErrMsg>}
        />
      </FormFieldTerms>

      <FormFieldSubscribe>
        <Form.Check
          type="checkbox"
          name="marketingConsent"
          label={MARKETING_TEXT}
          {...register("marketingConsent")}
        />
      </FormFieldSubscribe>
      <FormButton disabled={!isValid || Object.keys(errors).length > 0} type="submit">Proceed to checkout</FormButton>
    </FormWrap>
  )
};

const ToolTipIcon = styled(AiOutlineExclamationCircle)`
  color: #722ED1;
  font-size: 1rem;
  cursor: pointer;
`
const FormWrap = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute; 
  background: #fff;
  color: #000;
  width: 27.5rem;
  padding: 1.875rem;

  @media (max-width: 430px) {
    width: 24.5rem;
  }

  @media (max-width: 390px) {
    width: 22.5rem;
  }
`

const FormButton = styled(Button)`
  width: 100%;
  height: 2.8125rem;
  margin: 0 auto;
  background: #722ED1; 
  border-radius: 0.625rem;
  cursor: pointer;
`

const FormField = styled.div`
  margin-bottom: 1.25rem;

  .error {
    border: 1px solid red;
    color: #000;
  }
`

const FormFieldTerms = styled.div`
  margin-bottom: 1.25rem;

  .form-check [type=checkbox] {
    width: 15px;
    height: 15px;
  }
  label {
    font-weight: 400;
  }
  a {
    color: #722ED1;
  }
`

const FormFieldSubscribe = styled.div`
  margin-bottom: 1.75rem;
  
  .form-check [type=checkbox] {
    width: 15px;
    height: 15px;
  }
  label {
    font-weight: 400;
  }
`

const H2 = styled.h2`
  font-size: 18px;
  margin-bottom: 1.25rem;
`

const P = styled.p`
  color: red;
  margin: 0;
`

export default Appform_Contact_Legacy;