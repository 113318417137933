import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useForm, Controller } from "react-hook-form";
import { Form } from 'react-bootstrap';
import { Row, Col, CardBody, Spinner } from "reactstrap"
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import * as Yup from 'yup';
import { UncontrolledTooltip } from 'reactstrap';
import {
    SubTitle, Underline, CardContainer, ToolTipIcon, GroupP, 
    ErrMsg, Sup, SubmitButton, Terms, DeclineButton, SpinnerDiv, P
} from './Styled';
import { getPeopleInWebForm, getGroupMembers } from 'helpers/apiHelper'
import { OTHER_OPTION, DRESS, GROUP_ENTRY, MENU, SCB_DND_TERMS, DND_BEST_DRESS_MAX_MEMBERS, WAITLIST_DEAL_IDS,
    DND_OCT2023_CATEGORY, DEFAULT_DND_OCT_2023_MENU, DEFAULT_DND_OCT_2023_GROUPS, OTHER_ERROR_MSG } from  'utils/constants'
import WarningPopup from './WarningPopup'

const arePropsEqual = (prevProps, nextProps) => {
    // Convert webForm objects to strings for comparison
    const prevWebFormStr = JSON.stringify(prevProps.webForm);
    const nextWebFormStr = JSON.stringify(nextProps.webForm);

    // Compare webForm strings
    return prevWebFormStr === nextWebFormStr;
}

const ContactInfo = React.memo(function ContactInfo(props) {
    // console.log('contact info edit')
    const webFormId = props.webFormId
    const webForm = props.webForm || null
    const adminHash = props.adminHash
    const menuData = DEFAULT_DND_OCT_2023_MENU
    const groupData = DEFAULT_DND_OCT_2023_GROUPS

    const schema = Yup.object().shape({
        bankId: Yup.string().required("Required"),
        email: Yup.string()
            .email('Invalid email address')
            .required('Required'),
        phoneNumber: Yup.string().required("Required"),
        firstName: Yup.string().required("Required"),
        lastName: Yup.string().required("Required"),
        groupNameOption: Yup.string().required("Required"),
        groupName: Yup.string().when('groupNameOption', {
            is: OTHER_OPTION, 
            then: Yup.string()
                .trim('The field cannot start or end with a space')
                .matches(/^[a-zA-Z0-9- ]*$/, 'Only alphanumeric characters and dash are allowed')
                .required('Required'), 
            otherwise: Yup.string().trim('The field cannot start or end with a space') 
        }),  
        transport: Yup.string().required("Required"),
        dress: Yup.string().required("Required"),
        menu: Yup.string().required("Required"),
        cancelWarning: props.custom?.cancellationWarning ? Yup.boolean()
            .required("Required")
            .oneOf([true], "Please accept terms and conditions") : null,
        tableNumber: Yup.string()
    })

    // const [state] = useState(initialValues);
    const [isLoading, setIsLoading] = useState(false)
    // const [emailInput, setEmailInput] = useState("")
    // const [phoneInput, setPhoneInput] = useState(null)
    const [allGroupOptions, setAllGroupOptions] = useState([])
    const [groupNames, setGroupNames] = useState([])
    const [peopleInGroup, setPeopleInGroup] = useState([])
    const [selectedGroupName, setSelectedGroupName] = useState({})
    const [isGroupNameDuplicate, setIsGroupNameDuplicate] = useState(false)
    const [selectedMenu, setSelectedMenu] = useState([])
    // const [isGroupNameChange, setIsGroupNameChange] = useState(false)
    const [groupNameOptionNote, setGroupNameOptionNote] = useState(<GroupP>....</GroupP>)
    const [disableGroupOption, setDisableGroupOption] = useState(false)
    const [viewMembersLink, setViewMembersLink] = useState('')
    const [warning, setWarning] = useState({show: false})
    const isWaitlist = WAITLIST_DEAL_IDS.includes(props.dealId)

    const { 
        register, 
        unregister, 
        clearErrors,
        getValues, 
        setError, 
        setValue, 
        handleSubmit, 
        control, 
        formState: { 
            errors, 
            isSubmitSuccessful, 
            isValid 
        } 
    } = useForm({
            mode: "onChange",
            reValidateMode: "onChange",
            resolver: yupResolver(schema)
        });

    useEffect(async () => {
        if (!webForm) return
        console.log({webForm})
        setValue('bankId', webForm.bankId)
        setValue('email', webForm.email)
        setValue('firstName', webForm.firstName)
        setValue('lastName', webForm.lastName)
        setValue('dress', webForm.dress)
        setValue('transport', webForm.transport)
        setValue('phoneNumber', webForm.phoneNumber)
        const menu = {label: webForm.menu, value: webForm.menu}
        const groupName = {label: webForm.groupName, value: webForm.groupName}
        setValue('menu', webForm.menu)
        setValue('groupName', webForm.groupName)
        setValue('groupNameOption', webForm.groupNameOption)
        setValue('tableNumber', webForm.tableNumber)
        setSelectedMenu(menu)
        setSelectedGroupName(groupName)
        setDisableGroupOption(webForm.creator==='1' ? false: true)
        setViewMembers(webForm.groupName)
        const foundObject = groupData.find(item => item.value.toLowerCase() === webForm.groupNameOption.toLowerCase())
        if (foundObject) {
            setGroupNameOptionNote(<GroupP>You will have to stick to the default options selected for this choice</GroupP>)
        } else {
            getOtherGroupData(webForm.groupName)
        }
    }, [webForm])

    useEffect(() => {
        let isMounted = true

        if (!props.groupNames || props.groupNames?.length===0) return
        console.log('processGroupNames')
        processGroupNames(props.groupNames, isMounted)

        return () => {
            isMounted = false; // update the flag when the component unmounts
        };
    }, [props.groupNames])

    const onError = (e) => {
        console.log('---error', e)
    }

    const setViewMembers = async (groupName) => {
        let members = []
        const response = await getGroupMembers(groupName, webFormId)
        if (response.statusCode === 200){
            members = JSON.parse(response.body)
            if (members.length > 0) {
                const viewMembers = `/webform/view-group-members/${webForm.groupName}?webFormId=${webFormId}&userId=${webForm.userId}`
                setViewMembersLink(
                    <span style={{ fontSize:11, textDecoration:"underline" }}>
                        <a style={{ color: 'blue' }} href={viewMembers} target='_blank' rel='noreferrer'>View members</a>
                    </span>
                )
            } else {
                setViewMembersLink('')
            }
        }
    }

    const handleGroupChange = (e) => {
        setViewMembersLink('')
        setSelectedGroupName(e)
        clearErrors('groupNameOption')

        if (e.value === OTHER_OPTION) {
            setGroupNameOptionNote('')
            setValue('groupName', '')
            setValue('menu','')
            setValue('dress','')
            setSelectedMenu('')
            setDisableGroupOption(false)

        } else {
            let indexFound = -1
            const foundDefaultGroup = groupData.find((item, index) => {
                if (item.value?.toLowerCase() === e.value?.toLowerCase()) {
                    indexFound = index
                    return true
                }
            })
            if (foundDefaultGroup) {
                setSelectedMenu(menuData[indexFound])
                setDisableGroupOption(true)
                setValue('menu', menuData[indexFound].value)
                setValue('dress', '0')
                setValue('groupName', e.value)
                setValue('groupNameOption', e.value)
                setGroupNameOptionNote(<GroupP>You will have to stick to the default options selected for this choice</GroupP>)
            } else {
                getOtherGroupData(e.value)
            }
        }
        if (e.value === webForm.groupName) setViewMembers(e.value) // we only show View Members if the user is a member
        setValue('groupNameOption', e.value)
    }

    const handleMenuChange = (e) => {
        setSelectedMenu(e)
        setValue('menu', e.value)
    }

    const processGroupNames = async (groups, isMounted) => {
        if (isMounted) {
            
            let allValidGroups = groups.Items.filter(item => {
                if (WAITLIST_DEAL_IDS.includes(props.dealId)){
                    return item.status==='1' && item.dress==='0'
                } else {
                    return item.status === '1'
                }
            })
            
            groups.Items = allValidGroups //mutate
            setAllGroupOptions(allValidGroups)
            
            const createdGroups = groups.Items.filter(item => {
              return item.status === '1' && item.createdBy
            })

            // sort by name a, b, c...
            createdGroups.sort((a, b) => {
                if(a.name < b.name) { return -1; }
                if(a.name > b.name) { return 1; }
                return 0;
            });
            
            // add best dress on label
            const _createdGroups = createdGroups.map(item => {
                if (item.dress==='1') {
                    return {value: item.name, name: item.name + ' [best dress]'}
                } else {
                    return {value: item.name, name: item.name}
                }
            })
         
            // order the drop down
            const defaultGroups = groupData.map(item => {return {...item, name: item.value } })
            const finalGroup = defaultGroups.concat([{ value: OTHER_OPTION, name: OTHER_OPTION }]).concat(_createdGroups)
            const _groupData = finalGroup.map(item => {
                return {
                value: item.value,
                label: item.name
                }
            })
            setGroupNames(_groupData)
        }
    }

    const getGroupCreator = (groupMembers) => {
        let groupLeader = groupMembers.find(item => {
            return item.creator === '1'
        })
        return groupLeader
    }

    const getOtherGroupData = async (group) => {
        console.log('getOtherGroupData ', group)
        setGroupNameOptionNote(<Spinner size="sm" style={{margin:3}}/>)
        let response = await getPeopleInWebForm(webFormId, "")
        if (!response.error) {
            let webFormData = JSON.parse(response.body)
            // console.log({webFormData})
            let filterDataByGroup = webFormData.filter(item => {
                return group === item.groupName && item.status === '1' ;
            });
            // console.log({filterDataByGroup})
            let groupCreator = webFormData.find(item => {
                return group === item.groupName && item.status === '1' && item.creator === '1';
            });
            // console.log({groupCreator})
            setPeopleInGroup(filterDataByGroup)
          
            if (filterDataByGroup.length > 0 && groupCreator) {
                let menu = { value: groupCreator?.menu, label: groupCreator?.menu }
                let dress = groupCreator?.dress
                setSelectedMenu(menu)
                setValue('menu', menu.value)
                setValue('dress', dress)
                setDisableGroupOption(webForm.creator==='1' && webForm.userId===groupCreator?.userId?false:true)

                if (dress==='1' && filterDataByGroup.length >= DND_BEST_DRESS_MAX_MEMBERS) {
                    setGroupNameOptionNote(<GroupP>Max group members for best dress is {DND_BEST_DRESS_MAX_MEMBERS} only.</GroupP>)
                    setError('groupNameOption', {type:'custom', message: 'Group is full.'})
                } else {
                    const leaderName = groupCreator?.firstName + ' ' + groupCreator?.lastName
                    const personOrPeople  = filterDataByGroup?.length > 1 ? 'people are' : 'person is'
                    setGroupNameOptionNote(<GroupP><span style={{color: '#7A6FBE', fontWeight: "800"}}>{filterDataByGroup?.length}</span> {personOrPeople} already in this Group. Menu choice and best dressed opt-in for this Group is decided by the Group Leader <span style={{color: '#7A6FBE', fontWeight: "800"}}>{leaderName}</span>.</GroupP>)
                }
            } else {
                setValue('groupName', group)
                setValue('groupNameOption', OTHER_OPTION)
                setGroupNameOptionNote('')
            }
        } else {
          setGroupNameOptionNote('')
        }
        
    }

    const cancelReservation = () => {
        props.cancelReservation()
    }

    const DndTemplate = () => {
        return (
            <>
            {selectedGroupName.value === OTHER_OPTION &&
                <>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Form.Label>Enter Group Name<sup>*</sup>
                            <ToolTipIcon id='third' />
                            <UncontrolledTooltip
                                placement='right'
                                target='third'
                            >
                                <p style={{ textAlign: 'left', marginBottom: 0 }}>{GROUP_ENTRY}</p>
                            </UncontrolledTooltip>
                            </Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Control  
                                type="text" 
                                name="groupName"
                                placeholder="Input your group name" 
                                className={errors.groupName && "error"}
                                {...register("groupName")}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="groupName"
                                render={({ message }) => <P>{message}</P>}
                            />
                            {isGroupNameDuplicate && 
                            <P>Matching group name detected: {getValues('groupName').toUpperCase()}. Please submit a unique name or select from the above field.</P>}
                        </Col>
                    </Row>
                    <Underline className='mb-3' />
                </>
            }
            
            <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Menu Choice<sup>*</sup>
                    { selectedGroupName?.value !== OTHER_OPTION  && 
                        <span>
                        <ToolTipIcon id='fourth' />
                        <UncontrolledTooltip
                            placement='right'
                            target='fourth'
                        >
                            {MENU}
                        </UncontrolledTooltip>
                        </span> }
                </Form.Label>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Controller
                        name='menu' 
                        control={control}
                        render={({ ref }) => (
                            <Select
                            isDisabled={disableGroupOption || props.disableEditing}
                            isSearchable
                            inputRef={ref}
                            value={selectedMenu}
                            options={menuData}
                            placeholder='Select an option'
                            onChange={handleMenuChange}
                            />
                        )}
                        />
                        <ErrorMessage
                        errors={errors}
                        name="menu"
                        render={({ message }) => <P>{message}</P>}
                    />
                </Col>
            </Row>
            <Underline className='mb-3' />
            <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Form.Label>Register for Best Dressed Table?<sup>*</sup>
                        <ToolTipIcon id='tooltip' />
                        <UncontrolledTooltip
                            placement='right'
                            target='tooltip'
                        >
                            <p style={{ textAlign: 'left', marginBottom: 0 }}>{DRESS}</p>
                        </UncontrolledTooltip>
                    </Form.Label><br />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Form.Check
                        disabled={disableGroupOption || props.disableEditing || isWaitlist}
                        inline
                        name="dress"
                        type="radio" 
                        label="Yes"
                        value="1"
                        // onChange={(e) => onChangeFormFields(e)}
                        {...register("dress")}
                    />
                    <Form.Check 
                        disabled={disableGroupOption || props.disableEditing}
                        inline
                        name="dress"
                        type="radio" 
                        label="No"
                        value="0"
                        style={{marginLeft: 120}}
                        // onChange={(e) => onChangeFormFields(e)}
                        {...register("dress")}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="dress"
                        render={({ message }) => <P>{message}</P>}
                    />
                </Col>
            </Row>
            <Underline className='mb-3' />
            </>
        )
    }

    const contactTemplate = (value) => {
        let render = []
        let loop = value ? value : 1
        for (let x = 0; x < loop; x++) {
            let index = value ? x === 0 ? "" : (x + 1) : ""
            render.push(
                <div key={"contactInfo-" + x} className='mb-4' >
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Label>Bank ID<Sup>*</Sup></Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Control
                                disabled={true}
                                type="text"
                                name={"bankId" + index}
                                placeholder="Enter your bank ID "
                                className={errors.bankId && "error"}
                                autoComplete='off'
                                {...register("bankId" + index)}
                            />
                            <ErrorMessage
                                errors={errors}
                                name={"bankId" + index}
                                render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                            />
                        </Col>
                    </Row>
                    <Underline className='mb-3' />
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Label>Corporate email address<Sup>*</Sup></Form.Label>
                            {/* <div style={{ marginBottom: 5 }}>
                                <div style={{ color: "#6B6C7E", fontSize: 12 }}> Wristband redemption information and payment receipt will be sent to this email  </div>
                            </div> */}
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Control
                                type="text"
                                name={"email" + index}
                                placeholder="Enter your corporate email"
                                className={errors.email && "error"}
                                autoComplete='off'
                                autoCapitalize='none'
                                disabled={true}
                                {...register("email" + index)}
                            />
                            <ErrorMessage
                                errors={errors}
                                name={"email" + index}
                                render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                            />
                        </Col>
                    </Row>
                    <Underline className='mb-3' />
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Label>First name<Sup>*</Sup></Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Control
                                type="text"
                                name={"firstName" + index}
                                placeholder="Enter your first name "
                                className={errors.firstName && "error"}
                                autoComplete='off'
                                disabled={true}
                                {...register("firstName" + index)}
                            />
                            <ErrorMessage
                                errors={"firstName" + index}
                                name="firstName"
                                render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                            />
                        </Col>
                    </Row>
                    <Underline className='mb-3' />
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Label>Last name<Sup>*</Sup></Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Control
                                type="text"
                                name={"lastName" + index}
                                placeholder="Enter your last name"
                                className={errors.lastName && "error"}
                                autoComplete='off'
                                disabled={true}
                                {...register("lastName" + index)}
                            />
                            <ErrorMessage
                                errors={errors}
                                name={"lastName" + index}
                                render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                            />
                        </Col>
                    </Row>
                    <Underline className='mb-3' />
                    <Row style={{minHeight: 100}}>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Label>Group Name<sup>*</sup>
                                <ToolTipIcon id='second' />
                                <UncontrolledTooltip
                                    placement='right'
                                    target='second'
                                >
                                    <p style={{ textAlign: 'left', marginBottom: 0 }}>
                                    Can select from the default group options or groups created by other user.
                                    <br/>Can also create your own group by selecting the Others option.
                                    </p>
                                </UncontrolledTooltip>
                            </Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Controller
                                name="groupNameOption"
                                control={control}
                                render={({ ref }) => (
                                    <>
                                    <Select
                                        isDisabled={props.disableEditing}
                                        isSearchable
                                        inputRef={ref}
                                        value={selectedGroupName}
                                        options={groupNames}
                                        placeholder='Select...'
                                        onChange={handleGroupChange}
                                        styles={{
                                            control: base => ({
                                                ...base,
                                                height: 34,
                                                minHeight: 34
                                            })
                                        }}
                                    />
                                    {viewMembersLink}
                                    </>
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="groupNameOption"
                                render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                            />
                            {groupNameOptionNote}
                        </Col>
                    </Row>
                    <Underline className='mb-3' />
                    <DndTemplate/>
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Form.Label>Transportation from CBP?<sup>*</sup></Form.Label>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Form.Check
                                    disabled={props.disableEditing}
                                    inline
                                    name="transport"
                                    type="radio" 
                                    label="Yes"
                                    value="1"
                                    {...register("transport")}
                                />
                                <Form.Check 
                                    disabled={props.disableEditing}
                                    inline
                                    name="transport"
                                    type="radio" 
                                    label="No"
                                    value="0" 
                                    style={{marginLeft: 120}}
                                    {...register("transport")}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="transport"
                                    render={({ message }) => <P>{message}</P>}
                                />
                                </Col>
                        </Row>
                        <Underline className='mb-3' />
                        
                        {
                        adminHash && 
                        <>
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Form.Label>Table Number</Form.Label>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Form.Control
                                        type="text"
                                        name={"tableNumber" + index}
                                        placeholder="Enter user table number"
                                        className={errors.tableNumber && "error"}
                                        autoComplete='off'
                                        autoCapitalize='none'
                                        disabled={false}
                                        {...register("tableNumber" + index)}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={"tableNumber" + index}
                                        render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                    />
                                </Col>
                            </Row>
                            <Underline className='mb-3' />
                        </>
                        }
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Label>Phone number*</Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Control
                                disabled = {true}
                                type="text"
                                name={"phoneNumber" + index}
                                className={errors.phoneNumber && "error"}
                                autoComplete='off'
                                {...register("phoneNumber" + index)}
                            />
                            <ErrorMessage
                                errors={errors}
                                name={"phoneNumber" + index}
                                render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                            />
                        </Col>
                    </Row>
                    
                    {isLoading &&
                        <SpinnerDiv>
                            <Spinner />
                        </SpinnerDiv>}
                </div>
            )
        }
        return render
    }

    const processSubmit = async (e) => {
        setIsLoading(true)
        const values = getValues()
        console.log('processSubmit ', {values},{e}, {selectedGroupName})
        delete e.reason
        delete e.reasonText
        let params = {
            ...webForm,
            ...e,
            groupName :selectedGroupName.value===OTHER_OPTION ? e.groupName.toLowerCase() : selectedGroupName.value.toLowerCase(),
            category : DND_OCT2023_CATEGORY,
            // creator : selectedGroupName.value===OTHER_OPTION ? '1' :'0'
        }
        console.log('processSubmit: ', {params})
        props.onSubmit(params)
        setIsLoading(false)
    }

    const DnDCancellationWarning = () => {
        return (
            
            <Row className='justify-content-center' style={{ marginTop: 10 }}>
                {props.disableEditing ?
                <span style={{textAlign:"center", color:"red"}}>{props.disableEditingMessage}</span>
                :
                <>
                    <Terms>
                        <Form.Check 
                            name="cancelWarning"
                            type="checkbox" 
                            label={(
                                <span>I have read and agree to the Late Cancellation / No-Show penalties (SG$100) and
                                <a href={SCB_DND_TERMS} target='_blank' rel='noreferrer'> T&Cs</a> of registration<sup>*</sup>
                                </span>
                            )}
                            {...register("cancelWarning")}
                        />
                    </Terms>
                    <SubmitButton
                        type="submit"
                        className="btn btn-primary btn-block"
                        disabled={
                            !isValid ||
                            Object.keys(errors).length > 0
                        }
                    >Update reservation
                    </SubmitButton>
                    <DeclineButton
                        onClick={() => cancelReservation(true)}
                        className="btn "
                    >Cancel reservation
                    </DeclineButton>
                </>
                }
            </Row>
        )
    }

    const onChangeFormFields = (e) => {
        console.log('onChangeFormFields ',e.target.name, e.target.value, peopleInGroup.length, DND_BEST_DRESS_MAX_MEMBERS)
        if (e.target.name==='dress' && e.target.value==='1' && peopleInGroup.length > DND_BEST_DRESS_MAX_MEMBERS) {
            setWarning({show: true, warningType: OTHER_ERROR_MSG, message: `Cannot select BEST DRESS option as the current members are more than ${DND_BEST_DRESS_MAX_MEMBERS}`})
            setValue('dress', '0')
        }
    }

    return (
        <React.Fragment>
            <Row className='justify-content-center'>
                <Col>
                    <Form onSubmit={handleSubmit(processSubmit, onError)} onChange={(e) => onChangeFormFields(e)}>
                        <fieldset disabled={props.isDisabled}>
                            <CardContainer style={props.styling ?? { backgroundColor: "#fff" }} >
                                <CardBody>
                                    <SubTitle>You can update your group name and its options here.</SubTitle>
                                    {contactTemplate(props.issueQty)}
                                </CardBody>
                            </CardContainer>
                            {props.custom?.cancellationWarning && <DnDCancellationWarning />}
                        </fieldset>
                    </Form>
                </Col>
            </Row>
            { warning.show && <WarningPopup open={true} warningType={warning.warningType} message={warning.message} tryAgain={()=>setWarning({show: false})} /> }
        </React.Fragment>

    )
}, arePropsEqual)

export default ContactInfo