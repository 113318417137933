/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTags = /* GraphQL */ `
  mutation CreateTags(
    $input: CreateTagsInput!
    $condition: ModelTagsConditionInput
  ) {
    createTags(input: $input, condition: $condition) {
      id
      merchantId
      tagName
      tagType
      highlights
      createdAt
      updatedAt
    }
  }
`;
export const updateTags = /* GraphQL */ `
  mutation UpdateTags(
    $input: UpdateTagsInput!
    $condition: ModelTagsConditionInput
  ) {
    updateTags(input: $input, condition: $condition) {
      id
      merchantId
      tagName
      tagType
      highlights
      createdAt
      updatedAt
    }
  }
`;
export const deleteTags = /* GraphQL */ `
  mutation DeleteTags(
    $input: DeleteTagsInput!
    $condition: ModelTagsConditionInput
  ) {
    deleteTags(input: $input, condition: $condition) {
      id
      merchantId
      tagName
      tagType
      highlights
      createdAt
      updatedAt
    }
  }
`;
export const createDealApproval = /* GraphQL */ `
  mutation CreateDealApproval(
    $input: CreateDealApprovalInput!
    $condition: ModelDealApprovalConditionInput
  ) {
    createDealApproval(input: $input, condition: $condition) {
      pk
      isApproved
      deal {
        address
        aggregator
        approval {
          pk
          isApproved
          createdYearMonth
          createdAt
          updatedAt
          createdByUser
          createdByGroups
        }
        area
        bank
        brand
        mainCat
        cc_buddy_category
        ccbuddy_card_name
        country
        deal_handler
        display_merchant_name
        district
        district_name
        end
        end_timestamp
        festive
        general_location
        geohashlong
        geohashshort
        google_id
        google_placeid
        google_type
        image_path
        image_url
        is_online
        isSponsored
        sponsoredRank
        latitude
        longitude
        merchant_id
        merchant_name
        merchant_name_lowercase
        merchant_unique_id
        online_company
        phone
        pk
        postal_code
        promo_code
        promotion
        promotion_analytic
        promotion_caption
        rank
        rank_updated
        score
        sector
        start
        start_timestamp
        store_id
        subcategory
        TR_tags
        tags {
          nextToken
        }
        terms
        usable_promocode
        valid
        website
        createdAt
        createdBy
        createdByUser
        updatedAt
        updatedBy
        isPinned
        pinPageLocation
        pinOrder
        purchasable
        actualPrice
        discount
        discountPrice
        purchasePrice
        purchaseLimitPerCustomer
        showInOutletIds
        additionalPromotionInfo {
          id
          termsText
          iconUrl
        }
      }
      createdYearMonth
      createdAt
      updatedAt
      createdByUser
      createdByGroups
    }
  }
`;
export const updateDealApproval = /* GraphQL */ `
  mutation UpdateDealApproval(
    $input: UpdateDealApprovalInput!
    $condition: ModelDealApprovalConditionInput
  ) {
    updateDealApproval(input: $input, condition: $condition) {
      pk
      isApproved
      deal {
        address
        aggregator
        approval {
          pk
          isApproved
          createdYearMonth
          createdAt
          updatedAt
          createdByUser
          createdByGroups
        }
        area
        bank
        brand
        mainCat
        cc_buddy_category
        ccbuddy_card_name
        country
        deal_handler
        display_merchant_name
        district
        district_name
        end
        end_timestamp
        festive
        general_location
        geohashlong
        geohashshort
        google_id
        google_placeid
        google_type
        image_path
        image_url
        is_online
        isSponsored
        sponsoredRank
        latitude
        longitude
        merchant_id
        merchant_name
        merchant_name_lowercase
        merchant_unique_id
        online_company
        phone
        pk
        postal_code
        promo_code
        promotion
        promotion_analytic
        promotion_caption
        rank
        rank_updated
        score
        sector
        start
        start_timestamp
        store_id
        subcategory
        TR_tags
        tags {
          nextToken
        }
        terms
        usable_promocode
        valid
        website
        createdAt
        createdBy
        createdByUser
        updatedAt
        updatedBy
        isPinned
        pinPageLocation
        pinOrder
        purchasable
        actualPrice
        discount
        discountPrice
        purchasePrice
        purchaseLimitPerCustomer
        showInOutletIds
        additionalPromotionInfo {
          id
          termsText
          iconUrl
        }
      }
      createdYearMonth
      createdAt
      updatedAt
      createdByUser
      createdByGroups
    }
  }
`;
export const deleteDealApproval = /* GraphQL */ `
  mutation DeleteDealApproval(
    $input: DeleteDealApprovalInput!
    $condition: ModelDealApprovalConditionInput
  ) {
    deleteDealApproval(input: $input, condition: $condition) {
      pk
      isApproved
      deal {
        address
        aggregator
        approval {
          pk
          isApproved
          createdYearMonth
          createdAt
          updatedAt
          createdByUser
          createdByGroups
        }
        area
        bank
        brand
        mainCat
        cc_buddy_category
        ccbuddy_card_name
        country
        deal_handler
        display_merchant_name
        district
        district_name
        end
        end_timestamp
        festive
        general_location
        geohashlong
        geohashshort
        google_id
        google_placeid
        google_type
        image_path
        image_url
        is_online
        isSponsored
        sponsoredRank
        latitude
        longitude
        merchant_id
        merchant_name
        merchant_name_lowercase
        merchant_unique_id
        online_company
        phone
        pk
        postal_code
        promo_code
        promotion
        promotion_analytic
        promotion_caption
        rank
        rank_updated
        score
        sector
        start
        start_timestamp
        store_id
        subcategory
        TR_tags
        tags {
          nextToken
        }
        terms
        usable_promocode
        valid
        website
        createdAt
        createdBy
        createdByUser
        updatedAt
        updatedBy
        isPinned
        pinPageLocation
        pinOrder
        purchasable
        actualPrice
        discount
        discountPrice
        purchasePrice
        purchaseLimitPerCustomer
        showInOutletIds
        additionalPromotionInfo {
          id
          termsText
          iconUrl
        }
      }
      createdYearMonth
      createdAt
      updatedAt
      createdByUser
      createdByGroups
    }
  }
`;
export const createDealMaster = /* GraphQL */ `
  mutation CreateDealMaster(
    $input: CreateDealMasterInput!
    $condition: ModelDealMasterConditionInput
  ) {
    createDealMaster(input: $input, condition: $condition) {
      address
      aggregator
      approval {
        pk
        isApproved
        deal {
          address
          aggregator
          area
          bank
          brand
          mainCat
          cc_buddy_category
          ccbuddy_card_name
          country
          deal_handler
          display_merchant_name
          district
          district_name
          end
          end_timestamp
          festive
          general_location
          geohashlong
          geohashshort
          google_id
          google_placeid
          google_type
          image_path
          image_url
          is_online
          isSponsored
          sponsoredRank
          latitude
          longitude
          merchant_id
          merchant_name
          merchant_name_lowercase
          merchant_unique_id
          online_company
          phone
          pk
          postal_code
          promo_code
          promotion
          promotion_analytic
          promotion_caption
          rank
          rank_updated
          score
          sector
          start
          start_timestamp
          store_id
          subcategory
          TR_tags
          terms
          usable_promocode
          valid
          website
          createdAt
          createdBy
          createdByUser
          updatedAt
          updatedBy
          isPinned
          pinPageLocation
          pinOrder
          purchasable
          actualPrice
          discount
          discountPrice
          purchasePrice
          purchaseLimitPerCustomer
          showInOutletIds
        }
        createdYearMonth
        createdAt
        updatedAt
        createdByUser
        createdByGroups
      }
      area
      bank
      brand
      mainCat
      cc_buddy_category
      ccbuddy_card_name
      country
      deal_handler
      display_merchant_name
      district
      district_name
      end
      end_timestamp
      festive
      general_location
      geohashlong
      geohashshort
      google_id
      google_placeid
      google_type
      image_path
      image_url
      is_online
      isSponsored
      sponsoredRank
      latitude
      longitude
      merchant_id
      merchant_name
      merchant_name_lowercase
      merchant_unique_id
      online_company
      phone
      pk
      postal_code
      promo_code
      promotion
      promotion_analytic
      promotion_caption
      rank
      rank_updated
      score
      sector
      start
      start_timestamp
      store_id
      subcategory
      TR_tags
      tags {
        items {
          id
          merchantId
          tagName
          tagType
          highlights
          createdAt
          updatedAt
        }
        nextToken
      }
      terms
      usable_promocode
      valid
      website
      createdAt
      createdBy
      createdByUser
      updatedAt
      updatedBy
      isPinned
      pinPageLocation
      pinOrder
      purchasable
      actualPrice
      discount
      discountPrice
      purchasePrice
      purchaseLimitPerCustomer
      showInOutletIds
      additionalPromotionInfo {
        id
        termsText
        iconUrl
      }
    }
  }
`;
export const updateDealMaster = /* GraphQL */ `
  mutation UpdateDealMaster(
    $input: UpdateDealMasterInput!
    $condition: ModelDealMasterConditionInput
  ) {
    updateDealMaster(input: $input, condition: $condition) {
      address
      aggregator
      approval {
        pk
        isApproved
        deal {
          address
          aggregator
          area
          bank
          brand
          mainCat
          cc_buddy_category
          ccbuddy_card_name
          country
          deal_handler
          display_merchant_name
          district
          district_name
          end
          end_timestamp
          festive
          general_location
          geohashlong
          geohashshort
          google_id
          google_placeid
          google_type
          image_path
          image_url
          is_online
          isSponsored
          sponsoredRank
          latitude
          longitude
          merchant_id
          merchant_name
          merchant_name_lowercase
          merchant_unique_id
          online_company
          phone
          pk
          postal_code
          promo_code
          promotion
          promotion_analytic
          promotion_caption
          rank
          rank_updated
          score
          sector
          start
          start_timestamp
          store_id
          subcategory
          TR_tags
          terms
          usable_promocode
          valid
          website
          createdAt
          createdBy
          createdByUser
          updatedAt
          updatedBy
          isPinned
          pinPageLocation
          pinOrder
          purchasable
          actualPrice
          discount
          discountPrice
          purchasePrice
          purchaseLimitPerCustomer
          showInOutletIds
        }
        createdYearMonth
        createdAt
        updatedAt
        createdByUser
        createdByGroups
      }
      area
      bank
      brand
      mainCat
      cc_buddy_category
      ccbuddy_card_name
      country
      deal_handler
      display_merchant_name
      district
      district_name
      end
      end_timestamp
      festive
      general_location
      geohashlong
      geohashshort
      google_id
      google_placeid
      google_type
      image_path
      image_url
      is_online
      isSponsored
      sponsoredRank
      latitude
      longitude
      merchant_id
      merchant_name
      merchant_name_lowercase
      merchant_unique_id
      online_company
      phone
      pk
      postal_code
      promo_code
      promotion
      promotion_analytic
      promotion_caption
      rank
      rank_updated
      score
      sector
      start
      start_timestamp
      store_id
      subcategory
      TR_tags
      tags {
        items {
          id
          merchantId
          tagName
          tagType
          highlights
          createdAt
          updatedAt
        }
        nextToken
      }
      terms
      usable_promocode
      valid
      website
      createdAt
      createdBy
      createdByUser
      updatedAt
      updatedBy
      isPinned
      pinPageLocation
      pinOrder
      purchasable
      actualPrice
      discount
      discountPrice
      purchasePrice
      purchaseLimitPerCustomer
      showInOutletIds
      additionalPromotionInfo {
        id
        termsText
        iconUrl
      }
    }
  }
`;
export const deleteDealMaster = /* GraphQL */ `
  mutation DeleteDealMaster(
    $input: DeleteDealMasterInput!
    $condition: ModelDealMasterConditionInput
  ) {
    deleteDealMaster(input: $input, condition: $condition) {
      address
      aggregator
      approval {
        pk
        isApproved
        deal {
          address
          aggregator
          area
          bank
          brand
          mainCat
          cc_buddy_category
          ccbuddy_card_name
          country
          deal_handler
          display_merchant_name
          district
          district_name
          end
          end_timestamp
          festive
          general_location
          geohashlong
          geohashshort
          google_id
          google_placeid
          google_type
          image_path
          image_url
          is_online
          isSponsored
          sponsoredRank
          latitude
          longitude
          merchant_id
          merchant_name
          merchant_name_lowercase
          merchant_unique_id
          online_company
          phone
          pk
          postal_code
          promo_code
          promotion
          promotion_analytic
          promotion_caption
          rank
          rank_updated
          score
          sector
          start
          start_timestamp
          store_id
          subcategory
          TR_tags
          terms
          usable_promocode
          valid
          website
          createdAt
          createdBy
          createdByUser
          updatedAt
          updatedBy
          isPinned
          pinPageLocation
          pinOrder
          purchasable
          actualPrice
          discount
          discountPrice
          purchasePrice
          purchaseLimitPerCustomer
          showInOutletIds
        }
        createdYearMonth
        createdAt
        updatedAt
        createdByUser
        createdByGroups
      }
      area
      bank
      brand
      mainCat
      cc_buddy_category
      ccbuddy_card_name
      country
      deal_handler
      display_merchant_name
      district
      district_name
      end
      end_timestamp
      festive
      general_location
      geohashlong
      geohashshort
      google_id
      google_placeid
      google_type
      image_path
      image_url
      is_online
      isSponsored
      sponsoredRank
      latitude
      longitude
      merchant_id
      merchant_name
      merchant_name_lowercase
      merchant_unique_id
      online_company
      phone
      pk
      postal_code
      promo_code
      promotion
      promotion_analytic
      promotion_caption
      rank
      rank_updated
      score
      sector
      start
      start_timestamp
      store_id
      subcategory
      TR_tags
      tags {
        items {
          id
          merchantId
          tagName
          tagType
          highlights
          createdAt
          updatedAt
        }
        nextToken
      }
      terms
      usable_promocode
      valid
      website
      createdAt
      createdBy
      createdByUser
      updatedAt
      updatedBy
      isPinned
      pinPageLocation
      pinOrder
      purchasable
      actualPrice
      discount
      discountPrice
      purchasePrice
      purchaseLimitPerCustomer
      showInOutletIds
      additionalPromotionInfo {
        id
        termsText
        iconUrl
      }
    }
  }
`;
export const createMerchantOutlet = /* GraphQL */ `
  mutation CreateMerchantOutlet(
    $input: CreateMerchantOutletInput!
    $condition: ModelMerchantOutletConditionInput
  ) {
    createMerchantOutlet(input: $input, condition: $condition) {
      address
      deals {
        items {
          address
          aggregator
          area
          bank
          brand
          mainCat
          cc_buddy_category
          ccbuddy_card_name
          country
          deal_handler
          display_merchant_name
          district
          district_name
          end
          end_timestamp
          festive
          general_location
          geohashlong
          geohashshort
          google_id
          google_placeid
          google_type
          image_path
          image_url
          is_online
          isSponsored
          sponsoredRank
          latitude
          longitude
          merchant_id
          merchant_name
          merchant_name_lowercase
          merchant_unique_id
          online_company
          phone
          pk
          postal_code
          promo_code
          promotion
          promotion_analytic
          promotion_caption
          rank
          rank_updated
          score
          sector
          start
          start_timestamp
          store_id
          subcategory
          TR_tags
          terms
          usable_promocode
          valid
          website
          createdAt
          createdBy
          createdByUser
          updatedAt
          updatedBy
          isPinned
          pinPageLocation
          pinOrder
          purchasable
          actualPrice
          discount
          discountPrice
          purchasePrice
          purchaseLimitPerCustomer
          showInOutletIds
        }
        nextToken
      }
      mainCat
      display_merchant_name
      distance
      general_location
      geohashlong
      geohashshort
      geohashsix
      google_id
      google_placeid
      google_type
      image_path
      image_src
      latitude
      longitude
      mallId
      merchant_id
      merchant_name
      merchant_name_lowercase
      merchant_namelist
      merchant_unique_id
      online_company
      outlet_name
      phone
      postal_code
      postal_district
      postal_district_name
      postal_sector
      protectedAttributes
      store_id
      subc_online
      subcategory
      valid
      merchantImageUrl
      merchantImageUrlV2
      defaultImageUrl
      businessSummary
      outletWebsite
      mcc
      createdAt
      updatedAt
      createdByUser
      createdByGroups
      purchasableOutlet
      blkAptNum
      roadName
      buildingName
      unitNum
      country
    }
  }
`;
export const updateMerchantOutlet = /* GraphQL */ `
  mutation UpdateMerchantOutlet(
    $input: UpdateMerchantOutletInput!
    $condition: ModelMerchantOutletConditionInput
  ) {
    updateMerchantOutlet(input: $input, condition: $condition) {
      address
      deals {
        items {
          address
          aggregator
          area
          bank
          brand
          mainCat
          cc_buddy_category
          ccbuddy_card_name
          country
          deal_handler
          display_merchant_name
          district
          district_name
          end
          end_timestamp
          festive
          general_location
          geohashlong
          geohashshort
          google_id
          google_placeid
          google_type
          image_path
          image_url
          is_online
          isSponsored
          sponsoredRank
          latitude
          longitude
          merchant_id
          merchant_name
          merchant_name_lowercase
          merchant_unique_id
          online_company
          phone
          pk
          postal_code
          promo_code
          promotion
          promotion_analytic
          promotion_caption
          rank
          rank_updated
          score
          sector
          start
          start_timestamp
          store_id
          subcategory
          TR_tags
          terms
          usable_promocode
          valid
          website
          createdAt
          createdBy
          createdByUser
          updatedAt
          updatedBy
          isPinned
          pinPageLocation
          pinOrder
          purchasable
          actualPrice
          discount
          discountPrice
          purchasePrice
          purchaseLimitPerCustomer
          showInOutletIds
        }
        nextToken
      }
      mainCat
      display_merchant_name
      distance
      general_location
      geohashlong
      geohashshort
      geohashsix
      google_id
      google_placeid
      google_type
      image_path
      image_src
      latitude
      longitude
      mallId
      merchant_id
      merchant_name
      merchant_name_lowercase
      merchant_namelist
      merchant_unique_id
      online_company
      outlet_name
      phone
      postal_code
      postal_district
      postal_district_name
      postal_sector
      protectedAttributes
      store_id
      subc_online
      subcategory
      valid
      merchantImageUrl
      merchantImageUrlV2
      defaultImageUrl
      businessSummary
      outletWebsite
      mcc
      createdAt
      updatedAt
      createdByUser
      createdByGroups
      purchasableOutlet
      blkAptNum
      roadName
      buildingName
      unitNum
      country
    }
  }
`;
export const deleteMerchantOutlet = /* GraphQL */ `
  mutation DeleteMerchantOutlet(
    $input: DeleteMerchantOutletInput!
    $condition: ModelMerchantOutletConditionInput
  ) {
    deleteMerchantOutlet(input: $input, condition: $condition) {
      address
      deals {
        items {
          address
          aggregator
          area
          bank
          brand
          mainCat
          cc_buddy_category
          ccbuddy_card_name
          country
          deal_handler
          display_merchant_name
          district
          district_name
          end
          end_timestamp
          festive
          general_location
          geohashlong
          geohashshort
          google_id
          google_placeid
          google_type
          image_path
          image_url
          is_online
          isSponsored
          sponsoredRank
          latitude
          longitude
          merchant_id
          merchant_name
          merchant_name_lowercase
          merchant_unique_id
          online_company
          phone
          pk
          postal_code
          promo_code
          promotion
          promotion_analytic
          promotion_caption
          rank
          rank_updated
          score
          sector
          start
          start_timestamp
          store_id
          subcategory
          TR_tags
          terms
          usable_promocode
          valid
          website
          createdAt
          createdBy
          createdByUser
          updatedAt
          updatedBy
          isPinned
          pinPageLocation
          pinOrder
          purchasable
          actualPrice
          discount
          discountPrice
          purchasePrice
          purchaseLimitPerCustomer
          showInOutletIds
        }
        nextToken
      }
      mainCat
      display_merchant_name
      distance
      general_location
      geohashlong
      geohashshort
      geohashsix
      google_id
      google_placeid
      google_type
      image_path
      image_src
      latitude
      longitude
      mallId
      merchant_id
      merchant_name
      merchant_name_lowercase
      merchant_namelist
      merchant_unique_id
      online_company
      outlet_name
      phone
      postal_code
      postal_district
      postal_district_name
      postal_sector
      protectedAttributes
      store_id
      subc_online
      subcategory
      valid
      merchantImageUrl
      merchantImageUrlV2
      defaultImageUrl
      businessSummary
      outletWebsite
      mcc
      createdAt
      updatedAt
      createdByUser
      createdByGroups
      purchasableOutlet
      blkAptNum
      roadName
      buildingName
      unitNum
      country
    }
  }
`;
export const createMerchant = /* GraphQL */ `
  mutation CreateMerchant(
    $input: CreateMerchantInput!
    $condition: ModelMerchantConditionInput
  ) {
    createMerchant(input: $input, condition: $condition) {
      mainCat
      outlets {
        items {
          address
          mainCat
          display_merchant_name
          distance
          general_location
          geohashlong
          geohashshort
          geohashsix
          google_id
          google_placeid
          google_type
          image_path
          image_src
          latitude
          longitude
          mallId
          merchant_id
          merchant_name
          merchant_name_lowercase
          merchant_namelist
          merchant_unique_id
          online_company
          outlet_name
          phone
          postal_code
          postal_district
          postal_district_name
          postal_sector
          protectedAttributes
          store_id
          subc_online
          subcategory
          valid
          merchantImageUrl
          merchantImageUrlV2
          defaultImageUrl
          businessSummary
          outletWebsite
          mcc
          createdAt
          updatedAt
          createdByUser
          createdByGroups
          purchasableOutlet
          blkAptNum
          roadName
          buildingName
          unitNum
          country
        }
        nextToken
      }
      display_merchant_name
      google_type
      merchant_id
      merchant_name
      merchant_name_lowercase
      online_company
      valid
      acceptCC
      merchantImageUrl
      merchantImageUrlV2
      defaultImageUrl
      pockCalMerchImage
      createdAt
      updatedAt
      createdByUser
      createdByGroups
      website
      summary
      stripeExpressConnectedAccountId
      cmsPlan
    }
  }
`;
export const updateMerchant = /* GraphQL */ `
  mutation UpdateMerchant(
    $input: UpdateMerchantInput!
    $condition: ModelMerchantConditionInput
  ) {
    updateMerchant(input: $input, condition: $condition) {
      mainCat
      outlets {
        items {
          address
          mainCat
          display_merchant_name
          distance
          general_location
          geohashlong
          geohashshort
          geohashsix
          google_id
          google_placeid
          google_type
          image_path
          image_src
          latitude
          longitude
          mallId
          merchant_id
          merchant_name
          merchant_name_lowercase
          merchant_namelist
          merchant_unique_id
          online_company
          outlet_name
          phone
          postal_code
          postal_district
          postal_district_name
          postal_sector
          protectedAttributes
          store_id
          subc_online
          subcategory
          valid
          merchantImageUrl
          merchantImageUrlV2
          defaultImageUrl
          businessSummary
          outletWebsite
          mcc
          createdAt
          updatedAt
          createdByUser
          createdByGroups
          purchasableOutlet
          blkAptNum
          roadName
          buildingName
          unitNum
          country
        }
        nextToken
      }
      display_merchant_name
      google_type
      merchant_id
      merchant_name
      merchant_name_lowercase
      online_company
      valid
      acceptCC
      merchantImageUrl
      merchantImageUrlV2
      defaultImageUrl
      pockCalMerchImage
      createdAt
      updatedAt
      createdByUser
      createdByGroups
      website
      summary
      stripeExpressConnectedAccountId
      cmsPlan
    }
  }
`;
export const deleteMerchant = /* GraphQL */ `
  mutation DeleteMerchant(
    $input: DeleteMerchantInput!
    $condition: ModelMerchantConditionInput
  ) {
    deleteMerchant(input: $input, condition: $condition) {
      mainCat
      outlets {
        items {
          address
          mainCat
          display_merchant_name
          distance
          general_location
          geohashlong
          geohashshort
          geohashsix
          google_id
          google_placeid
          google_type
          image_path
          image_src
          latitude
          longitude
          mallId
          merchant_id
          merchant_name
          merchant_name_lowercase
          merchant_namelist
          merchant_unique_id
          online_company
          outlet_name
          phone
          postal_code
          postal_district
          postal_district_name
          postal_sector
          protectedAttributes
          store_id
          subc_online
          subcategory
          valid
          merchantImageUrl
          merchantImageUrlV2
          defaultImageUrl
          businessSummary
          outletWebsite
          mcc
          createdAt
          updatedAt
          createdByUser
          createdByGroups
          purchasableOutlet
          blkAptNum
          roadName
          buildingName
          unitNum
          country
        }
        nextToken
      }
      display_merchant_name
      google_type
      merchant_id
      merchant_name
      merchant_name_lowercase
      online_company
      valid
      acceptCC
      merchantImageUrl
      merchantImageUrlV2
      defaultImageUrl
      pockCalMerchImage
      createdAt
      updatedAt
      createdByUser
      createdByGroups
      website
      summary
      stripeExpressConnectedAccountId
      cmsPlan
    }
  }
`;
export const createCMSUser = /* GraphQL */ `
  mutation CreateCMSUser(
    $input: CreateCMSUserInput!
    $condition: ModelCMSUserConditionInput
  ) {
    createCMSUser(input: $input, condition: $condition) {
      email
      firstName
      lastName
      mobile
      roles
      marketingConsent
      agreeToTerms
      subPlan
      createdAt
      updatedAt
      profileImage
      corpName
      uen
      verified
      stripe {
        items {
          accountId
          email
          merchantId
          type
          journeyStep
          status
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateCMSUser = /* GraphQL */ `
  mutation UpdateCMSUser(
    $input: UpdateCMSUserInput!
    $condition: ModelCMSUserConditionInput
  ) {
    updateCMSUser(input: $input, condition: $condition) {
      email
      firstName
      lastName
      mobile
      roles
      marketingConsent
      agreeToTerms
      subPlan
      createdAt
      updatedAt
      profileImage
      corpName
      uen
      verified
      stripe {
        items {
          accountId
          email
          merchantId
          type
          journeyStep
          status
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteCMSUser = /* GraphQL */ `
  mutation DeleteCMSUser(
    $input: DeleteCMSUserInput!
    $condition: ModelCMSUserConditionInput
  ) {
    deleteCMSUser(input: $input, condition: $condition) {
      email
      firstName
      lastName
      mobile
      roles
      marketingConsent
      agreeToTerms
      subPlan
      createdAt
      updatedAt
      profileImage
      corpName
      uen
      verified
      stripe {
        items {
          accountId
          email
          merchantId
          type
          journeyStep
          status
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createCMSUserStripeAccount = /* GraphQL */ `
  mutation CreateCMSUserStripeAccount(
    $input: CreateCMSUserStripeAccountInput!
    $condition: ModelCMSUserStripeAccountConditionInput
  ) {
    createCMSUserStripeAccount(input: $input, condition: $condition) {
      accountId
      email
      merchantId
      type
      journeyStep
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateCMSUserStripeAccount = /* GraphQL */ `
  mutation UpdateCMSUserStripeAccount(
    $input: UpdateCMSUserStripeAccountInput!
    $condition: ModelCMSUserStripeAccountConditionInput
  ) {
    updateCMSUserStripeAccount(input: $input, condition: $condition) {
      accountId
      email
      merchantId
      type
      journeyStep
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteCMSUserStripeAccount = /* GraphQL */ `
  mutation DeleteCMSUserStripeAccount(
    $input: DeleteCMSUserStripeAccountInput!
    $condition: ModelCMSUserStripeAccountConditionInput
  ) {
    deleteCMSUserStripeAccount(input: $input, condition: $condition) {
      accountId
      email
      merchantId
      type
      journeyStep
      status
      createdAt
      updatedAt
    }
  }
`;
export const createVoucherMaster = /* GraphQL */ `
  mutation CreateVoucherMaster(
    $input: CreateVoucherMasterInput!
    $condition: ModelVoucherMasterConditionInput
  ) {
    createVoucherMaster(input: $input, condition: $condition) {
      id
      dealId
      code
      codeType
      startTimestamp
      endTimestamp
      userId
      status
      valid
      transactionId
      retrievalTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const updateVoucherMaster = /* GraphQL */ `
  mutation UpdateVoucherMaster(
    $input: UpdateVoucherMasterInput!
    $condition: ModelVoucherMasterConditionInput
  ) {
    updateVoucherMaster(input: $input, condition: $condition) {
      id
      dealId
      code
      codeType
      startTimestamp
      endTimestamp
      userId
      status
      valid
      transactionId
      retrievalTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const deleteVoucherMaster = /* GraphQL */ `
  mutation DeleteVoucherMaster(
    $input: DeleteVoucherMasterInput!
    $condition: ModelVoucherMasterConditionInput
  ) {
    deleteVoucherMaster(input: $input, condition: $condition) {
      id
      dealId
      code
      codeType
      startTimestamp
      endTimestamp
      userId
      status
      valid
      transactionId
      retrievalTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const createVoucherUser = /* GraphQL */ `
  mutation CreateVoucherUser(
    $input: CreateVoucherUserInput!
    $condition: ModelVoucherUserConditionInput
  ) {
    createVoucherUser(input: $input, condition: $condition) {
      masterId
      dealId
      userId
      code
      startTimestamp
      endTimestamp
      status
      transactionId
      redeemTimestamp
      createdAt
      updatedAt
      updatedBy
    }
  }
`;
export const updateVoucherUser = /* GraphQL */ `
  mutation UpdateVoucherUser(
    $input: UpdateVoucherUserInput!
    $condition: ModelVoucherUserConditionInput
  ) {
    updateVoucherUser(input: $input, condition: $condition) {
      masterId
      dealId
      userId
      code
      startTimestamp
      endTimestamp
      status
      transactionId
      redeemTimestamp
      createdAt
      updatedAt
      updatedBy
    }
  }
`;
export const deleteVoucherUser = /* GraphQL */ `
  mutation DeleteVoucherUser(
    $input: DeleteVoucherUserInput!
    $condition: ModelVoucherUserConditionInput
  ) {
    deleteVoucherUser(input: $input, condition: $condition) {
      masterId
      dealId
      userId
      code
      startTimestamp
      endTimestamp
      status
      transactionId
      redeemTimestamp
      createdAt
      updatedAt
      updatedBy
    }
  }
`;
export const createTransaction = /* GraphQL */ `
  mutation CreateTransaction(
    $input: CreateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    createTransaction(input: $input, condition: $condition) {
      id
      userId
      dealId
      quantity
      actualPrice
      discount
      discountPrice
      purchasePrice
      purchasePriceTotal
      timestamp
      paymentMethod
      status
      discountCode
      extraDiscount
      deal {
        address
        aggregator
        approval {
          pk
          isApproved
          createdYearMonth
          createdAt
          updatedAt
          createdByUser
          createdByGroups
        }
        area
        bank
        brand
        mainCat
        cc_buddy_category
        ccbuddy_card_name
        country
        deal_handler
        display_merchant_name
        district
        district_name
        end
        end_timestamp
        festive
        general_location
        geohashlong
        geohashshort
        google_id
        google_placeid
        google_type
        image_path
        image_url
        is_online
        isSponsored
        sponsoredRank
        latitude
        longitude
        merchant_id
        merchant_name
        merchant_name_lowercase
        merchant_unique_id
        online_company
        phone
        pk
        postal_code
        promo_code
        promotion
        promotion_analytic
        promotion_caption
        rank
        rank_updated
        score
        sector
        start
        start_timestamp
        store_id
        subcategory
        TR_tags
        tags {
          nextToken
        }
        terms
        usable_promocode
        valid
        website
        createdAt
        createdBy
        createdByUser
        updatedAt
        updatedBy
        isPinned
        pinPageLocation
        pinOrder
        purchasable
        actualPrice
        discount
        discountPrice
        purchasePrice
        purchaseLimitPerCustomer
        showInOutletIds
        additionalPromotionInfo {
          id
          termsText
          iconUrl
        }
      }
      user {
        userId
        emailId
        marketingConsent
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      utm_campaign
      utm_content
      utm_medium
      utm_source
      utm_term
    }
  }
`;
export const updateTransaction = /* GraphQL */ `
  mutation UpdateTransaction(
    $input: UpdateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    updateTransaction(input: $input, condition: $condition) {
      id
      userId
      dealId
      quantity
      actualPrice
      discount
      discountPrice
      purchasePrice
      purchasePriceTotal
      timestamp
      paymentMethod
      status
      discountCode
      extraDiscount
      deal {
        address
        aggregator
        approval {
          pk
          isApproved
          createdYearMonth
          createdAt
          updatedAt
          createdByUser
          createdByGroups
        }
        area
        bank
        brand
        mainCat
        cc_buddy_category
        ccbuddy_card_name
        country
        deal_handler
        display_merchant_name
        district
        district_name
        end
        end_timestamp
        festive
        general_location
        geohashlong
        geohashshort
        google_id
        google_placeid
        google_type
        image_path
        image_url
        is_online
        isSponsored
        sponsoredRank
        latitude
        longitude
        merchant_id
        merchant_name
        merchant_name_lowercase
        merchant_unique_id
        online_company
        phone
        pk
        postal_code
        promo_code
        promotion
        promotion_analytic
        promotion_caption
        rank
        rank_updated
        score
        sector
        start
        start_timestamp
        store_id
        subcategory
        TR_tags
        tags {
          nextToken
        }
        terms
        usable_promocode
        valid
        website
        createdAt
        createdBy
        createdByUser
        updatedAt
        updatedBy
        isPinned
        pinPageLocation
        pinOrder
        purchasable
        actualPrice
        discount
        discountPrice
        purchasePrice
        purchaseLimitPerCustomer
        showInOutletIds
        additionalPromotionInfo {
          id
          termsText
          iconUrl
        }
      }
      user {
        userId
        emailId
        marketingConsent
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      utm_campaign
      utm_content
      utm_medium
      utm_source
      utm_term
    }
  }
`;
export const deleteTransaction = /* GraphQL */ `
  mutation DeleteTransaction(
    $input: DeleteTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    deleteTransaction(input: $input, condition: $condition) {
      id
      userId
      dealId
      quantity
      actualPrice
      discount
      discountPrice
      purchasePrice
      purchasePriceTotal
      timestamp
      paymentMethod
      status
      discountCode
      extraDiscount
      deal {
        address
        aggregator
        approval {
          pk
          isApproved
          createdYearMonth
          createdAt
          updatedAt
          createdByUser
          createdByGroups
        }
        area
        bank
        brand
        mainCat
        cc_buddy_category
        ccbuddy_card_name
        country
        deal_handler
        display_merchant_name
        district
        district_name
        end
        end_timestamp
        festive
        general_location
        geohashlong
        geohashshort
        google_id
        google_placeid
        google_type
        image_path
        image_url
        is_online
        isSponsored
        sponsoredRank
        latitude
        longitude
        merchant_id
        merchant_name
        merchant_name_lowercase
        merchant_unique_id
        online_company
        phone
        pk
        postal_code
        promo_code
        promotion
        promotion_analytic
        promotion_caption
        rank
        rank_updated
        score
        sector
        start
        start_timestamp
        store_id
        subcategory
        TR_tags
        tags {
          nextToken
        }
        terms
        usable_promocode
        valid
        website
        createdAt
        createdBy
        createdByUser
        updatedAt
        updatedBy
        isPinned
        pinPageLocation
        pinOrder
        purchasable
        actualPrice
        discount
        discountPrice
        purchasePrice
        purchaseLimitPerCustomer
        showInOutletIds
        additionalPromotionInfo {
          id
          termsText
          iconUrl
        }
      }
      user {
        userId
        emailId
        marketingConsent
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      utm_campaign
      utm_content
      utm_medium
      utm_source
      utm_term
    }
  }
`;
export const createTermsMaster = /* GraphQL */ `
  mutation CreateTermsMaster(
    $input: CreateTermsMasterInput!
    $condition: ModelTermsMasterConditionInput
  ) {
    createTermsMaster(input: $input, condition: $condition) {
      id
      type
      iconUrl
      description
      recommended
      termsText
      placeholderText
      order
      createdAt
      updatedAt
    }
  }
`;
export const updateTermsMaster = /* GraphQL */ `
  mutation UpdateTermsMaster(
    $input: UpdateTermsMasterInput!
    $condition: ModelTermsMasterConditionInput
  ) {
    updateTermsMaster(input: $input, condition: $condition) {
      id
      type
      iconUrl
      description
      recommended
      termsText
      placeholderText
      order
      createdAt
      updatedAt
    }
  }
`;
export const deleteTermsMaster = /* GraphQL */ `
  mutation DeleteTermsMaster(
    $input: DeleteTermsMasterInput!
    $condition: ModelTermsMasterConditionInput
  ) {
    deleteTermsMaster(input: $input, condition: $condition) {
      id
      type
      iconUrl
      description
      recommended
      termsText
      placeholderText
      order
      createdAt
      updatedAt
    }
  }
`;
export const createCheckinTicket = /* GraphQL */ `
  mutation CreateCheckinTicket(
    $input: CreateCheckinTicketInput!
    $condition: ModelCheckinTicketConditionInput
  ) {
    createCheckinTicket(input: $input, condition: $condition) {
      masterId
      dealId
      firstName
      lastName
      email
      terms
      marketingConsent
      createdAt
      updatedAt
    }
  }
`;
export const updateCheckinTicket = /* GraphQL */ `
  mutation UpdateCheckinTicket(
    $input: UpdateCheckinTicketInput!
    $condition: ModelCheckinTicketConditionInput
  ) {
    updateCheckinTicket(input: $input, condition: $condition) {
      masterId
      dealId
      firstName
      lastName
      email
      terms
      marketingConsent
      createdAt
      updatedAt
    }
  }
`;
export const deleteCheckinTicket = /* GraphQL */ `
  mutation DeleteCheckinTicket(
    $input: DeleteCheckinTicketInput!
    $condition: ModelCheckinTicketConditionInput
  ) {
    deleteCheckinTicket(input: $input, condition: $condition) {
      masterId
      dealId
      firstName
      lastName
      email
      terms
      marketingConsent
      createdAt
      updatedAt
    }
  }
`;
export const createWebForm = /* GraphQL */ `
  mutation CreateWebForm(
    $input: CreateWebFormInput!
    $condition: ModelWebFormConditionInput
  ) {
    createWebForm(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      eventPromoCode
      userId
      webFormId
      createdAt
      updatedAt
      bankId
      category
      dress
      tableNumber
      groupName
      status
      terms
      transactionId
      designation
      menu
      salutation
      nationality
      participants
      phoneNumber
    }
  }
`;
export const updateWebForm = /* GraphQL */ `
  mutation UpdateWebForm(
    $input: UpdateWebFormInput!
    $condition: ModelWebFormConditionInput
  ) {
    updateWebForm(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      eventPromoCode
      userId
      webFormId
      createdAt
      updatedAt
      bankId
      category
      dress
      tableNumber
      groupName
      status
      terms
      transactionId
      designation
      menu
      salutation
      nationality
      participants
      phoneNumber
    }
  }
`;
export const deleteWebForm = /* GraphQL */ `
  mutation DeleteWebForm(
    $input: DeleteWebFormInput!
    $condition: ModelWebFormConditionInput
  ) {
    deleteWebForm(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      eventPromoCode
      userId
      webFormId
      createdAt
      updatedAt
      bankId
      category
      dress
      tableNumber
      groupName
      status
      terms
      transactionId
      designation
      menu
      salutation
      nationality
      participants
      phoneNumber
    }
  }
`;
export const createWebFormGroup = /* GraphQL */ `
  mutation CreateWebFormGroup(
    $input: CreateWebFormGroupInput!
    $condition: ModelWebFormGroupConditionInput
  ) {
    createWebFormGroup(input: $input, condition: $condition) {
      name
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const updateWebFormGroup = /* GraphQL */ `
  mutation UpdateWebFormGroup(
    $input: UpdateWebFormGroupInput!
    $condition: ModelWebFormGroupConditionInput
  ) {
    updateWebFormGroup(input: $input, condition: $condition) {
      name
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteWebFormGroup = /* GraphQL */ `
  mutation DeleteWebFormGroup(
    $input: DeleteWebFormGroupInput!
    $condition: ModelWebFormGroupConditionInput
  ) {
    deleteWebFormGroup(input: $input, condition: $condition) {
      name
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const createScanHistory = /* GraphQL */ `
  mutation CreateScanHistory(
    $input: CreateScanHistoryInput!
    $condition: ModelScanHistoryConditionInput
  ) {
    createScanHistory(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      voucherId
      scanStatus
      merchantId
      dealId
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const updateScanHistory = /* GraphQL */ `
  mutation UpdateScanHistory(
    $input: UpdateScanHistoryInput!
    $condition: ModelScanHistoryConditionInput
  ) {
    updateScanHistory(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      voucherId
      scanStatus
      merchantId
      dealId
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteScanHistory = /* GraphQL */ `
  mutation DeleteScanHistory(
    $input: DeleteScanHistoryInput!
    $condition: ModelScanHistoryConditionInput
  ) {
    deleteScanHistory(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      voucherId
      scanStatus
      merchantId
      dealId
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      userId
      emailId
      marketingConsent
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      userId
      emailId
      marketingConsent
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      userId
      emailId
      marketingConsent
      createdAt
      updatedAt
    }
  }
`;
