import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { useSelector, useDispatch } from "react-redux";
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardImg, Spinner, Button } from "reactstrap"
import LinesEllipsis from 'react-lines-ellipsis'
import { getUnixTime } from 'date-fns'

import RBAC from '../../../components/Common/rbac'

//import components
import StripePrompt from "../../../components/Common/stripePrompt";
import EmptyState from './Components/emptyState'
import SellEventTicketsForm from  './Components/sellEventTicketsForm'
import ImageOverlay from '../../../components/Common/imageOverlay'
import StripeOnboarding from "../../../components/Common/stripeOnboarding";
import sellVoucherStepImg from '../../../assets/images/sellVoucherStepsC.png'

// import utils
import { formatDateTime, isDealExpiring, openInNewTab, guidGenerator, capitalizeFirstLetter, convertToCSV } from '../../../utils'
import { STRIPE_STATUS, SELL_VOUCHERS_TERMS_TEMPLATE_SHOPFRONT, ON_BOARDING } from '../../../utils/constants'
import { getStripeStatusColor } from "utils/stripe";

//import store actions
import { setBreadcrumbItems, setAlert, setConfig, setEnv, setAmplitudeEvent  } from "../../../store/actions";

// import graphql
import { getPromoByMerchantUniqueId } from "../../../helpers/GraphQL/promotions"
import { listOutletsByMerchantId, getOutlet } from '../../../helpers/GraphQL/outlet'
import { getBrandInfo } from '../../../helpers/GraphQL/brands'
import { getMerchantStripeAccount, updateStripeAccount, createStripeAccount } from "../../../helpers/GraphQL/userStripeAccount"

// import helpers
import { stripeCreateOnboardingLink, stripeRetrieveKey, stripeRetrieveAccount, stripeCreateLoginLink, getConfig, getEnv, getEvents, getVoucherGroup} from '../../../helpers/apiHelper'

const SellEventTickets = () => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.User.user)
    const cmsConfig = useSelector(state => state.Config)
    const selectedMerchant = useSelector(state => state.Merchant.selectedMerchant)
    const [refreshUrl, setRefreshUrl] = useState('https://biz.cardspal.com')
    const [returnUrl, setReturnUrl] = useState('https://biz.cardspal.com')
    const [createStripeButtonDisabled, setCreateStripeButtonDisabled] = useState(true)
    const [stripeKey, setStripeKey] = useState(null)
    const [stripeLink, setStripeLink] = useState()
    const [buttonLabel, setButtonLabel] = useState('Connect to our Stripe Account')
    const [stripeAccountStatus, setStripeAccountStatus] = useState('')
    const [userInfo, setUserInfo] = useState()
    const [modalStripePromptStatus, setModalStripePromptStatus] = useState(false)
    const [needPrompt, setNeedPrompt] = useState(false)
    const [stripeStatus, setStripeStatus] = useState('')
    const [statusColor, setStatusColor] = useState('')
    const [loadingStatus, setLoadingStatus] = useState(false)
    const [modalSellEventTicketsForm, setModalSellEventTicketsForm] = useState(false)
    const [modalImageOverlayStatus, setModalImageOverlayStatus] = useState(false)
    const [mode, setMode] = useState('')
    const [sellEventTicket, setSellEventTicket] = useState({})
    const [reload, setReload] = useState('')
    const [outlets, setOutlets] =useState([])
    const [downloadingCSV, setDownloadingCSV] = useState(false)
    const [canCreateVoucher, setCanCreateVoucher] = useState(true)
    const [merchantUniqueIDs, setMerchantUniqueIDs] = useState([])
    const [events, setEvents] = useState([])
    const [eventTickets, setEventTickets] = useState([])

    const data = {
        columns: [
            {
                label: "ID",
                field: "id",
                width: 50,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            },
            {
                label: "Edit",
                field: "edit",
                sort: 'disabled',
                width: 50,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            },
            {
                label: "Deal Image",
                field: "dealImage",
                width: 80,
                
            },
            {
                label: "Visible on app",
                field: "visible",
                width: 70,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            },
            // {
            //     label: "No. of vouchers",
            //     field: "numVouchers",
            //     width: 100,
            //     attributes: {
            //         "className": "verticalAlignHeader"
            //     }
            // },
            // {
            //     label: "Uploaded / Generated / On-the-fly",
            //     field: "codeType",
            //     width: 110,
            // },
            // {
            //     label: "Promo Type",
            //     field: "promoType",
            //     width: 100,
            // },
            {
                label: "Event",
                field: "event",
                width: 100,
            },
            {
                label: "Group Name",
                field: "groupName",
                width: 100,
            },
            {
                label: "Ticket URL",
                field: "ticketURL",
                width: 200,
            },
            {
                label: "Ticket Title",
                field: "ticketTitle",
                width: 100,
            },
            {
                label: "Ticket Info",
                field: "ticketInfo",
                width: 200,
                sort: "asc",
            },
            {
                label: "T&Cs",
                field: "tc",
                width: 300,
                sort: 'disabled',
            },
            {
                label: "Valid Till",
                field: "validTill",
                sort: 'disabled',
                width: 120,
            },
            {
                label: "Actual Price",
                field: "actualPrice",
                sort: 'asc',
                width: 100,
            },
            {
                label: "Purchase Price",
                field: "purchasePrice",
                sort: 'disabled',
                width: 100,
            },
            {
                label: "Discount Amount",
                field: "discountAmount",
                sort: 'disabled',
                width: 100,
            },
            {
                label: "Discount Tag",
                field: "discountTag",
                sort: 'disabled',
                width: 100,
            },
            {
                label: "Purchase Limit",
                field: "purchaseLimit",
                sort: 'disabled',
                width: 100,
                
            },
            {
                label: "Display Start Date/Time",
                field: "displaySDate",
                sort: 'disabled',
                width: 120,
                
            },
            {
                label: "Display End Date/Time",
                field: "displayEDate",
                sort: 'disabled',
                width: 120,
                
            },
            {
                label: "Selling Start Date/Time",
                field: "sellingSDate",
                sort: 'disabled',
                width: 120,
                
            },
            {
                label: "Selling End Date/Time",
                field: "sellingEDate",
                sort: 'disabled',
                width: 120,
                
            },
            {
                label: "Redemption Start Date/Time",
                field: "redemptionSDate",
                sort: 'disabled',
                width: 120,
                
            },
            {
                label: "Redemption End Date/Time",
                field: "redemptionEDate",
                sort: 'disabled',
                width: 120,
                
            },
            {
                label: "Ticketing Event Website",
                field: "website",
                sort: 'disabled',
                width: 150,
                
            }

        ],
        rows: []
    }

    const [dataTable, setDataTable] = useState(data)

    const breadcrumbItems = [
        { title: "CardsPal", link: "#" },
        { title: selectedMerchant.display_merchant_name, link: "#" },
        { title: "Tools", link: "#" },
        { title: "Event Tickets", link: "#" },
    ]

    useEffect(() => {
        dispatch(setAlert(''))
        dispatch(setBreadcrumbItems('Event Tickets', breadcrumbItems))
    }, [selectedMerchant.display_merchant_name])

    useEffect(async () => {
        if (!selectedMerchant || !selectedMerchant.merchant_id) return 
        
        // get the updated merchant info from DB in case webhook is updating stripe and other info and the user has not logout yet causing the store to be an old-data
        const brand = await getBrandInfo(selectedMerchant.merchant_id)
        // update the stripe attribute with latest to make sure we restrict if needed
        selectedMerchant.stripeExpressConnectedAccountId = brand.stripeExpressConnectedAccountId
        // if stripeExpressConnectedAccountId is not in merchant or empty or null, disable button to add voucher
        setCanCreateVoucher(!brand.stripeExpressConnectedAccountId)
    }, [selectedMerchant])

    useEffect(() =>{
        if (cmsConfig && cmsConfig.cmsv2) setConfigVariables(cmsConfig.cmsv2.stripeVariables)
    }, [cmsConfig])

    useEffect(async () => {
        try {
            let outlet = []
            //this is to retrieve existing events under certain outlet
            const _outlets = await listOutletsByMerchantId(selectedMerchant.merchant_id)

            let _merchant_unique_ids = _outlets.map((merchant)=> merchant.merchant_unique_id ) ?? []

            // get new outlet for events
            const eventOutlet = await getOutlet(selectedMerchant.merchant_id,'event')
            
            if (eventOutlet){
                 _merchant_unique_ids.push(eventOutlet.merchant_unique_id)
                 outlet = [eventOutlet]    
            }else{
                setLoadingStatus(false)
                return
            }
            console.log({outlets},{_merchant_unique_ids})
            setOutlets([...outlet])
            setMerchantUniqueIDs([..._merchant_unique_ids])
        } catch (e) {
            dispatch(setAlert(''))
        }
    }, [selectedMerchant])

    // Populate events based on merchants unique ids
    useEffect(async () => {
        if(merchantUniqueIDs.length > 1)
        try {
            let _eventList = []
            let _dealList = []
            await Promise.all(  merchantUniqueIDs.map(async (muid) => {
                                const _events = await getEvents(muid, "merchant_unique_id")
                                if(_events.statusCode === '200') 
                                    _eventList.push(..._events.data.events)

                                // get all deals for each merchant unique id but only those that are event ticket type
                                const _deals = await getPromoByMerchantUniqueId(muid)
                                // console.log({muid}, {_deals})
                                let filteredItems = []
                                if(_deals) {
                                    const items = _deals.items
                                    filteredItems = items.filter((deal) => deal.eventDealType === '0')
                                }
                                _dealList.push(...filteredItems)
            }))

            _eventList.sort((a, b) => a.name.localeCompare(b.name))

            if(_dealList.length < 1) setLoadingStatus(false)
            // console.log({_eventList}, {_dealList})
            setEvents(_eventList)
            setEventTickets([..._dealList])

        } catch (e) {
            dispatch(setAlert(''))
        }
    }, [merchantUniqueIDs, reload])

    useEffect(() => {
        setStatusColor(getStripeStatusColor(stripeStatus))
    }, [stripeStatus])

    useEffect(async () => {
        setLoadingStatus(true)
        dispatch(setAlert('Initializing...Please wait while we finish getting updates', 'info'))

        try {
            // get user info
            const user = await JSON.parse(await localStorage.getItem('user'))
            setUserInfo(user)
            
            
            // retrieve the stripe key from secret manager
            const key = await stripeRetrieveKey()
            if (!key) {
                dispatch(setAlert(`Error loading this page. Please report to admin immediately.`, 'danger'))
                return
            } else {
                setStripeKey(key.stripeSecret)
            }

            await initializeStripeLink(user, key?.stripeSecret)
            
        } catch (error) {
            console.error(error)
            setLoadingStatus(false)
            dispatch(setAlert(`Error loading this page. Please report to admin immediately.`, 'danger'))
        }
    }, [])

    useEffect(() => {
        setButtonLabel('Connect to our Stripe Account')
        initializeStripeLink(userInfo, stripeKey)
    }, [selectedMerchant])

    useEffect(async () => {
        if (!user || !selectedMerchant.merchant_id || (eventTickets.length < 1)|| Object.keys(user).length === 0) return 
        console.log('loading list of tickets')

        setLoadingStatus(true)
        data.rows = [] // clear the display items on the table first
        setDataTable(data)      
        const dataRows = []
        let count = 0
        // console.log({eventTickets})

        await Promise.all(eventTickets.map(async (eventTicket, i) => {
            const _item = eventTicket
            const getVoucherGroupRes = await getVoucherGroup(_item.pk,"deal_id")
            if (getVoucherGroupRes.statusCode === '200') {
                const _voucherGroup = getVoucherGroupRes.data
                const _event = _voucherGroup ? events.filter((event) => event.pk === _voucherGroup.eventId)[0] : events.filter((event) => event.listOfTickets?.includes(_item.pk))[0]
                const ticketURL = _event ? `https://biz.${cmsConfig.env}.cardspal.com/event/${_event?.uniqueString || ""}/item/${_item?.uniqueString || ""}` : "-"
                count ++
                let ticketInfo = extractTextFromHTMLString(_item.ticketInfo, 'p')
                dataRows.push({
                    id: count,
                    edit: !_item ?  "-" : <a href="#" onClick={() => updateTicket(_item, i)}><i className="mdi mdi-pencil" style={{ color: "#7a6fbe" }} /></a> ,
                    dealImage: <a href="#" onClick={() => viewImg(_item)}>View</a>,
                    visible: getDealStatus(_item, i),
                    event:_event?.name || "-",
                    groupName: _voucherGroup?.categoryName,
                    ticketURL: <a href="#" onClick={() => openInNewTab(ticketURL)}>{ticketURL}</a>,
                    ticketTitle: _item.promotion_caption,
                    ticketInfo: <LinesEllipsis text={ticketInfo} maxLine={3} ellipsis="..." />,//<div dangerouslySetInnerHTML={{ __html: _item.ticketInfo}} />,
                    tc: <LinesEllipsis text={_item.terms} maxLine={3} ellipsis="..." />,
                    validTill: _item ? formatDateTime(_item.end_timestamp):'N/A',
                    actualPrice: _item.actualPrice,
                    purchasePrice: _item.purchasePrice,
                    discountAmount: _item.discountPrice,
                    discountTag: `${_item.discount*100}%`,
                    purchaseLimit: _item.purchaseLimitPerCustomer,
                    displaySDate: formatDateTime(_item.start_timestamp).toString(),
                    displayEDate: formatDateTime(_item.end_timestamp).toString(),
                    sellingSDate: formatDateTime(_item.selling_startTimestamp).toString(),
                    sellingEDate: formatDateTime(_item.selling_endTimestamp).toString(),
                    redemptionSDate: formatDateTime(_item.redemption_endTimestamp).toString(),
                    redemptionEDate: formatDateTime(_item.redemption_startTimestamp).toString(),
                    website: <a href="#" onClick={() => openInNewTab(_item.website)} value={_item.website}>{_item.website}</a>
                })
            }
            
        }))

        // set the data table values
        data.rows = dataRows
        setDataTable({...data})
        
        // TODO :: confirm parameters
        let _ampTrack = {
            'merchant id': selectedMerchant.merchant_id,
            'merchant name':  selectedMerchant.merchant_name,
            'mainCat': selectedMerchant.mainCat
        }
        let trackEmpty = {empty:eventTickets && eventTickets.length > 0? 'N' :'Y'}
        dispatch(setAmplitudeEvent('CMS Event Tickets page - page load', {..._ampTrack, ...trackEmpty}))
        
        setLoadingStatus(false)
    }, [eventTickets, user])

    useEffect(async () => { //Get CMS Config
        await getCMSConfig()
    }, [])

    function viewImg(_item) {
        setSellEventTicket(_item)
        setModalImageOverlayStatus(true)
    }

    const extractTextFromHTMLString = (htmlString, tagName) => {
        if (!htmlString) return ""

        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
      
        const elements = doc.getElementsByTagName(tagName);
        let textContent = '';
      
        for (let i = 0; i < elements.length; i++) {
          const element = elements[i];
          textContent += element.textContent;
        }
      
        return textContent;
      };

    function downloadCSV() {
        setDownloadingCSV(true)
        if (dataTable.rows.length>0) {

            let arr = []
            dataTable.rows.map((e)=>{
                let item = {'ID': e.id, 
                            'Event': e.event,
                            'Group Name': e.groupName,
                            'TIcket URL': e.ticketURL.props.href,
                            'Ticket Title': e.ticketTitle,
                            'Ticket Info': e.ticketInfo.props.text,
                             'T&Cs': e.tc.props.text.replaceAll("● ", ""),
                            'Valid Till': e.validTill,
                            'Actual Price': e.actualPrice,
                            'Discount Price': e.purchasePrice,
                            'Discount Amount': e.discountAmount,
                            'Discount Tag': e.discountTag,
                            'Purchase Limit': e.purchaseLimit,
                            'Display Start Date/Time': e.displaySDate,
                            'Display End Date/Time': e.displayEDate,
                            'Selling Start Date/Time': e.sellingSDate,
                            'Selling End Date/Time': e.sellingEDate,
                            'Redemption Start Date/Time': e.redemptionSDate,
                            'Redemption End Date/Time': e.redemptionEDate,
                            'Ticketing Event Website': e.website.props.value,
                            }
                console.log(e)
                arr.push(item)
            })

            const csvTitle = "Event Tickets"
            convertToCSV(arr, csvTitle)
        }
        setDownloadingCSV(false)
    }

    function getDealStatus(item) {
        if (item.valid === 'Y') {
            return (
                // <a href="#"  >
                <i className="mdi mdi-eye" style={{ color: "#58DB83" }} />
                // </a>
            )
        } else {
            return (
                // <a href="#" >
                <i className="mdi mdi-eye-off" style={{ color: "#EC536C" }} />
                // </a>
            )
        }
    }

    async function getCMSConfig() {
        try {
            
            if (!cmsConfig || !cmsConfig.cmsv2) {
                const config = await getConfig()

                const env = getEnv()
                
                dispatch(setConfig(config))
                dispatch(setEnv(env))
            }
        } catch(error) {
            console.error('error on retrieving config file', error)
            dispatch(setAmplitudeEvent('CMS Event Tickets page - getCMSConfig function error', {'details': JSON.stringify(error)}))
        }
    }
    
    async function setConfigVariables(variables) {
        setRefreshUrl(variables.refreshUrl)
        setReturnUrl(variables.returnUrl)
    }

    async function initializeStripeLink(userInfo, stripeKey) {
        try {

            if (!userInfo || !stripeKey) return

            // check user on-boarding status
            const stripeAccount = await getMerchantStripeAccount(selectedMerchant.merchant_id)
            
            let stripeInfo
            let status // status: completed, pending-verification, not-completed
            let link
            
            if (stripeAccount && stripeAccount.type === ON_BOARDING) {
            
                if (stripeAccount.status === STRIPE_STATUS.COMPLETED){
                    console.debug('completed stripe account ')
                    status = STRIPE_STATUS.COMPLETED
                    
                    if (!selectedMerchant.stripeExpressConnectedAccountId) {
                        dispatch(setAlert('Cannot create voucher. Please report to the Admin to resolve your account', 'danger'))
                    } else {
                        dispatch(setAlert(''))
                    }
                    
                    setButtonLabel('View Stripe Platform')
                } else {
                    // means user triggered on-boarding before but did not finish, so we retrieve info from stripe api again
                    stripeInfo = await stripeRetrieveAccount({ stripeKey, accountId: stripeAccount.accountId })

                    if (!stripeInfo.charges_enabled) {
                        // dispatch(setAlert(''))
                        status = stripeAccount.status
                        console.debug('not completed so create account link')
                        const stripeInfo = await stripeCreateOnboardingLink({ email: userInfo.email, stripeKey, refreshUrl, returnUrl, merchantId: selectedMerchant.merchant_id, accountId: stripeAccount.accountId })
                        link = stripeInfo.accountLink.url
                        setNeedPrompt(true)
                    } else {
                        status = await stripeStatusLogic(stripeInfo)
                        console.debug('not completed so create login link')
                        const stripeLoginLink = await stripeCreateLoginLink({ stripeKey, accountId: stripeAccount.accountId })
                        link = stripeLoginLink.url
                    }
                    dispatch(setAlert(''))
                    
                    await updateStripeAccount({
                        accountId: stripeAccount.accountId,
                        merchantId: selectedMerchant.merchant_id,
                        email: userInfo.email,
                        type: 'on-boarding',
                        status
                    })
                }
            } else {
                // first time to on-board to stripe
                status = STRIPE_STATUS.NOT_ONBOARDED
                stripeInfo = await stripeCreateOnboardingLink({ email: userInfo.email, stripeKey, refreshUrl, returnUrl, merchantId: selectedMerchant.merchant_id })
                link = stripeInfo.accountLink.url

                setNeedPrompt(true)
                dispatch(setAlert(''))
                
                await createStripeAccount({
                    accountId: stripeInfo.account.id,
                    merchantId: selectedMerchant.merchant_id,
                    email: userInfo.email,
                    type: 'on-boarding',
                    status
                })
            }
            
            setStripeAccountStatus(status)
            setStripeLink(link)
            setStripeStatus(status)
            setCreateStripeButtonDisabled(false)

        } catch(error) {
            console.error(error)
            dispatch(setAmplitudeEvent('CMS Event Tickets page - initializeStripeLink function error', {'details': JSON.stringify(error)}))
        }
    }

    function stripeStatusLogic(stripeAccount) {
        let status
        if (stripeAccount.charges_enabled) {
            // fully on-boarded
            status = STRIPE_STATUS.COMPLETED
            dispatch(setAlert(<span>Great! Stripe account set up has been approved. You may start selling vouchers for ${selectedMerchant.merchant_name} now.</span>, 'success'))
            setButtonLabel('View Stripe Platform')
        } else if (stripeAccount.details_submitted) {
            // completed onboarding, pending stripe verification
            status = STRIPE_STATUS.PENDING_VERIFICATION
            dispatch(setAlert(<span>Stripe set up is pending approval <b>within the next 48 hours.</b> We will alert you when the set up is done.</span>, 'info'))
            setButtonLabel('View Stripe Platform')
        } else if (!stripeAccount.details_submitted) {
            // on-boarding not completed, need to create login link
            status = STRIPE_STATUS.IN_PROGRESS
            dispatch(setAlert(''))
        }
        return status
    }

    function openStripeLink() {
        if (needPrompt) {
            setModalStripePromptStatus(true)
        } else {
            setCreateStripeButtonDisabled(true)
            openInNewTab(stripeLink)
        }
    }

    function closeForm(item, state) {
        setModalStripePromptStatus(state || false)
        setModalSellEventTicketsForm(state || false)
        setModalImageOverlayStatus(state || false)
        
        if (item) {
            console.log('reloading....')
            setReload(new Date())
        }
    }

    function createTicket() {
        if (outlets.length===0) {
            dispatch(setAlert(<span>You need to add at least 1 outlet first. Click <a href="/manage-outlets">here</a> to add outlet</span>, 'danger'))
            return
        }
        setModalSellEventTicketsForm(true)
        setMode('create')
        setSellEventTicket({
            terms: SELL_VOUCHERS_TERMS_TEMPLATE_SHOPFRONT,
            is_online:  'False',
            bank: 'DEALS FOR EVERYONE',
            deal_handler: 'exclusive_all',
            cc_buddy_category: selectedMerchant.mainCat,
            ccbuddy_card_name: 'NULL',
            country: 'SGP',
            valid: 'Y',
            merchant_id: selectedMerchant.merchant_id,
            merchant_name: selectedMerchant.merchant_name,
            merchant_name_lowercase: selectedMerchant.merchant_name_lowercase,
            mainCat: selectedMerchant.mainCat,
            display_merchant_name: selectedMerchant.merchant_name,
            start_timestamp: getUnixTime(new Date('yyy-MM-dd 00:00')), //Date.now("yyyy-MM-dd 00:00") / 1000 | 0,
            pk: guidGenerator(),
            purchaseLimitPerCustomer: "1",
            purchasable: "1",
            discount: "",
            actualPrice: "",
            discountPrice: "",
            purchasePrice: "",
            corporateId:"event-ticket",
            website:"",
            minQtyTx:"1",
            TR_tags:[],
            uniqueString:"",
            ticketInfo:""

        })
    }

    function updateTicket(_item) {
        if (!selectedMerchant.stripeExpressConnectedAccountId) {
            dispatch(setAlert('Cannot edit voucher. Please report to the Admin to resolve your account.', 'danger'))
            dispatch(setAmplitudeEvent('CMS Event Tickets page - updateVoucher function error', {'details': 'stripeExpressConnectedAccountId field is missing', ..._item}))
        } else {
            setModalSellEventTicketsForm(true)
            setMode('update')
            setSellEventTicket(_item)
        }
    }

    return (
        <React.Fragment>
            <RBAC merchantId={selectedMerchant.merchant_id} roles={['admin']}>

                <MetaTags>
                    <title>Event Tickets</title>
                </MetaTags>


                {
                    loadingStatus ?
                    <Row style={{minHeight:"400px", justifyContent:"center", alignContent:"center"}}>
                        <Spinner />
                    </Row>
                    : stripeAccountStatus !== STRIPE_STATUS.COMPLETED ?
                        <Row className="justify-content-center">
                            <Col xs={12} sm={12} md={6} lg={5} xl={5}>
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardImg
                                                src={sellVoucherStepImg}
                                                top
                                                width="100%"
                                                style={{ objectFit:'contain', marginTop:'20px' }}
                                            />
                                        </Card>
                                    </Col>
                                </Row>
                                            
                                <Row>
                                    <Col>
                                        {[STRIPE_STATUS.PENDING_VERIFICATION, STRIPE_STATUS.IN_PROGRESS].includes(stripeAccountStatus) ?
                                        <div>
                                            <StripeOnboarding
                                                stripeStatus={stripeStatus}
                                                statusColor={statusColor}
                                                disabled={createStripeButtonDisabled}
                                                buttonLabel={buttonLabel}
                                                cta="event tickets page"
                                                onClick={() => openStripeLink()}
                                                outline = {true}
                                            />
                                        </div>:

                                        <div>
                                            <StripeOnboarding
                                                stripeStatus={stripeStatus}
                                                statusColor={statusColor}
                                                disabled={createStripeButtonDisabled}
                                                buttonLabel={buttonLabel}
                                                cta="event tickets page"
                                                onClick={() => openStripeLink()}
                                                outline = {false}
                                            />
                                        </div>}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    : dataTable.rows.length > 0 ?
                    <Row>
                    <Col className="col-12">
                        <Card>
                        <CardBody>
                        
                                <div style={{ display: "flex", justifyContent: "flex-end", top: "21px", position: "relative" }} >
                                    <Button style={{marginRight:"8px", zIndex:1000}} color="primary" type="button" size="sm" onClick={()=>downloadCSV()}>Download csv file</Button>
                                    <Button color="primary" disabled={canCreateVoucher} type="button" size="sm" onClick={() => createTicket()}>Add ticket</Button>
                                </div>
                            
                                <MDBDataTable className="sellVouchers" fixed={true} entries={10} displayEntries={false} hover pagesAmount={10} scrollX  data={{ columns: dataTable.columns, rows: dataTable.rows }} noBottomColumns={true} />
                        
                            {downloadingCSV &&
                            <div style={{position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
                            <Spinner/>
                        </div>}
                        </CardBody>
                        </Card>
                    </Col>
                    </Row>
                    :
                        <EmptyState disabled={canCreateVoucher} createVouchers={createTicket} createEventFirst={outlets.length< 1 ? true : false}/> 
                    
                }
                {modalStripePromptStatus ? <StripePrompt cta="event tickets page" disableStripeButtonLInk={() => setCreateStripeButtonDisabled(true)} stripeLink={stripeLink} modalStripePromptStatus={modalStripePromptStatus} close={(item, state, type) => closeForm(item, state, type)} /> : null}
                {modalSellEventTicketsForm ? <SellEventTicketsForm outlet={outlets[0]} sellEventTicket={sellEventTicket} eventList={events} mode={mode} modalSellEventTicketsForm={modalSellEventTicketsForm} close={(item, state, type) => closeForm(item, state, type)} /> : null}
                {modalImageOverlayStatus ? <ImageOverlay state={modalImageOverlayStatus} caption={sellEventTicket.promotion_caption} close={(item, state, type) => closeForm(item, state, type)} imageUrl={sellEventTicket.image_url} /> : null}
            </RBAC>
        </React.Fragment>
    )

}


export default SellEventTickets;