import PropTypes from 'prop-types'
import React, { useEffect, useState} from "react"
import { useIdleTimer } from 'react-idle-timer'
import { BrowserRouter as Router, Switch } from "react-router-dom"
import { connect, useDispatch } from "react-redux"

// amplify
import aws_exports from './aws-exports'

// Import Routes all
import { userRoutes, authRoutes, guestRoutes, webformRoutes, eventRoutes } from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// store actions
import { setUser, setUserMerchants, setSelectedMerchant, setConfig, setEnv, logoutUser } from "./store/actions"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
// import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"

// import amplify class helper
import { initAmplifyBackend } from './helpers/authHelper'
import { getConfig, getEnv } from './helpers/apiHelper'

// import amplitude
import amplitude from 'amplitude-js';

//import utils
import  { getHashVersion } from './utils'

//import firebase
import { initializeApp } from "firebase/app";

//initializing amplify
initAmplifyBackend(aws_exports)

const App = props => {

  const dispatch = useDispatch()
  const IDLE_HR = 1
  let idleTimer = null
  const [configLoaded , setConfigLoaded] = useState(false)
  const [timeout, setTimeout] = useState(null)

  useEffect(() => {
    setTimeout(1000 * 60 * 60 * IDLE_HR)
  }, [])

  async function getCMSConfig() {
    try {
      const config = await getConfig()
      if(config && Object.keys(config).length>0){
        let amplitudeKey = config.cmsv2.amplitude.apiKey
        amplitude.getInstance().init(amplitudeKey)
        amplitude.getInstance().setEventUploadThreshold(10000)
        initFirebase(config.cmsv2.firebaseRemoteConfig)
        dispatch(setConfig(config))
        setConfigLoaded(true)
      }
      
      const env = getEnv()
      dispatch(setEnv(env))
    } catch(error) {
      console.log('error on App.js ', error)
    }
  }

  getCMSConfig()

  const initFirebase = (config) => {
    const app = initializeApp(config);  
  }

  function getLayout() {
    let layoutCls = VerticalLayout
    return layoutCls
  }

  async function onIdle() {
    
    const userHash = sessionStorage.getItem('hash')
    const { origin } = new URL(window.location.href)
    const currentHash = await getHashVersion(origin)
    console.log('location', window.location.pathname)
    if (currentHash !== userHash && 
      window.location.pathname.indexOf('/webform') === -1 && 
      window.location.pathname.indexOf('/checkin') === -1 && 
      window.location.pathname.indexOf('/groupinfo') === -1 &&
      window.location.pathname.indexOf('/event') === -1) {
      sessionStorage.setItem('hash', currentHash)
      window.location = '/login?eraseCache=true' // just reload
      // window.location = window.location.href+'?eraseCache=true'; //logout and reload
    } else {
      idleTimer.start()
    }
  }

  const Layout = getLayout()

  // find other way to set the user
  const user = localStorage.getItem('user')
  if (user) {
    const userObj = JSON.parse(user)
    userObj.roles = JSON.parse(userObj.roles)
    props.setUser(userObj)
  } else {
    props.setUser(null)
  }
  let userMerchants = localStorage.getItem('userMerchants') || null
  let selectedMerchant = localStorage.getItem('selectedMerchant') || null

  if (userMerchants) {
    userMerchants = JSON.parse(userMerchants)
    props.setUserMerchants(userMerchants)
  }
  
  if (selectedMerchant) {
    props.setSelectedMerchant(JSON.parse(selectedMerchant))
  } else if (userMerchants && userMerchants.length > 0) {
    const selectedMerchant = userMerchants[0] // get the first merchant to be set as default
    delete selectedMerchant.outlets // we dont need the outlets so remove it
    props.setSelectedMerchant(selectedMerchant)
  }
  
  idleTimer = useIdleTimer({ onIdle, timeout, debounce: timeout/2})
   
  return (
    <React.Fragment>
      <Router>
        <Switch>
          {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              isConfigLoaded={configLoaded}
            />
          ))}

          {userRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
              isConfigLoaded={configLoaded}
            />
          ))}

          {guestRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              isConfigLoaded={configLoaded}
            />
          ))}

          {webformRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              isConfigLoaded={configLoaded}
            />
          ))}

          {eventRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              isConfigLoaded={configLoaded}
            />
          ))}         
        </Switch>
      </Router>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
  history: PropTypes.object,
  logoutUser: PropTypes.func
}

const mapStateToProps = state => {
  return {
    layout: state.Layout
  }
}

export default connect(mapStateToProps, { setUser, setUserMerchants, setSelectedMerchant, logoutUser })(App)
