
import React from "react"
import {CardBody, CardImg, CardText, Button } from 'reactstrap'

import emptyState from '../../../assets/images/empty-state.png'

const EmptyState = (props) => {

    return (
        <CardBody className="text-center">
            <CardImg className="empty-state-img" src={emptyState} />
            <CardText className="empty-state-title">No summary available</CardText>
            <CardText className="empty-state-subtext">Start selling vouchers now!</CardText>
            <Button onClick={() => props.history.push('/sell-vouchers')} color="primary">Sell vouchers now!</Button>
        </CardBody>
    )
}

export default EmptyState