import MetaTags from 'react-meta-tags';
import React, { useState, useEffect } from "react"
import { Row, Col, CardBody, Card, Alert, Container, Spinner } from "reactstrap"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { loginUser, loginSuccess, setAmplitudeEvent} from "../../store/actions"
import logoLightPng from "../../assets/images/logo-onboarding.png"
import { getAmplifyBackend } from "helpers/authHelper";
import { LOGIN_INCORRECT_USERNAME_PASSWORD, LOGIN_USER_NOT_CONFIRMED, LOGIN_USER_NOT_FOUND } from 'utils/constants';
import { amplitudeUserProperty, openInNewTab, getHashVersion } from 'utils';
import { getUser } from 'helpers/GraphQL/user';

const Login = props => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [chkRememberMe, setChkRememberMe] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const dispatch = useDispatch()
  const amplifyBackend = getAmplifyBackend()

  const handleValidSubmit = async() => {
    try{
    setLoading(true)
    let loginResponse =  await amplifyBackend.signin(email, password)
    
    if (loginResponse === LOGIN_INCORRECT_USERNAME_PASSWORD.code) {
      setError(LOGIN_INCORRECT_USERNAME_PASSWORD.message)
      setLoading(false)
    } else if (loginResponse === LOGIN_USER_NOT_CONFIRMED.code) {
      await amplifyBackend.resendCode(email)
      dispatch(loginUser({email:email, password:password}))
      props.history.push("/email-verification-unconfirmeduser")
      setError(LOGIN_USER_NOT_CONFIRMED.message)
      setLoading(false)
    } else if (loginResponse === LOGIN_USER_NOT_FOUND.code) {
      setError(LOGIN_USER_NOT_FOUND.message)
      setLoading(false)
    } else{
      dispatch(loginSuccess(loginResponse))
      localStorage.setItem('authUser', JSON.stringify(loginResponse))
      let role = await getUserRole()
      if (role > 0){
        localStorage.setItem('sideBarType', 'default')
        props.history.push("/dashboard")
      }else{
        localStorage.setItem('sideBarType', 'setup')
        props.history.push("/setup-company-profile")
      }
      
      dispatch(setAmplitudeEvent('CMS login page - login button', {'registration status': 'Y'})) 
      amplitudeUserProperty('registration status', 'Y')
      
    }
  } catch(error) {
    setLoading(false)
    setError('Error submitting this form. Please report to the Administrator.')
    dispatch(setAmplitudeEvent('Error - login', { 'details': error }))
  }
  }

  const getUserRole = async() => { //handle if user has setup a company profile
    let response = await getUser(email)
    let roles = JSON.parse(response.roles)
    return roles.merchants?.length
  }

  useEffect(() => {
    if (props.error && typeof props.error === "string"){
      setLoading(false)
    }
  }, [props.error])

  useEffect(async () => {
    // make sure all local storage are clear first in this page
    localStorage.clear();
    amplifyBackend.signOut()

    const { origin } = new URL(window.location.href)
    const currentHash = await getHashVersion(origin)
    sessionStorage.setItem('hash', currentHash)

    dispatch(setAmplitudeEvent('CMS login page - page load', {'registration status': 'N'})) 
    amplitudeUserProperty('registration status', 'N') 
  }, [])
  
  const rememberMe = (e) =>{
    setChkRememberMe(e.target.checked) 
    dispatch(setAmplitudeEvent('CMS login page - remember me button', {'registration status': 'N'})) 
    amplitudeUserProperty('registration status', 'N')
  }

  const forgotPassword = () =>{
    dispatch(setAmplitudeEvent('CMS login page - forgot your password', {'registration status': 'N'})) 
    amplitudeUserProperty('registration status', 'N')
  }
  const signUpNow = () => {
    dispatch(setAmplitudeEvent('CMS login page - signup now', {'registration status': 'N'})) 
    amplitudeUserProperty('registration status', 'N')
  }

  return (
    <React.Fragment>
      <meta 
        name='viewport' 
        content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' 
      />
      <MetaTags>
        <title>Login | CardsPal Business</title>
      </MetaTags>
      <div className="account-pages login-background">
        <div style={{ position:'relative' }}>
          <a href='#' onClick={()=>openInNewTab('https://cardspal.com/wp-content/uploads/2022/03/CardsPal-Merchant-Guide_Mar-2022.pdf')} className="viewUserGuide">View merchant guide</a>
        </div>
        <Container>
          <Row className="justify-content-center vertical-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden mt-5">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-4">
                    <Link to="/" className="d-block auth-logo">
                      <img src={logoLightPng} alt="" height="50" className="auth-logo-dark" />
                    </Link>
                  </h3>
                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-1 text-center">Welcome Back !</h4>
                    <p className="text-muted text-center">Sign in to continue to CardsPal Biz.</p>
                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {typeof error === "string" ? (
                        <Alert color="danger">{error}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          value= {email}
                          className="form-control"
                          type="email"
                          required
                          errorMessage="This field is required"
                          onChange={(e)=>setEmail(e.target.value)}
                          autoComplete = 'off'
                          autoCapitalize="none"
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          value={password}
                          type="password"
                          required
                          errorMessage="This field is required"
                          onChange={(e)=>setPassword(e.target.value)}
                          autoComplete = 'off'
                        />
                      </div>

                      <div className="mb-3 row mt-4">
                        <div className="col-6">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInline"
                              onChange={(e)=>rememberMe(e)} 
                              checked={chkRememberMe}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customControlInline"
                            >
                              Remember me
                            </label>
                          </div>
                        </div>
                        <div className="col-6 text-end">
                          {loading?<Spinner size="sm" style={{"marginRight":"10px"}}/>:null}
                          <button disabled={loading} className="btn btn-primary w-md waves-effect waves-light" type="submit">Log In</button>
                        </div>
                      </div>
                      <div className="form-group mb-0 row">
                        <div className="col-12 mt-4">
                          <Link to="/forgot-password" onClick={()=>forgotPassword()} className="text-muted"><i className="mdi mdi-lock"></i> Forgot your password?</Link>
                        </div>
                      </div>

                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  <Link to="#" onClick={()=>openInNewTab('https://cardspal.com/support/')}><span>Support Center</span></Link><span className="separator">|</span>
                  <Link to="#" onClick={()=>openInNewTab('https://cardspal.com/merchant_terms/')}><span>Merchant T&C</span></Link><span className="separator">|</span>
                  <Link to="#" onClick={()=>openInNewTab('https://cardspal.com/terms-of-use/')}><span>Terms of Use</span></Link><span className="separator">|</span>
                  <Link to="#" onClick={()=>openInNewTab('https://cardspal.com/privacy-policy/')}><span>Privacy Policy</span></Link><span className="separator">|</span>
                  <Link to="/faqs"><span>FAQ</span></Link>
                </p>
                <p>
                  © {new Date().getFullYear()} CardsPal Business 
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Login