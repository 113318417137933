import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { PHONE_REGEX, MARKETING_TEXT, TERMS } from 'utils/constants';
import platform from 'platform';
import styled from 'styled-components';
import { remoteConfig } from 'utils';
import { parseQueryString } from '../../utils';
import { Spinner } from "reactstrap"
import Appform_Contact_ActivEdge from './Components/Appform_Contact_ActivEdge';

const AppFormLanding_ActivEdge = (props) => {

  const search = props.location.search;

  useEffect(async () => {
    await loadData()
  }, [])

  const [custom, setCustom] = useState(null)
  const [eventId, setEventId] = useState(null)
  const [dealId, setDealId] = useState(null)
  const [qty, setQty] = useState(null)
  const [countryCode, setCountryCode] = useState([])

  const terms = () => {
    const OS = platform.os.family
    const mobileOS = OS === 'iOS' || OS === 'Android'

    if (mobileOS || !mobileOS) { //open in app & ext browser
      window.open(TERMS, '_blank').focus()
    }
  }

  const postMessage = (output) => {
    try {
      window.ReactNativeWebView && window.ReactNativeWebView.postMessage(output);
    } catch (e) {
      console.error(e);
    }
  }

  const onSubmit = (values) => {
    // console.log(values);

    const output = JSON.stringify(values)
    // console.log(output);

    postMessage(output);

    // setTimeout(() => {
    //   reset()
    // }, 1000);
  }

  const onError = (errors) => {
    console.log(errors);
  }



  const loadData = async () => {
    const queryString = parseQueryString(search)
    setEventId(queryString.eventId)
    setDealId(queryString.dealId)
    setQty(queryString.qty)

    let cust = await remoteConfig("b2bCustom")
    let val = null
    if (cust._value) {
      let parseCustJson = JSON.parse(cust._value)
      val = parseCustJson[queryString.eventId]
      setCustom(val)
      getCountry(val)
    }
  }
  const getCountry = async (customData) => {
    let rmCountry = await remoteConfig("country")
    let parseCountry = JSON.parse(rmCountry._value)
    let countryList = parseCountry.filter(item => {
      return !!item.nationality
    })
    countryList.sort((a, b) => (a.priority != null ? a.priority : Infinity) - (b.priority != null ? b.priority : Infinity))

    let _countryCode = countryList.map(item => {
      return {
        value: `+${item.code}`,
        label: `+${item.code}`
      }
    })

    _countryCode = _countryCode.filter((x) => x.value.includes(customData?.countryRestriction))
    setCountryCode(_countryCode)
  }
  return (
    <FormContainer fluid>
      {eventId && dealId && custom?.dealInfo[dealId] ?
        <Appform_Contact_ActivEdge qty={qty} custom={custom} countryCode={countryCode} webFormId={"0006"} eventsId={eventId}/>
        :
        <Spinner />
      }
    </FormContainer>
  )
};

const FormContainer = styled(Container)`
  display: flex;
  justify-content: center;
  position: absolute;
  background: white;
  min-height: 100%;
  overflow: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
`

const FormWrap = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute; 
  background: #fff;
  color: #000;
  width: 27.5rem;
  padding: 1.875rem;

  @media (max-width: 430px) {
    width: 24.5rem;
  }

  @media (max-width: 390px) {
    width: 22.5rem;
  }
`

const FormButton = styled(Button)`
  width: 100%;
  height: 2.8125rem;
  margin: 0 auto;
  background: #722ED1; 
  border-radius: 0.625rem;
  cursor: pointer;
`

const FormField = styled.div`
  margin-bottom: 1.25rem;

  .error {
    border: 1px solid red;
    color: #000;
  }
`

const FormFieldTerms = styled.div`
  margin-bottom: 1.25rem;

  .form-check [type=checkbox] {
    width: 15px;
    height: 15px;
  }
  label {
    font-weight: 400;
  }
  a {
    color: #722ED1;
  }
`

const FormFieldSubscribe = styled.div`
  margin-bottom: 1.75rem;
  
  .form-check [type=checkbox] {
    width: 15px;
    height: 15px;
  }
  label {
    font-weight: 400;
  }
`

const H2 = styled.h2`
  font-size: 18px;
  margin-bottom: 1.25rem;
`

const P = styled.p`
  color: red;
  margin: 0;
`

export default AppFormLanding_ActivEdge;