
import {
    SET_CONFIG,
    SET_ENV
} from './actionTypes';

export const setConfig = (config) => ({
    type: SET_CONFIG,
    payload: {
        config
    }
});


export const setEnv = (env) => ({
    type: SET_ENV,
    payload: {
        env
    }
})