import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useEffect,  useState } from "react"
import { getAmplifyBackend } from "helpers/authHelper";
import { Row, Col, CardBody, Card, Alert, Container, Spinner } from "reactstrap"
import { connect, useSelector, useDispatch } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { AvForm, AvField } from "availity-reactstrap-validation"

import logoLightPng from "../../assets/images/logo-onboarding.png"

// import graphql
import { createUser } from 'helpers/GraphQL/user';

// import utils
import { amplitudeUserProperty, openInNewTab } from "utils";

// store actions
import { loginUser, apiError, changeSidebarType, setAmplitudeEvent, setUser } from "../../store/actions"

import { FREE_PLAN } from 'utils/constants';

const EmailVerificationUnconfirmedUser = props => {
  const loginUser = useSelector((state) => state.Login.user)
  const [otpCountDown, setOtpCountDown] = useState(30)
  const [code, setCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const amplifyBackend = getAmplifyBackend()
  const dispatch = useDispatch()

  const handleInputChange = (e) => {
    const { value } = e.target;
    
    setCode(value)
  };
  
  const handleValidSubmit = async () => {
    try {
      
      setLoading(true)
      let _email =  loginUser.email
      let _password = loginUser.password
      let verifyResponse = await amplifyBackend.verifyCode(_email, code)
      
      if (verifyResponse === 'SUCCESS'){  
        let signInResponse = await amplifyBackend.signin(_email, _password)

        if (signInResponse) {
          let getAuthUser = await amplifyBackend.getAuthenticatedUser()
             if (getAuthUser) {
              let user = {
                firstName: getAuthUser["given_name"],
                lastName: getAuthUser["family_name"],
                email: getAuthUser["email"],
                mobile: getAuthUser["custom:mobile"],
                roles: JSON.stringify({merchants:[]}),
                agreeToTerms: getAuthUser["custom:agree_terms"],
                marketingConsent: getAuthUser["custom:marketing_consent"],
                subPlan: FREE_PLAN,
              }
              await createUser(user)
    
              localStorage.setItem('sideBarType', 'setup')
              localStorage.setItem('authUser', JSON.stringify(signInResponse))
              localStorage.setItem('user', JSON.stringify(user))
              dispatch(setUser(user))
              props.history.push('/setup-company-profile');
              dispatch(setAmplitudeEvent('CMS email verification page - submit button', {'registration status': 'Y'}))
              amplitudeUserProperty('registration status','Y')
             }
        }
        setError('System error. Verification cannot proceed. Please report this to the Administrator.')
        setLoading(false)
        dispatch(setAmplitudeEvent('CMS email verification page - submit button', {'registration status': 'Pending'}))
        amplitudeUserProperty('registration status','Pending')
      } else{
        setLoading(false)
        setError('Invalid verification code provided, please try again.')
      }

    } catch(error) {
      setLoading(false)
      setError('Error submitting this form. Please report to the Administrator.')
      dispatch(setAmplitudeEvent('Error - email verification', { 'details': error }))
    }
  }

  useEffect(() => {
  const timer = otpCountDown > 0 && setInterval(() => setOtpCountDown(otpCountDown - 1), 1000);
   return () => clearInterval(timer);
  }, [otpCountDown]);

  useEffect(() => {
    window.onbeforeunload = function() {
        return true;
    };

    return () => {
        window.onbeforeunload = null;
    };
}, []);

useEffect(() => {
  dispatch(setAmplitudeEvent('CMS email verification page - page load', {'registration status': 'Pending'}))
  amplitudeUserProperty('registration status','Pending')
  
}, [])

const gotoSignIn = () =>{
  dispatch(setAmplitudeEvent('CMS email verification page - Sign in Here button', {'registration status': 'Pending'}))
  amplitudeUserProperty('registration status','Pending')
}

const resendCode = async() =>{
  setOtpCountDown(30)
  let _email = loginUser.email
  await amplifyBackend.resendCode(_email)
  setError(null)
  dispatch(setAmplitudeEvent('CMS email verification page- resend code button', {'registration status': 'Pending'})) 
  amplitudeUserProperty('registration status', 'Pending')
}

  const renderResend = () =>{
    return(
      <div>
        {otpCountDown > 0?
        <p>
          OTP Resend ({otpCountDown}s)
        </p>:  
        <p>
          OTP <Link to='#' onClick={()=>resendCode()}>Resend</Link>
        </p>}
      </div>
    )
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Email Verification | CardsPal Business</title>
      </MetaTags>
      <div className="account-pages login-background">
        <div style={{ position:'relative' }}>
            <a href='#' onClick={()=>openInNewTab('https://cardspal.com/wp-content/uploads/2022/03/CardsPal-Merchant-Guide_Mar-2022.pdf')} className="viewUserGuide">View merchant guide</a>
        </div>
        <Container>
          <Row className="justify-content-center vertical-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden mt-5">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-2">
                    <Link to="/" className="d-block auth-logo">
                      <img src={logoLightPng} alt="" height="50" className="auth-logo-dark" />
                    </Link>
                  </h3>
                  <div className="p-1">
                    <h4 className="text-muted text-center mb-3">Email Verification</h4>
                    {error?
                      <Alert color="info">
                        <p className="mb-0">
                          An OTP has been sent to your registered email.
                        </p>
                      </Alert>
                      : null}
                    <AvForm
                    
                      className="form-horizontal mt-4"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {typeof error === "string" ? (
                        <Alert color="danger">{error}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <AvField
                          name="otp"
                          label={renderResend()}
                          type="text"
                          required
                          errorMessage="This field is required"
                          validate={{number:true}}
                          value={code}
                          onChange={handleInputChange}
                        />
                      </div>

                      <div style={{paddingTop: "20px"}} className="mb-3 row mt-4">
                        <div className="col-12 text-end">
                          {loading ? <Spinner size="sm" style={{marginRight:"10px"}}/>:null}
                          <button style={{marginTop: "-20px"}} className="btn btn-primary w-md waves-effect waves-light" type="submit">Submit</button>
                        </div>
                      </div>

                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Remember it ?{" "}
                  <Link
                    to="register"
                    className="text-primary"
                    onClick={()=>{gotoSignIn()}}
                  >
                    {" "}
                    Sign In Here{" "}
                  </Link>{" "}
                </p>
                <p>
                  <Link to="#" onClick={()=>openInNewTab('https://cardspal.com/support/')}><span>Support Center</span></Link><span className="separator">|</span>
                  <Link to="#" onClick={()=>openInNewTab('https://cardspal.com/merchant_terms/')}><span>Merchant T&C</span></Link><span className="separator">|</span>
                  <Link to="#" onClick={()=>openInNewTab('https://cardspal.com/terms-of-use/')}><span>Terms of Use</span></Link><span className="separator">|</span>
                  <Link to="#" onClick={()=>openInNewTab('https://cardspal.com/privacy-policy/')}><span>Privacy Policy</span></Link>
                </p>
                <p>
                  © {new Date().getFullYear()} CardsPal Business 
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error } = state.Account
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, changeSidebarType })(EmailVerificationUnconfirmedUser)
)

EmailVerificationUnconfirmedUser.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object
}