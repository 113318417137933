import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useForm, Controller } from "react-hook-form";
import { Button, Form, Image } from 'react-bootstrap';
import { Row, Col, CardBody, Card, Alert, Container, Spinner, InputGroup } from "reactstrap"
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import styled from 'styled-components';
import * as Yup from 'yup';
import {
    Title, SubTitle, Underline, CardContainer, DateTimeData,
    TicketType, ErrMsg, Sup, SubmitButton, Terms, DeclineButton, PriceLabel, Caption
} from './Styled';
import { createAppUser, checkDuplicateEmail } from 'helpers/apiHelper'
import Popup from './Popup'
import amplitude from 'amplitude-js';
import { Auth } from 'aws-amplify';

const ContactInfo = (props) => {

    const initialValues = {
        email: props.prefill?.email ?? '',
        countryCode: props.prefill?.countryCode ?? '+65',
        phoneNumber: props.prefill?.phone ?? '',
        firstName: props.prefill?.firstName ?? '',
        lastName: props.prefill?.lastName ?? '',
        otpCountryCode: '+65'
    }

    const infoFields = ["email", "countryCode", "phoneNumber", "firstName", "lastName"]

    let dynamicKeys = {};
    if (props.issueQty && props.issueQty > 1) {
        for (let x = 2; x <= props.issueQty; x++) {
            Object.keys(infoFields).forEach((key) => {
                dynamicKeys[infoFields[key] + x] = infoFields[key].includes("email") ? Yup.string().email('Invalid email address').required('Required') : infoFields[key].includes("phoneNumber") ? Yup.string().required("Required") : Yup.string().required("Required")
                initialValues[infoFields[key] + x] = infoFields[key].includes("countryCode") ? '+65' : ''
            })
        }
    }
    if (props.custom?.customClause) {
        dynamicKeys[props.custom?.customClause.name] = props.custom?.customClause.required ? Yup.boolean()
            .required("Required")
            .oneOf([true], "Please accept terms and conditions") : null
    }

    const schema = Yup.object().shape({
        email: Yup.string()
            .email('Invalid email address')
            .required('Required'),
        countryCode: Yup.string().required("Required"),
        phoneNumber: Yup.string().required("Required"),
        firstName: Yup.string().required("Required"),
        lastName: Yup.string().required("Required"),
        terms: props.custom?.tnc ? Yup.boolean()
            .required("Required")
            .oneOf([true], "Please accept terms and conditions") : null,
        marketingConsent: props.custom?.marketingConsent ? Yup.boolean() : null,
        ...dynamicKeys
    })




    const onSubmitDecline = async (e) => {
        try {
            props.setIsBusy()
            let phoneNumber = `${e.countryCode}${e.phoneNumber}`
            e.reason = (e.reasonText && e.reason === "Others") ? e.reason + " - " + e.reasonText : e.reason
            let webFormData = {
                "webFormId": props.webFormId,
                "email": e.email,
                "phoneNumber": phoneNumber,
                "reason": e.reason,
                "firstName": e.firstName,
                "lastName": e.lastName,
                "prepopulated": "1"
            }
            // let response = await B2BDeclineAttendance(webFormData)
            // if (response) {
            //     if (response.body == "user_exists") {
            //         setErrorMessage("You have already submitted your declination.")
            //     }
            //     else {
            //         setNotAttending(false)
            //         setRsvped(true)
            //     }

            // }
            props.setNotBusy()
            amplitude.getInstance().logEventWithGroups('B2B - Decline')
        } catch (error) {
            console.error('onSubmitDecline', error)
        }
    }

    const [state] = useState(initialValues);
    const [notAttending, setNotAttending] = useState(false)
    const [rsvped, setRsvped] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [verified, setVerified] = useState(false)
    const [counter, setCounter] = useState(0);
    const [enableVerify, setEnableVerify] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [session, setSession] = useState(null)
    const [emailInput, setEmailInput] = useState("")
    const [phoneInput, setPhoneInput] = useState(null)
    const [nonSG, setNonSG] = useState(false)

    const { register, unregister, getValues, setError, setValue, handleSubmit, reset, clearErrors, control, watch, formState: { errors, isSubmitSuccessful, isValid } } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: state,
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        const timer =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    const SendOTP = async () => {
        const values = getValues()
        if (values?.otpCountryCode?.length > 1 && values?.otpPhoneNumber?.length > 1) {
            setIsLoading(true)
            const username = values.otpCountryCode + values.otpPhoneNumber
            Auth.signIn(username)
                .then((result) => {
                    clearErrors('otpPhoneNumber');
                    setCounter(60)
                    setEnableVerify(true)
                    setSession(result)
                    setIsLoading(false)
                })
                .catch(async (err) => {
                    if (err.code === 'UserNotFoundException') {
                        await signUp(username);
                    } else if (err.message === "User is disabled.") {
                        setError("otpPhoneNumber", { type: "custom", message: "User is blocked. Please contact support" })
                        setIsLoading(false)
                    } else {
                        setError("otpPhoneNumber", { type: "custom", message: "We've encountered an error sending you the code. Please try again later." })
                        setIsLoading(false)
                    }
                })
        } else {
            setError("otpPhoneNumber", { type: "custom", message: "Please enter a valid phone number" })
        }
    }

    const signUp = async (username) => {
        const password = 'Abcd@' + Math.floor(Math.random() * 90000)

        Auth.signUp({
            username: username,
            password: password,
            attributes: { phone_number: username, email: '' },
        }).then(async (data) => {
            await SendOTP()
        }
        ).catch(err => {
            if (err.code === 'InvalidParameterException') {
                setError("otpPhoneNumber", { type: "custom", message: "Invalid phone number. Please try again." })
            } else {
                setError("otpPhoneNumber", { type: "custom", message: "Invalid parameter. Please try again." })
            }
            setIsLoading(false)
        }
        )
    }
    const verify = () => {
        const values = getValues()
        const confirmationCode = values.otpVerify
        if (session && confirmationCode?.length > 1) {
            setIsLoading(true)
            Auth.sendCustomChallengeAnswer(session, confirmationCode)
                .then(() => {
                    Auth.currentAuthenticatedUser().then(async (user) => {
                        let userId = user.attributes['custom:uuid'] || user.attributes.sub
                        setIsLoading(false)
                        clearErrors("otpVerify")
                        let res = await createAppUser({ userId: userId, phoneNumber: session.username })
                        if (res.statusCode === 'User already exist' || res.statusCode === 'success') {
                            setValue("userId", userId)
                            setVerified(true)
                        } else {
                            setError("otpVerify", { type: 'custom', message: "Something went wrong. Please try again later" })
                        }
                    })
                        .catch((err) => {
                            console.log("err" + err)
                            setIsLoading(false)
                        });

                })
                .catch((err) => {
                    if (err.code === "NotAuthorizedException") {
                        if (err.message === "User is disabled.") {
                            setError('otpVerify', { type: 'custom', message: "User is blocked. Please contact support" });
                        } else {
                            setError('otpVerify', { type: 'custom', message: "Please enter a valid OTP" });
                            SendOTP()
                        }
                    }
                    else {
                        setError("otpVerify", { type: 'custom', message: "Something went wrong. Please try again later" })
                    }
                    setIsLoading(false)
                });
        } else {
            setError("otpVerify", { type: 'custom', message: "Please enter a valid OTP" })
        }
    }
    const onChange = async (e) => {
        // console.log('---onChange', e)
        if (e.target.name === "email") {
            setEmailInput(e.target.value)
            setValue("billingEmail", e.target.value)
        }

        if (e.target.name === "phoneNumber") {
            if (e.target.value) {
                setPhoneInput(parseInt(e.target.value))
                setValue("otpPhoneNumber", e.target.value)
            }
        }

        if (e.target.name.startsWith("email") && !errors[e.target.name]) {
            let loop = props.issueQty || 1
            const values = getValues()
            let duplicateFound = false
            let emailList = [e.target.value]

            for (let x = 0; x < loop; x++) {
                let index = x === 0 ? "" : x
                if (e.target.name !== "email" + index && e.target.value.length > 0) {
                    if (e.target.value === values["email" + index]) {
                        duplicateFound = true
                    }
                }
            }
            if (duplicateFound) {
                setError(e.target.name, { type: "custom", message: "Duplicate email found" })
            }
        }
    }

    const onSubmit = (e) => {
        // console.log('---submit', e)
    }

    const onBlur = async (e) => {
        if (e.target.name.startsWith("email")) {
            let loop = props.issueQty || 1
            const values = getValues()
            let duplicateFound = false
            let emailList = [e.target.value]

            for (let x = 0; x < loop; x++) {
                let index = x === 0 ? "" : x
                if (e.target.name !== "email" + index && e.target.value.length > 0) {
                    if (e.target.value === values["email" + index]) {
                        duplicateFound = true
                    }
                }
            }
            if (duplicateFound) {
                setError(e.target.name, { type: "custom", message: "Duplicate email found" })
            }
        }
    }

    const onError = (e) => {
        // console.log('---error', e)
    }

    const redirect = (URL) => {
        window.location.href = URL
    }


    const contactTemplate = (value) => {
        console.log(value)
        let render = []
        let loop = value ? value : 1
        for (let x = 0; x < loop; x++) {
            let index = value ? x === 0 ? "" : (x + 1) : ""
            render.push(
                <div key={"contactInfo-" + x} className='mb-4'>
                    {value && (
                        x === 0 ?
                            <SubTitle>Participant 1 (Myself)</SubTitle>
                            :
                            <SubTitle>Participant {x + 1}</SubTitle>
                    )
                    }
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Label>First name<Sup>*</Sup></Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Control
                                type="text"
                                name={"firstName" + index}
                                placeholder="Enter your first name "
                                className={errors.firstName && "error"}
                                autoComplete='off'
                                {...register("firstName" + index)}
                            />
                            <ErrorMessage
                                errors={"firstName" + index}
                                name="firstName"
                                render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                            />
                        </Col>
                    </Row>
                    <Underline className='mb-3' />
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Label>Last name<Sup>*</Sup></Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Control
                                type="text"
                                name={"lastName" + index}
                                placeholder="Enter your last name"
                                className={errors.lastName && "error"}
                                autoComplete='off'
                                {...register("lastName" + index)}
                            />
                            <ErrorMessage
                                errors={errors}
                                name={"lastName" + index}
                                render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                            />
                        </Col>
                    </Row>
                    <Underline className='mb-3' />
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Label>Email address<Sup>*</Sup></Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Control
                                type="text"
                                name={"email" + index}
                                placeholder="Enter your email"
                                className={errors.email && "error"}
                                autoComplete='off'
                                autoCapitalize='none'
                                disabled={props.isDisabled}
                                {...register("email" + index)}
                            />
                            <ErrorMessage
                                errors={errors}
                                name={"email" + index}
                                render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                            />
                        </Col>
                    </Row>
                    <Underline className='mb-3' />
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Label>Phone number*</Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Row>
                                <Col xs={'auto'}>
                                    <Controller
                                        name={"countryCode" + index}
                                        control={control}
                                        render={({ field: { onChange, value, name, ref } }) => {
                                            const handleSelectChange = (e) => {
                                                onChange(e?.value);
                                                if (!verified) {
                                                    setValue("otpCountryCode", e?.value)
                                                    if (e?.value !== "+65") {
                                                        setNonSG(true)
                                                    } else {
                                                        setNonSG(false)
                                                    }
                                                }
                                            };
                                            return (
                                                <Select
                                                    inputRef={ref}
                                                    value={props.countryCode.find((e) => e.value === value)}
                                                    options={props.countryCode}
                                                    placeholder='Select...'
                                                    onChange={handleSelectChange}
                                                    styles={{
                                                        control: base => ({
                                                            ...base,
                                                            height: 34,
                                                            minHeight: 34,
                                                            width: 120,
                                                        })
                                                    }}
                                                    isDisabled={props.isDisabled}
                                                />
                                            )
                                        }}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={"countryCode" + index}
                                        render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="number"
                                        name={"phoneNumber" + index}
                                        placeholder="Enter your phone number"
                                        className={errors.phoneNumber && "error"}
                                        autoComplete='off'
                                        {...register("phoneNumber" + index)}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={"phoneNumber" + index}
                                        render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                    />

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            )
        }
        return render
    }

    return (
        <React.Fragment>
            <Row className='justify-content-center'>
                <Col>
                    <Form onSubmit={handleSubmit(props.onSubmit, onError)} onChange={(e) => onChange(e)} onBlur={(e) => onBlur(e)}>
                        <fieldset disabled={props.isDisabled}>
                            <CardContainer style={props.styling ?? { backgroundColor: "#fff" }}>
                                <CardBody>
                                    <SubTitle>To start off, please share with us your contact info.</SubTitle>
                                    {contactTemplate(props.issueQty)}
                                </CardBody>
                            </CardContainer>

                            {props.cpSignIn &&
                                <CardContainer style={props.styling ?? { backgroundColor: "#fff" }}>
                                    <CardBody>
                                        <SubTitle>Billing Information</SubTitle>
                                        <Caption>Provide these information to keep track of your purchases.</Caption>
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Form.Label>Link your purchase to CardsPal Account</Form.Label>
                                                {nonSG ?
                                                    <div style={{ marginBottom: 5 }}>
                                                        <div style={{ color: "#6B6C7E", fontSize: 12, marginTop: -5 }}> For SG users only</div>
                                                    </div> : null}
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Row>
                                                    <Col xs={'auto'}>
                                                        <Row>
                                                            <Controller
                                                                name='otpCountryCode'
                                                                control={control}
                                                                render={({ field: { onChange, value, name, ref } }) => {
                                                                    const handleSelectChange = (e) => {
                                                                        onChange(e?.value);
                                                                        if (e?.value !== "+65") {
                                                                            setNonSG(true)
                                                                        } else {
                                                                            setNonSG(false)
                                                                        }
                                                                    };
                                                                    return (
                                                                        <Select
                                                                            inputRef={ref}
                                                                            value={props.countryCode.find((e) => e.value === value)}
                                                                            options={props.countryCode}
                                                                            placeholder='Select...'
                                                                            onChange={handleSelectChange}
                                                                            styles={{
                                                                                control: base => ({
                                                                                    ...base,
                                                                                    height: 34,
                                                                                    minHeight: 34,
                                                                                    width: 120,
                                                                                })
                                                                            }}
                                                                            isDisabled={verified}
                                                                        />
                                                                    )
                                                                }}
                                                            />
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="otpCountryCode"
                                                                render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                                            />
                                                        </Row>
                                                        {nonSG ? null :
                                                            <Row style={{ marginTop: "0.5rem", textAlign: "center" }}>
                                                                <Form.Label style={{ marginTop: "0.5rem", fontSize: 20 }} >OTP</Form.Label>
                                                            </Row>
                                                        }
                                                    </Col>
                                                    <Col >
                                                        <Row>
                                                            <InputGroup>
                                                                <Form.Control
                                                                    type="number"
                                                                    name="otpPhoneNumber"
                                                                    placeholder="Enter your phone number"
                                                                    className={errors.otpPhoneNumber && "error"}
                                                                    autoComplete='off'
                                                                    defaultValue={phoneInput}
                                                                    disabled={verified}
                                                                    {...register("otpPhoneNumber")}

                                                                />
                                                                {verified || nonSG ? null :
                                                                    <Button style={{ backgroundColor: "#722ED1", color: "#fff" }} disabled={counter > 0 ? true : false} onClick={() => SendOTP()} variant="outline-primary">{counter > 0 ? "Resend in " + counter + "s" : "Send OTP"}</Button>
                                                                }
                                                            </InputGroup>
                                                        </Row>
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="otpPhoneNumber"
                                                            render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                                        />
                                                        {nonSG ? null :
                                                            <div>
                                                                <Row style={{ marginTop: 10, textAlign: "center" }}>
                                                                    <InputGroup>
                                                                        <Form.Control
                                                                            type="number"
                                                                            name="otpVerify"
                                                                            placeholder="Enter OTP"
                                                                            className={errors.otpVerify && "error"}
                                                                            autoComplete='off'
                                                                            disabled={verified}
                                                                            {...register("otpVerify")}
                                                                        />
                                                                        {enableVerify ? <Button style={{ backgroundColor: verified ? "#2AD2C9" : "#722ED1", borderColor: verified ? "#2AD2C9" : "#722ED1", color: "#fff" }} onClick={() => verify()} disabled={verified} variant="outline-primary">{verified ? "Verified" : "Verify"}</Button> : null}

                                                                    </InputGroup>
                                                                </Row>
                                                                <ErrorMessage
                                                                    errors={errors}
                                                                    name="otpVerify"
                                                                    render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                                                />
                                                                {!verified && !errors.otpVerify && !props.isDisabled &&
                                                                    <ErrMsg>Please make sure to verify your mobile number.</ErrMsg>
                                                                }
                                                            </div>
                                                        }
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: 20 }}>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Form.Label>Receive receipt in your email </Form.Label>
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Form.Control
                                                    type="text"
                                                    name="billingEmail"
                                                    placeholder="Enter your billing email"
                                                    className={errors.email && "error"}
                                                    autoComplete='off'
                                                    autoCapitalize='none'
                                                    defaultValue={emailInput}
                                                    {...register("billingEmail")}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="billingEmail"
                                                    render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    {isLoading ?
                                        <div style={{ flex: 1, top: "50%", right: "50%", position: "absolute", alignSelf: "center" }}>
                                            <Spinner style={{ color: "#722ED1" }} />
                                        </div>
                                        : null
                                    }
                                </CardContainer>
                            }

                            {props.custom?.hasPayment ?
                                <Row className='justify-content-center' style={{ marginTop: 10 }}>
                                    {props.custom?.customClause &&
                                        <Terms>
                                            <Form.Check
                                                name={props.custom?.customClause.name}
                                                type="checkbox"
                                                className={errors[props.custom?.customClause.name] && "error"}
                                                label={<div style={{ color: "#272833" }}>I have read and agree to the <a href={props.custom?.customClause?.link} target='_blank' rel='noreferrer'>{props.custom?.customClause?.text}</a>.{props.custom?.customClause.required ? '*' : null}</div>}
                                                {...register(props.custom?.customClause.name)} />
                                            <ErrorMessage
                                                errors={errors}
                                                name={props.custom?.customClause.name}
                                                render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                            />
                                        </Terms>

                                    }
                                    {props.custom?.tnc &&
                                        <Terms>
                                            <Form.Check
                                                name="terms"
                                                type="checkbox"
                                                className={errors.terms && "error"}
                                                label={<div style={{ color: "#272833" }}>I have read and agree to the <a href='https://cardspal.com/ticket-sales-terms-and-conditions/' target='_blank' rel='noreferrer'>T&Cs of Ticket Sales</a> and <a href='https://cardspal.com/privacy-policy/' target='_blank' rel='noreferrer'>CardsPal&apos;s Data Privacy Policy</a>.*</div>}
                                                {...register("terms")} />
                                            <ErrorMessage
                                                errors={errors}
                                                name="terms"
                                                render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                            />
                                        </Terms>

                                    }
                                    {props.custom?.marketingConsent &&
                                        <Terms>
                                            <Form.Check
                                                name="marketingConsent"
                                                type="checkbox"
                                                label={<div style={{ color: "#272833" }}>I wish to receive marketing materials (including news on other exciting events) from the organisers and/or CardsPal, and consent to the disclosure of my personal data to the organisers for such purpose.</div>}
                                                {...register("marketingConsent")} />
                                            <ErrorMessage
                                                errors={errors}
                                                name="marketingConsent"
                                                render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                            />
                                        </Terms>
                                    }
                                    <SubmitButton
                                        type="submit"
                                        className="btn btn-primary btn-block"
                                        disabled={
                                            !isValid || (!nonSG && !verified) ||
                                            Object.keys(errors).length > 0
                                        }
                                    >Continue to payment
                                    </SubmitButton>

                                </Row> :
                                <>
                                    {props.custom?.tnc &&
                                        <Row className='justify-content-center m-3 pt-3'>
                                            <Terms>
                                                <Form.Check
                                                    name="terms"
                                                    type="checkbox"
                                                    className={errors.terms && "error"}
                                                    label={<div style={{ color: "#272833" }}>I have read and agree to the <a href='https://cardspal.com/ticket-sales-terms-and-conditions/' target='_blank' rel='noreferrer'>T&Cs of Ticket Sales</a> and <a href='https://cardspal.com/privacy-policy/' target='_blank' rel='noreferrer'>CardsPal&apos;s Data Privacy Policy</a>.*</div>}
                                                    {...register("terms")} />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="terms"
                                                    render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                                />
                                            </Terms>
                                        </Row>
                                    }


                                    <Row className='justify-content-center'>
                                        <SubTitle className='text-center'>Will you be attending this event ?</SubTitle>
                                        <SubmitButton
                                            type="submit"
                                            className="btn btn-primary btn-block"
                                            disabled={
                                                !isValid ||
                                                Object.keys(errors).length > 0
                                            }
                                        >Yes
                                        </SubmitButton>
                                        {props.custom?.attendanceCheck &&
                                            <DeclineButton
                                                onClick={() => setNotAttending(true)}
                                                className="btn btn-primary "
                                                disabled={
                                                    !isValid ||
                                                    Object.keys(errors).length > 0
                                                }
                                            >No</DeclineButton>
                                        }
                                    </Row>
                                </>
                            }
                            <Popup
                                open={notAttending}
                                inputType="reason"
                                inputLabel="Reason"
                                title="Reason for not attending"
                                name="reason"
                                register={register}
                                watch={watch}
                                error={errorMessage}
                                isBusy={props.isDisabled}
                                submit={handleSubmit(onSubmitDecline, props.onError)}
                                cancel={() => (setNotAttending(false), setErrorMessage(""), unregister("reason"), unregister("reasonText"))}
                            />
                            <Popup
                                open={rsvped}
                                inputType={'message'}
                                title="Submission Successful"
                                message="Thank you for your time."
                                cancel={() => (setRsvped(false), unregister("reason"), unregister("reasonText"), props.custom?.redirectURL ? redirect(props.custom?.redirectURL) : null)}
                            />
                        </fieldset>
                    </Form>
                </Col>
            </Row>
        </React.Fragment>

    )
}

export default ContactInfo