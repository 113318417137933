import PropTypes from 'prop-types'
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import {useDispatch } from "react-redux"
import { logoutUser } from "../../store/actions"

const Logout = props => {
  const dispatch = useDispatch()
  useEffect(() => {
    props.logoutUser(props.history)
    dispatch({type:'LOGOUT'})
    window.location = '/login?eraseCache=true'
  }, [])
  
  return <>Logging out...</>
}

Logout.propTypes = {
  history: PropTypes.object,
  logoutUser: PropTypes.func
}

export default withRouter(connect(null, { logoutUser })(Logout))
