import React, { useEffect } from "react"
import MetaTags from 'react-meta-tags';
import { useSelector, useDispatch } from "react-redux";
import { Card, CardBody, Label } from "reactstrap"

// import utils

//import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems, setAlert } from "../../../store/actions";

// import graphql

const ContactUs = () => {
    const dispatch = useDispatch()
    const selectedMerchant = useSelector(state => state.Merchant.selectedMerchant)

    const breadcrumbItems = [
        { title: "CardsPal", link: "#" },
        { title: selectedMerchant.display_merchant_name, link: "#" },
        { title: "Support", link: "#" },
        { title: "Contact Us", link: "#" },
    ]

    useEffect(() => {
        dispatch(setBreadcrumbItems('Contact Us', breadcrumbItems))
        dispatch(setAlert(''))
    },[selectedMerchant])

    return (
        <React.Fragment>

            <MetaTags>
                <title>Contact Us</title>
            </MetaTags>
            <Card>
                <CardBody>
                    <Label>To contact us, you may write in to <a href = "mailto:partnership@cardspal.com">partnership@cardspal.com</a></Label>
                </CardBody>
            </Card>
        </React.Fragment>
    )

}


export default ContactUs;