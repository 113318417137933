import React, { useState, useEffect, Suspense } from 'react';
import { Button, Image } from 'react-bootstrap';
import { Row, Col, CardBody, Card, Container, Spinner } from "reactstrap"
import styled from 'styled-components';
import ClockPng from '../../assets/images/clockCircle.png'
import LocationPng from '../../assets/images/Location.png'
import RegistrationSuccessfulPng from '../../assets/images/registrationSuccessful.png'
import TicketPng from '../../assets/images/ticket.png'
import DownloadBannerPng from '../../assets/images/DownloadBanner.png'
import AccessDenied from 'components/Common/accessDenied';
import platform from 'platform';
import { APP_STORE, PLAY_STORE } from 'utils/constants';
import { openInNewTab } from 'utils';
import { fromUnixTime, format } from 'date-fns';
import { atcb_action, atcb_init } from 'add-to-calendar-button';
import 'add-to-calendar-button/assets/css/atcb.css';
import { getDealInfo, getEvents, getEnv } from 'helpers/apiHelper';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { BiEditAlt } from 'react-icons/bi';
import { GoCheck } from 'react-icons/go';
import { FaWallet } from 'react-icons/fa';
import { IoIosPaper } from 'react-icons/io';
import { SpinnerDiv, SubTitle, Description } from './Components/Styled';
import RecommendedDealsCarousel from 'components/Carousel/RecommendedDeals';
import { remoteConfig, getHappeningOn24 } from 'utils';
import { parseQueryString } from '../../utils';
import sha256 from 'crypto-js/sha256';
const LazyBanner = React.lazy(() => import('./Components/Banner'))

const DND_WAITLIST_DEAL_TAG = 'dnd-waitlist'
const B2BRegistrationSuccessful = (props) => {
    const params = new URLSearchParams(props.location.search)
    const webFormId = props.match.params.webFormId || '0009'
    const timestamp = Date.now()
    const [isAccessDenied, setAccessDenied] = useState(null);
    const [calendarEvent, setCalendarEvent] = useState({})
    const [dealInfo, setDealInfo] = useState([])
    const [eventInfo, setEventInfo] = useState([])
    const [custom, setCustom] = useState([])
    const [happeningOn, setHappeningOn] = useState('')
    const [hash, setHash] = useState(null)
    const [editLink, setEditLink] = useState('')
    const [viewMembersLink, setViewMembersLink] = useState('')

    const faviconPath = `https://assets.${getEnv()}.cardspal.com/public/Events/favicon/`
    const imagePath = `https://assets.${getEnv()}.cardspal.com/public/Events/banner/`
    const eventId = params.get("eventId");
    const dealId = params.get("dealId");
    const email = params.get("email");
    const userId = params.get("userId")
    const transactionId = params.get("transactionId");
    const qty = params.get("qty");
    const groupName = params.get('groupName')
    const action = params.get('action')

    useEffect(async () => {
        let deal = await getDealInfo(dealId)
        let event = await getEvents(eventId)
        let custom = await getCustomSettings(eventId)
        let date = getHappeningOn24(event?.data?.events?.startTimestamp, event?.data?.events?.endTimestamp)

        setDealInfo(deal.data)
        setEventInfo(event?.data?.events)
        setCustom(custom)
        setHappeningOn(date)
        
        const hashValue = sha256(`${dealId};${userId}`)
        const hashHex = hashValue.toString()
        // console.log({dealId}, {userId}, {hashHex})
        setHash(hashHex)
        setEditLink(`/webform/edit/${webFormId}/${eventId}/${transactionId}/?hash=${hashHex}`)

        if (deal.data && event?.data?.events) {
            let startTime = fromUnixTime(Number(deal.data.start_timestamp))
            let endTime = fromUnixTime(Number(deal.data.end_timestamp))
            const eventData = {
                title: event?.data?.events?.name,
                description: event?.data?.events?.description,
                startTime: startTime,
                endTime: endTime,
                location: event?.data?.events?.location,
            }
            document.title = eventData.title;

            let link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = `${faviconPath}${eventId}_favicon.ico`;

            setViewMembersLink(`/webform/view-group-members/${groupName}?webFormId=${webFormId}&userId=${userId}`)
            setCalendarEvent(eventData)
            setAccessDenied(false)
        } else {
            const params = parseQueryString(props.location.search)
            console.debug('query string params: ', params)
            setAccessDenied(true)
        }
    }, [])

    useEffect(atcb_init, []);

    const downloadApp = (e) => {
        e.preventDefault()
        e.stopPropagation()

        const OS = platform.os.family
        if (OS === 'iOS') {
            openInNewTab(APP_STORE)
        } else if (OS === 'Android') {
            openInNewTab(PLAY_STORE)
        } else {
            openInNewTab(PLAY_STORE)
        }
    }

    const getCustomSettings = async (eventId) => {
        let cust = await remoteConfig("b2bCustom")
        if (cust._value) {
            let parseCustJson = JSON.parse(cust._value)
            return (parseCustJson[eventId])
        } else return null
    }

    const addToCalendar = () => {
        if (eventInfo) {
            let unixStartTimestamp = fromUnixTime(Number(eventInfo.startTimestamp))
            let unixEndTimestamp = fromUnixTime(Number(eventInfo.endTimestamp))
            let startDate = format(unixStartTimestamp, "yyyy-MM-dd")
            let endDate = format(unixEndTimestamp, "yyyy-MM-dd")
            let startTime = format(unixStartTimestamp, "HH:mm")
            let endTime = format(unixEndTimestamp, "HH:mm")

            atcb_action({
                name: eventInfo.name,
                startDate: startDate,
                endDate: endDate,
                startTime: startTime,
                endTime: endTime,
                description: eventInfo.description,
                location: eventInfo.location,
                options: [
                    "Google|Google Calendar",
                    "Apple|Apple Calendar",
                    "Microsoft365|Outlook Calendar",
                    "Yahoo|Yahoo Calendar"
                ],
                timeZone: "Asia/Singapore",
                iCalFileName: eventInfo.name,

            })
        }
    }

    const getSuccessTitle = () => {
        if (dealInfo?.ticketType.toLowerCase() === DND_WAITLIST_DEAL_TAG) {
            return 'Reservation Details'
        } else {
            return 'Reservation Details'
        }
    }

    const getSuccessStatusText = () => {
        if (action === 'update_reservation') {
            return 'Reservation Updated!'
        } else if (dealInfo?.ticketType.toLowerCase() === DND_WAITLIST_DEAL_TAG) {
            return 'The event is full!'
        } else {
            return 'Reservation Successful!'
        }
    }

    const getSuccessThankYou = () => {
        if (action === 'update_reservation') {
            return ('')
        } else if (dealInfo?.ticketType.toLowerCase() === DND_WAITLIST_DEAL_TAG) {
            return (<>You are on waitlist to <span>{eventInfo.name}</span>!</>)
        } else {
            return (<>Thank you for reserving a ticket to <span>{eventInfo.name}</span>!</>)
        }
    }

    return (
        <React.Fragment>
            {isAccessDenied === null ? <></> :
                (isAccessDenied ? <AccessDenied screen="B2B" showBackToDashboard={false} /> :
                    <div style={{ backgroundColor: "#fff" }}>
                        <div  style={{ display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "white"}}>
                            <Suspense fallback={<SpinnerDiv><Spinner /></SpinnerDiv>}>
                                <LazyBanner imagePath= {imagePath + eventId + "_banner.jpg?" + timestamp} custom={custom}/>
                            </Suspense>
                        </div>
                        {/* <Image fluid src={imagePath + eventId + "_banner.jpg?" + timestamp} style={{width: '100%', maxHeight:"500px", objectFit: "contain"}}/> */}
                        <Container>

                            <Row className='mt-5 justify-content-center'>
                                <Description style={{ fontSize: 14 }}>{eventInfo?.name + " > " + dealInfo?.promotion_caption + " >  Confirmation"}</Description>
                                <div>
                                    <div style={{ maxWidth: 400, margin: "auto" }}>
                                        <div style={{ display: "flex", flexDirection: "row", marginInline: 30, marginBottom: 10, marginTop: 25, justifyContent: "center" }}>
                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                <Circle>
                                                    <TickIcon style={{ color: "#fff", marginBlock: 5, marginInline: 4 }} />
                                                </Circle>
                                            </div>
                                            <div style={{ width: 80, border: "0.5px solid #CDCED9", marginTop: 13, marginInline: 10, alignSelf: "center" }} className='mb-3' />
                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                <Circle style={{ backgroundColor: "#2AD2C9" }}>
                                                    <EditIcon style={{ color: "#fff", marginBlock: 4, marginInline: 4 }} />
                                                </Circle>
                                            </div>
                                            <div style={{ width: 80, border: "0.5px solid #CDCED9", marginTop: 13, marginInline: 10, alignSelf: "center" }} className='mb-3' />
                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                <Circle style={{ backgroundColor: "#2AD2C9" }}>
                                                    <WalletIcon style={{ color: "#fff", marginBlock: 5, marginInline: 5 }} />
                                                </Circle>
                                            </div>
                                            <div style={{ width: 80, border: "0.5px solid #CDCED9", marginTop: 13, marginInline: 10, alignSelf: "center" }} className='mb-3' />
                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                <Circle style={{ backgroundColor: "#722ED1" }}>
                                                    <NotesIcon style={{ color: "#fff", marginBlock: 5, marginInline: 5 }} />
                                                </Circle>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", marginInline: 30, marginBottom: 30, justifyContent: "center" }}>
                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                <div style={{ color: "#6B6C7E", fontSize: 11, width: 90, marginLeft: -30 }}>Select Tickets</div>
                                            </div>
                                            <div style={{ width: 80, marginTop: 13, alignSelf: "center" }} className='mb-3' />

                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                <div style={{ color: "#6B6C7E", fontSize: 11, width: 60 }}>Edit info</div>
                                            </div>
                                            <div style={{ width: 80, marginTop: 13, alignSelf: "center" }} className='mb-3' />

                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                <div style={{ color: "#6B6C7E", fontSize: 11, width: 50 }}>Confirm Reservation</div>
                                            </div>
                                            <div style={{ width: 80, marginTop: 13, alignSelf: "center" }} className='mb-3' />

                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                <div style={{ color: "#6B6C7E", fontSize: 11, width: 50 }}>Confirmation</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Col>
                                    <SubTitle style={{ marginLeft: 10 }}>{getSuccessTitle()}</SubTitle>
                                    <Card>
                                        <CardBody>
                                            <div className='d-flex flex-row align-items-center my-4'>
                                                <IconImage fluid src={RegistrationSuccessfulPng} />
                                                <H4 style={{ fontSize: 16 }}>{getSuccessStatusText()}</H4>
                                            </div>
                                            <div>
                                                <H4 style={{ fontSize: 14 }}>{getSuccessThankYou()}</H4>
                                                {dealInfo?.ticketType.toLowerCase() === DND_WAITLIST_DEAL_TAG ? 
                                                <P>We will keep you updated on your waitlist status by email by the 22nd of November.                                                </P>
                                                : !action ?
                                                <P>You will receive an email confirmation shortly at <Email>{email}</Email>.</P>
                                                : null
                                                }
                                                {eventInfo?.webFormId === '0009' &&
                                                <>
                                                    <P>You can modify the registration using this <span style={{ fontWeight: "bold", textDecoration: "underline"}}><a href={editLink} target="_blank" rel="noreferrer">link</a></span>.</P>
                                                    <P>You can view group members <a style={{ fontWeight: "bold", textDecoration: "underline"}} href={viewMembersLink} target="_blank" rel="noreferrer">here</a></P>
                                                </>
                                                }
                                            </div>

                                            <div className='d-flex flex-row align-items-center mt-4'>
                                                <IconImageSm fluid src={TicketPng} />
                                                <div>
                                                    {custom?.dealInfo[dealId]?.type === 'bundle' ?
                                                        <P>1 x {dealInfo?.promotion_caption}</P>
                                                        :
                                                        <P>{qty}x  {dealInfo?.promotion_caption}</P>
                                                    }
                                                </div>
                                            </div>
                                            <div className='d-flex flex-row mt-4'>
                                                <IconImageSm fluid src={ClockPng} />
                                                <div>
                                                    <P>{happeningOn}{custom?.seatingInfoText ? ` (${custom.seatingInfoText})` : null}</P>
                                                    <P1>Note: Some of these options provided work better on desktop browser.</P1>
                                                    <div className="mt-2">
                                                        <SubmitButton onClick={() => addToCalendar()}>Add to Calendar</SubmitButton>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex flex-row align-items-center mt-4'>
                                                <IconImageSm fluid src={LocationPng} />
                                                <div>
                                                    <P>{eventInfo.location}</P>
                                                </div>
                                            </div>

                                        </CardBody>

                                    </Card>
                                    <div style={{ backgroundColor: "#F8F8FA" }}>
                                        <RecommendedDealsCarousel dealId={dealId} hash={hash} />
                                    </div>

                                    <Row style={{marginTop: 40}} className="justify-content-center">
                                    <Col align="right">
                                        <div><a href={eventInfo?.terms} target='__blank'>Terms and Condition</a></div>
                                    </Col>
                                    <Col align="left">
                                        <div><a href={eventInfo?.faq} target='__blank'>Frequently Ask Questions</a></div>
                                    </Col>
                                    </Row>
                                    <a href='#' onClick={(e) => downloadApp(e)}>
                                        <DownloadAppBanner fluid src={DownloadBannerPng} />
                                    </a>

                                </Col>

                            </Row>

                        </Container>
                    </div>
                )
            }
        </React.Fragment>
    )
}

const IconImage = styled(Image)`
    width: 30px;
    height: 30px;
    margin-right: 10px;
    margin-top: -5px;
`

const IconImageSm = styled(Image)`
    width: 25px;
    height: 25px;
    margin-right: 10px;
`

const DownloadAppBanner = styled(Image)`
    display: block;
    width: 100%;
    //height: 100%;
`
const H4 = styled.h4`
    color: #000;
    span {
    color: #722ED1;
  }
`
const Email = styled.span`
color: #000;
font-weight: bold;
`
const ReferenceNo = styled.span`
color: #000;
font-weight: bold;
`

const P = styled.p`
    margin: 0;
    white-space: pre-line;
`

const P1 = styled.p`
    font-size: 10px;
    margin: 0;
`

const SubmitButton = styled(Button)`
background: linear-gradient(117.03deg, #3672F8 0%, #B01EFF 100%);
`
const TickIcon = styled(GoCheck)`
  color: #3158d6;
  font-size: 16px;
`
const EditIcon = styled(BiEditAlt)`
  color: #3158d6;
  font-size: 16px;
`
const WalletIcon = styled(FaWallet)`
  color: #3158d6;
  font-size: 14px;
`
const NotesIcon = styled(IoIosPaper)`
color: #3158d6;
font-size: 14px;
`
const Circle = styled.div`
    background: #2AD2C9;
    width: 25px;
    height: 25px;
    border-radius: 50%;
`

export default B2BRegistrationSuccessful