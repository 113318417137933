import React, { useEffect, useState, useRef } from "react"
import MetaTags from 'react-meta-tags';
import { connect, useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, CardImg, Spinner, Button, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem,} from "reactstrap"
import {InputGroup, Form} from 'react-bootstrap';
import { MDBDataTable } from "mdbreact"
import { fromUnixTime, format } from 'date-fns';
import Decimal from 'decimal.js';
import RBAC from '../../components/Common/rbac'

// import components
import StripePrompt from "components/Common/stripePrompt";
import StripeOnboarding from "components/Common/stripeOnboarding";
import BoxWidget from "components/Common/boxWidget";
import EmptyState from  "./Components/emptyState"
import Chart from "react-apexcharts";

// import utils
import { evaluateStripeStatus, openInNewTab, computeSoldVouchers, convertToCSV } from '../../utils'
import  {getStripeStatusColor} from '../../utils/stripe'
import { STRIPE_STATUS, GENERIC_ERROR_MESSAGE, STRIPE_BUTTON_LABEL, DEFAULT_REFRESH_URL, DEFAULT_RETURN_URL } from '../../utils/constants'

//import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems, setAlert, setAmplitudeEvent,  setConfig, setEnv } from "../../store/actions";

// import helpers
import { stripeCreateOnboardingLink, stripeRetrieveKey, stripeRetrieveAccount, stripeCreateLoginLink, getConfig, getEnv } from '../../helpers/apiHelper'
import { getMerchantStripeAccount, updateStripeAccount, createStripeAccount } from "../../helpers/GraphQL/userStripeAccount"
import { getSalesSummaryDealsList, getDealById } from '../../helpers/GraphQL/promotions'
import { listVouchersByDealId } from '../../helpers/GraphQL/voucherMaster'
import { getAllTransactionsByDealIds } from 'helpers/GraphQL/transactions';

// import assets
import sellVoucherStepImg from '../../assets/images/sellVoucherStepsC.png'





const TransactionSummary = (props) => {
    const dispatch = useDispatch()
    const selectedMerchant = useSelector(state => state.Merchant.selectedMerchant)
    const cmsConfig = useSelector(state => state.Config)
    const userInfo = useSelector(state => state.User.user)
    const emailRegex = /[a-z0-9]+_[a-z]+\.[a-z]{2,3}/

    const [stripeKey, setStripeKey] = useState(null)
    const [stripeLink, setStripeLink] = useState()
    const [stripeAccountStatus, setStripeAccountStatus] = useState('')
    const [modalStripePromptStatus, setModalStripePromptStatus] = useState(false)

    const [totalRevenue, setTotalRevenue] = useState(null)
    const [soldVouchers, setSoldVouchers] = useState(null)
    const [totalDiscount, setTotalDiscount] = useState(null)
    const [stripeChecking, setStripeChecking] = useState(true)
    const [refreshUrl, setRefreshUrl] = useState(null)
    const [returnUrl, setReturnUrl] = useState(null)
    const [needPrompt, setNeedPrompt] = useState(null)
    const [createStripeButtonDisabled, setCreateStripeButtonDisabled] = useState(true)
    const [stripeButtonLabel, setStripeButtonLabel] = useState('')
    const [stripeAccount, setStripeAccount] = useState({})
    const [dealVouchers, setDealVouchers] = useState(null)
    const [selectedDealVoucher, setSelectedDealVoucher] = useState({})

    const breadcrumbItems = [
        { title: "CardsPal", link: "#" },
        { title: selectedMerchant.display_merchant_name, link: "#" },
        { title: "Tools", link: "#" },
        { title: "Transactions Summary", link: "#" },
    ]

    const dataContainer = useRef(null)


    const transactionData = {
        columns: [
            {
                label: "ID",
                field: "id",
                width: 40,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Transaction ID",
                field: "transactionId",
                width: 80,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Transaction Date and Time",
                field: "transactionDateTime",
                width: 100,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Purchaser Email",
                field: "purchaserEmail",
                width: 100,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Deal Title",
                field: "dealTitle",
                width: 100,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Purchase Price",
                field: "purchasePrice",
                width: 70,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Qty",
                field: "qty",
                width: 40,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Discount Code",
                field: "discountCode",
                width: 70,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Discount Code Value",
                field: "discountCodeValue",
                width: 80,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            },
            {
                label: "Purchase Price Total",
                field: "purchasePriceTotal",
                width: 80,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            },
            {
                label: "Source/Medium",
                field: "utmSourceMedium",
                width: 80,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            },
            {
                label: "Campaign",
                field: "utmCampaign",
                width: 80,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            }
        ],
        rows: []
    }

    // const dataEventNoCheckin = {columns:[...dataEvent.columns.slice(0,11)], rows:[...dataEvent.rows]}
    // const dataNoCheckin = {columns:[...data.columns.slice(0,10)], rows:[...data.rows]}

    const [dataTable, setDataTable] = useState(transactionData)
    const [downloadingCSV, setDownloadingCSV] = useState(false)
    //  const [saleDateFrom, setSaleDateFrom] = useState(format(new Date(),"yyyy-MM-dd"));
    //  const [saleDateTo, setSaleDateTo] = useState(format(new Date(),'yyyy-MM-dd'));
    const [saleDateFrom, setSaleDateFrom] = useState("");
    const [saleDateTo, setSaleDateTo] = useState("");
    const [redemptionDateFrom, setRedemptionDateFrom] = useState("");
    const [redemptionDateTo, setRedemptionDateTo] = useState("");
    const [dataLoading, setDataLoading] = useState(false)
    const [tooltipInfo, setTooltipInfo] = useState([])


    //new stuff
    const [toggleFilter, setToggleFilter] = useState(false)
    const [selectedVoucherList, setSelectedVoucherList] = useState([])
    const [transactionList, setTransactionList] = useState([])
    const [initTransactionsList, setInitTransactionsList] = useState([])
    const [barSeries, setBarSeries] = useState([{
        name: ["Revenue"],
        data: [0,0,0,0,0,0,0]
    }])
    const today = new Date()
    const [barCategories, setBarCategories] = useState([format(today , "dd MMM yy"),
                                                        format(new Date().setDate(today.getDate()-1), "dd MMM yy"),
                                                        format(new Date().setDate(today.getDate()-2), "dd MMM yy"),
                                                        format(new Date().setDate(today.getDate()-3), "dd MMM yy"),
                                                        format(new Date().setDate(today.getDate()-4), "dd MMM yy"),
                                                        format(new Date().setDate(today.getDate()-5), "dd MMM yy"),
                                                        format(new Date().setDate(today.getDate()-6), "dd MMM yy")])
    const [totalVouchers, setTotalVouchers] = useState(0)
    const [allActiveDealsChecked, setallActiveDealsChecked] = useState(true)

    const tooltipStyle = {
        marginRight:"20px"
    }
    const options = {
        chart: {
            type: 'bar',
        },
        zoom:{
            enabled: false,
        },
        plotOptions: {
           // offsetY: -20,
          bar: {
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: false,
          formatter: function (val) {
            return val > 0  ?"$" + val.toFixed(2) : "";
          },
          offsetY: -10,
          style: {
            fontFamily:'Roboto, sans-serif',
            fontWeight:"Normal",
            fontSize: '0.8em',
            colors: ["#A3A3A3"]
          }
        },
        grid: {
            padding: {
                top:30,
                left:0,
                right:0
            },
            show:true
        },
        xaxis: {
            tickPlacement: 'on',
            categories: barCategories,
            position: 'bottom',
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            crosshairs: {
                fill: {
                    type: 'gradient',
                    gradient: {
                        colorFrom: '#D8E3F0',
                        colorTo: '#BED1E6',
                        stops: [0, 100],
                        opacityFrom: 0.4,
                        opacityTo: 0.5,
                    }
                }
            },
            tooltip: {
                enabled: false,
            },
            labels: {
                show: true,
                rotate: -45,
                rotateAlways: false,
                hideOverlappingLabels: true,
                showDuplicates: false,
                trim: false,
                minHeight: undefined,
                maxHeight: 120,
                style: {
                    fontFamily:'Roboto, sans-serif',
                    fontWeight:"Normal",
                    fontSize: '0.8em',
                    colors: ["#A3A3A3"],
                }
            }    
        },
        yaxis: {
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: true
            }
        },
        tooltip: {
            enabled: true,
            theme:false,
            custom: function({series, seriesIndex, dataPointIndex, w}) {
                
                let report = ""
                
                for (var tooltip in tooltipInfo[dataPointIndex]) {
                    report += `<li><b>${tooltip}</b> : ` + tooltipInfo[dataPointIndex][tooltip] + '</li>'
                }
                
                return  `<div class="custom-tooltip"><span class="custom-tooltip-title"><b> ${barCategories[dataPointIndex]} </b></span><div class="custom-tooltip-info">`+
                        '<b>Revenue</b> : $' + series[seriesIndex][dataPointIndex]+ 
                        '<ul>' + report + '</ul></div></div>';
              },
            onDatasetHover: {
                    highlightDataSeries: true,
                },
                x: {
                    show: true,
                },
                // // y: {
                // //     formatter: function (val) {
                // //         return "$" + val.toFixed(2);
                // //         },

                // },
                marker:{
                    show:false
                }
        },
        noData: {  
            text: "Loading...",  
            align: 'center',  
            verticalAlign: 'middle',  
            offsetX: 0,  
            offsetY: 0,  
            style: {  
              color: "#000000",  
              fontSize: '14px',  
              fontFamily: "Helvetica"  
            }  
        }
            // responsive: [
            //     {
            //       breakpoint: 768,
            //       options: {
            //         plotOptions: {
            //           bar: {
            //             horizontal: false
            //           }
            //         },
            //         legend: {
            //           position: "bottom"
            //         }
            //       }
            //     }
            // ],
            // title: {
            // text: 'Transactions breakdown by day',
            // floating: true,
            // align: 'center',
            // style: {
            //     color: '#444'
            // }
            // }
      }


    useEffect(async () => {
        props.setBreadcrumbItems('Transaction Summary', breadcrumbItems)
    }, [selectedMerchant])

    useEffect(async () => {
        dispatch(setAlert('Initializing...Please wait while we finish getting updates', 'info'))
        const key = await stripeRetrieveKey()
        if (!key) {
            dispatch(setAlert(`Error loading this page. Please report to admin immediately.`, 'danger'))
            return
        } else {
            setStripeKey(key.stripeSecret)
        }
    }, [])

    useEffect(async () => {
        await getCMSConfig()
    }, [])

    // initialize if stripe has been on-boarded before
    useEffect(async () => {
        if (!stripeKey || !selectedMerchant || !cmsConfig.cmsv2) return 
    
        setStripeChecking(true)
        let _stripeAccount = await getMerchantStripeAccount(selectedMerchant.merchant_id)
        
        if (_stripeAccount && Object.keys(_stripeAccount).length!==0) {
            setStripeAccount(_stripeAccount)
            if (_stripeAccount.status !== STRIPE_STATUS.COMPLETED) {
                await retrieveStripeLink(_stripeAccount, stripeKey)
            } else {
                await retrieveStripeLink(_stripeAccount, stripeKey)
            }
        } else {
            // first time 
            _stripeAccount = await createStripeAccountLink(stripeKey)
            setStripeAccount(_stripeAccount)
        }

        setCreateStripeButtonDisabled(false)
        setStripeChecking(false)
        dispatch(setAlert(''))
    }, [selectedMerchant, stripeKey])

    // retrieve all vouchers
    useEffect(async () => {
        try {
            if (!selectedMerchant.merchant_id) return
            
            const tempPurchasableDeals = []

            const dealIds = []

            // get purchasable deals that are valid only
            const purchasableDeals = await getSalesSummaryDealsList(selectedMerchant.merchant_id)

            for (let i=0; i < purchasableDeals.length; i++) {
                const deal = purchasableDeals[i]
                
                if (dealIds.indexOf(deal.pk) === -1) {
                    dealIds.push(deal.pk) // collect only unique deal ids
                    tempPurchasableDeals.push(deal)
                }
            }

            const massageDealVouchers = tempPurchasableDeals.map((item, index) => {
                return {
                    value: item.pk,
                    label: `${index+1} - ${item.promotion_caption}`,
                    caption: item.promotion_caption,
                    display: true,
                    valid: item.valid
                }
            })


            setDealVouchers(massageDealVouchers)
            //setSelectedDealVoucher({...massageDealVouchers[0]})
            

            let _ampTrack = {
                'empty': massageDealVouchers.length===0 ? 'Y' : 'N',
                'merchant id': selectedMerchant.merchant_id,
                'merchant name':  selectedMerchant.merchant_name,
                'mainCat': selectedMerchant.mainCat,
                'deal id': massageDealVouchers.length===0 ? '' :massageDealVouchers[0].pk,
                'promo title': massageDealVouchers.length===0 ? '' :massageDealVouchers[0].promotion_caption
            }
            dispatch(setAmplitudeEvent('CMS Sales Summary page - page load', {..._ampTrack}))
        } catch(e) {
            console.error(e)
            dispatch(setAmplitudeEvent('CMS Sales Summary page - retrieve all vouchers error', {'details': JSON.stringify(e)}))
            
        }
    }, [selectedMerchant])

    // get config from s3 in case its not retrieve or lost from the redux store
    async function getCMSConfig() {
        try {
          if (!cmsConfig || !cmsConfig.cmsv2) {
    
            const config = await getConfig()
            const env = getEnv()
            
            setRefreshUrl(cmsConfig.cmsv2.stripeVariables.refreshUrl || DEFAULT_REFRESH_URL)
            setReturnUrl(cmsConfig.cmsv2.stripeVariables.returnUrl || DEFAULT_RETURN_URL)
            dispatch(setConfig(config))
            dispatch(setEnv(env))
          } 
          setRefreshUrl(cmsConfig.cmsv2.stripeVariables.refreshUrl || DEFAULT_REFRESH_URL)
          setReturnUrl(cmsConfig.cmsv2.stripeVariables.returnUrl || DEFAULT_RETURN_URL)
        } catch(e) {
          // TODO: we might need to re-route user or ask the user to refresh
          console.error('error on retrieving config file', e)
          dispatch(setAmplitudeEvent('CMS Sales Summary page - cms getConfig error', {'details': JSON.stringify(e)} ))
        }
    }

    // get all transactions
    useEffect(async () => {
        //console.log(dealVouchers)
        let dealIds = []
        let totalSold = 0
        let totalVouchers = 0
        if (dealVouchers && dealVouchers.length > 0){



            (dealVouchers.filter(deal => {return deal.display === false}).length > 0) ? setallActiveDealsChecked(false) : setallActiveDealsChecked(true)

            dealIds = (dealVouchers.filter(deal => {return deal.display})).map(deal => deal.value)      
            await Promise.all(dealIds.map(async (deal) => {
                const allVoucherCodes = await listVouchersByDealId(deal)
                const {sold, total} = computeSoldVouchers(allVoucherCodes)
                totalSold += sold
                totalVouchers += total
              }));
            let fullTransactions = await getAllTransactionsByDealIds(dealIds, {status: {eq: "0"}})
            fullTransactions.sort((a,b) => b.timestamp - a.timestamp)

            setTransactionList(fullTransactions)
            setInitTransactionsList(fullTransactions)
            setTotalVouchers(totalVouchers)
            setSoldVouchers(`${totalSold}/${totalVouchers}`)
            setSaleDateFrom("")
            setSaleDateTo("")

        }
    },[dealVouchers])

    // setup display data based on transactions
    useEffect(async () => {
        let count = 1
        let dataRows = []
        let revenue = 0
        let totalDiscountValue = 0
        let defaultSeriesData = []
        let defaultCategories = []
        let tooltipData = []
        let filteredTotalSold = 0

        if(transactionList.length > 0){
            let sDateFrom = saleDateFrom ?  new Date(saleDateFrom) : null
            let sDateTo = saleDateTo ? new Date(saleDateTo) : null
            let dateInput = new Date(transactionList.at(-1).timestamp * 1000) //set the last transaction input date
            dateInput = sDateFrom ?? dateInput
            let endDate = sDateTo ? sDateFrom ? sDateTo : sDateTo.setDate(sDateTo.getDate()+1) : today
            while(dateInput <= endDate){
                defaultCategories.push(format(dateInput , "dd MMM yy"))
                defaultSeriesData.push(0)
                dateInput.setDate(dateInput.getDate()+1)
            }
        }
 
        

        await Promise.all(transactionList.map(async (transaction) => {
            try {

                // get total revenue
                revenue = transaction.purchasePrice ? Decimal(revenue).plus(Decimal(transaction.purchasePrice) * Number(transaction.quantity)) : revenue
                totalDiscountValue = transaction.discountCode ? Decimal(totalDiscountValue).plus(Decimal(transaction.extraDiscount)) : totalDiscountValue
                filteredTotalSold += Number(transaction.quantity) ?? 0
                let transactionDate = format(fromUnixTime(Number(transaction.timestamp)) , "dd MMM yy")
                // if (!(transactionDate in barData)){
                //     barData[transactionDate] = Decimal(transaction.purchasePriceTotal)
                // }else{
                //     barData[transactionDate] = transaction.purchasePriceTotal ? Decimal(barData[transactionDate]).plus(Decimal(transaction.purchasePriceTotal)) : barData[transactionDate]
                // }
                if(defaultCategories.includes(transactionDate)){
                    let index = defaultCategories.indexOf(transactionDate)
                    defaultSeriesData[index] = transaction.purchasePrice ? Decimal(defaultSeriesData[index]).plus(Decimal(transaction.purchasePrice) * Number(transaction.quantity)).toNumber() : defaultSeriesData[index]
                    let dealTitle = (dealVouchers.filter(deal => {return deal.value === transaction.dealId}))[0]['caption']
                    if(tooltipData[index] && (dealTitle in tooltipData[index]))
                        tooltipData[index][dealTitle] += transaction.quantity  
                    else if(tooltipData[index] && !(dealTitle in tooltipData[index]))
                        tooltipData[index][dealTitle] = transaction.quantity  
                    else
                        tooltipData[index] = { [dealTitle] : transaction.quantity}
                }
                const purchaser = ""
                dataRows.push({
                    id: count++,
                    transactionId: transaction.id ?? "-",
                    transactionDateTime: format(fromUnixTime(Number(transaction.timestamp)) , "dd/MM/yyyy, HH:mm:ss"),
                    purchaserEmail: emailRegex.test(transaction.userId) ? transaction.userId : '-',
                    dealTitle: (dealVouchers.filter(deal => {return deal.value === transaction.dealId}))[0]['caption'] ?? '-',
                    purchasePrice: `$${transaction.purchasePrice.toFixed(2) ?? 0.00}`,
                    qty: transaction.quantity ?? '-',
                    discountCode: transaction.discountCode ?? '-',
                    discountCodeValue: transaction.discountCode ? "$"+transaction.extraDiscount.toFixed(2) : '-',
                    purchasePriceTotal: `$${transaction.purchasePriceTotal.toFixed(2) ?? 0.00}`,
                    utmSourceMedium: (transaction.utm_source && transaction.utm_source.trim() !== '' ? transaction.utm_source : '-') + '/' + (transaction.utm_medium && transaction.utm_medium.trim() !== '' ? transaction.utm_medium : '-'),
                    utmCampaign: (transaction.utm_campaign && transaction.utm_campaign.trim() !== '' ? transaction.utm_campaign : '-')
                })
            } catch (error) {
              console.log('error'+ error);
            }
          }))    
                
        // for (let data in barData) {
        //      defaultSeriesData.unshift(barData[data].toNumber());
        //      defaultCategories.unshift(data);
        // }
      
        let series = barSeries
        series[0]['data'] = defaultSeriesData
        transactionData.rows = dataRows
        setTooltipInfo(tooltipData)
        setBarSeries(series)
        setBarCategories(defaultCategories)
        setDataTable({...transactionData})
        //if (!(saleDateFrom || saleDateTo)) setMainDataTable({...transactionData})
        setTotalRevenue(`SGD ${revenue.toFixed(2)}`)
        setTotalDiscount(`SGD ${totalDiscountValue.toFixed(2)}`)
        setSoldVouchers(`${filteredTotalSold}/${totalVouchers}`)
    },[transactionList])   

    useEffect( () => {

        let sDateFrom = saleDateFrom ?  new Date(saleDateFrom) : null
        let sDateTo = saleDateTo ? new Date(saleDateTo) : null

        if(sDateFrom || sDateTo){
            setDataLoading(true)
            let filteredTransactions = initTransactionsList

            filteredTransactions = filteredTransactions.filter((e) => {
                if(e.timestamp){

                    let formattedDate = format(fromUnixTime(Number(e.timestamp)), "yyyy-MM-dd")

                    let tDate = new Date(formattedDate)
                    if (sDateFrom && sDateTo)
                        return (tDate >= sDateFrom) && (tDate <= sDateTo)
                    else if(sDateFrom)
                        return tDate >= sDateFrom
                    else if(sDateTo)
                        return tDate <= sDateTo
                }
            })
            setTransactionList(filteredTransactions)

        }else{
            setDataLoading(true)
            setTransactionList(initTransactionsList)
        }
    },[saleDateFrom, saleDateTo])



    useEffect(() => {
        setDataLoading(false)
    },[dataTable])
    
    const dealsFilterToggle = (index) =>{
        const deals = [...dealVouchers]
        deals[index]['display'] = !deals[index]['display']
        setDealVouchers(deals)
    }

    const allActiveDealsToggle = () =>{
        let deals = [...dealVouchers]
        deals.forEach(deal =>{
            deal['valid'] === 'Y' ? deal['display'] = true : deal['display'] = false
        })
        setDealVouchers(deals)
    }

    const createDropDown = () => {
        return (
          <DropdownMenu className="dropdown-menu">
            <DropdownItem key="allDeals" toggle={false}><Form.Check
                                    name="allDeals"
                                    type="checkbox"
                                    checked={false}
                                    disabled={false}
                                    onChange={()=> allActiveDealsToggle()}
                                    label={<div style={{ color: "#272833" }}>All Active Deals</div>}
                                    /></DropdownItem>
            {dealVouchers && dealVouchers.length > 0 &&
                dealVouchers.map((deal,index) =>{
                    return  <DropdownItem key={"deal"+index} toggle={false}>
                                <Form.Check
                                    name={deal.label}
                                    type="checkbox"
                                    checked={deal.display || (deal.valid === 'Y' ? allActiveDealsChecked : false)}
                                    onChange={()=> dealsFilterToggle(index)}
                                    label={<div style={{ color: "#272833" }}>{deal.caption}</div>}
                                />
                            </DropdownItem>
                })
            }

          </DropdownMenu>
        )
    }



    async function retrieveStripeLink(_stripeAccount, stripeKey) {
        try {
            const stripeInfo = await stripeRetrieveAccount({ stripeKey, accountId: _stripeAccount.accountId })
            let status
            let link
            let btnLabel
        
            if (!stripeInfo.charges_enabled) {
                
                status = _stripeAccount.status
                const stripeInfo = await stripeCreateOnboardingLink({ email: userInfo.email, 
                    stripeKey, 
                    refreshUrl, 
                    returnUrl, 
                    merchantId: selectedMerchant.merchant_id, 
                    accountId: _stripeAccount.accountId 
                })
                link = stripeInfo.accountLink.url
                setStripeButtonLabel(STRIPE_BUTTON_LABEL.CONNECT_TO_STRIPE)
                setNeedPrompt(true)
            } else {
                ({status, btnLabel} = await evaluateStripeStatus(stripeInfo))
                
                const stripeLoginLink = await stripeCreateLoginLink({ stripeKey, accountId: _stripeAccount.accountId })
                link = stripeLoginLink.url
                setStripeButtonLabel(btnLabel)
                setNeedPrompt(false)
            }
            setStripeAccountStatus(status)
            setStripeLink(link)
        
            // update user stripe db record
            await updateStripeAccount({
                accountId: _stripeAccount.accountId,
                merchantId: selectedMerchant.merchant_id,
                email: userInfo.email,
                type: 'on-boarding',
                status
            })
        } catch(e) {
            console.error('error on retrieveStripeLink', e)
            dispatch(setAlert(`${GENERIC_ERROR_MESSAGE}[Stripe link retrieval issue]`, 'danger'))
            dispatch(setAmplitudeEvent('CMS Sales Summary page - retrieve stripe link error', {'details': JSON.stringify(e)}))
        }
    }
    
    async function createStripeAccountLink(stripeKey) {
        // first time to on-board to stripe
        
        const stripeInfo = await stripeCreateOnboardingLink({ email: userInfo.email, stripeKey, refreshUrl, returnUrl, merchantId: selectedMerchant.merchant_id })

        setStripeAccountStatus(STRIPE_STATUS.NOT_ONBOARDED)
        setStripeLink(stripeInfo.accountLink.url)
        setStripeButtonLabel(STRIPE_BUTTON_LABEL.CONNECT_TO_STRIPE)
        setNeedPrompt(true)

        // create a user stripe record in db
        const stripeAccount = await createStripeAccount({
            accountId: stripeInfo.account.id,
            merchantId: selectedMerchant.merchant_id,
            email: userInfo.email,
            type: 'on-boarding',
            status: STRIPE_STATUS.NOT_ONBOARDED
        })
        return stripeAccount
    }

    function openStripeLink() {
        if (needPrompt) {
            setModalStripePromptStatus(true)
        } else {
            setCreateStripeButtonDisabled(true)
            openInNewTab(stripeLink)
        }
    }

    function closeForm(state) {
        setModalStripePromptStatus(state)
    }

    function handleDealVoucherChange(v) {
        setSelectedDealVoucher({...v})
    }

    const downloadVouchersTable = () => {
         if (dataTable.rows.length>0) {

            let arr = []
            dataTable.rows.map((e)=>{
                let item = {'ID': e.id, 
                            'Transaction ID': e.transactionId,
                            'Transaction date and time': e.transactionDateTime,
                            'Purchaser Email': e.purchaserEmail,
                            'Deal Title': e.dealTitle,
                            'Purchase Price': e.purchasePrice,
                            'Quantity': e.qty,
                            'Discount Code': e.discountCode,
                            'Discount Code Value': e.discountCodeValue,
                            'Purchase Price Total': e.purchasePriceTotal}
                arr.push(item)
            })
            const csvTitle = "Transaction Summary" +    (saleDateFrom ? saleDateTo ? ` (From ${saleDateFrom} to ${saleDateTo})` 
                                                        : ` (From ${saleDateFrom})` 
                                                        : saleDateTo ? ` (Till ${saleDateTo})` 
                                                        : "")
             convertToCSV(arr, csvTitle)
             setDownloadingCSV(false)
        }   
    }

    const totalRevenueObj = { title: "Total Revenue", iconClass: "cellphone-sound", total: totalRevenue, bgColor: "#7A6FBE", buttonLabel: "View voucher details", link: '/sell-vouchers' }
    const soldVouchersObj = { title: "Number of vouchers sold", iconClass: "ticket-confirmation", total: soldVouchers, bgColor: "#0DCAF0", buttonLabel: "View voucher details", link: '/sell-vouchers' }
    // const redeemVouchersObj = { title: "Vouchers redeemed", iconClass: "pencil", total: redeemVouchers, bgColor: "#F5B225", buttonLabel: "View voucher details", link: '/sell-vouchers' }
    // const checkinsObj = { title: "Check-Ins", iconClass: "pencil", total: checkins, bgColor: "#46b521", buttonLabel: "View voucher details", link: '/sell-vouchers' }
    const discountCodeObj = { title: "Discount Code Value", iconClass: "cellphone-sound", total: totalDiscount, bgColor: "#DA5E6E", buttonLabel: "View voucher details", link: '/sell-vouchers' }
    const others = {"deal id": selectedDealVoucher.pk, "promotion title": selectedDealVoucher.promotion_caption}

    return (

        <React.Fragment >
            <RBAC merchantId={selectedMerchant.merchant_id} roles={['admin']}>
            <MetaTags>
                <title>Transaction Summary</title>
            </MetaTags>
            
            
            <Row className="sale-summary-card justify-content-center" >
            { stripeChecking ? 
                <div className="text-center" >
                  <Spinner/> 
                </div>
              : stripeAccount.status !== STRIPE_STATUS.COMPLETED ?
                <Row className='justify-content-center' >
                    <Col xs={12} sm={12} md={6} lg={5} xl={5}>
                        <Card>
                            <CardImg
                                src={sellVoucherStepImg}
                                top
                                width="100%"
                                style={{ objectFit:'contain', marginTop:'20px' }}
                            />
                        </Card>
                        <StripeOnboarding 
                            stripeStatus={stripeAccountStatus}
                            statusColor={getStripeStatusColor(stripeAccountStatus)}
                            disabled={createStripeButtonDisabled}
                            buttonLabel={stripeButtonLabel}
                            onClick={() => openStripeLink()}
                            outline = {[STRIPE_STATUS.PENDING_VERIFICATION, STRIPE_STATUS.COMPLETED].includes(stripeAccountStatus)?true:false}
                            cta="sales summary"
                        />
                    </Col>
                </Row>
              : dealVouchers && dealVouchers.length===0 ?
                <Row className='justify-content-center'>
                    <Col xs={12} sm={12} md={6} lg={5} xl={5}>
                        {/* <Button onClick={() => openStripeLink()} color="primary" className="open-stripe-dashboard">Open Stripe Dashboard</Button> */}
                        <EmptyState history={props.history}/>  
                    </Col>
                </Row>
              :
                <>
                    <Row className="mb-2" style={{display:"flex",alignItems:"flex-end", justifyContent:"flex-end"}}>
                        <Col xs={12} sm={12} md={2}  xl={1}>
                        <Row>
                        <Dropdown
                        isOpen={toggleFilter}
                        toggle={() => setToggleFilter(!toggleFilter)}
                        className="d-inline-block"
                        >
                        <div className="dropdown dropdown-topbar pt-3 mt-1 d-inline-block">
                            <DropdownToggle
                            className="btn btn-light"
                            tag="button"
                            >
                            <i className="mdi mdi-filter-variant"></i> Filter 
                            </DropdownToggle>
                            {createDropDown()}

                        </div>
                        </Dropdown>
                        </Row>
                
                        </Col>

                        <Col xs={12} sm={12} md={6} className="mt-2" style ={{display:"flex", alignItems:"center"}}>
                                <div className="mx-2">
                                    Selling Period
                                </div>
                                <div >
                                    <InputGroup>
                                        <Form.Control
                                            style={{borderWidth: 1, maxWidth:"50%" }}
                                            type="date"
                                            name="saleFrom"
                                            value={saleDateFrom}
                                            onChange={(e) => setSaleDateFrom(e.target.value)}
                                        />
                                        <Form.Control
                                            style={{ borderWidth: 1, maxWidth:"50%" }}
                                            type="date"
                                            name="saleTo"
                                            value={saleDateTo}
                                            onChange={(e) => setSaleDateTo(e.target.value)}
                                    />                                         
                                    </InputGroup>
                                </div>
                       
                        </Col>
                        <Col  className="open-stripe-dashboard-2 mt-2">
                                <Button onClick={() => openStripeLink()} color="primary">Open Stripe Dashboard</Button>
                        </Col>
                    </Row>

                    <Card style={{padding: 15}}>   
                        <CardBody>
                                    <Chart
                                    options={options}
                                    series={barSeries}
                                    type="bar"
                                    height="200%"
                                    width="100%"          
                                    />
                        </CardBody>
                        </Card>

                    
                    <Card style={{padding: 15, paddingBottom:0}}>
                        <Row>
                            <Col xs={12} sm={12} md={3}>
                                <BoxWidget tooltip={"Total Revenue = No. of tickets sold x ticket price. Note: This amount does not exclude discount codes amount, commissions and stripe fees yet. "} hideButton={true} cta="total revenue" screen="Sales Summary" others={others} history={props.history} details={totalRevenueObj}/>
                            </Col>
                            <Col xs={12} sm={12} md={3}>
                                <BoxWidget hideButton={true} cta="number of voucher sold" screen="Sales Summary" history={props.history} details={soldVouchersObj}/>
                            </Col>
                            <Col xs={12} sm={12} md={3}>
                                <BoxWidget hideButton={true} cta="discount code value" screen="Sales Summary" others={others} history={props.history} details={discountCodeObj}/>
                            </Col>
                        </Row>
                    </Card>
                </>
            }
            </Row>
            { stripeChecking ? 
               null
                : (dealVouchers && dealVouchers.length >0) && (stripeAccount.status === STRIPE_STATUS.COMPLETED)?
                dataLoading ? 
                    <div className="text-center" >
                        <Spinner/> 
                    </div>
                :
                <Row>
                    <Card>
                        <CardBody>
                            <Row style={{ display: "flex", justifyContent: "flex-end", top: "21px", position:"relative", zIndex:"99"}}>
                                
                                {/* <Col style={{display:"flex",alignSelf:"flex-end", justifyContent:"flex-start"}}>
                                    <Button color="primary" type="button" size="sm" onClick={()=>downloadVouchersTable()}><BsFilter/> Filter</Button>
                                </Col> */}
                                
                                <Col style={{display:"flex",alignSelf:"flex-end", justifyContent:"flex-end"}}>
                                    <div>
                                    <Row>
                                        <Col style={{ display:"flex",justifyContent: "flex-end", top:"0.5vh"}}>
                                            <Button color="primary" type="button" size="sm" style={{fontSize:"0.65rem"}}  onClick={()=>downloadVouchersTable()}>Export as CSV Document</Button>
                                        </Col>
                                    </Row>
                                    </div>
                                </Col>
                            </Row>
                            <div ref={dataContainer}>
                            <MDBDataTable  className="transactionsSummary" entriesOptions={[10, 25, 50, 100]} entries={10}  displayEntries={true} hover pagesAmount={10} fixed={true} scrollX data={{ columns: dataTable.columns, rows: dataTable.rows }} noBottomColumns={true}/>
                            {downloadingCSV &&
                                <div  >
                                <Spinner/>
                                </div>
                            }
                            </div>
                        </CardBody>
                    </Card>
                </Row>
                : null
            }
            
            {modalStripePromptStatus ? <StripePrompt stripeLink={stripeLink} modalStripePromptStatus={modalStripePromptStatus} close={(item, type) => closeForm(item, type)} /> : null}
            </RBAC>

        </React.Fragment>
    )

}

export default connect(null, { setBreadcrumbItems })(TransactionSummary);