import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { Form, InputGroup, Image } from 'react-bootstrap';
import { Row, Col, CardBody, Spinner , FormGroup,Input, Label} from "reactstrap"
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import styled from 'styled-components';
import * as Yup from 'yup';
import {
    SubTitle, Underline, SpinnerDiv, CardContainer, ErrMsg, PriceLabel, boxStyling, boxStylingNoPadding, Caption, SubmitButton, SubText
} from './Styled';
import { verifyDiscountCode, reserveDiscountCode, releaseDiscountCode } from 'helpers/apiHelper'
import { AiOutlinePlusCircle, AiOutlineMinusCircle, AiFillCloseCircle } from 'react-icons/ai';
import { HiTicket } from 'react-icons/hi';
import { TIME_IS_UP, NO_SLOT, STOP_TIMER, START_TIMER, RESET_TIMER, PROCESSING_ERROR, RESERVE_ERROR } from 'utils/constants';
import WarningPopup from './WarningPopup';
import Payment, { CardForm } from './Payment';

import { Elements} from '@stripe/react-stripe-js';
import cardsImg from "../../../assets/images/cards.png"
import paynowImg from "../../../assets/images/PayNow.png"
import applepayImg from "../../../assets/images/ApplePay.png"
import googlepayImg from "../../../assets/images/GooglePay.png"


const ContactInfo = (props) => {

    const schema = Yup.object().shape({
        promoCode: Yup.string()
    })

    const initialValues = {
        promoCode: props.prefill?.promoCode ?? ''
    }

    const idempotencyKey =props.idempotencyKey
    const [state] = useState(initialValues);
    const [isLoading, setIsLoading] = useState(false)
    const [paymentType, setPaymentType] = useState({})
    const [paymentMethod , setPaymentMethod] = useState("")
    const [paymentMethodClicked , setPaymentMethodClicked] = useState(0)
    const [errorOccur, setErrorOccur] = useState(false)


    
    useEffect(()=>{

        (paymentType?.applePay === true || paymentType?.googlePay === true) && (props.paymentPrice > 0)? setPaymentMethod("wallet") : setPaymentMethod("card")
        props.step === 'payment' ? window.scrollTo(0, (window.innerHeight *30/100)) : null
    }, [paymentType])

    useEffect(()=>{
        props.step === 'payment' ? window.scrollTo(0, (window.innerHeight *30/100)) : null
    }, [props.step])

    useEffect(()=>{
        (props.paymentPrice <= 0) ? setPaymentMethod("FREE") : (paymentType?.applePay === true || paymentType?.googlePay === true) ? setPaymentMethod("wallet") : setPaymentMethod("card")
    }, [props.paymentPrice])

    useEffect(()=>{
        if(errorOccur){
            props.warningPopup({ show: true, type: PROCESSING_ERROR })
            setPaymentMethodClicked(0)
            setErrorOccur(false)
        }
    },[errorOccur])

    const { register, getValues, setValue, setError, clearErrors, formState: { errors } } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: state,
        resolver: yupResolver(schema)
    });

    const redirect = (URL) => {
        window.location.href = URL
    }
    const decreaseQty = () => {
        let { qty, dealInfo } = props

        if (qty > dealInfo?.data?.minQtyTx) {
            let newQty = qty - 1
            let newTotalPrice = dealInfo.data.purchasePrice * newQty
            let newPaymentPrice = newTotalPrice
            props.setQty(newQty)
            clearCode()
            props.setTotalPrice(newTotalPrice.toFixed(2))
            props.setPaymentPrice(newPaymentPrice.toFixed(2))

        }
    }
    const increaseQty = () => {
        let { qty, dealInfo } = props

        if (qty < dealInfo?.data?.purchaseLimitPerCustomer) {
            let newQty = qty + 1
            let newTotalPrice = dealInfo.data.purchasePrice * newQty
            let newPaymentPrice = newTotalPrice
            props.setQty(newQty)
            clearCode()
            props.setTotalPrice(newTotalPrice.toFixed(2))
            props.setPaymentPrice(newPaymentPrice.toFixed(2))
        }
    }

    const onChangePaymentMethod = (e) =>{
        setPaymentMethod(e.target.value);
    }

    const verifyCode = async () => {
        let { contactInfo, qty, setDiscountCampaign, setDiscountCodeReserved } = props
        setIsLoading(true)
        let code = getValues("promoCode")
        let data = {
            "dealId": props.dealId,
            "userId": contactInfo.userId,
            "code": code,
            "totalSpend": props.totalPrice,
            "qty": qty
        }

        try {
            let response = await verifyDiscountCode(data)
            if (response.data) {
                const error = response.data.error;
                if (!error && response.data.discountCampaign) {
                    const discountCampaign = response.data.discountCampaign;
                    let obj = { userId: contactInfo.userId, dealId: props.dealId, campaignId: discountCampaign.campaignId, qty: 1 }
                    let reserveResponse = await reserveDiscountCode(obj)
                    const newDiscountCodes = reserveResponse?.data ? JSON.parse(reserveResponse?.data) : null
                    setDiscountCodeReserved(newDiscountCodes?.codes[0])
                    setDiscountCampaign(discountCampaign)
                    calculateAmountPayable(discountCampaign)
                    setIsLoading(false)
                } else {
                    setError('promoCode', { type: 'custom', message: error });
                    setIsLoading(false)
                }
            }
        } catch (err) {
            console.error('Error _onVerifyCodeClicked ', err);
            setError('promoCode', { type: 'custom', message: 'Error getting discount code' });
            setIsLoading(false)
        }
        setValue('promoCode', code);
    }

    const clearCode = async () => {
        let { contactInfo, discountCampaign, setDiscountCampaign, setDiscountCodeReserved, discountCodeReserved } = props
        setDiscountCampaign(null);
        setDiscountCodeReserved(null)
        calculateAmountPayable(null)
        setValue('promoCode', '');
        clearErrors('promoCode');
        if (discountCampaign) {
            let obj = { userId: contactInfo.userId, campaignId: discountCampaign.campaignId, discountCodes: discountCodeReserved.toString() }
            let res = await releaseDiscountCode(obj);
        }

    }

    const calculateAmountPayable = (discountCampaign) => {
        let totalAmount = props.totalPrice;
        let _discountAmount = Number(discountCampaign?.discountAmountComputed) || 0
        totalAmount = totalAmount - _discountAmount;
        props.setDiscountAmt(_discountAmount.toFixed(2))
        props.setPaymentPrice(totalAmount.toFixed(2))
    }
    return (
        <React.Fragment>
            <Row className='justify-content-center'>
                <Col>
                    <Form onChange={(e) => props.onChange(e)} onSubmit={e => { e.preventDefault() }}>
                        <fieldset disabled={props.isDisabled}>
                            <CardContainer style={props.styling ?? { backgroundColor: "#fff" }}>
                                {props.step === "payment" ?
                                    <div style={{ margin: 10 }}>
                                        <SubTitle className='m-1'>{props.eventInfo?.name + ": " + props.dealInfo?.data?.promotion_caption}</SubTitle>
                                        <Form.Label style={{ marginInline: 5 }}>{props.dealInfo?.data?.display_merchant_name}</Form.Label>
                                    </div>
                                    : null}
                                <CardBody>
                                    {props.type === 'bundle' ? null :
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Form.Label>Quantity</Form.Label>
                                                {!props.isQtyFixed && props.type === 'single' ? 
                                                    <div style={{ position: "absolute" }}>
                                                        <div style={{ color: "#6B6C7E", fontSize: 10 }}> {"Min. " + (props.dealInfo?.data?.minQtyTx ? props.dealInfo?.data?.minQtyTx : 1) + " and max. " + props.dealInfo?.data?.purchaseLimitPerCustomer + " purchase(s)"} </div>
                                                    </div> : null
                                                }
                                            </Col>
                                            {props.step === 'payment' ?
                                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <PriceLabel>{props.qty}</PriceLabel>
                                                </Col>
                                                :
                                                (props.isQtyFixed ?
                                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                                            <Form.Label style={{ backgroundColor: "#F1F2F5", borderRadius: 8, paddingInline: 32, paddingBlock: 5, marginTop: 5 }}>{props.qty}</Form.Label>
                                                        </Col>
                                                    :
                                                    <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                                        <MinusIcon onClick={() => decreaseQty()} />
                                                        <Form.Label style={{ backgroundColor: "#F1F2F5", borderRadius: 8, paddingInline: 12, paddingBlock: 5, marginTop: 5 }}>{props.qty}</Form.Label>
                                                        <PlusIcon onClick={() => increaseQty()} />
                                                    </Col>
                                                )
                                            }
                                        </Row>
                                    }
                                    {props.type === 'bundle' ? null : <Underline className='mb-3' />}
                                    <Row>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <Form.Label>Total Price</Form.Label>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <PriceLabel>{"S$" + props.totalPrice}</PriceLabel>
                                        </Col>
                                    </Row>
                                    <Underline className='mb-3' />
                                    {props.step === "payment" ?
                                        props.type === 'free' ? null :
                                        <Row>
                                            {props.type === 'vip' ?
                                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <Form.Label>Promo Code*</Form.Label>
                                                    <div style={{ marginBottom: 5 }}>
                                                        <div style={{ color: "#6B6C7E", fontSize: 10 }}> {'Enter "Promo Code" to complete purchase.'} </div>
                                                    </div>
                                                </Col>
                                                :
                                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <Form.Label>Promo Code</Form.Label>
                                                </Col>}

                                            <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginBottom: errors.promoCode ? 10 : 0 }}>
                                                <div>
                                                    <InputGroup style={{ maxWidth: 200 }}>
                                                        <InputGroup.Text style={{ backgroundColor: "#F4F5F6", borderWidth: 0 }}>
                                                            <CouponIcon />
                                                        </InputGroup.Text>
                                                        {props.discountCampaign?.code ?
                                                            <Form.Control
                                                                style={{ color: "#2AD2C9", backgroundColor: "#F4F5F6", borderWidth: 0 }}
                                                                type="text"
                                                                disabled={true}
                                                                value={props.discountCampaign?.code}
                                                            /> :
                                                            <Form.Control
                                                                style={{ backgroundColor: "#F4F5F6", borderWidth: 0,fontSize:"14px" }}
                                                                type="text"
                                                                name="promoCode"
                                                                placeholder="Enter Promo Code"
                                                                className={errors.promoCode && "error"}
                                                                {...register("promoCode")}
                                                            />
                                                        }
                                                        {getValues("promoCode").length > 0 || props.discountCampaign?.code ?
                                                            <InputGroup.Text style={{ backgroundColor: "#F4F5F6", borderWidth: 0 }} onClick={() => clearCode()}>
                                                                <CrossIcon />
                                                            </InputGroup.Text>
                                                            : null}
                                                    </InputGroup>
                                                    <div style={{ position: "absolute", marginTop: 5 }}>
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="promoCode"
                                                            render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                                        />
                                                    </div>
                                                </div>
                                                {props.discountCampaign?.code ?
                                                    <PriceLabel style={{ marginBottom: 0, marginLeft: 10 }}>{"-S$" + props.discountAmt}</PriceLabel>
                                                    :
                                                    <Form.Label onClick={() => props.isDisabled || getValues("promoCode").trim().length < 1 ? null : verifyCode()} style={{ backgroundColor: "#2AD2C9", color: "#fff", borderRadius: 6, paddingInline: 20, paddingBlock: 5, marginInline: 10, marginTop: 5 }}>Apply</Form.Label>
                                                }
                                            </Col>
                                            <Underline className='mb-3' />
                                        </Row> : null}
                                    <Row>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <Form.Label>Amount payable</Form.Label>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <PriceLabel style={{ color: "#722ED1", fontSize: 16 }}>{"S$" + props.paymentPrice}</PriceLabel>
                                        </Col>
                                    </Row>
                                </CardBody>
                                {isLoading &&
                                    <SpinnerDiv>
                                        <Spinner />
                                    </SpinnerDiv>}
                            </CardContainer>
                            {props.step === "payment" &&
                            <>
                            <Elements stripe={props.stripePromise} >
                            <CardContainer style={{backgroundColor:"#fff"}}>
                              
                                {props.paymentPrice > 0 ?
                                <div style={{...boxStyling,display:"flex", flexDirection:"column", width:"100%"}}>
                                    <div style={{alignSelf:"center", width:"100%"}}>
                                        <SubTitle className='m-1'>Payment Method</SubTitle>
                                        <Underline/>
                                        {(paymentType?.applePay === true || paymentType?.googlePay === true)  && 
                                            <>
                                                <FormGroup check>
                                                <Label check style={{width:"100%"}}>
                                                    <Input type="radio" name="paymentMethod" value="wallet" onChange={onChangePaymentMethod} checked ={paymentMethod === "wallet"}  />
                                                    <Row>
                                                        <Col xs="9" sm="10" md="11">
                                                            <Caption>{paymentType?.applePay === true ? "Apple Pay" : paymentType?.googlePay === true ? "Google Pay" : "" }</Caption>
                                                        </Col>
                                                        <Col>
                                                        < Image fluid src={paymentType?.applePay === true ? applepayImg : paymentType?.googlePay === true ? googlepayImg : null} style={{alignSelf:"flex-end"}} />
                                                            
                                                        </Col>
                                                        
                                                    </Row>
                                                    
                                                </Label>
                                                </FormGroup>
                                                <Underline/>
                                            </>
                                        }
                                        <FormGroup check>
                                        <Label check style={{width:"100%"}}>
                                            <Input type="radio" name="paymentMethod" value="paynow" onChange={onChangePaymentMethod} checked ={paymentMethod === "paynow"}  /> 
                                            <Row>
                                                <Col xs="9" sm="10" md="11">
                                                <Caption>PayNow</Caption>
                                                <SubText>It is recommended to disable pop-up blocker for this page.</SubText>
                                                </Col>
                                                <Col>
                                                    < Image fluid src={paynowImg} style={{alignSelf:"flex-end"}} />
                                                    
                                                </Col>
                                            </Row>
                                        </Label>
                                        </FormGroup>
                                        <Underline/>
                                        <FormGroup check>
                                        <Label check style={{width:"100%"}}>
                                            <Input type="radio" name="paymentMethod" value="card" onChange={onChangePaymentMethod} checked ={paymentMethod === "card"}  /> 
                                            <Row>
                                                <Col xs="6" sm="8">
                                                <Caption>Credit/Debit Card</Caption>
                                                </Col>
                                                <Col>
                                                    < Image fluid src={cardsImg} style={{alignSelf:"flex-end"}} />
                                                    
                                                </Col>
                                            </Row>
                                        </Label>
                                        </FormGroup>
                                        <Underline/>
                                    </div>
                                    
                                    <div style={{backgroundColor:"#F1F2F5", borderRadius:"10px", alignSelf:"center",justifyContent:"center",width:"100%", height:"40%", display:paymentMethod!=="card" ? "none" : "flex"}}>
                                        <CardForm 
                                            paymentMethodClicked={paymentMethodClicked}
                                            userId={props.userId}
                                            vouchers={props.reserveVouchers}
                                            data={props}
                                            webFormId={props.webFormId}
                                            hasAccount={props.hasAccount}
                                            idempotencyKey={idempotencyKey}
                                            paymentMethod={paymentMethod}
                                            goToPaymentSuccessful={(transactionId) => props.goToPaymentSuccessful(transactionId)}
                                            setIsLoading={(e)=>setIsLoading(e)}
                                            setErrorOccur = {(e)=>setErrorOccur(e)}
                                            /> 
                                    </div>

                                </div>
                                :
                                <div style={{alignSelf:"center",justifyContent:"center",width:"100%", height:"40%", display:"none"}}>
                                    <CardForm 
                                        paymentMethodClicked={paymentMethodClicked}
                                        userId={props.userId}
                                        vouchers={props.reserveVouchers}
                                        data={props}
                                        paymentMethod={'FREE'}
                                        webFormId={props.webFormId}
                                        hasAccount={props.hasAccount}
                                        idempotencyKey={idempotencyKey}
                                        goToPaymentSuccessful={(transactionId) => props.goToPaymentSuccessful(transactionId)}
                                        setIsLoading={(e)=>setIsLoading(e)}
                                        setErrorOccur={(e) => setErrorOccur(e)}
                                        /> 
                                </div>
                                }

                                

                            </CardContainer>


                            <div style={{ display:paymentMethod ==="wallet" ? "flex" : "none", flexDirection: "column", alignItems: "center", marginTop:"30px",backgroundColor: "transparent"}}>
                                <div style={{ textAlign: "center", marginBottom:"3%" }}>By clicking this button, you accept the <a target="_blank" rel='noreferrer' href="https://stripe.com/en-sg/legal"> Stripe Services Agreement</a>, <a href='https://cardspal.com/ticket-sales-terms-and-conditions/' target='_blank' rel='noreferrer'>CardsPal Terms of Use</a> and <a href='https://cardspal.com/privacy-policy/' target='_blank' rel='noreferrer'>CardsPal Privacy Policy</a>.</div>
                                <Payment
                                    getPaymentType={(e)=>setPaymentType(e)}
                                    userId={props.userId}
                                    vouchers={props.reserveVouchers}
                                    data={props}
                                    webFormId={props.webFormId}
                                    hasAccount={props.hasAccount}
                                    paymentMethod={paymentMethod}
                                    idempotencyKey={idempotencyKey}
                                    goToPaymentSuccessful={(transactionId) => props.goToPaymentSuccessful(transactionId)}
                                    setErrorOccur={(e) => setErrorOccur(e)}
                                    voucherGroup={props.voucherGroup}
                                /> 
                            </div>

                            <div style={{display: (paymentMethod === "card" || paymentMethod === "paynow" || paymentMethod === "FREE") ? "flex" : "none", flexDirection: "column", alignItems: "center",backgroundColor: "transparent"}}>
                                <div style={{textAlign: "center", marginBottom:"3%" }}>By clicking this button, you accept the <a target="_blank" rel='noreferrer' href="https://stripe.com/en-sg/legal"> Stripe Services Agreement</a>, <a href='https://cardspal.com/ticket-sales-terms-and-conditions/' target='_blank' rel='noreferrer'>CardsPal Terms of Use</a> and <a href='https://cardspal.com/privacy-policy/' target='_blank' rel='noreferrer'>CardsPal Privacy Policy</a>.</div>
                                <SubmitButton style={{ marginTop: 20 }}
                                    title="Please provide a valid promo code before continue to payment."
                                    onClick={() => setPaymentMethodClicked((paymentMethodClicked)=> paymentMethodClicked+1)}
                                    type="button"
                                    className="btn btn-primary btn-block"
                                    disabled={isLoading || paymentMethodClicked>0}
                                >Confirm Purchase
                                </SubmitButton>
                            </div>   

                            {isLoading &&
                                <SpinnerDiv>
                                    <Spinner />
                                </SpinnerDiv>}

                        </Elements>
                        </>
                        }

                        </fieldset>
                    </Form>
                </Col>
            </Row>
        </React.Fragment >

    )
}

const PlusIcon = styled(AiOutlinePlusCircle)`
  color: #722ED1;
  margin-inline: 10px;
  font-size: 18px;
`
const MinusIcon = styled(AiOutlineMinusCircle)`
  color: #722ED1;
  margin-inline: 10px;
  font-size: 18px;
`
const CouponIcon = styled(HiTicket)`
  color: #D3ADF7;
  font-size: 18px;
`
const CrossIcon = styled(AiFillCloseCircle)`
  color: #272833;
  font-size: 18px;
`
export default ContactInfo