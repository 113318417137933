import {
    SET_CONFIG,
    SET_ENV
} from './actionTypes';

const initialState = {
    config: {}
}

const setup = (state = initialState, action) => {
    switch (action.type) {
        case SET_CONFIG:
            return {
                ...state,
                ...action.payload.config,
            };
        case SET_ENV:
            return {
                ...state,
                env: action.payload.env
            }
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default setup;