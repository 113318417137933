import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';

const SetupOutlet = (props) => {
  let outlet = props.outlet
  
  if (outlet == null) {
    outlet = []
  }

  const outletList = outlet.length !== 0 ? (
    outlet.map((e, index) => {
      return (
        <ListGroupItem key={index} className='py-1'>
          <span>{e.outletName}</span>
          <p className="m-0 p-0" style={{float: "right"}}>
            <a className="fa fa-trash" onClick={() => { 
              props.delete(e.outletName)
             }}/>
          </p>
        </ListGroupItem>
      )
    })
  ) : (
      <p></p>
    );

  return (
      <ListGroup>
        {outletList}
      </ListGroup>
    
  )
}

export default SetupOutlet;