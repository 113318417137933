/* eslint-disable react/jsx-no-target-blank */
import React from "react"
import MetaTags from 'react-meta-tags';
import { Row, Col, Card, CardBody, Container } from "reactstrap"
import FAQ from "components/Common/faq";
import { Link } from "react-router-dom"
import { openInNewTab } from "utils";
import logoLightPng from "../../assets/images/logo-onboarding.png"

const Faq = () => {
    
    return (
        <React.Fragment>
            <MetaTags>
            <title>FAQ | CardsPal Business</title>
            </MetaTags>
            <div className="login-background">
                <Container>
                    <Row className="justify-content-center vertical-center">
                        <Col md={10} lg={10} xl={12}>
                            <Card className="overflow-hidden mt-5">
                                {/* <h3 className="m-2">FAQ</h3> */}
                                <CardBody>
                                <div className="text-center mb-2">
                                    <Link to="#" className="d-block auth-logo">
                                    <img src={logoLightPng} alt="" height="50" className="auth-logo-dark" />
                                    </Link>
                                    <h3 className="mt-1 mb-4 text-center">FAQ</h3>
                                </div>
                                    <FAQ/>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p>
                                Already have an account ?{" "}
                                <Link to="/login" className="text-primary">
                                    {" "}
                                    Login
                                </Link>{" "}
                                </p>
                                <p>
                                <Link to="#" onClick={()=>openInNewTab('https://cardspal.com/support/')}><span>Support Center</span></Link><span className="separator">|</span>
                                <Link to="#" onClick={()=>openInNewTab('https://cardspal.com/merchant_terms/')}><span>Merchant T&C</span></Link><span className="separator">|</span>
                                <Link to="#" onClick={()=>openInNewTab('https://cardspal.com/terms-of-use/')}><span>Terms of Use</span></Link><span className="separator">|</span>
                                <Link to="#" onClick={()=>openInNewTab('https://cardspal.com/privacy-policy/')}><span>Privacy Policy</span></Link>
                                </p>
                                <p>
                                © {new Date().getFullYear()} CardsPal Business 
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )

}


export default Faq;