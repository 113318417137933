import React, { useEffect, useState } from "react"
import styled from "styled-components"
import axios from "axios"


const TicketPreview = props => {
  const { imgSrc, deal, type,  config } = props;
  if (type==="app")
  return (
    <ItemContainer>
      <ImageContainer>
        <ItemImage src={imgSrc} />
      </ImageContainer>
      <TextContainer>
        <TextSubtext>
          <TextCaption>{deal.promotion_caption || "{Ticket Title}"}</TextCaption>
          <TextMerchantName>{deal.merchant_name || "{Merchant ame}"}</TextMerchantName>
        </TextSubtext>

        <PriceContainer>
          <PriceContent>
            <PriceText>${deal.purchasePrice || "{Price}"}</PriceText>
            <ActualPriceText>${deal.actualPrice || "{Actual Price}"}</ActualPriceText>
          </PriceContent>
          <DiscountContent>
            <DiscountBlock>-{deal.discount ? deal.discount * 100 : "X"}%</DiscountBlock>
          </DiscountContent>
        </PriceContainer>
      </TextContainer>
    </ItemContainer>
  )
  else
  return (
    <ItemTicketContainer>
      <ImageTicketContainer>
        <ItemTicketImage src={imgSrc}  />
      </ImageTicketContainer>
      <TextContainer>
        <TextSubtext>
          <TextCaption>{deal.promotion_caption || "{Ticket Title}"}</TextCaption>
          <TextMerchantName>{deal.merchant_name || "{Merchant name}"}</TextMerchantName>
        </TextSubtext>

        <PriceTicketContainer>
          <PriceContent>
            <PriceText>${deal.purchasePrice || "{Price}"}</PriceText>
            <ActualPriceText>${deal.actualPrice || "{Actual Price}"}</ActualPriceText>
          </PriceContent>
          <DiscountContent>
            <DiscountBlock>-{deal.discount ? deal.discount * 100 : "X"}%</DiscountBlock>
          </DiscountContent>
        </PriceTicketContainer>
      </TextContainer>
    </ItemTicketContainer>
  )


}

const ItemContainer = styled.div`

  display: inline-block;
  margin-top: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 3px;
  margin-right: 10px;
  background-color: transparent;
  border-radius: 15px;
  width: 160px;
  box-shadow: 0px 4px 4px rgb(210 210 210 / 25%);
  cursor: pointer;
`

const ItemTicketContainer = styled.div`
  display: flex;
  flex-direction:row;
  margin-top: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 3px;
  margin-right: 10px;
  background-color: transparent;
  border-radius: 15px;
  width: 320px;
  box-shadow: 0px 4px 4px rgb(210 210 210 / 25%);
  cursor: pointer;
`

const ImageContainer = styled.div`
  width: 160px;
  height: 100px;
  display: block;
  position: relative;
`

const ImageTicketContainer = styled.div`
  width: 100px;
  height: 100px;
  display: block;
  position: relative;
`

const ImageStatus = styled.img`
  position: absolute;
  width: 120px;
  height: auto;
  left: 20px;
  top: 19px;
`

const ItemImage = styled.img`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 160px;
  height: 100px;
  margin-top: 5px;
  background-color: #ffffff;
`

const ItemTicketImage = styled.img`
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  width: 100px;
  height: 100px;
  object-fit: stretch;
  margin-top: 5px;
  background-color: #ffffff;
`

const TextContainer = styled.div`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #ffffff;
`

const TextSubtext = styled.div`
  padding-left: 5px;
  padding-right: 5px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 70px;
`

const TextCaption = styled.div`
  margin-left: 7px;
  margin-right: 7px;
  padding-top: 10px;
  line-height: 1.5em;
  max-height: 3.4em;
  white-space: normal;
  overflow: hidden;
`

const TextMerchantName = styled.div`
  margin-left: 7px;
  margin-right: 7px;
  margin-top: 5px;
  font-size: 12px;
  color: #6b6c7e;
`

const PriceContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 11px 12px 10px;
`

const PriceTicketContainer = styled.div`
  display: flex;
  flex: 1;
  padding-left: 11px;
  padding-right: 12px;
`

const PriceContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const DiscountContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: right;
  align-items: flex-end;
`

const DiscountBlock = styled.div`
  display: inline-block;
  background-color: #722ed1;
  color: #ffffff;
  font-size: 8px;
  text-align: center;
  padding-top: 7px;
  border-radius: 15px;
  width: 25px;
  height: 25px;
`

const PriceText = styled.div`
  color: #722ed1;
`

const ActualPriceText = styled.div`
  font-size: 8px;
  text-decoration: line-through;
  color: #A7A9BC;
`

export default TicketPreview
