import { API, graphqlOperation } from 'aws-amplify'
import { createDealApproval } from '../../graphql/mutations'
import amplitude from 'amplitude-js';
import format from 'date-fns/format'

export const create = async (pk) => {
    try {
        const createdYearMonth = format(new Date(), "yyyy-MM")
        const result = await API.graphql(graphqlOperation(createDealApproval, { input: { pk, isApproved: false, createdYearMonth } }))
        return result.data.createDealApproval
    } catch (error) {
        amplitude.getInstance().logEventWithGroups('Error - createDealApproval', { 'details': error })
        console.error('error create deal approval  ', error)
        throw error ? error.message : error
    }
}


export default create