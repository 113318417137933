import React, { useState, useEffect, useRef } from 'react';
import { Spinner } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import MetaTags from 'react-meta-tags';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import Login from './Components/D&D_Login'
import { format } from 'date-fns';
import { remoteConfig } from 'utils';
import { getAllGroups, searchWebFormGroup, getEnv, getGroupMembers } from '../../helpers/apiHelper'
import { listVouchersByDealId } from '../../helpers/GraphQL/voucherMaster'
import { getCancelledVouchersByDealId } from '../../helpers/GraphQL/voucherUser'
import { getWebFormByDealId } from '../../helpers/GraphQL/webForm'
import { computeSoldVouchers, computeWaitlistSoldVouchers, convertToCSV } from '../../utils'
import { DEFAULT_DND_OCT_2023_GROUPS } from  '../../utils/constants'
import { SpinnerDiv } from './Components/Styled';
import Popup from './Components/Popup';
// import { initAmplifyAppUser } from 'helpers/authHelper'
import GroupUpdate from './Components/D&D_GroupUpdate';
import sha256 from 'crypto-js/sha256';

const DnDAdmin = (props) => {

  const webFormId = '0009'
  const faviconPath = `https://assets.${getEnv()}.cardspal.com/public/Events/favicon/`
  const thMiddle = {verticalAlign: 'middle', padding: '4px !important'}
  const [dataTable, setDataTable] = useState({columns: [], rows: []})
  const [hasLogin, setHasLogin] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [groups, setGroups] = useState([])
  const [adminHash, setAdminHash] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [popUpSetting, setPopUpSettings] = useState({show: false})
  const [groupUpdateSettings, setGroupUpdateSettings] = useState(false)
  const [soldVouchersRegular, setSoldVouchersRegular] = useState(null)
  const [soldRegular, setSoldRegular] = useState(0)
  const [totalRegular, setTotalRegular] = useState(0)
  const [availableRegular, setAvailableRegular] = useState(0)
  const [soldWaitlist, setSoldWaitlist] = useState(0)
  const [confirmedWaitlist, setConfirmedWaitlist] = useState(0)
  const [unConfirmedWaitlist, setUnConfirmedWaitlist] = useState(0)
  const [rejectedWaitlist, setRejectedWaitlist] = useState(0)
  const [totalWaitlist, setTotalWaitlist] = useState(0)
  const [availableWaitlist, setAvailableWaitlist] = useState(0)
  const [soldVouchersWaitlist, setSoldVouchersWaitlist] = useState(null)
  const [regularCancellations, setRegularCancellations] = useState(null)
  const [waitlistCancellations, setWaitlistCancellations] = useState(null)
  const [downloadingCSV, setDownloadingCSV] = useState(false)

  const [dimensions, setDimensions] = useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  })
  
  useEffect(() => {
    const data = {
      columns: [
          {
              label: "ID",
              field: "id",
              width: 50,
              sort: 'disabled',
              attributes: {
                style: thMiddle,
              }
          },
          {
              label: "Edit",
              field: "edit",
              sort: 'disabled',
              width: 50,
              attributes: {
                style: thMiddle,
              }
          },
          {
              label: "Group Name",
              field: "groupName",
              width: 200,
              
          },
          {
              label: "Menu",
              field: "menu",
              width: 100,
              sort: 'disabled',
              attributes: {
                style: thMiddle,
              }
          },
          {
              label: "Dress",
              field: "dress",
              sort: 'disabled',
              width: 70,
              attributes: {
                style: thMiddle,
              }
          },
        {
          label: "Confirmed",
          field: "confirmed",
          width: 50,
          sort: 'disabled',
          attributes: {
            style: thMiddle,
          }
        },
        {
          label: "Waitlisted",
          field: "waitlisted",
          width: 50,
          sort: 'disabled',
          attributes: {
            style: thMiddle,
          }
        },
          {
              label: "Members",
              field: "membersLink",
              width: 70,
              sort: 'disabled',
              attributes: {
                style: thMiddle,
              }
          }

      ],
      rows: []
    }
    console.log('init data table...')
    setDataTable({...data})
  }, [])

  useEffect(() => {
    try {
      const userData = JSON.parse(localStorage.getItem('dnd-user'));
      if (!userData) {
        setHasLogin(false)
        return
      }
      const hash = sha256(`${userData.email};${userData.sub}`)
      // console.log('set admin hash ', hash)
      setAdminHash(hash.toString())
      setHasLogin(true)
    } catch (e) {
      console.error(e)
      setHasLogin(false)
    }
  }, [])

  useEffect(async () => {
    try {
      if (dataTable.columns.length > 0) {
        init()
      }
    } catch (error) {
      console.log('error getting all groups ', error)
    }
  }, [dataTable.columns, adminHash])

  const getCustomSettings = async () => {
    let cust = await remoteConfig("b2bCustom")
    if (cust._value) {
      let parseCustJson = JSON.parse(cust._value)
      return (parseCustJson["152-dnd-2023"])
    } else return null
  }

  useEffect(async () => {
    try {
      let regularDealId = "dummy"
      let waitListDealId = "dummy"

      let remoteConfigB2BCustom = await getCustomSettings()
      for (const dealId in remoteConfigB2BCustom?.dealInfo) {
        if (remoteConfigB2BCustom.dealInfo[dealId]?.category == "regular") {
          regularDealId = dealId
        }
        if (remoteConfigB2BCustom.dealInfo[dealId]?.category == "waitlist") {
          waitListDealId = dealId
        }
      }
      // get vouchers counts

      const allVoucherCodesRegular = await listVouchersByDealId(regularDealId)
      if (allVoucherCodesRegular) {
        const { sold, total, available } = computeSoldVouchers(allVoucherCodesRegular)
        setSoldRegular(sold)
        setTotalRegular(total)
        setAvailableRegular(available)
        setSoldVouchersRegular(`${sold}/${total}`)
      }
      const cancelledRegVouchers = await getCancelledVouchersByDealId(regularDealId)
      setRegularCancellations(cancelledRegVouchers.length)

      const allVoucherCodesWaitlist = await listVouchersByDealId(waitListDealId)
      if (allVoucherCodesWaitlist) {
        const { sold, total, available } = computeWaitlistSoldVouchers(allVoucherCodesWaitlist)
        setSoldWaitlist(sold)
        setTotalWaitlist(total)
        setAvailableWaitlist(available)
        setSoldVouchersWaitlist(`${sold}/${total}`)
      }
      const cancelledWaitVouchers = await getCancelledVouchersByDealId(waitListDealId)
      const confirmedWaitlist = await getWebFormByDealId(webFormId, waitListDealId, '1', 'confirmed')
      const unconfirmedWaitlist = await getWebFormByDealId(webFormId, waitListDealId, '1', 'waitlisted')
      const rejectedWaitlist = await getWebFormByDealId(webFormId, waitListDealId, '8', 'rejected')
      
      setConfirmedWaitlist(confirmedWaitlist.length)
      setWaitlistCancellations(cancelledWaitVouchers.length)
      setUnConfirmedWaitlist(unconfirmedWaitlist.length)
      setRejectedWaitlist(rejectedWaitlist.length)

    } catch (error) {
      console.log('error' + error);
    }
  })

  useEffect (()=>{
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = `${faviconPath}152-dnd-2023_favicon.ico`;
  },[])

  const init = async () => {
    setIsLoading(true)
    const data = await getAllGroups('dnd_oct_2023')
    dataTable.rows = data.Items
    setGroups(data.Items)
    setDataTable({...dataTable})
    reloadTable({...dataTable})
    // setIsLoading(false)
  }

  const reloadTable = async (_dataTable) => {
    setIsLoading(true)
    if (_dataTable.columns.length > 0 && _dataTable.rows.length > 0) {
      const dataRows = []
      for (const [index, item] of _dataTable.rows.entries()) {
        // console.log(item.groupName, index)
        dataRows.push({
          id: index + 1,
          edit: groupEdit(item),
          groupName: item.name,
          menu: item.menu,
          dress: item.dress === '1' ? 'Yes' : 'No',
          confirmed: "loading...",
          waitlisted: "loading...",
          membersLink: <a href="#" onClick={(e) => viewMembers(e, item)} style={{ ...Styles.purple, textDecoration: "underline" }} >View</a>,
        })
      }
      _dataTable.rows = dataRows
      console.log('fresh data loaded ', dataTable)
      setDataTable({ ..._dataTable })
    }
    setIsLoading(false)
    reloadTableWithStatus({ ..._dataTable })
  }


  const reloadTableWithStatus = async (_dataTable) => {
    if (_dataTable.columns.length > 0 && _dataTable.rows.length > 0) {
      const dataRows = []
      await Promise.all(
        _dataTable.rows.map(async row => {
          let members = await getMembers(row.groupName?.toLowerCase())
          let waitlisted = members.filter(item => item.statusType === 'waitlisted')
          dataRows.push({
            ...row,
            confirmed: members.length - waitlisted.length,
            waitlisted: waitlisted.length,
          })
        }
        )

      )
      dataRows.sort((a, b) => a.id - b.id)
      _dataTable.rows = dataRows
      setDataTable({..._dataTable})
    }
  }

  const groupEdit = (item) => {
    // do not show default groups to avoid editing
    const foundObject = DEFAULT_DND_OCT_2023_GROUPS.find(m => m.value.toLowerCase() === item.name.toLowerCase())
    if (!foundObject) {
      return (
          <a href="#" onClick={(e) => updateGroup(e, item)}><i className="mdi mdi-pencil" style={Styles.purple} /></a>
      )
    } else {
      return null
    }
  }

  const updateGroup = (e, item) => {
    e.preventDefault()
    setGroupUpdateSettings({show: true, data: item})
    // const data = JSON.stringify(item)
    // const newTab = window.open(`/webform/dnd-update-group/${item.name}?data=${data}&adminHash=${adminHash}`, '_blank');
    // if (newTab) {
    //     newTab.focus();
    // }
  }

  const viewMembers = (e, item) => {
    e.preventDefault(); 
    const newTab = window.open(`/webform/view-group-members/${item.name}?webFormId=${item.webFormId || '0009'}&adminHash=${adminHash}`, '_blank')
    if (newTab) {
      newTab.focus();
    }
  }

  const onSubmitLogin = (userData) => {
    console.log('login successful:', userData)
    const hash = sha256(`${userData.attributes.email};${userData.attributes.sub}`)
    setAdminHash(hash.toString())
    setHasLogin(true)
    try {
      localStorage.setItem('dnd-user', JSON.stringify(userData.attributes));
    } catch (e) {
      console.error(e)
    }
  }

  const makeSearch = async (s) => {
    const result = await searchWebFormGroup(webFormId, s)
    if (result.statusCode === 200) {
      const searchedData = JSON.parse(result.body)
      const newGroups = groups.filter(item1 => searchedData.some(item2 => item2.groupName.toLowerCase() === item1.name.toLowerCase()));
      dataTable.rows = newGroups
      setDataTable({...dataTable})
      reloadTable({...dataTable})
    }
  }

  const handleSearch = async (e) => {
    e.preventDefault()
    if (searchTerm.length < 3) {
      setPopUpSettings({show: true, inputType: 'message', message: 'Please enter a minimum of 3 characters to search', title: 'Search requirements'})
    } else {
      setIsLoading(true)
      await makeSearch(searchTerm);
      setIsLoading(false)
    }
  }

  const handleLoadAllGroups = async(e) => {
    init()
    setSearchTerm('')
  }

  const onLogout = () => {
    localStorage.removeItem('dnd-user');
    setHasLogin(false)
  }

  const closeUpdateGroup = (reload) => {
    setGroupUpdateSettings({show: false})
    if (reload) init()
  }

  const getMembers = async (groupName) => {
    let members = []
    // this will get only those status=1
    const response = await getGroupMembers(groupName, webFormId)
    if (response.statusCode === 200) {
      const m = JSON.parse(response.body)
      // const filteredMembers = m.filter(m => m.status === "1");
      const sortedMembers = sortMembers(m)
      members = sortedMembers
      return members
    }
  }
  const sortMembers = (arr) => {
    arr.sort((a, b) => {
      if (a.creator === b.creator) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      }
      return b.creator - a.creator; // this will put leaders (creator: "1") at the top
    });
    return arr
  }
  const sortGroups = (arr) => {
    arr.sort((a, b) => {
      return a.ID - b.ID;
    }
    );
    return arr
  }

  const formatDateTimeCSV = (dt) => {
    if (dt) {
      const date = new Date(dt);
      return date.toISOString().split('.')[0].replace('T', ' ');
    } else {
      return ''
    }
  }
  const downloadVouchersTable = async () => {
    setDownloadingCSV(true)
    if (dataTable.rows.length > 0) {
      let arr = []
      await Promise.all(
        dataTable.rows.map(async (e) => {
          let members = await getMembers(e.groupName)
          if (members?.length > 0) {
            await Promise.all(
              members.map((member) => {
                // we filter only those that are confirmed or waitlist
                if (member.status==='1') {
                  let item = {
                    'ID': e.id,
                    'Group name': e.groupName,
                    'Menu': e.menu,
                    'Dress': e.dress,
                    'Name': `${member.firstName} ${member.lastName}`,
                    'Email': member.email,
                    'Bank ID': member.bankId,
                    'Phone Number': member.phoneNumber.toString(),
                    'Position': member.creator === "1" ? "Leader" : "Member",
                    'Table': member.tableNumber || '',
                    'Status': member.statusType,
                    'Creation Date/Time': formatDateTimeCSV(member.createdAt),
                    'Scanned Date/Time': formatDateTimeCSV(member.scannedDateTime)
                  }
                  arr.push(item)
                }
              })
            )
          }
        })
      )
      arr = sortGroups(arr)
      const csvTitle = "Group Summary" + (`(as of ${format(new Date(), "dd MMM yy")})`)
      convertToCSV(arr, csvTitle)
      setDownloadingCSV(false)
    }
  }

  return (
    <>
    {!hasLogin ?
      <Login onSubmit={onSubmitLogin}></Login>
    :<>
      <MetaTags>
      <title>D&D Admin | CardsPal Business</title>
      </MetaTags>
      <div style={{ margin: 15, justifyContent: 'space-between', alignItems: 'flex-start', flexDirection: 'row' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 15 }}>
          <span style={{ fontSize: 16, fontWeight: 'bolder' }}>Manage Dinner and Dance Groups and User Choice</span>
          <a href="#" onClick={() => onLogout()} style={{ fontSize: 14, fontWeight: 'bolder' }}>Logout</a>
        </div>
            <div style={{ display: 'flex', flexDirection: "column", alignItems: 'start', marginBottom: 25 }}>
              <span style={{ fontSize: 14, fontWeight: 'bolder' }}>Regular : Confirmed: {soldRegular}, Cancellations : {regularCancellations},  Available: {availableRegular}</span>
              <span style={{ fontSize: 14, fontWeight: 'bolder' }}>Waitlist : Total Waitlist Reservation: {soldWaitlist}, Rejected Waitlist: {rejectedWaitlist}, Waiting for Confirmation: {unConfirmedWaitlist}, Confirmed Waitlist: {confirmedWaitlist}, Cancellations : {waitlistCancellations},  Available: {availableWaitlist}</span>
            </div>
        <MDBDataTable 
          className="web_group" 
          fixed={true} 
          entries={10} 
          displayEntries={false} 
          hover 
          pagesAmount={10} 
          scrollX  
          data={{ columns: dataTable.columns, rows: dataTable.rows }} 
          noBottomColumns={true} />

          <div style={{ top: 115, marginLeft: 'auto', position: "absolute", }}>
          <input type="text" placeholder="Search bank or email id" value={searchTerm} onChange={(e)=> setSearchTerm(e.target.value)} style={{...Styles.bankSearch, ...Styles.searchInpu}}/>
          <button style={{...Styles.bankSearch, ...Styles.searchButton}} onClick={(e) => handleSearch(e)}>Go</button>
          <button style={{...Styles.bankSearch, ...Styles.searchButton}} onClick={(e) => handleLoadAllGroups(e)}>Load All Groups</button>
              <button style={{ ...Styles.bankSearch, ...Styles.searchButton }} onClick={(e) => downloadVouchersTable()}>Export as CSV</button>
        </div>

      </div>
    </>
    }
    {groupUpdateSettings.show &&
      <GroupUpdate adminHash={adminHash} open={true} data={groupUpdateSettings.data} close={(reload)=> closeUpdateGroup(reload)}/>
    }

    <Popup 
      open={popUpSetting.show} 
      inputType={popUpSetting.inputType} 
      message={popUpSetting.message} 
      title={popUpSetting.title}
      cancel={() => {setPopUpSettings({show: false})}}/>

      {isLoading || downloadingCSV ?
        <SpinnerDiv>
            <Spinner />
        </SpinnerDiv> : null}
    </>
  )
}

const Styles = {
  bankSearch : {
      fontSize: '0.71094rem', 
      borderRadius: '5px', 
      color: '#5b626b'
  },
  searchInpu: {
    padding: 5,
    border: '1px solid #000', 
    width: 200
  },
  searchButton: {
    marginLeft: 5,
    border: 0,
    padding: 5,
    backgroundColor: "#7a6fbe",
    color: "#fff",
    minWidth: 50,
  },
  purple: {
    color: "#7a6fbe"
  }
}

export default DnDAdmin