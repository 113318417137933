import {
    SET_AMPLITUDE_EVENT
} from './actionTypes';
import amplitude from 'amplitude-js';
const initialState = {
    eventName:'',
    eventProperties:{}
}

const event = (state = initialState, action) => {
    switch (action.type) {
        case SET_AMPLITUDE_EVENT: {
            let user, userId
            if (localStorage.getItem('authUser')){
                user = JSON.parse(localStorage.getItem('authUser'))
                userId = user.sub || user.userSub
            }else{
                userId = null //auto generated amplitude id
            }

            let eventName = action.payload.eventName //string
            let eventProperties =  action.payload.eventProperties //object
            
            try {
                if (userId) {
                    amplitude.getInstance().setUserId(userId);
                }
                
                amplitude.getInstance().logEventWithGroups(eventName, eventProperties);
            } catch (error) {
                console.error('amplitudeError', error)
            }
            
        }
        break;
        default: {
            state = { ...state };
            break;
        }
    }
    return state;
}

export default event;