
import React from "react"
import {CardBody, CardImg, CardText, Button } from 'reactstrap'

import emptyState from '../../../../assets/images/empty-state.png'

const EmptyState = (props) => {

    return (
        <CardBody className="text-center">
            <CardImg className="empty-state-img" src={emptyState} />
            <CardText className="empty-state-title">No summary available</CardText>
        </CardBody>
    )
}

export default EmptyState