import React, { useEffect, useState, useRef } from "react"
import MetaTags from 'react-meta-tags';
import { connect, useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, CardImg, Spinner, Button, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown , NavLink} from "reactstrap"
import {InputGroup, Form, Overlay} from 'react-bootstrap';
import { MDBDataTable, MDBInput } from "mdbreact"
import { fromUnixTime, format } from 'date-fns';
import Decimal from 'decimal.js';
import RBAC from '../../../components/Common/rbac'

// import components
import StripePrompt from "components/Common/stripePrompt";
import StripeOnboarding from "components/Common/stripeOnboarding";
import BoxWidget from "components/Common/boxWidget";
import EmptyState from  "./Components/emptyState"
import ModalWebformIndfo from  "./Components/viewWebformInfo"

// import utils
import { evaluateStripeStatus, openInNewTab, computeSoldVouchers, convertToCSV } from '../../../utils'
import  {getStripeStatusColor} from '../../../utils/stripe'
import { STRIPE_STATUS, GENERIC_ERROR_MESSAGE, STRIPE_BUTTON_LABEL, DEFAULT_REFRESH_URL, DEFAULT_RETURN_URL, THEME_COLOR } from '../../../utils/constants'

//import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems, setAlert, setAmplitudeEvent,  setConfig, setEnv } from "../../../store/actions";

// import helpers
import { stripeCreateOnboardingLink, stripeRetrieveKey, stripeRetrieveAccount, stripeCreateLoginLink, getConfig, getEnv, getEvents} from '../../../helpers/apiHelper'
import { getMerchantStripeAccount, updateStripeAccount, createStripeAccount } from "../../../helpers/GraphQL/userStripeAccount"
import { getSalesSummaryDealsList } from '../../../helpers/GraphQL/promotions'
import { listVouchersByDealId} from '../../../helpers/GraphQL/voucherMaster'
import { getRedeemVouchersByDeal, getVoucherUserByMasterId , setVoucherRedeemedStatus } from  '../../../helpers/GraphQL/voucherUser'
import { getCheckinTickets } from "helpers/GraphQL/checkinTicket"
import { getWebFormByTransactionId, getWebFormByWebFormId } from "helpers/GraphQL/webForm"
import { getScanHistoryByMerchantId } from "helpers/GraphQL/scanHistory"
import { components } from "react-select";
import { default as ReactSelect } from "react-select";
import { getAllTransactionsByDealIds } from 'helpers/GraphQL/transactions';

// import assets
import sellVoucherStepImg from '../../../assets/images/sellVoucherStepsC.png'





const RedemptionList = (props) => {
    const NOTAVAIL = "NA"
    const BLANKVALUE = "-"

    const dispatch = useDispatch()
    const selectedMerchant = useSelector(state => state.Merchant.selectedMerchant)
    const cmsConfig = useSelector(state => state.Config)
    const userInfo = useSelector(state => state.User.user)

    const [stripeKey, setStripeKey] = useState(null)
    const [stripeLink, setStripeLink] = useState()
    const [stripeAccountStatus, setStripeAccountStatus] = useState('')
    const [modalStripePromptStatus, setModalStripePromptStatus] = useState(false)

    const [stripeChecking, setStripeChecking] = useState(true)
    const [refreshUrl, setRefreshUrl] = useState(null)
    const [returnUrl, setReturnUrl] = useState(null)
    const [needPrompt, setNeedPrompt] = useState(null)
    const [createStripeButtonDisabled, setCreateStripeButtonDisabled] = useState(true)
    const [stripeButtonLabel, setStripeButtonLabel] = useState('')
    const [stripeAccount, setStripeAccount] = useState({})
    const [dealVouchers, setDealVouchers] = useState(null)

    const breadcrumbItems = [
        { title: "CardsPal", link: "#" },
        { title: selectedMerchant.display_merchant_name, link: "#" },
        { title: "Tools", link: "#" },
        { title: "Redemption List", link: "#" },
    ]

    const dataContainer = useRef(null)


    const transactionData = {
        columns: [
            {
                label: "ID",
                field: "id",
                width: 40,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Transaction ID",
                field: "transactionId",
                width: 80,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Voucher Name",
                field: "voucherName",
                width: 100,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Registration Info",
                field: "registrationInfo",
                width: 100,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Confirmation ID",
                field: "confirmationId",
                width: 80,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Voucher Code",
                field: "voucherCode",
                width: 70,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Redemption",
                field: "redeemed",
                width: 100,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Redeemed By",
                field: "redeemedBy",
                width: 80,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Redemption Date and Time",
                field: "redeemDateTime",
                width: 80,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Check-in",
                field: "checkin",
                width: 60,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Email Address",
                field: "email",
                width: 100,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "First Name",
                field: "firstName",
                width: 80,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Last Name",
                field: "lastName",
                width: 80,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },      
        ],
        rows: []
    }
    //DataTable format for non-checkin deals
    let noCheckinCol = [...transactionData.columns]
    noCheckinCol.splice(-4)
    const transactionDataNoCheckIn = {columns:[...noCheckinCol], rows:[...transactionData.rows]}

    // const dataEventNoCheckin = {columns:[...dataEvent.columns.slice(0,11)], rows:[...dataEvent.rows]}
    // const dataNoCheckin = {columns:[...data.columns.slice(0,10)], rows:[...data.rows]}

    const [dataTable, setDataTable] = useState(transactionData)
    const [downloadingCSV, setDownloadingCSV] = useState(false)
    const [redeemDateFrom, setRedeemDateFrom] = useState("");
    const [redeemDateTo, setRedeemDateTo] = useState("");
    const [dataLoading, setDataLoading] = useState(false)
    const [redeemVouchers, setRedeemVouchers] = useState(null)
    const [checkins, setCheckins] = useState(null)


    //new stuff
    const [toggleRedemptionFilter, setToggleRedemptionFilter] = useState(false)
    const [transactionList, setTransactionList] = useState([])
    const [initTransactionsList, setInitTransactionsList] = useState([])
    const [checkInsList, setCheckInsList] = useState([])
    const [redeemVouchersList, setRedeemVouchersList] = useState([])
    const [redemptionFilter, setRedemptionFilter] = useState({
        redeemedOnly : false,
        unusedOnly : false,
        checkInOnly : false,
        nonCheckInOnly : false
    })
    const [redemptionFilterTriggered, setRedemptionFilterTriggered] = useState(false)
    const [merchantUniqueId, setMerchantUniqueId] = useState("")
    const [dealsWithCheckIn, setDealsWithCheckIn] = useState([])
    const [showViewRegInfoModal, setShowViewRegInfoModal] = useState(false)
    const [regInfo, setRegInfo] = useState(null)
    const [initDataRows, setInitDataRows] = useState(null)
    const [webformDetails, setWebformDetails] = useState([])
    // react - select items
    const Option = (props) => {
        return (
          <div>
                <components.Option {...props}>
                <Row>
                    <Col>
                        <Form.Check 
                            type="checkbox"
                            checked={props.isSelected}
                            onChange={() => null}
                            label={props.label}
                            name={props.label}
                        />
                    </Col>


                </Row>
                </components.Option>
          </div>
        );
      };
    const [optionSelected, setOptionSelected] = useState(null)



    const groupedOptions = [
        {
          label: "All Deals",
          options: dealVouchers
        }
      ];
    
    const colourStyles = {
        control: (styles, state) => ({  ...styles, 
                                        backgroundColor: 'white',
                                        boxShadow: state.isFocused ? 0 : 0, 
                                        borderColor: state.isFocused ? THEME_COLOR.accent : styles.borderColor,
                                        "&:hover": {
                                            borderColor: state.isFocused ? THEME_COLOR.accent : styles.borderColor
                                        }
                                        }),
        option: (styles, state) => {

            return {
            ...styles,
            backgroundColor: state.isSelected ? '#E4E2F2' : 'white',
            color:  'black',
            cursor: state.isDisabled ? 'not-allowed' : 'default'
            }
        }
    };
    //-----------------------------



    useEffect(async () => {
        props.setBreadcrumbItems('Redemption List', breadcrumbItems)
    }, [selectedMerchant])

    useEffect(async () => {

        await getCMSConfig()

        dispatch(setAlert('Initializing...Please wait while we finish getting updates', 'info'))
        const key = await stripeRetrieveKey()
        if (!key) {
            dispatch(setAlert(`Error loading this page. Please report to admin immediately.`, 'danger'))
            return
        } else {
            setStripeKey(key.stripeSecret)
        }
    }, [])

    // initialize if stripe has been on-boarded before
    useEffect(async () => {
        if (!stripeKey || !selectedMerchant || !cmsConfig.cmsv2) return 
    
        setStripeChecking(true)
        let _stripeAccount = await getMerchantStripeAccount(selectedMerchant.merchant_id)
        
        if (_stripeAccount && Object.keys(_stripeAccount).length!==0) {
            setStripeAccount(_stripeAccount)
            if (_stripeAccount.status !== STRIPE_STATUS.COMPLETED) {
                await retrieveStripeLink(_stripeAccount, stripeKey)
            } else {
                await retrieveStripeLink(_stripeAccount, stripeKey)
            }
        } else {
            // first time 
            _stripeAccount = await createStripeAccountLink(stripeKey)
            setStripeAccount(_stripeAccount)
        }

        setCreateStripeButtonDisabled(false)
        setStripeChecking(false)
        dispatch(setAlert(''))
    }, [selectedMerchant, stripeKey])

    // retrieve all vouchers
    useEffect(async () => {
        try {
            if (!selectedMerchant.merchant_id) return
            
            const tempPurchasableDeals = []

            const dealIds = []

            // get purchasable deals that are valid only
            const purchasableDeals = await getSalesSummaryDealsList(selectedMerchant.merchant_id)

            for (let i=0; i < purchasableDeals.length; i++) {
                const deal = purchasableDeals[i]
                
                if (dealIds.indexOf(deal.pk) === -1) {
                    dealIds.push(deal.pk) // collect only unique deal ids
                    tempPurchasableDeals.push(deal)
                }
            }

            let mercUniqueId = ""
            const massageDealVouchers = tempPurchasableDeals.map((item, index) => {
                mercUniqueId = item.merchant_unique_id
                return {
                    value: item.pk,
                    label: `${index+1} - ${item.promotion_caption}`,
                    caption: item.promotion_caption,
                    display: true,
                    valid: item.valid
                }
            })
            setMerchantUniqueId(mercUniqueId)
            setDealVouchers(massageDealVouchers)
            setOptionSelected(massageDealVouchers)
            

            let _ampTrack = {
                'empty': massageDealVouchers.length===0 ? 'Y' : 'N',
                'merchant id': selectedMerchant.merchant_id,
                'merchant name':  selectedMerchant.merchant_name,
                'mainCat': selectedMerchant.mainCat,
                'deal id': massageDealVouchers.length===0 ? '' :massageDealVouchers[0].pk,
                'promo title': massageDealVouchers.length===0 ? '' :massageDealVouchers[0].promotion_caption
            }
            dispatch(setAmplitudeEvent('CMS Redemption List page - page load', {..._ampTrack}))
        } catch(e) {
            console.error(e)
            dispatch(setAmplitudeEvent('CMS Redemption List page - retrieve all vouchers error', {'details': JSON.stringify(e)}))
            
        }
    }, [selectedMerchant])

    // get config from s3 in case its not retrieve or lost from the redux store
    async function getCMSConfig() {
        try {
          if (!cmsConfig || !cmsConfig.cmsv2) {
    
            const config = await getConfig()
            const env = getEnv()
            
            setRefreshUrl(cmsConfig.cmsv2.stripeVariables.refreshUrl || DEFAULT_REFRESH_URL)
            setReturnUrl(cmsConfig.cmsv2.stripeVariables.returnUrl || DEFAULT_RETURN_URL)
            dispatch(setConfig(config))
            dispatch(setEnv(env))
          } 
          setRefreshUrl(cmsConfig.cmsv2.stripeVariables.refreshUrl || DEFAULT_REFRESH_URL)
          setReturnUrl(cmsConfig.cmsv2.stripeVariables.returnUrl || DEFAULT_RETURN_URL)
        } catch(e) {
          // TODO: we might need to re-route user or ask the user to refresh
          console.error('error on retrieving config file', e)
          dispatch(setAmplitudeEvent('CMS Redemption List page - cms getConfig error', {'details': JSON.stringify(e)} ))
        }
    }

    // get all transactions
    useEffect(async () => {
        let dealIds = []
        let totalRedeemVouchers = []
        let allVouchers = []
        let checkInsVouchers = []
        let checkInsData = []
        let checkInDeals = []
        let eventDeals = []
        let hasCheckIn = false
        let webforms = []
        let webformIds = []
        setDataLoading(true)
        // const checkinsList = await getCheckinTickets()

        if (dealVouchers && dealVouchers.length > 0){

            dealIds = (dealVouchers.filter(deal => {return deal.display})).map(deal => deal.value)
            await Promise.all(dealIds.map(async (deal) => {
                const allVoucherCodes = await listVouchersByDealId(deal)
                const redeem = await getRedeemVouchersByDeal(deal)
                const checkins = await getCheckinTickets(deal)
                // const checkins = checkinsList && checkinsList?.length>0 ? checkinsList.filter(ele=> ele.dealId=== deal) : []
                checkInsData = checkInsData.concat(checkins)
                totalRedeemVouchers = totalRedeemVouchers.concat(redeem)
                allVouchers = allVouchers.concat(allVoucherCodes)
                if(!hasCheckIn && (checkins.length>0))
                    hasCheckIn = true
                
                if(checkins.length > 0){
                    hasCheckIn = true
                    checkInDeals.push(deal)
                }
            }));
            await Promise.all(webformIds.map(async(webformId)=>{
                const webformsResult = await getWebFormByWebFormId(webformId)
                webforms = webforms.concat(webformsResult)
            }))
            allVouchers = allVouchers.filter(voucher => {return voucher.transactionId && (parseInt(voucher.status) < 2 )})
            allVouchers.sort((a,b) => b.retrievalTimestamp - a.retrievalTimestamp)
            hasCheckIn ? null : setDataTable(transactionDataNoCheckIn)
            setDealsWithCheckIn(checkInDeals)
            setRedeemVouchersList(totalRedeemVouchers)
            setCheckInsList(checkInsData)
            setRedeemDateFrom("")
            setRedeemDateTo("")
            setWebformDetails(webforms)

        }

        if (allVouchers.length > 0){
            let fullTransactions = await getAllTransactionsByDealIds(dealIds, {status: {eq: "0"}})
            fullTransactions = fullTransactions.map(transaction => {return transaction.id})
            allVouchers =  allVouchers.filter(voucher => fullTransactions.includes(voucher.transactionId))
            setTransactionList(allVouchers)
            setInitTransactionsList(allVouchers)
        }

    },[dealVouchers])

    // setup display data based on transactions
    useEffect(async () => {
        let dataRows = []
        let checkInsTransaction = 0
        let redeemedTransaction = 0
        const scanHistoryList = await getScanHistoryByMerchantId(selectedMerchant.merchant_id)
        await Promise.all(transactionList.map(async (transaction) => {
            try {
                const redeemedVU = redeemVouchersList.filter(voucher => voucher.masterId === transaction.id)
                const redeemed = redeemedVU.length > 0
                let redeemDTLatest = BLANKVALUE
                let redeemByLatest = BLANKVALUE
                if(redeemed){
                    redeemedTransaction += 1
                    redeemDTLatest =  fromUnixTime(Number(redeemedVU[0].redeemTimestamp))
                    redeemByLatest = "User"
                    if(redeemedVU[0].updatedAt && redeemedVU[0].updatedBy){
                        redeemDTLatest = (new Date(redeemedVU[0].updatedAt))
                        redeemByLatest = redeemedVU[0].updatedBy
                    }
                    const redeemedBy = await scanHistoryList.filter(history => history.voucherId === transaction.id)  ?? []
                    if(redeemedBy.length > 0){
                        if(new Date(redeemedBy[0].createdAt) > redeemDTLatest){
                            redeemDTLatest = (new Date(redeemedBy[0].createdAt))
                            redeemByLatest = redeemedBy[0].createdBy
                        }else if(((Math.abs(new Date(redeemedBy[0].createdAt).getTime() - redeemDTLatest.getTime())/1000) <5)){
                            redeemDTLatest = (new Date(redeemedBy[0].createdAt))
                            redeemByLatest = redeemedBy[0].createdBy
                        }

                        if(redeemedBy[0].updatedBy)
                            if(new Date(redeemedBy[0].updatedAt) > redeemDTLatest){
                                redeemDTLatest = (new Date(redeemedBy[0].updatedAt))
                                redeemByLatest = redeemedBy[0].updatedBy
                            }else if(((Math.abs(new Date(redeemedBy[0].updatedAt).getTime() - redeemDTLatest.getTime())/1000) <5)){
                                redeemDTLatest = (new Date(redeemedBy[0].updatedAt))
                                redeemByLatest = redeemedBy[0].updatedBy
                            }
                    }
                    redeemDTLatest = redeemDTLatest.toLocaleString()
                }

                //if (redeemDTLatest)
                const checkin = checkInsList.filter(checkin => checkin.masterId === transaction.id)
                if(checkin.length > 0 ) checkInsTransaction+=1
                dataRows.push({
                    transactionId: transaction.transactionId ?? "-",
                    voucherName: (dealVouchers.filter(deal => {return deal.value === transaction.dealId}))[0]['caption'] ?? NOTAVAIL,
                    voucherCode: transaction.code,
                    registrationInfo: <NavLink onClick={(e)=>viewRegistrationInfo(transaction.transactionId, transaction.code, transaction.id, e)} active>Check Info</NavLink>,
                    redeemed: <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}><Form.Check  label= {redeemed ? "Redeemed" : "Not Redeemed"} name={"data_checkbox_"+transaction.code} type='checkbox' onChange={()=> updateRedeemStatusHandler(transaction.id, redeemed? '0' : '1')} checked={redeemed}/></div>,
                    confirmationId: transaction.id?.substring(transaction.id?.length - 8) ?? "-",
                    checkin: checkin.length > 0 ? "Yes" : dealsWithCheckIn.includes(transaction.dealId) ? "No" : NOTAVAIL,
                    email: checkin[0]?.email ? checkin[0].email : dealsWithCheckIn.includes(transaction.dealId) ? BLANKVALUE : NOTAVAIL,
                    firstName:  checkin[0]?.firstName ? checkin[0].firstName : dealsWithCheckIn.includes(transaction.dealId) ? BLANKVALUE :  NOTAVAIL,
                    lastName: checkin[0]?.lastName ? checkin[0].lastName : dealsWithCheckIn.includes(transaction.dealId) ? BLANKVALUE :  NOTAVAIL,
                    redeemedBy: redeemByLatest,
                    retrievalTimestamp : transaction.retrievalTimestamp,
                    redeemDateTime : redeemDTLatest,
                    voucherId: transaction.id
                })
            } catch (error) {
              console.log('error'+ error);
            }
          }))    
        let rowDataFormat = dealsWithCheckIn.length > 0 ? transactionData  : transactionDataNoCheckIn
        rowDataFormat.rows = dataRows.sort((a,b) => b.retrievalTimestamp - a.retrievalTimestamp)
                                     .map((dataRow, index) => {dataRow['id'] = index+1
                                                                return dataRow})
        setDataTable({...rowDataFormat})
        if(initDataRows === null && rowDataFormat.rows.length >0){
            setInitDataRows(rowDataFormat.rows)
        } 
        setRedeemVouchers(`${redeemedTransaction}/${transactionList.length}`)
        setCheckins(checkInsTransaction)
    },[transactionList, redeemVouchersList])   

    useEffect( () => {

        let sDateFrom = redeemDateFrom ?  new Date(redeemDateFrom) : null
        let sDateTo = redeemDateTo ? new Date(redeemDateTo) : null

        if(sDateFrom || sDateTo){
            setDataLoading(true)
            let filteredDataRows = initDataRows
            let filteredTransactions = initTransactionsList


            filteredDataRows = filteredDataRows.filter((e) => {
                if(e.redeemDateTime && e.redeemDateTime !== '-'){

                    let dateSpilt = e.redeemDateTime.split(",")[0].split("/")
                    let formattedDate = dateSpilt[2]+"-"+dateSpilt[1]+"-"+dateSpilt[0]
                    let tDate = new Date(formattedDate)
                    if (sDateFrom && sDateTo)
                        return (tDate >= sDateFrom) && (tDate <= sDateTo)
                    else if(sDateFrom)
                        return tDate >= sDateFrom
                    else if(sDateTo)
                        return tDate <= sDateTo
                }
            }).map((e)=> e.voucherId)
            filteredTransactions = filteredTransactions.filter ((e)=>{
                return filteredDataRows.includes(e.id)
            })
            filterRedemptionList(filteredTransactions)

        }else{
            setDataLoading(true)
            filterRedemptionList(initTransactionsList)
        }
    },[redeemDateFrom, redeemDateTo, redemptionFilterTriggered])



    useEffect(() => {
        setDataLoading(false)
    },[dataTable])

    const redemptionFilterToggle = (toggle) =>{
        let redemptionFilterUpdate = redemptionFilter
        redemptionFilterUpdate[toggle] = !redemptionFilterUpdate[toggle]
        setRedemptionFilter(redemptionFilterUpdate)
        setRedemptionFilterTriggered(!redemptionFilterTriggered)
    }

    const filterRedemptionList = (transactions) =>{
        let filteredTransactions = transactions
        
        if ((redemptionFilter.redeemedOnly && redemptionFilter.unusedOnly) || (redemptionFilter.checkInOnly && redemptionFilter.nonCheckInOnly)){
            null
        }else if (redemptionFilter.redeemedOnly || redemptionFilter.unusedOnly || redemptionFilter.checkInOnly || redemptionFilter.nonCheckInOnly ){
            let checkInsMasterIds = checkInsList.map(checkin => checkin.masterId)
            let redeemVoucherCodes = redeemVouchersList.map(redeem => redeem.code)
            let redeemedOnlyTransactions, unusedOnlyTransaction, checkInOnlyTransaction, nonCheckInOnlyTransaction = []
            redeemedOnlyTransactions = redemptionFilter.redeemedOnly ? initTransactionsList.filter(x => redeemVoucherCodes.includes(x.code)) : []
            unusedOnlyTransaction = redemptionFilter.unusedOnly ?  initTransactionsList.filter( x => !redeemVoucherCodes.includes(x.code)) : []
            checkInOnlyTransaction = redemptionFilter.checkInOnly ? initTransactionsList.filter(x => checkInsMasterIds.includes(x.id)) : []
            nonCheckInOnlyTransaction = redemptionFilter.nonCheckInOnly ? initTransactionsList.filter( x => !checkInsMasterIds.includes(x.id)) : []
            filteredTransactions = [... new Set([...redeemedOnlyTransactions, ...unusedOnlyTransaction, ...checkInOnlyTransaction, ...nonCheckInOnlyTransaction])]
        }         
        setTransactionList(filteredTransactions)
    }



    const createRedemptionFilterDropDown = () => {
        return (
          <DropdownMenu className="dropdown-filter-menu">
            <DropdownItem key="redeemedOnly" toggle={false}>
                <Form.Check
                    name="redeemedOnly"
                    type="checkbox"
                    defaultChecked={redemptionFilter.redeemedOnly ? true : false}
                    disabled={false}
                    onClick={()=> redemptionFilterToggle("redeemedOnly")}
                    label={<div style={{ color: THEME_COLOR.primary }}>Show Redeemed Vouchers Only</div>}
                />
            </DropdownItem>
            <DropdownItem key="unusedOnly" toggle={false}>
                <Form.Check
                    name="unusedOnly"
                    type="checkbox"
                    defaultChecked={redemptionFilter.unusedOnly ? true : false}
                    disabled={false}
                    onClick={()=> redemptionFilterToggle("unusedOnly")}
                    label={<div style={{ color: THEME_COLOR.primary }}>Show unused Vouchers Only</div>}
                />
            </DropdownItem>

            <DropdownItem key="checkInOnly" toggle={false}>
                <Form.Check
                    name="checkInOnly"
                    type="checkbox"
                    defaultChecked={redemptionFilter.checkInOnly ? true : false}
                    disabled={false}
                    onClick={()=> redemptionFilterToggle("checkInOnly")}
                    label={<div style={{ color: THEME_COLOR.primary }}>Show checked in Vouchers Only</div>}
                />
            </DropdownItem>

            <DropdownItem key="nonCheckInOnly" toggle={false}>
                <Form.Check
                    name="nonCheckInOnly"
                    type="checkbox"
                    defaultChecked={redemptionFilter.nonCheckInOnly ? true : false}
                    disabled={false}
                    onClick={()=> redemptionFilterToggle("nonCheckInOnly")}
                    label={<div style={{ color: THEME_COLOR.primary }}>Show non-checked-in Vouchers Only</div>}
                />
            </DropdownItem>

          </DropdownMenu>
        )
    }



    async function retrieveStripeLink(_stripeAccount, stripeKey) {
        try {
            const stripeInfo = await stripeRetrieveAccount({ stripeKey, accountId: _stripeAccount.accountId })
            let status
            let link
            let btnLabel
        
            if (!stripeInfo.charges_enabled) {
                
                status = _stripeAccount.status
                const stripeInfo = await stripeCreateOnboardingLink({ email: userInfo.email, 
                    stripeKey, 
                    refreshUrl, 
                    returnUrl, 
                    merchantId: selectedMerchant.merchant_id, 
                    accountId: _stripeAccount.accountId 
                })
                link = stripeInfo.accountLink.url
                setStripeButtonLabel(STRIPE_BUTTON_LABEL.CONNECT_TO_STRIPE)
                setNeedPrompt(true)
            } else {
                ({status, btnLabel} = await evaluateStripeStatus(stripeInfo))
                
                const stripeLoginLink = await stripeCreateLoginLink({ stripeKey, accountId: _stripeAccount.accountId })
                link = stripeLoginLink.url
                setStripeButtonLabel(btnLabel)
                setNeedPrompt(false)
            }
            setStripeAccountStatus(status)
            setStripeLink(link)
        
            // update user stripe db record
            await updateStripeAccount({
                accountId: _stripeAccount.accountId,
                merchantId: selectedMerchant.merchant_id,
                email: userInfo.email,
                type: 'on-boarding',
                status
            })
        } catch(e) {
            console.error('error on retrieveStripeLink', e)
            dispatch(setAlert(`${GENERIC_ERROR_MESSAGE}[Stripe link retrieval issue]`, 'danger'))
            dispatch(setAmplitudeEvent('CMS Sales Summary page - retrieve stripe link error', {'details': JSON.stringify(e)}))
        }
    }
    
    async function createStripeAccountLink(stripeKey) {
        // first time to on-board to stripe
        
        const stripeInfo = await stripeCreateOnboardingLink({ email: userInfo.email, stripeKey, refreshUrl, returnUrl, merchantId: selectedMerchant.merchant_id })

        setStripeAccountStatus(STRIPE_STATUS.NOT_ONBOARDED)
        setStripeLink(stripeInfo.accountLink.url)
        setStripeButtonLabel(STRIPE_BUTTON_LABEL.CONNECT_TO_STRIPE)
        setNeedPrompt(true)

        // create a user stripe record in db
        const stripeAccount = await createStripeAccount({
            accountId: stripeInfo.account.id,
            merchantId: selectedMerchant.merchant_id,
            email: userInfo.email,
            type: 'on-boarding',
            status: STRIPE_STATUS.NOT_ONBOARDED
        })
        return stripeAccount
    }

    function openStripeLink() {
        if (needPrompt) {
            setModalStripePromptStatus(true)
        } else {
            setCreateStripeButtonDisabled(true)
            openInNewTab(stripeLink)
        }
    }

    function closeForm(state) {
        setModalStripePromptStatus(state)
    }

    function handleMultiSelectChange(selectedDeals) {
        setOptionSelected(selectedDeals)
        let updatedOptions = dealVouchers.map(deal => { selectedDeals.filter(selectedDeal => selectedDeal.value === deal.value).length > 0 ? deal['display'] = true : deal['display'] = false
                                                        return deal})
        setDealVouchers(updatedOptions)
    }

    const updateRedeemStatusHandler = async(masterId, status) =>{  
        try{
            setDataLoading(true)
            let totalRV = redeemVouchersList
            const vu = await getVoucherUserByMasterId(masterId)
            await setVoucherRedeemedStatus(vu[0], status)
            const updatedVU = await getVoucherUserByMasterId(masterId)


            if(status === '0')
                totalRV = totalRV.filter(rv => rv.masterId !== updatedVU[0].masterId)
            else if(status === '1')
                totalRV.push(updatedVU[0])   
            setRedeemVouchersList([...totalRV]) 
        }catch(e){
            setDataLoading(false)
            console.error('error on updating redeem status', e)
            dispatch(setAmplitudeEvent('CMS Redemption List page - cms updateRedeemStatusHandler error', {'details': JSON.stringify(e)} ))
        }
       
    }

    const viewRegistrationInfo = async(transactionId, voucherCode, masterId, e) =>{
        try{
            const webformData = await getWebFormByTransactionId(transactionId, voucherCode, masterId)
            if(webformData.length > 0){
                if (voucherCode) webformData[0]['voucherCode'] = voucherCode
                webformData[0]['confirmationId'] = masterId?.substring(masterId?.length - 8) ?? "-"
                setRegInfo(webformData[0])
                setShowViewRegInfoModal(true)
            }else{
                e.target.innerHTML = NOTAVAIL
            }

        }catch(e){
            console.error('error on view registration info', e)
            dispatch(setAmplitudeEvent('CMS Redemption List page - cms viewRegistrationInfo error', {'details': JSON.stringify(e)} ))
        }

    }

    const downloadVouchersTable = async() => {
         if (dataTable.rows.length>0) {

            let arr = []
            let webformInfo = ""
            await Promise.all(
            dataTable.rows.map( async(e)=>{
                webformInfo = e.registrationInfo
                if(webformInfo !== 'NA'){
                   const webForm = webformDetails.filter(webform => e.transactionId === webform.transactionId)
                   if(webForm.length > 0){
                        webformInfo =   `First Name: ${webForm[0].firstName} \r` +
                                        `Last Name: ${webForm[0].lastName} \r` +
                                        `Phone Number: ${webForm[0].phoneNumber} \r` +
                                        `Email: ${webForm[0].email} \r`
                        if(webForm[0].participants && webForm[0].participants.length > 0){
                            webformInfo = `Participant 1\r` + webformInfo 
                            webForm[0].participants.forEach((participant, index)=>{
                                const info = JSON.parse(participant)
                                webformInfo = webformInfo +     `Participant ${index+2}\r` +
                                                                `First Name: ${info.firstName} \r` +
                                                                `Last Name: ${info.lastName} \r` +
                                                                `Phone Number: ${info.phoneNumber} \r` +
                                                                `Email: ${info.email} \r`
                            })
                        }
                   }else webformInfo = "NA"
                } 
            
                let item = {'ID': e.id,
                            'Transaction ID': e.transactionId,
                            'Voucher Name': e.voucherName,
                            'Registration Info': webformInfo ?? "NA",
                            'Voucher Code': e.voucherCode,
                            'Confirmation ID': e.confirmationId,
                            'Redemption': e.redeemed.props.children.props.label,
                            'Redeemed By': e.redeemedBy,
                            'Redemption Date and Time': e.redeemDateTime,
                            'Check-in': e.checkin,
                            'Email Address': e.email,
                            'First Name': e.firstName,
                            'Last Name': e.lastName,
                            }
                arr.push(item)
            }))
            const csvTitle = "Redemption List" +    (redeemDateFrom ? redeemDateTo ? ` (From ${redeemDateFrom} to ${redeemDateTo})` 
                                                        : ` (From ${redeemDateFrom})` 
                                                        : redeemDateTo ? ` (Till ${redeemDateTo})` 
                                                        : "")
             convertToCSV(arr, csvTitle)
             setDownloadingCSV(false)
        }   
    }

    // const totalRevenueObj = { title: "Total Revenue", iconClass: "cellphone-sound", total: totalRevenue, bgColor: "#7A6FBE", buttonLabel: "View voucher details", link: '/sell-vouchers' }
    // const soldVouchersObj = { title: "Number of vouchers sold", iconClass: "ticket-confirmation", total: soldVouchers, bgColor: "#0DCAF0", buttonLabel: "View voucher details", link: '/sell-vouchers' }
    const redeemVouchersObj = { title: "Vouchers redeemed", iconClass: "pencil", total: redeemVouchers, bgColor: "#F5B225", buttonLabel: "View voucher details", link: '/sell-vouchers' }
    const checkinsObj = { title: "Check-Ins", iconClass: "pencil", total: checkins, bgColor: "#46b521", buttonLabel: "View voucher details", link: '/sell-vouchers' }
    // const discountCodeObj = { title: "Discount Code Value", iconClass: "cellphone-sound", total: totalDiscount, bgColor: "#DA5E6E", buttonLabel: "View voucher details", link: '/sell-vouchers' }
    // const others = {"deal id": selectedDealVoucher.pk, "promotion title": selectedDealVoucher.promotion_caption}

    return (

        <React.Fragment >
            <RBAC merchantId={selectedMerchant.merchant_id} roles={['admin']}>
            <MetaTags>
                <title>Redemption List</title>
            </MetaTags>
            
            
            <Row className="sale-summary-card justify-content-center" >
            { stripeChecking ? 
                <div className="text-center" >
                  <Spinner/> 
                </div>
              : stripeAccount.status !== STRIPE_STATUS.COMPLETED ?
                <Row className='justify-content-center' >
                    <Col xs={12} sm={12} md={6} lg={5} xl={5}>
                        <Card>
                            <CardImg
                                src={sellVoucherStepImg}
                                top
                                width="100%"
                                style={{ objectFit:'contain', marginTop:'20px' }}
                            />
                        </Card>
                        <StripeOnboarding 
                            stripeStatus={stripeAccountStatus}
                            statusColor={getStripeStatusColor(stripeAccountStatus)}
                            disabled={createStripeButtonDisabled}
                            buttonLabel={stripeButtonLabel}
                            onClick={() => openStripeLink()}
                            outline = {[STRIPE_STATUS.PENDING_VERIFICATION, STRIPE_STATUS.COMPLETED].includes(stripeAccountStatus)?true:false}
                            cta="redemption list"
                        />
                    </Col>
                </Row>
              : dealVouchers && dealVouchers.length===0 ?
                <Row className='justify-content-center'>
                    <Col xs={12} sm={12} md={6} lg={5} xl={5}>
                        {/* <Button onClick={() => openStripeLink()} color="primary" className="open-stripe-dashboard">Open Stripe Dashboard</Button> */}
                        <EmptyState history={props.history}/>  
                    </Col>
                </Row>
              :
                <Card style={{padding: 15}}>
                    <Row style={{position:"absolute", top:"-50px", right:"16px"}}>
                        <Col  className="open-stripe-dashboard-2">
                                <Button onClick={() => openStripeLink()} color="primary">Open Stripe Dashboard</Button>
                        </Col>
                    </Row>
                    <Row className="mb-2" style={{display:"flex",alignItems:"flex-end", justifyContent:"flex-end"}}>
                        <Col xs={12}>
                            <div>
                                <ReactSelect
                                    options={groupedOptions}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{
                                        Option
                                    }}
                                    onChange={handleMultiSelectChange}
                                    allowSelectAll={true}
                                    value={optionSelected}
                                    styles={colourStyles}
                                    />
                            </div>
                        </Col>



                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                    <BoxWidget hideButton={true} cta="total revenue" screen="Redemption List" history={props.history} details={redeemVouchersObj}/>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                    <BoxWidget hideButton={true} cta="check-ins" screen="Redemption List" history={props.history} details={checkinsObj}/>
                                </Col>
                            </Row>
                        </Col>
   
                    </Row>
                    {/* <Row>
                        <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                            <BoxWidget hideButton={true} cta="discount code value" screen="Sales Summary" others={others} history={props.history} details={discountCodeObj}/>
                        </Col>
                    </Row> */}
                </Card>
            }
            </Row>
            { stripeChecking ? 
               null
                : (dealVouchers && dealVouchers.length >0) && (stripeAccount.status === STRIPE_STATUS.COMPLETED)?
                <Row>
                    <Card>
                        <CardBody>
                            <Row style={{ display: "flex", justifyContent: "flex-end", top: "21px", position:"relative", zIndex:"99"}}>
                                
                                {/* <Col style={{display:"flex",alignSelf:"flex-end", justifyContent:"flex-start"}}>
                                    <Button color="primary" type="button" size="sm" onClick={()=>downloadVouchersTable()}><BsFilter/> Filter</Button>
                                </Col> */}

                                <Col xs={12} sm={12} md={6} className="mt-2" style ={{display:"flex", flexDirection:"column", alignItems:"flex-start"}}>
                                    <div className="mb-2">
                                        <h2>Redemption List</h2>
                                    </div>
                                    <div className="mb-2">
                                        Filter By:
                                    </div>
                                    <div className="mb-2">
                                        Redemption Period
                                    </div>
                                    <Row  style ={{display:"flex", flexDirection:"row", alignItems:"flex-start"}}>
                                        <Col xs={12} sm={9} className="mb-2">
                                        <InputGroup>
                                            <Form.Control
                                                style={{borderWidth: 1, maxWidth:"50%" }}
                                                type="date"
                                                name="redemptionFrom"
                                                value={redeemDateFrom}
                                                onChange={(e) => setRedeemDateFrom(e.target.value)}
                                            />
                                            <Form.Control
                                                style={{ borderWidth: 1, maxWidth:"50%" }}
                                                type="date"
                                                name="redemptionTo"
                                                value={redeemDateTo}
                                                onChange={(e) => setRedeemDateTo(e.target.value)}
                                        />                                         
                                        </InputGroup>
                                        </Col>
                                        <Col>
                                            <Dropdown
                                                isOpen={toggleRedemptionFilter}
                                                toggle={() => setToggleRedemptionFilter(() => {return !toggleRedemptionFilter})}
                                                className="d-inline-block"
                                                direction="up"
                                                >
                                                    <div className="dropdown dropdown-topbar d-inline-block">
                                                        <DropdownToggle
                                                        className="btn btn-light"
                                                        tag="button"
                                                        >
                                                        <i className="mdi mdi-filter-variant"></i> Filter 
                                                        </DropdownToggle>
                                                        {createRedemptionFilterDropDown()}

                                                    </div>
                                            </Dropdown>
                                        </Col>
                                    </Row>
                                    <div className="mb-5"></div>
                                
                                </Col>
                                
                                <Col style={{display:"flex",alignSelf:"flex-end", justifyContent:"flex-end"}}>
                                    <div>
                                    <Row>
                                        <Col style={{ display:"flex",justifyContent: "flex-end", top:"0.5vh"}}>
                                            <Button hidden={dataLoading} color="primary" type="button" size="sm" style={{fontSize:"0.65rem"}}  onClick={()=>downloadVouchersTable()}>Export as CSV Document</Button>
                                        </Col>
                                    </Row>
                                    </div>
                                </Col>
                            </Row>
                            {dataLoading ?
                                <div  >
                                    <Spinner/>
                                </div> 
                            :
                            <div ref={dataContainer}>
                            <MDBDataTable  className="transactionsSummary" disableRetreatAfterSorting={true} noRecordsFoundLabel="No records available." entriesOptions={[10, 25, 50, 100]} entries={10}  displayEntries={true} hover pagesAmount={10} fixed={true} scrollX data={{ columns: dataTable.columns, rows: dataTable.rows }} noBottomColumns={true}/>
                            {downloadingCSV &&
                                <div  >
                                <Spinner/>
                                </div>
                            }
                            </div>
                            }
                        </CardBody>
                    </Card>
                </Row>
                : null
            }
            {showViewRegInfoModal ? <ModalWebformIndfo toggleModal={(e)=>setShowViewRegInfoModal(e)} webformData={regInfo} voucherCode={""} showModal={showViewRegInfoModal}/> : null }
            {modalStripePromptStatus ? <StripePrompt stripeLink={stripeLink} modalStripePromptStatus={modalStripePromptStatus} close={(item, type) => closeForm(item, type)} /> : null}
            </RBAC>

        </React.Fragment>
    )

}

export default connect(null, { setBreadcrumbItems })(RedemptionList);