import React, { useEffect, useState } from "react"
import styled from "styled-components"
import axios from "axios"
import bullet from '../../assets/images/check-circle.png'
import { Button } from "reactstrap"
import { Image } from 'react-bootstrap';

const EventTicket = props => {
  const { deal, bundle, customPromoText, webformId, disable } = props;
  const WEB_FORM_IDS = ["0008", "0009"]
  console.log(typeof webformId, webformId in WEB_FORM_IDS)
  const promotion = props.promotion ?? true
  const bundleQty = props.issueQty ?? 2
  const splitPromotion = deal?.promotion.split("\n")
  
  return (
    <ItemContainer onClick={() => disable ? null :  deal?.dealLink && window.open(deal.dealLink, '_blank')}>

    <style type="text/css">
        {`
            @media screen and (min-width: 769px) {
              .btn-purple {
              background-color: #722ED1 !important;
              color: white;
              min-height:2vh;
              min-width:9vw;
              font-size:1.8vw;
              font-weight:600;
              }
            }
            @media screen and (max-width: 768px) {
              .btn-purple {
              background-color: #722ED1 !important;
              color: white;
              min-height:1vh;
              min-width:7vw;
              font-size:3vw;
              font-weight:600;
              }
            }
        `}
    </style>

      <TextContainer>
        <TextSubtext>
          <PromotionCaption>{deal.promotion_caption}</PromotionCaption>
            { customPromoText ?
                <Promotion><Image style={{marginRight:"1%",width:"5%", display:promotion ? "block" : "none" }} fluid src={bullet}/>{customPromoText}</Promotion>
              :
                splitPromotion.map((text, i) =>
                <Promotion key={i}><Image style={{marginRight:"1%",width:"5%", display:promotion ? "block" : "none" }} fluid src={bullet}/>{text}</Promotion>)
            }
        </TextSubtext>

        <PriceContainer>
          <PriceContent>
            {WEB_FORM_IDS.includes(webformId) ? null :
            <PriceText>${bundle ? (Number(deal.purchasePrice) * bundleQty).toFixed(2) : (Number(deal.purchasePrice)).toFixed(2)} (inclu. tax)</PriceText>
            }
          </PriceContent>
            <Button className="btn-purple" disabled={disable ? true : false }>{ disable ||  "Select"}</Button>
        </PriceContainer>
      </TextContainer>
    </ItemContainer>
  )
}

const ItemContainer = styled.div`
  display: inline-block;
  margin-right:1%;
  margin-top:1%;
  margin-bottom:1%;
  background-color: white;
  border-radius: 14px;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  width:100%;
`
const TextContainer = styled.div`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #ffffff;
  font-family:Rubik;
  color: black;
  display:flex;
  flex-direction:column;
  justify-content: space-between;
  align-self: flex-end;
  height:100%;
  padding: 2%;
`
const TextSubtext = styled.div`
  padding-left: 2vw;
  padding-right: 2vw;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  min-height:14vh;
  @media (max-width: 768px) {
     min-height:10vh;
  }
`
const Promotion = styled.div`
  font-size: 1.2vw;
  font-weight: 400;
  width:95%;

  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 768px) {
     font-size: 3.2vw;
  }
`
const PromotionCaption = styled.div`
  margin-top: 1%;
  font-size: 1.8vw;
  font-weight:600;
  @media (max-width: 768px) {
    font-size:3.6vw;
  }
`
const PriceContainer = styled.div`
  display: flex;
  flex: 1;
  padding-bottom: 1vh;
  margin-top:5%;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-bottom: 1vw;
  align-items:end;
`
const PriceContent = styled.div`
  display: flex;
  flex: 1;
`
const PriceText = styled.div`
  font-weight:600;
  font-size: 1.8vw;
  @media (max-width: 768px) {
    font-size: 3.6vw;
  }
`

export default EventTicket
