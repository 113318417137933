import React, { useState, useEffect, useRef } from 'react';
import { Spinner } from "reactstrap"
import { Title, SpinnerDiv } from './Components/Styled';
import { MDBDataTable } from "mdbreact"
import { getGroupMembers, getEnv } from 'helpers/apiHelper';
import { parseQueryString } from 'utils/'
import AccessDenied from 'components/Common/accessDenied';
import sha256 from 'crypto-js/sha256';
import MetaTags from 'react-meta-tags';

const GroupMembers = (props) => {
  const faviconPath = `https://assets.${getEnv()}.cardspal.com/public/Events/favicon/`
  const groupName = props.match.params?.groupName
  const queryStrings = parseQueryString(props.location.search)
  const webFormId = queryStrings.webFormId
  const userId = queryStrings.userId
  const adminHash = queryStrings.adminHash
  const thMiddle = {verticalAlign: 'middle', padding: '4px !important'}

  const [members, setMembers] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isAccessDenied, setIsAccessDenied] = useState(userId || adminHash?false:true)
  const [dataTable, setDataTable] = useState({columns: [], rows: []})
  const [dimensions, setDimensions] = useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  })

  useEffect(() => {
    if (!groupName) return
    getMembers(groupName)
  }, [groupName])

  useEffect(() => {
    const data = {
      columns: [
          {
              label: "#",
              field: "count",
              width: 50,
              sort: 'disabled',
              attributes: {
                style: thMiddle,
              }
          },
          {
              label: "Name",
              field: "name",
              width: 270,
              sort: 'disabled',
              attributes: {
                style: thMiddle,
              }
          },
          {
              label: "Email",
              field: "email",
              sort: 'disabled',
              width: 270,
          },
          {
              label: "Bank Id",
              field: "bankId",
              sort: 'disabled',
              attributes: {
                style: thMiddle,
              }
          },
          {
              label: "Position",
              field: "position",
              sort: 'disabled',
              attributes: {
                style: thMiddle,
              }
          },
          {
            label: "Table #",
            field: "tableNumber",
            sort: 'disabled',
            attributes: {
              style: thMiddle,
            }
          },
          {
              label: "Status",
              field: "status",
              width: 100,
              sort: 'disabled',
              attributes: {
                style: thMiddle
              }
          }
      ],
      rows: []
    }

    if (adminHash) {
      data.columns.push({
          label: "Admin Action",
          field: "edit",
          width: 200,
          sort: 'disabled',
          attributes: {
            style: {verticalAlign: 'middle', padding: '4px !important', textAlign: 'center'},
          }
      })
    }
    setDataTable({...data})
  }, [])

  useEffect(() => {
    if (isLoading) return 
    loadTable(members)
  }, [isLoading])

  useEffect (()=>{
    window.addEventListener('resize', handleResize)
  
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = `${faviconPath}152-dnd-2023_favicon.ico`;
  
    return _ => {
        window.removeEventListener('resize', handleResize)
    }
  },[])
  
  const handleResize = () => {
    let adjustment = window.document.body.offsetHeight
        setDimensions({
            height: adjustment ,
            width: window.innerWidth
        })
        window.parent.postMessage({cpHeight:adjustment}, '*')
  }
  
  const loadTable = (members) => {
    if (dataTable.columns.length > 0) {
      const dataRows = []
      members.forEach((item, index) => {
        let name = `${item.firstName} ${item.lastName}`;
        dataRows.push({
          count: index+1,
          name: name,
          email: item.email,
          bankId: item.bankId,
          position: item.creator === "1" ? "Leader" : "Member",
          tableNumber: item.tableNumber || '',
          status: item.statusType,
          edit: <div style={{textAlign: 'center', textDecoration: 'underline'}}><a href='#' style={{color: '#722ED1'}} onClick={(e) => updateUser(e, item)}>Update user</a></div>
        })
      })
      dataTable.rows = dataRows
      console.log('fresh data loaded ',dataTable)
      setDataTable({...dataTable})
    }
  }

  const sortMembers = (arr) => {
    arr.sort((a, b) => {
      if (a.creator === b.creator) {
          return new Date(b.createdAt) - new Date(a.createdAt);
      }
      return b.creator - a.creator; // this will put leaders (creator: "1") at the top
    });
    return arr
  }

  const getMembers = async (groupName) => {
    let saveAdminHash = ''
    try {
      const userData = JSON.parse(localStorage.getItem('dnd-user'))
      saveAdminHash = sha256(`${userData?.email};${userData?.sub}`).toString()
    } catch (e) {
      console.error('error on getMembers ', e)
    }

    const response = await getGroupMembers(groupName, webFormId)
    if (response.statusCode===200) {
      const m  =JSON.parse(response.body)
      // console.log({m}, {userId})
      const isUserIdPresent = m.some(m => m.userId === userId && m.status === "1");
      // console.log({isUserIdPresent}, {adminHash}, {saveAdminHash})
      if (isUserIdPresent && !adminHash) {
        const filteredMembers = m.filter(m => m.status === "1");
        const sortedMembers = sortMembers(filteredMembers)
        setMembers(sortedMembers)
      } else if (adminHash && adminHash===saveAdminHash) {
        setMembers(m)
      } else {
        setIsAccessDenied(true)
      }
      setIsLoading(false)
    }
  }

  const updateUser = (e, m) => {
    console.log('updateUser click',{m})
    e.preventDefault(); 
    window.open(`/webform/edit/${m.webFormId}/${m.eventsId}/${m.transactionId}/?adminHash=${adminHash}`, '_blank')
  }


  return (
    <React.Fragment>
        <MetaTags>
        <title>D&D Admin | CardsPal Business</title>
        </MetaTags>
        {isAccessDenied ? <AccessDenied screen="B2B" showBackToDashboard={false} /> :
          isLoading ? null :
          <div style={{ margin: 20}} >
            <Title><span style={{color: "#722ED1"}}>{groupName?.toUpperCase()}</span> members</Title>
            <MDBDataTable 
            className="web_group" 
            fixed={true} 
            entries={10} 
            displayEntries={false} 
            hover 
            pagesAmount={10} 
            scrollX  
            data={{ columns: dataTable.columns, rows: dataTable.rows }} 
            noBottomColumns={true} />
          </div>
        }
        
        {isLoading &&
          <SpinnerDiv>
              <Spinner />
          </SpinnerDiv>}
    </React.Fragment>
  )
}


export default GroupMembers