import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import { connect, useSelector, useDispatch } from "react-redux";
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardImg, CardText, Spinner, Button } from "reactstrap"

import RBAC from '../../../components/Common/rbac'

//import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems, setAlert, setAmplitudeEvent } from "../../../store/actions";

// import graphql
import { listOutletsByMerchantId } from "../../../helpers/GraphQL/outlet"

import emptyState from '../../../assets/images/empty-state.png'

// import custom components
import OutletForm from './outletForm'
import DealStatusForm from './outletStatusForm'

const ManagedOutlet = (props) => {
    const dispatch = useDispatch()
    const selectedMerchant = useSelector(state => state.Merchant.selectedMerchant)
    const merchantId = selectedMerchant.merchant_id
    const data = {
        columns: [
            {
                label: "ID",
                field: "id",
                width: 50,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Edit",
                field: "edit",
                sort: 'disabled',
                width: 50,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Visible on app",
                field: "visible",
                width: 70,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Outlet Name",
                field: "outletName",
                sort: "asc",
                width: 250,
            },
            {
                label: "Online",
                field: "online",
                sort:'disabled',
                width: 70,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Phone Number",
                field: "phoneNumber",
                sort: 'disabled',
                width: 120,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Address",
                field: "address",
                sort: 'disabled',
                width: 250,
            },
            {
                label: "Postal Code",
                field: "postalCode",
                sort: 'disabled',
                width: 70,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "MCC Code",
                field: "mccCode",
                sort: 'disabled',
                width: 70,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            }
        ],
        rows: []
    }

    const breadcrumbItems = [
        { title: "CardsPal", link: "#" },
        { title: selectedMerchant.display_merchant_name, link: "#" },
        { title: "Tools", link: "#" },
        { title: "Manage Outlets", link: "#" },
    ]
    const [outlet, setOutlet] = useState({})
    const [loadingStatus, setLoadingStatus] = useState(true)
    const [dataTable, setDataTable] = useState(data)
    const [modalFormStatus, setModalFormStatus] = useState(false)
    const [modalOutletStatus, setModalOutletStatus] = useState(false)
    const [formTitle, setFormTitle] = useState('Create Outlet')
    const [mode, setMode] = useState()
    const [selectedIndex, setSelectedIndex] = useState()
    const [reload, setReload] = useState('')
    
    useEffect(() => {
        dispatch(setAlert(''))
        props.setBreadcrumbItems('Manage Outlets', breadcrumbItems)
    },[selectedMerchant.merchant_id])

    useEffect( async () => {
        setLoadingStatus(true)
        data.rows = []
        setDataTable(data)
        const result = await listOutletsByMerchantId(merchantId)

        if (result && result.length > 0) {
            const outlets = result.map(((item, index) => {
                return {
                    id: index+1,
                    outletName: item.outlet_name,
                    online: item.subc_online === 'True' ? <span style={{ alignSelf: "center", alignItems: "center" }}><i className="mdi mdi-check-bold" style={{ color: "#35834F" }} /></span> : <span className="centerTableContent"><i className="mdi mdi-close-thick" style={{ color: "#35834F" }} /></span>,
                    phoneNumber: item.phone,
                    address: item.address,
                    postalCode: item.postal_code,
                    mccCode: item.mcc && item.mcc.length > 0 ? item.mcc[0] : '',
                    visible: getOutletStatus(item, index),
                    edit: <a href="#" onClick={() => updateOutlet(index, item)}><i className="mdi mdi-pencil" style={{ color: "#35834F" }} /></a>
                }
            }))
            
            // set the data table values
            data.rows = outlets
            setDataTable(data)
        }
        let _ampTrack = {
            'merchant id': selectedMerchant.merchant_id,
            'merchant name':  selectedMerchant.merchant_name,
            'mainCat': selectedMerchant.mainCat
          }
        let trackEmpty = {empty:result && result.length > 0? 'N' :'Y'}
        dispatch(setAmplitudeEvent('CMS Manage Outlets page - page load', {..._ampTrack, ...trackEmpty}))
        setLoadingStatus(false)
    }, [selectedMerchant.merchant_id, reload])

    function createOutlet () {
        const item = {
            merchant_id: selectedMerchant.merchant_id,
            valid: 'Y',
            display_merchant_name: selectedMerchant.display_merchant_name,
            merchant_name_lowercase: selectedMerchant.merchant_name_lowercase,
            mainCat: selectedMerchant.mainCat
        }
        setOutlet(item)
        setMode('create')
        setFormTitle('Create Outlet')
        setModalFormStatus(true)
    }

    function updateOutlet (index, item) {
        setOutlet(item)
        setSelectedIndex(index)
        setMode('update')
        setFormTitle('Update Outlet')
        setModalFormStatus(true)
    }

    function viewOutletStatus(item, index) {
        setOutlet(item)
        setSelectedIndex(index)
        setFormTitle('Show/hide outlet')
        setModalOutletStatus(true)
    }

    function getOutletStatus(item, index) {
        if (item.valid === 'Y') {
            return (
                <a href="#" onClick={() => viewOutletStatus(item, index)} >
                    <i className="mdi mdi-eye" style={{ color: "#58DB83" }} />
                </a>
            )
        } else {
            return (
                <a href="#" onClick={() => viewOutletStatus(item, index)} >
                    <i className="mdi mdi-eye-off" style={{ color: "#EC536C" }} />
                </a>
            )
        }
    }

    function showForm(showStatus, type) {
        if (type ==='outletStatus') {
            setModalOutletStatus(showStatus)
        } else {
            setModalFormStatus(showStatus)
        }
    }

    function closeForm(item, reload) {
        if (reload) {
            setReload(new Date())
            setModalFormStatus(false)
            setModalOutletStatus(false)
            return
        }

        if (item && item.outlet_name) {
            // having an outlet name meaning the user has added a new one
            if (mode==='create'){
                const totalRows = dataTable.rows.length
                
                dataTable.rows.push({
                    id: totalRows+1,
                    outletName: item.outlet_name,
                    online: item.subc_online === 'True' ? <i className="mdi mdi-check-bold" style={{ color: "#35834F" }} /> : "-",
                    phoneNumber: item.phone,
                    address: item.address,
                    postalCode: item.postal_code,
                    mccCode: item.mcc && item.mcc.length > 0 ? item.mcc[0] : '',
                    visible: getOutletStatus(item, selectedIndex),
                    edit: <a href="#" onClick={() => updateOutlet(totalRows, item)}><i className="mdi mdi-pencil" style={{ color: "#35834F" }} /></a>
                })
            } else {
                // we will try to update the current display with new data for better experienced without reloading
                dataTable.rows[selectedIndex] = {
                    id: selectedIndex + 1,
                    outletName: item.outlet_name,
                    online: item.subc_online === 'True' ? <i className="mdi mdi-check-bold" style={{ color: "#35834F" }} /> : "-",
                    phoneNumber: item.phone,
                    address: item.address,
                    postalCode: item.postal_code,
                    mccCode: item.mcc && item.mcc.length > 0 ? item.mcc[0] : '',
                    visible: getOutletStatus(item, selectedIndex),
                    edit: <a href="#" onClick={() => updateOutlet(selectedIndex, item)}><i className="mdi mdi-pencil" style={{ color: "#35834F" }} /></a>
                }
            }
        
            setDataTable({...dataTable})
        }
        setModalFormStatus(false)
        setModalOutletStatus(false)
    }

    return (
        <React.Fragment>
            <RBAC merchantId={selectedMerchant.merchant_id} roles={['admin']}>
                <MetaTags>
                    <title>Manage Outlets</title>
                </MetaTags>

                <Row>
                    <Col className="col-12">
                        <Card>

                            {loadingStatus ?
                            <CardBody className="text-center">
                                <Spinner/>
                            </CardBody>
                            : dataTable.rows.length > 0 ?
                            <CardBody>
                                <div style={{ display: "flex", justifyContent: "flex-end", top: "21px", position:"relative" }} >
                                    <Button color="primary" type="button" size="sm" onClick={() => createOutlet()}>Add outlet</Button>
                                </div>
                                <MDBDataTable className="outlets" entries={10} displayEntries={false} hover pagesAmount={10} fixed={true} scrollX data={{ columns: dataTable.columns, rows: dataTable.rows }} noBottomColumns={true}/>
                            </CardBody>
                            : 
                            <CardBody className="text-center">
                                <CardImg className="empty-state-img" src={emptyState} />
                                <CardText className="empty-state-title">No outlets added</CardText>
                                <CardText className="empty-state-subtext">Start setting up outlets now!</CardText>
                                <Button onClick={() => createOutlet()} color="primary">Set up outlets</Button>
                            </CardBody>}
                        </Card>
                    </Col>    
                </Row>

                {modalOutletStatus?<DealStatusForm outlet={outlet} modalOutletStatus={modalOutletStatus} showForm={showForm} formTitle={formTitle} close={(item) => closeForm(item)} />:null}
                {modalFormStatus?<OutletForm outlet={outlet} merchantId={merchantId} showForm={showForm} close={(item, reload) => closeForm(item, reload)} modalFormStatus={modalFormStatus} formTitle={formTitle} mode={mode}/>:null}
            </RBAC>
        </React.Fragment>
    )

    
}

ManagedOutlet.propTypes = {
    user: PropTypes.any,
    selectedMerchant: PropTypes.any,
    userMerchants: PropTypes.any,
}

const mapStatetoProps = state => {
    const { user } = state.Login
    const { selectedMerchant, userMerchants } = state.Merchant
    return { user, selectedMerchant, userMerchants }
}

export default connect(mapStatetoProps, { setBreadcrumbItems })(ManagedOutlet);