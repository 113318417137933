import { API, graphqlOperation } from 'aws-amplify'

// graphql
import { getMerchant } from '../../graphql/queries'
import { createMerchant, updateMerchant } from '../../graphql/mutations'
import amplitude from 'amplitude-js';

// helper
import { invalidateCloudFrontCache } from '../../helpers/apiHelper'

const indices = ['subc_online', 'mainCat', 'ecommerce', 'merchant_name_lowercase']

export const getBrandInfo = async (merchantId) => {
    try {
        const result = await API.graphql(graphqlOperation(getMerchant, { merchant_id: merchantId }))
        return result.data.getMerchant
    } catch (e) {
        amplitude.getInstance().logEventWithGroups('Error - getBrandInfo', { 'details': e })
        console.error('error getMerchantInfo ', e)
        throw e ? e.message : e
    }
}

export const getBrandByMerchantIds = async (merchantIds) => {
    try{
        const merchants = []
        for (const merchantId of merchantIds) {
            const result = await getBrandInfo(merchantId)
            if (result) merchants.push(result)
        }
        return merchants
    } catch (e) {
        amplitude.getInstance().logEventWithGroups('Error - getBrandByMerchantIds', { 'details': e })
        console.error('error getBrandByMerchantIds  ',e)
        throw e ? e.message : e
    }
}

export const create = async (brand) => {
    try{
        const item = { ...brand }
        for (const index of indices) {
            try {
                if (index in item && (item[index] === null || item[index] === '' || item[index].toLowerCase() === 'null')) {
                    delete item[index]
                }
            } catch (error) {
                // ignore error and continue loop
            }   
        }

        const result = await API.graphql(graphqlOperation(createMerchant, { input: item }))

        //TODO: update the USERCMS table, redux store and local storage after this

        return result.data.createMerchant
    } catch(error) {
        amplitude.getInstance().logEventWithGroups('Error - createMerchant', { 'details': error })
        console.error('error on brands create ', error)
        throw error
    }
}

export const update = async (brand, options) => {
    const item = {...brand}
    try {
        // nullify indices with empty space values. ddb will throw error. cannot have empty values in indices
        for (const index of indices) {
            if (index in item && item[index] !== null && (item[index] === '' || item[index].toLowerCase() === 'null')) {
                item[index] = null
            }
        }

        // remove the fields not defined in schema
        delete item.outlets

        const result = await API.graphql(graphqlOperation(updateMerchant, { input: item }))
        // console.log(result)

        const updatedBrand = result.data.updateMerchant

        if (options && options.invalidateCloudFrontCache) {
            options.invalidateCloudFrontCache.forEach((key) => {
                invalidateCloudFrontCache(item[key])
            })
        }

        // TODO: Get all the brands of the user

        // retrieve all outlets for merchant
        // const outlets = await Model.listAll('MerchantOutlet', {
        //     merchant_id: item.merchant_id
        // })
        // item.outlets.items = outlets

        return updatedBrand
    } catch (error) {
        amplitude.getInstance().logEventWithGroups('Error - updateMerchant', { 'details': error })
        console.error(error)
        throw error
    }
}