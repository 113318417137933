import {
  FORGET_PASSWORD
} from "./actionTypes"

const initialState = {
  email:''
}

const forgetPassword = (state = initialState, action) => {
  switch (action.type) {
    case FORGET_PASSWORD:
      return {
        ...state,
        email: action.payload
      }
     
    default:
      state = { ...state }
      break
  }
  return state
}

export default forgetPassword
