
import React, { useEffect, useState } from "react"
import { Table, Spinner } from 'reactstrap'

const VoucherCodeTable = (props) => {
    const [codes, setCodes] = useState([])
    
    useEffect(() => {
        setCodes([...props.voucherCodes])
    }, [props.voucherCodes, props.isGeneratingCode])

    return (
            <div className="table-responsive">
                {props.isGeneratingCode?<div className="text-center"><Spinner /></div>:
                <div className="scrollview scrollbar">
                <Table className="table mb-0">
                    <thead style={{backgroundColor: "#F8F9FA"}}>
                        <tr>
                        <th>ID</th>
                        <th>Voucher Code</th>
                        <th>Status</th>
                        <th>Redemption</th>
                        </tr>
                    </thead>
                    <tbody>
                        {codes.map((item, index) => {
                            return (
                            <tr key={index}>
                                <th scope="row">{item.id}</th>
                                <td>{item.code}</td>
                                <td>{item.status}</td>
                                <td>{item.redemption}</td>
                            </tr>)
                        })
                        }
                    </tbody>
                </Table>
                </div>
                }
            </div>
    )
}

export default VoucherCodeTable