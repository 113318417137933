import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useForm, Controller } from "react-hook-form";
import { Button, Form, Image } from 'react-bootstrap';
import { Row, Col, CardBody, Card, Alert, Container, Spinner, InputGroup } from "reactstrap"
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import styled from 'styled-components';
import * as Yup from 'yup';
import { UncontrolledTooltip } from 'reactstrap';
import {
    SubTitle, Underline, CardContainer, ToolTipIcon, GroupP, 
    ErrMsg, Sup, SubmitButton, Terms, DeclineButton, SpinnerDiv, P
} from './Styled';
import { createAppUser, verifyFamilyDay, getWebFormGroup, getPeopleInWebForm } from 'helpers/apiHelper'
import { OTHER_OPTION, DRESS, GROUP_ENTRY, MENU, SCB_DND_TERMS, INDIVIDUAL_FUSION,
    DEFAULT_DND_OCT_2023_MENU
} from  'utils/constants'
import { getErrorMessage } from 'utils'
import Popup from './Popup'
import amplitude from 'amplitude-js';
import { Auth } from 'aws-amplify';
import platform from 'platform';
// import DnDGrouping from './Components/DND_Grouping'

const ContactInfo = (props) => {
    const DND_OCT2023_CATEGORY = 'dnd_oct_2023'
    const webFormId = props.webFormId || '0008'
    const webForm = props.webForm || null
    const initialValues = {
        email: props.prefill?.email ?? '',
        countryCode: props.prefill?.countryCode ?? '+65',
        phoneNumber: props.prefill?.phone ?? '',
        firstName: props.prefill?.firstName ?? '',
        lastName: props.prefill?.lastName ?? '',
        otpCountryCode: '+65',
        groupName: props.prefill?.groupName ?? '',
        menu: props.prefill?.menu ?? '',
        dress: props.prefill?.dress ?? '', 
        transport: props.prefill?.transport ?? ''
    }
    const menuData = DEFAULT_DND_OCT_2023_MENU

    const infoFields = ["email", "countryCode", "phoneNumber", "firstName", "lastName", "groupName", "menu", "dress", "transport"]

    let dynamicKeys = {};
    if (props.issueQty && props.issueQty > 1) {
        for (let x = 2; x <= props.issueQty; x++) {
            Object.keys(infoFields).forEach((key) => {
                dynamicKeys[infoFields[key] + x] = infoFields[key].includes("email") ? Yup.string().email('Invalid email address').required('Required') : infoFields[key].includes("phoneNumber") ? Yup.string().required("Required") : Yup.string().required("Required")
                initialValues[infoFields[key] + x] = infoFields[key].includes("countryCode") ? '+65' : ''
            })
        }
    }
    if (props.custom?.customClause) {
        dynamicKeys[props.custom?.customClause.name] = props.custom?.customClause.required ? Yup.boolean()
            .required("Required")
            .oneOf([true], "Please accept terms and conditions") : null
    }

    let schema
    if (webFormId==='0008') {
        schema = Yup.object().shape({
            email: Yup.string()
                .email('Invalid email address')
                .required('Required'),
            countryCode: Yup.string().required("Required"),
            phoneNumber: Yup.string().required("Required"),
            firstName: Yup.string().required("Required"),
            lastName: Yup.string().required("Required"),
            terms: props.custom?.tnc ? Yup.boolean()
                .required("Required")
                .oneOf([true], "Please accept terms and conditions") : null,
            marketingConsent: props.custom?.marketingConsent ? Yup.boolean() : null,
            bankId: Yup.string().required("Required"),
            wristbandAcknowledgment: Yup.boolean().required("Required").oneOf([true], "Please accept terms and conditions"),
            ...dynamicKeys
        })
    } else if (webFormId==='0009') {
        schema = Yup.object().shape({
            bankId: Yup.string().required("Required"),
            email: Yup.string()
                .email('Invalid email address')
                .required('Required'),
            countryCode: Yup.string().required("Required"),
            phoneNumber: Yup.string().required("Required"),
            firstName: Yup.string().required("Required"),
            lastName: Yup.string().required("Required"),
            groupNameOption: Yup.string().required("Required"),
            groupName: Yup.string().when('groupNameOption', {
                is: OTHER_OPTION, 
                then: Yup.string().required('Required'), 
                otherwise: Yup.string() 
            }),
            transport: Yup.string().required("Required"),
            dress: Yup.string().required("Required"),
            // menu: Yup.string().required("Required"),
            cancelWarning: props.custom?.cancellationWarning ? Yup.boolean()
                .required("Required")
                .oneOf([true], "Please accept terms and conditions") : null,
            ...dynamicKeys
        })
    }

    const onSubmitDecline = async (e) => {
        try {
            props.setIsBusy()
            let phoneNumber = `${e.countryCode}${e.phoneNumber}`
            e.reason = (e.reasonText && e.reason === "Others") ? e.reason + " - " + e.reasonText : e.reason
            let webFormData = {
                "webFormId": props.webFormId,
                "email": e.email,
                "phoneNumber": phoneNumber,
                "reason": e.reason,
                "firstName": e.firstName,
                "lastName": e.lastName,
                "prepopulated": "1"
            }
            props.setNotBusy()
            amplitude.getInstance().logEventWithGroups('B2B - Decline')
        } catch (error) {
            console.error('onSubmitDecline', error)
        }
    }

    const [state] = useState(initialValues);
    const [notAttending, setNotAttending] = useState(false)
    const [rsvped, setRsvped] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [verified, setVerified] = useState(false)
    const [counter, setCounter] = useState(0);
    const [enableVerify, setEnableVerify] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [session, setSession] = useState(null)
    const [emailInput, setEmailInput] = useState("")
    const [phoneInput, setPhoneInput] = useState(null)
    const [nonSG, setNonSG] = useState(false)
    const [allGroupOptions, setAllGroupOptions] = useState([])
    const [groupNames, setGroupNames] = useState([])
    const [peopleInGroup, setPeopleInGroup] = useState([])
    const [selectedGroupName, setSelectedGroupName] = useState({})
    const [isGroupNameDuplicate, setIsGroupNameDuplicate] = useState(false)
    const [selectedMenu, setSelectedMenu] = useState([])
    const [isGroupNameChange, setIsGroupNameChange] = useState(false)
    const [groupCreator, setGroupCreator] = useState('')
    const [groupNameOptionNote, setGroupNameOptionNote] = useState('')
    const [disableGroupOption, setDisableGroupOption] = useState(false)

    const { 
        register, 
        unregister, 
        getValues, 
        setError, 
        setValue, 
        handleSubmit, 
        reset, 
        clearErrors, 
        control, 
        watch, 
        formState: { 
            errors, 
            isSubmitSuccessful, 
            isValid 
        } 
    } = useForm({
            mode: "onChange",
            reValidateMode: "onChange",
            defaultValues: state,
            resolver: yupResolver(schema)
        });

    useEffect(() => {
        if (!webForm) return
        setValue('dress', webForm.dress)
        setValue('transport', webForm.transport)
        const menu = [{label: webForm.menu, value: webForm.menu}]
        const groupName = [{label: webForm.groupName, value: webForm.groupName}]
        setValue('menu', webForm.menu)
        setValue('groupName', webForm.groupName)
        setSelectedMenu(menu)
        setSelectedGroupName(groupName)
    }, [webForm])

    useEffect(() => {
        if (webFormId==='0009') getGroup()
    }, [webFormId])

    useEffect(() => {
        const timer =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    const SendOTP = async () => {
        const values = getValues()
        if (values?.otpCountryCode?.length > 1 && values?.otpPhoneNumber?.length > 1) {
            setIsLoading(true)
            const username = values.otpCountryCode + values.otpPhoneNumber
            Auth.signIn(username)
                .then((result) => {
                    clearErrors('otpPhoneNumber');
                    setCounter(60)
                    setEnableVerify(true)
                    setSession(result)
                    setIsLoading(false)
                })
                .catch(async (err) => {
                    if (err.code === 'UserNotFoundException') {
                        await signUp(username);
                    } else if (err.message === "User is disabled.") {
                        setError("otpPhoneNumber", { type: "custom", message: "User is blocked. Please contact support" })
                        setIsLoading(false)
                    } else {
                        setError("otpPhoneNumber", { type: "custom", message: "We've encountered an error sending you the code. Please try again later." })
                        setIsLoading(false)
                    }
                })
        } else {
            setError("otpPhoneNumber", { type: "custom", message: "Please enter a valid phone number" })
        }
    }

    const signUp = async (username) => {
        const password = 'Abcd@' + Math.floor(Math.random() * 90000)

        Auth.signUp({
            username: username,
            password: password,
            attributes: { phone_number: username, email: '' },
        }).then(async (data) => {
            await SendOTP()
        }
        ).catch(err => {
            if (err.code === 'InvalidParameterException') {
                setError("otpPhoneNumber", { type: "custom", message: "Invalid phone number. Please try again." })
            } else {
                setError("otpPhoneNumber", { type: "custom", message: "Invalid parameter. Please try again." })
            }
            setIsLoading(false)
        }
        )
    }
    const verify = () => {
        const values = getValues()
        const confirmationCode = values.otpVerify
        if (session && confirmationCode?.length > 1) {
            setIsLoading(true)
            Auth.sendCustomChallengeAnswer(session, confirmationCode)
                .then(() => {
                    Auth.currentAuthenticatedUser().then(async (user) => {
                        let userId = user.attributes['custom:uuid'] || user.attributes.sub
                        setIsLoading(false)
                        clearErrors("otpVerify")
                        let res = await createAppUser({ userId: userId, phoneNumber: session.username })
                        if (res.statusCode === 'User already exist' || res.statusCode === 'success') {
                            setValue("userId", userId)
                            setVerified(true)
                        } else {
                            setError("otpVerify", { type: 'custom', message: "Something went wrong. Please try again later" })
                        }
                    })
                        .catch((err) => {
                            console.log("err" + err)
                            setIsLoading(false)
                        });

                })
                .catch((err) => {
                    if (err.code === "NotAuthorizedException") {
                        if (err.message === "User is disabled.") {
                            setError('otpVerify', { type: 'custom', message: "User is blocked. Please contact support" });
                        } else {
                            setError('otpVerify', { type: 'custom', message: "Please enter a valid OTP" });
                            SendOTP()
                        }
                    }
                    else {
                        setError("otpVerify", { type: 'custom', message: "Something went wrong. Please try again later" })
                    }
                    setIsLoading(false)
                });
        } else {
            setError("otpVerify", { type: 'custom', message: "Please enter a valid OTP" })
        }
    }
    const onChange = async (e) => {
        // console.log('---onChange', e)
        if (e.target.name === "email") {
            setEmailInput(e.target.value)
            setValue("billingEmail", e.target.value)
        }

        if (e.target.name === "phoneNumber") {
            if (e.target.value) {
                clearErrors('otpPhoneNumber');
                setPhoneInput(parseInt(e.target.value))
                setValue("otpPhoneNumber", e.target.value)
            }
        }

        if (e.target.name.startsWith("email") && !errors[e.target.name]) {
            let loop = props.issueQty || 1
            const values = getValues()
            let duplicateFound = false
            let emailList = [e.target.value]

            for (let x = 0; x < loop; x++) {
                let index = x === 0 ? "" : x
                if (e.target.name !== "email" + index && e.target.value.length > 0) {
                    if (e.target.value === values["email" + index]) {
                        duplicateFound = true
                    }
                }
            }
            if (duplicateFound) {
                setError(e.target.name, { type: "custom", message: "Duplicate email found" })
            }
        }
    }

    const onSubmit = (e) => {
        // console.log('---submit', e)
    }

    const onBlur = async (e) => {
        if (e?.target?.name?.startsWith("email")) {
            let domains = ["@sc.com", "@cardspal.com"]
            let test = false
            for (const domain of domains) {
                var idx = e.target.value.indexOf(domain);
                if (idx > -1) {
                    test = true
                    break;
                }
            }
            if (!test) {
                setError(e.target.name, { type: "custom", message: "Invalid corporate email" })
            } else if (!errors.email && test) {
                clearErrors('email');
            }
        } else if (e?.target?.name?.startsWith("bankId")) {
            let isNum = /^\d+$/.test(e.target.value);
            if (!isNum || e.target.value.length !== 7)
                setError(e.target.name, { type: "custom", message: "Invalid bankId" })
        }
    }

    const onError = (e) => {
        console.log('---error', e)
    }

    const redirect = (URL) => {
        window.location.href = URL
    }

    const handleGroupChange = (e) => {
        // console.log('handleGroupChange ', e)
        setIsGroupNameChange(true)
        setSelectedGroupName(e)
        setGroupNameOptionNote('')

        if (e.value === OTHER_OPTION) {
            setDisableGroupOption(false)
        } else {
            const foundObject = menuData.find(item => item.value.toLowerCase() === e.value.toLowerCase())
            if (foundObject) {
                setSelectedMenu(foundObject)
                setDisableGroupOption(true)
                setValue('menu', foundObject.value)
                setValue('dress', '0')
                setValue('groupNameOption', foundObject.value)
                setGroupNameOptionNote(<GroupP>You will have to stick to the default options selected for this choice</GroupP>)
            } else {
                getOtherGroupData(e.value)
            }
        }
        setValue('groupNameOption', e.value)
    }

    const handleMenuChange = (e) => {
        setSelectedMenu(e)
        setValue('menu', e.value)
    }

    const getGroup = async () => {
        let groups = await getWebFormGroup(DND_OCT2023_CATEGORY)
        if (!groups.error) {
          let parseGroups = JSON.parse(groups.body)
          let allValidGroups = parseGroups.Items.filter(item => {
            return item.status === '1'
          });
          setAllGroupOptions(allValidGroups)
          
          const createdGroups = parseGroups.Items.filter(item => {
            return item.status === '1' && item.createdBy
          })
       
          // order the drop down
          const defaultGroups = menuData.map(item => {return {...item, name: item.value } })
          const finalGroup = defaultGroups.concat(createdGroups).concat([{ name: OTHER_OPTION }])
          const groupData = finalGroup.map(item => {
            return {
              value: item.name,
              label: item.name,
            }
          })
          setGroupNames(groupData)
        }
    }

    const getGroupCreator = (groupName) => {
        let group = allGroupOptions.filter(item => {
            return item.name === groupName
        })
        return group.length > 0 ? group[0].createdBy: ''
    }

    const getOtherGroupData = async (group) => {
        let response = await getPeopleInWebForm(webFormId, "")
        if (!response.error) {
          let webFormData = JSON.parse(response.body)
          let filterDataByGroup = webFormData.filter(item => {
            return group === item.groupName && item.status === '1' ;
          });
          setPeopleInGroup(filterDataByGroup)
          
          if (filterDataByGroup.length > 0) {
            let menu = [{ value: filterDataByGroup[0].menu, label: filterDataByGroup[0].menu }] 
            let dress = filterDataByGroup[0].dress
            setSelectedMenu(menu)
            setValue('menu', menu[0].value)
            setValue('dress', dress)
            setDisableGroupOption(true)
            const groupCreatedBy = getGroupCreator(group)
            setGroupNameOptionNote(<GroupP>Only {filterDataByGroup?.length} people are in this group now. You have to stick to the option selected by {groupCreatedBy}, the original creator for this group</GroupP>)
          }
        }
      }

    const DNDTemplate = () => {
        return (
            <>
            {selectedGroupName.value === OTHER_OPTION &&
                <>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Form.Label>Enter Group Name<sup>*</sup>
                            <ToolTipIcon id='third' />
                            <UncontrolledTooltip
                                placement='right'
                                target='third'
                            >
                                <p style={{ textAlign: 'left', marginBottom: 0 }}>{GROUP_ENTRY}</p>
                            </UncontrolledTooltip>
                            </Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Control  
                                type="text" 
                                name="groupName"
                                placeholder="Input your group name" 
                                className={errors.groupName && "error"}
                                {...register("groupName")}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="groupName"
                                render={({ message }) => <P>{message}</P>}
                            />
                            {isGroupNameDuplicate && 
                            <P>Matching group name detected: {getValues('groupName').toUpperCase()}. Please submit a unique name or select from the above field.</P>}
                        </Col>
                    </Row>
                    <Underline className='mb-3' />
                </>
            }
            
            <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Menu Choice<sup>*</sup>
                    { selectedGroupName?.value !== OTHER_OPTION  && 
                        <span>
                        <ToolTipIcon id='fourth' />
                        <UncontrolledTooltip
                            placement='right'
                            target='fourth'
                        >
                            {MENU}
                        </UncontrolledTooltip>
                        </span> }
                </Form.Label>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Controller
                        name='menu' 
                        control={control}
                        render={({ ref }) => (
                            <Select
                            isDisabled={disableGroupOption}
                            isSearchable
                            inputRef={ref}
                            value={selectedMenu}
                            options={menuData}
                            placeholder='Select an option'
                            onChange={handleMenuChange}
                            />
                        )}
                        />
                        <ErrorMessage
                        errors={errors}
                        name="menu"
                        render={({ message }) => <P>{message}</P>}
                    />
                </Col>
            </Row>
            <Underline className='mb-3' />
            <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Form.Label>Register for Best Dressed Table?<sup>*</sup>
                        <ToolTipIcon id='tooltip' />
                        <UncontrolledTooltip
                            placement='right'
                            target='tooltip'
                        >
                            <p style={{ textAlign: 'left', marginBottom: 0 }}>{DRESS}</p>
                        </UncontrolledTooltip>
                    </Form.Label><br />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Form.Check
                        disabled={disableGroupOption}
                        inline
                        name="dress"
                        type="radio" 
                        label="Yes"
                        value="1"
                        {...register("dress")}
                    />
                    <Form.Check 
                        disabled={disableGroupOption}
                        inline
                        name="dress"
                        type="radio" 
                        label="No"
                        value="0"
                        style={{marginLeft: 120}}
                        {...register("dress")}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="dress"
                        render={({ message }) => <P>{message}</P>}
                    />
                </Col>
            </Row>
            <Underline className='mb-3' />
            </>
        )
    }

    const contactTemplate = (value) => {
        let render = []
        let loop = value ? value : 1
        for (let x = 0; x < loop; x++) {
            let index = value ? x === 0 ? "" : (x + 1) : ""
            render.push(
                <div key={"contactInfo-" + x} className='mb-4' >
                    {webFormId!=='0009' && value && (
                        x === 0 ?
                            <SubTitle>Participant 1 (Myself)</SubTitle>
                            :
                            <SubTitle>Participant {x + 1}</SubTitle>
                    )
                    }
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Label>Bank ID<Sup>*</Sup></Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Control
                                type="text"
                                name={"bankId" + index}
                                placeholder="Enter your bank ID "
                                className={errors.bankId && "error"}
                                autoComplete='off'
                                {...register("bankId" + index)}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="bankId"
                                render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                            />
                        </Col>
                    </Row>
                    <Underline className='mb-3' />
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Label>Corporate email address<Sup>*</Sup></Form.Label>
                            <div style={{ marginBottom: 5 }}>
                                <div style={{ color: "#6B6C7E", fontSize: 12 }}> Wristband redemption information and payment receipt will be sent to this email  </div>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Control
                                type="text"
                                name={"email" + index}
                                placeholder="Enter your corporate email"
                                className={errors.email && "error"}
                                autoComplete='off'
                                autoCapitalize='none'
                                disabled={props.isDisabled}
                                {...register("email" + index)}
                            />
                            <ErrorMessage
                                errors={errors}
                                name={"email" + index}
                                render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                            />
                        </Col>
                    </Row>
                    <Underline className='mb-3' />
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Label>First name<Sup>*</Sup></Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Control
                                type="text"
                                name={"firstName" + index}
                                placeholder="Enter your first name "
                                className={errors.firstName && "error"}
                                autoComplete='off'
                                {...register("firstName" + index)}
                            />
                            <ErrorMessage
                                errors={"firstName" + index}
                                name="firstName"
                                render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                            />
                        </Col>
                    </Row>
                    <Underline className='mb-3' />
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Label>Last name<Sup>*</Sup></Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Control
                                type="text"
                                name={"lastName" + index}
                                placeholder="Enter your last name"
                                className={errors.lastName && "error"}
                                autoComplete='off'
                                {...register("lastName" + index)}
                            />
                            <ErrorMessage
                                errors={errors}
                                name={"lastName" + index}
                                render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                            />
                        </Col>
                    </Row>
                    <Underline className='mb-3' />
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Label>Group Name<sup>*</sup>
                                <ToolTipIcon id='second' />
                                <UncontrolledTooltip
                                    placement='right'
                                    target='second'
                                >
                                    <p style={{ textAlign: 'left', marginBottom: 0 }}>
                                    Can select from the default group options or groups created by other user.
                                    <br/>Can also create your own group by selecting the Others option.
                                    </p>
                                </UncontrolledTooltip>
                            </Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Controller
                                name="groupNameOption"
                                control={control}
                                render={({ ref }) => (
                                    <Select
                                        isSearchable
                                        inputRef={ref}
                                        value={selectedGroupName}
                                        options={groupNames}
                                        placeholder='Select...'
                                        onChange={handleGroupChange}
                                        styles={{
                                            control: base => ({
                                                ...base,
                                                height: 34,
                                                minHeight: 34
                                            })
                                        }}
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="groupNameOption"
                                render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                            />
                            {groupNameOptionNote}
                            {/* {!showAll && selectGroup &&
                                (isGroupCreator ?
                                <GroupP>Only {peopleInGroup?.length} people are in this group now. You have to stick to the option selected by {creator}, the original creator for this group</GroupP>
                                :
                                <GroupP>You will have to stick to the default options selected for this choice</GroupP>
                                )
                            } */}
                        </Col>
                    </Row>
                    <Underline className='mb-3' />
                    { isGroupNameChange ? <DNDTemplate/> : null }
                    { webFormId === '0009' &&
                        <>
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Form.Label>Transportation from CBP?</Form.Label>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Form.Check
                                    inline
                                    name="transport"
                                    type="radio" 
                                    label="Yes"
                                    value="1"
                                    {...register("transport")}
                                />
                                <Form.Check 
                                    inline
                                    name="transport"
                                    type="radio" 
                                    label="No"
                                    value="0" 
                                    style={{marginLeft: 120}}
                                    {...register("transport")}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="transport"
                                    render={({ message }) => <P>{message}</P>}
                                />
                                </Col>
                        </Row>
                        <Underline className='mb-3' />
                        </>
                    }
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Label>Phone number*</Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Row>
                                <Col xs={'auto'}>
                                    <Controller
                                        name={"countryCode" + index}
                                        control={control}
                                        render={({ field: { onChange, value, name, ref } }) => {
                                            const handleSelectChange = (e) => {
                                                onChange(e?.value);
                                                if (!verified) {
                                                    setValue("otpCountryCode", e?.value)
                                                    if (e?.value !== "+65") {
                                                        setNonSG(true)
                                                    } else {
                                                        setNonSG(false)
                                                    }
                                                }
                                            };
                                            return (
                                                <Select
                                                    inputRef={ref}
                                                    value={props.countryCode.find((e) => e.value === value)}
                                                    options={props.countryCode}
                                                    placeholder='Select...'
                                                    onChange={handleSelectChange}
                                                    styles={{
                                                        control: base => ({
                                                            ...base,
                                                            height: 34,
                                                            minHeight: 34,
                                                            width: 120,
                                                        })
                                                    }}
                                                    isDisabled={verified}
                                                />
                                            )
                                        }}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={"countryCode" + index}
                                        render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                    />
                                    {nonSG ? null :
                                        <Row style={{ marginTop: "0.5rem", textAlign: "center" }}>
                                            <Form.Label style={{ marginTop: "0.5rem", fontSize: 20 }} >OTP</Form.Label>
                                        </Row>
                                    }
                                </Col>
                                <Col>
                                    <InputGroup>
                                    <Form.Control
                                        type="number"
                                        name={"phoneNumber" + index}
                                        placeholder="Enter your phone number"
                                        className={errors.phoneNumber && "error"}
                                        autoComplete='off'
                                            disabled={verified}
                                        {...register("phoneNumber" + index)}
                                    />
                                        {verified || nonSG ? null :
                                            <Button style={{ backgroundColor: "#722ED1", color: "#fff" }} disabled={counter > 0 ? true : false} onClick={() => SendOTP()} variant="outline-primary">{counter > 0 ? "Resend in " + counter + "s" : "Send OTP"}</Button>
                                        }
                                    </InputGroup>

                                    <ErrorMessage
                                        errors={errors}
                                        name={"otpPhoneNumber"}
                                        render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                    />
                                    {nonSG ? null :
                                        <div>
                                            <Row style={{ marginTop: 10, textAlign: "center" }}>
                                                <InputGroup>
                                                    <Form.Control
                                                        type="number"
                                                        name="otpVerify"
                                                        placeholder="Enter OTP"
                                                        className={errors.otpVerify && "error"}
                                                        autoComplete='off'
                                                        disabled={verified}
                                                        {...register("otpVerify")}
                                                    />
                                                    {enableVerify ? <Button style={{ backgroundColor: verified ? "#2AD2C9" : "#722ED1", borderColor: verified ? "#2AD2C9" : "#722ED1", color: "#fff" }} onClick={() => verify()} disabled={verified} variant="outline-primary">{verified ? "Verified" : "Verify"}</Button> : null}

                                                </InputGroup>
                                            </Row>
                                            <ErrorMessage
                                                errors={errors}
                                                name="otpVerify"
                                                render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                            />
                                            {!verified && !errors.otpVerify && !props.isDisabled &&
                                                <ErrMsg>Please make sure to verify your mobile number.</ErrMsg>
                                            }
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    
                    {isLoading &&
                        <SpinnerDiv>
                            <Spinner />
                        </SpinnerDiv>}
                </div>
            )
        }
        return render
    }

    const processSubmit = async (e) => {
        setIsLoading(true)
        let otpPhoneNumber = `${e.otpCountryCode}${e.otpPhoneNumber}`
        const values = getValues()
        let userId = values?.userId ? values.userId : values?.email?.replace('@', '_')
        let params = {
            webFormId: props.webFormId ? props.webFormId : "0008",
            bankId: e.bankId,
            email: e.email,
            dealId: props.dealId,
            phoneNumber: otpPhoneNumber,
            userId: userId
        }

        if (props.webFormId==='0009') {
            params.groupName = selectedGroupName.value===OTHER_OPTION ? e.groupName : selectedGroupName.value=
            params.menu = e.menu
            params.transport = e.transport
            params.dress = e.dress
            params.category= DND_OCT2023_CATEGORY
            params.creator = selectedGroupName.value===OTHER_OPTION ? '1' :'0'
        }
        let verificationData = await verifyFamilyDay(params)
        if (verificationData?.body?.mismatchedFields?.length > 0) {
            let mismatchedFields = verificationData?.body?.mismatchedFields
            Object.keys(mismatchedFields).forEach((key) => {
                
                if (mismatchedFields[key].field === 'phoneNumber') {
                    setError("otpPhoneNumber", { type: "custom", message: "Phone number doesn't match your previous transaction" })
                } else {
                    let readableField = ''
                    if (mismatchedFields[key].field === 'bankId') {
                        readableField = 'Bank ID'
                    } else if (mismatchedFields[key].field === 'email') {
                        readableField = 'Email'
                    }
                    setError(mismatchedFields[key].field, { type: "custom", message: (readableField + " doesn't match your previous transaction") })
                }
            })
        } else if (verificationData?.canPurchase) {
            e.pastPurchaseQty = verificationData?.body?.purchased?.length || "0"
            props.onSubmit({...e, ...params})
        } else {
            console.error("verification Error", verificationData)
            setError("email", { type: 'custom', message: getErrorMessage(verificationData?.error) || "Something went wrong. Please try again later" })
        }
        setIsLoading(false)
    }

    const DnDCancellationWarning = () => {
        return (
            <Row className='justify-content-center' style={{ marginTop: 10 }}>
                <Terms>
                    <Form.Check 
                        name="cancelWarning"
                        type="checkbox" 
                        label={(
                            <span>I have read and agree to the Late Cancellation / No-Show penalties (SG$100) and
                            <a href={{SCB_DND_TERMS}} target='_blank' rel='noreferrer'>T&Cs</a> of registration<sup>*</sup>
                            </span>
                        )}
                        {...register("cancelWarning")}
                    />
                </Terms>
                <SubmitButton
                    type="submit"
                    className="btn btn-primary btn-block"
                    disabled={
                        !isValid || (!nonSG && !verified) ||
                        Object.keys(errors).length > 0
                    }
                >Proceed to reserve tickets
                </SubmitButton>
            </Row>
        )
    }

    console.log(`======= is valid:${isValid}, nonSG: ${nonSG} verified: ${verified}, errors: ${Object.keys(errors).length}`)
    return (
        <React.Fragment>
            <Row className='justify-content-center'>
                <Col>
                    <Form onSubmit={handleSubmit(processSubmit, onError)} onChange={(e) => onChange(e)} onBlur={(e) => onBlur(e)}>
                        <fieldset disabled={props.isDisabled}>
                            <CardContainer style={props.styling ?? { backgroundColor: "#fff" }} >
                                <CardBody>
                                    <SubTitle>To start off, please share with us your contact info.</SubTitle>
                                    {contactTemplate(props.issueQty)}
                                </CardBody>
                            </CardContainer>
                            {props.custom?.cancellationWarning && <DnDCancellationWarning />}
                            {props.custom?.hasPayment ?
                                <Row className='justify-content-center' style={{ marginTop: 10 }}>
                                    {props.custom?.customClause &&
                                        <Terms>
                                            <Form.Check
                                                name={props.custom?.customClause.name}
                                                type="checkbox"
                                                className={errors[props.custom?.customClause.name] && "error"}
                                                label={<div style={{ color: "#272833" }}>I have read and agree to the <a href={props.custom?.customClause?.link} target='_blank' rel='noreferrer'>{props.custom?.customClause?.text}</a>.{props.custom?.customClause.required ? '*' : null}</div>}
                                                {...register(props.custom?.customClause.name)} />
                                            <ErrorMessage
                                                errors={errors}
                                                name={props.custom?.customClause.name}
                                                render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                            />
                                        </Terms>

                                    }
                                    <Terms>
                                        <Form.Check
                                            name="wristbandAcknowledgment"
                                            type="checkbox"
                                            label={<div style={{ color: "#272833" }}>I acknowledge that physical wristbands must be collected prior to the event. I will adhere to the collection data provided by the organizers.*</div>}
                                            {...register("wristbandAcknowledgment")} />
                                        <ErrorMessage
                                            errors={errors}
                                            name="wristbandAcknowledgment"
                                            render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                        />
                                    </Terms>
                                    {props.custom?.tnc &&
                                        <Terms>
                                            <Form.Check
                                                name="terms"
                                                type="checkbox"
                                                className={errors.terms && "error"}
                                                label={<div style={{ color: "#272833" }}>I have read and agree to the <a href='https://cardspal.com/ticket-sales-terms-and-conditions/' target='_blank' rel='noreferrer'>T&Cs of Ticket Sales</a> and <a href='https://cardspal.com/privacy-policy/' target='_blank' rel='noreferrer'>CardsPal&apos;s Data Privacy Policy</a>.*</div>}
                                                {...register("terms")} />
                                            <ErrorMessage
                                                errors={errors}
                                                name="terms"
                                                render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                            />
                                        </Terms>

                                    }
                                    {props.custom?.marketingConsent &&
                                        <Terms>
                                            <Form.Check
                                                name="marketingConsent"
                                                type="checkbox"
                                                label={<div style={{ color: "#272833" }}>I wish to receive marketing materials (including news on other exciting events) from the organisers and/or CardsPal, and consent to the disclosure of my personal data to the organisers for such purpose.</div>}
                                                {...register("marketingConsent")} />
                                            <ErrorMessage
                                                errors={errors}
                                                name="marketingConsent"
                                                render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                            />
                                        </Terms>
                                    }
                                    <SubmitButton
                                        type="submit"
                                        className="btn btn-primary btn-block"
                                        disabled={
                                            !isValid || (!nonSG && !verified) ||
                                            Object.keys(errors).length > 0
                                        }
                                    >Continue to payment
                                    </SubmitButton>

                                </Row> :
                                <>
                                    {props.custom?.tnc &&
                                        <Row className='justify-content-center m-3 pt-3'>
                                            <Terms>
                                                <Form.Check
                                                    name="terms"
                                                    type="checkbox"
                                                    className={errors.terms && "error"}
                                                    label={<div style={{ color: "#272833" }}>I have read and agree to the <a href='https://cardspal.com/ticket-sales-terms-and-conditions/' target='_blank' rel='noreferrer'>T&Cs of Ticket Sales</a> and <a href='https://cardspal.com/privacy-policy/' target='_blank' rel='noreferrer'>CardsPal&apos;s Data Privacy Policy</a>.*</div>}
                                                    {...register("terms")} />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="terms"
                                                    render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                                />
                                            </Terms>
                                        </Row>
                                    }

                                    {props.custom?.willBeAttending &&
                                    <Row className='justify-content-center'>
                                        <SubTitle className='text-center'>Will you be attending this event ?</SubTitle>
                                        <SubmitButton
                                            type="submit"
                                            className="btn btn-primary btn-block"
                                            disabled={
                                                !isValid ||
                                                Object.keys(errors).length > 0
                                            }
                                        >Yes
                                        </SubmitButton>
                                        {props.custom?.attendanceCheck &&
                                            <DeclineButton
                                                onClick={() => setNotAttending(true)}
                                                className="btn btn-primary "
                                                disabled={
                                                    !isValid ||
                                                    Object.keys(errors).length > 0
                                                }
                                            >No</DeclineButton>
                                        }
                                    </Row>
                                    }
                                </>
                            }
                            <Popup
                                open={notAttending}
                                inputType="reason"
                                inputLabel="Reason"
                                title="Reason for not attending"
                                name="reason"
                                register={register}
                                watch={watch}
                                error={errorMessage}
                                isBusy={props.isDisabled}
                                submit={handleSubmit(onSubmitDecline, props.onError)}
                                cancel={() => (setNotAttending(false), setErrorMessage(""), unregister("reason"), unregister("reasonText"))}
                            />
                            <Popup
                                open={rsvped}
                                inputType={'message'}
                                title="Submission Successful"
                                message="Thank you for your time."
                                cancel={() => (setRsvped(false), unregister("reason"), unregister("reasonText"), props.custom?.redirectURL ? redirect(props.custom?.redirectURL) : null)}
                            />
                        </fieldset>
                    </Form>
                </Col>
            </Row>
        </React.Fragment>

    )
}

export default ContactInfo