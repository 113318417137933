import React, { useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux";
import { Row, Col, Modal, ModalFooter, ModalHeader, ModalBody, Button, Label, Alert, Spinner } from "reactstrap"

// import graphql helpers
import { update } from '../../../helpers/GraphQL/promotions'

// import store
import { setAmplitudeEvent  } from "store/actions";

const DealStatusForm = (props) => {
    const dispatch = useDispatch()
    const [transactionMessage, setTransactionMessage] = useState(null)
    const [processing, setProcessing] = useState(false)
    const [promo, setPromo] = useState({})


    useEffect(() => {
        setPromo(props.promotion)
        setProcessing(false)
        setTransactionMessage(null)
    }, [props.promotion])

    function handleChange(e) {
        const _promo = {...promo}
        _promo.valid = e.target.value
        setPromo(_promo)
    }

    async function handleSubmit() {
        try{
            setTransactionMessage(null)
            setProcessing(true)
            const _promo = { ...promo }
            
            // delete custom added fields
            delete _promo.store_ids
            delete _promo.deal_ids
            delete _promo.outlet_name

            await update(_promo)

            close()
        } catch (error) {
            console.error('dealStatusForm handleSubmit ',error)
            setTransactionMessage({msg:'Error updating deal status', type:'danger'})
            dispatch(setAmplitudeEvent('CMS Manage promotions page (deal status form) - handleSubmit function error', {'details': JSON.stringify(error)}))
        }
    }

    function close() {
        setProcessing(false)
        setTransactionMessage(null)

        props.close(promo, 'dealStatus')
    }

    return (
        <Modal
            size="md"
            isOpen={props.modalDealStatus}
            toggle={() => {
                props.showForm(false, 'dealStatus')
            }}
            centered={true}
        >
            <ModalHeader toggle={() => props.showForm(false, 'dealStatus')}>
                {props.formTitle}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Label>Do you wish to hide this promotion?</Label>
                    <Label style={{ fontStyle: "italic" }}>{promo.promotion}</Label>
                </Row>
                <Row style={{paddingTop:"10px"}}>
                    <Col style={{ flex: 1 }}>
                        <div className="form-check mb-3 center">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="dealStatus"
                                value="Y"
                                onChange={(e) => handleChange(e)}
                                checked={promo.valid === 'Y'}

                            />
                            <label
                                className="form-check-label margin-left3px"
                                style={{paddingTop: "4px"}}
                                htmlFor="dealStatus"
                            >
                                Show
                            </label>
                        </div>
                    </Col>
                    <Col style={{ flex: 1 }}>
                        <div className="form-check mb-3 center">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="dealStatus"
                                value="N"
                                onChange={(e) => handleChange(e)}
                                checked={promo.valid === 'N'}
                            />
                            <label
                                className="form-check-label margin-left3px"
                                style={{ paddingTop: "4px" }}
                                htmlFor="dealStatus"
                            >
                                Hide
                            </label>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {transactionMessage ?
                    <Alert color={transactionMessage.type}>
                        {transactionMessage.msg}
                    </Alert>
                    : null}

                {processing ?
                    <Spinner />
                    : null}

                <Button
                    disabled={processing}
                    color="primary"
                    onClick={(e) => handleSubmit(e)}
                >
                    Proceed
                </Button>
                {' '}
                <Button disabled={processing} onClick={() => props.showForm(false, 'dealStatus')}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    )

}

export default connect(null, {})(DealStatusForm);