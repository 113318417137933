import { API, graphqlOperation } from 'aws-amplify'
import { listVoucherUsers, voucherUserByDealId, voucherByTransactionId } from '../../graphql/queries'
import { updateVoucherUser } from '../../graphql/mutations'
import amplitude from 'amplitude-js';

export const getVoucherUserByCode = async (code) => {
    try {
        const params = {
            limit: 1000,
            filter: {
                code: {
                    eq : code
                }
            }
        }
        
        const result = await API.graphql(graphqlOperation(listVoucherUsers, params))
        return result.data.listVoucherUsers
    } catch (e) {
        amplitude.getInstance().logEventWithGroups('Error - getVoucherUserByCode', { 'details': e })
        console.error('Error on getVoucherUserByCode', e)
        throw e ? e.message : 'Please contact administrator'
    }
}

export const getRedeemVouchersByDeal = async (dealId) => {
    let deals = []
    let params = {
        limit: 1000,
        dealId: dealId,
        filter: {
            status: {
                eq : "1"
            }
        }
    }

    // get all deals belonging to merchnat
    do {
        const dealsResult = await API.graphql(graphqlOperation(voucherUserByDealId, params))
        const items = dealsResult.data.voucherUserByDealId.items

        if (items.length > 0) {
            deals = deals.concat(items)
        }

        const nextToken = dealsResult.data.voucherUserByDealId.nextToken
        params.nextToken = nextToken
    } while (params.nextToken)

    return deals
}
export const getCancelledVouchersByDealId = async (dealId) => {
    let deals = []
    let params = {
        limit: 1000,
        dealId: dealId,
        filter: {
            status: {
                eq: "9"
            }
        }
    }

    // get all deals belonging to merchnat
    do {
        const dealsResult = await API.graphql(graphqlOperation(voucherUserByDealId, params))
        const items = dealsResult.data.voucherUserByDealId.items

        if (items.length > 0) {
            deals = deals.concat(items)
        }

        const nextToken = dealsResult.data.voucherUserByDealId.nextToken
        params.nextToken = nextToken
    } while (params.nextToken)

    return deals
}


export const getVoucherUserByMasterId = async (masterId) => {
    try {
        const params = {
            limit: 1,
            masterId: masterId
        }
        
        let vouchers = []
        do {
            const result = await API.graphql(graphqlOperation(listVoucherUsers, params))
            const items = result.data.listVoucherUsers.items
            if (items.length > 0) {
                vouchers = vouchers.concat(items)
            }
            const nextToken = result.data.listVoucherUsers.nextToken
            params.nextToken = nextToken
        } while (params.nextToken)
        return vouchers
    } catch (e) {
        amplitude.getInstance().logEventWithGroups('Error - getVoucherUserByMasterId', { 'details': e })
        console.error('Error in getVoucherUserByMasterId', e)
    }
}

export const setVoucherRedeemedStatus = async (voucherUser, status='1') => {
    try {
        const item = {
            ...voucherUser,
            status: status,
            redeemTimestamp: status==='1' ?  Math.floor(Date.now() / 1000).toString() : "",
            updatedAt: new Date().toISOString()
        }
        const result = await API.graphql(graphqlOperation(updateVoucherUser, { input: item  }))
        //console.log('setVoucherRedeemedStatus', result.data.updateVoucherUser)
        return result.data.updateVoucherUser
    } catch (e) {
        amplitude.getInstance().logEventWithGroups('Error - setVoucherRedeemedStatus', { 'details': e })
        console.error('Error in setVoucherRedeemedStatus', e)
        return null
    }
}

export const getVoucherUsersByTransactionId = async (transactionId) => {
    try {
        const params = {
            transactionId: transactionId
        }
        // const params= {
        //     limit: 1000,
        //     filter: {
        //         transactionId: {
        //             eq : transactionId
        //         }
        //     }
        // }
        
        let vouchers = []
        do {
            const result = await API.graphql(graphqlOperation(voucherByTransactionId, params))
            const items = result.data.voucherByTransactionId.items
            if (items.length > 0) {
                vouchers = vouchers.concat(items)
            }
            const nextToken = result.data.voucherByTransactionId.nextToken
            params.nextToken = nextToken
        } while (params.nextToken)
        return vouchers
    } catch (e) {
        amplitude.getInstance().logEventWithGroups('Error - getVoucherUsersByTransactionId', { 'details': e })
        console.error('Error in getVoucherUsersByTransactionId', e)
    }
}