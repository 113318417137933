import {
    SET_SHOW_MODAL
} from './actionTypes';

const initialState={
    showModal : false
}

const setup = (state=initialState,action) => {
    switch(action.type){
        case SET_SHOW_MODAL:
            return {
              ...state,
              showModal: action.payload.showModal,
            };

        default:
            state = {...state};
            break;
    }
    return state;
}

export default setup;