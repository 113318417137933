import React, { useEffect, useState } from "react"
import { connect } from "react-redux";
import { Row, Col, Modal, ModalFooter, ModalHeader, ModalBody, Button, Label, Alert, Spinner } from "reactstrap"

// import graphql helpers
import { update } from '../../../helpers/GraphQL/outlet'

const DealStatusForm = (props) => {

    const [transactionMessage, setTransactionMessage] = useState(null)
    const [processing, setProcessing] = useState(false)
    const [outlet, setOutlet] = useState({})


    useEffect(() => {
        setOutlet({ ...props.outlet, valid: props.outlet.valid ? props.outlet.valid:'N'})
        setProcessing(false)
        setTransactionMessage(null)
    }, [props.outlet])

    function handleChange(e) {
        const _outlet = {...outlet}
        _outlet.valid = e.target.value
        setOutlet(_outlet)
    }

    async function handleSubmit() {
        try{
            setProcessing(true)
            delete outlet.deals
            await update(outlet)
            close()
        } catch (error) {
            console.error(error)
            setTransactionMessage('Error processing.')
        }
    }

    function close() {
        setProcessing(false)
        setTransactionMessage(null)
        props.close(outlet, true)
    }

    return (
        <Modal
            size="md"
            isOpen={props.modalOutletStatus}
            toggle={() => {
                props.showForm(false, 'outletStatus')
            }}
            centered={true}
        >
            <ModalHeader toggle={() => props.showForm(false, 'outletStatus')}>
                {props.formTitle}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Label>Do you wish to hide this outlet?</Label>
                    <Label style={{ fontStyle: "italic" }}>{outlet.outlet_name}</Label>
                </Row>
                <Row style={{paddingTop:"10px"}}>
                    <Col style={{ flex: 1 }}>
                        <div className="form-check mb-3 center">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="outletStatus"
                                value="Y"
                                onChange={(e) => handleChange(e)}
                                checked={outlet.valid === 'Y'}

                            />
                            <label
                                className="form-check-label margin-left3px"
                                style={{paddingTop: "4px"}}
                                htmlFor="outletStatus"
                            >
                                Show
                            </label>
                        </div>
                    </Col>
                    <Col style={{ flex: 1 }}>
                        <div className="form-check mb-3 center">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="outletStatus"
                                value="N"
                                onChange={(e) => handleChange(e)}
                                checked={outlet.valid !== 'Y'}
                            />
                            <label
                                className="form-check-label margin-left3px"
                                style={{ paddingTop: "4px" }}
                                htmlFor="outletStatus"
                            >
                                Hide
                            </label>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {transactionMessage ?
                    <Alert color={transactionMessage.type}>
                        {transactionMessage.msg}
                    </Alert>
                    : null}

                {processing ?
                    <Spinner />
                    : null}

                <Button
                    disabled={processing}
                    color="primary"
                    onClick={(e) => handleSubmit(e)}
                >
                    Proceed
                </Button>
                {' '}
                <Button disabled={processing} onClick={() => props.showForm(false, 'outletStatus')}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    )

}

export default connect(null, {})(DealStatusForm);