import React, { useState, useEffect, Suspense } from 'react';
import { Form, Image } from 'react-bootstrap';
import { Row, Col, CardBody, Container, Spinner } from "reactstrap"
import styled from 'styled-components';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { BiEditAlt } from 'react-icons/bi';
import { GoCheck } from 'react-icons/go';
import { FaWallet } from 'react-icons/fa';
import { IoIosPaper } from 'react-icons/io';
import { AiFillInfoCircle } from 'react-icons/ai';
import sha256 from 'crypto-js/sha256';

// custom components
// import { getUTMParams } from 'helpers/urlHelper';
import { remoteConfig, getHappeningOn24 } from 'utils';
import ContactInfo from './Components/ContactInfo_Edit_DnD';
import WarningPopup from './Components/WarningPopup';
import {
    SubTitle, CardContainer,
    SpinnerDiv, Description, DateTimeData, SeatingInfo, 
    Underline, TicketType, boxStyling, boxStylingNoPadding
} from './Components/Styled';
import { PROCESSING_ERROR, DND_OCT2023_CATEGORY, CANCEL_TRANSACTION_CODE } from 'utils/constants';
import { parseQueryString } from 'utils'
import {
    getDealInfo, getEvents, getWebFormGroup, getRemainingTickets, 
    getEnv, getWebFormByTransactionId, updateWebFormData, cancelTransaction
} from 'helpers/apiHelper';
import AccessDenied from 'components/Common/accessDenied';
import footerImg from '../../assets/images/PoweredByCardsPal.png'
import Popup from './Components/Popup'

const LazyBanner = React.lazy(() => import('./Components/Banner'))

const B2B_FamilyDay = (props) => {

    const [countryCode, setCountryCode] = useState([])
    const [warningMessage, setWarningMessage] = useState({ show: false, type: '', message: '' })
    const [isBusy, setIsBusy] = useState(true)
    const [dealInfo, setDealInfo] = useState([])
    const [eventInfo, setEventInfo] = useState([])
    const [happeningOn, setHappeningOn] = useState('')
    const [isAccessDenied, setAccessDenied] = useState(null);
    const [custom, setCustom] = useState([])
    const [qty, setQty] = useState(1)
    const [isLoading, setIsLoading] = useState(true)
    const [webForm, setWebForm] = useState(null)
    const [dealId, setDealId] = useState(null)
    const [popUpSetting, setPopUpSettings] = useState({show: false, inputType: '', title: '', message: ''})
    const eventId = props.match.params.eventId;
    const transactionId = props.match.params.transactionId
    const imagePath = `https://assets.${getEnv()}.cardspal.com/public/Events/banner/`
    const faviconPath = `https://assets.${getEnv()}.cardspal.com/public/Events/favicon/`
    const timestamp = Date.now()
    const webFormId = props.match.params.webFormId
    const queryStrings = parseQueryString(props.location.search)
    const adminHash = queryStrings?.adminHash
    const [disableEditing, setDisableEditing] = useState(false)
    const [groupNames, setGroupNames] = useState(null)
    const [disableEditingMessage, setDisableEditingMessage] = useState('')
    const [dimensions, setDimensions] = useState({ 
        height: window.innerHeight,
        width: window.innerWidth
    })
    const [remainingTickets, setRemainingTickets] = useState(0)

    const location = props.location;
    // const utmParams = getUTMParams(location);

    useEffect (()=>{
        window.addEventListener('resize', handleResize)

        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = `${faviconPath}${eventId}_favicon.ico`;

        return _ => {
            window.removeEventListener('resize', handleResize)
        }
    },[])

    useEffect(() => {
        getCustomSettings()
        getGroupNames()
        getWebForm(transactionId)
    }, [])

    useEffect(async () => {
        if (!dealId) return

        const result = await getRemainingTickets(dealId)
        setRemainingTickets(result.remaining || 0)
    }, [dealId])

    useEffect(() => {
        if (!dealId || custom.length === 0 ) return

        console.log('load event deal info')
        loadEventDealInfo(custom)
    }, [dealId, custom])

    useEffect(() => {
        if (eventInfo?.name) {
            document.title = eventInfo.name;
        }
    }, [eventInfo]);

    const handleResize = () => {
        let adjustment = window.document.body.offsetHeight
            setDimensions({
                height: adjustment ,
                width: window.innerWidth
            })
            window.parent.postMessage({cpHeight:adjustment}, '*')
    }

    const getGroupNames = async () => {
        let groups = await getWebFormGroup(DND_OCT2023_CATEGORY)
        if (!groups.error) {
            let parseGroups = JSON.parse(groups.body)
            setGroupNames(parseGroups)
        }
    }

    const getWebForm = async (transactionId) => {
        try {
            const response = await getWebFormByTransactionId(transactionId)
            if (response.statusCode === 200) {
                const webForm = JSON.parse(response.body)
                // console.log('getWebForm ', webForm, {transactionId})

                // validate hash else access denied on this page
                const isAdminUser = queryStrings?.hash ? false : true
                const u = webForm[0]?.userId
                const d = webForm[0]?.dealId
                // console.log({d}, {u})
                const actualHash = sha256(`${d};${u}`).toString() + '1' //we append 

                let saveAdminHash=''
                try {
                    const userData = JSON.parse(localStorage.getItem('dnd-user')) || {}
                    saveAdminHash = sha256(`${userData.email};${userData.sub}`).toString()
                } catch (e) {
                    console.error('error on localStorage get dnd-user', e)
                }
                // console.log({adminHash})
                // console.log(actualHash, queryStrings?.hash)
                if (isAdminUser && adminHash!==saveAdminHash) {
                    setAccessDenied(true)
                } else if (!isAdminUser) { // we force not editing of reservation and only for admin
                // } else if (!isAdminUser && queryStrings?.hash !== actualHash) {
                    setAccessDenied(true)
                } else if (webForm[0].status === CANCEL_TRANSACTION_CODE){
                    setAccessDenied(true)
                } else {
                    setAccessDenied(false)
                    setDealId(webForm[0].dealId)
                    setWebForm(webForm[0])
                }
            }
        } catch (e) {
            console.error('getWebForm: ',e)
            setAccessDenied(true)
        }
    }

    const loadEventDealInfo = async (customData) => {
        let deal = await getDealInfo(dealId)
        setDealInfo(deal)
        let updatedQty = 1
        if (customData?.dealInfo[dealId]?.isfixedQty && customData?.dealInfo[dealId]?.fixQty) {
            updatedQty = customData?.dealInfo[dealId]?.fixQty
            setQty(updatedQty)
        }
        if (customData?.dealInfo[dealId]?.isfixedQty && customData?.dealInfo[dealId]?.issueQty) {
            updatedQty = customData?.dealInfo[dealId]?.issueQty
            setQty(updatedQty)
        }

        let event = await getEvents(eventId)
        if (event?.data?.events?.freezeEvent==="1") {
            // event has been frozen so we dont allow editing
            setDisableEditing(true)
            setDisableEditingMessage('Editing is not allowed anymore at this time')
        }
        setEventInfo(event?.data?.events)

        if (deal.statusCode === '200' && event?.statusCode === '200') {
            let _datetime = getHappeningOn24(event.data?.events?.startTimestamp, event.data?.events?.endTimestamp)
            setAccessDenied(false)
            setHappeningOn(_datetime)
        } else {
            setAccessDenied(true)
        }
        setIsLoading(false)
        setIsBusy(false)
    }

    const getCustomSettings = async () => {
        let cust = await remoteConfig("b2bCustom")
        if (cust._value) {
            let parseCustJson = JSON.parse(cust._value)
            setCustom(parseCustJson[eventId])
            return (parseCustJson[eventId])
        } else return null
    }

    const onSubmitContactInfo = async (e) => {
        setIsBusy(true)
        // console.log("on submit", e)
        const response = await updateWebFormData(e)
        console.log('onSubmitContactInfo ', response, e)
        if (response.statusCode === 200) {
            const data = JSON.parse(response.body)
            setWebForm(data)
            setPopUpSettings({
                show: true,
                inputType: 'dnd-update-done',
                title:"Reservation updated",
                message:`Your reservation information for ${eventInfo.name} has been updated`,
                caller: 'update_reservation'
            })
        } else if (response.statusCode === 409) {
            // duplicate error
            setWarningMessage({ show: true, type: response.error, message: response.error })
        } else {
            setWarningMessage({ show: true, type: PROCESSING_ERROR })
        }
        setIsBusy(false)
    }

    const onChangeContactInfo = (e) => {
        const { value, name } = e.target
    }

    const onBlurContactInfo = async (e) => {
        const { value, name } = e.target
    }

    const onProceedCancelTicket = async(e) => {
        setIsBusy(true)
        setPopUpSettings({ show: false })
        const result = await cancelTransaction(transactionId)
        console.log('onCancelReservation ', {result})
        if (result.statusCode !== 200) {
            setWarningMessage({
                show: true,
                type: PROCESSING_ERROR
            })
        } else {
            setPopUpSettings({
                show: true,
                inputType: 'message',
                title:"Reservation status",
                message:`Your reservation for ${eventInfo.name} has been cancelled.`
            })
            setDisableEditing(true)
            setDisableEditingMessage('This ticket reservation has been cancelled')
        }
        setIsBusy(false)
    }

    const onCancelReservation = async (e) => {
        if (remainingTickets > 0) {
            setPopUpSettings({
                show: true,
                inputType: 'dnd-cancel-confirmation',
                title: 'Cancel ticket confirmation',
                message: `This will cancel your ticket reservation for ${eventInfo.name}. `
            })
        } else {
            setPopUpSettings({
                show: true,
                inputType: 'dnd-cancel-confirmation',
                title: 'Cancel ticket confirmation',
                message: `This will cancel your ticket reservation for ${eventInfo.name}. `,
                additionalText: 'Please note the event is full.  If you decide to re-register in the future you will be on the waiting list..'
            })
        }
    }

    const onCancelPopup = (e) => {
        setPopUpSettings({show: false})
        if (e && e==='update_reservation') {
            // dealInfo.happeningOn = getHappeningOn24(event.data?.events?.startTimestamp, event.data?.events?.endTimestamp)
            const queryString = `?action=${e}&dealId=${dealId}&eventId=${eventInfo?.pk}&qty=${qty}&userId=${encodeURIComponent(webForm?.userId)}&email=${webForm?.email}&transactionId=${webForm.transactionId}&groupName=${webForm?.groupName}`
            window.location.href = `/webform/${webFormId}/B2BPaymentSuccessful${queryString}`
        }

    }

    const tryAgain = () => {
        setIsBusy(false)
        setWarningMessage({
            show: false
        })
    }

    const RenderDetailsPage = () => {
        let dealCustom = custom?.dealInfo[dealId]
        const splitPromotion = dealInfo?.data?.promotion.split("\n")

        return (
            <div>
                <CardContainer style={boxStyling}>
                    <div>
                        <Row>
                            <Col><Image style={{ minHeight: "10vh", maxHeight: "40vh", borderRadius: "14px" }} fluid src={dealInfo?.data?.image_url} /></Col>
                            <Col style={{ marginLeft: 0, paddingLeft: 0 }}>
                                <div style={{ marginInline: 10 }}>
                                    {/* <div style={{ color: "#272833", fontWeight: 500, fontSize: 15, marginInline: 5, marginBottom: 5 }}>{eventInfo?.name}</div> */}
                                    <div style={{ color: "#272833", fontWeight: 800, fontSize: 17, marginInline: 5 }}>{dealInfo?.data?.promotion_caption}</div>
                                    <div style={{ display: "flex", flexDirection: "row", margin: 5 }}>
                                        <CheckIcon style={{ color: "#3158d6", marginTop: 2}} />
                                        <div style={{ color: "#A7A9BC", fontSize: 14, marginInlineStart: 10, marginInlineEnd: 5 }}><span style={{textDecoration: 'underline'}}><a style={{fontWeight: 800}} href={eventInfo?.faq} target="__blank">FAQ</a></span> and <span style={{textDecoration: 'underline'}}><a style={{fontWeight: 800, textDecorationLine:'underline'}} href={eventInfo?.terms} target="__blank">T&C</a></span></div>
                                    </div>
                                    {custom?.dealInfo[dealId]?.info1 &&
                                        <div style={{ display: "flex", flexDirection: "row", margin: 5 }}>
                                            <CheckIcon style={{ color: dealCustom?.color }} />
                                            <div style={{ color: "#6B6C7E", fontSize: 12, marginInlineStart: 10, marginInlineEnd: 5 }}>{custom?.dealInfo[dealId]?.info1}</div>
                                        </div>
                                    }
                                    {custom?.dealInfo[dealId]?.info2 &&
                                        <div style={{ display: "flex", flexDirection: "row", margin: 5 }}>
                                            <CheckIcon style={{ color: dealCustom?.color }} />
                                            <div style={{ color: "#6B6C7E", fontSize: 12, marginInlineStart: 10, marginInlineEnd: 5 }}>{custom?.dealInfo[dealId]?.info2}</div>
                                        </div>
                                    }
                                    {custom?.dealInfo[dealId]?.info3 &&
                                        <div style={{ display: "flex", flexDirection: "row", margin: 5 }}>
                                            <CheckIcon style={{ color: dealCustom?.color }} />
                                            <div style={{ color: "#6B6C7E", fontSize: 12, marginInlineStart: 10, marginInlineEnd: 5 }}>{custom?.dealInfo[dealId]?.info3}</div>
                                        </div>
                                    }
                                </div>
                                {dealCustom?.showTicketInfo ?
                                    <>
                                        {dealCustom?.type === 'vip' ?
                                            <div style={{ margin: 10 }}>
                                                <div style={{ color: "#A7A9BC", fontSize: 12, marginInlineStart: 10, marginInlineEnd: 5 }}>- Each unique promo code is eligible for one redemption only.</div>
                                                <div style={{ color: "#A7A9BC", fontSize: 12, marginInlineStart: 10, marginInlineEnd: 5 }}>- Enter your promo code at the payment page to enjoy one complimentary ticket.</div>
                                            </div>
                                            : dealCustom?.type === 'free' ?
                                                <div style={{ margin: 10 }} />
                                                :
                                                <div className="d-none d-sm-none d-xs-none d-md-block" style={{ margin: 10 }}>
                                                    <div style={{ color: "#A7A9BC", fontSize: 16, marginInlineStart: 10, marginInlineEnd: 5 }}>{dealInfo?.data?.promotion}</div>
                                                    <br />
                                                    <div style={{ color: "#A7A9BC", fontSize: 12, marginInlineStart: 10, marginInlineEnd: 5 }}>- Limited tickets, while stock last</div>
                                                    <div style={{ color: "#A7A9BC", fontSize: 12, marginInlineStart: 10, marginInlineEnd: 5 }}>- Have a promo code? Enter it at the payment page to enjoy further discount! </div>
                                                </div>
                                        }
                                    </> :
                                    <div className="d-none d-sm-none d-xs-none d-md-block" style={{ margin: 10, marginTop: 24 }}>
                                        {splitPromotion ?
                                            splitPromotion.map((text, i) =>
                                                <div style={{ color: "#000", fontSize: 16, marginInlineStart: 10, marginInlineEnd: 5 }} key={i}>{text}</div>)
                                            : <div style={{ color: "#A7A9BC", fontSize: 18, marginInlineStart: 10, marginInlineEnd: 5 }}>{dealInfo?.data?.promotion}</div>
                                        }
                                    </div>
                                }

                            </Col>
                        </Row>
                        <Row className="d-xl-none d-lg-none d-md-none d-sm-block d-xs-none">
                            <div style={{ marginTop: 10, marginBottom: 10 }}>
                                <div style={{ color: "#A7A9BC", fontSize: 16, marginInlineStart: 10, marginInlineEnd: 5 }}>{dealInfo?.data?.promotion}</div>
                                {dealCustom?.showTicketInfo &&
                                    <>
                                        <br />
                                        <div style={{ color: "#A7A9BC", fontSize: 12, marginInlineStart: 10, marginInlineEnd: 5 }}>- Limited tickets, while stock last</div>
                                        <div style={{ color: "#A7A9BC", fontSize: 12, marginInlineStart: 10, marginInlineEnd: 5 }}>- Have a promo code? Enter it at the payment page to enjoy further discount! </div>
                                    </>}
                            </div>
                        </Row>

                    </div>

                </CardContainer>
                <CardContainer style={boxStyling}>
                    <Row >
                        <Col>
                            <p>Location</p>
                        </Col>
                        <Col className='d-flex justify-content-end'>
                            <TicketType>{eventInfo?.location}</TicketType>
                        </Col>
                    </Row>
                    <Underline />
                    <Row >
                        <Col>
                            <p>Date & Time</p>
                        </Col>
                        <Col className='d-flex justify-content-end'>
                            <DateTimeData>{happeningOn}{custom?.seatingInfoText ? <SeatingInfo>{custom?.seatingInfoText}</SeatingInfo> : null}</DateTimeData>
                        </Col>
                    </Row>
                    <Underline />
                    <Row>
                        <Col>
                            <p>Ticket Type</p>
                        </Col>
                        <Col className='d-flex justify-content-end'>
                            <TicketType>{dealInfo?.data?.ticketType}</TicketType>
                        </Col>
                    </Row>
                </CardContainer>
                <CardContainer style={boxStyling}>
                    <CardBody>
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Form.Label>Quantity</Form.Label>
                                <div>
                                    <div style={{ color: "#6B6C7E", fontSize: 12 }}> Children aged 3 and below do not require a ticket</div>
                                    <div style={{ color: "#6B6C7E", fontSize: 12 }}> {"Min. " + (dealInfo?.data?.minQtyTx ? dealInfo?.data?.minQtyTx : 1) + " and max. " + dealInfo?.data?.purchaseLimitPerCustomer + " ticket(s)"} </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    <Form.Label style={{ backgroundColor: "#F1F2F5", borderRadius: 8, paddingInline: 32, paddingBlock: 5, marginTop: 5 }}>{qty}</Form.Label>
                                </Col>
                        </Row>
                    </CardBody>
                </CardContainer>
                <ContactInfo
                    countryCode={countryCode}
                    onSubmit={(e, pastQty) => onSubmitContactInfo(e, pastQty)}
                    onChange={(e) => onChangeContactInfo(e)}
                    onBlur={(e) => onBlurContactInfo(e)}
                    custom={custom}
                    isDisabled={isBusy}
                    styling={boxStylingNoPadding}
                    cpSignIn={true}
                    issueQty={custom?.dealInfo[dealId]?.issueQty || 1}
                    webFormId={webFormId}
                    dealId={dealId}
                    webForm={webForm}
                    cancelReservation = {(e) => onCancelReservation(e)}
                    disableEditing={disableEditing}
                    disableEditingMessage={disableEditingMessage}
                    groupNames={groupNames}
                    adminHash={adminHash}
                />
                <div>
                </div>
            </div>
        )
    }

    return (
        <React.Fragment>
            {isAccessDenied === null ? <></> :
                (isAccessDenied ? <AccessDenied screen="B2B" showBackToDashboard={false} /> :
                <>
                    <div style={{ backgroundColor: "#fff" }}>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Suspense fallback={<Image fluid src={imagePath + eventId + "_banner_lazy.jpg"} />}>
                                <LazyBanner imagePath={imagePath + eventId + "_banner.jpg?" + timestamp} custom={custom} />
                            </Suspense>
                        </div>
                        <Container style={{ marginTop: 50 }}>
                            <Row className="justify-content-center mt-3">
                                {isLoading ? null :
                                    custom?.dealInfo[dealId].active || !custom?.dealInfo[dealId].disable ?
                                        <Col>
                                            {eventInfo?.name ?
                                                <div>
                                                    <Description style={{ fontSize: 14 }}>{eventInfo?.name + " > " + dealInfo?.data?.promotion_caption + " > Purchase Details"}</Description>
                                                    <div style={{ maxWidth: 400, margin: "auto" }}>
                                                        <div style={{ display: "flex", flexDirection: "row", marginInline: 30, marginBottom: 10, marginTop: 25, justifyContent: "center" }}>
                                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                <Circle>
                                                                    <TickIcon style={{ color: "#fff", marginBlock: 5, marginInline: 4 }} />
                                                                </Circle>
                                                            </div>
                                                            <div style={{ width: 80, border: "0.5px solid #CDCED9", marginTop: 13, marginInline: 10, alignSelf: "center" }} className='mb-3' />
                                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                <Circle style={{ backgroundColor: "#722ED1" }}>
                                                                    <EditIcon style={{ color: "#fff", marginBlock: 4, marginInline: 4 }} />
                                                                </Circle>
                                                            </div>
                                                            <div style={{ width: 80, border: "0.5px solid #CDCED9", marginTop: 13, marginInline: 10, alignSelf: "center" }} className='mb-3' />
                                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                <Circle style={{ backgroundColor: "#BFBFC1" }}>
                                                                    <WalletIcon style={{ color: "#fff", marginBlock: 5, marginInline: 5 }} />
                                                                </Circle>
                                                            </div>
                                                            <div style={{ width: 80, border: "0.5px solid #CDCED9", marginTop: 13, marginInline: 10, alignSelf: "center" }} className='mb-3' />
                                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                <Circle style={{ backgroundColor: "#BFBFC1" }}>
                                                                    <NotesIcon style={{ color: "#fff", marginBlock: 5, marginInline: 5 }} />
                                                                </Circle>
                                                            </div>
                                                        </div>
                                                        <div style={{ display: "flex", flexDirection: "row", marginInline: 30, marginBottom: 30, justifyContent: "center" }}>
                                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                <div style={{ color: "#6B6C7E", fontSize: 11, width: 90, marginLeft: -30 }}>Select Tickets</div>
                                                            </div>
                                                            <div style={{ width: 80, marginTop: 13, alignSelf: "center" }} className='mb-3' />

                                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                <div style={{ color: "#6B6C7E", fontSize: 11, width: 60 }}>Edit info</div>
                                                            </div>
                                                            <div style={{ width: 80, marginTop: 13, alignSelf: "center" }} className='mb-3' />

                                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                <div style={{ color: "#6B6C7E", fontSize: 11, width: 50 }}>Confirm Reservation</div>
                                                            </div>
                                                            <div style={{ width: 80, marginTop: 13, alignSelf: "center" }} className='mb-3' />

                                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                <div style={{ color: "#6B6C7E", fontSize: 11, width: 50 }}>Confirmation</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className='text-center'>
                                                    <SubTitle className='m-1'>Ticket Registration</SubTitle>
                                                    <p>Please fill out the following form in order to reserve a ticket.</p>
                                                </div>
                                            }

                                            <div className='d-flex flex-row justify-content-between align-items-center'>
                                                <SubTitle style={{ marginLeft: 10 }}>Event</SubTitle>
                                            </div>
                                            
                                            <RenderDetailsPage />
                                                
                                        </Col>
                                        :
                                        <CardContainer style={{ backgroundColor: "#fff", maxWidth: 570, justifyContent: "center" }}>
                                            <div style={{ marginInline: 10, marginBlock: 20 }}>
                                                <div style={{ color: "#000", fontWeight: 800, fontSize: 20, marginBlock: 10, textAlign: "center" }}>SOLD OUT</div>
                                                <div style={{ fontWeight: 400, fontSize: 14, marginInline: 5, marginBlock: 15 }}>Thank you for your interest. Unfortunately, the tickets have been sold out.</div>
                                                <div style={{ fontWeight: 400, fontSize: 14, marginInline: 5, marginBlock: 15 }}>For any queries, please email <p style={{ color: "#2B70C7", textDecorationLine: "underline", textDecorationColor: "#2B70C7", display: "inline" }}>support@cardspal.com</p></div>
                                                <div style={{ fontWeight: 400, fontSize: 14, marginInline: 5, marginBlock: 15 }}>See you next time!</div>
                                            </div>
                                        </CardContainer>
                                }
                            </Row>
                            <Row style={{marginTop: 40}} className="justify-content-center">
                                <Col align="right">
                                    <div><a href={eventInfo?.terms} target='__blank'>Terms and Condition</a></div>
                                </Col>
                                <Col align="left">
                                    <div><a href={eventInfo?.faq} target='__blank'>Frequently Ask Questions</a></div>
                                </Col>
                            </Row>
                            <Row className="justify-content-center mt-3">
                                <Col className="col-sm-6 pd-3" align="center">
                                    <Image style={{ minHeight: "3vh", maxHeight: "5vh", marginBottom: "5vh" }} fluid src={footerImg} />
                                </Col>
                            </Row>
                           
                           {warningMessage.show &&
                            <WarningPopup
                                open={warningMessage.show}
                                warningType={warningMessage.type}
                                message={warningMessage.message}
                                tryAgain={() => tryAgain()}
                            />
                           }
                           {popUpSetting.show &&
                            <Popup
                                open={popUpSetting.show}
                                inputType={popUpSetting.inputType}
                                title={popUpSetting.title}
                                message={popUpSetting.message}
                                additionalText={popUpSetting.additionalText}
                                cancel={(e) => onCancelPopup(e)}
                                proceed={() => onProceedCancelTicket()}
                                caller={popUpSetting.caller}
                            />
                           }
                            {isBusy &&
                                <SpinnerDiv>
                                    <Spinner />
                                </SpinnerDiv>}
                        </Container>
                    </div>
                </>
                )
                
            }
        </React.Fragment>

    )
}
const CheckIcon = styled(BsFillCheckCircleFill)`
  color: #3158d6;
  font-size: 16px;
`
const TickIcon = styled(GoCheck)`
  color: #3158d6;
  font-size: 16px;
`
const EditIcon = styled(BiEditAlt)`
  color: #3158d6;
  font-size: 16px;
`
const WalletIcon = styled(FaWallet)`
  color: #3158d6;
  font-size: 14px;
`
const NotesIcon = styled(IoIosPaper)`
color: #3158d6;
font-size: 14px;
`
const InfoIcon = styled(AiFillInfoCircle)`
color: #fff;
font-size: 16px;
`
const Circle = styled.div`
    background: #2AD2C9;
    width: 25px;
    height: 25px;
    border-radius: 50%;
`
const Promotion = styled.div`
  font-size: 1.6vw;
  font-weight: 400;
  width:95%;
  `

export default B2B_FamilyDay