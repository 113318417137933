import { API, graphqlOperation } from 'aws-amplify'
import amplitude from 'amplitude-js';

// import constants
import {GENERIC_ERROR_MESSAGE} from '../../utils/constants'

 // import graphql
 import { termsMasterByType } from '../../graphql/queries'


 export const getTermsByType = async(type) => {
    try {
        const transaction = await API.graphql(graphqlOperation(termsMasterByType, {type: type}))
        const items = transaction.data.termsMasterByType.items
            
        items.sort(({order: a}, {order: b}) => parseInt(a) > parseInt(b) ? 1 : -1)
        
        return items
        
    } catch(e) {
        console.error('error on getTermsByType',e)
        amplitude.getInstance().logEventWithGroups('Error - getTermsByType', { 'details': e })
        throw e && e.errors.length > 0 ? e.errors.message :`${GENERIC_ERROR_MESSAGE}[Total Revenue Computation] `
    }
}
