import React, { useState } from "react"
import {useDispatch } from "react-redux"
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
} from "reactstrap"
import { Link } from "react-router-dom"

const LogoutMenu = props => {
    // Declare a new state variable, which we'll call "menu"
    const [menu, setMenu] = useState(false)
    const dispatch = useDispatch()
    
    const logout = ()=>{
        dispatch({type:'LOGOUT'})
    }
    return (
        <React.Fragment>
            <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="d-inline-block"
            >
                <DropdownToggle
                    className="btn header-item waves-effect"
                    id="page-header-user-dropdown"
                    tag="button"
                >
                    <i style={{fontSize:"20px", padding: "2px"}} className="mdi mdi-cog"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <Link to="/logout" onClick={()=>logout()} className="dropdown-item text-danger">
                        <i className="mdi mdi-power font-size-17 text-muted align-middle me-1 text-danger" />
                        <span>Logout</span>
                    </Link>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    )
}

export default LogoutMenu
