import {
    SELECTED_MERCHANT,
    USER_MERCHANTS
} from "./actionTypes"

const initialState = {
    error: "",
    loading: false,
    selectedMerchant: {},
    userMerchants: []
}

const merchant = (state = initialState, action) => {
    switch (action.type) {
        case SELECTED_MERCHANT:
            state = {
                ...state,
                selectedMerchant: action.payload.selectedMerchant,
            }
            break
        case USER_MERCHANTS:
            state = {
                ...state,
                userMerchants: action.payload.userMerchants,
            }
            break;
        default:
            state = { ...state }
            break
    }
    return state
}

export default merchant
