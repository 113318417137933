/* eslint-disable no-unused-vars */

import { takeEvery, fork, put, all } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed } from "./actions"

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user } }) {
  try {
    // TODO: Implement
    yield put(registerUserSuccessful(''))
    
  } catch (error) {
    yield put(registerUserFailed(error))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
