import React, { useEffect, useState } from 'react';

const FullCountDown = (targetDate) => {
  const countDownDate = targetDate * 1000;

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  ).toLocaleString('en-US', { minimumIntegerDigits: 2 });
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60)).toLocaleString('en-US', { minimumIntegerDigits: 2 });
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000).toLocaleString('en-US', { minimumIntegerDigits: 2 });

  return [days, hours, minutes, seconds];
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <div style={{ height: 25 }}>
      <div className="show-counter" style={{ display: "flex", position: "absolute", right: 12, top: 5, backgroundColor: "#B896E8", borderTopRightRadius: 10 }}>
        <p style={{ fontFamily: "Rubik", color: "#fff", fontSize: "15px", marginInline: 10, marginBlock: 5, width: "95%" }}>Coming soon in {days} : {hours} : {minutes} : {seconds}  </p>
      </div>
    </div>
  );
};

const ComingSoonCountDown = ({ targetDate, setComingSoon }) => {
  const [days, hours, minutes, seconds] = FullCountDown(targetDate);

  if (!targetDate || (Number(days) + Number(hours) + Number(minutes) + Number(seconds)) <= 0) {
    setComingSoon(false)
    return null;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export { ComingSoonCountDown };
