import React, { useEffect} from "react"
import { useSelector, useDispatch } from "react-redux";
import { Card, CardBody, CardImg, CardText, Button, CardSubtitle, Badge } from "reactstrap"

// import store actions
import { setAmplitudeEvent } from '../../store/actions'


// import utils
import  { STRIPE_BUTTON_LABEL } from  '../../../src/utils/constants'

import stripeLogo from '../../assets/images/stripe.png'

const StripeStatus = (props)=> {
    const dispatch = useDispatch()
    const selectedMerchant = useSelector(state => state.Merchant.selectedMerchant)

    useEffect(() => {
        if (!selectedMerchant || !selectedMerchant.merchant_id) return

        track()
    }, [selectedMerchant.merchant_id])

    function track() {
        let _ampTrack = {
          'merchant id': selectedMerchant.merchant_id,
          'merchant name':  selectedMerchant.merchant_name,
          'cta location': props.cta,
          'status': props.stripeStatus
        }
        dispatch(setAmplitudeEvent(`CMS Stripe Section  - Section Load `, {..._ampTrack}))
    }

    function handleClickStripeButton(buttonLabel) {
        let _ampTrack = {
            'merchant id': selectedMerchant.merchant_id,
            'merchant name':  selectedMerchant.merchant_name,
            'cta location': props.cta,
            'status': props.stripeStatus
          }
          if (STRIPE_BUTTON_LABEL.CONNECT_TO_STRIPE=== buttonLabel) {
            dispatch(setAmplitudeEvent(`CMS Stripe Section - Connect to our Stripe Account button`, {..._ampTrack}))
          } else {
            dispatch(setAmplitudeEvent(`CMS Stripe Section - View Stripe Platform button`, {..._ampTrack}))
          }
        props.onClick()
    }

    return(
        <Card className="boxStripeDashboard" style={{paddingLeft: "20px", paddingRight: "20px"}}>
            <CardImg
            src={stripeLogo}
            top
            width="100px"
            height="100px"
            style={{ objectFit:'contain', marginTop:'30px' }}
            />
            <CardBody className="text-center m-0 p-0">
            <p style={{ fontSize: 10 }}><Badge className={`${props.statusColor} rounded bg-primary`}>{props.stripeStatus}</Badge></p>
                <CardSubtitle
                    className="mb-2 text-muted"
                    style={{ fontSize:12 }}>
                    <h5><strong>Get onboard with us on Stripe!</strong></h5>
                </CardSubtitle>
                <CardText className=" m-0 p-0" style={{ fontSize:12 }}> Connect with CardsPal Stripe </CardText>
                <CardText style={{ fontSize:12 }}>account to start selling!</CardText>
                <Button className="mb-5 mt-3" disabled={props.disabled} color='primary' outline={props.outline} onClick={()=>handleClickStripeButton(props.buttonLabel)}>
                    {props.buttonLabel}
                </Button>
            </CardBody>
        </Card>
             
    )
}

export default StripeStatus