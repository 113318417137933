import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import store from "./store"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import * as apiHelper from "./helpers/apiHelper"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import "./i18n"

// Sentry monitoring
Sentry.init({
  dsn: "https://2aa677bc862047ce9f032cf9938f50fe@o1126181.ingest.sentry.io/6222490",
  integrations: [new BrowserTracing()],
  environment: apiHelper.getEnv(),
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const app = (
  <Provider store={store}>
    <App />
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
