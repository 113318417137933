/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalFooter, ModalHeader, ModalBody, Button } from "reactstrap"

// utils
import { openInNewTab } from "../../utils";

// store actions
import { setAmplitudeEvent } from '../../store/actions'

 
const StripePrompt = (props) => {
    const dispatch = useDispatch()
    const selectedMerchant = useSelector(state => state.Merchant.selectedMerchant)
    const [isAgree, setIsAgree] = useState(true)

    useEffect(() => {
        const _ampTrack = {
            'merchant id': selectedMerchant.merchant_id,
            'merchant name':  selectedMerchant.merchant_name,
            'cta location': props.cta
          }
          dispatch(setAmplitudeEvent(`CMS Stripe account set up pop up - pop up`, {..._ampTrack}))
    }, [])

    function handleChange(e) {
        if (e.target.checked) {
            const _ampTrack = {
                'merchant id': selectedMerchant.merchant_id,
                'merchant name':  selectedMerchant.merchant_name,
                'cta location': props.cta
            }
            dispatch(setAmplitudeEvent(`CMS Stripe account set up pop up - agreement checkbox clickthrough`, {..._ampTrack}))
            setIsAgree(false)
        } else {
            setIsAgree(true)
        }
    }

    function openExternalLink() {
        const _ampTrack = {
            'merchant id': selectedMerchant.merchant_id,
            'merchant name':  selectedMerchant.merchant_name,
            'cta location': props.cta
        }
        dispatch(setAmplitudeEvent(`CMS Stripe account set up pop up - Set up now button`, {..._ampTrack}))

        if (props.disableStripeButtonLInk) {
            props.disableStripeButtonLInk()
        }
        openInNewTab(props.stripeLink)
        props.close(null, false, 'stripePrompt')
    }

    function cancelStripe() {
        const _ampTrack = {
            'merchant id': selectedMerchant.merchant_id,
            'merchant name':  selectedMerchant.merchant_name,
            'cta location': props.cta
        }
        dispatch(setAmplitudeEvent(`CMS Stripe account set up pop up - Cancel button`, {..._ampTrack}))
        props.close(null, false)
    }
    
    return (
        <Modal
            size="xs"
            isOpen={props.modalStripePromptStatus}
            toggle={() => {
                props.close(null, false, 'stripePrompt')
            }}
            centered={true}
            backdrop={true}
        >
            <ModalHeader toggle={() => props.close(null, false, 'stripePrompt')}>Stripe account set up</ModalHeader>
            <ModalBody>
                <div className="form-check">
                    <input type="checkbox" className="form-check-input" id="agree" onChange={(e) => handleChange(e, 'agree')} />
                    <label className="form-check-label" htmlFor="online">By setting up a Stripe merchant account, you have read and agree to be bound by the <a target="_blank" href="https://stripe.com/en-gb-sg/connect-account/legal">Stripe Connected Account Agreement</a>,  the <a target="_blank" href="https://stripe.com/en-sg/legal"> Stripe Services Agreement</a> and Stripe’s <a target="_blank" href="https://stripe.com/en-sg/privacy">Privacy Policy.</a></label>                   
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                    disabled={isAgree}
                    color="primary"
                    onClick={(e) => openExternalLink(e)}
                >
                    Setup now
                </Button>
                {' '}
                <Button onClick={() => cancelStripe()}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default StripePrompt