import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useForm, Controller } from "react-hook-form";
import { Button, Form, Image } from 'react-bootstrap';
import { Row, Col, CardBody, Card, Alert, Container, Spinner } from "reactstrap"
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Title, SubTitle, Underline, CardContainer, DateTimeData, 
         TicketType, ErrMsg, Sup, SubmitButton, Terms } from './Styled';



const AlternateInfo = (props) => {

    const {custom, register, schema, control, errors, state, updateInitial} = props

    let dynamicKeys = {};
    const alternateInfoCustomization =  custom?.alternateInfoCustomization?.fields || null

    if(alternateInfoCustomization)
        Object.keys(alternateInfoCustomization).forEach((key) => {
            dynamicKeys[alternateInfoCustomization[key].name] = alternateInfoCustomization[key].name.toLowerCase().includes("email") ? 
                                                                alternateInfoCustomization[key].required ? Yup.string().email('Invalid email address').required('Required') : 
                                                                Yup.string().email('Invalid email address') : 
                                                                alternateInfoCustomization[key].required ? Yup.string().required("Required") : Yup.string()
        })
    const altSchema = Yup.object().shape({
        altSalutation: Yup.string(),  
        altFirstName: Yup.string(),
        altLastName: Yup.string(),
        altEmail: Yup.string().email('Invalid email address'),
        altCountryCode : Yup.string(),
        altPhoneNumber : Yup.string(),
        ...dynamicKeys
    })

    schema.concat(altSchema)


    const initialValues = {
        altSalutation: '',
        altFirstName: '',
        altLastName: '',
        altEmail:'',
        altCountryCode:'+65',
        altPhoneNumber:''
      }

    const viewRender =[]


    return(
        <React.Fragment>
                <Row className='justify-content-center'>
                    <Col>
                            <fieldset disabled={props.isDisabled}>
                                <CardContainer>
                                    <CardBody>
                                        <SubTitle>{custom?.alternateInfoCustomization?.label ?? 'Alternate Contact Info (Optional)'}</SubTitle>
                                        <Underline className='mb-3'/>
                                        {!alternateInfoCustomization && 
                                        <>
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Form.Label>Salutation</Form.Label>
                                            </Col>
                                            <Col xs={12} sm={3} md={3} lg={3} xl={3}>
                                                <Controller
                                                    name='altSalutation' 
                                                    control={control}
                                                    render={({ field: { onChange, value, name, ref } }) => {
                                                        const handleSelectChange = (e) => {
                                                            onChange(e?.value);
                                                        };
                                                        return(
                                                            <Select
                                                                inputRef={ref}
                                                                value={props.salutationList.find((e) => e.value === value)} 
                                                                options={props.salutationList}
                                                                placeholder='Select...'
                                                                onChange={handleSelectChange}
                                                                styles={{
                                                                    control: base => ({
                                                                    ...base,
                                                                    width: 120
                                                                    })
                                                                }}
                                                                isDisabled={props.isDisabled}
                                                            />
                                                        )}}
                                                    />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="altSalutation"
                                                    render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                                />
                                            </Col>
                                        </Row>
                                        <Underline className='mb-3'/>
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Form.Label>First name</Form.Label>
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Form.Control 
                                                    type="text" 
                                                    name="altFirstName"
                                                    placeholder="Enter your first name " 
                                                    className={errors.firstName && "error"}
                                                    autoComplete='off'
                                                    {...register("altFirstName")}
                                                    />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="altFirstName"
                                                    render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                                />
                                            </Col>
                                        </Row>
                                        <Underline className='mb-3'/>
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Form.Label>Last name</Form.Label>
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Form.Control 
                                                    type="text" 
                                                    name="altLastName"
                                                    placeholder="Enter your last name" 
                                                    className={errors.lastName && "error"}
                                                    autoComplete='off'
                                                    {...register("altFLastName")}
                                                    />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="altLastName"
                                                    render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                                />
                                            </Col>
                                        </Row>
                                        <Underline className='mb-3'/>
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Form.Label>Email Address</Form.Label>
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Form.Control 
                                                    type="text" 
                                                    name="lastName"
                                                    placeholder="Enter your email address" 
                                                    className={errors.lastName && "error"}
                                                    autoComplete='off'
                                                    {...register("altEmail")}
                                                    />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="altEmail"
                                                    render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                                />
                                            </Col>
                                        </Row>
                                        <Underline className='mb-3'/>
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Form.Label>Phone number</Form.Label>
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Row>
                                                    <Col xs={'auto'}>
                                                        <Controller
                                                            name='altCountryCode' 
                                                            control={control}
                                                            render={({ field: { onChange, value, name, ref } }) => {
                                                                const handleSelectChange = (e) => {
                                                                    onChange(e?.value);
                                                                };
                                                                return(
                                                                    <Select
                                                                        inputRef={ref}
                                                                        value={props.countryCode.find((e) => e.value === value)} 
                                                                        options={props.countryCode}
                                                                        placeholder='Select...'
                                                                        onChange={handleSelectChange}
                                                                        styles={{
                                                                            control: base => ({
                                                                            ...base,
                                                                            height: 34,
                                                                            minHeight: 34,
                                                                            width: 120,
                                                                            })
                                                                        }}
                                                                        isDisabled={props.isDisabled}
                                                                    />
                                                                )}}
                                                        />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="altCountryCode"
                                                            render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Control 
                                                            type="number" 
                                                            name="altPhoneNumber"
                                                            placeholder="Enter your phone number" 
                                                            className={errors.altPhoneNumber && "error"}
                                                            autoComplete='off'
                                                            {...register("altPhoneNumber")}
                                                        />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="altPhoneNumber"
                                                            render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Underline/>
                                        </>
                                        }
                                        {/* Custom setup */}
                                        {alternateInfoCustomization &&
                                        Object.keys(alternateInfoCustomization).forEach((key,i) => {
                                            viewRender.push(      
                                                <div key={"custom_"+i}>
                                                <Row>
                                                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                        <Form.Label>{key}<Sup>{alternateInfoCustomization[key].required ? "*" : null}</Sup></Form.Label>
                                                    </Col>
                                                    {alternateInfoCustomization[key]?.type === "select" &&
                                                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                        <Controller
                                                            name={alternateInfoCustomization[key].name} 
                                                            control={control}
                                                            render={({ field: { onChange, value, name, ref } }) => {
                                                                const handleSelectChange = (e) => {
                                                                    onChange(e?.value);
                                                                };
                                                                return(
                                                                    <Select
                                                                        inputRef={ref}
                                                                        value={alternateInfoCustomization[key]?.options.find((e) => e.value === value)} 
                                                                        options={alternateInfoCustomization[key]?.options}
                                                                        placeholder='Select...'
                                                                        onChange={handleSelectChange}
                                                                        isDisabled={props.isDisabled}
                                                                    />
                                                                )}}
                                                            />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name={alternateInfoCustomization[key].name}
                                                            render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                                        />
                                                    </Col>
                                                    }
                                                    {alternateInfoCustomization[key]?.type === "text" &&
                                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                <Form.Control 
                                                            type="text" 
                                                            name={alternateInfoCustomization[key].name}
                                                            placeholder={"Enter your " + key.toLowerCase()}
                                                            autoComplete='off' 
                                                            className={errors[alternateInfoCustomization[key].name] && "error"}
                                                            {...register(alternateInfoCustomization[key].name)}
                                                            />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name={alternateInfoCustomization[key].name}
                                                            render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                                        />
                                                    </Col>
                                                    }
                                                </Row>
                                                <Underline className='mb-3'/>
                                            </div>
                                            )
                                        })
                                        }
                                        {viewRender}
                                    </CardBody>
                                </CardContainer>
                            </fieldset>
                    </Col>
                </Row>    
        </React.Fragment>

    )
}

export default AlternateInfo