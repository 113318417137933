import React, { useEffect, useState } from "react"
import styled from "styled-components"
import PurchasableDealItem from "../Items/PurchasableDeal"
import { API } from "aws-amplify"
import { TICKET_API } from "utils/constants"
import { remoteConfig } from 'utils';

const RecommendedDealsCarousel = props => {
  const { dealId, hash } = props
  const [recommendedDeals, setRecommendedDeals] = useState([])
  const [config, setConfig] = useState(null)

  useEffect(() => {
    const getEventRecommendedDeals = async () => {
      const path = `/eventrecommendeddeals/deal/${dealId}?hash=${hash}`
      const result = await API.get(TICKET_API, path)
      const dealsResult = JSON.parse(result.body)
      await getCustomSettings()
      setRecommendedDeals(dealsResult)
    }

    dealId && hash && getEventRecommendedDeals()
  }, [dealId, hash])

  const getCustomSettings = async () => {
    let cust = await remoteConfig("dealRedirectLinks")
    if (cust._value) {
      let parseCustJson = JSON.parse(cust._value)
      setConfig(parseCustJson)
    }
  }
  const renderItems = () => {
    return recommendedDeals?.map((deal, index) => (
      <PurchasableDealItem key={`deal-${index}`} deal={deal} config={config} />
    ))
  }

  return recommendedDeals && recommendedDeals.length > 0 ? (
    <CarouselContainer>
      <CarouselTitle>Explore more</CarouselTitle>
      <CarouselScrollview>{renderItems()}</CarouselScrollview>
    </CarouselContainer>
  ) : null
}

const CarouselContainer = styled.div`
  width: 100%;
  max-width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
`

const CarouselScrollview = styled.div`
  max-width: 100vw;
  white-space: nowrap;
  overflow-x: auto;
  padding-right: 50px;
`

const CarouselTitle = styled.h2`
  font-size: 16px;
`

export default RecommendedDealsCarousel
