import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

import Breadcrumb from "./Breadcrumb/reducer";  

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"
import Register from "./auth/register/reducer";

// merchant
import Merchant from './merchant/reducer'

// setup / onboarding
import Setup from "./setup/reducer"

// message alerts
import MessageAlert from './alerts/reducer'

// user
import User from './user/reducer'


// config
import Config from './config/reducer'

import Amplitude from './amplitude/reducer'


const rootReducer = combineReducers({
  // public
  Layout,
   //Breadcrumb items
  Breadcrumb,
  Merchant,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Setup,
  Register,
  MessageAlert,
  User,
  Config,
  Amplitude
})

const allReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }
  return rootReducer(state, action)
}

export default allReducer
