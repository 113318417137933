import {
    SET_USER
} from './actionTypes';

const initialState = {
    user: null
}

const setup = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                user: action.payload
            };
        
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default setup;