import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { Form } from 'react-bootstrap';
import { Row, Col, CardBody, Spinner } from "reactstrap"
import { yupResolver } from '@hookform/resolvers/yup';
import styled from 'styled-components';
import * as Yup from 'yup';
import {
    SubTitle, Underline, SpinnerDiv, CardContainer, PriceLabel, SubmitButton
} from './Styled';
import { AiOutlinePlusCircle, AiOutlineMinusCircle, AiFillCloseCircle, AiOutlineCheckCircle } from 'react-icons/ai';
import { HiTicket } from 'react-icons/hi';
import { PROCESSING_ERROR } from 'utils/constants';
import {createPaymentIntent, getEnv } from '../../../helpers/apiHelper'

const DealInfo = (props) => {

    const schema = Yup.object().shape({
        promoCode: Yup.string()
    })

    const initialValues = {
        promoCode: props.prefill?.promoCode ?? ''
    }

    const idempotencyKey = props.idempotencyKey
    const [state] = useState(initialValues);
    const [isLoading, setIsLoading] = useState(true)
    const [paymentType, setPaymentType] = useState({})
    const [paymentMethod, setPaymentMethod] = useState("")
    const [paymentMethodClicked, setPaymentMethodClicked] = useState(0)
    const [errorOccur, setErrorOccur] = useState(false)
    const [isBusy, setIsBusy] = useState(false)

    useEffect(() => {
        if (props.dealInfo) {
            setIsLoading(false)
        }
    }, [])
    
    useEffect(() => {
        (paymentType?.applePay === true || paymentType?.googlePay === true) && (props.paymentPrice > 0) ? setPaymentMethod("wallet") : setPaymentMethod("card")
        props.step === 'payment' ? window.scrollTo(0, (window.innerHeight * 30 / 100)) : null
    }, [paymentType])

    useEffect(() => {
        props.step === 'payment' ? window.scrollTo(0, (window.innerHeight * 30 / 100)) : null
        // props.step === 'payment' && props.discountCodeSettings && !props.discountCampaign && props.contactInfo?.userId ? preloadCode() : null
    }, [props.step])

    useEffect(() => {
        (props.paymentPrice <= 0) ? setPaymentMethod("FREE") : (paymentType?.applePay === true || paymentType?.googlePay === true) ? setPaymentMethod("wallet") : setPaymentMethod("card")
    }, [props.paymentPrice])

    useEffect(() => {
        if (errorOccur) {
            props.warningPopup({ show: true, type: PROCESSING_ERROR })
            setPaymentMethodClicked(0)
            setErrorOccur(false)
        }
    }, [errorOccur])

    const { register, getValues, setValue, setError, clearErrors, formState: { errors } } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: state,
        resolver: yupResolver(schema)
    });

    const onSubmitPayment = async (paymentMethodId) => {
        await createIntent(paymentMethodId)
    }

    const createIntent = async (paymentMethodId) => {
        console.log('card-form props.data ', props)
        const intentPayload = {
          "env": getEnv(),
          "dataType": props.dataType || 'b2b',
          "qty": props.qty.toString(),
          "userId": props.userId,
          "dealId": props.dealId,
          "discountCampaignId": Number(props.discountCampaign?.qty) > 0 ?  props.discountCampaign?.campaignId || null : null,
          "purchasePriceTotal": props.paymentPrice,
          "eventsId": props.eventInfo?.pk || props.eventInfo?.data?.pk || null,
          "vouchers": props.reserveVouchers || [],
          "webFormData": props.webForm ? {...props.webForm} : { "webFormId": props.webFormId, ...props.contactInfo },
          "discountCodes": props.discountCodeReserved || [],
          "transactionType": 'web_purchased',
          "paymentMethod": { 'id': paymentMethodId },
          "webPayment": "true",
          "utm_source": props.utm_source,
          "utm_medium": props.utm_medium,
          "utm_campaign": props.utm_campaign,
          "utm_term": props.utm_term,
          "utm_content": props.utm_content,
          "hasAccount": props.hasAccount,
          "others": {"idempotencyKey": props.idempotencyKey},
          "voucherGroup": props.voucherGroup,
        }
        const result = await createPaymentIntent(intentPayload)
        console.log('create payment result ', result)
        if(result.statusCode === 200){
            props.goToPaymentSuccessful(result.data?.id)
        } else {
            setErrorOccur(true)
        }
        setIsLoading(false)
    }

    return (
        <React.Fragment>
            <Row className='justify-content-center'>
                <Col>
                    <Form onChange={(e) => props.onChange(e)} onSubmit={e => { e.preventDefault() }}>
                        <fieldset disabled={props.isDisabled}>
                            <CardContainer style={props.styling ?? { backgroundColor: "#fff" }}>
                                {props.step === "payment" ?
                                    <>
                                    <div style={{ margin: 10 }}>
                                        <SubTitle className='m-1'>{props.dealInfo?.data?.promotion_caption}</SubTitle>
                                        <Form.Label style={{ marginInline: 5 }}>{props.eventInfo?.name }</Form.Label>
                                        {
                                            props?.pastPurchaseQty && Number(props?.pastPurchaseQty) > 0 &&  
                                            <>
                                                <br/>
                                                <Form.Label style={{ marginInline: 5, fontStyle: "italic", color: "#239690" }}>{"You have bought " + props?.pastPurchaseQty + " tickets previously."}</Form.Label>
                                            </>
                                        }
                                    </div>
                                    </>
                                : null}
                                <CardBody>
                                    { isLoading ? null : 
                                        <>
                                        {props.type === 'bundle' ? null :

                                        props.step === 'payment' ? isLoading ? null :
                                        <Row>
                                            <Underline />
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                            <Form.Label>{`${props.qty} X ticket`}</Form.Label>
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <PriceLabel>S${props.paymentPrice}</PriceLabel>
                                            </Col>
                                        </Row>
                                        :
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Form.Label>Quantity</Form.Label>
                                                {!props.isQtyFixed && props.type === 'single' ?
                                                    <div>
                                                        <div style={{ color: "#6B6C7E", fontSize: 12 }}> Children aged 3 and below do not require a ticket</div>
                                                        <div style={{ color: "#6B6C7E", fontSize: 12 }}> {"Min. " + (props.dealInfo?.data?.minQtyTx ? props.dealInfo?.data?.minQtyTx : 1) + " and max. " + props.dealInfo?.data?.purchaseLimitPerCustomer + " ticket(s)"} </div>
                                                    </div> : null
                                                }
                                            </Col>
                                            {props.step === 'payment' ?
                                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <PriceLabel>{props.qty}</PriceLabel>
                                                </Col>
                                                :
                                                (props.isQtyFixed && 
                                                    <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                                        <Form.Label style={{ backgroundColor: "#F1F2F5", borderRadius: 8, paddingInline: 32, paddingBlock: 5, marginTop: 5 }}>{props.qty}</Form.Label>
                                                    </Col>
                                                )
                                            }
                                        </Row>
                                    }
                                    {props.step === "payment" &&
                                        <>
                                        <Underline />    
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Form.Label style={{ fontWeight: "700" }}>Amount payable</Form.Label>
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <PriceLabel style={{ color: "#722ED1", fontSize: 16 }}>{"S$" + props.paymentPrice}</PriceLabel>
                                            </Col>
                                        </Row>
                                        </> 
                                    }
                                    </>
                                    }
                                </CardBody>
                            </CardContainer>
                            {props.step === "payment" &&
                                <>
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "transparent" }}>
                                        <div style={{ textAlign: "center", marginBottom: "3%", width: '100%' }}>
                                            <SubmitButton style={{ marginTop: 20 }}
                                                title="Please note Late Cancellation / No-Show penalties (SG$100)"
                                                onClick={() => { setIsBusy(true); onSubmitPayment(paymentMethod)}}
                                                type="button"
                                                className="btn btn-primary btn-block"
                                                disabled={isBusy || paymentMethodClicked > 0}
                                            >Confirm Reservation
                                            </SubmitButton>
                                        </div>
                                    </div>

                                    {(isLoading || isBusy )&&
                                    <SpinnerDiv>
                                        <Spinner />
                                    </SpinnerDiv>}
                                </>
                            }

                        </fieldset>
                    </Form>
                </Col>
            </Row>
        </React.Fragment >

    )
}

const PlusIcon = styled(AiOutlinePlusCircle)`
  color: #722ED1;
  margin-inline: 10px;
  font-size: 18px;
    cursor: pointer;

`
const MinusIcon = styled(AiOutlineMinusCircle)`
  color: #722ED1;
  margin-inline: 10px;
  font-size: 18px;
    cursor: pointer;
`
const CouponIcon = styled(HiTicket)`
  color: #D3ADF7;
  font-size: 18px;
`
const CrossIcon = styled(AiFillCloseCircle)`
  color: #272833;
  font-size: 18px;
`
const TickIcon = styled(AiOutlineCheckCircle)`
  color: #2AD2C9;
  font-size: 18px;
`

export default DealInfo