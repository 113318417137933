/* eslint-disable no-unused-vars */
import { takeEvery, fork, put, all } from "redux-saga/effects"

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes"
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions"


//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    // const response = yield call(amplifyBackend.forgetPwd, {
    //   email: user.email,
    // })
    // if (response) {
    // TODO: send an email of something to the user by calling some API
    yield put(
      userForgetPasswordSuccess(
        "Reset link are sended to your mailbox, check there first"
      )
    )
    // }
  } catch (error) {
    yield put(userForgetPasswordError(error))
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
