import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Tools
import ManageOutlets from "../pages/Tools/ManageOutlets"
import ManagePromotions from "../pages/Tools/ManagePromotions"
import SellVouchers from "../pages/Tools/SellVouchers"
import RedeemVouchers from "../pages/Tools/RedeemVouchers"
import ScanHistory from "../pages/Tools/ScanHistory"
import RedemptionList from "../pages/Tools/RedemptionList"

//Setup
import SetupCompanyProfile from "../pages/Setup/SetupCompanyProfile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import EmailVerification from "pages/Authentication/EmailVerification"
import ForgetPwdReset from "../pages/Authentication/ForgetPasswordReset"
import EmailVerificationUnconfirmedUser from "pages/Authentication/EmailVerificationUnconfirmedUser"

// Sales
import SalesSummary from '../pages/Sales/summary'

// Transactions
import TransactionsSummary from '../pages/Transactions/summary'

//Support
import UserGuide from '../pages/Support/UserGuide'
import Faq from '../pages/Support/Faq'
import ContactUs from '../pages/Support/ContactUs'

//Settings
import ManageProfile from '../pages/Settings/ManageProfile'
import ManagePlans from '../pages/Settings/ManagePlans'

// Dashboard
import Dashboard from "../pages/Dashboard/index"

//Guest or non-auth
import GuestFaq from '../pages/Guest/faq'

//Webform
import Contacts from '../pages/Webform/Contacts'
import DnD from '../pages/Webform/D&D'
import B2B from '../pages/Webform/B2B'
import B2B_advocate from '../pages/Webform/B2B_MCR'
import B2BRegistrationSuccessful from '../pages/Webform/B2BRegistrationSuccessful'
import B2BwPayment from '../pages/Webform/B2BwPayment'
import B2BPaymentSuccessful from '../pages/Webform/B2BPaymentSuccessful'
import ExternalComponent from '../pages/Webform/ExternalComponent'
import TicketsLanding from '../pages/Webform/TicketsLanding'
import EventsLanding from '../pages/Webform/EventsLanding'
import B2B_ActivEdge from '../pages/Webform/B2B_ActivEdge'
import B2B_Legacy from '../pages/Webform/B2B_Legacy'
import B2B_template from '../pages/Webform/B2B_template'
import B2BPaymentSuccessful_ActivEdge from '../pages/Webform/B2BPaymentSuccessful_ActivEdge'
import B2BPaymentSuccessful_Legacy from '../pages/Webform/B2BPaymentSuccessful_Legacy'
import B2BPaymentSuccessful_template from '../pages/Webform/B2BPaymentSuccessful_template'
import B2B_FamilyDay from '../pages/Webform/B2B_FamilyDay'
import B2BPaymentSuccessful_FamilyDay from '../pages/Webform/B2BPaymentSuccessful_FamilyDay'
import AppFormLanding_ActivEdge from '../pages/Webform/AppFormLanding_ActivEdge'
import AppFormLanding_Legacy from '../pages/Webform/AppFormLanding_Legacy'
import Edit_DnD from '../pages/Webform/D&D_EditUser'

//Checkin
import TicketForm from '../pages/Checkin/TicketForm'
import MasterList from '../pages/Checkin/MasterList'
import CheckinLandingScreen from "../pages/Checkin/CheckinLandingScreen"

//Group info for D&D
import GroupInfo from '../pages/GroupInfo/ViewGroupInfo'

//Events
import ManageEvents from "../pages/Tools/EventListing"
import EventTickets from "../pages/Tools/EventTickets"

// PayNow
//import PayNow from '../pages/PayNow'

// DnD
import DnD_LandingPage from '../pages/Webform/D&D_TicketsLanding'
import DnD_GroupMembers from '../pages/Webform/D&D_GroupMembers'
import DnD_Admin from '../pages/Webform/D&D_Admin'
// import DnD_UpdateGroup from '../pages/Webform/D&D_UpdateGroup'
import DnD_Main from '../pages/Webform/D&D_Main'
import DnD_SuccessPage from '../pages/Webform/D&D_SuccessPage'
import DnD_ViewUser from '../pages/Webform/D&D_ViewUser'

const userRoutes = [
  { path: "/dashboard", component: Dashboard },

  // tools
  { path: "/manage-outlets", component: ManageOutlets },
  { path: "/manage-promotions", component: ManagePromotions },
  { path: "/sell-vouchers", component: SellVouchers },

  // sales
  // { path: "/sales-summary", component: SalesSummary },

    // sales
    { path: "/transactions-summary", component: TransactionsSummary },

  // support
  { path: "/user-guide", component: UserGuide },
  { path: "/faq", component: Faq },
  { path: "/contact-us", component: ContactUs },

  // settings
  { path: "/manage-profile", component: ManageProfile },
  { path: "/manage-plans", component: ManagePlans },

  // setup
  { path: "/setup-company-profile", component: SetupCompanyProfile },

  // // //profile
  { path: "/profile", component: UserProfile },

  // Redemption
  { path: "/redeem", component: RedeemVouchers },
  { path: "/redeem/history", component: ScanHistory },
  { path: "/redeem/list", component: RedemptionList },

  //Events
  { path: "/events", component: ManageEvents },
  { path: "/events/tickets", component: EventTickets },
  // { path: "/redeem/list", component: RedemptionList },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/email-verification", component: EmailVerification },
  { path: "/password-reset", component: ForgetPwdReset },
  { path: "/email-verification-unconfirmeduser", component: EmailVerificationUnconfirmedUser },
  //{ path: "/faqs", component: GuestFaq }
]

const guestRoutes = [
  { path: "/faqs", component: GuestFaq }
]

const webformRoutes = [
  { path: "/webform/0001/appform", component: Contacts },
  { path: "/webform/0002/appform", component: DnD },
  { path: "/webform/0003/:eventId/:dealId/:payload", component: B2B },
  { path: "/webform/0003/:eventId/:dealId/", component: B2B },
  { path: "/webform/0003/B2BRegistrationSuccessful", component: B2BRegistrationSuccessful },
  { path: "/webform/0004/:eventId/external/SeedlyComponent", component: ExternalComponent },
  { path: "/webform/0004/:eventId/:dealId/", component: B2BwPayment },
  { path: "/webform/0004/B2BPaymentSuccessful", component: B2BPaymentSuccessful },
  { path: "/webform/0005/:eventId/:dealId/:payload", component: B2B_advocate },
  { path: "/webform/0005/:eventId/:dealId/", component: B2B_advocate },
  { path: "/webform/0005/B2BRegistrationSuccessful", component: B2BRegistrationSuccessful },
  { path: "/webform/0006/appform", component: AppFormLanding_ActivEdge },
  { path: "/webform/:webFormId/:eventId/LegacyLanding", component: TicketsLanding },
  { path: "/webform/0007/:eventId/:dealId/", component: B2B_Legacy },
  { path: "/webform/0007/B2BPaymentSuccessful", component: B2BPaymentSuccessful_Legacy },
  { path: "/webform/0007/appform", component: AppFormLanding_Legacy },

  // D&D October 2023
  { path: "/webform/0009/:eventId/DinnerAndDanceLanding", component: DnD_LandingPage },
  { path: "/webform/:webFormId/:eventId/dnd-view-user/phonesearch", component: DnD_ViewUser},
  { path: "/webform/0009/:eventId/:dealId/", component: DnD_Main },
  { path: "/webform/0009/B2BPaymentSuccessful", component: DnD_SuccessPage },
  { path: "/webform/view-group-members/:groupName", component: DnD_GroupMembers},
  { path: "/webform/dnd-admin", component: DnD_Admin},
  // { path: "/webform/dnd-update-group/:groupName", component: DnD_UpdateGroup},
  { path: "/webform/edit/:webFormId/:eventId/:transactionId/", component: Edit_DnD },
  
  { path: "/webform/:webFormId/:eventId/FamilyDayLanding", component: TicketsLanding },
  { path: "/webform/0008/:eventId/:dealId/", component: B2B_FamilyDay },
  { path: "/webform/0008/B2BPaymentSuccessful", component: B2BPaymentSuccessful_FamilyDay },

  
  { path: "/webform/:webFormId/:eventId/:dealId/", component: B2B_FamilyDay },
  
  // { path: "/webform/:webFormId/:eventId/ActivEdgeLanding", component: TicketsLanding },
  // { path: "/webform/:webFormId/:eventId/:dealId/", component: B2B_ActivEdge },
  { path: "/webform/:webFormId/B2BPaymentSuccessful", component: B2BPaymentSuccessful_ActivEdge },

  //new template 
  { path: "/event/:eventUniqueString/item/:dealUniqueString", component: B2B_template },
  { path: "/event/PaymentSuccessful", component: B2BPaymentSuccessful_template },
  { path: "/event/:eventUniqueString", component: EventsLanding },
  
]

const eventRoutes = [
  { path: "/checkin/success", component: CheckinLandingScreen },
  { path: "/checkin/masterlist/:transactionId", component: MasterList },
  { path: "/checkin/:masterId", component: TicketForm },
  { path: "/groupinfo/:groupName", component: GroupInfo }
]

export { userRoutes, authRoutes, guestRoutes, webformRoutes, eventRoutes }