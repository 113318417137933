/* eslint-disable no-unused-vars */

import React, { useState } from "react"
import { Row, Col, Modal, ModalFooter, ModalHeader, ModalBody, FormGroup, Button, Label, Input } from "reactstrap"
import { STARTER_PLAN_1, STARTER_PLAN_2, STARTER_PLAN_3 } from "utils/constants"

const ModalPlanLimit = (props) => {
    const [selectedOption, setSelectedOption] = useState(null)
    const [isProceedDisabled, setIsProceedDisabled] = useState(true)

    const proceed = () => {
        props.toggleModal('proceed', selectedOption)
    }
    const closeModal = () => {
        props.toggleModal('close')
    }

    const handleOptionChange = (e) => {
        setIsProceedDisabled(false)
        setSelectedOption(e.target.value)
    }

    return(
        <Modal
        toggle={()=>props.toggleModal()}
        isOpen={props.showModal}
        centered={true}
        >
            <ModalHeader
            //toggle={()=>props.showModal(false)}
             close={<button className="close" onClick={closeModal}></button>}
            >
                Plan limits alert!
            </ModalHeader>
                <ModalBody>
                    How many brands do you own?
                    <Row className="mt-2">
                        <Col>
                            <FormGroup check>
                                    <Input
                                        name="starterPlan"
                                        value={STARTER_PLAN_1}
                                        type="radio"
                                        onChange={(e) => handleOptionChange(e)}
                                    />
                                    {' '}
                                    <Label check>
                                        1
                                    </Label>
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup check>
                                    <Input
                                        name="starterPlan"
                                        value={STARTER_PLAN_2}
                                        type="radio"
                                        onChange={(e) => handleOptionChange(e)}
                                    />
                                    {' '}
                                    <Label check>
                                        2
                                    </Label> 
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup check>
                                    <Input
                                        name="starterPlan"
                                        value={STARTER_PLAN_3}
                                        type="radio"
                                        onChange={(e) => handleOptionChange(e)}
                                    />
                                    {' '}
                                    <Label check>
                                        3
                                    </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    onClick={()=>proceed()}
                    disabled={isProceedDisabled}
                >
                    Proceed
                </Button>
                {' '}
                <Button onClick={()=>closeModal()}>
                    Cancel
            </Button>
            </ModalFooter>
        </Modal>
    )
}

export default ModalPlanLimit