
export const getStripeStatusColor = (stripeStatus) => {
    let color
    switch (stripeStatus) {
        case 'Not onboarded':
            color = 'blueBg'  // blue
            break;
        case 'Completed':
            color = 'greenBg' // green
            break;
        default:
            color = 'purpleBg' // purple
            break;
    }

    return color
}