import React, { useEffect, useState } from "react"
import { connect, useSelector, useDispatch } from "react-redux";
import { Row, Col, Modal, ModalFooter, ModalHeader, ModalBody, FormGroup, Button, Label, Alert, Spinner } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

// import components
import CMSAddress from './components/cmsAddress'

// import utils
import { guidGenerator, updateGeohash } from '../../../utils'

// import graphql functions
import { create, update } from '../../../helpers/GraphQL/outlet'

// import store
import { setAmplitudeEvent } from '../../../store/actions'

const OutletForm = (props) => {
    const dispatch = useDispatch()
    const protectedAttributes = ['phone', 'outletWebsite']
    const selectedMerchant = useSelector(state => state.Merchant.selectedMerchant)
    const [outlet, setOutlet] = useState({})
    const [processing, setProcessing] = useState(false)
    const [transactionMessage, setTransactionMessage] = useState(null)
    const [validUnitNum, setValidUnitNum] = useState(true)
    const [processingLngLat, setProcessingLngLat] =  useState(false)
    
    useEffect(() => {
        setOutlet(props.outlet)
        setProcessing(false)
        setTransactionMessage(null)
    }, [props.outlet])

    function handleChange(e, fieldName){
        setTransactionMessage(null)

        const item = {...outlet}
        if (e.target.type === 'checkbox') {
            if (fieldName === 'valid') {
                item[fieldName] = e.target.checked ? 'Y' : 'N'
            } else {
                item[fieldName] = e.target.checked ? 'True' : 'False';
            }
        } else {
            item[fieldName] = e.target.value
        }

        if (fieldName === 'mcc') {
            // empty string split will return [""]. use filter to filter empty string.
            item.mcc = e.target.value.split('\n').filter((s) => s.trim() !== '')
        }
        
        let blkAptNum = item.blkAptNum || ''
        let roadName =  item.roadName ? ', ' + item.roadName : ''
        let buildingName = item.buildingName ? ', ' + item.buildingName : ''
        let unitNum = item.unitNum ? ', ' + item.unitNum : ''
        let country =  item.address ? ', Singapore' : ''
        let postal_code = item.postal_code ? ' ' + item.postal_code : ''
        
        let combineAddress = blkAptNum.concat(roadName, buildingName, unitNum, country, postal_code)
        let address = blkAptNum.length > 0 ? combineAddress : combineAddress.replace(', ', '') //remove extra comma
        item.address = address

        if (e.target.type === 'checkbox') {
            if (fieldName === 'subc_online') {
                if (e.target.checked) {
                    clearFields(item)
                    
                }else{
                    item.address = ''
                }
            }
            
        }
        
        if (protectedAttributes.includes(fieldName)) {
            // eslint-disable-next-line no-prototype-builtins
            if (!item.hasOwnProperty('protectedAttributes')) {
                item['protectedAttributes']= {}
            } else if (typeof item.protectedAttributes === 'string') {
                item['protectedAttributes'] = JSON.parse(item['protectedAttributes'])
            }
            item['protectedAttributes'][fieldName] = '1'
            item['protectedAttributes'] = JSON.stringify(item['protectedAttributes'])
        }

        setTransactionMessage(null)
        setOutlet(item)
    }

    const clearFields = (item) => {
        
        item.blkAptNum = ''
        item.roadName = ''
        item.buildingName = ''
        item.unitNum = ''
        item.country = ''
        item.postal_code = ''
        item.address = ''
    }

    // function handleAddressChange(address, latlng) {
    //     let item = { ...outlet }
    //     item.address = address

    //     // if address is removed, then need to reset geohashlong, geohashshort, geohashsix
    //     if (!address) {
    //         item.geohashlong = '';
    //         item.geohashshort = '';
    //         item.geohashsix = '';
    //         item.latitude = 'null';
    //         item.longitude = 'null';
    //     } else if (latlng) {
    //         item.latitude = latlng.lat
    //         item.longitude = latlng.lng

    //         const { geohashlong, geohashshort, geohashsix } = updateGeohash(item)
    //         item.geohashlong = geohashlong;
    //         item.geohashshort = geohashshort;
    //         item.geohashsix = geohashsix;
    //     }
    //     setTransactionMessage(null)
    //     setOutlet(item)
    // }

    function handlePostalCodeChange(postal_code, latlng) {
        let item = { ...outlet }
        let address = item.address ? item.address.replace(item.postal_code, '') : ''
        item.address = address.trim().concat(' ', postal_code)

        // if postal code is removed, then need to reset geohashlong, geohashshort, geohashsix
        if (!postal_code) {
            item.geohashlong = '';
            item.geohashshort = '';
            item.geohashsix = '';
            item.latitude = 'null';
            item.longitude = 'null';
            item.postal_code = ''

        } else if (latlng) {
            item.latitude = latlng.lat
            item.longitude = latlng.lng

            const { geohashlong, geohashshort, geohashsix } = updateGeohash(item)
            item.geohashlong = geohashlong;
            item.geohashshort = geohashshort;
            item.geohashsix = geohashsix;
            item.postal_code = postal_code
        }
        
        setTransactionMessage(null)
        setOutlet(item)
    }

    async function handleSubmit(e) {
        e.preventDefault()
        e.stopPropagation()

        if (!outlet.outlet_name) {
            setTransactionMessage({msg:'Outlet name is required', 'type': 'danger'})
            return
        }

        setProcessing(true)
        try {
            if (props.mode === 'create') {
                await handleCreate(e)
            } else if (props.mode === 'update') {
                await handleUpdate(e)
            }
            setProcessing(false)
            close()
        } catch (error) {
            console.error('error on handleSubmit ',error)
            setTransactionMessage(error)
            setProcessing(false)
            dispatch(setAmplitudeEvent('CMS Manage Outlets page - handle submit error', {'details': JSON.stringify(error)}))
        }
    }

    async function handleCreate() {
        // eslint-disable-next-line no-useless-catch
        try {
            const item = { ...outlet  }

            if (!item.merchant_id) {
                item.merchant_id = props.merchantId
            }

            item.store_id = guidGenerator()
            item.merchant_unique_id = `${item.merchant_id}_${item.store_id}`
            item.merchantImageUrlV2 = selectedMerchant.merchantImageUrlV2
            item.merchant_name = selectedMerchant.merchant_name
            item.defaultImageUrl = selectedMerchant.defaultImageUrl
            item.country = 'Singapore' //default
            const createdOutlet = await create(item)
            delete createdOutlet.deals

            setOutlet(createdOutlet)
            setTransactionMessage({ msg: 'Success', type: 'info' })
        } catch(error) {
            throw error
        }
    }

    async function handleUpdate() {
        // eslint-disable-next-line no-useless-catch
        try {
            const item = {...outlet, country: 'Singapore'}
            delete item.deals
            const updatedOutlet = await update(item)
            
            setOutlet(updatedOutlet)
            
            delete updatedOutlet.deals
        } catch (error) {
            throw error
        }
    }

    function close() {
        // do clean up since this is a modal and it might show old message when open again
        setProcessing(false)
        setTransactionMessage(null)
        props.close(outlet, true)
    }

    const funcValidateUnitNum = (unitNum) => {
        if (unitNum) {
            let splitUnit = unitNum.split("-")
            let getNum = splitUnit[0].replace(/\D/g, '')
            
            if (getNum.length <= 2) {
                setValidUnitNum(true)
            }else{
                setValidUnitNum(false)
            }
        }   
    }

    return (
        <Modal
            size="xl"
            isOpen={props.modalFormStatus}
            toggle={() => {
                props.showForm(false)
            }}
            centered={true}
        >
            <ModalHeader toggle={() => props.showForm(false)}>
                {props.formTitle}
            </ModalHeader>
            <AvForm className="needs-validation" onValidSubmit={(e)=>handleSubmit(e)}>
            <ModalBody>
                <FormGroup style={{ "marginBottom": "10px" }}>
                    <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="online" defaultChecked={outlet.subc_online === 'True' ? true : false} onChange={(e) => handleChange(e, 'subc_online')}/>
                        <label className="form-check-label" htmlFor="online">Online</label>
                    </div>
                </FormGroup>
                <Row>
                    <Col md={6}>
                        <div className="mb-3">
                            <Label htmlFor="outletName">Outlet Name *</Label>
                            <AvField
                                name="outletName"
                                placeholder=""
                                type="text"
                                errorMessage="Enter Outlet Name"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="outletName"
                                value={outlet.outlet_name}
                                onChange={(e) => handleChange(e, 'outlet_name')}
                                autoComplete='off'
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="mb-3 mt-3">
                            <Label htmlFor="outletName">Address</Label>
                            <AvField
                                name="address"
                                disabled
                                placeholder=""
                                type="text"
                                errorMessage=""
                                className="form-control"
                                id="address"
                                value={outlet.address}
                                onChange={(e) => handleChange(e, 'address')}
                                autoComplete='off'
                            />

                        
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <div className="mb-3 mt-3">
                        <Label htmlFor="outletName">{ outlet.subc_online === 'True'? 'Building/Lot/BLK/Apt No.' : 'Building/Lot/BLK/Apt No.*' }</Label>
                            <AvField
                                name="blkAptNum"
                                placeholder="e.g. 68 or BLK 18"
                                type="text"
                                errorMessage="Input must be a digit or alphanumeric"
                                className="form-control"
                                validate={{
                                    required: {value: outlet.subc_online === 'True' ? false : true},
                                    pattern: {value: '^[A-Za-z0-9 ]+$'}
                                }}
                                id="blkAptNum"
                                value={outlet.blkAptNum}
                                onChange={(e) => handleChange(e, 'blkAptNum')}
                                disabled={outlet.subc_online === 'True' ? true : false}
                                autoComplete='off'
                            />

                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mb-3 mt-3">
                            <Label htmlFor="roadName">{ outlet.subc_online === 'True'? 'Road Name' : 'Road Name*' }</Label>
                            <AvField
                                name="roadName"
                                placeholder="e.g. Orchard Road"
                                type="text"
                                errorMessage="Input must have at least 4 characters"
                                className="form-control"
                                validate={{
                                    required: {value: outlet.subc_online === 'True' ? false : true},
                                    minLength: {value: 4},
                                }}
                                id="roadName"
                                value={outlet.roadName}
                                onChange={(e) => handleChange(e, 'roadName')}
                                disabled={outlet.subc_online === 'True' ? true : false}
                                autoComplete='off'
                            />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <div className="mb-3 mt-3">
                            <label className="form-label" htmlFor="buildingName">Building Name</label>
                            <input type="text" autoComplete='off' disabled={outlet.subc_online === 'True' ? true : false} placeholder="e.g. Plaza Singapura" className="form-control" id="buildingName" value={outlet.buildingName} onChange={(e) => handleChange(e, 'buildingName')}/>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mb-3 mt-3">
                            {/* <Label htmlFor="unitNum">{ outlet.subc_online === 'True'? 'Unit No.' : 'Unit No.*' }</Label> */}
                            <Label htmlFor="unitNum">Unit No.</Label>
                            <AvField
                                name="unitNum"
                                placeholder="e.g #04-17"
                                type="text"
                                errorMessage="Input seems wrong. Check unit number again"
                                className = { !validUnitNum && 'border-danger'}
                                //validate={{ required: {value: validUnitNum} }}
                                onBlur={()=>funcValidateUnitNum(outlet.unitNum)}
                                id="unitNum"
                                value={outlet.unitNum}
                                onChange={(e) => handleChange(e, 'unitNum')}
                                disabled={outlet.subc_online === 'True' ? true : false}
                                autoComplete='off'
                            />
                            { !validUnitNum && 
                            <p style={{ color:'#EC536C', fontSize: 11, marginTop: -15, }}>Input seems wrong. Check unit number again</p> }
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <div className="mb-3 mt-3">
                            {/* <label className="form-label" htmlFor="country">Country</label>
                            <input type="text" disabled className="form-control" id="country" defaultValue={outlet.country || 'Singapore'} onChange={(e) => handleChange(e, 'country')}/> */}
                            <Label htmlFor="country">Country</Label>
                            <AvField
                                name="country"
                                placeholder=""
                                type="text"
                                className="form-control"
                                id="country"
                                value={outlet.country || 'Singapore'}
                                onChange={(e) => handleChange(e, 'country')}
                                disabled
                            />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mb-3 mt-3">
                            <Label htmlFor="postal_code">{ outlet.subc_online === 'True'? 'Postal Code' : 'Postal Code*' }</Label>
                            <CMSAddress
                                showList={false} 
                                name="postal_code"
                                placeholder="e.g. 122354"
                                errorMessage="Input must have at least 6 numbers"
                                validate={{
                                    required: {value: outlet.subc_online === 'True' ? false : true},
                                    number: {value: true},
                                    minLength: {value: 6},
                                }}
                                value={outlet.postal_code}
                                addressLength={6}
                                processing={(e)=> setProcessingLngLat(e)}
                                onChange={(postal_code, latlng) => handlePostalCodeChange(postal_code, latlng)}   
                                disabled={outlet.subc_online === 'True' ? true : false} 
                                />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <div className="mb-3 mt-3">
                            <label className="form-label" htmlFor="mcc">MCC</label>
                            <input type="email" autoComplete='off' className="form-control" id="mcc" defaultValue={outlet.mcc && outlet.mcc.length > 0 ? outlet.mcc[0] : ''} onChange={(e) => handleChange(e, 'mcc')}/>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mb-3 mt-3">
                            <label className="form-label" htmlFor="phoneNumber">Phone</label>
                            <input type="text" autoComplete='off' className="form-control" id="phoneNumber" defaultValue={outlet.phone} onChange={(e) => handleChange(e, 'phone')}/>
                        </div>
                    </Col>
                </Row>
                <FormGroup>
                    <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="visible" checked={outlet.valid==='Y'} onChange={(e) => handleChange(e, 'valid')}/>
                        <label className="form-check-label" htmlFor="visible">Visible in CardsPal app</label>
                    </div>
                </FormGroup>
                
            </ModalBody>
            <ModalFooter>
                
                {transactionMessage ?
                    <Alert color={transactionMessage.type}>
                    {transactionMessage.msg}
                </Alert>
                : null}

                {processing ?
                <Spinner />
                : null}

                <Button
                    disabled={processing || processingLngLat}
                    color="primary"
                    type='submit'
                    //onClick={(e) => handleSubmit(e)}
                >
                    {props.mode==='create'?'Create outlet':'Update outlet'}
                </Button>
                {' '}
                <Button disabled={processing} onClick={() => props.showForm(false)}>
                    Cancel
                </Button>
            </ModalFooter>
            </AvForm>
        </Modal>
    )
}

export default connect(null, {  })(OutletForm);