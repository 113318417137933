import React from "react"
import { Row, Col, Badge } from "reactstrap"

const suggestion = [
    '1-for-1 [item name]', '% off [item name]', '$X voucher at only $Y', '(Sat-Sun/PH)[item Name] for 1 adult',
    'X-hour [Service] for Y pax (Z session/s)'
]

const suggestion_tags = ['Renovation', 'Wedding', 'Mooncake']

const VoucherTitleSuggestion = ({onClick, type}) => {

    const renderSuggestion = () =>{
        if(type && type === "tags")
            return suggestion_tags.map((item, index) => {
                return (
                        <Badge key={index}
                        className="purpleBg m-1"
                        >
                            <a className="whiteText" href={'#'} onClick={()=> onClick(item)}> {item} </a>
                        </Badge>
                    )
            })
        else        
            return suggestion.map((item, index) => {
                return (
                        <Badge key={index}
                        className="purpleBg m-1"
                        >
                            <a className="whiteText" href={'#'} onClick={()=> onClick(item)}> {item} </a>
                        </Badge>
                    )
            })
    }
    

    return(
        <Row>
            <Col> 
            {renderSuggestion()}
            </Col>
        </Row>
    )
}

export default VoucherTitleSuggestion;