import React from "react"
import {  Modal, ModalFooter, ModalHeader, ModalBody, Button } from "reactstrap"

const ModalSetupSuccess = (props) => {

    return(
        <Modal
        toggle={()=>props.toggleModal()}
        isOpen={props.showModal}
        centered={true}
        backdrop={false}
        >
            <ModalHeader
             close={<button className="close" onClick={()=>props.toggleModal('close')}></button>}
            >
                Brand set up successfully!
            </ModalHeader>
                <ModalBody>
                    CardsPal will be verifying your company set up. Meanwhile, you may start setting up your deals now.
                </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    onClick={()=>props.toggleModal('proceed')}
                >
                    Proceed
                </Button>
                {' '}
                <Button onClick={()=>props.toggleModal('cancel')}>
                    Cancel
            </Button>
            </ModalFooter>
        </Modal>
    )
}

export default ModalSetupSuccess