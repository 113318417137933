import { API, graphqlOperation } from 'aws-amplify'
import { scanHistoryByVoucherId, scanHistoryByMerchantId, scanHistoryByCreatedBy } from '../../graphql/queries'
import { createScanHistory, updateScanHistory, deleteScanHistory } from '../../graphql/mutations'
import { guidGenerator } from '../../utils'
import { setVoucherRedeemedStatus } from './voucherUser'
import amplitude from 'amplitude-js'

//Post API
export const createScanHistoryEntries = async (webForm, voucherUser, dealMaster, scanData, createdBy) => {
  try {
    const timestamp = new Date().toISOString()
    const id = guidGenerator()
    const item = {
      id: id,
      firstName: webForm?.firstName || '',
      lastName: webForm?.lastName || '',
      email: webForm?.email || '',
      voucherId: voucherUser?.masterId || '',
      merchantId: dealMaster?.merchant_id || '',
      dealId: dealMaster?.pk,
      scanStatus: JSON.parse(scanData),
      webFormId: webForm.webFormId,
      transactionId: webForm.transactionId,
      eventsId: webForm.eventsId,
      createdBy: createdBy,
      createdAt: timestamp,
      updatedAt: timestamp
    }
    const result = await API.graphql(graphqlOperation(createScanHistory, { input: item }))
    const redeemResult = await setVoucherRedeemedStatus(voucherUser, '1')
    if (!redeemResult) {
      // revert scan history entry if there is an error
      await API.graphql(graphqlOperation(deleteScanHistory, { input: {id: id} }))
      throw('Error in setVoucherRedeemedStatus')
    } else {
      return result.data.createScanHistory
    }
  } catch (e) {
    amplitude.getInstance().logEventWithGroups('Error - createScanHistoryEntries', { 'details': e })
    console.error('Error in createScanHistoryEntries', e)
    throw e
  }
}

export const updateScanHistoryEntries = async (history, webForm, voucherUser, dealMaster, scanData, updatedBy) => {
  try {
    const timestamp = new Date().toISOString()
    const item = {
      id: history.id,
      firstName: webForm.firstName,
      lastName: webForm.lastName,
      email: webForm.email,
      voucherId: voucherUser.masterId,
      merchantId: dealMaster.merchant_id,
      dealId: dealMaster.pk,
      scanStatus: JSON.parse(scanData),
      updatedAt: timestamp,
      updatedBy: updatedBy,
    }
    const result = await API.graphql(graphqlOperation(updateScanHistory, { input: item }))
    const redeemResult = await setVoucherRedeemedStatus(voucherUser, '1')
    if (!redeemResult) {
      // revert scan history entry if there is an error
      await API.graphql(graphqlOperation(deleteScanHistory, { input: {id: history.id} }))
      throw('Error in setVoucherRedeemedStatus')
    } else {
      return result.data.updateScanHistory
    }
  } catch (e) {
    amplitude.getInstance().logEventWithGroups('Error - updateScanHistoryEntries', { 'details': e })
    console.error('Error in updateScanHistoryEntries', e)
  }
}

//Query by voucherId in ScanHistory table
export const getScanHistoryByVoucherId = async (voucherId) => {
  try {
    const result = await API.graphql(graphqlOperation(scanHistoryByVoucherId, { voucherId }))
    return result.data.scanHistoryByVoucherId.items

  } catch (e) {
    amplitude.getInstance().logEventWithGroups('Error - scanHistoryByVoucherId', { 'details': e })
    console.error('Error in scanHistoryByVoucherId', e)
    return []
  }
}

export const getScanHistoryByMerchantId = async (merchantId) => {
  try {
    const result = await API.graphql(graphqlOperation(scanHistoryByMerchantId, { merchantId }))
    return result.data.scanHistoryByMerchantId.items
  } catch (e) {
    amplitude.getInstance().logEventWithGroups('Error - getScanHistoryByMerchantId', { 'details': e })
    console.error('Error in getScanHistoryByMerchantId', e)
    return []
  }
}

export const getScanHistoryByCreatedBy = async (createdBy, webFormId='0009') => {
  try {
    let data = []
    let result = []
    const params = {
      createdBy,
      filter: {
        webFormId: {
          eq: webFormId
        }
      }
    }
    do {
      result = await API.graphql(graphqlOperation(scanHistoryByCreatedBy, params))
      const nextToken = result.data.scanHistoryByCreatedBy.nextToken
      params.nextToken = nextToken
      const items = result.data.scanHistoryByCreatedBy.items
      if(items.length > 0) data = data.concat(items)
    } while (result?.data?.scanHistoryByCreatedBy?.nextToken)
    return data

    // const result = await API.graphql(graphqlOperation(scanHistoryByCreatedBy, params))
    // return result.data.scanHistoryByCreatedBy.items
  } catch (e) {
    amplitude.getInstance().logEventWithGroups('Error - getScanHistoryByMerchantId', { 'details': e })
    console.error('Error in getScanHistoryByCreatedBy', e)
    return []
  }
}