
import React from "react"
import { Card, CardBody, CardImg, CardText, Button } from 'reactstrap'

import emptyState from '../../../../assets/images/empty-state.png'

const EmptyState = (props) => {
    console.log('EmptyState ',props.createEventFirst)
    return (
        <Card>
            {props.createEventFirst ? 
                <CardBody className="text-center">
                    <CardImg className="empty-state-img" src={emptyState} />
                    <CardText className="empty-state-title">No events available</CardText>
                    <CardText className="empty-state-subtext">Please proceed to create events first.</CardText>
                </CardBody>
            :
            <CardBody className="text-center">
                <CardImg className="empty-state-img" src={emptyState} />
                <CardText className="empty-state-title">No events tickets added</CardText>
                <CardText className="empty-state-subtext">Start adding event tickets now!</CardText>
                <Button data-testid="addVoucher" disabled={props.disabled} onClick={() => props.createVouchers()} color="primary">Add event tickets now</Button>
            </CardBody>

            }

        </Card>
    )
}

export default EmptyState