import React from "react"
import {  Modal, ModalFooter, ModalHeader, ModalBody, Button } from "reactstrap"
import styled from "styled-components"
import redemptionAvatar from "../../../../assets/images/avatar/plan-avatar-redemption.png"

const ModalWebformIndfo = (props) => {
    const {webformData} = props
    const WEBFORM_W_PARTICIPANTS = ["0004", "0006", "0007", "0008"]
    return(
        <Modal
        toggle={()=>props.toggleModal()}
        isOpen={props.showModal}
        centered={true}
        backdrop={false}
        >
            <BodyContainer>
            <ModalBody >
                <ImageContainer src={redemptionAvatar}></ImageContainer>
                {!webformData || webformData.length < 1?
                    <>
                    <h5>
                        <b>No data available for display.</b> 
                        <br/>
                        <br/>
                    </h5>
                    </>
                :WEBFORM_W_PARTICIPANTS.includes(webformData.webFormId) &&
                <>
                <h5>
                    <b>Transaction ID :</b> {webformData.transactionId ?? "-"}
                    <br/>
                    <b>Confirmation ID :</b> {webformData.confirmationId ?? "-"}
                    <br/>
                    <b>Voucher Code :</b> {webformData.voucherCode ?? "-"}
                    <br/>
                    <br/>
                </h5>
                <h3>Registration Info</h3>
                <InfoContainer>
                    {webformData.participants && webformData.participants.length > 0 ?
                    <>
                        <DisplayParticipant participant = {webformData} index={0}/>
                        {
                            webformData.participants.map((participant, index)=>{
                            return  (<DisplayParticipant key={`participant${index+1}`} participant={JSON.parse(participant)} index={index+1}/>)
                            })
                        }

                        
                    </>
                    :
                    <>
                        <DisplayParticipant participant = {webformData}/>
                    </>
                    }
                </InfoContainer>
                </>
                }
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    onClick={()=>props.toggleModal(false)}
                >
                    Done
                </Button>
            </ModalFooter>
            </BodyContainer>
        </Modal>
    )
}

const DisplayParticipant = (props) =>{
    const {participant, index} = props
    return (<>      
        {index && <h5><u>Participant {index+1}</u></h5>}
        <b>First Name</b><br/>{participant.firstName ?? "-"}<br/><br/>
        <b>Last Name</b><br/>{participant.lastName ?? "-"}<br/><br/>
        <b>Email Address</b><br/>{participant.email ?? "-"}<br/><br/>
        <b>Contact Number</b><br/>{participant.phoneNumber ?? "-"}<br/><br/>
    </>)
        
}


export default ModalWebformIndfo


const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`

const InfoContainer = styled.div`
    background: #F8F9FA;
    border-radius: 4px;
    text-align:start;
    margin-top:3%;
    margin-top:3%;
    padding-left:12%;
    padding-top:4%;
    padding-bottom:4%;
    font-size:16px;
`

const ImageContainer = styled.img`
    width:30%
`