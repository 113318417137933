/* eslint-disable no-useless-catch */
import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux";
import { Image} from "react-bootstrap"
import { Row, Col, Modal, ModalFooter, ModalBody, Button, Label, Alert, Spinner } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import "flatpickr/dist/themes/material_blue.css"
import Select from 'react-select'

// import action
import { setUserMerchants, setSelectedMerchant, setUser, setAmplitudeEvent  } from '../../store/actions'

// import graphql functions
import { create, update, getBrandInfo } from '../../helpers/GraphQL/brands'
import { updateUser } from '../../helpers/GraphQL/user'

// import utils
import { getAssetUrl, guidGenerator, handleImageUpload, isValidHttpUrl, isValidURL } from '../../utils'
import { DEFAULT_MERCHANT_IMAGE } from "utils/constants";

// constants
import { MERCHANT_CATEGORY } from '../../constants'

//import component
import ImageUploader from './imageUploader'


const ManageBrand = (props) => {
    const dispatch = useDispatch()
    const merchant = useSelector(state => state.Merchant.selectedMerchant)
    const merchants = useSelector(state => state.Merchant.userMerchants)
    const user = useSelector(state => state.User.user)
    const [brand, setBrand] = useState({})
    const [processing, setProcessing] = useState(false)
    const [transactionMessage, setTransactionMessage] = useState(null)
    const [pageTitle, setPageTitle] = useState('')
    const [selectedMainCat, setSelectedMainCat] = useState({})
    const [croppedImages, setCroppedImages] = useState({})
    const [validUrl, setValidUrl] = useState(true)

    useEffect(() => {
        let selected = MERCHANT_CATEGORY[0]
        if (props.mode === 'update' && merchant && Object.keys(merchant).length !== 0) {
            selected = MERCHANT_CATEGORY.filter(item => item.value.toLocaleLowerCase() === merchant.mainCat.toLocaleLowerCase())[0]
        }
        
        setSelectedMainCat({...selected})
    }, [])

    useEffect(async () => {
        if (props.mode === 'create') {
            setPageTitle('Add new Brand')
            setBrand({ ...brand, mainCat: selectedMainCat.value})
        } else {
            const merchantInfo = await retrieveBrandById(merchant.merchant_id)
            setBrand(merchantInfo)
            setPageTitle('Manage Brand')
        }
        setProcessing(false)
        setTransactionMessage(null)
    }, [merchant, selectedMainCat])

    async function retrieveBrandById(merchantId) {
        try {
            return await getBrandInfo(merchantId)
        } catch (e) {
            console.error('Error retrieving merchant info, defaulting to store value ', e)
            dispatch(setAmplitudeEvent('CMS Manage Brand page - retrieveBrandById function error', {'details': JSON.stringify(e)}))
            return merchant
        }
    }

    function handleChange(e, fieldName) {
        const item = { ...brand }
        
        if (e.target.type === 'checkbox') {
            if (fieldName === 'valid') {
                item[fieldName] = e.target.checked ? 'Y' : 'N'
            } else if (fieldName === 'acceptCC') {
                item[fieldName] = e.target.checked ? '1' : '0'
            } else {
                item[fieldName] = e.target.checked ? 'TRUE' : 'FALSE'
            }
        } else {
            item[fieldName] = e.target.value
        }

        if (fieldName === 'merchant_name') {
            item['merchant_name_lowercase'] = item[fieldName].replace(/ /g, '').toLowerCase()
            item['display_merchant_name'] = item[fieldName]
        }

        setTransactionMessage(null)
        setBrand({...item})

        if (fieldName === 'website'){
            let inputWebsite = e.target.value
            let isValidURLA = isValidURL(inputWebsite)
            let isValidURLB = isValidHttpUrl(inputWebsite)
            
            if (inputWebsite.length > 0){
                if (isValidURLA && isValidURLB) {
                    setValidUrl(true)
                    
                }else{
                    setValidUrl(false)
                   
                }
            }else {
                setValidUrl(true)
            }
        }
    }

    function handleChangeCategory(cat) {
        setSelectedMainCat({...cat})
        setBrand({...brand, mainCat: selectedMainCat.value})
    }

    async function handleSubmit(e) {
        e.preventDefault()
        e.stopPropagation()


        if (!brand.merchant_name || (!croppedImages.logo && !brand.pockCalMerchImage) || (!croppedImages.banner && !brand.merchantImageUrlV2)){
            setTransactionMessage({ msg: 'Missing required field. Check those mark with asterisk(*)', type: 'info' })
            return
        }

        setProcessing(true)
        setTransactionMessage({msg: 'Processing...', type: 'info'})

        try {
            if (props.mode === 'create') {
                await handleCreate(e)
            } else if (props.mode === 'update') {
                await handleUpdate(e)
            }
            setProcessing(false)
            close()
        } catch (error) {
            console.error('error handleSubmit manage brand', error)
            setTransactionMessage({msg: error, type:'danger'})
            setProcessing(false)
            console.error(error)
        }
    }

    async function handleCreate() {
        try {
            const item = { ...brand}
            item.display_merchant_name = item.merchant_name
            item.merchant_name_lowercase = item.merchant_name.toLowerCase()
            item.defaultImageUrl = DEFAULT_MERCHANT_IMAGE

            if (!item.merchant_id) {
                item.merchant_id = guidGenerator()
            }
            
            if (croppedImages.banner) {
                const brandPath = `MerchantImages/brand_${item.merchant_id}${croppedImages.banner.ext}`
                item.merchantImageUrlV2 = await getAssetUrl(brandPath)
                handleImageUpload(brandPath, croppedImages.banner)
            }

            if (croppedImages.logo) {
                const logoPath = `MerchantImages/logo_${item.merchant_id}${croppedImages.logo.ext}`
                item.pockCalMerchImage = await getAssetUrl(logoPath)
                handleImageUpload(logoPath, croppedImages.logo)
            }
            
            item.valid = 'Y' // default newly created brand to valid
            const createdBrand = await create(item)
            
            await updateUserRoles(createdBrand)
            await updateLocalStorage(createdBrand)
            setTransactionMessage({msg: 'Brand created successfully', type: 'success'})
        } catch (e) {
            dispatch(setAmplitudeEvent('CMS Manage Brand page - handleCreate function error', {'details': JSON.stringify(e)}))
            throw e
        }
    }

    async function updateUserRoles(createdBrand) {
        try {
            const temp = { ...user}
            const roles = typeof temp.roles === 'object'?temp.roles:JSON.parse(temp.roles)

            if (!roles.merchants) {
                roles['merchants'] = []
            }
            roles.merchants.push({
                merchantId: createdBrand.merchant_id,
                roles: 'admin'
            })
            
            temp.roles = JSON.stringify(roles)
            await updateUser(temp)

            dispatch(setUser(temp))
            
        } catch (e) {
            console.error(e)
            dispatch(setAmplitudeEvent('CMS Manage Brand page - handleCreate function error', {'details': JSON.stringify(e)}))
        }
    }

    async function handleUpdate(e) {
        try {
            const invalidation = []
            if (croppedImages.banner) {
                const brandPath = `MerchantImages/brand_${brand.merchant_id}${croppedImages.banner.ext}`
                brand.merchantImageUrlV2 = await getAssetUrl(brandPath)
                invalidation.push('merchantImageUrlV2')
                handleImageUpload(brandPath, croppedImages.banner)
            }

            if (croppedImages.logo) {
                const logoPath = `MerchantImages/logo_${brand.merchant_id}${croppedImages.logo.ext}`
                brand.pockCalMerchImage = await getAssetUrl(logoPath)
                invalidation.push('pockCalMerchImage')
                handleImageUpload(logoPath, croppedImages.logo)
            }
            
            brand.display_merchant_name = brand.merchant_name
            brand.merchant_name_lowercase = brand.merchant_name.toLowerCase()
        
            delete brand.outlets
            delete brand.stripeExpressConnectedAccountId // TODO: fetch the latest account or merchant information from db
            const updatedBrand = await update(brand, { invalidateCloudFrontCache: invalidation })
        
            updateLocalStorage(updatedBrand)
            setBrand({...updatedBrand})
            setTransactionMessage({ msg: 'Brand update successfully', type: 'success' })
        } catch (error) {
            dispatch(setAmplitudeEvent('CMS Manage Brand page - handleUpdate function error', {'details': JSON.stringify(e)} ))
            throw error
        }
    }

    function updateLocalStorage(updatedBrand) {
        merchants[updatedBrand.merchant_id] = updatedBrand
        localStorage.setItem('userMerchants', JSON.stringify(merchants))
        localStorage.setItem('selectedMerchant', JSON.stringify(updatedBrand))
        dispatch(setSelectedMerchant(updatedBrand))
        dispatch(setUserMerchants(merchants))
    }

    async function getCroppedImage(cropped, fieldName) {
        let _croppedImages = croppedImages ?? {}
        _croppedImages[fieldName] = cropped

        
        setCroppedImages({..._croppedImages})
    }

    function close() {
        // do clean up since this is a modal and it might show old message when open again
        setProcessing(false)
        setTransactionMessage(null)
        props.showManagedBrand(false)
    }

    return (
        <Modal
            size="xl"
            isOpen={true}
            toggle={() => { props.showManagedBrand(false) }}
            centered={true}
            backdrop={true}
        >
            <ModalBody>
                <AvForm className="needs-validation">
                    <Row>
                        <Col>
                            <Label className="groupTitle m-0">{pageTitle}</Label>
                            <p>You are encouraged to fill in the optional fields to enhance your business profile.</p>
                        </Col>
                        <Col className="d-none d-md-block">
                            <Label className="groupTitle">Preview</Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div>
                                <Label htmlFor="brandName" className="subTitle m-0">Store name*</Label>
                                <p className="m-0">You can use your storefront name or the name of your website.</p>
                                <AvField
                                    name="brandName"
                                    placeholder=""
                                    type="text"
                                    errorMessage="Brand name"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="brandName"
                                    value={brand ? brand.merchant_name : ''}
                                    // defaultValue={brand ? brand.merchant_name : ''}
                                    onChange={(e) => handleChange(e, 'merchant_name')}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="rowMarginTop">
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div>
                                <Label htmlFor="brandCategory" className="subTitle">Brand category*</Label>
                                <Select 
                                    value={selectedMainCat}
                                    options={MERCHANT_CATEGORY} 
                                    onChange={(cat) => handleChangeCategory(cat)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="rowMarginTop">
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Label className="subTitle">Brand logo</Label>
                            <p>
                                <span>Image will be scaled and cropped to display 110 by 110 pixel</span>
                                <br/>
                                <span>(You may upload larger images and use the cropping tool.)</span>
                            </p>
                            <ImageUploader
                                width={110}
                                height={110}
                                required={true}
                                label="Brand Logo"
                                onOk={(croppedImage) => getCroppedImage(croppedImage, 'logo')}
                            />  
                        </Col>
                        <Col className="my-2">
                        <h6 className='d-md-none'>Preview</h6>
                            {(croppedImages.logo && croppedImages.logo.url) || brand.pockCalMerchImage ?
                                <Image fluid src={croppedImages.logo ? croppedImages.logo.url : `${brand.pockCalMerchImage}?timestamp=${Date.now()}`} />
                            : null}
                        </Col>
                    </Row>
                    <Row className="rowMarginTop">
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Label className="subTitle">Brand banner image</Label>
                            <p>
                                <span>Image will be scaled and cropped to display 375 by 266 pixel</span>
                                <br/>
                                <span>(You may upload larger images and use the cropping tool.)</span>
                            </p>
                            <ImageUploader
                                width={375}
                                height={266}
                                required={true}
                                label="Brand Banner"
                                onOk={(croppedImage) => getCroppedImage(croppedImage, 'banner')}
                            />
                            
                        </Col>
                        <Col>
                        <h6 className='d-md-none mt-2'>Preview</h6>
                            <Row style={{ position: "relative" }}>
                                {(croppedImages.banner && croppedImages.banner.url) || brand.merchantImageUrlV2 ?
                                    <Image fluid src={croppedImages.banner ? croppedImages.banner.url : `${brand.merchantImageUrlV2}?timestamp=${Date.now()}`} />
                                : null}
                                
                            </Row>
                        </Col>
                    </Row>
                    <Row className="rowMarginTop">
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="mb-3">
                                <Label htmlFor="summary" className="subTitle">Brand summary (Optional)</Label>
                                <p>
                                    <span>Describe your business in a few short sentences.</span>
                                </p>
                                <textarea rows={3} id="summary" className="form-control" defaultValue={brand.summary} onChange={(e) => handleChange(e, 'summary')} />
                            </div>
                        </Col>
                        
                    </Row>
                    <Row className="rowMarginTop">
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="mb-3">
                                <Label htmlFor="website" className="subTitle">Brand website (Optional)</Label>
                                <input type="text" id="summary" className="form-control" defaultValue={brand.website} onChange={(e) => handleChange(e, 'website')} />
                                {!validUrl ? <p className="mt-1" style={{color:'red', fontSize: 11}}>Please make sure your link starts with http:// or https://</p> : <></>}
                            </div>
                        </Col>
                        <Col></Col>
                    </Row>
                </AvForm>
            </ModalBody>

            <ModalFooter>
                {transactionMessage ?
                    <Alert color={transactionMessage.type}>
                        {transactionMessage.msg}
                    </Alert>
                    : null}

                {processing ?
                    <Spinner />
                    : null}

                <Button
                    disabled={processing}
                    color="primary"
                    onClick={(e) => handleSubmit(e)}
                >
                    {props.mode === 'create' ? 'Add brand' : 'Save changes'}
                </Button>
                {' '}
                <Button disabled={processing} onClick={() => props.showManagedBrand(false)}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default ManageBrand;
