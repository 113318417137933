import {
    SELECTED_MERCHANT,
    SET_SELECTED_MERCHANT,
    USER_MERCHANTS,
    SET_USER_MERCHANTS
} from "./actionTypes"

export const setSelectedMerchant = (merchant) => {
    return {
        type: SET_SELECTED_MERCHANT,
        payload: merchant
    }
}

export const selectedMerchant = (merchant) => {
    return {
        type: SELECTED_MERCHANT,
        payload: { selectedMerchant: merchant }
    }
}

export const setUserMerchants = (merchants) => {
    return {
        type: SET_USER_MERCHANTS,
        payload: merchants
    }
}

export const userMerchants = (merchants) => {
    return {
        type: USER_MERCHANTS,
        payload: { userMerchants: merchants }
    }
}