import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import { useForm, Controller } from "react-hook-form";
import { Button, Form, Image } from 'react-bootstrap';
import { Row, Col, CardBody, Card, Alert, Container, Spinner, CardTitle } from "reactstrap"
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

// import Banner from '../../assets/images/B2B_Banner.png'
import * as Yup from 'yup';
import CountDown from './Components/CountDown';
import { remoteConfig, getHappeningOn } from 'utils';
import ContactInfo from './Components/ContactInfo';
import PersonalInfo from './Components/PersonalInfo';
import WarningPopup from './Components/WarningPopup';
import { Title, SubTitle, Underline, CardContainer, DateTimeData, SeatingInfo,
    TicketType, SpinnerDiv, ErrMsg, Sup, SubmitButton, Terms, MapWrapper, Description, MCRCardContainer} from './Components/Styled';
import { TIME_IS_UP, NO_SLOT, STOP_TIMER, START_TIMER, RESET_TIMER, PROCESSING_ERROR,RESERVE_ERROR } from 'utils/constants';
import { getCanPurchase, getDealInfo, getEvents, reserveVoucher, B2BRegistration, cancelReserveVoucher, createWebformData,getEnv, B2BValidate, retrieveGKey } from 'helpers/apiHelper';
import AccessDenied from 'components/Common/accessDenied';
import amplitude from 'amplitude-js';
import Map from './Components/Map'
import moment from 'moment'
import footerImg from '../../assets/images/PoweredByCardsPal.png'
import { CodePipeline } from 'aws-sdk';

  
 
const B2B = (props) => {

    const [timerStatus, setTimerStatus] = useState(STOP_TIMER)
    const [nationality, setNationality] = useState([])
    const [countryCode, setCountryCode] = useState([])
    const [canPurchase, setCanPurchase] = useState(false)
    const [warningMessage, setWarningMessage] = useState({show:false, type:'',message:''})
    const [isBusy, setIsBusy] = useState(false)
    const [dealInfo, setDealInfo] = useState([])
    const [eventInfo, setEventInfo] = useState([])
    const [reserveVoucherInfo, setReserveVoucherInfo] = useState([])
    const [initialCount, setInitialCount] = useState(0)
    const [contactInfo, setContactInfo] = useState({})
    const [happeningOn, setHappeningOn] = useState('')
    const [isAccessDenied, setAccessDenied] = useState(null);
    const [prefill, setPrefill] = useState(null)
    const [custom, setCustom] = useState([])
    const [mapKey, setMapKey] = useState('')
    const [invalid, setInvalid] = useState(true)
  

    const salutationList = [ 
        { value: 'Mr', label: 'Mr' },
        { value: 'Mrs', label: 'Mrs' },
        { value: 'Miss', label: 'Miss' },
        { value: 'Ms', label: 'Ms' },
        { value: 'Dr', label: 'Dr' }           
      ];
    
    const eventId = props.match.params.eventId
    const dealId = props.match.params.dealId
    const payload = props.match.params.payload ? decodeURIComponent(props.match.params.payload) : null
    const imagePath = `https://assets.${getEnv()}.cardspal.com/public/Events/banner/`
    const faviconPath = `https://assets.${getEnv()}.cardspal.com/public/Events/favicon/`
    const webFormId = window.location.pathname.split("/")[2]
    

    const boxStyling = {background:"#FFFFFF", boxShadow: "2px 4px 12px rgba(0, 0, 0, 0.15)", borderRadius: "14px"}
    const descriptionStyling = {fontFamily:'Rubik',fontStyle : "normal", fontWeight: "700", fontSize: "20px", lineHeight: "150%", color: "#394452", marginTop:"5vh" ,marginBottom:"5vh"}
    
    useEffect( async() => {
        setIsBusy(true)
        payload ? B2BValidatePayLoad(payload) : setInvalid(false)
        getCountry()
        getKey()
        getCustomSettings()
        loadEventDealInfo()
//        const searchParams = new URLSearchParams(window.location.search)
//        if (searchParams.get.length>0) 
//            setPrefill( Object.fromEntries([...searchParams]))


        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = `${faviconPath}${dealId}_favicon.ico`;
    }, [])

    useEffect(() => {
        if (eventInfo?.data?.name) {
            document.title = eventInfo.data.name;
        }
    }, [eventInfo]);

    useEffect(() => {
        if (prefill && (Object.keys(prefill).length > 0)) {
            setInvalid(false)
        }
    }, [prefill]);

    useEffect(() => {

        if (canPurchase) {
            window.history.pushState(null, "", window.location.href);
            window.onpopstate = () => {
            window.history.pushState(null, "", window.location.href);
                // cancelReservation()
                setCanPurchase(false)  
            }
             
        }

        return () => {
            if(canPurchase){
                setCanPurchase(false) 
            }     
        } 
        
    },[canPurchase]);

    useEffect(async() => {
        if(reserveVoucherInfo && custom.isOnePager){
            onSubmitPersonalInfo({})
        } 
    },[reserveVoucherInfo])

    const loadEventDealInfo = async() => {
        let deal = await getDealInfo(dealId)
        setDealInfo(deal)

        let event = await getEvents(eventId)
        setEventInfo(event)

        if (deal.statusCode === '200' && event.statusCode === '200') {
            let _datetime = getHappeningOn(event?.data?.startTimestamp, event?.data?.endTimestamp)
            setAccessDenied(false)
            setHappeningOn(_datetime)
        } else {
            setAccessDenied(true)
        }
        setIsBusy(false)
    }
    
    const getCountry = async() => {
        let rmCountry = await remoteConfig("country")
        let parseCountry = JSON.parse(rmCountry._value)
        let countryList = parseCountry.filter(item => {
            return !!item.nationality
          }) 
        countryList.sort((a, b) => (a.priority != null ? a.priority : Infinity) - (b.priority != null ? b.priority : Infinity))
        const _nationality = countryList.map(item => {
            return {
              value: item.nationality,
              label: item.nationality,
            }
          })
        const _countryCode = countryList.map(item => {
            return {
              value: `+${item.code}`,
              label: `+${item.code}`
            }
          })
        setNationality(_nationality)
        setCountryCode(_countryCode)
    }

    const B2BValidatePayLoad = async(payload) =>{
            let res = await B2BValidate({
                "encryptedData":payload
            })
            if (res.statusCode === 200){
                setPrefill(JSON.parse(res.body))
                // JSON.parse(res.body).email ? cancelReservation(JSON.parse(res.body).email) : null
            }
        }

    const getKey = async ()=>{
        let res = await retrieveGKey()
        if (res.success === "200")
           setMapKey(res.data.GOOGLE_API_KEY)
    }    

    const getCustomSettings = async() =>{
        let cust = await remoteConfig("b2bCustom")
        if(cust._value){
            let parseCustJson = JSON.parse(cust._value)
            setCustom(parseCustJson[eventId])
        }           
    }

    const reservation = async(data) => {
        let reserveV = await reserveVoucher(data)
        if (reserveV.statusCode === 200 && Object.entries(reserveV?.data)?.length > 0) {
            setReserveVoucherInfo(reserveV)
            if(!custom.isOnePager){
                setInitialCount(reserveV?.data?.minsToKickout * 60)
                setCanPurchase(true)
                setTimerStatus(START_TIMER)
                setIsBusy(false)
            }
        } else if(reserveV.error) {
            setWarningMessage({ show: true, type: RESERVE_ERROR, message: reserveV.error})
        }
        
    }

    const onSubmitContactInfo = async(e) => {
        try {  
            setIsBusy(true)         
            let phoneNumber = e.phoneNumber ? `${e.countryCode}${e.phoneNumber}` : ''
            let data = { userId: e.email, dealId: dealId, qty: '1' }
            let getUserCanPurchase = await getCanPurchase(data)
            if (getUserCanPurchase.statusCode === 200) {
                if (getUserCanPurchase?.canPurchase) {
                    let obj = { dealId: dealId, userId: e.email, numberOfVouchers: "1" }
                    reservation(obj)
                    setContactInfo({ userId: e.email, email: e.email, phoneNumber: phoneNumber, firstName: e.firstName, lastName: e.lastName })
                }else {
                    setWarningMessage({ show: true, type: NO_SLOT})
                }
            } 
        } catch (error) {   
            console.error('onSubmitContactInfo', error)            
        }
    }

    const onSubmitDecline = async(e) => {
        try {  
            setIsBusy(true)
            let phoneNumber = e.phoneNumber ? `${e.countryCode}${e.phoneNumber}` : ''
            setContactInfo({ userId: e.email, email: e.email, phoneNumber: phoneNumber })

            let webFormData = {
                "webFormId": webFormId,
                "email": e.email,
                "phoneNumber": phoneNumber,
                "reason": e.reason ?? "",
                "prepopulated": prefill && (Object.keys(prefill).length === 0)? "0" : "1"  
              }
              let response = await createWebformData(webFormData)
              if (response) {
                    if(response.body == "user_exists"){
                        return false
                    }
                    else{
                        setIsBusy(false)
                        return true
                    }
                    
              }
        } catch (error) {   
            console.error('onSubmitDecline', error)            
        }
    }

    const onBlurContactInfo = async(e) => {
        const {value, name} = e.target
    }

    const onChangeContactInfo = (e) => {
        const {value, name} = e.target
    }

    const onErrorContactInfo = (e) => {
        console.log('---error', e)
    }

    const onSubmitPersonalInfo = async(e) => {
        try {
            setIsBusy(true)
            setTimerStatus(STOP_TIMER)

            if(e['altPhoneNumber']){
                e['altPhoneNumber'] = `${e.countryCode}${e.phoneNumber}`       
            }
            delete e['altCountryCode']

            let transform = {}
            Object.keys(e).forEach((key) => {
                if(e[key])
                    transform[key] = e[key] === true ? '1' : e[key] === false ? '0' : e[key]
            })

            let webFormData = {
                webFormId: webFormId,
                email: contactInfo.email,
                phoneNumber: contactInfo.phoneNumber,
                firstName: contactInfo.firstName,
                lastName: contactInfo.lastName,
                prepopulated: prefill && (Object.keys(prefill).length === 0) ? "0" : "1",
                ...transform
              }
            
            if(prefill && (Object.keys(prefill).length === 0)){
                webFormData['createdBy'] = prefill.email       
            }

            let data = {
                wordpress: "1",
                dealId: dealId,
                eventsId: eventId,
                paymentMethod: { id: "FREE" },
                purchasePriceTotal: "0.00",
                qty: "1",
                userId: contactInfo.userId,
                vouchers: reserveVoucherInfo.data.vouchers,
                webFormData: webFormData,
                hideTicketInfo : true,
                others: { "idempotencyKey" : uuidv4()}
            }

            let response = await B2BRegistration(data)
            if (response && response.statusCode ===200) {
                setIsBusy(false)
                gotoRegistrationSuccessful({...data, ...response})
            }else if(response && response.statusCode ===500){
                setWarningMessage({ show: true, type: PROCESSING_ERROR})
            }else{
                setWarningMessage({ show: true, type: PROCESSING_ERROR})
            }
            
            
        } catch (error) {   
            console.error('onSubmitPersonalInfo', error)            
        }
        
    }

    const gotoRegistrationSuccessful = (e) => {
        dealInfo.data.happeningOn = happeningOn
        let eventData  = eventInfo.data
        let eventDescription = eventData.description.split("<br>")
        eventDescription[3] = "[url]https://cardspal.com/mcrcny-event/[/url]"
        eventData.description = eventDescription.join("<br>")
        let param = {event: eventData, deal: dealInfo.data, ...e, custom}
        let page = {pathname: `/webform/${webFormId}/B2BRegistrationSuccessful`, state: param};
        props.history.push(page)
    }

    const onBlurPersonalInfo = (e) => {
        const {value, name} = e.target
    }

    const onChangePersonalInfo = (e) => {
        const {value, name} = e.target
    }

    const onErrorPersonalInfo = (e) => {
        console.log('---error', e)
    }

    const tryAgain = () => {
        setWarningMessage({ show: false,type:'',message:'' })
        setTimerStatus(RESET_TIMER)
        setIsBusy(false)
    }

    const getTimerStatus = (e) => {
        if (e === 'Stopped'){
            setWarningMessage({ show: true, type: TIME_IS_UP})
        }
    }

    return(
        <React.Fragment>
            {(isAccessDenied === null) || invalid  ? <></> :
                ( isAccessDenied ? <AccessDenied screen="B2B" showBackToDashboard={false}/> : 
                <div style={{backgroundColor:"#FFFFFF"}}>
                    <div  style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <Image style={{height:"50vh"}} fluid src={imagePath+dealId+"_banner.png"}/>
                    </div>
                    <Container>
                        {eventInfo?.data?.registrationEnd <= moment().unix() ?
                            <CardContainer className="mt-3">
                                <CardBody>
                                    <CardTitle>Registration closed</CardTitle>
                                    <p>
                                        Thank you for your interest. Unfortunately, the registration is now closed. We will make sure to keep you informed for future events.
                                    </p>

                                    <p>
                                        For any queries, please email <a href="mailto:mediaclubroom@gmail.com">mediaclubroom@gmail.com</a>.
                                    </p>

                                    <p>
                                        See you next time!
                                    </p>
                                </CardBody>
                            </CardContainer>
                        :
                            <Row className="justify-content-center mt-3">
                                <Col>
                                {eventInfo?.data?.description ?
                                    <div>
                                            <Description style={descriptionStyling ?? {}}>{eventInfo?.data?.description.split("<br>")[0]}</Description>
                                    </div>
                                :
                                    <div className='text-center'>
                                        <SubTitle className='m-1'>Ticket Registration</SubTitle>
                                        <p>Please fill out the following form in order to reserve a ticket.</p>
                                    </div>
                                }

                                    <div className='d-flex flex-row justify-content-between align-items-center'>
                                        <SubTitle>Registration</SubTitle>
                                        { canPurchase &&
                                        <div className='d-flex flex-row'>
                                            <p className='px-3 align-self-center'>Time Left</p>
                                            <CountDown
                                            initialCount={initialCount}
                                            setTimerStatus={timerStatus}
                                            getTimerStatus={(e)=>getTimerStatus(e)}/>           
                                        </div> }
                                    </div>

                                    <CardContainer style={boxStyling ?? {}}>
                                        <CardBody>
                                            <Title>{eventInfo?.data?.name}</Title>
                                            <Description>{eventInfo?.data?.location}</Description>
                                            <MapWrapper> 
                                            { eventInfo?.data && mapKey &&
                                            <Map location={ {
                                                    address: eventInfo?.data?.location ?? '',
                                                    lat: parseFloat(eventInfo?.data?.latitude) ?? 0,
                                                    lng: parseFloat(eventInfo?.data?.longitude) ?? 0,
                                                    key: mapKey
                                                } } zoomLevel={18}/> 
                                            } 
                                            </MapWrapper>
                                            <Row >
                                                <Col>
                                                    <p>Date Time</p>
                                                </Col>
                                                <Col className='d-flex justify-content-end'>
                                                    <DateTimeData>{happeningOn}{custom?.seatingInfoText ? <SeatingInfo>{custom?.seatingInfoText}</SeatingInfo> : null}</DateTimeData>
                                                </Col>
                                            </Row>
                                            <Underline/>
                                            <Row>
                                                <Col>
                                                    <p>Ticket Type</p>
                                                </Col>
                                                <Col className='d-flex justify-content-end'>
                                                    <TicketType>{dealInfo?.data?.ticketType}</TicketType>
                                                </Col>
                                            </Row>

        
                                        </CardBody>
                                    </CardContainer>
                                    { !canPurchase ? 
                                    <ContactInfo
                                        countryCode={countryCode}
                                        onSubmit={(e)=>onSubmitContactInfo(e)}
                                        onBlur={(e)=>onBlurContactInfo(e)}
                                        onChange={(e)=>onChangeContactInfo(e)}
                                        onErrorContactInfo={(e)=>onErrorContactInfo(e)}
                                        onSubmitDecline ={(e)=>onSubmitDecline(e)}
                                        prefill={prefill}
                                        custom={custom}
                                        isDisabled={isBusy}
                                        setIsBusy ={()=>setIsBusy(true)}
                                        setNotBusy ={()=>setIsBusy(false)}
                                        styling={boxStyling}
                                        webFormId={webFormId}
                                    />:
                                    <PersonalInfo 
                                        salutationList={salutationList}
                                        nationality={nationality}
                                        countryCode={countryCode}
                                        onSubmit={(e)=>onSubmitPersonalInfo(e)}
                                        onBlur={(e)=>onBlurPersonalInfo(e)}
                                        onChange={(e)=>onChangePersonalInfo(e)}
                                        onErrorContactInfo={(e)=>onErrorPersonalInfo(e)}
                                        custom={custom}
                                        isDisabled={isBusy}
                                    /> }
                                    
                                </Col>
                            </Row>

                             
                        }
                        <Row className="justify-content-center mt-3">
                            <Col className="col-sm-6 pd-3" align="center">
                                <Image style={{maxHeight:"3vh",marginBottom:"3vh"}} fluid src={footerImg}/>
                            </Col>
                        </Row>

                        <WarningPopup
                            open={warningMessage.show}
                            warningType={warningMessage.type}
                            message={warningMessage.message}
                            tryAgain={()=>tryAgain()}
                            onOpened={()=>{setTimerStatus(STOP_TIMER)}}
                        />
                        { isBusy && 
                        <SpinnerDiv>
                            <Spinner/>
                        </SpinnerDiv> }
                    </Container>
                </div>
                )
            }
        </React.Fragment>

    )
}


export default B2B