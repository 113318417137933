import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import { Container, Spinner } from "reactstrap"

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  isConfigLoaded,
  ...rest
}) => {
  //  Auth.currentAuthenticatedUser({ bypassCache: true }).then(currentUser => {
  //    console.log('log from authmiddleware', currentUser )
  //  })

  return (
    <Route
      {...rest}
      render={props => {
         if (isAuthProtected && !localStorage.getItem("authUser")) {
           return (
             <Redirect
               to={{ pathname: "/login", state: { from: props.location } }}
             />
           )
         }
         if (isConfigLoaded) {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }else {
        return (
          <Container>
            <div style={{position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
              <Spinner/>
            </div>
          </Container>
        )
      }
      }}
    />
  )
}

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware
