import React from "react"
import { Alert } from "reactstrap"
import { useSelector } from 'react-redux';

const messageAlert = () => {
    const messageAlerts = useSelector(state => state.MessageAlert) || {}
    return (
        <div>
            {messageAlerts.message ?
                <Alert color={messageAlerts.color} >{messageAlerts.message}</Alert>
            :null}
        </div>
    )
}

export default messageAlert
