
import React from "react"
import { Card, CardBody, CardImg, CardText, Button } from 'reactstrap'

import emptyState from '../../../../assets/images/empty-state.png'

const EmptyState = (props) => {

    return (
        <Card>
            <CardBody className="text-center">
                <CardImg className="empty-state-img" src={emptyState} />
                <CardText className="empty-state-title">No events added</CardText>
                <CardText className="empty-state-subtext">Start adding events now!</CardText>
                <Button data-testid="addEvent" disabled={props.disabled} onClick={() => props.createEvents()} color="primary">Add events now</Button>
            </CardBody>
        </Card>
    )
}

export default EmptyState