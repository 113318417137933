import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { useSelector, useDispatch } from "react-redux";
import {Container, Row, Col, Card, CardBody, Spinner } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Brand from './Components/brands'

import RBAC from '../../../components/Common/rbac'

// graphql
import { getMerchantStripeAccount  } from '../../../helpers/GraphQL/userStripeAccount'

// import assets
import professionalProfileIcon from "../../../assets/images/avatar/plan-avatar-pro.png"
import starterProfileIcon from "../../../assets/images/avatar/plan-avatar-starter.png"
import freeProfileIcon from "../../../assets/images/avatar/plan-avatar-free.png"
import bronzeProfileIcon from "../../../assets/images/avatar/plan-avatar-bronze.png"
import goldProfileIcon from "../../../assets/images/avatar/plan-avatar-gold.png"
import silverProfileIcon from "../../../assets/images/avatar/plan-avatar-silver.png"

// helpers
import { stripeCreateOnboardingLink, stripeRetrieveKey, stripeCreateLoginLink, getConfig, getEnv } from '../../../helpers/apiHelper'
import { createStripeAccount, updateStripeAccount } from '../../../helpers/GraphQL/userStripeAccount'

// import utils
import { STARTER_PLAN_1, STARTER_PLAN_2, STARTER_PLAN_3, PROFESSIONAL_PLAN, SILVER_PLAN, BRONZE_PLAN, GOLD_PLAN, WAIVER_PLAN } from '../../../utils/constants'

// import actions
import { setBreadcrumbItems, setAlert, setAmplitudeEvent, setConfig, setEnv } from "../../../store/actions";

const ManageProfile = () => {
    
    const dispatch = useDispatch()
    const cmsConfig = useSelector(state => state.Config)
    const merchants = useSelector(state => state.Merchant.userMerchants)
    const selectedMerchant = useSelector(state => state.Merchant.selectedMerchant)
    const user = useSelector(state => state.User.user)
    
    const [refreshUrl, setRefreshUrl] = useState('https://biz.dev.cardspal.com/')
    const [returnUrl, setReturnUrl] = useState('https://biz.dev.cardspal.com/')
    const [brands, setBrands] = useState([])
    const [isProcessing, setIsProcessing] = useState(false)
    const [profileIcon, setProfileIcon] = useState(freeProfileIcon)
    const breadcrumbItems = [
        { title: "CardsPal", link: "#" },
        { title: selectedMerchant.display_merchant_name, link: "#" },
        { title: "Settings", link: "#" },
        { title: "Manage Profile", link: "#" },
    ]

    useEffect(() => {
        if (!user || Object.keys(user).length === 0) return 
        
        switch (user.subPlan) {
          case PROFESSIONAL_PLAN:
          case WAIVER_PLAN:
            setProfileIcon(professionalProfileIcon)
            break;
          case STARTER_PLAN_1:
          case STARTER_PLAN_2:
          case STARTER_PLAN_3:
            setProfileIcon(starterProfileIcon)
            break;
          case SILVER_PLAN:
            setProfileIcon(silverProfileIcon)
            break;
          case BRONZE_PLAN:
            setProfileIcon(bronzeProfileIcon)
            break;
          case GOLD_PLAN:
            setProfileIcon(goldProfileIcon)
            break
          default:
            setProfileIcon(freeProfileIcon)
        }

        
        let _ampTrack = {
            'merchant id': selectedMerchant.merchant_id,
            'merchant name':  selectedMerchant.merchant_name,
            'mainCat': selectedMerchant.mainCat
          }
       
        dispatch(setAmplitudeEvent('CMS Manage Profile page - page load', {..._ampTrack} ))

    }, [user])

    useEffect(() => {
        if (!user) {
            dispatch(setAlert('Error loading this page. Please contact Administrator', 'danger'))
        } else {
            dispatch(setAlert(''))
        }
        dispatch(setBreadcrumbItems('Manage Profile', breadcrumbItems))
    }, [selectedMerchant])

    useEffect(() => {
        if (cmsConfig && cmsConfig.cmsv2) setConfigVariables(cmsConfig.cmsv2.stripeVariables)
    }, [cmsConfig])

    useEffect(async () => {
        if (!user) return
        
        setIsProcessing(true)
        dispatch(setAlert('Please wait while we retrieve the status of each brand...', 'info'))
        const key = await getStripeKey()

        const merchantIds = Object.keys(merchants)
        let tempBrands = []

        for (let i = 0; i < merchantIds.length; i++) {
            const mid = merchantIds[i]
            let stripeAccount = await getMerchantStripeAccount(mid)
            const { status, link, buttonLabel, needPrompt } = await getStripeLink(key, stripeAccount, mid)

            if (status) {
                const temp = {
                    id: mid,
                    brand: merchants[mid].display_merchant_name,
                    stripeStatus: status,
                    banner: merchants[mid].merchantImageUrlV2,
                    logo: merchants[mid].pockCalMerchImage,
                    link,
                    buttonLabel,
                    needPrompt
                }
                tempBrands.push(temp)
                setBrands([...tempBrands]) // we call this to starting loading of what we have, if the process if busy, it might now show 
            }
        }

        dispatch(setAlert(''))
        setBrands([...tempBrands])
        setIsProcessing(false)
    }, [])

    useEffect(async () => {
        await getCMSConfig()
      }, [])
    
    async function getCMSConfig() {
        try {
            if (!cmsConfig || !cmsConfig.cmsv2) {
            
            const config = await getConfig()

            const env = getEnv()
            
            dispatch(setConfig(config))
            dispatch(setEnv(env))
            }
        } catch(error) {
            console.error('error on retrieving config file', error)
            dispatch(setAmplitudeEvent('CMS Manage Profile page - get cms config error', {'details': JSON.stringify(error) }))
        }
    }

    async function setConfigVariables(variables) {
        setRefreshUrl(variables.refreshUrl)
        setReturnUrl(variables.returnUrl)
    }
    
    async function getStripeKey() {
        const key = await stripeRetrieveKey()
        if (!key) {
            dispatch(setAlert(`Error loading this page. Please report to admin immediately.`, 'danger'))
        }
        return key.stripeSecret
    }

    async function getStripeLink(key, stripeAccount, merchantId) {
        let status
        let stripeInfo
        let link
        let buttonLabel
        let needPrompt
        let accountId

        try {
            if (!stripeAccount || Object.keys(stripeAccount).length === 0 || stripeAccount.status === 'Not onboarded') {
                // either we have no stripe account for this merchant or the on-boarding link was not used or user did not created the account (first 2 screens of on-boarding)
                status = 'Not onboarded'
                stripeInfo = await stripeCreateOnboardingLink({ email: user.email, accountId: (stripeAccount?stripeAccount.accountId:null) ,stripeKey: key, refreshUrl, returnUrl, merchantId })
                link = stripeInfo?.accountLink?.url
                accountId = stripeInfo?.account ? stripeInfo?.account?.id : stripeAccount?.accountId
                buttonLabel = 'Connect on Stripe'
                needPrompt = true
            } else {
                // this means we have an existing stripe account that is not newly-created in status, so we will just need a login link
                status = stripeAccount.status
                stripeInfo = await stripeCreateLoginLink({ stripeKey: key, accountId: stripeAccount.accountId })

                if (!stripeInfo) {
                    stripeInfo = await stripeCreateOnboardingLink({ email: user.email, accountId: (stripeAccount?stripeAccount.accountId:null) ,stripeKey: key, refreshUrl, returnUrl, merchantId })
                }

                link = stripeInfo?.url
                accountId = stripeAccount?.accountId
                buttonLabel = 'View on Stripe'
                needPrompt = false
            }
           
            await updateUserStripeDB(stripeAccount, {
                accountId: accountId,
                merchantId: merchantId,
                email: user.email,
                status
            })

            return {status, link, buttonLabel, needPrompt}
        } catch(error) {
            console.error('Error generating stripe link ', error)
            dispatch(setAlert(`Error loading this page. Please report to admin immediately.`, 'danger'))
            dispatch(setAmplitudeEvent('CMS Manage Profile page - get stripe link error', {'details': JSON.stringify(error) }))
            return { status, link, buttonLabel, needPrompt }
        }
    }

    const updateUserStripeDB = async (stripeAccount, { accountId, merchantId, email, status, type='on-boarding'}) => {
        if (stripeAccount) {
            await updateStripeAccount({accountId,
                merchantId,
                email,
                type,
                status
            })
        } else {
            await createStripeAccount({
                accountId,
                merchantId,
                email,
                type,
                status
            })
        }
    }
    
    const renderBrand = () =>{
        return brands.map((item) => {
              return (
                <Col key={item.id}>
                    <Brand id={item.id} brand={item.brand} banner={item.banner}
                     logo={item.logo}  stripeStatus={item.stripeStatus}
                     link={item.link} buttonLabel={item.buttonLabel} needPrompt={item.needPrompt}
                     />
                </Col>
              )
            })
    }

    return (
        <React.Fragment>
    
            <MetaTags>
                <title>Manage Profile</title>
            </MetaTags>
           <div>
               <Container>
                   <Row className="justify-content-center">
                        <Col>
                            <Card>
                                <CardBody>
                                    <h5>Personal Profile</h5>
                                    <Row className="p-2">

                                        <Col xs={1} sm={2} md={1} lg={1} xl={1}
                                            className="d-flex rounded-circle justify-content-center align-content-center"
                                            style={{backgroundColor:'lightgrey', width:'75px', height:'75px'}}>
                                            <img className="rounded-circle" src={profileIcon}/>
                                        </Col>
                                        <Col>
                                            <h5>{`${user?user.firstName:''} ${user?user.lastName:''}`}</h5>
                                            <div>
                                               <span>{user?user.email:''}</span> 
                                            </div>
                                            <div>
                                                <span>{user?user.mobile:''}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            <RBAC merchantId={selectedMerchant.merchant_id} roles={['admin']}>
                                <AvForm>
                                    <Card>
                                        <CardBody>
                                            <h5>Company Profile</h5>
                                            <Row xs={1} sm={1} md={2} lg={2} xl={2}>
                                                <AvField
                                                    name="companyName"
                                                    label="Company name in ACRA"
                                                    className="form-control"
                                                    type="text"
                                                    value={user.corpName}
                                                    disabled={true}  
                                                />
                                                <AvField
                                                    name="uenNumber"
                                                    label="UEN number"
                                                    className="form-control"
                                                    type="text"
                                                    value={user.uen}
                                                    disabled={true}
                                                />
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </AvForm>
                                <Card>
                                    {isProcessing ? <span className="text-center"><Spinner /></span>:null}
                                    <CardBody>
                                    <h5 className="pb-2">Brands & Stripe Setup</h5>
                                    <Row  xs={12} sm={12} md={3} lg={3} xl={3}>
                                        {renderBrand()}
                                    </Row>
                                    </CardBody>
                                </Card>
                            </RBAC>
                       </Col>
                   </Row>
               </Container>
           </div>
        </React.Fragment>
    )

}


export default ManageProfile;