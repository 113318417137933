/* eslint-disable no-useless-escape */

import { isFuture, isPast, differenceInDays, format, sub, fromUnixTime } from 'date-fns'
import amplitude from 'amplitude-js';
import Geohash from 'latlon-geohash'
import { Storage } from 'aws-amplify'
import { ExportToCsv } from 'export-to-csv';

// import helper
import { getEnv } from 'helpers/apiHelper';

// import constant
import { STRIPE_BUTTON_LABEL, STRIPE_STATUS } from  './constants'

// import helpers
import { listVouchersByDealId } from  '../../src/helpers/GraphQL/voucherMaster'
import { getUser } from 'helpers/GraphQL/user';
import { getRemoteConfig, fetchAndActivate, getValue } from "firebase/remote-config";

export const guidGenerator = () => {
    const S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
} 


export const formatDateTime = (timestamp, form=null) => {
    try {
        const _format = form || "yyyy-MM-dd HH:mm:ss"
        const dt = fromUnixTime(timestamp)
        return format(dt, _format)
    } catch (error) {
        // console.error( 'timestamp passed causing error ', timestamp )
        return '-'
    }
}

export const isDealExpiring = (endTimestamp) => {
    try {
        const dt = new Date(endTimestamp)
        const temp = sub(dt, {days:7})
        return Date.now() >= temp
    } catch(error) {
        console.log(error)
        return false
    }
}

export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

export const getAssetUrl = async (path) => {
    const env = getEnv()
    return `https://assets.${env}.cardspal.com/public/${path}`
}

// this will return a list of items not in parentList
// list1 will be the parent list should contain the initial list
export const listDifference = (parentList, childList) => {

    const removeItemsFromParent = parentList.filter(o => childList.indexOf(o) === -1)
    const addedItemsToParent = childList.filter(o => parentList.indexOf(o) === -1)
    
    return {
        removeItemsFromParent,
        addedItemsToParent
    }
}

export const openInNewTab = (url, self) => {
    const re = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
    let newUrl
    if (re.test(url)) {
        newUrl = url
    } else {
        newUrl = `http://${url}`
    }
    const newWindow = window.open(newUrl, self ? '_self' : '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

export const capitalizeFirstLetter = (string) => {
    try{
        const _string = string.toLowerCase()
        return _string.charAt(0).toUpperCase() + _string.slice(1);
    } catch (e) {
        return string
    }
}


export const getOutletName = (item, outlets) => {
    try {
        let name = '-'
        if (outlets) {
            const mid = item.merchant_id
            const sid = item.store_id
            const foundOutlet = outlets.filter(o =>
                o.merchant_id === mid && o.store_id === sid
            )

            name = foundOutlet && foundOutlet.length > 0 ? foundOutlet[0].outlet_name : '-'
        }
        return name
    } catch (error) {
        console.error(error)
        return '-'
    }
}

export const amplitudeUserProperty = (property,value) => {
    var identify = new amplitude.Identify().set(property, value);
    amplitude.getInstance().identify(identify);
}

export const delay = ms => new Promise(res => setTimeout(res, ms));

export const updateGeohash = (item) => {
    try {
        // item.geohashlong = Geohash.encode(item.latitude, item.longitude, 12)
        // item.geohashshort = Geohash.encode(item.latitude, item.longitude, 7)
        // item.geohashsix = Geohash.encode(item.latitude, item.longitude, 6)

        // return item

        const geohashlong = Geohash.encode(item.latitude, item.longitude, 12)
        const geohashshort = Geohash.encode(item.latitude, item.longitude, 7)
        const geohashsix = Geohash.encode(item.latitude, item.longitude, 6)
        return { geohashlong, geohashshort, geohashsix}
    } catch (error) {
        console.error('error on updateGeohash', error)
        throw error
    }
}

export const handleImageUpload = async (path, croppedImages, options) => {
    try {
        if (croppedImages && croppedImages.blob) {
            try {
                croppedImages.blob.name = path
            } catch(e){
                // ignore error as dropzone library down not allow change of file
            }
            let result
            if (options) {
                result = await Storage.put(path, croppedImages.blob, {level: "private"})
            } else {
                result = await Storage.put(path, croppedImages.blob)
            }
            console.debug('file uploaded to S3', result, croppedImages.blob.name)
        }
    } catch(error) {
        console.error('error on handleImageUpload ', error)
        throw error
    }
}

export const generatePromoCodes = async (total, prefix='CP') => {
    var S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };

    const codes = []

    for (let i=0; i < total; i++){
        codes.push(prefix + S4() + S4())
    }
     return codes
}

export const convertToCSV = (data, filename) => {
   
    const options = { 
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: false, 
        showTitle: false,
        title: '',
        useTextFile: false,
        useBom: false,
        useKeysAsHeaders: true,
        filename: filename
        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };
    
    const csvExporter = new ExportToCsv(options);
    
    csvExporter.generateCsv(data);
}

export const validateVoucherCodes = (codes) => {
    let _codes = []
    const re = /^[a-zA-Z0-9]*$/
    for (let i=1; i<codes.length; i++){
        let code = codes[i].trim()
        
        // ignore empty line
        if (code==="") continue

        if (code.length < 6) {
            return []
            
        } else if (_codes.includes(code)) {
            return []
            
        } else if (!re.test(code)) {
            if(isValidHttpUrl(code) && isValidURL(code)){
                console.log('validUrl')
            } else {
                return []
            }
        }
        _codes.push(code)
    }
    return _codes
}

export const isValidHttpUrl = (string) => {
    let url;
    
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
  }

  export const isValidURL = (string) => {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(string);
  }

  export const evaluateStripeStatus = (stripeAccount) => {
    let status
    let buttonLabel

    if (stripeAccount.charges_enabled) {
        // fully on-boarded
        status = STRIPE_STATUS.COMPLETED
        buttonLabel = STRIPE_BUTTON_LABEL.VIEW_STRIPE_PLATFORM
    } else if (stripeAccount.details_submitted) {
        // completed onboarding, pending stripe verification
        status = STRIPE_STATUS.PENDING_VERIFICATION
        buttonLabel = STRIPE_BUTTON_LABEL.VIEW_STRIPE_PLATFORM
    } else if (!stripeAccount.details_submitted) {
        // on-boarding not completed, need to create login link
        status = STRIPE_STATUS.IN_PROGRESS
        buttonLabel = STRIPE_BUTTON_LABEL.CONNECT_TO_STRIPE
    }
    return {status, buttonLabel}
  }

  export const getPromotionTotals = (promotions) => {
    try {
        let totalActive = null
        let totalPending = null
        let totalSchedule = null
        let totalExpiring = null

        promotions.forEach(item => {
            const sDate = fromUnixTime(Number(item.start_timestamp))
            const eDate = fromUnixTime(Number(item.end_timestamp))

            if (item.approval && !item.approval.isApproved) {
                totalPending += 1
            }

            if (isPast(sDate) && item.valid==='Y') {
                totalActive += 1
            }

            if (isFuture(sDate) && item.valid==='Y') {
                totalSchedule += 1
            }

            if (differenceInDays(eDate, new Date()) <= 7 && item.valid==='Y') {
                totalExpiring += 1
            }
        })


        return {totalActive: totalActive || '-', totalPending: totalPending || '-', totalSchedule: totalSchedule || '-', totalExpiring: totalExpiring || '-'}
    } catch(error) {
        console.error('error on getPromotionTotals ', error)
        throw error
    }
  }

  export const getVoucherTotals = async (purchasableDeals) => {
    try {
        let totalVouchers = null
        let totalActive = null
        let totalPending = null
        let totalSchedule = null
        let totalExpiring = null

        for (let i=0; i < purchasableDeals.length; i++) {
            let vouchers = await listVouchersByDealId(purchasableDeals[i].pk)
            totalVouchers += vouchers.length
    
            if (purchasableDeals[i].approval && !purchasableDeals[i].approval.isApproved) {
                totalPending += vouchers.length
            } else {
                for (let ii=0; ii < vouchers.length; ii++) {

                    const sDate = fromUnixTime(Number(vouchers[ii].startTimestamp))
                    const eDate = fromUnixTime(Number(vouchers[ii].endTimestamp))
                    
                    if (isPast(sDate) && vouchers[ii].valid==='1' && purchasableDeals[i].valid==='Y') {
                        totalActive += 1
                    }

                    if (isFuture(sDate) && vouchers[ii].valid==='1' && purchasableDeals[i].valid==='Y') {
                        totalSchedule += 1
                    }

                    if (differenceInDays(eDate, new Date()) <= 7 && vouchers[ii].valid==='1' && purchasableDeals[i].valid==='Y') {
                        totalExpiring += 1
                    }
                }
            }
        }

        return {totalVouchers: totalVouchers || '-', totalActive: totalActive || '-', totalPending: totalPending || '-', totalSchedule: totalSchedule || '-', totalExpiring: totalExpiring || '-'}
    } catch(error) {
        console.error('error on getVoucherTotals ', error)
        throw error
    }

  }

  export const computeSoldVouchers = (voucherCodes) => {
    // const total = voucherCodes.length
    const filteredAllVouchers = voucherCodes.filter(item => item.status === '1' || item.status==='0')
    const total = filteredAllVouchers.length

    const filteredAvailable = voucherCodes.filter(item => item.status==='0')
    const available = filteredAvailable.length

    let sold = 0
    voucherCodes.forEach(item => {
        // no userId(not bought yet) OR status===2(refunded)
        if (item.userId && item.status==="1") sold += 1
    })
    return {sold, total, available}
  }
  
  export const computeWaitlistSoldVouchers = (voucherCodes) => {
    // const total = voucherCodes.length
    const filteredAllVouchers = voucherCodes.filter(item => item.status === '1' || item.status==='0')
    const total = filteredAllVouchers.length

    const filteredAvailable = voucherCodes.filter(item => item.status==='0')
    const available = filteredAvailable.length

    let sold = 0
    voucherCodes.forEach(item => {
        // no userId(not bought yet) OR status===2(refunded)
        if (item.userId && (item.status==="1" || item.status==="8")) sold += 1
    })
    return {sold, total, available}
  }

  export const getHashVersion = async (endPoint) => {
    let data = ''
    const response = (await fetch(`${endPoint}/hash.txt`))
    if (response.status === 200) {
        data = await response.text();
    }
    return data
}

export const RemoveFreeTrial = async(userInfo) => {
    let user = await getUser(userInfo.email)
    let removeDate = new Date('July 01 2022') //remove free trial from this date for new user registration
    let createdAt = user.createdAt
    let createdDate =  new Date(createdAt.substring(0, 10) + ' 00:00')
    let diff = getDateDiff(removeDate, createdDate)

    if(diff <=0 && user.subPlan === 'free'){
      return true
    }else{
      return false
    }
  }

export const getDateDiff = (removeDate, createdDate) => {
    var Diff = Math.floor((removeDate.getTime() - createdDate.getTime()) / (1000 * 60 * 60 * 24));
    return Diff
}

export const parseQueryString = (queryString) => {
    try {
        return queryString
            .replace('?', '')
            .split('&')
            .map(param => param.split('='))
            .reduce((values, [ key, value ]) => {
                values[ key ] = value
                return values
            }, {})
    } catch(error) {
        console.error(error)
        return ''
    }
}

export const remoteConfig = async(configName) => {
    const remoteConfig = getRemoteConfig();
    remoteConfig.settings.minimumFetchIntervalMillis = 300;
    let rmConfig
    await fetchAndActivate(remoteConfig)
    .then(()=>{
      rmConfig = getValue(remoteConfig,  configName)
    })
    return rmConfig
}

  export const lastURLSegment = async(href) => {
    const segments = new URL(href).pathname.split('/');
    const last = segments.pop() || segments.pop();
    return last
  }

  export const getHappeningOn = (from, to) => {
    try {
        let _startTimestamp = fromUnixTime(Number(from))
        let _endTimestamp = fromUnixTime(Number(to))
        
        if (_startTimestamp && _endTimestamp){
            
            let diff = differenceInDays(_endTimestamp, _startTimestamp)
            let dateStart = format(_startTimestamp, "dd MMMM yyyy")
            let timeStart = format(_startTimestamp, "h:mmaaaaa'm'").replace(':00', '')
            let dateEnd = format(_endTimestamp, "dd MMMM yyyy")
            let timeEnd = format(_endTimestamp, "h:mmaaaaa'm'").replace(':00', '')
        
            if (diff > 0){
                let _dateTime = `${dateStart}, ${timeStart} to ${dateEnd}, ${timeEnd}`
                return  _dateTime
            } else {
                    let _dateTime = `${dateStart}, ${timeStart} to ${timeEnd}`
                    return  _dateTime    
            } 
        } else {
            return 'N/A'
        }   
    } catch (e) {
        console.error(e)
    }
  }

  export const getHappeningOn24 = (from, to) => {
    try {
        let _startTimestamp = fromUnixTime(Number(from))
        let _endTimestamp = fromUnixTime(Number(to))
        
        if (_startTimestamp && _endTimestamp){
            
            let diff = differenceInDays(_endTimestamp, _startTimestamp)
            let dateStart = format(_startTimestamp, "dd MMMM yyyy")
            let timeStart = format(_startTimestamp, "HH:mm")
            let dateEnd = format(_endTimestamp, "dd MMMM yyyy")
            let timeEnd = format(_endTimestamp, "HH:mm")
        
            if (diff > 0){
                let _dateTime = `${dateStart}, ${timeStart} to ${dateEnd}, ${timeEnd}`
                return  _dateTime
            } else {
                    let _dateTime = `${dateStart}, ${timeStart} to ${timeEnd}`
                    return  _dateTime    
            } 
        } else {
            return 'N/A'
        }   
    } catch (e) {
        console.error(e)
    }
  }

export const getHappeningOnFullInfo = (from, to) => {
    try {
        let _startTimestamp = fromUnixTime(Number(from))
        let _endTimestamp = fromUnixTime(Number(to))

        if (_startTimestamp && _endTimestamp) {

            let dateStart = format(_startTimestamp, "dd MMMM yyyy")
            let timeStart = format(_startTimestamp, "h:mm aa")
            let dateEnd = format(_endTimestamp, "dd MMMM yyyy")
            let timeEnd = format(_endTimestamp, "h:mm aa")
            let startDay = format(_startTimestamp, 'EEEE')
            let endDay = format(_endTimestamp, 'EEEE')

            let _dateTime = `${startDay}, ${dateStart} ${timeStart} - ${endDay}, ${dateEnd} ${timeEnd}`
            return _dateTime
        } else {
            return 'N/A'
        }
    } catch (e) {
        console.error(e)
    }
}

export const getDisplayTime24 = (date) => {
    try {
        let timestamp = fromUnixTime(Number(date))

        if (timestamp) {
            let dateStart = format(timestamp, "dd MMMM yyyy")
            let timeStart = format(timestamp, "HH:mm")
            let _dateTime = `${dateStart}, ${timeStart}`
            return _dateTime
        } else {
            return 'N/A'
        }
    } catch (e) {
        console.error(e)
    }
}

export const getErrorMessage = (msg) => {
    const message = msg === 'no_more_available_vouchers' ? "There is no more voucher(s) available."
                    : msg === 'not_enough_vouchers' ? "There is not enough voucher(s) left."
                    : msg === 'min_voucher_not_meet' ? "Minimum voucher(s) requirement not met."
                    : msg === "request_voucher_exceed_limit" ? "Voucher request has exceeded current limit."
                    : msg === "purchase_limit_hit" ? "Voucher request has exceeded current limit."
                    : msg === "pending_reserved_needs_release" ? "An incomplete transaction has been detected, please complete the transaction first ... or retry again after 30 minutes"
                    : msg === "duplicate_group_name"? "Group name has been created before. Create a unique one."
                    : msg === "group_not_found" ? "Group name cannot be found."
                    : msg === "best_dress_group_limit_hit" ? "The selected group has hit the limit of maximum member for best dress."
                    : msg === "pending_reserved_needs_release" ? "There is a pending reservation made on this account and waiting to be release."
                    : msg === "blacklisted_bankId" ? "Please contact recreation club(RecClub-Events.SG@sc.com) for further assistance"
                    : "We have encounter some issues processing your reservation. Please try again later."
    return message
}