import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { useSelector, useDispatch } from "react-redux";
import {Container, Row, Col} from "reactstrap"
import ModalVideo from "react-modal-video"
import "react-modal-video/scss/modal-video.scss"

// import store actions
import { setBreadcrumbItems, setAlert } from "../../../store/actions";

// import components
import Guide from "./Components/guide";

// import assets
import mediaKitImg from '../../../assets/images/mediaKit.png'
import bizImg from '../../../assets/images/bizUserGuide.png'
import walkthroughImg from '../../../assets/images/walkthroughVideo.png'
// import marketplaceImg from '../../../assets/images/marketplaceVideo.png'

// import utils
import { openInNewTab } from "utils";

const UserGuide = () => {
    const dispatch = useDispatch()
    const selectedMerchant = useSelector(state => state.Merchant.selectedMerchant)
    const [walkthruVideoOpen, setWalkthruVideoOpen] = useState(false)
    
    const breadcrumbItems = [
        { title: "CardsPal", link: "#" },
        { title: selectedMerchant.display_merchant_name, link: "#" },
        { title: "Support", link: "#" },
        { title: "User Guide", link: "#" },
    ]

    useEffect(() => {
        dispatch(setBreadcrumbItems('User Guide', breadcrumbItems))
        dispatch(setAlert(''))
    },[selectedMerchant])

    const handleMediaKit = () => {
        openInNewTab('https://cardspal.com/wp-content/uploads/2022/03/CardsPal-Media-Kit_Mar-2022.pdf')
    }

    const handleBizUserGuide = () => {
        openInNewTab('https://cardspal.com/wp-content/uploads/2022/03/CardsPal-Merchant-Guide_Mar-2022.pdf')
    }

    const handleWalkthrough = (state) => {
        setWalkthruVideoOpen(state)
        // openInNewTab('https://www.youtube.com/watch?v=GxwAze8yuoI&')
    }

    // const handleMarketlace = () => {

    // }

    return (
        <React.Fragment>

            <MetaTags>
                <title>User Guide</title>
            </MetaTags>
            <Container> 
                <Row>
                    <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Guide
                            image={mediaKitImg}
                            title={'CardsPal Media Kit'}
                            subTitle={'Last updated Mar 2022'}
                            description={"A brief introduction of CardsPal's Value proposition and product"}
                            buttonName={'View PDF'}
                            onClick={()=>{handleMediaKit()}}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={4} xl={4} >
                        <Guide
                            image={bizImg}
                            title={'CardsPal for Biz Merchant Guide'}
                            subTitle={'Last updated Mar 2022'}
                            description={"A brief introduction of CardsPal's Value proposition and product"}
                            buttonName={'View PDF'}
                            onClick={()=>{handleBizUserGuide()}}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Guide
                            image={walkthroughImg}
                            title={'Product Walkthrough Video'}
                            subTitle={'Last updated Dec 2021'}
                            description={"A brief introduction of CardsPal's Value proposition and product"}
                            buttonName={'Watch Video'}
                            onClick={()=>{handleWalkthrough(true)}}
                        />
                        <ModalVideo
                            videoId="GxwAze8yuoI"
                            channel="youtube"
                            isOpen={walkthruVideoOpen}
                            onClose={() => {
                                setWalkthruVideoOpen(false)
                            }}
                        />
                    </Col>
                    {/* <Col>
                        <Guide
                            image={marketplaceImg}
                            title={'Marketplace Video (CUT)'}
                            subTitle={'Last updated Dec 2021'}
                            description={"A brief introduction of CardsPal's Value proposition and product"}
                            buttonName={'Watch Video'}
                            onClick={()=>{handleMarketlace()}}
                        />
                    </Col> */}
                    
                </Row>
            </Container>
        </React.Fragment>
    )

}


export default UserGuide;