
import React  from "react"
import { Row, Col, Label } from  'reactstrap'
import { Image } from "react-bootstrap"
import Switch from 'react-switch'
import { AvField, AvForm } from "availity-reactstrap-validation"

// import components
import OffSymbol from './offSymbol'
import OnSymbol from "./onSymbol"

// import assets
import thumbsup from '../../../../assets/images/thumbsup.png'

const CreateTerms = (props) => {

    function refresh(e, item, index) {
        // we override the target so we can re-used the handlInputChange function to trigger a change
        e.target.value = props.allTerms[index].termsText
        props.handleInputChange(e, item, index)
    }

    function createSwitch(item, index){

        return (
            <Row style={{marginBottom:"4pt"}} key={"row"+index}>
                <Col xs="3">
                    <Image fluid style={{minWidth: "12pt", objectFit:"cover"}} src={item.recommended==="1"?thumbsup:null} />&nbsp;
                    <Switch
                        uncheckedIcon={<OffSymbol />}
                        checkedIcon={<OnSymbol />}
                        onColor="#02a499"
                        onChange={() => {
                            props.switchTermsState({...item, ON: !item.ON}, index)
                        }}
                        checked={item.ON}
                    />
                </Col>
                <Col>
                    {item.ON ?
                        <div>
                            <AvForm>
                                <AvField
                                    name={item.id}
                                    placeholder={item.placeholderText || ''}
                                    type="textarea"
                                    validate= {{ maxLength: {value: item.order === '1'? 2000 : 250 }}}
                                    rows="2"
                                    className="form-control terms-textarea"
                                    value={item.termsText}
                                    onChange={(e) => props.handleInputChange(e, item, index)}
                                />
                            </AvForm>
                            <a data-testid="refreshIcon" href="#" onClick={(e) => refresh(e, item, index)}><span className="icon-refresh" ></span></a>
                            <span className="border border-info bg-info rounded-2 px-1 text-center" style={{float:'right', marginTop: -15, color:'#FFF', fontSize: 10}}>{item.totalChars}</span>
                        </div>
                        :
                        <Label style={{marginTop: "4pt"}}>{item.description}</Label>
                    }
                </Col>
            </Row>
        )
    }

    function displayTerms() {
        return props.additionalInfo.map((item, index) => createSwitch(item, index))
    }

    return (
        displayTerms()
    )
}


export default CreateTerms