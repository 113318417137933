import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import { connect, useSelector } from "react-redux"
// import { Row, Col } from "reactstrap"

import RBAC from '../../components/Common/rbac'

import { Link } from "react-router-dom"

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"

// Import menuDropdown
// import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
// import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
// import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import LogoutMenu from  '../CommonForBoth/TopbarDropdown/logoutMenu'

// import images
// import megamenuImg from "../../assets/images/megamenu-img.png"
import logo from "../../assets/images/cp-logo.png"
import logoLightPng from "../../assets/images/logo-transparent.png"
import professionalProfileIcon from "../../assets/images/avatar/plan-avatar-pro.png"
import starterProfileIcon from "../../assets/images/avatar/plan-avatar-starter.png"
import freeProfileIcon from "../../assets/images/avatar/plan-avatar-free.png"
import bronzeProfileIcon from "../../assets/images/avatar/plan-avatar-bronze.png"
import goldProfileIcon from "../../assets/images/avatar/plan-avatar-gold.png"
import silverProfileIcon from "../../assets/images/avatar/plan-avatar-silver.png"

// import logoDark from "../../assets/images/logo-dark.png"

//i18n
import { withTranslation } from "react-i18next"

// store
import { setSelectedMerchant } from '../../store/merchant/actions'

// import utils
import { capitalizeFirstLetter  } from '../../utils'
import { FREE_PLAN, STARTER_PLAN_1, STARTER_PLAN_2, STARTER_PLAN_3, PROFESSIONAL_PLAN, SILVER_PLAN, BRONZE_PLAN, GOLD_PLAN, WAIVER_PLAN } from '../../utils/constants'

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"

const Header = props => {
  const [merchants, setMerchants] = useState([])
  const [createmenu, setCreateMenu] = useState(false)
  const user = useSelector(state => state.User.user)
  const selectedMerchant = useSelector(state => state.Merchant.selectedMerchant)
  const [profileIcon, setProfileIcon] = useState(freeProfileIcon)
  const [sideBarType, setSideBarType] = useState('')

  useEffect(() => {
    if (!user || Object.keys(user).length === 0) return 
    
    switch (user.subPlan) {
      case PROFESSIONAL_PLAN:
      case WAIVER_PLAN:
        setProfileIcon(professionalProfileIcon)
        break;
      case STARTER_PLAN_1:
      case STARTER_PLAN_2:
      case STARTER_PLAN_3:
        setProfileIcon(starterProfileIcon)
        break;
      case SILVER_PLAN:
        setProfileIcon(silverProfileIcon)
        break;
      case BRONZE_PLAN:
        setProfileIcon(bronzeProfileIcon)
        break;
      case GOLD_PLAN:
        setProfileIcon(goldProfileIcon)
        break
      default:
        setProfileIcon(freeProfileIcon)
    }
  }, [user])

  useEffect(() => {
    setMerchants(props.userMerchants)
  }, [props.userMerchants])

  useEffect(() => {
    let _sideBarType = localStorage.getItem("sideBarType")
    setSideBarType(_sideBarType)
    
  }, [])
  

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body;
    if (window.screen.width > 992) {
      body.classList.toggle("vertical-collpsed");
    }else{
      body.classList.toggle('data-keep-enlarged')
    }
    body.classList.toggle("sidebar-enable");
  }

  function selectBrand(merchant) {
    localStorage.setItem('selectedMerchant', JSON.stringify(merchant))
    props.setSelectedMerchant(merchant)
  }

  // create a dropdown menu of the user brands
  function createDropDown() {
    const keys = Object.keys(merchants);
    return (
      <DropdownMenu className="dropdown-menu-end">
        {
          keys.map(key => {
              return <DropdownItem key={merchants[key].merchant_id} tag="a" href="/dashboard" onClick={() => selectBrand(merchants[key])}>View {merchants[key].display_merchant_name}</DropdownItem>
          })
        }
        <RBAC merchantId={selectedMerchant.merchant_id} roles={['admin']}>
          <div className="dropdown-divider"></div>
          {user && Object.keys(user).length >0 && (user.subPlan !== FREE_PLAN) || (user && Object.keys(user).length >0 && user.subPlan === FREE_PLAN && keys.length === 0)?
            <DropdownItem tag="a" href="#" onClick={() => props.showManagedBrand(true, 'create')}>Add new brand</DropdownItem>
            : null
          }
          <DropdownItem tag="a" href="/manage-outlets">Add new outlet</DropdownItem>
          <div className="dropdown-divider"></div>
          <DropdownItem tag="a" href="#" onClick={() => props.showManagedBrand(true, 'update')}>Manage Brand</DropdownItem>
        </RBAC>
      </DropdownMenu>
    )
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              {/* <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="17" />
                </span>
              </Link> */}

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoLightPng} alt="" height="40" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-24 header-item waves-effect vertical-menu-btn"
              id="vertical-menu-btn"
            >
              <i className="mdi mdi-menu"></i>
            </button>
            {sideBarType === 'default' && 
              <div>
                <Dropdown
                  isOpen={createmenu}
                  toggle={() => setCreateMenu(!createmenu)}
                  className="d-inline-block"
                >

                  <div className="dropdown dropdown-topbar pt-3 mt-1 d-inline-block">

                    <DropdownToggle
                      className="btn btn-light"
                      tag="button"
                    >
                      Quick Actions <i className="mdi mdi-chevron-down"></i>
                    </DropdownToggle>
                    {createDropDown()}

                  </div>
                </Dropdown>
              </div>
            }
          </div>
          <div className="d-flex">
            <div style={{marginTop: "17px"}}>
              
              <a href= {sideBarType === 'default' ? "/manage-plans" : "#" }>
                <span style={{ marginLeft: "10px", fontWeight:"bold" }}>{capitalizeFirstLetter(user && user.subPlan ? user.subPlan : 'FREE')}</span>
                <img
                  style= {{marginLeft: "10px"}}
                  className="rounded-circle header-profile-user"
                  src={profileIcon}
                  alt="Header Avatar"
                />
              </a>
            </div>
            {/* <ProfileMenu /> */}
            {/* <LanguageDropdown /> */}
            <div className="dropdown d-none d-lg-inline-block">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen()
                }}
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen"
              >
                <i className="mdi mdi-fullscreen font-size-24"></i>
              </button>
            </div>
            {/* <NotificationDropdown /> */}
            
            <LogoutMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
  user: PropTypes.any,
  selectedMerchant: PropTypes.any,
  userMerchants: PropTypes.any,
}

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout
  const { selectedMerchant, userMerchants } = state.Merchant
  
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType, selectedMerchant, userMerchants }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  setSelectedMerchant,
})(withTranslation()(Header))
