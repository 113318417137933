/* eslint-disable no-unused-vars */
import React from "react"
import { Modal } from "reactstrap"

import oldPlanImg from '../../../../assets/images/oldPlan.png'

const OldPlanOverlay = (props) => {

    return (
        <Modal
            size="lg"
            isOpen={props.modalImageStatus}
            toggle={() => {
                props.showForm(false, 'imageOverlay')
            }}
            centered={true}
            backdrop={true}
            contentClassName="oldPlanImage"
        >
            <img src={oldPlanImg} />
        </Modal>
    )
}

export default OldPlanOverlay