import React, { useState, useEffect } from 'react';
import { Button, Form, Image } from 'react-bootstrap';
import { Row, Col, CardBody, Card, Container, Dropdown,
        DropdownToggle, DropdownMenu, DropdownItem, Spinner } from "reactstrap"
import styled from 'styled-components';
import ClockPng from '../../assets/images/clockCircle.png'
import LocationPng from '../../assets/images/Location.png'
import RegistrationSuccessfulPng from '../../assets/images/registrationSuccessful.png'
import TicketPng from '../../assets/images/ticket.png'
import PinPng from '../../assets/images/pin.png'
import DownloadBannerPng from '../../assets/images/DownloadBanner.png' 
import AccessDenied from 'components/Common/accessDenied';
import platform from 'platform';
import { APP_STORE, PLAY_STORE } from 'utils/constants';
import { openInNewTab } from 'utils';
import { fromUnixTime, format } from 'date-fns';
import { atcb_action, atcb_init } from 'add-to-calendar-button';
import 'add-to-calendar-button/assets/css/atcb.css';
import {getEnv } from 'helpers/apiHelper';


const B2BRegistrationSuccessful = (props) => {

    const [isAccessDenied, setAccessDenied] = useState(null);
    const [calendarEvent, setCalendarEvent] = useState({})

    const registrationInfo = props.location.state;
    const faviconPath = `https://assets.${getEnv()}.cardspal.com/public/Events/favicon/`

    useEffect(() => {
        if (registrationInfo){
            let startTime = fromUnixTime(Number(registrationInfo.deal.start_timestamp))
            let endTime = fromUnixTime(Number(registrationInfo.deal.end_timestamp))
            const event = {
                title: registrationInfo.event.name,
                description: registrationInfo.event.description,
                startTime: startTime,
                endTime: endTime,
                location: registrationInfo.event.location,
              }
            document.title = event.title;

            let link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = `${faviconPath}${registrationInfo.deal.pk}_favicon.ico`;
            setCalendarEvent(event)
            setAccessDenied(false)
        }else{
            setAccessDenied(true)
        }
        
    }, [])

    

    useEffect(atcb_init, []);
    
    const downloadApp = (e) => {
        e.preventDefault()
        e.stopPropagation()
        
        const OS = platform.os.family   
        if (OS === 'iOS') {
            openInNewTab(APP_STORE)
        } else if (OS === 'Android') {
            openInNewTab(PLAY_STORE)
        } else {
            openInNewTab(PLAY_STORE)
        }
    }

    const addToCalendar = () => {
        if (registrationInfo){
            let unixStartTimestamp = fromUnixTime(Number(registrationInfo.event.startTimestamp))
            let unixEndTimestamp = fromUnixTime(Number(registrationInfo.event.endTimestamp))
            let startDate = format(unixStartTimestamp, "yyyy-MM-dd")
            let endDate = format(unixEndTimestamp, "yyyy-MM-dd")
            let startTime = format(unixStartTimestamp, "HH:mm")
            let endTime = format(unixEndTimestamp, "HH:mm")

        atcb_action({
            name: registrationInfo.event.name,
            startDate: startDate,
            endDate: endDate,
            startTime: startTime,
            endTime: endTime,
            description:registrationInfo.event.description,
            location: registrationInfo.event.location,
            options:[
                "Google|Google Calendar",
                "Apple|Apple Calendar",
                "Microsoft365|Outlook Calendar",
                "Yahoo|Yahoo Calendar"
              ],
            timeZone: "Asia/Singapore",
            iCalFileName: registrationInfo.event.name,
               
          })
        }
    }

    return (
        <React.Fragment>
            {isAccessDenied === null ? <></> :
                ( isAccessDenied ? <AccessDenied screen="B2B" showBackToDashboard={false}/> : 
                <Container>
                    <Row className='mt-5 justify-content-center'>
                        <Col>
                            <Card>
                                <CardBody>
                                    <div className='d-flex flex-row align-items-center my-4'>
                                        <IconImage fluid src={RegistrationSuccessfulPng}/>
                                        <H4>Registration Successful!</H4>
                                    </div>
                                    <div>
                                        <H4>Thank you for registering for <span>{registrationInfo.event.name}</span></H4>
                                        <P>You will receive an email confirmation shortly at <Email>{registrationInfo?.webFormData?.email}</Email>.</P>
                                    </div>

                                    <div className='d-flex flex-row align-items-center mt-4'>
                                        <IconImage fluid src={TicketPng}/>
                                        <div>

                                            <P>Transaction ID: <ReferenceNo>{registrationInfo.data?.id ? registrationInfo.data?.id : "-"}</ReferenceNo></P>                                     
                                            <P>{registrationInfo.hideTicketInfo ? "": registrationInfo.qty+"x Visitor Pass" }</P>
                                        </div>
                                    </div>

                                    <div className='d-flex flex-row align-items-center mt-4'>
                                        <IconImageSm fluid src={PinPng}/>
                                        <div>
                                            <P>{registrationInfo.event.name}</P>
                                        </div>
                                    </div>

                                    <div className='d-flex flex-row mt-4'>
                                        <IconImage fluid src={ClockPng}/>
                                        <div>
                                            <P>{registrationInfo.deal.happeningOn}{registrationInfo?.custom?.seatingInfoText ? ` (${registrationInfo.custom.seatingInfoText})` : null}</P>
                                            <P1>Note: Some of these options provided work better on desktop browser.</P1>
                                            <div className="mt-2">
                                                <SubmitButton onClick={()=>addToCalendar()}>Add to Calendar</SubmitButton>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='d-flex flex-row align-items-center mt-4'>
                                        <IconImage fluid src={LocationPng}/>
                                        <div>
                                            <P>{registrationInfo.event.location}</P>
                                        </div>
                                    </div>

                                </CardBody>
                                
                            </Card>
                            <a href='#' onClick={(e)=> downloadApp(e)}>
                                <DownloadAppBanner fluid src={DownloadBannerPng}/>
                            </a>
                            
                        </Col>
                    </Row>
                    
                </Container>
                )
            }
        </React.Fragment>
    )
}

const IconImage = styled(Image)`
    width: 40px;
    height: 40px;
    margin-right: 10px;
`

const IconImageSm = styled(Image)`
    width: 30px;
    height: 30px;
    margin: 10px;
`

const DownloadAppBanner = styled(Image)`
    display: block;
    width: 100%;
    //height: 100%;
`
const H4 = styled.h4`
    color: #000;
    span {
    color: #722ED1;
  }
`
const Email = styled.span`
color: #000;
font-weight: bold;
`
const ReferenceNo = styled.span`
color: #000;
font-weight: bold;
`

const P = styled.p`
    margin: 0;
`

const P1 = styled.p`
    font-size: 10px;
    margin: 0;
`

const SubmitButton = styled(Button)`
background: linear-gradient(117.03deg, #3672F8 0%, #B01EFF 100%);
`

export default B2BRegistrationSuccessful