import React from "react"
import { Button, Container, Card, CardBody, Spinner, CardText } from "reactstrap"
import { useDispatch } from "react-redux";
import { Image } from "react-bootstrap"
import _404 from '../../assets/images/404.png'
import styled from "styled-components";
// import store actions
import { setAmplitudeEvent } from '../../store/actions'

const AccessDenied = props => {
  const dispatch = useDispatch()

  const details = props.details

  function track() {
    const screen = props.screen
    let _ampTrack = {
      'error': props.errorMsg || 'Networkj error',
    }
    dispatch(setAmplitudeEvent(`CMS ${screen} page -Access Denied`, {..._ampTrack}))
  }

  return (
    <React.Fragment>
      
        <div className='text-center' style={{ width: '100%', position:'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <Image404 fluid src={_404}/>
            <Label404>
                404!
            </Label404>
            <LabelPageNotFound>
                Sorry, Page not found!
            </LabelPageNotFound>
            { props.showBackToDashboard && 
             <Button color="primary" className="mt-3">Back to Dashboard</Button> }
        </div>
      
    </React.Fragment>
  )

}

const Image404 = styled(Image)`
  height: 148px;
  width: 86px;
  margin-bottom: -10;
`
const Label404 =  styled.p`
  font-size: 70px;
  font-weight: bold;
  color: black;
  margin-top: -10px;
  margin-bottom: -10px;
`
const LabelPageNotFound = styled.h3`
  
`

export default AccessDenied
