import React, { useEffect } from "react"
import { Row, Col, Modal, ModalBody, Button } from "reactstrap"
import { Image } from "react-bootstrap"
import OppsPng from '../../../assets/images/Opps.png'
import styled from "styled-components"
import { TIME_IS_UP, NO_SLOT,PROCESSING_ERROR, RESERVE_ERROR, DUPLICATE_ERROR, GROUP_NOT_FOUND, OTHER_ERROR_MSG } from "utils/constants"
import { getErrorMessage } from 'utils'
const WarningPopup = (props) => {
    const message = getErrorMessage(props.message)
    return (
        <Modal
            size='sm'
            isOpen={props.open}
            centered={true}
            onOpened={props.onOpened}
        >
            <ModalBody className='justify-content-center text-center'>
                <OppsImage
                    fluid
                    src={OppsPng}
                />
                <h4 className="mt-3 mb-3">Opps...</h4>

                { props.warningType === NO_SLOT ?
                    <div>
                        <p>No more slots left. Better luck next time!</p>
                        <Button href="https://cardspal.com" tag='a' color="primary" className="mt-2 mb-2">Back to event page</Button>
                    </div>
                :props.warningType === PROCESSING_ERROR ?
                    <div>
                        <p>Something went wrong. Please try again.</p>
                        <Button onClick={()=>props.tryAgain()} color="primary" className="mt-2 mb-2">Try again</Button>
                    </div>
                :props.warningType === RESERVE_ERROR || props.warningType === DUPLICATE_ERROR || props.warningType === GROUP_NOT_FOUND?
                    <div>
                        <p>{message}</p>
                        <Button onClick={()=>props.tryAgain()} color="primary" className="mt-2 mb-2">Try again</Button>
                    </div>
                :props.warningType === TIME_IS_UP ?
                    <div>
                        <p>Times up! Please try again if you wish to continue with your purchase.</p>
                        <Button onClick={()=>props.tryAgain()} color="primary" className="mt-2 mb-2">Try again</Button>
                    </div>
                :props.warningType === OTHER_ERROR_MSG ?
                    <div>
                        <p>{props.message}</p>
                        <Button onClick={()=>props.tryAgain()} color="primary" className="mt-2 mb-2">Try again</Button>
                    </div>
                : null }
            </ModalBody> 
            
        </Modal>
    )
}

const OppsImage = styled(Image)`
  
  width: 86px;
  height: 150px;
  
`

export default WarningPopup