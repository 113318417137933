import React, { useEffect, useState } from "react"
import { Row, Col } from 'reactstrap'

const ListOutlets = (props) => {

    const [outletListRight, setOutletListRight] = useState([])
    const [outletListLeft, setOutletListLeft] = useState([])

    useEffect(async () => {
        iterateOutletList()
    }, [props.outlets, props.selectedStoredIds])

    function iterateOutletList() {
        let right = []
        let left = []
        if (props.outlets) {
            const totalOutlets = props.outlets.length
    
            for (let i = 0; i < totalOutlets; i++) {
                // we will alternately display the outlet side by side
                if (i % 2) {
                    right.push(
                        <div className="form-check mt-1" key={i}>
                            <input disabled={props.disableEditing} type="checkbox" key={i + props.outlets[i].store_id} 
                                className="form-check-input" checked={isChecked(props.outlets[i])} onChange={(e) =>props.handleOutletChange(e, props.outlets[i].store_id)} />
                            <label key={i} className="form-check-label fontWeightLight">{props.outlets[i].outlet_name}</label>
                        </div>
                    )
                } else {
                    left.push(
                        <div className="form-check mt-1" key={i}>
                            <input disabled={props.disableEditing} type="checkbox" key={i + props.outlets[i].store_id} 
                                className="form-check-input" checked={isChecked(props.outlets[i])} onChange={(e) => props.handleOutletChange(e, props.outlets[i].store_id)} />
                            <label key={i} className="form-check-label fontWeightLight" >{props.outlets[i].outlet_name}</label>
                        </div>
                    )
                }
            }
        }
        setOutletListRight(right)
        setOutletListLeft(left)
    }

    function isChecked(outlet) {
        return props.selectedStoredIds && props.selectedStoredIds.includes(outlet.store_id)
    }

    return (
        <div>
            <Row>
                <Col>
                    <div className="form-check">
                        <input disabled={props.disableEditing} key="apply" type="checkbox" checked={props.isAllOutletChecked} 
                            className="form-check-input" onChange={(e) => props.handleOutletChange(e, 'all')}/>
                        <label className="form-check-label subTitle">Apply to all outlets*</label>
                    </div>
                </Col>
            </Row>
            {(outletListRight.length===0 && outletListLeft.length===0)?
            <Row><span className="danger">Please create an outlet first as this field is required.</span></Row>
            :
            <Row className="scrollview scrollbar scrollbar-primary" style={{ maxHeight: "400px" }}>
                <Col>{outletListLeft}</Col>
                <Col>{outletListRight}</Col>
            </Row>
            }
        </div>
    )
}

export default ListOutlets