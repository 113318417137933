import React, { useEffect, useState } from "react"
import styled from "styled-components"
import axios from "axios"
import bullet from '../../assets/images/check-circle.png'
import { Button } from "reactstrap"
import { Image } from 'react-bootstrap';
import { ComingSoonCountDown } from '../../pages/Webform/Components/ComingSoonCountDown';
import { Description } from '../../pages/Webform/Components/Styled';
import parse from 'html-react-parser'

const EventTicket_template = props => {
  const { deal, bundle, customPromoText } = props;
  const promotion = props.promotion ?? true
  const bundleQty = props.issueQty ?? 2
  const splitPromotion = deal?.promotion.split("\n")
  const descriptionStyling = { fontFamily: 'Rubik', fontStyle: "normal", fontWeight: "400", fontSize: "16px", lineHeight: "15px", color: "#394452", marginTop: "3vh", marginBottom: "1vh", maxWidth: "100%" }

  const [comingSoon, setComingSoon] = useState((deal.selling_startTimestamp * 1000) > new Date().getTime())


  return (
    <ItemContainer onClick={() => deal?.dealLink && !comingSoon && window.open(deal.dealLink, '_blank')}>

      <style type="text/css">
        {`
                @media screen and (min-width: 769px) {
                  .btn-purple {
                  background-color: #722ED1 !important;
                  color: white;
                  min-height:2vh;
                  min-width:9vw;
                  font-size:1.8vw;
                  font-weight:600;
                  }
                }
                @media screen and (max-width: 768px) {
                  .btn-purple {
                  background-color: #722ED1 !important;
                  color: white;
                  min-height:1vh;
                  min-width:7vw;
                  font-size:3vw;
                  font-weight:600;
                  }
                }
                `}
      </style>

      <TextContainer>
        <TextSubtext>
          {comingSoon ?
            <ComingSoonCountDown targetDate={deal?.selling_startTimestamp} setComingSoon={(value) => setComingSoon(value)} />
            :
            null
          }
          <PromotionCaption>{deal.promotion_caption}</PromotionCaption>
          {customPromoText ?
            // <Description style={descriptionStyling ?? {}}>{customPromoText}</Description>
            <Promotion><Image style={{ marginRight: "1%", width: "5%", display: promotion ? "block" : "none" }} fluid src={bullet} />{customPromoText}</Promotion>
            :
            <Description style={descriptionStyling ?? {}}>{deal?.ticketInfo ? parse(deal?.ticketInfo) : ""}</Description>

            // splitPromotion.map((text, i) =>
            //   <Promotion key={i}><Image style={{ marginRight: "1%", width: "5%", display: promotion ? "block" : "none" }} fluid />• {text}</Promotion>
            // )
          }
        </TextSubtext>

        <PriceContainer>
          <PriceContent>
            {Number(deal.purchasePrice) === Number(deal.actualPrice) ? null : 
              <PriceText style={{ fontSize: 16, color: "#FF5F5F", fontWeight: 400, textDecorationLine: 'line-through' }}>S${bundle ? (Number(deal.actualPrice) * bundleQty).toFixed(2) : (Number(deal.actualPrice)).toFixed(2)}</PriceText>
            }
            <PriceText>S${bundle ? (Number(deal.purchasePrice) * bundleQty).toFixed(2) : (Number(deal.purchasePrice)).toFixed(2)} (inclu. tax)</PriceText>
          </PriceContent>
          {comingSoon ?
            null
            :
            <Button className="btn-purple">Select</Button>
          }
        </PriceContainer>
      </TextContainer>
    </ItemContainer>
  )
}

const ItemContainer = styled.div`
  display: inline-block;
  margin-right:1%;
  margin-top:1%;
  margin-bottom:1%;
  background-color: white;
  border-radius: 14px;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  width:100%;
`
const TextContainer = styled.div`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #ffffff;
  font-family:Rubik;
  color: black;
  display:flex;
  flex-direction:column;
  justify-content: space-between;
  align-self: flex-end;
  height:100%;
  padding: 2%;
`
const TextSubtext = styled.div`
  padding-left: 2vw;
  padding-right: 2vw;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  min-height:14vh;
  @media (max-width: 768px) {
     min-height:10vh;
  }
`
const Promotion = styled.div`
  font-size: 1.2vw;
  font-weight: 400;
  width:95%;

  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 768px) {
     font-size: 3.2vw;
  }
`
const PromotionCaption = styled.div`
  margin-top: 4%;
  margin-bottom: 1%;
  font-size: 1.8vw;
  font-weight:600;
  @media (max-width: 768px) {
    font-size:3.6vw;
  }
`
const PriceContainer = styled.div`
  display: flex;
  flex: 1;
  padding-bottom: 1vh;
  margin-top:1%;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-bottom: 1vw;
  align-items:end;
`
const PriceContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
const PriceText = styled.div`
  font-weight:600;
  font-size: 1.8vw;
  @media (max-width: 768px) {
    font-size: 3.6vw;
  }
`

export default EventTicket_template
