export const LOGIN_USER_NOT_FOUND = { code: 'UserNotFoundException', message: 'User does not exist' }
export const LOGIN_USER_NOT_CONFIRMED = { code: 'UserNotConfirmedException', message: 'User is not confirmed' }
export const LOGIN_INCORRECT_USERNAME_PASSWORD = { code: 'NotAuthorizedException', message: 'Incorrect username or password' }
export const REGISTER_USER_EXIST = {code: 'UsernameExist', message: 'Email already exist' }
export const OTP_EMAIL_EXCEED = { code: 'LimitExceededException', msg: 'Infra limit on email exceed in processing new account.'}
export const CODE_DELIVERY_FAILURE_EXCEPTION = {code : 'CodeDeliveryFailureException', message: 'Email address is not verified. Please inform admin. (SES) '} 
export const FREE_PLAN = 'free' //default subscription plan
export const STARTER_PLAN_1 = 'starter-1'
export const STARTER_PLAN_2 = 'starter-2'
export const STARTER_PLAN_3 = 'starter-3'
export const PROFESSIONAL_PLAN = 'professional'
export const WAIVER_PLAN = 'waiver'
export const BRONZE_PLAN = 'bronze'
export const SILVER_PLAN = 'silver'
export const GOLD_PLAN = 'gold'
export const DEFAULT_MERCHANT_IMAGE = 'https://assets.prod.cardspal.com/DefaultImages/MerchantImages/defaultMerchant.png'
export const DEFAULT_REFRESH_URL = 'https://biz.cardspal.com'
export const DEFAULT_RETURN_URL = 'https://biz.cardspal.com'
export const VOUCHER_CODE_TYPE = {
    UPLOADED:'uploaded',
    GENERATED: 'generated'
}
export const STRIPE_STATUS = {
    NOT_ONBOARDED: 'Not onboarded',
    COMPLETED: 'Completed',
    PENDING_VERIFICATION: 'Pending verification',
    IN_PROGRESS: 'In progress'
}

export const STRIPE_BUTTON_LABEL = {
    VIEW_STRIPE_PLATFORM: 'View Stripe Platform',
    CONNECT_TO_STRIPE: 'Connect to our Stripe Account'
}

export const GENERIC_ERROR_MESSAGE = 'Please report this error to the Administrator.'
export const VOUCHER_CODE_TEMPLATE_FILE = 'https://assets.prod.cardspal.com/public/VoucherCodes/voucher-code-template.csv'
export const ON_BOARDING = 'on-boarding'

export const SELL_VOUCHERS_TERMS_TEMPLATE_ONLINE = `● Purchased vouchers can be redeemed at all merchant outlets OR can be redeemed at all merchant outlets except [xxx outlets] OR website until <Insert date>.
● User must be using the latest version of the CardsPal app and signed up for an account to make the purchase.
● All purchased vouchers are valid only within Singapore and their value is equivalent to the Singapore currency as showcased on the purchased voucher.
● [No minimum spending is required. /There is a minimum spend of xx required.] 
● The purchased voucher [can or cannot] be used for in-store promotions only and [can or cannot] be used in conjunction with other offers or promotions.
● The purchased voucher [can or cannot] be stacked with other partner promotions.
● Purchased vouchers [are not available or available] for online store purchases.
● [The purchased voucher can be used for redemptions storewide (subject to availability) OR to redeem all items except for [items] (subject to availability).]
● The voucher cannot be used in exchange for lottery products, tobacco & alcohol products, and other products which we will specify from time to time.
● Deal is limited to [one (1) voucher purchase] per user.
● Each voucher can only be redeemed once.
● The purchased voucher [can or cannot] be used for general, sale or promotion items.
● The purchased voucher/voucher code/eGift Card [can or cannot] be used to purchase vouchers.
● [To redeem your purchased voucher at merchant outlets/website, please copy the voucher code for use on merchant’s website OR key in the voucher code at merchant outlets kiosk system OR use at merchant outlets. Merchant outlet staff [may or will] key in the voucher code to redeem.]
● Do not click on the ‘Mark as redeemed’ button until you have redeemed the voucher code. Vouchers marked redeemed will not be valid.
● The purchased voucher will be considered as redeemed once it has been clicked or swiped for redemption.
● The purchased voucher is strictly non-transferable, non-refundable and not exchangeable for cash.
● There will be no extension of expired vouchers
● Any unused value from the voucher will not be refunded.
● There will be no replacement for any stolen, lost or damaged vouchers.
● GST and service charges are [included or not included] in the voucher price.
● Voucher redemptions [will or will not] be counted towards merchant’s membership collection of stamps, points or any form of rewards.
● Multiple vouchers of varying or similar denominations [can or cannot] be combined for use in a single transaction.
● For any voucher related issues, please write in to [merchant’s contact email] to enquire.
● Please refer to [merchant’s page] for other applicable voucher terms and exclusions.  
● [The voucher purchase is valid for new CardsPal users only.]
● No additional top-up is allowed. 
● [Surcharge of [amount] may be chargeable for [service required/product].
● [(For dining merchants) The purchased voucher is valid for dine-in and takeaway.] 
● (For dining merchants) The purchased voucher is valid on all days except public holidays and Sundays.]
`

export const SELL_VOUCHERS_TERMS_TEMPLATE_SHOPFRONT = `● Purchased vouchers can be redeemed at all merchant outlets OR can be redeemed at all merchant outlets except [xxx outlets] OR website until <Insert date>.
● User must be using the latest version of the CardsPal app and signed up for an account to make the purchase.
● All purchased vouchers are valid only within Singapore and their value is equivalent to the Singapore currency as showcased on the purchased voucher.
● [No minimum spending is required. /There is a minimum spend of xx required.] 
● (For dining merchants) The purchased voucher is valid for dine-in and takeaway. 
● (For dining merchants) The purchased voucher is valid on all days except public holidays and on [blackout dates].
● [The purchased voucher can be used for redemptions storewide (subject to availability) OR to redeem all items except for [items] (subject to availability).]
● The voucher cannot be used in exchange for lottery products, tobacco & alcohol products, and other products which we will specify from time to time.
● The purchased voucher can be used for in-store promotions only.
● The purchased voucher [can or cannot] be stacked with other partner promotions.
● Purchased vouchers are not available for online store purchases.
● Deal is limited to [one (1) voucher purchase] per user.
● Each voucher can only be redeemed once. 
● The purchased voucher [can or cannot] be used for general, sale or promotion items.
● The purchased voucher/voucher code/eGift Card [can or cannot] be used to purchase vouchers.
● To redeem your purchased voucher, please ensure that you are present at the merchant outlet and in front of an outlet staff. Staff [may or will] redeem the voucher on your behalf.
● Do not swipe redeemed until the redemption has been completed. Vouchers marked redeemed will not be valid.
● The purchased voucher will be considered as redeemed once it has been clicked or swiped for redemption.
● The purchased voucher is strictly non-transferable, non-refundable and not exchangeable for cash.
● There will be no extensions of expired vouchers.
● Any unused value from the voucher will not be refunded.
● There will be no replacement for any stolen, lost or damaged vouchers.
● GST and service charges are [included or not included] in the voucher price.
● Voucher redemptions [will or will not] be counted towards merchant’s membership collection of stamps, points or any form of rewards.
● Multiple vouchers of varying or similar denominations [can or cannot] be combined for use in a single transaction.
● For any voucher related issues, please write in to [merchant’s contact email] to enquire.
● Please refer to [merchant’s page] for other applicable voucher terms and exclusions.  
● [The voucher purchase is valid for new CardsPal users only.]
● No additional top-up is allowed. 
● [Surcharge of [amount] may be chargeable for [service required/product].
`
export const MANDATORY_TERMS = `● CardsPal reserves the right to vary/amend these Terms and Conditions without prior notice.
● CardsPal shall not be responsible for the quality, merchantability or the fitness for any purpose or any other aspect of the products and/or services provided by third parties
● All pictures shown are for illustration purposes only.
● By purchasing a voucher, you hereby agree to these terms and conditions.
● All decisions made by CardsPal shall be final.
`
export const MANDATORY_TERMS_SUBTITLE_SHOPFRONT = 'These T&Cs will be added after your listed T&Cs to cover for shopfront redemption deals.'
export const MANDATORY_TERMS_SUBTITLE_ONLINE = 'These T&Cs will be added after your listed T&Cs to cover for online promo code/URL deals.'
export const MANDATORY_TERMS_SUBTITLE_EVENT = 'Write your T&Cs here and preview it in the box below. CardsPal mandatory T&Cs will be added after your listed T&Cs to cover for ticket purchase and redemption.'

//WEBFORM
export const NAME_REGEX = /^[^0-9~`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/  
export const PHONE_REGEX = /\+|(\d+)$/
export const MASKNAME_REGEX = /\B[a-z]/g
export const MASKEMAIL_REGEX = /(\w{1})[\w.-]+@([\w.]+\w)/
export const MARKETING_TEXT = `I wish to receive marketing materials (such as news on other exciting events) from the organisers, 
 and consent to the disclosure of my personal data to the organiser for such purpose.`
export const TERMS = 'https://cardspal.com/ticket-sales-terms-and-conditions/'
export const TICKET_API = 'cardspalcmsv2api'
export const APP_STORE = 'https://itunes.apple.com/app/id1511465447'
export const PLAY_STORE = 'https://play.google.com/store/apps/details?id=com.cardspal&hl=en_SG'
export const QRSCAN_TEXT = 'Please allow permission to access device camera and ensure your pop-up blocker is disabled for this feature to function.'

//D&D
export const BANK_ID = 'To be used for entry checks'
export const BANKID_REGEX = /^[0-9]{7}$/
export const GROUP_NAME = 'If your group is under a different menu option, you may create a new group' 
export const GROUP_ENTRY = 'Group name cannot be changed after submission'
export const MENU = 'If the menu option is not suitable, you can select "Others" in the above field to create a new group'
export const DRESS = 'Only groups of 12 are eligible to take part in best dress'
export const CATEGORY_DND = 'dnd'
export const OTHER_OPTION = 'New Group - Select this to create a new group'
export const SCB_DND_TERMS = 'https://cardspal.com/standard-chartered-singapore-dinner-dance-2022-terms-and-conditions/'
export const INDIVIDUAL_FUSION = 'individual - fusion'
export const INDIVIDUAL_FUSION_VEGETARIAN = 'individual - fusion vegetarian'
export const GROUP_NAME_REGEX = /[^a-zA-Z0-9 ]/g

//QR SCAN
 export const WEBFORM_ID = "0001"
 export const MERCHANT_TEXT = 'This feature is only available for merchants hosting or selling event tickets'
 export const PARTNERSHIP_TEXT = 'If you wish to host/sell event tickets, please contact our partnership team at'
 export const PARTNERSHIP_EMAIL = 'mailto:partnership@cardspal.com'

 //B2B
export const NO_SLOT = 'noslot'
export const TIME_IS_UP = 'timeisup'
export const STOP_TIMER = 'stop'
export const START_TIMER = 'start'
export const RESET_TIMER = 'reset'
export const PROCESSING_ERROR = 'processingError'
export const RESERVE_ERROR = 'reserveError'
export const CANCEL_TRANSACTION_CODE = '9'
export const DUPLICATE_ERROR = 'duplicate_group_name'
export const GROUP_NOT_FOUND = 'notgroup_not_found_found'
export const OTHER_ERROR_MSG = 'other_error'

//Events
export const ALPHANUMERIC_REGEX = /^[a-zA-Z0-9-]+$/
export const NUMBERONLY_REGEX = /^[0-9]+$/
export const LATLONG_REGEX = /^-?\d{1,2}(?:\.\d+)?, ?-?\d{1,3}(?:\.\d+)?$/
export const URL_REGEX = /^(https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/[\w-]+)*\/?(\?\S*)?(?!.*[<>|\\^{}[\]`!#$%&'"()*+,/:;=?@])$/

//Style Constant
export const THEME_COLOR = {
    primary : "#272833",
    accent :"#7A6FBE"
}

export const DND_OCT2023_CATEGORY = 'dnd_oct_2023'

export const DEFAULT_DND_OCT_2023_GROUPS = [
    { value: 'Individual - Fusion', label: 'Individual - Fusion' },
    { value: 'Individual - Fusion Vegetarian', label: 'Individual - Fusion Vegetarian' }         
]

export const DEFAULT_DND_OCT_2023_MENU = [
    { value: 'Fusion', label: 'Fusion' },
    { value: 'Fusion Vegetarian', label: 'Fusion Vegetarian' }         
]

export const DND_BEST_DRESS_MAX_MEMBERS = 12

export const WAITLIST_DEAL_IDS = ['3c31e0fc-ef2b-a38f-6a2c-c5236d3386fd','9b97b7b9-b301-c482-3513-d79adee4ffe3']