import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useForm, Controller } from "react-hook-form";
import { Button, Form, Image } from 'react-bootstrap';
import { Row, Col, CardBody, Card, Alert, Container, Spinner } from "reactstrap"
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import styled from 'styled-components';
import * as Yup from 'yup';
import {
    Title, SubTitle, Underline, CardContainer, DateTimeData,
    TicketType, ErrMsg, Sup, SubmitButton, Terms, DeclineButton, PriceLabel
} from './Styled';
import { createWebformData } from 'helpers/apiHelper'
import Popup from './Popup'
import amplitude from 'amplitude-js';

const ContactInfo = (props) => {

    const schema = Yup.object().shape({
        email: Yup.string()
            .email('Invalid email address')
            .required('Required'),
        countryCode: Yup.string(),
        phoneNumber: Yup.string(),
        firstName: Yup.string().required("Required"),
        lastName: Yup.string().required("Required"),
        terms: props.custom?.tnc ? Yup.boolean()
            .required("Required")
            .oneOf([true], "Please accept terms and conditions") : null,
        marketingConsent: props.custom?.marketingConsent ? Yup.boolean() : null,
    })

    const initialValues = {
        email: props.prefill?.email ?? '',
        countryCode: props.prefill?.countryCode ?? '+65',
        phoneNumber: props.prefill?.phone ?? '',
        firstName: props.prefill?.firstName ?? '',
        lastName: props.prefill?.lastName ?? '',
    }

    const onSubmitDecline = async (e) => {
        try {
            props.setIsBusy()
            let phoneNumber = `${e.countryCode}${e.phoneNumber}`
            e.reason = (e.reasonText && e.reason === "Others") ? e.reason + " - " + e.reasonText : e.reason
            let webFormData = {
                "webFormId": props.webFormId,
                "email": e.email,
                "phoneNumber": phoneNumber,
                "reason": e.reason,
                "firstName": e.firstName,
                "lastName": e.lastName,
                "prepopulated": "1"
            }
            let response = await createWebformData(webFormData)
            if (response) {
                if (response.body == "user_exists") {
                    setErrorMessage("You have already submitted your declination.")
                }
                else {
                    setNotAttending(false)
                    setRsvped(true)
                }

            }
            props.setNotBusy()
            amplitude.getInstance().logEventWithGroups('B2B - Decline')
        } catch (error) {
            console.error('onSubmitDecline', error)
        }
    }

    const [state] = useState(initialValues);
    const [notAttending, setNotAttending] = useState(false)
    const [rsvped, setRsvped] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")



    const { register, unregister, getValues, setValue, handleSubmit, reset, clearErrors, control, watch, formState: { errors, isSubmitSuccessful, isValid } } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: state,
        resolver: yupResolver(schema)
    });

    const onChange = (e) => {
        // console.log('---onChange', e)
    }

    const onSubmit = (e) => {
        // console.log('---submit', e)
    }

    const onBlur = (e) => {
        // console.log('---blur', e)
    }

    const onError = (e) => {
        // console.log('---error', e)
    }

    const redirect = (URL) => {
        window.location.href = URL
    }

    return (
        <React.Fragment>
            <Row className='justify-content-center'>
                <Col>
                    <Form onSubmit={handleSubmit(props.onSubmit, onError)} onChange={(e) => onChange(e)} onBlur={(e) => onBlur(e)}>
                        <fieldset disabled={props.isDisabled}>
                            <CardContainer style={ props.styling ?? { backgroundColor: "#fff" }}>
                                <CardBody>
                                    <SubTitle>To start off, please share with us your contact info.</SubTitle>
                                    <Row>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <Form.Label>First name<Sup>*</Sup></Form.Label>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <Form.Control
                                                type="text"
                                                name="firstName"
                                                placeholder="Enter your first name "
                                                className={errors.firstName && "error"}
                                                autoComplete='off'
                                                {...register("firstName")}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="firstName"
                                                render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                            />
                                        </Col>
                                    </Row>
                                    <Underline className='mb-3' />
                                    <Row>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <Form.Label>Last name<Sup>*</Sup></Form.Label>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <Form.Control
                                                type="text"
                                                name="lastName"
                                                placeholder="Enter your last name"
                                                className={errors.lastName && "error"}
                                                autoComplete='off'
                                                {...register("lastName")}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="lastName"
                                                render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                            />
                                        </Col>
                                    </Row>
                                    <Underline className='mb-3' />
                                    <Row>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <Form.Label>Email address<Sup>*</Sup></Form.Label>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <Form.Control
                                                type="text"
                                                name="email"
                                                placeholder="Enter your email"
                                                className={errors.email && "error"}
                                                autoComplete='off'
                                                autoCapitalize='none'
                                                disabled={props.isDisabled}
                                                {...register("email")}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="email"
                                                render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                            />
                                        </Col>
                                    </Row>
                                    <Underline className='mb-3' />
                                    <Row>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <Form.Label>Phone number</Form.Label>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <Row>
                                                <Col xs={'auto'}>
                                                    <Controller
                                                        name='countryCode'
                                                        control={control}
                                                        render={({ field: { onChange, value, name, ref } }) => {
                                                            const handleSelectChange = (e) => {
                                                                onChange(e?.value);
                                                            };
                                                            return (
                                                                <Select
                                                                    inputRef={ref}
                                                                    value={props.countryCode.find((e) => e.value === value)}
                                                                    options={props.countryCode}
                                                                    placeholder='Select...'
                                                                    onChange={handleSelectChange}
                                                                    styles={{
                                                                        control: base => ({
                                                                            ...base,
                                                                            height: 34,
                                                                            minHeight: 34,
                                                                            width: 120,
                                                                        })
                                                                    }}
                                                                    isDisabled={props.isDisabled}
                                                                />
                                                            )
                                                        }}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="countryCode"
                                                        render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Control
                                                        type="number"
                                                        name="phoneNumber"
                                                        placeholder="Enter your phone number"
                                                        className={errors.phoneNumber && "error"}
                                                        autoComplete='off'
                                                        {...register("phoneNumber")}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="phoneNumber"
                                                        render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                                    />

                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </CardContainer>
                            {props.custom?.hasPayment ?
                                <Row className='justify-content-center' style={{ marginTop: 10 }}>
                                    {props.custom?.tnc &&
                                        <Terms>
                                            <Form.Check
                                                name="terms"
                                                type="checkbox"
                                                className={errors.terms && "error"}
                                                label={<div style={{ color: "#272833" }}>I have read and agree to the <a href='https://cardspal.com/ticket-sales-terms-and-conditions/' target='_blank' rel='noreferrer'>T&Cs of Ticket Sales</a> and <a href='https://cardspal.com/privacy-policy/' target='_blank' rel='noreferrer'>CardsPal&apos;s Data Privacy Policy</a>.*</div>}
                                                {...register("terms")} />
                                            <ErrorMessage
                                                errors={errors}
                                                name="terms"
                                                render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                            />
                                        </Terms>

                                    }
                                    {props.custom?.marketingConsent &&
                                        <Terms>
                                            <Form.Check
                                                name="marketingConsent"
                                                type="checkbox"
                                                label={<div style={{ color: "#272833" }}>I wish to receive marketing materials (including news on other exciting events) from the organisers and/or CardsPal, and consent to the disclosure of my personal data to the organisers for such purpose.</div>}
                                                {...register("marketingConsent")} />
                                            <ErrorMessage
                                                errors={errors}
                                                name="marketingConsent"
                                                render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                            />
                                        </Terms>
                                    }
                                    <SubmitButton
                                        type="submit"
                                        className="btn btn-primary btn-block"
                                        disabled={
                                            !isValid ||
                                            Object.keys(errors).length > 0
                                        }
                                    >Continue to payment
                                    </SubmitButton>
                                </Row> :
                                <>
                                    {props.custom?.tnc &&
                                        <Row className='justify-content-center m-3 pt-3'>
                                            <Terms>
                                                <Form.Check
                                                    name="terms"
                                                    type="checkbox"
                                                    className={errors.terms && "error"}
                                                    label={<div style={{ color: "#272833" }}>I have read and agree to the <a href='https://cardspal.com/ticket-sales-terms-and-conditions/' target='_blank' rel='noreferrer'>T&Cs of Ticket Sales</a> and <a href='https://cardspal.com/privacy-policy/' target='_blank' rel='noreferrer'>CardsPal&apos;s Data Privacy Policy</a>.*</div>}
                                                    {...register("terms")} />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="terms"
                                                    render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                                />
                                            </Terms>
                                        </Row>
                                    }
                                

                                    <Row className='justify-content-center'>
                                        <SubTitle className='text-center'>Will you be attending this event ?</SubTitle>
                                        <SubmitButton
                                            type="submit"
                                            className="btn btn-primary btn-block"
                                            disabled={
                                                !isValid ||
                                                Object.keys(errors).length > 0
                                            }
                                        >Yes
                                        </SubmitButton>
                                        {props.custom?.attendanceCheck &&
                                            <DeclineButton
                                                onClick={() => setNotAttending(true)}
                                                className="btn btn-primary "
                                                disabled={
                                                    !isValid ||
                                                    Object.keys(errors).length > 0
                                                }
                                            >No</DeclineButton>
                                        }
                                    </Row>
                                </>
                            }
                            <Popup
                                open={notAttending}
                                inputType="reason"
                                inputLabel="Reason"
                                title="Reason for not attending"
                                name="reason"
                                register={register}
                                watch={watch}
                                error={errorMessage}
                                isBusy={props.isDisabled}
                                submit={handleSubmit(onSubmitDecline, props.onError)}
                                cancel={() => (setNotAttending(false), setErrorMessage(""), unregister("reason"), unregister("reasonText"))}
                            />
                            <Popup
                                open={rsvped}
                                inputType={'message'}
                                title="Submission Successful"
                                message="Thank you for your time."
                                cancel={() => (setRsvped(false), unregister("reason"), unregister("reasonText"), props.custom?.redirectURL ? redirect(props.custom?.redirectURL) : null)}
                            />
                        </fieldset>
                    </Form>
                </Col>
            </Row>
        </React.Fragment>

    )
}

export default ContactInfo