import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import { useForm, Controller } from "react-hook-form";
import { Form } from 'react-bootstrap';
import { Row, Col, CardBody, Spinner, Modal, ModalFooter, ModalHeader, ModalBody, Button } from "reactstrap"
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import * as Yup from 'yup';
import { UncontrolledTooltip } from 'reactstrap';
import {
    Underline, CardContainer, ToolTipIcon, GroupP, 
    ErrMsg, SubmitButton, SpinnerDiv, P
} from './Styled';
import { getGroupMembers, updateWebFormGroup } from 'helpers/apiHelper'
import { OTHER_OPTION, DRESS, GROUP_ENTRY, MENU, DND_OCT2023_CATEGORY, DEFAULT_DND_OCT_2023_MENU,
    DEFAULT_DND_OCT_2023_GROUPS, DND_BEST_DRESS_MAX_MEMBERS, OTHER_ERROR_MSG } from  'utils/constants'
import WarningPopup from './WarningPopup'
import Popup from './Popup';

const GroupUpdate = (props) => {
    console.log('GroupUpdate',props)
    const groupName = props.data.name
    const adminHash=props.adminHash
    const webFormId = '0009'
    const menuData = DEFAULT_DND_OCT_2023_MENU
    // const groupData = DEFAULT_DND_OCT_2023_GROUPS

    const schema = Yup.object().shape({
        groupNameOption: Yup.string().required("Required"),
        groupName: Yup.string().when('groupNameOption', {
            is: OTHER_OPTION, 
            then: Yup.string()
                .trim('The field cannot start or end with a space')
                .matches(/^[a-zA-Z0-9- ]*$/, 'Only alphanumeric characters and dash are allowed')
                .required('Required'), 
            otherwise: Yup.string().trim('The field cannot start or end with a space') 
        }),
        dress: Yup.string().required("Required"),
        menu: Yup.string().required("Required")
    })

    const [isLoading, setIsLoading] = useState(true)
    const [groupNames, setGroupNames] = useState([])
    const [selectedGroupName, setSelectedGroupName] = useState({})
    const [selectedMenu, setSelectedMenu] = useState([])
    const [groupNameOptionNote, setGroupNameOptionNote] = useState('')
    const [viewMembersLink, setViewMembersLink] = useState('')
    const [isDataLoaded, setIsDataLoaded] = useState(false)
    const [warning, setWarning] = useState({show: false})
    const [showSuccess, setShowSuccess] = useState(false)
    const [groupData, setGroupData] = useState(props.data)
    const [groupMembers, setGroupMembers] = useState([])
    const [disableBestDressOption, setDisableBestDressOption] = useState(false)

    const { 
        register, 
        setValue, 
        getValues,
        handleSubmit, 
        control, 
        formState: { 
            errors, 
            isValid 
        } 
    } = useForm({
            mode: "onChange",
            reValidateMode: "onChange",
            resolver: yupResolver(schema)
        });

    useEffect(async () => {
        if ((!groupData && !groupData.name) || isDataLoaded) return
    
        await getGroupData({value: groupData.name})
        const createdGroups = [{ name : groupData?.name }]
        const finalGroup = createdGroups.concat([{ name: OTHER_OPTION }])
        const data = finalGroup.map(item => {
            return {
                value: item.name,
                label: item.name,
            }
        })
        setGroupNames(data)
        setIsDataLoaded(true)
        setIsLoading(false)
    }, [isDataLoaded])

    useEffect(() => {

    }, [groupMembers])

    const setViewMembers = async (members) => {
        if (members.length > 0) {
            const viewMembers = `/webform/view-group-members/${groupName}?webFormId=${webFormId}&adminHash=${adminHash}`
            setViewMembersLink(
                <span style={{ fontSize:11, textDecoration:"underline" }}>
                    <a style={{ color: 'blue' }} href={viewMembers} target='_blank' rel='noreferrer'>View members</a>
                </span>
            )
        } else {
            setViewMembersLink('')
        }
    }

    const handleGroupChange = (e) => {
        setViewMembersLink('')
        setSelectedGroupName(e)

        if (e.value === OTHER_OPTION) {
            setGroupNameOptionNote('')
            setValue('groupName', '')
            setValue('menu','')
            setValue('dress','')
            setSelectedMenu('')
        } else {
            getGroupData(e)
        }
        setValue('groupNameOption', e.value)
    }

    const getGroupData = async (e) => {
        const response = await getGroupMembers(e.value, webFormId)
        if (response.statusCode === 200){
            const members = JSON.parse(response.body)
            setGroupMembers([...members])
            console.log({members})
            // checkBestDressOptionStatus(members)
            let groupOwner = members.filter(item => {
                return item.creator === '1' ;
            });
            // console.log({groupOwner})
            
            if (groupOwner.length > 0) {
                const owner = groupOwner[0]
                const menu = { value: owner.menu, label: owner.menu }
                const dress = owner.dress
                setSelectedMenu(menu)
                setSelectedGroupName({label: owner.groupName, value: owner.groupName})
                setValue('groupNameOption', owner.groupName)
                setValue('groupName', owner.groupName)
                setValue('menu', menu.value)
                setValue('dress', dress)
                setViewMembers(members)
                const leaderName = owner.firstName + ' ' + owner.lastName
                setGroupNameOptionNote(<GroupP>Only {members?.length} people are in this group now. Group leader is {leaderName}</GroupP>)
            }
        }
    }

    const handleMenuChange = (e) => {
        setSelectedMenu(e)
        setValue('menu', e.value)
    }

    const DndTemplate = () => {
        return (
            <>
            {selectedGroupName.value === OTHER_OPTION &&
                <>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Form.Label>Enter Group Name<sup>*</sup>
                            <ToolTipIcon id='third' />
                            <UncontrolledTooltip
                                placement='right'
                                target='third'
                            >
                                <p style={{ textAlign: 'left', marginBottom: 0 }}>{GROUP_ENTRY}</p>
                            </UncontrolledTooltip>
                            </Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Control  
                                type="text" 
                                name="groupName"
                                placeholder="Input your group name" 
                                className={errors.groupName && "error"}
                                {...register("groupName")}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="groupName"
                                render={({ message }) => <P>{message}</P>}
                            />
                        </Col>
                    </Row>
                    <Underline className='mb-3' />
                </>
            }
            
            <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Menu Choice<sup>*</sup>
                    { selectedGroupName?.value !== OTHER_OPTION  && 
                        <span>
                        <ToolTipIcon id='fourth' />
                        <UncontrolledTooltip
                            placement='right'
                            target='fourth'
                        >
                            {MENU}
                        </UncontrolledTooltip>
                        </span> }
                </Form.Label>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Controller
                        name='menu' 
                        control={control}
                        render={({ ref }) => (
                            <Select
                            // isDisabled={disableGroupOption || props.disableEditing}
                            isSearchable
                            inputRef={ref}
                            value={selectedMenu}
                            options={menuData}
                            placeholder='Select an option'
                            onChange={handleMenuChange}
                            />
                        )}
                        />
                        <ErrorMessage
                        errors={errors}
                        name="menu"
                        render={({ message }) => <P>{message}</P>}
                    />
                </Col>
            </Row>
            <Underline className='mb-3' />
            <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Form.Label>Register for Best Dressed Table?<sup>*</sup>
                        <ToolTipIcon id='tooltip' />
                        <UncontrolledTooltip
                            placement='right'
                            target='tooltip'
                        >
                            <p style={{ textAlign: 'left', marginBottom: 0 }}>{DRESS}</p>
                        </UncontrolledTooltip>
                    </Form.Label><br />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Form.Check
                        disabled={disableBestDressOption}
                        inline
                        name="dress"
                        type="radio" 
                        label="Yes"
                        value="1"
                        {...register("dress")}
                    />
                    <Form.Check 
                        disabled={disableBestDressOption}
                        inline
                        name="dress"
                        type="radio" 
                        label="No"
                        value="0"
                        style={{marginLeft: 120}}
                        {...register("dress")}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="dress"
                        render={({ message }) => <P>{message}</P>}
                    />
                </Col>
            </Row>
            <Underline className='mb-3' />
            </>
        )
    }

    const processSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        const v = getValues()
        // console.log({v})
        
        let params = {
            ...groupData,
            ...v,
            // groupName :selectedGroupName.value===OTHER_OPTION ? v.groupName : selectedGroupName.value,
            category : DND_OCT2023_CATEGORY,
            webFormId
        }
        // console.log({groupData})
        if (!params.groupName) {
            setWarning({show: true, warningType: 'processingError', message: 'group_not_found'})
            return
        }
        console.log('processSubmit: ', {params})
        const result = await updateWebFormGroup(params)
        switch (result.statusCode) {
            case 404:
                setWarning({show: true, warningType: result.error, message: result.error})
                break
            case 409:
                setWarning({show: true, warningType: result.error, message: result.error})
                break;
            case 500:
            case 502:
                setWarning({show: true, warningType: 'processingError', message: result.error})
                break;
            default:
                setWarning({show: false})
                setShowSuccess(true)
                setGroupData(JSON.parse(result.body))
        }
        setIsLoading(false)
    }

    const close = (reload) => {
      props.close(reload)
    }

    const onChangeFormFields = (e) => {
        // console.log('onChangeFormFields ',e.target.name, e.target.value, groupMembers.length, DND_BEST_DRESS_MAX_MEMBERS)
        if (e.target.name==='dress' && e.target.value==='1' && groupMembers.length > DND_BEST_DRESS_MAX_MEMBERS) {
            setWarning({show: true, warningType: OTHER_ERROR_MSG, message: `Cannot select BEST DRESS option as the current members are more than ${DND_BEST_DRESS_MAX_MEMBERS}`})
            setValue('dress', '0')
        }
    }

    return (
      <Modal
        size="lg"
        isOpen={props.open}
        toggle={() => {
            close(false)
        }}
        centered={true}
      >
        <ModalHeader toggle={() => close(false)}>
          Update group information
        </ModalHeader>
        <ModalBody>
            <Row className='justify-content-center' style={{}}>
                <Col>
                    <Form onChange={(e) => onChangeFormFields(e)}>
                        <fieldset disabled={props.isDisabled}>
                            <CardContainer style={props.styling ?? { backgroundColor: "#fff" }} >
                                <CardBody>
                                    <div key={"contactInfo"} className='mb-4' >
                                      <Row>
                                          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                              <Form.Label>Group Name<sup>*</sup>
                                                  <ToolTipIcon id='second' />
                                                  <UncontrolledTooltip
                                                      placement='right'
                                                      target='second'
                                                  >
                                                        <p style={{ textAlign: 'left', marginBottom: 0 }}>
                                                        Can select from the default group options or groups created by other user.
                                                        <br/>Can also create your own group by selecting the Others option.
                                                        </p>
                                                  </UncontrolledTooltip>
                                              </Form.Label>
                                          </Col>
                                          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                              <Controller
                                                  name="groupNameOption"
                                                  control={control}
                                                  render={({ ref }) => (
                                                      <>
                                                      <Select
                                                          isDisabled={props.disableEditing}
                                                          isSearchable
                                                          inputRef={ref}
                                                          value={selectedGroupName}
                                                          options={groupNames}
                                                          placeholder='Select...'
                                                          onChange={handleGroupChange}
                                                          styles={{
                                                              control: base => ({
                                                                  ...base,
                                                                  height: 34,
                                                                  minHeight: 34
                                                              })
                                                          }}
                                                      />
                                                      {viewMembersLink}
                                                      </>
                                                  )}
                                              />
                                              <ErrorMessage
                                                  errors={errors}
                                                  name="groupNameOption"
                                                  render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                              />
                                              {groupNameOptionNote}
                                          </Col>
                                      </Row>
                                      <Underline className='mb-3' />
                                      <DndTemplate/>
                                          
                                      {isLoading &&
                                          <SpinnerDiv>
                                              <Spinner />
                                          </SpinnerDiv>}
                                  </div>
                                </CardBody>
                            </CardContainer>
                            <Row className='justify-content-center' style={{ marginTop: 10 }}>
                              <span style={{textAlign:"center", color:"red"}}>This will update all the members of the group</span>
                            </Row>
                            
                        </fieldset>
                    </Form>
                </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
          <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
            <SubmitButton
                type="submit"
                className="btn btn-primary btn-block"
                onClick={(e) => processSubmit(e)}
                disabled={
                    !isValid ||
                    Object.keys(errors).length > 0
                }
            >Update group information
            </SubmitButton>
            </div>
          </ModalFooter>
            { warning.show && <WarningPopup open={true} warningType={warning.warningType} message={warning.message} tryAgain={()=>setWarning({show: false})} /> }
            { showSuccess && <Popup open={true} inputType='message' message='Group information has been update and all its users.' cancel={()=> close(true)} title='Group update'/> }
      </Modal>
    )
}

export default GroupUpdate