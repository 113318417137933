import React, { useEffect, useState } from "react"
import { connect, useSelector, useDispatch } from "react-redux";
import { Image } from "react-bootstrap"
import { Row, Col, Modal, ModalFooter, ModalHeader, ModalBody, Button, Label, Alert, Spinner, InputGroup } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { fromUnixTime, getUnixTime, endOfDay } from 'date-fns'

// import components
import ImageUploader from  '../../../components/Common/imageUploader'

// import hepers functions
import { listOutletsByMerchantId } from '../../../helpers/GraphQL/outlet'
import { create, update, getPromoByMerchantUniqueId } from  '../../../helpers/GraphQL/promotions'
import * as DealApproval from '../../../helpers/GraphQL/dealApproval'
import { getConfig, sendEmail } from '../../../helpers/apiHelper'

// import utils
import { getAssetUrl, listDifference, guidGenerator, handleImageUpload, isValidHttpUrl, isValidURL } from '../../../utils'

// import actions
import  { setAmplitudeEvent } from '../../../store/actions'

const PromotionForm = (props) => {
    const dispatch = useDispatch()
    const selectedMerchant = useSelector(state => state.Merchant.selectedMerchant)
    const user = useSelector(state => state.User.user)
    const config = useSelector(state => state.Config)
    const [promo, setPromo] = useState({})
    const [outlets, setOutlets] = useState([])
    const [processing, setProcessing] = useState(false)
    const [transactionMessage, setTransactionMessage] = useState(null)
    const [selectedStoredIds, setSelectedStoreIds] = useState([])
    const [CMSConfig, setCMSConfig] = useState({})
    // const [hasNoStartDate, setHasNoStartDate] = useState(false)
    // const [hasNoEndDate, setHasNoEndDate] = useState(false)
    const [isAllOutletChecked, setIsAllOutletChecked] = useState(false)
    const [croppedImages, setCroppedImages] = useState({})
    const [validUrl, setValidUrl] = useState(true)

    useEffect(() => {
        if (props.mode === 'update') {
            setPromo({ 
                ...props.promotion, 
                merchant_id: selectedMerchant.merchant_id,
                mainCat: selectedMerchant.mainCat,
                cc_buddy_category: selectedMerchant.mainCat,
                ccbuddy_card_name: 'NULL',
                merchant_name: selectedMerchant.merchant_name,
                merchant_name_lowercase: selectedMerchant.merchant_name_lowercase,
                display_merchant_name: selectedMerchant.display_merchant_name
            })
            setProcessing(false)
            setTransactionMessage(null)
            // setHasNoStartDate(!props.promotion.start_timestamp)
            // setHasNoEndDate(!props.promotion.end_timestamp)
            setSelectedStoreIds([props.promotion.store_id])
        } else {
            setPromo({ 
                ...props.promotion,
                merchant_id: selectedMerchant.merchant_id, 
                mainCat: selectedMerchant.mainCat,
                cc_buddy_category: selectedMerchant.mainCat,
                deal_handler: 'exclusive_all',
                ccbuddy_card_name: 'NULL',
                country: 'SGP',
                bank: 'DEALS FOR EVERYONE',
                merchant_name: selectedMerchant.merchant_name,
                merchant_name_lowercase: selectedMerchant.merchant_name_lowercase,
                display_merchant_name: selectedMerchant.display_merchant_name
            })
        }
    }, [props.promotion])

    useEffect(async () => {
        if (!config && !config.cmsv2) {
            try {
                const _config = await getConfig()
                setCMSConfig(_config)
            } catch(error) {
                setTransactionMessage('Error initializing this page. Please contact Administrator.')
            }
        }
        
    }, [config])

    useEffect(async () => {
        const _outlets = await listOutletsByMerchantId(selectedMerchant.merchant_id)
        setOutlets(_outlets)
    }, [selectedMerchant])

    function handleChange(e, fieldName) {
        setTransactionMessage(null)

        const item = { ...promo }
        if (e.target.type === 'checkbox') {
            if (fieldName === 'valid') {
                item[fieldName] = e.target.checked ? 'Y' : 'N'
            } else {
                item[fieldName] = e.target.checked ? 'True' : 'False';
            }
        } else {
            item[fieldName] = e.target.value

            if (fieldName === 'promotion_caption') {
                item['promotion_analytic'] = item[fieldName]
            }
        }

        setTransactionMessage(null)
        setPromo({...item})

        if (fieldName === 'website'){
            let inputWebsite = e.target.value
            let isValidURLA = isValidURL(inputWebsite)
            let isValidURLB = isValidHttpUrl(inputWebsite)
            
            if (inputWebsite.length > 0){
                if (isValidURLA && isValidURLB) {
                    setValidUrl(true)
                    
                }else{
                    setValidUrl(false)
                   
                }
            }else {
                setValidUrl(true)
            }
        }
    }

    function handleOutletChange(e, storeId) {
        
        if (e.target.checked) {
            if (storeId === 'all') {
                setSelectedStoreIds(outlets.map(item => item.store_id))
                setIsAllOutletChecked(true)
            } else {
                setSelectedStoreIds(selectedStoredIds.concat(storeId))
                setIsAllOutletChecked(false)
            }
        } else {
            if (storeId === 'all') {
                setSelectedStoreIds([])
                setIsAllOutletChecked(false)
            } else {
                const newStoreIds = selectedStoredIds.filter(id => id !== storeId)
                setSelectedStoreIds(newStoreIds)
                setIsAllOutletChecked(false)
            }
        }
    }

    function handleNoDateToggle(e, field) {
        
        if (!e.target.checked) {
            // user unchecked
            promo[field] = getUnixTime(new Date())
            // if (field === 'start_timestamp') setHasNoStartDate(false)
            // else setHasNoEndDate(false)
        } else {
            promo[field] = null
            // if (field==='start_timestamp')
            //     setHasNoStartDate(true)
            // else
            //     setHasNoEndDate(true)
        }
        setPromo({...promo})
    }

    async function handleSubmit(e) {
        e.preventDefault()
        e.stopPropagation()
        
        if ((!promo.image_url && !croppedImages.promo) || selectedStoredIds.length === 0) {
            setTransactionMessage({ msg: "Missing required fields. Please check fields mark with *", type: 'danger' })
            return
        }
        setTransactionMessage({msg: "Processing...", type:'info'})
        setProcessing(true)

        try {
            if (props.mode === 'create') {
                const createdPromo = await handleCreate(e)
                await sendEmailToFirstPromotionOfBrand(createdPromo)
            } else if (props.mode === 'update') {
                await handleUpdate(e)
            }
            close()
        } catch (error) {
            console.error('handleSubmit ', error)
            setTransactionMessage({msg: 'Please report this error to Administrator.', type: 'danger'})
            setProcessing(false)
        }
    }

    async function sendEmailToFirstPromotionOfBrand(promotion) {
        try {
            // we send when this is the first tiime for a promotion is created in a brand
            if (props.isNoPromotion && CMSConfig.cmsv2 && CMSConfig.cmsv2.emailReceiver) {
                const body = `New promotion has been added on ${promotion.createdAt}. \n\n
                                Company name: ${user.corpName}
                                Brand name: ${promotion.merchant_name}
                                Business rep: ${user.firstName} ${user.lastName}
                                Deal title: ${promotion.promotion_caption}`
                console.log('sendEmailToFirstPromotionOfBrand', {body})
                await sendEmail({
                    to: [...CMSConfig.cmsv2.emailReceiver],
                    content: body,
                    subject: `First promotion added by ${promotion.merchant_name}`
                })
            } else {
                console.debug('Cannot retrieve email receivers from config or its not first promotion. No email sent')
            }
        } catch (error) {
            console.error('error on sendEmail', error)
            dispatch(setAmplitudeEvent('CMS Manage promotions page - sendEmailToFirstPromotionOfBrand function error', {'details':JSON.stringify(error)}))
        }
    }

    async function handleCreate() {
        // eslint-disable-next-line no-useless-catch
        try {
            const { addedItemsToParent } = listDifference([promo.store_id], selectedStoredIds)
            const createdPromo = await addDealsToOutlet(addedItemsToParent, promo)

            return createdPromo
        } catch (error) {
            throw error
        }
    }

    async function handleUpdate() {
        try {
            const _promo = { ...promo }
            const invalidation = []
            let path = ''
 
            if (croppedImages.promo) {
                path = `DealImages/${_promo.merchant_unique_id}/${_promo.pk}${croppedImages.promo.ext}`
                _promo.image_url = await getAssetUrl(path)
                invalidation.push('image_url')
            }
            
            // delete custom fields before updating
            delete _promo.store_ids
            delete _promo.valid_store_ids
            delete _promo.deal_ids
            delete _promo.outlet_name

            const updatedPromo = await update(_promo, { invalidateCloudFrontCache: invalidation })

            if (path) await handleImageUpload(path, croppedImages.promo) 
        
            const { removeItemsFromParent, addedItemsToParent } = listDifference([promo.store_id], selectedStoredIds)
            
            await addDealsToOutlet(addedItemsToParent, updatedPromo)
            await removePromoFromOtherOutlet(removeItemsFromParent, updatedPromo)

            return updatedPromo
        } catch (error) {
            console.error('error handleUpdate ', error)
            dispatch(setAmplitudeEvent('CMS Manage promotions page - handleUpdate function error', {'details': JSON.stringify(error)}))
            throw error
        }
    }

    async function createPromo(_promo) {
        try{
            let createdDeal = await create(_promo)

            const resultApproval = await DealApproval.create(createdDeal.pk)
            console.debug('create promo approval', resultApproval)

            return createdDeal;
        } catch(error){
            console.error('error createDeal ', error)
            dispatch(setAmplitudeEvent('CMS Manage promotions page - createPromo function error', {'details': JSON.stringify(error)}))
            throw error
        }
    }

    async function addDealsToOutlet(list, _promo) {
        let createdPromo

        for (let i = 0; i < list.length; i++) {
            const storeId = list[i]
            const merchantUniqueId = `${_promo.merchant_id}_${storeId}`
            const outletPromo = await getPromoByMerchantUniqueId(merchantUniqueId, { promotion_caption: _promo.promotion_caption } )

            // TODO: remove the IF and use the ELSE logic only
            if (outletPromo && outletPromo.items.length > 0) {
                for (let ii = 0; ii < outletPromo.items.length; ii++) {
                    // found an existing promo(s) in an outlet,
                    createdPromo = outletPromo.items[ii]
                }
            } else {
                // no deal found so we create a new deal on an outlet
                const newOutletPromo = createDealInfo(storeId, _promo)

                let path
                if (croppedImages.promo) {
                    path = `DealImages/${newOutletPromo.merchant_unique_id}/${newOutletPromo.pk}${croppedImages.promo.ext}`
                    newOutletPromo.image_url = await getAssetUrl(path)
                }
                createdPromo = await createPromo(newOutletPromo)

                if (path) await handleImageUpload(path, croppedImages.promo)
            }
        }
        return createdPromo
    }

    async function removePromoFromOtherOutlet(list, _promo) {
        
        for (let i = 0; i < list.length; i++) {
            const storeId = list[i]
            const merchantUniqueId = `${_promo.merchant_id}_${storeId}`
            const outletPromo = await getPromoByMerchantUniqueId(merchantUniqueId, { promotion_caption: _promo.promotion_caption } )
            

            if (outletPromo && outletPromo.items.length > 0) {
                for (let ii = 0; ii < outletPromo.items.length; ii++) {
                    // found an existing promo(s) in an outlet, so check if the deal is valid and make it invalid
                    const promoTemp = outletPromo.items[ii]
                    if (promoTemp.valid === 'Y') {
                        promoTemp.valid = 'N'
                        await update(promoTemp)
                    }
                }
            }
        }
        return
    }

    function createDealInfo(storeId, promo) {
        const selectedOutlet = outlets.filter(item => storeId===item.store_id)
        let outlet = {}
        if (selectedOutlet && selectedOutlet.length > 0) outlet = selectedOutlet[0]

        const deal = {
            ...promo,
            isSponsored: promo.isSponsored || "0",
            merchant_unique_id: `${promo.merchant_id}_${storeId}`,
            store_id: storeId,
            valid: 'N',
            bank:'DEALS FOR EVERYONE',
            country: "SGP",
            is_online: "False",
            deal_handler: 'exclusive_all',
            cc_buddy_category: selectedMerchant.mainCat,
            ccbuddy_card_name: 'NULL',
            latitude: outlet.latitude,
            longitude: outlet.longitude,
            geohashlong: outlet.geohashlong,
            geohashshort: outlet.geohashshort,
            postal_code: outlet.postal_code,
            phone: outlet.phone,
            address: outlet.address,
            pk: guidGenerator()
        };

        delete deal.image_url;

        return deal;
    }

    // next release
    // function removeTag(tag) {
    //     setTags(tags.filter(e => e !== tag))
    // }

    // next release
    // function addTag(tag) {
    //     if (!tags.includes(tag)) {
    //         tags.push(tag)
    //     }
    // }

    // next release
    // async function handleImageCopy(src, dest) {
    //     try {
    //         await Storage.copy({key: src, key: dest})
    //     } catch(error) {
    //         throw error
    //     }
    // }

    // async function handleImageUpload(path) {
    //     try {
    //         if (promoImage.preview) {
    //             const resultUpload = await Storage.put(path, promoImage)
    //             console.log('result upload to S3', resultUpload)
    //         }
    //     } catch(error) {
    //         console.error('error on handleImageUpload ', error)
    //         throw error
    //     }
    // }

    function listOutlets(outlets) {
        if (outlets) {
            let outletListLeft = []
            let outletListRight = []
            const totalOutlets = outlets.length

            for (let i = 0; i < totalOutlets; i++) {
                // we will alternately display the outlet side by side
                if (i % 2) {
                    outletListRight.push(
                        <div className="form-check mt-1" key={i}>
                            <input type="checkbox" key={i + outlets[i].store_id} className="form-check-input" checked={isChecked(outlets[i])} onChange={(e) => handleOutletChange(e, outlets[i].store_id)} />
                            <label key={i} className="form-check-label">{outlets[i].outlet_name}</label>
                        </div>
                    )
                } else {
                    outletListLeft.push(
                        <div className="form-check mt-1" key={i}>
                            <input type="checkbox" key={i + outlets[i].store_id} className="form-check-input" checked={isChecked(outlets[i])} onChange={(e) => handleOutletChange(e, outlets[i].store_id)} />
                            <label key={i} className="form-check-label" >{outlets[i].outlet_name}</label>
                        </div>
                    )
                    
                }
            }

            return (
                <div>
                    <Row>
                        <Col>
                            <div className="form-check">
                                <input key="apply" type="checkbox" checked={isAllOutletChecked} className="form-check-input" onChange={(e) => handleOutletChange(e, 'all')}/>
                                <label className="form-check-label subTitle">Apply to all outlets*</label>
                            </div>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row className="scrollview scrollbar scrollbar-primary" style={{ maxHeight: "400px" }}>
                        <Col>{outletListLeft}</Col>
                        <Col>{outletListRight}</Col>
                    </Row>
                </div>
            )
        }
    }

    function isChecked(outlet) {
        // return false
        return selectedStoredIds.includes(outlet.store_id)
        // return promo.store_id === outlet.store_id
    }

    function close(updatePromo = promo) {
        // do clean up since this is a modal and it might show old message when open again
        setProcessing(false)
        setTransactionMessage(null)

        props.close(updatePromo, true)
    }

    async function getCroppedImage(cropped, fieldName) {
        let _croppedImages = croppedImages ?? {}
        _croppedImages[fieldName] = cropped

        setCroppedImages({ ..._croppedImages })
    }

    return (
        <Modal
            size="xl"
            isOpen={props.modalFormStatus}
            toggle={() => {
                props.showForm(false)
            }}
            centered={true}
        >   
            <AvForm className="needs-validation" onValidSubmit={(e, v) => handleSubmit(e, v)}>
            <ModalHeader toggle={() => props.showForm(false)}>
                {props.formTitle}
            </ModalHeader>
            <ModalBody>
                
                <Row>
                    <Col xs={12} sm={12} md={12} lg={6} xl={6} >
                        <Label className="groupTitle">Promotion details</Label>
                        <p className="subTitle">Deal Image*</p>
                        <p>
                            <span>Image will be scaled and cropped to display 375 by 266 pixel</span>
                            <span>(You may upload larger images and use the cropping tool.)</span>
                        </p>
                        <ImageUploader
                            width={375}
                            height={266}
                            required={true}
                            // disabled={this.state.readOnly}
                            // readOnly={this.state.readOnly}
                            label="Brand Logo"
                            onOk={(croppedImage) => getCroppedImage(croppedImage, 'promo')}
                        />
                    </Col>
                    <Col className="mb-3">
                        <Label className="groupTitle mt-3">Deal Image Preview</Label>
                        <div style={{ marginTop: "10px" }}><div>
                            {/* <img className="dealImageBig" src={promo.image_url || null} /> */}
                                {(croppedImages.promo && croppedImages.promo.url) || promo.image_url ?
                                    <Image fluid src={croppedImages.promo ? croppedImages.promo.url : `${promo.image_url}?timestamp=${Date.now()}`} />
                                : null}
                            </div>
                        </div>
                       
                    </Col>
                </Row>
                <Row>
                    <Col className="mt-3" xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Label htmlFor="promotionTitle" className="subTitle">Promotion Title*</Label>
                        <AvField
                            name="promotionTitle"
                            placeholder=""
                            type="text"
                            errorMessage="This field is required"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="promoTitle"
                            value={promo ? promo.promotion_caption : ''}
                            onChange={(e) => handleChange(e, 'promotion_caption')}
                        /> 
                    </Col>
                </Row>
                <Row>
                    <Col className="mt-3" xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Label htmlFor="promotion_details" className="subTitle">Promotion Details*</Label>
                        <AvField
                        name="promotionDetails"
                        placeholder=""
                        type="textarea"
                        rows={4}
                        errorMessage="This field is required"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="promotionDetails"
                        value={promo.promotion}
                        onChange={(e) => handleChange(e, 'promotion')}
                        />
                       
                    </Col>
                </Row>
                <Row>
                    <Col className="mt-3" xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Label htmlFor="terms" className="subTitle">Terms and Conditions*</Label>
                        <AvField
                            name="terms"
                            placeholder=""
                            type="textarea"
                            rows={5}
                            errorMessage="This field is required"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="terms"
                            value={promo.terms}
                            onChange={(e) => handleChange(e, 'terms')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="mt-3" xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Label htmlFor="website" className="subTitle">Deal Website</Label>
                            <AvField
                                name="terms"
                                placeholder=""
                                type="text"
                                className="form-control"
                                id="website"
                                value={promo.website}
                                onChange={(e) => handleChange(e, 'website')}
                            />
                            {!validUrl ? <p className="mt-1" style={{color:'red', fontSize: 11}}>Please make sure your link starts with http:// or https://</p> : <></>}
                    </Col>
                </Row>

                {props.mode === 'create' ?
                <Row className="mt-3" >
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            {outlets ? listOutlets(outlets): <span>loading outlet list...</span>}
                        </Col>
                    
                </Row>
                :null}

                <Row className="mt-3">
                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Label className="subTitle">Start Date*</Label>
                            <InputGroup>
                            <Flatpickr
                                id="start_timestamp"
                                className="form-control d-block whiteBg"
                                placeholder="Select a date"
                                value={promo.start_timestamp ? fromUnixTime(promo.start_timestamp) : ""}
                                options={{
                                    minDate: props.mode==='create'?'today':'',
                                    enableTime: true,
                                    enableSeconds: true,
                                    time_24hr: true,
                                    defaultHour: 0,
                                    defaultMinute: 0,
                                    defaultSeconds: 0,
                                    altInput: true,
                                    altFormat: "F j, Y H:i:S",
                                    dateFormat: "Y-m-d H:i:S",
                                    onChange: function (selectedDates, dateStr, instance) {
                                        promo['start_timestamp'] = getUnixTime(new Date(dateStr))
                                        setPromo({...promo})
                                    }
                                }}
                                
                            />
                            <span className="input-group-text mdi mdi-calendar"></span>
                            </InputGroup>
                            <div className="form-check mt-1">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={promo.start_timestamp ? false : true }
                                    onChange={(e) => handleNoDateToggle(e, 'start_timestamp')}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="noStartDate"
                                >
                                    No start date
                                </label>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Label className="subTitle mt-3">End Date*</Label> <a href="#" onClick={ () => {promo.end_timestamp=getUnixTime(endOfDay(fromUnixTime(promo.end_timestamp))); setPromo(promo)} }>Default to 23:59</a>
                            <InputGroup>
                                <Flatpickr
                                    id="end_timestamp"
                                    className="form-control d-block whiteBg"
                                    placeholder="Select a date"
                                    value={promo.end_timestamp ? fromUnixTime(promo.end_timestamp) : ""}
                                    options={{
                                        minDate: props.mode==='create'?'today':'',
                                        enableTime: true,
                                        enableSeconds: true,
                                        time_24hr: true,
                                        defaultHour: 23,
                                        defaultMinute: 59,
                                        defaultSeconds: 59,
                                        altInput: true,
                                        altFormat: "F j, Y H:i:S",
                                        dateFormat: "Y-m-d H:i:S",
                                        onChange: function (selectedDates, dateStr, instance) {
                                            promo['end_timestamp'] = getUnixTime(new Date(dateStr))
                                            setPromo({...promo})

                                        }
                                    }}
                                />
                                <span className="input-group-text mdi mdi-calendar"></span>
                            </InputGroup>
                            <div className="form-check mt-1">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={promo.end_timestamp ? false : true}
                                    onChange={(e) => handleNoDateToggle(e, 'end_timestamp')}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="noEndDate"
                                >
                                    No end date
                                </label>
                            </div>
                        </Col>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}/>
                    </Row> 

                {/* // FOR next release version
                <Row className="rowMarginTop">
                    <Col>
                        <div>
                            <Label className="subTitle">Tags</Label>{" "}
                            {predefinedTags.map((tag, index) => 
                                <a href="#" onClick={() => addTag(tag)} key={index+tag}><Badge key={index} className="primary-bgcolor rounded bg-primary">{tag}</Badge>{" "}</a>
                            )}
                        </div>
                        <div className="tagBox">
                            {tags ? tags.map((tag, index) => 
                                <span key={`${index}_${tag}}`}><Badge className="primary-bgcolor rounded bg-primary">{tag} <a href="#" onClick={() => removeTag(tag)} style={{color:"#FFF"}}>x</a></Badge>{" "}</span>
                            ): null}
                        </div>
                    </Col>
                    <Col></Col>
                </Row> */}
                
            </ModalBody>

            <ModalFooter>
                {transactionMessage ?
                    <Alert color={transactionMessage.type}>
                        {transactionMessage.msg}
                    </Alert>
                    : null}

                {processing ?
                    <Spinner />
                    : null}

                <Button
                    disabled={processing}
                    color="primary"
                    // onClick={(e) => handleSubmit(e)}
                    type="submit"
                >
                    {props.mode==='create'? 'Add promotion' :'Update promotion'}
                </Button>
                {' '}
                <Button disabled={processing} onClick={() => props.showForm(false)}>
                    Cancel
                </Button>
            </ModalFooter>
            </AvForm>
        </Modal>
    )
}

export default connect(null, {})(PromotionForm);