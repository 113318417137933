import React, { useState, useEffect } from 'react';
import { Form, Image } from 'react-bootstrap';
import { Row, Col, CardBody, Container, Spinner, CardTitle } from "reactstrap"
import styled from 'styled-components';
import moment from 'moment'
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { BiEditAlt } from 'react-icons/bi';
import { GoCheck } from 'react-icons/go';
import { FaWallet } from 'react-icons/fa';
import { IoIosPaper } from 'react-icons/io';
import { AiFillInfoCircle } from 'react-icons/ai';
import { v4 as uuidv4 } from 'uuid';

// custom components
import CountDown from './Components/CountDown';
import { remoteConfig, getHappeningOn } from 'utils';
import ContactInfo from './Components/ContactInfo';
import DealInfo from './Components/DealInfo';
import WarningPopup from './Components/WarningPopup';
import {
    SubTitle, CardContainer, HeaderLine, PriceLabel,
    SpinnerDiv, SubmitButton, Terms, Description
} from './Components/Styled';
import { TIME_IS_UP, NO_SLOT, STOP_TIMER, START_TIMER, RESET_TIMER, PROCESSING_ERROR, RESERVE_ERROR } from 'utils/constants';
import {
    getCanPurchase, getDealInfo, getEvents, reserveVoucher, B2BRegistration,
    stripeRetrieveKey, stripeCreatePaymentLink, cancelReserveVoucher, getEnv, B2BValidate,
    retrieveGKey, completeTransaction, releaseDiscountCode
} from 'helpers/apiHelper';
import AccessDenied from 'components/Common/accessDenied';
import { openInNewTab } from 'utils';
import { setAlert } from "store/alerts/actions";
const sha512 = require('crypto-js/sha512');



const B2BwPayment = (props) => {

    const [timerStatus, setTimerStatus] = useState(STOP_TIMER)
    const [nationality, setNationality] = useState([])
    const [countryCode, setCountryCode] = useState([])
    const [canPurchase, setCanPurchase] = useState(false)
    const [warningMessage, setWarningMessage] = useState({ show: false, type: '', message: '' })
    const [isBusy, setIsBusy] = useState(false)
    const [dealInfo, setDealInfo] = useState([])
    const [eventInfo, setEventInfo] = useState([])
    const [reserveVoucherInfo, setReserveVoucherInfo] = useState([])
    const [initialCount, setInitialCount] = useState(0)
    const [contactInfo, setContactInfo] = useState({})
    const [happeningOn, setHappeningOn] = useState('')
    const [isAccessDenied, setAccessDenied] = useState(null);
    const [prefill, setPrefill] = useState(null)
    const [custom, setCustom] = useState([])
    const [mapKey, setMapKey] = useState('')
    const [invalid, setInvalid] = useState(true)
    const [qty, setQty] = useState(1)
    const [step, setStep] = useState("details")
    const [isQtyFixed, setIsQtyFixed] = useState(false)
    const [discountCampaign, setDiscountCampaign] = useState(null);
    const [discountAmt, setDiscountAmt] = useState(null)
    const [totalPrice, setTotalPrice] = useState(null)
    const [paymentPrice, setPaymentPrice] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [discountCodeReserved, setDiscountCodeReserved] = useState(null);
    const [stripeKey, setStripeKey] = useState('')
    const [paymentLinks, setPaymentLinks] = useState({})
    const [priceId, setPriceId] = useState()

    const eventId = props.match.params.eventId;
    const dealId = props.match.params.dealId
    const payload = props.match.params.payload ? decodeURIComponent(props.match.params.payload) : null
    const imagePath = `https://assets.${getEnv()}.cardspal.com/public/Events/banner/`
    // const faviconPath = `https://assets.${getEnv()}.cardspal.com/public/Events/favicon/`


    useEffect(async () => {
        setIsBusy(true)
        payload ? B2BValidatePayLoad(payload) : setInvalid(false)
        getCountry()
        getKey()
        let customData = await getCustomSettings()
        await loadEventDealInfo(customData)
        setIsLoading(false)
        // const searchParams = new URLSearchParams(window.location.search)
        // if (searchParams.get.length>0) 
        //     setPrefill( Object.fromEntries([...searchParams]))

        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        // link.href = `${faviconPath}${dealId}_favicon.ico`;
    }, [])

    useEffect(() => {
        if (discountCampaign && discountCampaign.code) {
            try {
                const priceId = custom.dealInfo[dealId]['stripePriceIds'][discountCampaign?.code?.toUpperCase()]
                setPriceId(priceId)
            } catch (error) {
                setPriceId(null)
                setPaymentLinks({})
            }
        } else {
            console.log('no discount campaign')
            setPriceId(null)
            setPaymentLinks({})
        }
    }, [discountCampaign])

    useEffect(() => {
        if (!priceId) {
            setPriceId(null)
            setPaymentLinks({})
        }
    }, [priceId])

    useEffect(() => {
        if (eventInfo?.data?.name) {
            document.title = eventInfo.data.name;
        }
    }, [eventInfo]);

    useEffect(() => {

        if (canPurchase) {
            window.history.pushState(null, "", window.location.href);
            window.onpopstate = () => {
                window.history.pushState(null, "", window.location.href);
                // cancelReservation()
                setCanPurchase(false)
            }

        }

        return () => {
            if (canPurchase) {
                setCanPurchase(false)
            }
        }

    }, [canPurchase]);

    const loadEventDealInfo = async (customData) => {
        let deal = await getDealInfo(dealId)
        setDealInfo(deal)
        let updatedQty = 1
        if (customData?.dealInfo[dealId]?.isfixedQty && customData?.dealInfo[dealId]?.fixQty) {
            updatedQty = customData?.dealInfo[dealId]?.fixQty
            setQty(updatedQty)
            setIsQtyFixed(true)
        }
        setTotalPrice((deal.data.purchasePrice * updatedQty).toFixed(2))
        setPaymentPrice((deal.data.purchasePrice * updatedQty).toFixed(2))

        let event = await getEvents(eventId)
        setEventInfo(event)

        if (deal.statusCode === '200' && event.statusCode === '200') {
            let _datetime = getHappeningOn(event?.data?.startTimestamp, event?.data?.endTimestamp)
            setAccessDenied(false)
            setHappeningOn(_datetime)
        } else {
            setAccessDenied(true)
        }
        setIsBusy(false)
    }

    const getCountry = async () => {
        let rmCountry = await remoteConfig("country")
        let parseCountry = JSON.parse(rmCountry._value)
        let countryList = parseCountry.filter(item => {
            return !!item.nationality
        })
        countryList.sort((a, b) => (a.priority != null ? a.priority : Infinity) - (b.priority != null ? b.priority : Infinity))
        const _nationality = countryList.map(item => {
            return {
                value: item.nationality,
                label: item.nationality,
            }
        })
        const _countryCode = countryList.map(item => {
            return {
                value: `+${item.code}`,
                label: `+${item.code}`
            }
        })
        setNationality(_nationality)
        setCountryCode(_countryCode)
    }

    const B2BValidatePayLoad = async (payload) => {
        let res = await B2BValidate({
            "encryptedData": payload
        })
        if (res.statusCode === 200) {
            setInvalid(false)
            setPrefill(JSON.parse(res.body))
            // JSON.parse(res.body).email ? cancelReservation(JSON.parse(res.body).email) : null
        }
    }

    const getKey = async () => {
        let res = await retrieveGKey()
        if (res.success === "200")
            setMapKey(res.data.GOOGLE_API_KEY)
    }

    const getCustomSettings = async () => {
        let cust = await remoteConfig("b2bCustom")
        if (cust._value) {
            let parseCustJson = JSON.parse(cust._value)
            setCustom(parseCustJson[eventId])
            return (parseCustJson[eventId])
        } else return null
    }

    const getPlatformFee = async (dealId, quantity, purchasePriceTotal) => {
        let flatFees_custom = await remoteConfig("stripeFlatFees")
        let percentageFees_custom = await remoteConfig("stripePercentageFees")
        let flatFees = JSON.parse(flatFees_custom._value)
        let percentageFees = JSON.parse(percentageFees_custom._value)

        let flatFee = null
        let percentageFee = null
        let platformFee = 0.00
        for (let type in flatFees) {
            if (flatFees[type].pks?.includes(dealId)) {
                flatFee = parseFloat(flatFees[type].fee) * parseFloat(quantity)
            }
        }
        for (let type in percentageFees) {
            if (percentageFees[type].pks?.includes(dealId)) {
                percentageFee = parseFloat(purchasePriceTotal) * parseFloat(percentageFees[type].fee)
            }
        }
        if (flatFee && percentageFee) {
            platformFee = parseFloat((flatFee + percentageFee) * 1.08).toFixed(2)
        } else if (flatFee) {
            platformFee = flatFee
        } else if (percentageFee) {
            platformFee = percentageFee
        }
        return platformFee * 100
    }

    const reservation = async (data) => {
        const forceReservation = true // for seedly, we force reservation to avoid checking if user has already reserved or purchased
        let reserveV = await reserveVoucher(data, forceReservation)
        if (reserveV.statusCode === 200 && Object.entries(reserveV?.data)?.length > 0) {
            setInitialCount(reserveV?.data?.minsToKickout * 60)
            setReserveVoucherInfo(reserveV)
            setCanPurchase(true)
            setTimerStatus(START_TIMER)
            setStep('payment')
            setIsBusy(false)
        } else if (reserveV.error) {
            setWarningMessage({ show: true, type: RESERVE_ERROR, message: reserveV.error })
        }

    }

    const onSubmitContactInfo = async (e) => {
        setIsBusy(true)
        let phoneNumber = e.phoneNumber ? `${e.countryCode}${e.phoneNumber}` : '+65'
        await onSubmitForPayment(e.email)
        setIsBusy(false)
        setContactInfo({ userId: e.email, email: e.email, phoneNumber: phoneNumber, firstName: e.firstName, lastName: e.lastName, terms: e.terms, marketingConsent: e.marketingConsent })
    }

    const onSubmitForPayment = async (email) => {
        try {
            setIsBusy(true)
            let data = { userId: email, dealId: dealId, qty: qty }
            let getUserCanPurchase = await getCanPurchase(data)
            if (getUserCanPurchase.statusCode === 200) {
                if (getUserCanPurchase?.canPurchase) {
                    let obj = { dealId: dealId, userId: email, numberOfVouchers: qty }
                    await reservation(obj)
                } else {
                    setWarningMessage({ show: true, type: NO_SLOT })
                }
            }
        } catch (error) {
            setWarningMessage({ show: true, type: PROCESSING_ERROR })
            console.error('onSubmitContactInfo', error)
        }
    }
    async function getStripeKey() {
        const key = await stripeRetrieveKey()
        if (!key) {
            setAlert(`Error loading this page. Please report to admin immediately.`, 'danger')
        } else {
            setStripeKey(key.stripeSecret)
        }
        return key?.stripeSecret
    }

    async function createPaymentLink(_priceId) {
        try {
            let _stripeKey = null
            if (!stripeKey) {
                _stripeKey = await getStripeKey()
                setStripeKey(_stripeKey)
            }
            const webFormData = {
                webFormId: '0004',
                ...contactInfo,
            }
            let platformFee = await getPlatformFee(dealId, qty, paymentPrice)
            
            const userId = contactInfo?.email?.replace('@', '_')
            const linkObj = await stripeCreatePaymentLink({
                stripeKey: stripeKey || _stripeKey,
                priceIds: { [dealId]: priceId || _priceId},
                email: contactInfo.email,
                others: {
                    env: getEnv(),
                    dataType: 'b2b',
                    quantity: qty.toString(),
                    userId,
                    dealId: dealId,
                    discountCampaignId: discountCampaign?.id || null,
                    purchasePriceTotal: paymentPrice,
                    platformFee: Math.round(platformFee),
                    eventsId: eventInfo?.data?.pk || '',
                    vouchers: reserveVoucherInfo?.data?.vouchers || [],
                    webFormData,
                    discountCodes: discountCodeReserved || [],
                    transactionType: 'web_purchased',
                    stripeAccountId: custom?.stripeAccountId,
                    customMessage: 'Thank you for your purchase. Please contact us at partnership@cardspal.com if you have any queries.',
                    redirectUrl: `${window.location.origin}/webform/0004/B2BPaymentSuccessful?dealId=${dealId}&eventId=${eventInfo?.data?.pk}&qty=${qty}&userId=${userId}&email=${contactInfo?.email}`,
                }
            })
            
            if (!linkObj) {
                setWarningMessage({ show: true, type: PROCESSING_ERROR, message: 'Error creating payment link.' })
                setPaymentLinks({})
                return {}
            } else {
                const linkDetails = linkObj[dealId]
                setPaymentLinks(linkDetails)
                return linkDetails
            }

        } catch (error) {
            setWarningMessage({ show: true, type: PROCESSING_ERROR })
            console.error('error on create link ', error)
            setWarningMessage({ show: true, type: PROCESSING_ERROR, message: 'Something went wrong in creating payment link.' })
            return null
        }
    }

    // const processPayment = async () => {
    //     setIsBusy(true)
    //     if (!stripeKey) {
    //         setStripeKey(await getStripeKey())
    //     }
    //     let webFormData = {
    //         webFormId: '0004',
    //         email: contactInfo.email,
    //         phoneNumber: contactInfo.phoneNumber,
    //         firstName: contactInfo.firstName,
    //         lastName: contactInfo.lastName,
    //     }
    //     if (!stripeKey) {
    //         await createPaymentLink({...webFormData, qty})
    //     }
    //     setIsBusy(false)
    // }

    const onChangeContactInfo = (e) => {
        const { value, name } = e.target
    }
    const onBlurContactInfo = async (e) => {
        const { value, name } = e.target
    }
    const gotoRegistrationSuccessful = async () => {
        if (!contactInfo?.email || contactInfo?.email.length < 1 || !qty || qty === 0 || !reserveVoucherInfo?.data?.vouchers || reserveVoucherInfo?.data?.vouchers.length < 1) {
            setWarningMessage({ show: true, type: PROCESSING_ERROR, message: 'Missing mandatory data' })
        } else {
            if (paymentLinks?.url) {
                openInNewTab(paymentLinks?.url, true) // open self
            } else {
                console.debug('no payment link found..will try to create with default price id', { totalPrice }, { paymentPrice })
                // no discount was entered so we create the payment link using the default price id
                setIsBusy(true)
                if (Number(paymentPrice) === 0) {
                    // no payment required
                    const webFormData = {
                        webFormId: '0004',
                        ...contactInfo,
                    }

                    const userId = contactInfo?.email?.replace('@', '_')
                    const payload = {
                        wordpress: true,
                        env: getEnv(),
                        dataType: 'b2b',
                        qty: qty.toString(),
                        userId,
                        dealId: dealId,
                        discountCampaignId: discountCampaign?.id || null,
                        purchasePriceTotal: paymentPrice,
                        eventsId: eventInfo?.data?.pk || '',
                        vouchers: reserveVoucherInfo?.data?.vouchers || [],
                        webFormData,
                        discountCodes: discountCodeReserved || [],
                        transactionType: 'web_purchased',
                        paymentMethod: { 'id': 'FREE' },
                        others: {idempotencyKey: uuidv4()},
                    }
                    const res = await completeTransaction(payload)
                    
                    if (res.statusCode === 200) {
                        dealInfo.data.happeningOn = happeningOn
                        const queryString = `?dealId=${dealId}&eventId=${eventInfo?.data?.pk}&qty=${qty}&userId=${userId}&email=${contactInfo?.email}`
                        setIsBusy(false)
                        window.location.href = `/webform/0004/B2BPaymentSuccessful${queryString}`
                        // props.history.push(page)
                    } else {
                        setIsBusy(false)
                        setWarningMessage({ show: true, type: PROCESSING_ERROR, message: 'Error processing a free transaction.' })
                    }
                } else {
                    let _priceId
                    if (priceId) {
                        _priceId = priceId
                    } else {
                        _priceId = custom?.dealInfo[dealId]['stripePriceIds']['DEFAULT']
                    }
                    const link = await createPaymentLink(_priceId)
                    console.debug('link', link)
                    setIsBusy(false)
                    if (link.url) {
                        openInNewTab(link.url, true) // open self
                    } else {
                        setWarningMessage({ show: true, type: PROCESSING_ERROR, message: 'Error creating payment link' })
                    }
                }
            }
        }
    }

    const tryAgain = () => {
        setIsBusy(true)
        setWarningMessage({ show: false, type: '', message: '' })
        // cancelReservation(contactInfo.email)
        setTimerStatus(STOP_TIMER)
        setInitialCount(0)
        setStep("details")
        setIsBusy(false)
    }

    const getTimerStatus = (e) => {
        if (e === 'Stopped') {
            setWarningMessage({ show: true, type: TIME_IS_UP })
        }
    }

    const RenderDetailsPage = () => {
        let dealCustom = custom?.dealInfo[dealId]
        return (
            <div>
                <CardContainer style={{ backgroundColor: "#fff", maxWidth: 400 }}>
                    <div>
                        <HeaderLine style={{ borderColor: dealCustom?.color }} className='mb-3' />
                        <div style={{ marginInline: 10 }}>
                            <div style={{ color: dealCustom?.color, fontWeight: 800, fontSize: 17, marginInline: 5 }}>{dealInfo?.data?.promotion_caption}</div>
                            {dealCustom?.type === 'bundle' ?
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ color: "#414858", fontWeight: 800, fontSize: 20, marginInline: 5 }}>{"S$" + (Number(dealInfo.data.purchasePrice) * 2).toFixed(2)}</div>
                                    <div style={{ color: "#414858", fontWeight: 800, fontSize: 14, alignSelf: "center", marginTop: 3 }}>FOR 2</div>
                                </div>
                                : dealCustom?.type === 'vip' || dealCustom?.type === 'free' ?
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ color: "#414858", fontWeight: 800, fontSize: 21, marginInline: 5 }}>{"S$0.00"}</div>
                                    </div>
                                    :
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ color: "#414858", fontWeight: 800, fontSize: 21, marginInline: 5 }}>{"S$" + Number(dealInfo.data.purchasePrice).toFixed(2)}</div>
                                        <div style={{ color: "#414858", fontWeight: 800, fontSize: 15, alignSelf: "center", marginTop: 3 }}>EACH</div>
                                    </div>
                            }
                            <div style={{ display: "flex", flexDirection: "row", margin: 5 }}>
                                <CheckIcon style={{ color: dealCustom?.color }} />
                                <div style={{ color: "#6B6C7E", fontSize: 12, marginInlineStart: 10, marginInlineEnd: 5 }}>{custom?.dealInfo[dealId]?.info1}</div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", margin: 5 }}>
                                <CheckIcon style={{ color: dealCustom?.color }} />
                                <div style={{ color: "#6B6C7E", fontSize: 12, marginInlineStart: 10, marginInlineEnd: 5 }}>{custom?.dealInfo[dealId]?.info2}</div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", margin: 5 }}>
                                <CheckIcon style={{ color: dealCustom?.color }} />
                                <div style={{ color: "#6B6C7E", fontSize: 12, marginInlineStart: 10, marginInlineEnd: 5 }}>{custom?.dealInfo[dealId]?.info3}</div>
                            </div>
                        </div>
                    </div>
                    {dealCustom?.type === 'vip' ?
                        <div style={{ margin: 10 }}>
                            <div style={{ color: "#A7A9BC", fontSize: 12, marginInlineStart: 10, marginInlineEnd: 5 }}>- Each unique promo code is eligible for one redemption only.</div>
                            <div style={{ color: "#A7A9BC", fontSize: 12, marginInlineStart: 10, marginInlineEnd: 5 }}>- Enter your promo code at the payment page to enjoy one complimentary ticket.</div>
                        </div>
                        : dealCustom?.type === 'free' ?
                            <div style={{ margin: 10 }} />
                            :
                        <div style={{ margin: 10 }}>
                            <div style={{ color: "#A7A9BC", fontSize: 12, marginInlineStart: 10, marginInlineEnd: 5 }}>- Limited tickets, while stock last</div>
                            <div style={{ color: "#A7A9BC", fontSize: 12, marginInlineStart: 10, marginInlineEnd: 5 }}>- Have a promo code? Enter it at the payment page to enjoy further discount! </div>
                        </div>
                    }
                </CardContainer>
                <DealInfo
                    dealInfo={dealInfo}
                    dealId={dealId}
                    countryCode={countryCode}
                    onChange={(e) => onChangeContactInfo(e)}
                    prefill={prefill}
                    type={custom?.dealInfo[dealId]?.type}
                    isDisabled={isBusy}
                    qty={qty}
                    isQtyFixed={isQtyFixed}
                    totalPrice={totalPrice}
                    paymentPrice={paymentPrice}
                    setQty={(qty) => setQty(qty)}
                    setTotalPrice={(price) => setTotalPrice(price)}
                    setPaymentPrice={(price) => setPaymentPrice(price)}
                    discountCampaign={discountCampaign}
                    setDiscountCampaign={(campaign) => setDiscountCampaign(campaign)}
                    discountAmt={discountAmt}
                    setDiscountAmt={(amt) => setDiscountAmt(amt)}
                    contactInfo={contactInfo}
                    step={step}
                    eventInfo={eventInfo}
                    discountCodeReserved={discountCodeReserved}
                    setDiscountCodeReserved={(obj) => setDiscountCodeReserved(obj)}
                />
                <ContactInfo
                    countryCode={countryCode}
                    onSubmit={(e) => onSubmitContactInfo(e)}
                    onChange={(e) => onChangeContactInfo(e)}
                    onBlur={(e) => onBlurContactInfo(e)}
                    prefill={prefill}
                    custom={custom}
                    isDisabled={isBusy}
                    contactInfo={contactInfo}
                />
                <div>
                </div>
            </div>
        )
    }
    const RenderPaymentPage = () => {
        let dealType = custom?.dealInfo[dealId]?.type
        return (
            <div>
                <DealInfo
                    dealInfo={dealInfo}
                    dealId={dealId}
                    countryCode={countryCode}
                    onChange={(e) => onChangeContactInfo(e)}
                    prefill={prefill}
                    type={dealType}
                    isDisabled={isBusy}
                    qty={qty}
                    isQtyFixed={isQtyFixed}
                    totalPrice={totalPrice}
                    paymentPrice={paymentPrice}
                    setQty={(qty) => setQty(qty)}
                    setTotalPrice={(price) => setTotalPrice(price)}
                    setPaymentPrice={(price) => setPaymentPrice(price)}
                    discountCampaign={discountCampaign}
                    setDiscountCampaign={(campaign) => setDiscountCampaign(campaign)}
                    discountAmt={discountAmt}
                    setDiscountAmt={(amt) => setDiscountAmt(amt)}
                    contactInfo={contactInfo}
                    step={step}
                    eventInfo={eventInfo}
                    discountCodeReserved={discountCodeReserved}
                    setDiscountCodeReserved={(obj) => setDiscountCodeReserved(obj)}
                />
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{ textAlign: "center" }}>By clicking this button, you accept the <a target="_blank" rel='noreferrer' href="https://stripe.com/en-sg/legal"> Stripe Services Agreement</a>, <a href='https://cardspal.com/ticket-sales-terms-and-conditions/' target='_blank' rel='noreferrer'>CardsPal Terms of Use</a> and <a href='https://cardspal.com/privacy-policy/' target='_blank' rel='noreferrer'>CardsPal Privacy Policy</a>.</div>
                    <SubmitButton style={{ marginTop: 20 }}
                        title="Please provide a valid promo code before continue to payment."
                        disabled={(dealType === "vip" && paymentPrice > 0) || isBusy }
                        onClick={() => gotoRegistrationSuccessful()}
                        type="submit"
                        className="btn btn-primary btn-block"
                    >Confirm Purchase
                    </SubmitButton>
                </div>
            </div >
        )
    }

    return (
        <React.Fragment>
            {(isAccessDenied === null) || invalid ? <></> :
                (isAccessDenied ? <AccessDenied screen="B2B" showBackToDashboard={false} /> :
                    <div style={{ backgroundColor: "#fff" }}>
                        <div  style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Image fluid src={imagePath + dealId + "_banner.png"} />
                        </div>
                        <Container style={{ marginTop: 50, marginBottom: 100 }}>
                            {eventInfo?.data?.registrationEnd <= moment().unix() ?
                                <CardContainer className="mt-3">
                                    <CardBody>
                                        <CardTitle>Registration closed</CardTitle>
                                        <p>
                                            Thank you for your interest. Unfortunately, the registration is now closed. We will make sure to keep you informed for future events.
                                        </p>

                                        <p>
                                            For any queries, please email 10years@cchodlings.sg.
                                        </p>

                                        <p>
                                            See you next time!
                                        </p>
                                    </CardBody>
                                </CardContainer>
                                :
                                <Row className="justify-content-center mt-3">
                                    {isLoading ? null :
                                        custom?.dealInfo[dealId].active ?
                                        <Col>
                                            {eventInfo?.data?.name ?
                                                <div>
                                                    <Description style={{ fontSize: 14 }}>{eventInfo?.data?.name + " > " + dealInfo?.data?.promotion_caption + " > Purchase Details"}</Description>
                                                    <div style={{ maxWidth: 400, margin: "auto" }}>
                                                        <div style={{ display: "flex", flexDirection: "row", marginInline: 30, marginBottom: 10, marginTop: 25, justifyContent: "center" }}>
                                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                <Circle>
                                                                    <TickIcon style={{ color: "#fff", marginBlock: 5, marginInline: 4 }} />
                                                                </Circle>
                                                            </div>
                                                            <div style={{ width: 80, border: "0.5px solid #CDCED9", marginTop: 13, marginInline: 10, alignSelf: "center" }} className='mb-3' />
                                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                <Circle style={{ backgroundColor: step === 'payment' ? "#2AD2C9" : "#722ED1" }}>
                                                                    <EditIcon style={{ color: "#fff", marginBlock: 4, marginInline: 4 }} />
                                                                </Circle>
                                                            </div>
                                                            <div style={{ width: 80, border: "0.5px solid #CDCED9", marginTop: 13, marginInline: 10, alignSelf: "center" }} className='mb-3' />
                                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                <Circle style={{ backgroundColor: step === 'payment' ? "#722ED1" : "#BFBFC1" }}>
                                                                    <WalletIcon style={{ color: "#fff", marginBlock: 5, marginInline: 5 }} />
                                                                </Circle>
                                                            </div>
                                                            <div style={{ width: 80, border: "0.5px solid #CDCED9", marginTop: 13, marginInline: 10, alignSelf: "center" }} className='mb-3' />
                                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                <Circle style={{ backgroundColor: "#BFBFC1" }}>
                                                                    <NotesIcon style={{ color: "#fff", marginBlock: 5, marginInline: 5 }} />
                                                                </Circle>
                                                            </div>
                                                        </div>
                                                        <div style={{ display: "flex", flexDirection: "row", marginInline: 30, marginBottom: 30, justifyContent: "center" }}>
                                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                <div style={{ color: "#6B6C7E", fontSize: 11, width: 90, marginLeft: -30 }}>Select Tickets</div>
                                                            </div>
                                                            <div style={{ width: 80, marginTop: 13, alignSelf: "center" }} className='mb-3' />

                                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                <div style={{ color: "#6B6C7E", fontSize: 11, width: 60 }}>Edit info</div>
                                                            </div>
                                                            <div style={{ width: 80, marginTop: 13, alignSelf: "center" }} className='mb-3' />

                                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                <div style={{ color: "#6B6C7E", fontSize: 11, width: 50 }}>Payment</div>
                                                            </div>
                                                            <div style={{ width: 80, marginTop: 13, alignSelf: "center" }} className='mb-3' />

                                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                <div style={{ color: "#6B6C7E", fontSize: 11, width: 50 }}>Confirmation</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className='text-center'>
                                                    <SubTitle className='m-1'>Ticket Registration</SubTitle>
                                                    <p>Please fill out the following form in order to reserve a ticket.</p>
                                                </div>
                                            }

                                            <div className='d-flex flex-row justify-content-between align-items-center'>
                                                <SubTitle style={{ marginLeft: 10 }}>{step === 'payment' ? "Complete Payment" : "Event"}</SubTitle>
                                                {canPurchase && step === "payment" ?
                                                    <div className='d-flex flex-row'>
                                                        <p className='px-3 align-self-center'>Pay within</p>
                                                        <CountDown
                                                            initialCount={initialCount}
                                                            setTimerStatus={timerStatus}
                                                            getTimerStatus={(e) => getTimerStatus(e)} />
                                                    </div> : null}
                                            </div>
                                            {step === 'details' ?
                                                <RenderDetailsPage />
                                                :
                                                <RenderPaymentPage />
                                            }
                                            </Col>   
                                            :
                                            <CardContainer style={{ backgroundColor: "#fff", maxWidth: 570, justifyContent: "center" }}>
                                                <div style={{ marginInline: 10, marginBlock: 20 }}>
                                                    <div style={{ color: "#000", fontWeight: 800, fontSize: 20, marginBlock: 10, textAlign: "center" }}>SOLD OUT</div>
                                                    <div style={{ fontWeight: 400, fontSize: 14, marginInline: 5, marginBlock: 15 }}>Thank you for your interest. Unfortunately, the tickets have been sold out.</div>
                                                    <div style={{ fontWeight: 400, fontSize: 14, marginInline: 5, marginBlock: 15 }}>For any queries, please email <p style={{ color: "#2B70C7", textDecorationLine: "underline", textDecorationColor: "#2B70C7", display: "inline" }}>support@seedly.sg</p></div>
                                                    <div style={{ fontWeight: 400, fontSize: 14, marginInline: 5, marginBlock: 15 }}>See you next time!</div>
                                                </div>
                                            </CardContainer>
                                    }
                                </Row>
                            }

                            <WarningPopup
                                open={warningMessage.show}
                                warningType={warningMessage.type}
                                message={warningMessage.message}
                                tryAgain={() => tryAgain()}
                                onOpened={() => { setTimerStatus(STOP_TIMER) }}
                            />
                            {isBusy &&
                                <SpinnerDiv>
                                    <Spinner />
                                </SpinnerDiv>}
                        </Container>
                    </div>
                )
            }
        </React.Fragment>

    )
}
const CheckIcon = styled(BsFillCheckCircleFill)`
  color: #3158d6;
  font-size: 16px;
`
const TickIcon = styled(GoCheck)`
  color: #3158d6;
  font-size: 16px;
`
const EditIcon = styled(BiEditAlt)`
  color: #3158d6;
  font-size: 16px;
`
const WalletIcon = styled(FaWallet)`
  color: #3158d6;
  font-size: 14px;
`
const NotesIcon = styled(IoIosPaper)`
color: #3158d6;
font-size: 14px;
`
const InfoIcon = styled(AiFillInfoCircle)`
color: #fff;
font-size: 16px;
`
const Circle = styled.div`
    background: #2AD2C9;
    width: 25px;
    height: 25px;
    border-radius: 50%;
`

export default B2BwPayment