import { API, graphqlOperation } from 'aws-amplify'
import { webformByUserId, webformByTransactionId, webformByWebFormId } from '../../graphql/queries'
import amplitude from 'amplitude-js'

export const getWebFormByUserId = async (userId, webFormId="0001") => {
  try {
    const params = {
      userId,
      filter: {
        webFormId: {
          eq: webFormId
        }
      }
    }  
    // console.log('getWebFormByUserId ', {params})
    const result = await API.graphql(graphqlOperation(webformByUserId, params))
    return result.data.webformByUserId.items
  } catch (e) {
    amplitude.getInstance().logEventWithGroups('Error - webformByUserId', { 'details': e })
    console.error('Error in webformByUserId', e)
  }
}

export const getWebFormByTransactionId = async (transactionId) => {
  try {
    const params = {
      transactionId
    }  
    // console.log('getWebFormByUserId ', {params})
    const result = await API.graphql(graphqlOperation(webformByTransactionId, params))
    console.log('getWebFormByTransactionId ', result.data.webformByTransactionId.items)
    return result.data.webformByTransactionId.items
  } catch (e) {
    amplitude.getInstance().logEventWithGroups('Error - webformByTransactionId', { 'details': e })
    console.error('Error in webformByTransactionId', e)
  }
}

export const getWebFormByWebFormId = async (webFormId) => {
  try {
    let webforms =[]
    let params = {
      limit: 1000,
      webFormId: webFormId
    }  
    do {
      const result = await API.graphql(graphqlOperation(webformByWebFormId, params))
      const items = result.data.webformByWebFormId.items

      if (items.length > 0) {
        webforms = webforms.concat(items)
      }

      const nextToken = result.data.webformByWebFormId.nextToken
      params.nextToken = nextToken
    } while (params.nextToken)

  return webforms

  } catch (e) {
    amplitude.getInstance().logEventWithGroups('Error - webformByWebFormId', { 'details': e })
    console.error('Error in webformByWebFormId', e)
  }
}

export const getWebFormByDealId = async (webFormId, dealId, status='1', statusType='confirmed') => {
  try {
    let webforms =[]
    const params = {
      webFormId: webFormId,
      filter: {
        dealId: {
          eq: dealId
        },
        status: {
          eq: status
        },
        statusType: {
          eq: statusType
        }
      }
    } 
    // console.log('getWebFormByDealId: ',params)
    do {
      const result = await API.graphql(graphqlOperation(webformByWebFormId, params))
      const items = result.data.webformByWebFormId.items

      if (items.length > 0) {
        webforms = webforms.concat(items)
        console.log()
      }

      const nextToken = result.data.webformByWebFormId.nextToken
      params.nextToken = nextToken
    } while (params.nextToken)

  return webforms

  } catch (e) {
    amplitude.getInstance().logEventWithGroups('Error - webformByWebFormId', { 'details': e })
    console.error('Error in webformByWebFormId', e)
  }
}