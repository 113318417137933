import React, {useState, useEffect, useRef} from 'react'
import styled from 'styled-components';
import { TimerDigit, TimerLabel } from './Styled';
import { STOP_TIMER, START_TIMER, RESET_TIMER } from 'utils/constants';

const STATUS = {
    STARTED: 'Started',
    STOPPED: 'Stopped',
  }
const twoDigits = (num) => String(num).padStart(2, '0')
let startTime, endTime = null
let timerInterval = null

const CountDown = (props) => {
    const [secondsRemaining, setSecondsRemaining] = useState(props.initialCount)
    const [status, setStatus] = useState(null)
    
    const secondsToDisplay = secondsRemaining % 60
    const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60
    const minutesToDisplay = minutesRemaining % 60
  
    useEffect(() => {
      let tmrStatus = props.setTimerStatus
      if (tmrStatus === START_TIMER) {
        handleStart()
      } else if (tmrStatus === STOP_TIMER){
        handleStop()
      }else if (tmrStatus === RESET_TIMER){
        handleReset()
      }
    }, [props.setTimerStatus])
    

    useEffect(() => {
      if (status === STATUS.STOPPED) {
        clearInterval(timerInterval)
        props.getTimerStatus(STATUS.STOPPED)
      }
    }, [status])

    useEffect(() => {
      if (secondsRemaining === 0) {
        handleStop()
      }
    }, [secondsRemaining])
    
    const handleStart = () => {
      setStatus(STATUS.STARTED)

      startTime = new Date().getTime()
      endTime = startTime + secondsRemaining * 1000
      // console.log('remaining in minutes:', (endTime - startTime) / 1000  / 60)

      // run every second to compute the remaining time
      timerInterval = setInterval(() => {
        const now = new Date().getTime()
        const remaining = Math.floor((endTime - now)/1000)
        
        if (remaining >= 0) {
            setSecondsRemaining(remaining)
        } else {
            handleStop()
        }
      }, 1000);
    }
    const handleStop = () => {
      setStatus(STATUS.STOPPED)
    }

    const handleReset = () => {
      setStatus(STATUS.STOPPED)
      setSecondsRemaining(props.initialCount)
      setStatus(STATUS.STARTED)
    }

    

    return (
        <div>
            <div className='d-flex flex-row justify-content-between align-items-center'>
                <div>
                    <TimerDigit>
                        <p>{twoDigits(minutesToDisplay)}</p> 
                    </TimerDigit>
                    <TimerLabel>MM</TimerLabel>
                </div>
                <div>
                    <TimerDigit>
                        <p>{twoDigits(secondsToDisplay)}</p>
                    </TimerDigit>
                    <TimerLabel>SS</TimerLabel>
                </div>
            </div>
            
        </div>

    )
}

export default CountDown