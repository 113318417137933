import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useForm, Controller } from "react-hook-form";
import { Form } from 'react-bootstrap';
import { Row, Col, CardBody, Spinner, InputGroup } from "reactstrap"
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import * as Yup from 'yup';
import { UncontrolledTooltip } from 'reactstrap';
import {
    SubTitle, Underline, CardContainer, ToolTipIcon, GroupP, BtnOtp, BtnVerify, 
    ErrMsg, Sup, SubmitButton, Terms, SpinnerDiv, P
} from './Styled';
import { createAppUser, verifyDnD, getWebFormGroup, getPeopleInWebForm } from 'helpers/apiHelper'
import { OTHER_OPTION, DRESS, GROUP_ENTRY, MENU, SCB_DND_TERMS, WAITLIST_DEAL_IDS, 
    DEFAULT_DND_OCT_2023_MENU, DEFAULT_DND_OCT_2023_GROUPS, DND_BEST_DRESS_MAX_MEMBERS
} from  'utils/constants'
import { getErrorMessage, capitalizeFirstLetter } from 'utils'
import Popup from './Popup'
import { Auth } from 'aws-amplify';

const ContactInfo = (props) => {
    const DND_OCT2023_CATEGORY = 'dnd_oct_2023'
    const webFormId = props.webFormId || '0009'
    const webForm = props.webForm || null
    const initialValues = {
        email: props.prefill?.email ?? '',
        countryCode: props.prefill?.countryCode ?? '+65',
        phoneNumber: props.prefill?.phone ?? '',
        firstName: props.prefill?.firstName ?? '',
        lastName: props.prefill?.lastName ?? '',
        otpCountryCode: '+65',
        groupName: props.prefill?.groupName ?? '',
        menu: props.prefill?.menu ?? '',
        dress: props.prefill?.dress ?? '', 
        transport: props.prefill?.transport ?? ''
    }
    const menuData = DEFAULT_DND_OCT_2023_MENU
    const groupData = DEFAULT_DND_OCT_2023_GROUPS

    const infoFields = ["email", "countryCode", "phoneNumber", "firstName", "lastName", "groupName", "menu", "dress", "transport"]

    let dynamicKeys = {};
    if (props.issueQty && props.issueQty > 1) {
        for (let x = 2; x <= props.issueQty; x++) {
            Object.keys(infoFields).forEach((key) => {
                dynamicKeys[infoFields[key] + x] = infoFields[key].includes("email") ? Yup.string().email('Invalid email address').required('Required') : infoFields[key].includes("phoneNumber") ? Yup.string().required("Required") : Yup.string().required("Required")
                initialValues[infoFields[key] + x] = infoFields[key].includes("countryCode") ? '+65' : ''
            })
        }
    }
    if (props.custom?.customClause) {
        dynamicKeys[props.custom?.customClause.name] = props.custom?.customClause.required ? Yup.boolean()
            .required("Required")
            .oneOf([true], "Please accept terms and conditions") : null
    }

    const schema = Yup.object().shape({
            bankId: Yup.string().required("Required"),
            email: Yup.string()
                .email('Invalid email address')
                .required('Required'),
            countryCode: Yup.string().required("Required"),
            phoneNumber: Yup.string().required("Required"),
            firstName: Yup.string().required("Required"),
            lastName: Yup.string().required("Required"),
            groupNameOption: Yup.string().required("Required"),
            groupName: Yup.string().when('groupNameOption', {
                is: OTHER_OPTION, 
                then: Yup.string()
                    .trim('The field cannot start or end with a space')
                    .matches(/^[a-zA-Z0-9- ]*$/, 'Only alphanumeric characters and dash are allowed')
                    .required('Required'), 
                otherwise: Yup.string().trim('The field cannot start or end with a space') 
            }),            
            transport: Yup.string().required("Required"),
            dress: Yup.string().required("Required"),
            menu: Yup.string().required("Required"),
            cancelWarning: props.custom?.cancellationWarning ? Yup.boolean()
                .required("Required")
                .oneOf([true], "Please accept terms and conditions") : null,
            ...dynamicKeys
        })

    const [state] = useState(initialValues);
    const [rsvped, setRsvped] = useState(false)
    const [verified, setVerified] = useState(false)
    const [counter, setCounter] = useState(0);
    const [enableVerify, setEnableVerify] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [session, setSession] = useState(null)
    const [emailInput, setEmailInput] = useState("")
    const [phoneInput, setPhoneInput] = useState(null)
    const [nonSG, setNonSG] = useState(false)
    const [allGroupOptions, setAllGroupOptions] = useState([])
    const [groupNames, setGroupNames] = useState([])
    const [peopleInGroup, setPeopleInGroup] = useState([])
    const [selectedGroupName, setSelectedGroupName] = useState({})
    const [isGroupNameDuplicate, setIsGroupNameDuplicate] = useState(false)
    const [selectedMenu, setSelectedMenu] = useState([])
    const [isGroupNameChange, setIsGroupNameChange] = useState(false)
    const [groupNameOptionNote, setGroupNameOptionNote] = useState('')
    const [disableGroupOption, setDisableGroupOption] = useState(false)
    const isWaitlist = WAITLIST_DEAL_IDS.includes(props.dealId)

    const { 
        register, 
        unregister, 
        getValues, 
        setError, 
        setValue, 
        handleSubmit, 
        reset, 
        clearErrors, 
        control,
        formState: { 
            errors, 
            isValid 
        } 
    } = useForm({
            mode: "onChange",
            reValidateMode: "onChange",
            defaultValues: state,
            resolver: yupResolver(schema)
        });

    useEffect(() => {
        if (!webForm) return

        setValue('dress', webForm.dress)
        setValue('transport', webForm.transport)
        const menu = [{label: webForm.menu, value: webForm.menu}]
        const groupName = [{label: webForm.groupName, value: webForm.groupName}]
        setValue('menu', webForm.menu)
        setValue('groupName', webForm.groupName)
        setSelectedMenu(menu)
        setSelectedGroupName(groupName)
    }, [webForm])

    useEffect(() => {
        getGroup()
    }, [])

    useEffect(() => {

    }, [])

    useEffect(() => {
        const timer =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    const SendOTP = async () => {
        const values = getValues()
        if (values?.otpCountryCode?.length > 1 && values?.otpPhoneNumber?.length > 1) {
            setIsLoading(true)
            const username = values.otpCountryCode + values.otpPhoneNumber
            Auth.signIn(username)
                .then((result) => {
                    clearErrors('otpPhoneNumber')
                    setCounter(60)
                    setEnableVerify(true)
                    setSession(result)
                    setIsLoading(false)
                })
                .catch(async (err) => {
                    if (err.code === 'UserNotFoundException') {
                        await signUp(username);
                    } else if (err.message === "User is disabled.") {
                        setError("otpPhoneNumber", { type: "custom", message: "User is blocked. Please contact support" })
                        setIsLoading(false)
                    } else {
                        setError("otpPhoneNumber", { type: "custom", message: "We've encountered an error sending you the code. Please try again later." })
                        setIsLoading(false)
                    }
                })
        } else {
            setError("otpPhoneNumber", { type: "custom", message: "Please enter a valid phone number" })
        }
    }

    const signUp = async (username) => {
        const password = 'Abcd@' + Math.floor(Math.random() * 90000)

        Auth.signUp({
            username: username,
            password: password,
            attributes: { phone_number: username, email: '' },
        }).then(async (data) => {
            await SendOTP()
        }
        ).catch(err => {
            if (err.code === 'InvalidParameterException') {
                setError("otpPhoneNumber", { type: "custom", message: "Invalid phone number. Please try again." })
            } else {
                setError("otpPhoneNumber", { type: "custom", message: "Invalid parameter. Please try again." })
            }
            setIsLoading(false)
        }
        )
    }

    const verify = () => {
        const values = getValues()
        const confirmationCode = values.otpVerify
        if (session && confirmationCode?.length > 1) {
            setIsLoading(true)
            clearErrors('otpPhoneNumber')
            Auth.sendCustomChallengeAnswer(session, confirmationCode)
                .then(() => {
                    Auth.currentAuthenticatedUser().then(async (user) => {
                        let userId = user.attributes['custom:uuid'] || user.attributes.sub
                        setIsLoading(false)
                        clearErrors("otpVerify")
                        let res = await createAppUser({ userId: userId, phoneNumber: session.username })
                        if (res.statusCode === 'User already exist' || res.statusCode === 'success') {
                            setValue("userId", userId)
                            setVerified(true)
                        } else {
                            setError("otpVerify", { type: 'custom', message: "Something went wrong. Please try again later" })
                        }
                    })
                        .catch((err) => {
                            console.log("err" + err)
                            setIsLoading(false)
                        });

                })
                .catch((err) => {
                    if (err.code === "NotAuthorizedException") {
                        if (err.message === "User is disabled.") {
                            setError('otpVerify', { type: 'custom', message: "User is blocked. Please contact support" });
                        } else {
                            setError('otpVerify', { type: 'custom', message: "Please enter a valid OTP" });
                            SendOTP()
                        }
                    }
                    else {
                        setError("otpVerify", { type: 'custom', message: "Something went wrong. Please try again later" })
                    }
                    setIsLoading(false)
                });
        } else {
            setError("otpVerify", { type: 'custom', message: "Please enter a valid OTP" })
        }
    }

    const onChange = async (e) => {
        if (e.target.name === "email") {
            setEmailInput(e.target.value)
            setValue("billingEmail", e.target.value)
            // setValue('userId', '')
            clearErrors('userId')
        }

        if (e.target.name === "phoneNumber") {
            if (e.target.value) {
                clearErrors('otpPhoneNumber');
                setPhoneInput(parseInt(e.target.value))
                setValue("otpPhoneNumber", e.target.value)
            }
        }

        if (e.target.name.startsWith("email") && !errors[e.target.name]) {
            let loop = props.issueQty || 1
            const values = getValues()
            let duplicateFound = false
            let emailList = [e.target.value]

            for (let x = 0; x < loop; x++) {
                let index = x === 0 ? "" : x
                if (e.target.name !== "email" + index && e.target.value.length > 0) {
                    if (e.target.value === values["email" + index]) {
                        duplicateFound = true
                    }
                }
            }
            if (duplicateFound) {
                setError(e.target.name, { type: "custom", message: "Duplicate email found" })
            }
        }
    }

    const onBlur = async (e) => {
        // console.log('onblur called')
        if (e?.target?.name?.startsWith("email")) {
            let domains = ["@sc.com", "@cardspal.com"]
            let test = false
            for (const domain of domains) {
                var idx = e.target.value.toLowerCase().indexOf(domain);
                if (idx > -1) {
                    test = true
                    break;
                }
            }
            if (!test) {
                setError(e.target.name, { type: "custom", message: "Invalid corporate email" })
            } else if (!errors.email && test) {
                clearErrors('email');
            }
        } else if (e?.target?.name?.startsWith("bankId")) {
            let isNum = /^\d+$/.test(e.target.value);
            if (!isNum || e.target.value.length !== 7)
                setError(e.target.name, { type: "custom", message: "Invalid bankId" })
        }
    }

    const onError = (e) => {
        console.log('---error', e)
    }

    const handleGroupChange = (e) => {
        console.log('handleGroupChange ', e)
        setIsGroupNameChange(true)
        setSelectedGroupName(e)
        setGroupNameOptionNote('')
        clearErrors('groupNameOption')

        if (e.value === OTHER_OPTION) {
            setValue('groupName', '')
            setValue('menu','')
            setValue('dress','')
            setSelectedMenu('')
            setDisableGroupOption(false)
        } else {
            let indexFound = -1
            const foundDefaultGroup = groupData.find((item, index) => {
                if (item.value?.toLowerCase() === e.value?.toLowerCase()) {
                    indexFound = index
                    return true
                }
            })
            if (foundDefaultGroup) {
                setSelectedMenu(menuData[indexFound])
                setDisableGroupOption(true)
                setValue('menu', menuData[indexFound].value)
                setValue('dress', '0')
                setValue('groupName', e.value)
                setValue('groupNameOption', foundDefaultGroup.value)
                setGroupNameOptionNote(<GroupP>You will have to stick to the default options selected for this choice</GroupP>)
            } else {
                getOtherGroupData(e.value.toLowerCase())
            }
        }
        setValue('groupNameOption', e.value)
    }

    const handleMenuChange = (e) => {
        setSelectedMenu(e)
        setValue('menu', e.value)
    }

    const groupNameCustomOption = ({ innerProps, label }) => {
        const newLabel = label.replace(/\[best dress\]/g, '<span style="color:red">best dress</span>');
        return <div {...innerProps} dangerouslySetInnerHTML={{ __html: newLabel }} />;
    }

    const getGroup = async () => {
        let groups = await getWebFormGroup(DND_OCT2023_CATEGORY)

        if (!groups.error) {
          let parseGroups = JSON.parse(groups.body)
          console.log({parseGroups}, {props})
          if (WAITLIST_DEAL_IDS.includes(props.dealId)){
            const filterGroup = parseGroups.Items.filter(item => item.dress === '0')
            console.log({filterGroup})
            parseGroups.Items = filterGroup
          } 
          setAllGroupOptions(parseGroups.Items)
          
          const createdGroups = parseGroups.Items.filter(item => {
            // get only the custom groups
            return item.status === '1' && item.createdBy
          })

          // sort by name a, b, c...
          createdGroups.sort((a, b) => {
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
          });
          
          // add best dress on label
          const _createdGroups = createdGroups.map(item => {
            if (item.dress==='1') {
                return {value: item.name, name: item.name + ' [best dress]'}
            } else {
                return {value: item.name, name: item.name}
            }
          })
       
          // order the drop down
          const defaultGroups = groupData.map(item => {return {...item, name: item.value } })
          const finalGroup = defaultGroups.concat([{ value: OTHER_OPTION, name: OTHER_OPTION }]).concat(_createdGroups)
          const _groupData = finalGroup.map(item => {
            return {
              value: item.value,
              label: item.name
            }
          })
          setGroupNames(_groupData)
        }
    }

    const getGroupCreator = (groupMembers) => {
        let groupLeader = groupMembers.find(item => {
            return item.creator === '1'
        })
        return groupLeader
    }

    const getOtherGroupData = async (group) => {
        setGroupNameOptionNote(<Spinner size="sm" style={{margin:3}}/>)
        let response = await getPeopleInWebForm(webFormId, "")
        if (!response.error) {
            let webFormData = JSON.parse(response.body)
            console.log('getOtherGroupData ',{webFormData})
            let filterDataByGroup = webFormData?.filter(item => {
                return group === item.groupName?.toLowerCase() && item.status === '1'
            })

            setPeopleInGroup(filterDataByGroup)
            console.log({filterDataByGroup}, {webFormId}, {webFormData})
            if (filterDataByGroup.length > 0) {
                let menu = [{ value: filterDataByGroup[0].menu, label: filterDataByGroup[0].menu }] 
                let dress = filterDataByGroup[0].dress
                setSelectedMenu(menu)
                setValue('menu', menu[0].value)
                setValue('dress', dress)
                setDisableGroupOption(true)

                if (dress==='1' && filterDataByGroup.length >= DND_BEST_DRESS_MAX_MEMBERS) {
                    setGroupNameOptionNote(<GroupP>Max group members for best dress is {DND_BEST_DRESS_MAX_MEMBERS} only.</GroupP>)
                    setError('groupNameOption', {type:'custom', message: 'Group is full.'})
                } else {
                    const groupLeader = getGroupCreator(filterDataByGroup)
                    if (!groupLeader) {
                        //group suddenly lost a leader; it might be that the creator cancel the reservation
                        const g = groupNames.filter(item => item.value.toLowerCase() !== group)
                        setGroupNames(g)
                        handleGroupChange({value: OTHER_OPTION, label: OTHER_OPTION})
                        setGroupNameOptionNote('')
                    } else {
                        const leaderName = groupLeader?.firstName + ' ' + groupLeader.lastName
                        const personOrPeople  = filterDataByGroup?.length > 1 ? 'people are' : 'person is'
                        setGroupNameOptionNote(<GroupP><span style={{color: '#7A6FBE', fontWeight: "800"}}>{filterDataByGroup?.length}</span> {personOrPeople} already in this Group. Menu choice and best dressed opt-in for this Group is decided by the Group Leader <span style={{color: '#7A6FBE', fontWeight: "800"}}>{leaderName}</span>.</GroupP>)
                    }
                }
            } else {
                const g = groupNames.filter(item => item.value.toLowerCase() !== group)
                setGroupNames(g)
                handleGroupChange({value: OTHER_OPTION, label: OTHER_OPTION})
                setGroupNameOptionNote('')
            }
        }
    }

    const DNDTemplate = () => {
        return (
            <>
            {selectedGroupName.value === OTHER_OPTION &&
                <>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Form.Label>Enter Group Name<sup>*</sup>
                            <ToolTipIcon id='third' />
                            <UncontrolledTooltip
                                placement='right'
                                target='third'
                            >
                                <p style={{ textAlign: 'left', marginBottom: 0 }}>{GROUP_ENTRY}</p>
                            </UncontrolledTooltip>
                            </Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Control  
                                type="text" 
                                name="groupName"
                                placeholder="Input your group name" 
                                className={errors.groupName && "error"}
                                {...register("groupName")}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="groupName"
                                render={({ message }) => <P>{message}</P>}
                            />
                            {isGroupNameDuplicate && 
                            <P>Matching group name detected: {getValues('groupName').toUpperCase()}. Please submit a unique name or select from the above field.</P>}
                        </Col>
                    </Row>
                    <Underline className='mb-3' />
                </>
            }
            
            <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Menu Choice<sup>*</sup>
                    { selectedGroupName?.value !== OTHER_OPTION  && 
                        <span>
                        <ToolTipIcon id='fourth' />
                        <UncontrolledTooltip
                            placement='right'
                            target='fourth'
                        >
                            {MENU}
                        </UncontrolledTooltip>
                        </span> }
                </Form.Label>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Controller
                        name='menu' 
                        control={control}
                        render={({ ref }) => (
                            <Select
                            isDisabled={disableGroupOption}
                            isSearchable
                            inputRef={ref}
                            value={selectedMenu}
                            options={menuData}
                            placeholder='Select an option'
                            onChange={handleMenuChange}
                            />
                        )}
                        />
                        <ErrorMessage
                        errors={errors}
                        name="menu"
                        render={({ message }) => <P>{message}</P>}
                    />
                </Col>
            </Row>
            <Underline className='mb-3' />
            <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Form.Label>Register for Best Dressed Table?<sup>*</sup>
                        <ToolTipIcon id='tooltip' />
                        <UncontrolledTooltip
                            placement='right'
                            target='tooltip'
                        >
                            <p style={{ textAlign: 'left', marginBottom: 0 }}>{DRESS}</p>
                        </UncontrolledTooltip>
                    </Form.Label><br />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Form.Check
                        disabled={disableGroupOption || isWaitlist}
                        inline
                        name="dress"
                        type="radio" 
                        label="Yes"
                        value="1"
                        {...register("dress")}
                    />
                    <Form.Check 
                        disabled={disableGroupOption}
                        inline
                        name="dress"
                        type="radio" 
                        label="No"
                        value="0"
                        style={{marginLeft: 120}}
                        {...register("dress")}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="dress"
                        render={({ message }) => <P>{message}</P>}
                    />
                </Col>
            </Row>
            <Underline className='mb-3' />
            </>
        )
    }

    const contactTemplate = (value) => {
        let render = []
        let loop = value ? value : 1
        for (let x = 0; x < loop; x++) {
            let index = value ? x === 0 ? "" : (x + 1) : ""
            render.push(
                <div key={"contactInfo-" + x} className='mb-4' >
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Label>Bank ID<Sup>*</Sup></Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Control
                                type="text"
                                name={"bankId" + index}
                                placeholder="Enter your bank ID "
                                className={errors.bankId && "error"}
                                autoComplete='off'
                                {...register("bankId" + index)}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="bankId"
                                render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                            />
                        </Col>
                    </Row>
                    <Underline className='mb-3' />
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Label>Corporate email address<Sup>*</Sup></Form.Label>
                            {/* <div style={{ marginBottom: 5 }}>
                                <div style={{ color: "#6B6C7E", fontSize: 12 }}> Wristband redemption information and payment receipt will be sent to this email  </div>
                            </div> */}
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Control
                                type="text"
                                name={"email" + index}
                                placeholder="Enter your corporate email"
                                className={errors.email && "error"}
                                autoComplete='off'
                                autoCapitalize='none'
                                disabled={props.isDisabled}
                                {...register("email" + index)}
                            />
                            <ErrorMessage
                                errors={errors}
                                name={"email" + index}
                                render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                            />
                        </Col>
                    </Row>
                    <Underline className='mb-3' />
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Label>First name<Sup>*</Sup></Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Control
                                type="text"
                                name={"firstName" + index}
                                placeholder="Enter your first name "
                                className={errors.firstName && "error"}
                                autoComplete='off'
                                {...register("firstName" + index)}
                            />
                            <ErrorMessage
                                errors={"firstName" + index}
                                name="firstName"
                                render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                            />
                        </Col>
                    </Row>
                    <Underline className='mb-3' />
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Label>Last name<Sup>*</Sup></Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Control
                                type="text"
                                name={"lastName" + index}
                                placeholder="Enter your last name"
                                className={errors.lastName && "error"}
                                autoComplete='off'
                                {...register("lastName" + index)}
                            />
                            <ErrorMessage
                                errors={errors}
                                name={"lastName" + index}
                                render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                            />
                        </Col>
                    </Row>
                    <Underline className='mb-3' />
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Label>Group Name<sup>*</sup>
                                <ToolTipIcon id='second' />
                                <UncontrolledTooltip
                                    placement='right'
                                    target='second'
                                >
                                    <p style={{ textAlign: 'left', marginBottom: 0 }}>
                                    Can select from the default group options or groups created by other user.
                                    <br/>Can also create your own group by selecting the Others option.
                                    </p>
                                </UncontrolledTooltip>
                            </Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Controller
                                name="groupNameOption"
                                control={control}
                                render={({ ref }) => (
                                    <Select
                                        isSearchable
                                        inputRef={ref}
                                        value={selectedGroupName}
                                        options={groupNames}
                                        placeholder='Select...'
                                        onChange={handleGroupChange}
                                        styles={{
                                            control: base => ({
                                                ...base,
                                                height: 34,
                                                minHeight: 34
                                            })
                                        }}
                                        // components={{ Option: groupNameCustomOption }}
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="groupNameOption"
                                render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                            />
                            {groupNameOptionNote}
                        </Col>
                    </Row>
                    <Underline className='mb-3' />
                    { isGroupNameChange ? <DNDTemplate/> : null }
                    
                        
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Label>Transportation from CBP?<sup>*</sup></Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Check
                                inline
                                name="transport"
                                type="radio" 
                                label="Yes"
                                value="1"
                                {...register("transport")}
                            />
                            <Form.Check 
                                inline
                                name="transport"
                                type="radio" 
                                label="No"
                                value="0" 
                                style={{marginLeft: 120}}
                                {...register("transport")}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="transport"
                                render={({ message }) => <P>{message}</P>}
                            />
                            </Col>
                    </Row>
                    <Underline className='mb-3' />
                    
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Label>Phone number*</Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Row>
                                <Col xs={'auto'}>
                                    <Controller
                                        name={"countryCode" + index}
                                        control={control}
                                        render={({ field: { onChange, value, name, ref } }) => {
                                            const handleSelectChange = (e) => {
                                                onChange(e?.value);
                                                if (!verified) {
                                                    setValue("otpCountryCode", e?.value)
                                                    if (e?.value !== "+65") {
                                                        setNonSG(true)
                                                    } else {
                                                        setNonSG(false)
                                                    }
                                                }
                                            };
                                            return (
                                                <Select
                                                    inputRef={ref}
                                                    value={props.countryCode.find((e) => e.value === value)}
                                                    options={props.countryCode}
                                                    placeholder='Select...'
                                                    onChange={handleSelectChange}
                                                    styles={{
                                                        control: base => ({
                                                            ...base,
                                                            height: 34,
                                                            minHeight: 34,
                                                            width: 120,
                                                        })
                                                    }}
                                                    // isDisabled={true}
                                                />
                                            )
                                        }}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={"countryCode" + index}
                                        render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                    />
                                    {nonSG ? null :
                                        <Row style={{ marginTop: "0.5rem", textAlign: "center" }}>
                                            <Form.Label style={{ marginTop: "0.5rem", fontSize: 20 }} >OTP</Form.Label>
                                        </Row>
                                    }
                                </Col>
                                <Col>
                                    <InputGroup>
                                    <Form.Control
                                        type="number"
                                        name={"phoneNumber" + index}
                                        placeholder="Enter your phone number"
                                        className={errors.phoneNumber && "error"}
                                        autoComplete='off'
                                            disabled={verified}
                                        {...register("phoneNumber" + index)}
                                    />
                                        {verified || nonSG ? null :
                                            <BtnOtp disabled={counter > 0 ? true : false} onClick={() => SendOTP()}>{counter > 0 ? "Resend in " + counter + "s" : "Send OTP"}</BtnOtp>
                                        }
                                    </InputGroup>

                                    <ErrorMessage
                                        errors={errors}
                                        name={"otpPhoneNumber"}
                                        render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                    />
                                    {nonSG ? null :
                                        <div>
                                            <Row style={{ marginTop: 10, textAlign: "center" }}>
                                                <InputGroup>
                                                    <Form.Control
                                                        type="number"
                                                        name="otpVerify"
                                                        placeholder="Enter OTP"
                                                        className={errors.otpVerify && "error"}
                                                        autoComplete='off'
                                                        disabled={verified}
                                                        {...register("otpVerify")}
                                                    />
                                                    {enableVerify ? 
                                                        <BtnVerify verified={verified} onClick={() => verify()} disabled={verified} variant="outline-primary">
                                                            {verified ? "Verified" : "Verify"}
                                                        </BtnVerify>
                                                    : null}

                                                </InputGroup>
                                            </Row>
                                            <ErrorMessage
                                                errors={errors}
                                                name="otpVerify"
                                                render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                            />
                                            {!verified && !errors.otpVerify && !props.isDisabled &&
                                                <ErrMsg>Please make sure to verify your mobile number.</ErrMsg>
                                            }
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    
                    {isLoading &&
                        <SpinnerDiv>
                            <Spinner />
                        </SpinnerDiv>}
                </div>
            )
        }
        return render
    }

    const processSubmit = async (e) => {
        console.log('submit click')
        setIsLoading(true)
        let otpPhoneNumber = `${e.otpCountryCode}${e.otpPhoneNumber}`
        const values = getValues()
        let userId = values?.userId
        if (e.otpCountryCode==='+65' && !values?.userId) {
            return
        } else {
            // this is for non-sg number that needs no verification
            userId = values?.userId || values?.email?.replace('@', '_') 
        }

        let params = {
            webFormId: props.webFormId ? props.webFormId : "0009",
            bankId: e.bankId,
            email: e.email,
            dealId: props.dealId,
            phoneNumber: otpPhoneNumber,
            userId: userId,
            groupName: selectedGroupName.value===OTHER_OPTION ? e.groupName?.toLowerCase() : selectedGroupName.value?.toLowerCase(),
            menu: e.menu,
            transport: e.transport,
            dress: e.dress,
            category: DND_OCT2023_CATEGORY,
            creator: selectedGroupName.value===OTHER_OPTION ? '1' :'0'
        }
        let verificationData = await verifyDnD(params)
        if (verificationData?.body?.mismatchedFields?.length > 0) {
            let mismatchedFields = verificationData?.body?.mismatchedFields
            mismatchedFields.forEach(field => {
                if (field==='phoneNumber') {
                    setError('otpPhoneNumber', { type: "custom", message: (capitalizeFirstLetter(field) + " already registered") })
                } else {
                    setError(field, { type: "custom", message: (capitalizeFirstLetter(field) + " already registered") })
                }
                
            })
        } else if (verificationData?.canPurchase) {
            e.pastPurchaseQty = verificationData?.body?.purchased?.length || "0"
            props.onSubmit({...e, ...params})
        } else {
            console.error("verification Error", verificationData)
            if (verificationData.error === 'best_dress_group_limit_hit') {
                setGroupNameOptionNote(<GroupP>Max group members for best dress is {DND_BEST_DRESS_MAX_MEMBERS} only.</GroupP>)
                setError('groupNameOption', {type:'custom', message: 'Group is full.'})
            } else if (verificationData.error === 'blacklisted_bankId') {
                setError('bankId', { type: 'custom', message: <span>Please contact recreation club (<a href='mailTo:RecClub-Events.SG@sc.com'>RecClub-Events.SG@sc.com</a>) for further assistance</span>} )
            } else {
                setError("email", { type: 'custom', message: getErrorMessage(verificationData?.error) || "Something went wrong. Please try again later" })
            }
        }
        setIsLoading(false)
    }

    const DnDCancellationWarning = () => {
        return (
            <Row className='justify-content-center' style={{ marginTop: 10 }}>
                <Terms>
                    <Form.Check 
                        name="cancelWarning"
                        type="checkbox" 
                        label={(
                            <span>I have read and agree to the Late Cancellation / No-Show penalties (SG$100) and
                            <a href={SCB_DND_TERMS} target='_blank' rel='noreferrer'> T&Cs</a> of registration<sup>*</sup>
                            </span>
                        )}
                        {...register("cancelWarning")}
                    />
                </Terms>
                <SubmitButton
                    type="submit"
                    className="btn btn-primary btn-block"
                    disabled={
                        !isValid || (!nonSG && !verified) ||
                        Object.keys(errors).length > 0 || isLoading
                    }
                >Proceed to reserve tickets
                </SubmitButton>
            </Row>
        )
    }

    // console.log({isValid}, {errors}, Object.keys(errors).length)
    return (
        <React.Fragment>
            <Row className='justify-content-center'>
                <Col>
                    <Form onSubmit={handleSubmit(processSubmit, onError)} onChange={(e) => onChange(e)} onBlur={(e) => onBlur(e)}>
                        <fieldset disabled={props.isDisabled}>
                            <CardContainer style={props.styling ?? { backgroundColor: "#fff" }} >
                                <CardBody>
                                    <SubTitle>To start off, please share with us your contact info.</SubTitle>
                                    {contactTemplate(props.issueQty)}
                                </CardBody>
                            </CardContainer>
                            {props.custom?.cancellationWarning && <DnDCancellationWarning />}
                            
                        </fieldset>
                    </Form>
                </Col>
            </Row>
        </React.Fragment>

    )
}

export default ContactInfo