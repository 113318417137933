import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { Button, Col, Row, Spinner } from "reactstrap";
import { connect, useSelector, useDispatch } from "react-redux";
import { MdQrCodeScanner } from 'react-icons/md';
import styled from 'styled-components';

import RBAC from '../../components/Common/rbac'

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems, setAlert, setShowModal, setAmplitudeEvent,  setConfig, setEnv  } from "../../store/actions";

// import components
import ModalSetupSuccess from "./Components/ModalSetupSuccess";
import BoxWidget from "components/Common/boxWidget";
import StripeOnboarding from "components/Common/stripeOnboarding";
import StripePrompt from "components/Common/stripePrompt";

// import api helpers
import { listPromotionsByMerchantId } from '../../helpers/GraphQL/promotions'
import { getConfig, getEnv, stripeCreateOnboardingLink, stripeCreateLoginLink, stripeRetrieveKey, stripeRetrieveAccount } from '../../helpers/apiHelper'
import { getMerchantStripeAccount, updateStripeAccount, createStripeAccount } from "../../helpers/GraphQL/userStripeAccount"

// import utils
import { STRIPE_STATUS, DEFAULT_REFRESH_URL, DEFAULT_RETURN_URL, STRIPE_BUTTON_LABEL, GENERIC_ERROR_MESSAGE } from '../../utils/constants'
import { evaluateStripeStatus, getPromotionTotals, openInNewTab, RemoveFreeTrial } from '../../utils'
import  {getStripeStatusColor} from '../../utils/stripe'
import UpgradePlan from "components/Common/upgradePlan";

const Dashboard = (props) => {
  const dispatch = useDispatch()
  const selectedMerchant = useSelector(state => state.Merchant.selectedMerchant)
  const cmsConfig = useSelector(state => state.Config)
  const useSelSetup = useSelector((state) => state.Setup)
  const userInfo = useSelector(state => state.User.user)
  
  const [showSetupModal, setShowSetupModal] = useState(false) 
  const [stripeChecking, setStripeChecking] = useState(true)
  const [stripeAccount, setStripeAccount] = useState({})
  const [stripeAccountStatus, setStripeAccountStatus] = useState(null)
  const [stripeLink, setStripeLink] = useState(null)
  const [stripeKey, setStripeKey] = useState(null)
  const [stripeButtonLabel, setStripeButtonLabel] = useState('')
  const [refreshUrl, setRefreshUrl] = useState(null)
  const [returnUrl, setReturnUrl] = useState(null)
  const [needPrompt, setNeedPrompt] = useState(true)
  const [modalStripePromptStatus, setModalStripePromptStatus] = useState(false)
  const [createStripeButtonDisabled, setCreateStripeButtonDisabled] = useState(true)
  const [totalPromotions, setTotalPromotions] = useState(null)
  const [totalActivePromotions, setTotalActivePromotions] = useState(null)
  const [totalPendingPromotions, setTotalPendingPromotions] = useState(null)
  const [totalSchedulePromotions, setTotalSchedulePromotions] = useState(null)
  const [totalExpiringPromotions, setTotalExpiringPromotions] = useState(null)

  const [totalVouchers, setTotalVouchers] = useState(null)
  const [totalActiveVouchers, setTotalActiveVouchers] = useState(null)
  const [totalPendingVouchers, setTotalPendingVouchers] = useState(null)
  const [totalScheduleVouchers, setTotalScheduleVouchers] = useState(null)
  const [totalExpiringVouchers, setTotalExpiringVouchers] = useState(null)
  const [freeTrial, setFreeTrial] = useState(null)

  const breadcrumbItems = [
    { title: "CardsPal", link: "#" },
    { title: selectedMerchant.display_merchant_name, link: "#" },
    { title: "Dashboard", link: "#" }
  ]

  useEffect(async () => {
    const values = {
      events: {
        name: 'test bonfire'
      },
      voucherGroup: {
        'category': 'ticket',
      }
    }
  }, [])

  useEffect(async () => {
    if (!stripeKey || !selectedMerchant || Object.keys(selectedMerchant).length === 0 || !cmsConfig.cmsv2) return 
    try {
      setStripeChecking(true)
      let _stripeAccount = await getMerchantStripeAccount(selectedMerchant.merchant_id)
      
      if (_stripeAccount && Object.keys(_stripeAccount).length!==0) {
        setStripeAccount(_stripeAccount)
        if (_stripeAccount.status !== STRIPE_STATUS.COMPLETED) {
          await retrieveStripeLink(_stripeAccount, stripeKey)
        }
      } else {
        // first time 
        _stripeAccount = await createStripeAccountLink(stripeKey)
        setStripeAccount(_stripeAccount)
      } 
      setCreateStripeButtonDisabled(false)
      setStripeChecking(false)
      dispatch(setAlert(''))
    } catch(e) {
      dispatch(setAmplitudeEvent('Dashboard initialize error', {'details': JSON.stringify(e)}))
    }
  }, [selectedMerchant, stripeKey])

  useEffect(async () => {
    props.setBreadcrumbItems('Dashboard', breadcrumbItems)
    let _ampTrack = {
      'merchant id': selectedMerchant.merchant_id,
      'merchant name':  selectedMerchant.merchant_name,
      'mainCat': selectedMerchant.mainCat
    }
    dispatch(setAmplitudeEvent('CMS Dashboard page - page load', {..._ampTrack}))
  }, [selectedMerchant])

  useEffect(() => {
    if (useSelSetup.showModal === true){
      setShowSetupModal(true)
    }
  }, [useSelSetup.showModal])
  
  useEffect(async () => {
    await getCMSConfig()
  }, [])

  // get totals for promotions
  useEffect(async () => {
    try {
      if (!selectedMerchant.merchant_id) return

      const promotions = await listPromotionsByMerchantId(selectedMerchant.merchant_id, {onlyPurchasable: false})
      setTotalPromotions(promotions.length === 0 ? '-' : promotions.length)

      const totals = getPromotionTotals(promotions)
      setTotalActivePromotions(totals.totalActive)
      setTotalPendingPromotions(totals.totalPending)
      setTotalSchedulePromotions(totals.totalSchedule)
      setTotalExpiringPromotions(totals.totalExpiring)
    } catch(e) {
      console.error(e)
      dispatch(setAlert(`${GENERIC_ERROR_MESSAGE}[Promotion statistics]`, 'danger'))
      dispatch(setAmplitudeEvent('Dashboard computing promotions - error', {'details': JSON.stringify(e)}))
    }
  }, [selectedMerchant.merchant_id])

  // get totals for vouchers
  useEffect(async () => {
    try {
      if (!selectedMerchant.merchant_id) return
      
      const purchasableDeals = await listPromotionsByMerchantId(selectedMerchant.merchant_id, {onlyPurchasable: true})
      setTotalVouchers(purchasableDeals.length === 0 ? '-' : purchasableDeals.length)

      const totals = getPromotionTotals(purchasableDeals)
      // setTotalVouchers(totals.totalVouchers)
      setTotalActiveVouchers(totals.totalActive)
      setTotalPendingVouchers(totals.totalPending)
      setTotalScheduleVouchers(totals.totalSchedule)
      setTotalExpiringVouchers(totals.totalExpiring)
    } catch(e) {
      console.error(e)
      dispatch(setAlert(`${GENERIC_ERROR_MESSAGE}[Voucher statistics]`, 'danger'))
      dispatch(setAmplitudeEvent('Dashboard computing voucher - error', {'details': JSON.stringify(e)}))
    }
  }, [selectedMerchant.merchant_id])

  useEffect(async () => {
    dispatch(setAlert('Initializing...Please wait while we finish getting updates', 'info'))
    const key = await stripeRetrieveKey()
    if (!key) {
        dispatch(setAlert(`Error loading this page. Please report to admin immediately.`, 'danger'))
        return
    } else {
        setStripeKey(key.stripeSecret)
    }
  }, [])

  useEffect( async()=>{
    if(userInfo !== null) {
      let removeFreeTrial = await RemoveFreeTrial(userInfo)
      setFreeTrial(removeFreeTrial)
    }
    
  },[userInfo])

  async function retrieveStripeLink(_stripeAccount, stripeKey) {
    try {
      const stripeInfo = await stripeRetrieveAccount({ stripeKey, accountId: _stripeAccount.accountId })
      let status
      let link
      let btnLabel

      if (!stripeInfo.charges_enabled) {
          
          status = _stripeAccount.status
          
          const stripeInfo = await stripeCreateOnboardingLink({ email: userInfo.email, 
            stripeKey, 
            refreshUrl, 
            returnUrl, 
            merchantId: selectedMerchant.merchant_id, 
            accountId: _stripeAccount.accountId 
          })
          link = stripeInfo.accountLink.url
          setStripeButtonLabel(STRIPE_BUTTON_LABEL.CONNECT_TO_STRIPE)
          setNeedPrompt(true)
      } else {
          ({status, btnLabel} = await evaluateStripeStatus(stripeInfo))
          
          const stripeLoginLink = await stripeCreateLoginLink({ stripeKey, accountId: _stripeAccount.accountId })
          link = stripeLoginLink.url
          setStripeButtonLabel(btnLabel)
          setNeedPrompt(false)
      }
      setStripeAccountStatus(status)
      setStripeLink(link)

      // update user stripe db record
      await updateStripeAccount({
          accountId: _stripeAccount.accountId,
          merchantId: selectedMerchant.merchant_id,
          email: userInfo.email,
          type: 'on-boarding',
          status
      })
    } catch(e) {
      dispatch(setAlert(`${GENERIC_ERROR_MESSAGE}[Stripe link retrieval issue]`, 'danger'))
      dispatch(setAmplitudeEvent('Dashboard retrieving stripe - error', {'details': JSON.stringify(e)}))
    }
  }

  async function createStripeAccountLink(stripeKey) {
      // first time to on-board to stripe
      
      const stripeInfo = await stripeCreateOnboardingLink({ email: userInfo.email, stripeKey, refreshUrl, returnUrl, merchantId: selectedMerchant.merchant_id })

      setStripeAccountStatus(STRIPE_STATUS.NOT_ONBOARDED)
      setStripeLink(stripeInfo.accountLink.url)
      setStripeButtonLabel(STRIPE_BUTTON_LABEL.CONNECT_TO_STRIPE)
      setNeedPrompt(true)

      // create a user stripe record in db
      const stripeAccount = await createStripeAccount({
          accountId: stripeInfo.account.id,
          merchantId: selectedMerchant.merchant_id,
          email: userInfo.email,
          type: 'on-boarding',
          status: STRIPE_STATUS.NOT_ONBOARDED
      })
      return stripeAccount
  }

  async function getCMSConfig() {
    try {
      if (!cmsConfig || !cmsConfig.cmsv2) {

        const config = await getConfig()
        const env = getEnv()
        
        setRefreshUrl(cmsConfig.cmsv2.stripeVariables.refreshUrl || DEFAULT_REFRESH_URL)
        setReturnUrl(cmsConfig.cmsv2.stripeVariables.returnUrl || DEFAULT_RETURN_URL)
        dispatch(setConfig(config))
        dispatch(setEnv(env))
      } 
      setRefreshUrl(cmsConfig.cmsv2.stripeVariables.refreshUrl || DEFAULT_REFRESH_URL)
      setReturnUrl(cmsConfig.cmsv2.stripeVariables.returnUrl || DEFAULT_RETURN_URL)
    } catch(e) {
      // TODO: we might need to re-route user or ask the user to refresh
      console.error('error on retrieving config file', e)
      dispatch(setAmplitudeEvent('Dashboard get CMS config - error', {'details': JSON.stringify(e)}))
    }
  }

  const toggleShowSetupModal = (e) => {
    let ampTrack = {
      'merchant id': selectedMerchant.merchant_id,
      'merchant name':  selectedMerchant.merchant_name,
      'mainCat': selectedMerchant.mainCat
    }

    setShowSetupModal(!showSetupModal)
    localStorage.setItem('sideBarType', 'default')

    if (!showSetupModal) {
      dispatch(setAmplitudeEvent('CMS successful brand setup pop up - pop up', {...ampTrack}))
    }

    if (e === 'proceed'){
      dispatch(setAmplitudeEvent('CMS successful brand setup pop up - proceed button', {...ampTrack}))
    }else if(e === 'cancel'){
      dispatch(setAmplitudeEvent('CMS successful brand setup pop up - cancel button', {...ampTrack}))
    }else{
      dispatch(setAmplitudeEvent('CMS successful brand setup pop up - cross button', {...ampTrack}))
    }

    dispatch(setShowModal(false))
  }

  function openStripeLink() {
    if (needPrompt) {
        setModalStripePromptStatus(true)
    } else {
        setCreateStripeButtonDisabled(true)
        openInNewTab(stripeLink)
    }
  }

  function closeForm(item, state) {
    setModalStripePromptStatus(state || false)
  }

  function redemption () {
    props.history.push('/redeem')
  }

  const totalPromotionsObj = { title: "Total Promotions added", iconClass: "cellphone-sound", total: totalPromotions, bgColor: "#7A6FBE", buttonLabel: "Add promotions", link: '/manage-promotions' }
  const activePromotionsObj = { title: "Active promotions", iconClass: "check-decagram", total: totalActivePromotions, bgColor: "#35834F", buttonLabel: "View active deals", link: '/manage-promotions' }
  const pendingPromotionsObj = { title: "Promotions pending approval", iconClass: "pencil", total: totalPendingPromotions, bgColor: "#F5B225", buttonLabel: "View deals pending approval", link: '/manage-promotions' }
  const schedulePromotionsObj = { title: "Scheduled promotions", iconClass: "clock-outline", total: totalSchedulePromotions, bgColor: "#0DCAF0", buttonLabel: "View scheduled deals", link: '/manage-promotions' }
  const expiringPromotionsObj = { title: "Expiring promotions", iconClass: "timer-sand-empty", total: totalExpiringPromotions, bgColor: "#EC536C", buttonLabel: "View expiring deals", link: '/manage-promotions' }

  const totalVouchersObj = { title: "Total voucher deals added", iconClass: "cellphone-sound", total: totalVouchers, bgColor: "#7A6FBE", buttonLabel: "Add vouchers", link: '/sell-vouchers' }
  const activeVouchersObj = { title: "Active vouchers", iconClass: "ticket-confirmation", total: totalActiveVouchers, bgColor: "#35834F", buttonLabel: "View active vouchers", link: '/sell-vouchers' }
  const pendingVouchersObj = { title: "Vouchers pending approval", iconClass: "pencil", total: totalPendingVouchers, bgColor: "#F5B225", buttonLabel: "View vouchers pending approval", link: '/sell-vouchers' }
  const scheduleVouchersObj = { title: "Scheduled vouchers", iconClass: "clock-outline", total: totalScheduleVouchers, bgColor: "#0DCAF0", buttonLabel: "View scheduled vouchers", link: '/sell-vouchers' }
  const expiringVouchersObj = { title: "Expiring vouchers", iconClass: "timer-sand-empty", total: totalExpiringVouchers, bgColor: "#EC536C", buttonLabel: "View expiring vouchers", link: '/sell-vouchers' }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Dashboard</title>
      </MetaTags>
      <ScanWrap>
        <ScanButton onClick={redemption}>
          <ScanIcon />Go to QR Scanner
        </ScanButton>
      </ScanWrap>

      <RBAC merchantId={selectedMerchant.merchant_id} roles={['admin']}>
        <Row>
          {/* Vouchers Section */}
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            {!stripeAccount || stripeAccount.status !== STRIPE_STATUS.COMPLETED ?
              <Col style={{ display:"flex", height:'96.5%', marginBottom:'21px', justifyContent:"center", alignItems: "center", backgroundColor: "rgba(122, 111, 190, 50%)", borderRadius:5 }}>
                {stripeChecking ? 
                  <div className="text-center">
                    <Spinner/> 
                  </div>
                :
                  <div style={{marginTop:'20px'}}>
                    <StripeOnboarding
                      stripeStatus={stripeAccountStatus}
                      statusColor={getStripeStatusColor(stripeAccountStatus)}
                      disabled={createStripeButtonDisabled}
                      buttonLabel={stripeButtonLabel}
                      onClick={() => openStripeLink()}
                      outline = {[STRIPE_STATUS.PENDING_VERIFICATION, STRIPE_STATUS.IN_PROGRESS].includes(stripeAccountStatus)?true:false}
                      cta="dasboard page"
                    />
                  </div>
                }
              </Col>
              :
              <Col>
                <Row>
                  <Col className='total-deal-vouchers'>
                    <BoxWidget cta="total deal vouchers" screen="Dashboard"  history={props.history} details={totalVouchersObj}/>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <BoxWidget cta="active deal vouchers" screen="Dashboard"  history={props.history} details={activeVouchersObj}/>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <BoxWidget cta="pending deal vouchers"  screen="Dashboard"  history={props.history} details={pendingVouchersObj}/>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <BoxWidget cta="schedule deal vouchers" screen="Dashboard"  history={props.history} details={scheduleVouchersObj}/>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <BoxWidget cta="expiring deal vouchers" screen="Dashboard"  history={props.history} details={expiringVouchersObj}/>
                  </Col>
                </Row>
              </Col>
            }
          </Col>

          {/* Promotions Section */}
          {freeTrial === false ?
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <Row>
                <Col>
                  <BoxWidget cta="total promotion" screen="Dashboard" history={props.history} details={totalPromotionsObj}/>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <BoxWidget cta="active promotion" screen="Dashboard"  history={props.history} details={activePromotionsObj}/>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <BoxWidget cta="pending promotions" screen="Dashboard"  history={props.history} details={pendingPromotionsObj}/>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <BoxWidget cta="schedule promotion" screen="Dashboard"  history={props.history} details={schedulePromotionsObj}/>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <BoxWidget cta="expiring promotions" screen="Dashboard"  history={props.history} details={expiringPromotionsObj}/>
                </Col>
              </Row>
            </Col>
          : 
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <Col style={{ display:"flex", height:'96.5%', marginBottom:'21px', justifyContent:"center", alignItems: "center", 
                            backgroundColor: "rgba(122, 111, 190, 50%)", borderRadius:5 }}>
              <div style={{marginTop:'20px'}}>
                <UpgradePlan
                  onClick={()=>{props.history.push("/manage-plans")}}
                  disabled={stripeChecking}
                />
              </div>
              </Col>
            </Col>
          }
        </Row>
      </RBAC>
      
      {showSetupModal ? 
        <ModalSetupSuccess toggleModal={(e)=>toggleShowSetupModal(e)} showModal={showSetupModal}/> 
      : null }
      {modalStripePromptStatus ? 
        <StripePrompt disableStripeButtonLInk={() => setCreateStripeButtonDisabled(true)} 
          stripeLink={stripeLink} 
          modalStripePromptStatus={modalStripePromptStatus} 
          cta="dasboard page"
          close={(item, state, type) => closeForm(item, state, type)} /> 
      : null}
    </React.Fragment>
  )
}

const ScanWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: -3.75rem;

  @media (max-width: 475px) {
    justify-content: flex-start;
    top: 0;
    margin-bottom: 1.25rem;
  }
`

const ScanButton = styled(Button)`
  background: #fff;
  border: 1px solid #7A6FBE;
  border-radius: 5px;
  color: #7A6FBE;
  font-size: 14px;
  cursor: pointer;

  &:hover, &:focus {
    background: #fff;
    border: 1px solid #7A6FBE;
    color: #7A6FBE;
  }
`

const ScanIcon = styled(MdQrCodeScanner)`
  width: 25px;
  height: 25px;
  margin-right: 5px;
`

export default connect(null, { setBreadcrumbItems })(Dashboard);