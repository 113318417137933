import {
    SET_ALERT_MESSAGE
} from './actionTypes';

export const setAlert = (message, color) => ({
    type: SET_ALERT_MESSAGE,
    payload: {
        message,
        color
    }
});