import React, { useState, useEffect, Suspense, lazy }  from 'react'
import { remoteConfig, getHappeningOn24 } from 'utils';
import {  getDealInfo, getEvents, reserveVoucher, getEnv } from 'helpers/apiHelper';
import AccessDenied from 'components/Common/accessDenied';
import amplitude from 'amplitude-js';
import EventTicket from "../../components/Common/EventTicket"
import styled from "styled-components"
import { Row, Col, Container, Spinner } from "reactstrap"
import { Image, Button } from 'react-bootstrap';
import FooterImage from '../../assets/images/PoweredByFooter.png'
import QuestionImage from '../../assets/images/question.png'
import { SpinnerDiv, CardContainer } from './Components/Styled';
const LazyBanner = React.lazy(() => import('./Components/Banner'))
import ReactPixel from 'react-facebook-pixel';
import { getUTMParams, getUTMQueryString } from 'helpers/urlHelper';

 const ExternalComponent =  (props) => {
    
    const utmParams = getUTMParams(location);
    const utmQueryString = getUTMQueryString(utmParams)
    const WEB_FORM_IDS = ["0008", "0009"]
    const eventId = props.match.params.eventId;
    const webformId = props.match.params.webFormId
    const imagePath = `https://assets.${getEnv()}.cardspal.com/public/Events/banner/`
    const faviconPath = `https://assets.${getEnv()}.cardspal.com/public/Events/favicon/`

    const [isBusy, setIsBusy] = useState(false)
    const [tickets, setTickets] = useState([])
    const [eventInfo, setEventInfo] = useState([])
    const [isAccessDenied, setAccessDenied] = useState(null);
    const [happeningOn, setHappeningOn] = useState('')
    const [invalid, setInvalid] = useState(true)
    const [dimensions, setDimensions] = useState({ 
        height: window.innerHeight,
        width: window.innerWidth
      })
    const [custom, setCustom] = useState([])
    
    const getCustomSettings = async () => {
        let cust = await remoteConfig("b2bCustom")
        if (cust._value) {
            let parseCustJson = JSON.parse(cust._value)
            setCustom(parseCustJson[eventId])
            return (parseCustJson[eventId])
        } else return null
    }

    const MOBILE_WIDTH = 768

    const handleResize = () => {
        let adjustment = window.document.body.offsetHeight
            setDimensions({
                height: adjustment ,
                width: window.innerWidth
            })
            window.parent.postMessage({cpHeight:adjustment}, '*')
    }
    
    useEffect (()=>{
        window.addEventListener('resize', handleResize)

        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = `${faviconPath}${eventId}_favicon.ico`;

        return _ => {
            window.removeEventListener('resize', handleResize)
        }
    },[])

    useEffect( async() => {
        setIsBusy(true)
        await loadEventDeal()
    }, [])


    const loadEventDeal = async() => {

        let remoteConfigB2BCustom = await getCustomSettings()


        if(remoteConfigB2BCustom.fbPixel){
            const advancedMatching = {};
            const options = {
                autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
                debug: true, // enable logs
                };
            ReactPixel.init(remoteConfigB2BCustom.fbPixel,advancedMatching, options);
            ReactPixel.pageView(); // For tracking page view
        }


        let event = await getEvents(eventId)
        setEventInfo(event?.data?.events)

        if (event.statusCode === '200') {
            let _datetime = getHappeningOn24(event?.data?.events?.startTimestamp, event?.data?.events?.endTimestamp)
            setHappeningOn(_datetime)
            let ticketList = await Promise.all(event.data?.events?.listOfTickets.map(async (item, index) => {
                let deal = await getDealInfo(item)
                let dealCustom = remoteConfigB2BCustom?.dealInfo[deal.data.pk]
                deal?.data ? deal.data.dealLink= `/webform/${webformId}/${eventId}/${deal.data.pk}?${utmQueryString}` :null
                return <EventTicket 
                    key={`ticket-${index}`} 
                    webformId={webformId} 
                    deal={deal?.data} 
                    bundle={dealCustom?.type === 'bundle'} 
                    promotion={false} 
                    issueQty={dealCustom?.issueQty ?? 1} 
                    customPromoText={dealCustom?.customPromoText ?? ""} 
                    disable={dealCustom?.disable || false} />
              }))

            setTickets(ticketList)
            setAccessDenied(false)
        } else {
            setAccessDenied(true)
        }
        setIsBusy(false)
        setInvalid(false)
    }

    const loadTickets = (tickets) =>{
        let render = []
        for (let x=0; x < tickets.length; x+=2){
            x+1 ===  tickets.length ? 
            render.push( <Row key={"tixRow-"+x} ><Col xs={12} sm={12} md={6} lg={6} xl={6}>{tickets[x]}</Col><Col></Col></Row>)
            :
            render.push( <Row  key={"tixRow-"+x}><Col style={{marginBottom:"2%"}} xs={12} sm={12} md={6} lg={6} xl={6}>{tickets[x]}</Col><Col style={{marginBottom:"2%"}} xs={12} sm={12} md={6} lg={6} xl={6}>{tickets[x+1]}</Col></Row>)
        }
        return render
    }

    return (
        <React.Fragment>
            {isBusy ? <SpinnerDiv><Spinner /></SpinnerDiv> :
            (isAccessDenied === null) || invalid  ? <></> :
            ( isAccessDenied ? <AccessDenied screen="B2B" showBackToDashboard={false}/> : 
            <>
            <div  style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
                <Suspense fallback={<SpinnerDiv><Spinner /></SpinnerDiv>}>
                    <LazyBanner imagePath= {imagePath + eventId + "_banner.jpg"} custom={custom} />
                </Suspense>
            </div>
            <FrameContainer id='cpframeContainer'  >
                <BannerContainer id="cpBannerContainer">
                    <Row>
                        {dimensions.width > MOBILE_WIDTH ?
                        <>
                            <Col className='col-8'>
                                                    <Title mobileWidth={MOBILE_WIDTH}> {eventInfo?.name} </Title>
                                <Caption mobileWidth={MOBILE_WIDTH}> {happeningOn} </Caption>
                                </Col>
                            <Col className='col-4'></Col>
                        </>
                        :
                        <>
                            <Col >
                                                    <Title mobileWidth={MOBILE_WIDTH}> {eventInfo?.name} </Title>
                                <Caption mobileWidth={MOBILE_WIDTH}> {happeningOn} </Caption>
                            </Col>
                        </>
                    }
                    </Row>
                    <Divider></Divider>
                </BannerContainer>
                <Container fluid style={{marginTop:0, flexDirection: 'row',flexGrow:1}}>
                    {loadTickets(tickets)}
                </Container>
                {webformId in WEB_FORM_IDS ?
                    <>
                        <Container fluid style={{ marginTop: 20, flexDirection: 'row', flexGrow: 1 }}>
                            <CardContainer style={{ backgroundColor: "#fff", padding: 30 }}>
                                <div style={{ fontSize: dimensions.width > MOBILE_WIDTH ? "1.6vw" : "3.2vw", width: "95%", textAlign: "center", color: "#000" }}>All token donation proceeds (i.e. up to $40 per employee) will go towards Community Chest to support Futuremakers-themed programmes and projects. Community Chest is the philanthropy and engagement arm of the National Council of Social Service (NCSS), which is the national coordinating body for social service agencies.</div>
                            </CardContainer>
                        </Container>
                        <FAQContainer id="cpFooterContainer" >
                            <Image style={{ maxHeight: "10vh", marginBottom: "3vh" }} fluid src={QuestionImage} />
                        </FAQContainer>
                        <div style={{ fontSize: dimensions.width > MOBILE_WIDTH ? "1.6vw" : "3.2vw", width: "95%", textAlign: "center", color: "#000" }}>Have a question? Click <a href='https://cardspal.com/faqs-for-sc-family-day/' target='_blank' rel='noreferrer'>here</a> to find out more! </div>
                    </>
                : null}
                <FooterContainer id="cpFooterContainer" >
                    <Image onLoad={handleResize} fluid style={{minHeight:"3vh", maxHeight:"5vh",marginBottom:"5vh"}}  src={FooterImage}/>
                </FooterContainer>
            </FrameContainer>
            {custom.customFooter &&  
                <>
                <Row className="justify-content-center mt-5" display={{backgroundColor:"#F7F8F9"}}>Our Partners</Row>
                    <Row className="justify-content-center mt-3 mx-3">
                        <Col className="col-sm-6 pd-3" align="center">
                            <Image style={{maxHeight:"10vh",marginBottom:"3vh"}} fluid src={imagePath + eventId + "_footer.png"}/>
                        </Col>
                    </Row>
                </>
            }
            </>
            )
            }

            </React.Fragment>

      )
 }

 const FrameContainer = styled.div`
        display: flex;
        flex-direction: column;
        background-color: white;
        height:100%;
        padding:2%;
        padding-left:5%;
        padding-right:5%;
        padding-bottom:1%;
        flex:1;
    `
    const FooterContainer = styled.div`
        margin-top:5%;
        padding-left: 1%;
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:center;

    `
const FAQContainer = styled.div`
       /* margin-top:5%; */
       padding-left: 1%;
       display:flex;
       flex-direction:row;
       align-items:center;
       justify-content:center;

   `
    const BannerContainer = styled.div`
        display: inline-block;
        margin-top: 1%;
        padding-top: 1%;
        padding-bottom: 1%;
        padding-left: 2%;
        padding-right: 2%;
        width: 100%;
        /* cursor: pointer; */
    `

    const Title = styled.div`
        font-size: 3vw;
        color:black;
        font-weight:600;
        font-style:normal;
        font-family:Rubik;
        @media (max-width: ${props => props.mobileWidth}px) {
            text-align:center;
            font-size: 4.5vw;
        }
    `
    const Caption = styled.div`
        font-size: 2.8vw;
        color:black;
        font-weight:400;
        font-style:normal;
        font-family:Rubik;
        @media (max-width: ${props => props.mobileWidth}px) {
            text-align:center;
            font-size: 4vw;
        }
    `
    const Divider = styled.div`
        color: #E7E7ED;
        background-color: #E7E7ED;
        height: 5px;
        width: 100%;
        margin-bottom:5%;
        margin-top:3%;
    `

  export default ExternalComponent;