import React, { useState, useEffect } from 'react';
import { Container, Spinner } from 'reactstrap';
import { API } from 'aws-amplify';
import { TICKET_API } from 'utils/constants';
import { parseQueryString } from '../../utils';
import styled from 'styled-components';
import { AiFillCaretDown } from 'react-icons/ai';

const GroupInfo = (props) => {

  const groupName = props.match.params.groupName;
  const search = props.location.search;

  const params = new URLSearchParams(props.location.search)
  const transactionId = params.get('transactionId') || ''

  // const dealId = params.get('dealId') || ''
  // const eventId = params.get('eventId') || ''

  const [CreatorEntry, setCreatorEntry] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [NormalList, setNormalList] = useState([]);
  const [DisplayNormal, setDisplayNormal] = useState([]);
  const [Waitlist, setWaitlist] = useState([]);
  const [DisplayWaitlist, setDisplayWaitlist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [HasLastEvaluatedKey, setHasLastEvaluatedKey] = useState(false)

  useEffect(() => {
    fetchGroupInfo();
  }, [])

  /*
    Get WebForm data from WebForm table, query by groupName
    Get WebFormGroup data from WebFormGroup table, query by groupName
    Get VoucherUser data from VoucherUser table, query by transactionId
  */

  const sortData = (webform, createdBy) => {
    var splitA = webform.filter(item => item.createdBy?.includes(createdBy))
    var splitB = webform.filter(item => item.transactionId?.includes(transactionId));
    var splitC = webform.filter(item => !item.createdBy?.includes(createdBy) && !item.transactionId?.includes(transactionId));
    let combinedEntries
    if (splitA[0]?.transactionId === splitB[0]?.transactionId) {
      combinedEntries = splitA.concat(splitC)
    } else {
      combinedEntries = splitA.concat(splitB.concat(splitC))
    }
    return combinedEntries
  }

  const fetchGroupInfo = async () => {
    try {
      setLoading(true);

      const queryString = parseQueryString(search)
      const { hash } = queryString
      const path = `/groupInfo/${groupName}?hash=${hash}`

      const result = await API.get(TICKET_API, path)

      if (result.statusCode === '200') {
        let groupData = result.data.groupData || []
        setGroupData(groupData ? groupData : null)
        let webformDataNormal = result.data.webformDataNormal || []
        let webformDataWaitlist = result.data.webformDataWaitlist || []
        let hasLastEvaluatedKey = result.data.hasLastEvaluatedKey || false
        let creator = null
        let creatorFound = false

        // Normal list sorting and consolidation 

        if (webformDataNormal && webformDataNormal.length > 0) {
          if (groupData && groupData.createdBy) {
            creator = webformDataNormal.find(entry => entry.createdBy === groupData.createdBy) || null
            creatorFound = creator ? true : false
            webformDataNormal = sortData(webformDataNormal, groupData.createdBy)
          }
        }

        // Waitlist sorting and consolidation 

        if (webformDataWaitlist && webformDataWaitlist.length > 0) {
          if (groupData && groupData.createdBy) {
            if (!creatorFound) {
              creator = webformDataWaitlist.find(entry => entry.createdBy === groupData.createdBy) || null
              creatorFound = creator ? true : false
            }
            webformDataWaitlist = sortData(webformDataWaitlist, groupData.createdBy)
          }
        }

        // if no creator info found (especially for indv groups)

        if (!creatorFound) {
          creator = webformDataNormal.length > 0 ? webformDataNormal[0] : webformDataWaitlist[0]
          webformDataNormal = sortData(webformDataNormal, 'dummy')
          webformDataWaitlist = sortData(webformDataWaitlist, 'dummy')
        }
        setCreatorEntry(creator)

        setNormalList(webformDataNormal)
        setDisplayNormal(webformDataNormal.slice(0, 5))
        setWaitlist(webformDataWaitlist)
        setDisplayWaitlist(webformDataWaitlist.slice(0, 5))
        setHasLastEvaluatedKey(hasLastEvaluatedKey)
        setLoading(false);
      }
    } catch (e) {
      console.log('Error in fetchGroupInfo:', e)
    }
  }

  const isNotNull = (item) => {
    if (item && item !== 'undefined' && item !== null && item !== "null" && item !== "NULL" && item !== "Null" && item.length > 0)
      return true
    else
      return false
  }

  const onExpandNormal = () => {
    setDisplayNormal(NormalList)

  }
  const onExpandWaitlist = () => {
    setDisplayWaitlist(Waitlist)
  }

  return (
    <TicketContainer fluid>
      {loading ? <SpinnerIcon /> :
        <TicketWrap>
          <EventBanner src={"https://assets.prod.cardspal.com/public/DealImages/5e125030-278a-4e16-8fad-5e1281944adb_45ac80e8-ef36-415d-8b53-455be2495cbc/208445e4-74f4-91fc-f9b3-d44afddfce65.png"} />
          <H1>Group Name</H1>
          <H2>{groupName}</H2>
          <H1>Group Menu Choice</H1>
          <H2>{CreatorEntry.menu}</H2>
          <H1>Group registered for best dressed table</H1>
          <H2>{CreatorEntry.dress === '1' ? "Yes" : "No"}</H2>
          {HasLastEvaluatedKey ?
            <GreyTextLight style={{ marginLeft: "1rem", width: "80%", color: "red", marginTop: "20px" }}>Note: Only limited entries are being displayed here. For further information contact SCRC.</GreyTextLight>
            : null}
          {DisplayNormal && DisplayNormal.length > 0 ?
            <div>
              <H1>{"Confirmed list (" + NormalList.length + ")"}</H1>
              <GreyTextLight style={{ marginLeft: "1rem", width: "95%" }}>Users highlighted in red have already cancelled their tickets </GreyTextLight>
              <ButtonWrap>
                {DisplayNormal.map((item, index) => {
                  let voucher = item.VoucherData
                  return (
                    <TicketButton style={voucher && voucher.valid && voucher.valid === '0' ? { background: "#FEEFEF", opacity: 0.6 } : {}} key={index}>
                      <TextWrap style={{ width: "10%", marginRight: '5%' }}>
                        <GreyTextLight>Table</GreyTextLight>
                        <GreyTextBold1>{isNotNull(item.tableNumber) ? item.tableNumber : "TBC"}</GreyTextBold1>
                      </TextWrap>
                      {groupData?.createdBy && item === CreatorEntry ?
                        <CreatorContainer style={{ width: "65%", alignSelf: "center" }}>
                          <WhiteTextLight style={{ background: "#0095FF", borderRadius: 8, paddingInline: 10, marginLeft: -10 }}>Creator</WhiteTextLight>
                          <GreyTextBold2 style={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis", height: 25, alignSelf: "flex-start", marginTop: 5, marginLeft: -10 }}>{item.email}</GreyTextBold2>
                        </CreatorContainer>
                        :
                        <GreyTextBold2 style={{ width: "65%", overflow: "hidden", textOverflow: "ellipsis" }}>{item.email}</GreyTextBold2>
                      }
                      {item.transport && item.transport === '1' ? <H2 style={{ fontSize: 18 }}>{"🚌"}</H2> : null}
                    </TicketButton>
                  )
                })}
              </ButtonWrap>
              {DisplayNormal.length < NormalList.length ?
                <ExpandWrap onClick={() => onExpandNormal()}>
                  <GreyTextBold2 style={{}}>{"Expand to view more"}</GreyTextBold2>
                  <DownArrow />
                </ExpandWrap>
                : null
              }
            </div> : null}
          {DisplayWaitlist && DisplayWaitlist.length > 0 ?
            <div>
              <H1>{"Waitlist (" + Waitlist.length + ")"}</H1>
              <GreyTextLight style={{ marginLeft: "1rem", width: "95%" }}>Anyone with assigned table can attend the event on that day. </GreyTextLight>
              <ButtonWrap>
                {DisplayWaitlist.map((item, index) => {
                  let voucher = item.VoucherData
                  return (
                    <TicketButton style={voucher && voucher.valid && voucher.valid === '0' ? { background: "#FEEFEF", opacity: 0.6, marginLeft: "5px" } : { marginLeft: "5px" }} key={index}>
                      <TextWrap style={{ width: "10%", marginRight: '5%' }}>
                        <GreyTextLight>Table</GreyTextLight>
                        <GreyTextBold1>{isNotNull(item.tableNumber) ? item.tableNumber : "TBC"}</GreyTextBold1>
                      </TextWrap>
                      {groupData?.createdBy && item === CreatorEntry ?
                        <CreatorContainer style={{ width: "65%", alignSelf: "center" }}>
                          <WhiteTextLight style={{ background: "#0095FF", borderRadius: 8, paddingInline: 10, marginLeft: -10 }}>Creator</WhiteTextLight>
                          <GreyTextBold2 style={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis", height: 25, alignSelf: "flex-start", marginTop: 5, marginLeft: -10 }}>{item.email}</GreyTextBold2>
                        </CreatorContainer>
                        :
                        <GreyTextBold2 style={{ width: "65%", overflow: "hidden", textOverflow: "ellipsis" }}>{item.email}</GreyTextBold2>
                      } {item.transport && item.transport === '1' ? <H2 style={{ fontSize: 18 }}>{"🚌"}</H2> : null}
                    </TicketButton>
                  )
                })}
              </ButtonWrap>
              {DisplayWaitlist.length < Waitlist.length ?
                <ExpandWrap onClick={() => onExpandWaitlist()}>
                  <GreyTextBold2 style={{}}>{"Expand to view more"}</GreyTextBold2>
                  <DownArrow />
                </ExpandWrap>
                : null
              }
            </div> : null}
        </TicketWrap>
      }
    </TicketContainer >
  )
}

const TicketContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000;
  position: absolute;
  margin-top: 50px;
  padding-bottom: 50px;
  background-color: #F5F6FA;
`

const TicketWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative; 
`

const ButtonWrap = styled(Container)`
margin-top: 20px`

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  margin-left: 0.875rem;
`
const ExpandWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  margin-left: 0px;
  cursor: pointer;
  margin-top: 20px;
`
const TicketButton = styled(Container)`
  display: flex;
  align-items: center;
  align-self: start;
  margin-left:5px;
  width: 150%;
  height: 70px;
  background: #fff;
  border: none;
  border-radius: 0.625rem;
  box-shadow: 0px 4px 4px rgba(210, 210, 210, 0.25);
  color: #000;
  margin-bottom: 15px;
  max-width: 350px;`

const CreatorContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  border: none;
  justify-content: center;
  margin: 0;
  color: #000;`

const SpinnerIcon = styled(Spinner)`
  display: flex;
  color: #531DAB;
  position: relative;
  top: 350px;
`

const EventBanner = styled.img`
  display: flex; 
  width: 95%;
  align-self: center;
  height: 150px;
  object-fit: cover;
  margin-top: 2rem;
  border-radius: 15px;

  @media (max-width: 450px) {
    margin-top: 0;
  }
`

const H1 = styled.h1`
  font-size: 18px;
  font-weight: bold;
  margin-top: 2rem;
  margin-left: 1rem;
`

const H2 = styled.h2`
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  text-align: left;
  margin-left: 1rem;
`
const GreyTextLight = styled.h2`
  color: #6B6C7E;
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  text-align: left;
  width: 100%;
`
const GreyTextBold1 = styled.h2`
  color: #6B6C7E;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  text-align: center;
  width: 100%;
`
const GreyTextBold2 = styled.h2`
  color: #6B6C7E;
  font-size: 14px;
  justify-content: flex-start;
  align-self: center;
  font-weight: 500;
  margin: 0;
  text-align: left;
`

const WhiteTextLight = styled.h2`
  color: #FFF;
  font-size: 10px;
  justify-content: flex-start;
  align-self: flex-start;
  font-weight: 400;
  margin: 0;
  text-align: left;
`

const DownArrow = styled(AiFillCaretDown)`
  color: #272833;
  margin: 0px 0px 0px 5px;
  font-size: 16px;
`
export default GroupInfo;