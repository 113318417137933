import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Row, Col, CardBody, Card, Alert, Container, Spinner } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import { setBreadcrumbItems, setShowModal } from "store/actions";
import Select from 'react-select'
import { Image} from "react-bootstrap"


// import component 
import SetupOutlet from "./Components/setupOutlet";
import ImageUploader from "components/Common/imageUploader";

// import store actions
import { setAlert, setUserMerchants, setSelectedMerchant, setUser, setAmplitudeEvent } from "store/actions";

// import graphql
import { create as createBrand } from "helpers/GraphQL/brands";
import { create as createOutlet } from "helpers/GraphQL/outlet";
import { updateUser } from "helpers/GraphQL/user";

// import helpers
import { getConfig, sendEmail } from "../../helpers/apiHelper";

// import utils
import { MERCHANT_CATEGORY } from '../../constants';
import { getAssetUrl, guidGenerator, isValidHttpUrl, isValidURL, handleImageUpload } from '../../utils'
import { DEFAULT_MERCHANT_IMAGE } from "../../utils/constants";

const initValues = {
    companyName:'',
    uenNumber:'',
    brandName:'',
    brandCategory: MERCHANT_CATEGORY[0].value,
    brandSummary:'',
    brandWebsite:'',
    outletName:''
}

const SetupCompanyProfile = (props) => {
    const dispatch = useDispatch()
    let  config = useSelector((state) => state.Config)
    const [selectedBrand, setSelectedBrand] = useState(MERCHANT_CATEGORY[0])
    const [values, setValues] = useState(initValues)
    const [setupOutlet, setSetupOutlet] = useState([])
    const [countChar, setCountChar] =  useState('0/250')
    // const [brandLogo, setBrandLogo] = useState({})
    // const [brandBanner, setBrandBanner] = useState({})
    // const [previewLogo, setPreviewLogo] = useState([])
    // const [previewBanner, setPreviewBanner] = useState([])
    // const [preventCache, setPreventCache] = useState(`?t=${new Date()}`)
    const [userInfo, setUserInfo] = useState(null)
    const [CMSConfig, setCMSConfig] = useState({})
    const [errorMsg, setErrorMsg] = useState(null)
    const [loading, setLoading] = useState(false)
    const [croppedImages, setCroppedImages] = useState({})
    const selUserInfo = useSelector(state => state.User)
    const breadcrumbItems = [
        { title: "CardsPal", link: "#" },
        { title: "Setup", link: "#" }
    ]
    const [validUrl, setValidUrl] = useState(true)

    useEffect(() => {
        dispatch(setAlert('You need to complete your profile set up before adding deals and get it approved.','danger'))
        dispatch(setBreadcrumbItems('Setup', breadcrumbItems))

        let user = selUserInfo.user
        setUserInfo(user)
        if (user && Object.keys(user).length>0 && user.subPlan){    
            let subPlan = { subPlan : user.subPlan }
            dispatch(setAmplitudeEvent('CMS Setup page - page load', {subPlan}))
        }
        
    }, [])

    useEffect(async () => {
        if (!config && !config.cmsv2) {
            try {
                config = await getConfig()
            } catch (error) {
                console.error(error)
            }
        }
        setCMSConfig(config)
    }, [config])

    const handleValidSubmit = async(e, values) => {
        e.preventDefault()
        e.stopPropagation()
        try {
            setLoading(true)

            if (!croppedImages.logo) {
                setLoading(false)
                setErrorMsg('Please upload logo')
                return
            }

            if (setupOutlet.length === 0 && !values.outletName) {
                setLoading(false)
                setErrorMsg('Please add at least one outlet.')
                return 
            }

            let merchant_id = guidGenerator()
            let logoPath = `MerchantImages/logo_${merchant_id}${croppedImages.logo.ext}`
            let logoURL 
            let bannerPath = croppedImages.banner ? `MerchantImages/brand_${merchant_id}${croppedImages.banner.ext}` : ''
            let bannerURL 

            const outletsCreated = [...setupOutlet, { outletName: values.outletName }]

            if (croppedImages.logo) logoURL = await getAssetUrl(logoPath)
            //if (croppedImages.banner) bannerURL = await getAssetUrl(bannerPath)
            if (croppedImages.banner) {
                bannerURL = await  getAssetUrl(bannerPath)
            }else {
                bannerURL = DEFAULT_MERCHANT_IMAGE
            }

            let brand = {
                merchant_id: merchant_id,
                mainCat: selectedBrand.value,
                merchant_name: values.brandName,
                display_merchant_name: values.brandName,
                merchant_name_lowercase: values.brandName.toLowerCase(),
                pockCalMerchImage: logoURL,
                merchantImageUrlV2: bannerURL,
                website: values.brandWebsite,
                summary: values.brandSummary,
                defaultImageUrl: DEFAULT_MERCHANT_IMAGE,
                valid:'Y'
            }
            let responseCreateBrand = await createBrand(brand)

            // upload images when successfully added the brand
            if (bannerURL) await handleImageUpload(bannerPath, croppedImages.banner)
            if (logoURL) await handleImageUpload(logoPath, croppedImages.logo)

            // update redux store and localstorage
            delete responseCreateBrand.outlets
            const target = { [responseCreateBrand.merchant_id] : responseCreateBrand }
            localStorage.setItem("userMerchants", JSON.stringify(target))
            dispatch(setUserMerchants(target))
            localStorage.setItem('selectedMerchant', JSON.stringify(responseCreateBrand))
            dispatch(setSelectedMerchant(responseCreateBrand))

            if (responseCreateBrand) {
                let userRoles = {
                    ...userInfo,
                    roles: JSON.stringify({ 
                        merchants: [{
                            merchantId : merchant_id, 
                            roles: 'admin'
                        }]
                    }),
                    corpName: values.companyName,
                    uen: values.uenNumber
                }

                // set user store redux, localstorage, update db
                let responseUpdateUser = await updateUser(userRoles)
                delete responseUpdateUser.stripe
                dispatch(setUser(responseUpdateUser))
                localStorage.setItem('user', JSON.stringify(responseUpdateUser))
                
                if (outletsCreated.length>0){
                    await addNewOutlet(outletsCreated, responseCreateBrand, merchant_id)
                    await sendEmailToNewAccount(responseUpdateUser)
                }
                
                let ampTrack = {
                    'merchant id': merchant_id,
                    'merchant name': values.brandName,
                    'mainCat': selectedBrand.value,
                    'display merchant name': values.brandName,
                    'website': values.brandWebsite,
                    'summary': values.brandSummary
                }
                dispatch(setAmplitudeEvent('CMS Setup page - Set up profile button', {...ampTrack}))
                gotoDashboard()
            }
            setLoading(false)
        } catch (error) {
            console.error('error submitting form handleValidSubmit ', error)
            setLoading(false)
            setErrorMsg('Error processing this page. Please report to the Administrator')
            dispatch(setAmplitudeEvent('Error - Setup page handleValidSubmit', { 'details': JSON.stringify(error) }))
        }
    }

    const sendEmailToNewAccount = async (brand) => {
        try {
            if (CMSConfig && CMSConfig.cmsv2 && CMSConfig.cmsv2.emailReceiver) {
                // sending email to internal cardspal
                const bodyForInternal = `New account has been set up on ${brand.createdAt}\n\n
                    Company name: ${brand.corpName}
                    Business rep: ${userInfo.firstName} ${userInfo.lastName}
                    Email: ${userInfo.email}
                    Contact Number: ${userInfo.mobile}\n\n`

                await sendEmail({
                    to: [...CMSConfig.cmsv2.emailReceiver],
                    content: bodyForInternal,
                    subject: 'New account setup'
                })

                // send email to user who setup account
                await sendEmail({
                    to: [userInfo.email],
                    content: "",
                    type: 'template',
                    template: 'NewlyOnboarded',
                    templateData: "{}",
                    subject: ''
                })

            } else {
                console.debug('Cannot retrieve email receivers from config. No email sent')
            }
        } catch(error) {
            dispatch(setAmplitudeEvent('Error - Setup page sendEmailToNewAccount', { 'details': JSON.stringify(error) }))
            console.error('error on sendEmail',error)
        }
    }

    const gotoDashboard = () => {
        localStorage.setItem('sideBarType', 'default')
        props.history.push('/dashboard');
        dispatch(setShowModal(true))
    }
    
    const handleInputChange = (e) => {
        setErrorMsg(null)
        const { name, value } = e.target;
        setValues({
        ...values,
        [name]: value,
        });

        if (name === 'brandSummary') {
            setCountChar(`${value.length}/250`)
        }

        if (name === 'brandWebsite'){
            let inputWebsite = e.target.value
            let isValidURLA = isValidURL(inputWebsite)
            let isValidURLB = isValidHttpUrl(inputWebsite)
            if (inputWebsite.length > 0){
                if(isValidURLA && isValidURLB){
                    setValidUrl(true)
                }else{
                    setValidUrl(false)
                }
            }else {
                setValidUrl(true)
            }
        }

    };

    const addOutlet = (e) => {
        e.preventDefault()
        let outlet = values['outletName'].trim()
        if (outlet.length>0) {
            setSetupOutlet([...setupOutlet, {outletName:outlet}])
            values['outletName'] =  ""
        }
        
    }

    const deleteOutlet = outletName => {
        setSetupOutlet(setupOutlet.filter(item => item.outletName !== outletName ))
    }

    const addNewOutlet = async (outletsCreated, brand) => {
        try {
            for (let i = 0; i < outletsCreated.length; i++) {
                let o = outletsCreated[i]
                let store_id = guidGenerator()
                let merchant_unique_id = `${brand.merchant_id}_${store_id}`
                let outlet = {
                    outlet_name: o.outletName,
                    store_id: store_id,
                    merchant_id: brand.merchant_id,
                    merchant_unique_id,
                    display_merchant_name: brand.display_merchant_name,
                    merchant_name_lowercase: brand.merchant_name_lowercase,
                    mainCat: brand.mainCat,
                    merchantImageUrlV2: brand.merchantImageUrlV2,
                    defaultImageUrl: brand.defaultImageUrl
                }
                
                const responseCreateOutlet = await createOutlet(outlet)
                console.debug('---responseCreateOutlet', responseCreateOutlet)
            }
        } catch (error) {
            dispatch(setAmplitudeEvent('Error - Setup page addNewOutlet', { 'details': JSON.stringify(error) }))
            console.error('error on addNewOutlet ', error)
            throw error
        }
    }

    async function getCroppedImage(cropped, fieldName) {
        try {

        let _croppedImages = croppedImages ?? {}
        _croppedImages[fieldName] = cropped

        setErrorMsg(null)
        setCroppedImages({..._croppedImages})
    } catch (error) {
        dispatch(setAmplitudeEvent('Error - Setup page getCroppedImage', { 'details': JSON.stringify(error) }))
        console.error('error on getCroppedImage ', error)
    }
    }


return(
    <React.Fragment>
        <meta 
          name='viewport' 
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' 
        />
        <MetaTags>
            <title>Setup Company Profile | CardsPal Business</title>
        </MetaTags>
        <div className="mt-2">
            <Container>
                <Row className="justify-content-center">
                    <Col>
                        <AvForm onValidSubmit={(e, v) => {
                            handleValidSubmit(e, v)
                        }}>  
                           {/*  <Alert color="danger">
                                <p className="mb-0">
                                You need to <strong style={{fontSize:14}}>complete your profile set up</strong> before adding deals and get it approved.
                                </p>
                                </Alert>
                            <h5>Setup</h5> */}
                            <Card>
                                <CardBody>
                                    <h4>Setup company profile</h4>
                                    <p>Please ensure the details are accurate as CardsPal will verify on this.</p>
                                    <div className="mb-3">
                                    
                                <Row xs={1} sm={1} md={2} lg={2} xl={2}>
                                <AvField
                                    name="companyName"
                                    label="Company name in ACRA"
                                    className="form-control"
                                    type="text"
                                    required
                                    errorMessage="This field is required"
                                />
                                <AvField
                                    name="uenNumber"
                                    label="UEN number"
                                    className="form-control"
                                    type="text"
                                    required
                                    errorMessage="This field is required"
                                />
                                </Row>
                                
                            </div>
                                </CardBody>
                            </Card>

                            <Card>
                                <CardBody>
                                    
                                        <Row>
                                            <Col>
                                                <h4>Brand setup</h4>
                                                <p>Fill in the mandatory fields marked with asterisk(*). You can always come back to the remainding fields via “Manage Brands” later on.</p>
                                            </Col>
                                            <Col className='d-none d-md-block'>
                                                <h4>Preview</h4>
                                            </Col>  
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <div className="mb-3">
                                                <h6>Store name*</h6>
                                                <AvField
                                                    name="brandName"
                                                    label="You can use your storefront name or the name of your website."
                                                    className="form-control mb-0"
                                                    type="text"
                                                    required
                                                    errorMessage="This field is required"
                                                />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <div className="mb-3">
                                                <h6>Brand category*</h6>
                                                    <Select options={MERCHANT_CATEGORY}
                                                    value = {selectedBrand}
                                                    onChange={(e)=>setSelectedBrand(e)}
                                                    placeholder=''
                                                    styles={{
                                                        control: (provided) => ({
                                                          ...provided,
                                                          boxShadow: "none",
                                                            "&:focus-within": {
                                                            borderColor: "#CED4DA",
                                                            }
                                                        }),
                                                        option: (provided, state) => ({
                                                           ...provided,
                                                           backgroundColor: state.isFocused && "#7A6FBE",
                                                           color: state.isFocused && "#FFF",
                                                           
                                                        })
                                                      }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <div className="d-flex justify-content-between mt-1">
                                                            <h6 className="mt-2">Add outlet (Min. 1 to start)*</h6>
                                                            <button className="btn btn-link" onClick={addOutlet}>+ Add new outlet</button>
                                            </div>
                                            <p>You are advised to name your outlet in this format: “Brand name - Location” e.g. CardsPal - Online, CardsPal - Plaza Singapura etc for better search exposure. You may add outlets later if you have many to add.</p>
                                                <AvField
                                                    name="outletName"
                                                    className="form-control m-0"
                                                    type="text"
                                                    placeholder="Outlet name (Brand name - Location)"
                                                    value={values.outletName}
                                                    onChange={handleInputChange}
                                                    required
                                                    errorMessage="This field is required"
                                                />
                                                <SetupOutlet
                                                    outlet={setupOutlet}
                                                    delete={(item)=> deleteOutlet(item)}
                                                />
                                            </Col>
                                            
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6} className="mt-2">
                                                <div>
                                                    <h6>Brand logo *</h6>
                                                    <p className="m-0">Image will be scaled and cropped to display 110 by 110 pixel</p>
                                                    <p>(You may upload larger images and use the cropping tool.)</p>

                                                </div>
                                                <ImageUploader
                                                    width={110}
                                                    height={110}
                                                    required={true}
                                                    label="Brand Logo"
                                                    onOk={(croppedImage) => getCroppedImage(croppedImage, 'logo')}
                                                />
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6} className='mt-2'>
                                                <h6 className='d-md-none'>Preview</h6>
                                                {(croppedImages.logo && croppedImages.logo.url) ?
                                                    <Image fluid src={croppedImages.logo ? croppedImages.logo.url : null} />
                                                : null}
                                                {/* <div className="mt-5">
                                                    <img style={{height: '75px', width: '75px', objectFit:'contain', border: '1px solid rgba(0, 0, 0, 0.09)'  }} src={previewLogo}/>
                                                </div> */}
                                                
                                            </Col>
                                        </Row>
                                        <Row className="mt-1" style={{paddingTop:"20px"}}>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <div>
                                                    <h6>Brand banner image (Optional)</h6>
                                                    <p className="m-0">Image will be scaled and cropped to display 375 by 266 pixel.</p>
                                                    <p>(You may upload larger images and use the cropping tool.)</p>

                                                </div>
                                                <ImageUploader
                                                    width={375}
                                                    height={266}
                                                    required={true}
                                                    label="Brand Banner"
                                                    onOk={(croppedImage) => getCroppedImage(croppedImage, 'banner')}
                                                />
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6} className='mt-2'>
                                                <h6 className='d-md-none'>Preview</h6>
                                                {(croppedImages.banner && croppedImages.banner.url) ?
                                                    <Image fluid src={croppedImages.banner ? croppedImages.banner.url : null} />
                                                : null}
                                                {/* <div style={{height:250}}>
                                                    <img style={{ maxHeight:'100%', maxWidth:'100%', objectFit:'cover'  }} src={previewBanner}/>
                                                </div> */}
                                                
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <div className="mt-4">
                                                    <h6>Brand summary (Optional)</h6>
                                                    <AvField
                                                        name="brandSummary"
                                                        label="Describe your business in a few short sentences"
                                                        className="form-control mb-0"
                                                        type="textarea"
                                                        validate= {{maxLength: {value: 250}}}
                                                        value={values.brandSummary}
                                                        onChange={handleInputChange}
                                                    />
                                                    <span className="border border-info bg-info rounded-2 px-1 text-center" style={{float:'right', marginTop: -10, color:'#FFF', fontSize: 11}}>{countChar}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <div className="mb-3 mt-1">
                                                    <AvField
                                                        name="brandWebsite"
                                                        label="Brand website (Optional)"
                                                        className="form-control mb-0"
                                                        type="text"
                                                        value={values.brandWebsite}
                                                        onChange={handleInputChange}
                                                    />
                                                    {!validUrl ? <p className="mt-1" style={{color:'red', fontSize: 11}}>Please make sure your link starts with http:// or https://</p> : <></>}
                                                </div>
                                            </Col>
                                        </Row>
                                    <Col className="d-flex align-items-end flex-column">
                                    {errorMsg ? <Alert style={{}} color="danger">{errorMsg}</Alert> : null}
                                        <div className="mt-3">
                                            {loading ? <Spinner size="sm" style={{marginRight: "10px"}}/> : null}
                                            
                                            <button disabled={loading} className="btn btn-primary w-md waves-effect waves-light" type="submit">Setup profile</button>
                                        </div>
                                    
                                    </Col>
                                </CardBody>
                            </Card>
                        </AvForm>
                    </Col>
                </Row>
            </Container>
        </div>

    </React.Fragment>
    )
}

export default SetupCompanyProfile