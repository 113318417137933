import React, { useEffect,useState } from "react"
import { Row, Col, Modal, ModalBody, Button, Container, Spinner } from "reactstrap"
import { Form } from "react-bootstrap"
import OppsPng from '../../../assets/images/Opps.png'
import styled from "styled-components"
import { SubmitButton, SubTitle,ErrMsg, SpinnerDiv, Caption } from "./Styled"

const Popup = (props) => {

    const [counter, setCounter] = useState(0);

    const openQRLink = (link) =>{
        window.open(link)
    }

    useEffect(() => {
        const timer =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    return (
        <Modal
            size='m'
            isOpen={props.open}
            centered={true}
            onOpened={props.onOpened}
        >
            <ModalBody className='justify-content-center'>
                

                { props.inputType  === 'reason' ?
                    <Container fluid>
                        <Row>
                            <Col>
                                <Row><SubTitle>{props.title}</SubTitle><hr/>
                                </Row>
                                <Row className ="mb-3">
                                    <Row className ="mb-3">
                                        <Col>
                                            <Form.Group>
                                            <Form.Check 
                                                id="reasonRadio1"
                                                type="radio"
                                                value="Exising Event"
                                                label="I have an existing event on the same day"
                                                className="mb-3"
                                                name="reason"
                                                {...props.register('reason')}
                                                />
                                            <Form.Check
                                                id="reasonRadio2"
                                                type="radio"
                                               
                                                value="Overseas"
                                                label="Overseas"
                                                className="mb-3"
                                                name="reason"
                                                {...props.register('reason')}
                                                />
                                            <Form.Check
                                                id="reasonRadio3"
                                                type="radio"
                                                
                                                value="Others"
                                                label="Others"
                                                className="mb-3"
                                                name="reason"
                                                {...props.register('reason')}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                        <Form.Control 
                                            as="textarea" 
                                            rows={3} 
                                            name={props.name}
                                            placeholder="Enter your reason for not attending"
                                            disabled = {props.isBusy || (props.watch("reason")!=="Others")} 
                                            { ...props.register("reasonText")}/>
                                        {props.error && 
                                            <ErrMsg>{props.error}</ErrMsg>
                                        }
                                    
                                </Row>
                                <Row  className="justify-content-end">     
                                    <Col style={{display: 'flex', justifyContent: 'right'}}>
                                        <Button onClick={()=>props.submit()} color="primary" className="mx-2">Submit reason</Button>
                                        <Button onClick={()=>props.cancel()} color="secondary" >Cancel</Button>
                                    </Col>
    
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                    : props.inputType  === 'message' ?
                    <Container fluid>
                        <Row>
                            <Col>
                                <Row><SubTitle>{props.title}</SubTitle><hr/>
                                </Row>
                                <Row className ="mb-3">
                                        <p>{props.message}</p>                                
                                </Row>
                                <Row  className="justify-content-end">     
                                    <Col style={{display: 'flex', justifyContent: 'right'}}>
                                        <Button style={{backgroundColor: '#7A6FBE !important', color: "#fff"}} onClick={()=>props.cancel()} >Close</Button>
                                    </Col>
    
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                    : props.inputType  === 'dnd-update-done' ?
                    <Container fluid>
                        <Row>
                            <Col>
                                <Row><SubTitle>{props.title}</SubTitle><hr/>
                                </Row>
                                <Row className ="mb-3">
                                        <p>{props.message}</p>                                
                                </Row>
                                <Row  className="justify-content-end">     
                                    <Col style={{display: 'flex', justifyContent: 'right'}}>
                                        <Button style={{backgroundColor: '#7A6FBE !important', color: "#fff"}} onClick={()=>props.cancel(props.caller)} >Close</Button>
                                    </Col>
    
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                    :  props.inputType  === 'paynow' ?
                    <Container fluid>
                        <Row>
                            <Col>
                                <Row><SubTitle>{props.title}</SubTitle><hr/>
                                </Row>
                                <Row className ="mb-3" style={{display: 'flex', justifyContent: 'center', textAlign:"center"}}>
                                        <SubTitle>{props.message}<Caption>Checking payment in {props.countdown}s<br/>or</Caption></SubTitle>                   
                                </Row>
                                <Row  className="justify-content-end mb-3">     
                                    <Col style={{display: 'flex', justifyContent: 'center'}}>
                                        <Button onClick={()=>props.manualCheck()} color="primary" >Click to check payment status manually</Button>
                                    </Col>
                                    {props.paynowQRLink &&
                                    <Col style={{display: 'flex', justifyContent: 'center'}}>
                                        <Button onClick={()=>openQRLink(props.paynowQRLink)} color="primary" >Regenerate PayNow QR</Button>
                                    </Col>
                                    }
                                </Row>
                                <Row style={{fontSize: 12, paddingTop: 10, display: 'flex', justifyContent: 'center', textAlign:"center"}}><p>Please disable popup blockers for this site or click <u><a href={props.paynowQRLink} target="_blank" rel="noreferrer">here</a></u> to redirect to the PayNow page now.</p></Row>
                            </Col>
                        </Row>
                    </Container>
                    : props.inputType  === 'confirmation' ?
                    <Container fluid>
                        <Row>
                            <Col>
                                <Row><SubTitle>{props.title}</SubTitle><hr/>
                                </Row>
                                <Row className ="mb-3">
                                        <p>{props.message}</p>                                
                                </Row>
                                <Row  className="justify-content-end">     
                                    <Col style={{display: 'flex', justifyContent: 'right'}}>
                                        <Button style={{marginRight: 10}} onClick={()=>props.proceed()} color="secondary" >Proceed</Button>
                                        <Button onClick={()=>props.cancel()} color="secondary" >Close</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                    : props.inputType  === 'dnd-cancel-confirmation' ?
                    <Container fluid>
                        <Row>
                            <Col>
                                <Row><SubTitle>{props.title}</SubTitle><hr/>
                                </Row>
                                <Row className ="mb-3">
                                    <p>{props.message}
                                    <br/>
                                    <span style={{color:'red'}}>{props.additionalText}</span>
                                    </p>                               
                                </Row>
                                <Row  className="justify-content-end">     
                                    <Col style={{display: 'flex', justifyContent: 'right'}}>
                                        <Button style={{marginRight: 10, backgroundColor: '#7A6FBE'}} onClick={()=>props.proceed()} >Proceed</Button>
                                        <Button onClick={()=>props.cancel()} >Close</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                    :null }
                    { props.isBusy && 
                        <SpinnerDiv>
                            <Spinner/>
                        </SpinnerDiv> }
            </ModalBody> 
            
        </Modal>
    )
}


export default Popup