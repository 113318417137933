import React, { useState, useEffect, useMemo } from 'react';
import { CardElement, useStripe, useElements, PaymentRequestButtonElement, CardCvcElement, CardNumberElement, CardExpiryElement } from '@stripe/react-stripe-js';
import {createPaymentIntent, getEnv, getTransactionById } from '../../../helpers/apiHelper'
import {
   SubmitButton, SpinnerDiv, ErrMsg
} from './Styled';
import {Spinner, Row, Col} from "reactstrap"
import Popup from './Popup'
import "../styles.css"



const GoogleApplePay = (props) => {
  const [loading, setLoading] = useState(false);
  const [paymentError, setPaymentError] = useState(null);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const createIntent = async (paymentMethodId) => {
    // this should be coming from the previous page where you received the reserved vouchers
    console.log('google-apple-pay props.data ', props.data)
    const intentPayload = {
      "env": getEnv(),
      "dataType": props.data.dataType || 'b2b',
      "qty": props.data.qty.toString(),
      "userId": props.userId,
      "dealId": props.data.dealId,
      "discountCampaignId": Number(props.data.discountCampaign?.qty) > 0 ?  props.data.discountCampaign?.campaignId || null : null,
      "purchasePriceTotal": props.data.paymentPrice,
      "eventsId": props.data.eventInfo?.pk || props.data.eventInfo?.data?.pk || null,
      "vouchers": props.vouchers || [],
      "webFormData": props.data.webForm ? {...props.data.webForm} : { "webFormId": props.webFormId, ...props.data.contactInfo },
      "discountCodes": props.data.discountCodeReserved || [],
      "transactionType": 'web_purchased',
      "paymentMethod": { 'id': paymentMethodId },
      "webPayment": "true",
      "utm_source": props.data.utm_source,
      "utm_medium": props.data.utm_medium,
      "utm_campaign": props.data.utm_campaign,
      "utm_term": props.data.utm_term,
      "utm_content": props.data.utm_content,
      "hasAccount": props.hasAccount,
      "others": {"idempotencyKey": props.idempotencyKey},
      "voucherGroup": props.voucherGroup,
    }
    const result = await createPaymentIntent(intentPayload)
    return result
  }

  const initListener = (request, paymentType) => {
    request.on('paymentmethod', async (event) => {
      // user click Pay button in the pay-sheet
      try {
        //('paymentmethod', paymentType)
        const response = await createIntent(paymentType);
        
        if (response.error) {
          setPaymentError(response.error)
          event.complete('fail');
          return
        } else {
          // we confirm the payment id created once we get the payment intent object only in the server
          const { paymentIntent, error } = await stripe.confirmCardPayment(response?.data.client_secret, {
            payment_method: event.paymentMethod.id
          });

          if (error) {
            console.error(error)
            setPaymentError(error.message);
            event.complete('fail');
          } else {
            setPaymentError(null);
            event.complete('success');
            props.goToPaymentSuccessful(paymentIntent?.id)
          }
        }
      } catch (error) {
        setPaymentError('There was an error processing your payment, please try again.');
        event.complete('fail');
      }
    })

    request.on('cancel', (event) => {
     // console.log('User cancel payment request')
      // can do some clean up here
    })
  }

  useEffect(() => {
    if (stripe) {
      // Configure the payment request for Google Pay
      const googlePaymentRequest = stripe.paymentRequest({
        country: 'SG',
        currency: 'sgd',
        total: {
          label: 'Wallet Payment (ActivEdge)',
          amount: parseFloat(props.data.paymentPrice) * 100, // replace with your desired amount
        }
      });

      // Check whether the user's device and browser support Google Pay
      googlePaymentRequest.canMakePayment().then((result) => {
        if (result) {
          props.getPaymentType(result)
          const paymentMethod = result.applePay === true ? 'applePay' : 'googlePay'
          setPaymentRequest(googlePaymentRequest);
          initListener(googlePaymentRequest, paymentMethod)
        }
      });
    }
  }, [stripe]);

  if (!stripe || !elements) {
    return null;
  }

  return (
    <>
        {paymentRequest && (props.paymentMethod === 'wallet') && <div style={{width:"75%", marginBottom:"2%"}}><PaymentRequestButtonElement style={{width:"75%"}}  options={{ paymentRequest }} /></div>}
        {paymentError && <div><ErrMsg>{paymentError}</ErrMsg></div>}

    </>
  );
};


export default GoogleApplePay

export const CardForm = (props) => {
  const useOptions = () => {
    const fontSize = '14px';
    const options = useMemo(
      () => ({
        style: {
          base: {
            fontSize,
            letterSpacing: "0.025em",
            fontFamily: "Rubik, Normal",
            backgroundColor: 'transparent',
            "::placeholder": {
              color: "#aab7c4",
            }
          },
          invalid: {
            color: "#9e2146"
          }
        }
      }),
      [fontSize]
    );
  
    return options;
  };

  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [loading, setLoading] = useState(false);
  const [paymentError, setPaymentError] = useState(null);
  const [paynowRequest, setPaynowRequest] = useState(null);
  const [counter, setCounter] = useState(-1)
  const [errorMessage, setErrorMessage] = useState("")
  const[paynowQRLink, setPaynowQRLink] = useState("")
  const [paymentId, setPaymentId] = useState("")
  const intervalTime = 10

  useEffect(() => {
    const timer =
        counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    if(!paynowRequest)
      setCounter(-1)
  }, [paynowRequest]);


  const createIntent = async (paymentMethodId) => {
    console.log('card-form props.data ', props.data)
    const intentPayload = {
      "env": getEnv(),
      "dataType": props.data.dataType || 'b2b',
      "qty": props.data.qty.toString(),
      "userId": props.userId,
      "dealId": props.data.dealId,
      "discountCampaignId": Number(props.data.discountCampaign?.qty) > 0 ?  props.data.discountCampaign?.campaignId || null : null,
      "purchasePriceTotal": props.data.paymentPrice,
      "eventsId": props.data.eventInfo?.pk || props.data.eventInfo?.data?.pk || null,
      "vouchers": props.vouchers || [],
      "webFormData": props.data.webForm ? {...props.data.webForm} : { "webFormId": props.webFormId, ...props.data.contactInfo },
      "discountCodes": props.data.discountCodeReserved || [],
      "transactionType": 'web_purchased',
      "paymentMethod": { 'id': paymentMethodId },
      "webPayment": "true",
      "utm_source": props.data.utm_source,
      "utm_medium": props.data.utm_medium,
      "utm_campaign": props.data.utm_campaign,
      "utm_term": props.data.utm_term,
      "utm_content": props.data.utm_content,
      "hasAccount": props.hasAccount,
      "others": {"idempotencyKey": props.idempotencyKey},
      "voucherGroup": props.voucherGroup,
    }
    const result = await createPaymentIntent(intentPayload)
    return result
  }

  useEffect(() => {
    if(props.paymentMethodClicked > 0) 
      submitPayment(props.paymentMethod)
  }, [props.paymentMethodClicked]);

  const submitPayment = async (type) => {
    setLoading(true);
    if (type === 'paynow') {
      props.setIsLoading(true)
      const response = await createIntent('paynow')
      setPaymentError(response.error? response.error : '')

      if(response.statusCode ===  200){
        setCounter(intervalTime)
        setPaynowRequest(true)
        setPaymentId(response.data.id)
        setPaynowQRLink(response?.data?.metadata?.payNowQrUrl ?? null)
        window.open(response?.data?.metadata?.payNowQrUrl, '_blank', 'noreferrer')
        
        const interval = setInterval(async() => {
          await checkPayment(response.data.id, interval)
        },intervalTime*1000);
      }
    } else if (type === 'card') {
      const response = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement)
      });
      
      if (response.error) {
        setPaymentError(response.error? response.error.message : null)
      } else {
        const createIntentResponse = await createIntent(response.paymentMethod.id)
        setPaymentError(createIntentResponse.error? createIntentResponse.error : null)
        if(createIntentResponse.statusCode === 200)
          props.goToPaymentSuccessful(createIntentResponse.data?.id)
        else
          props.setErrorOccur(true)
      }
    } else if (type === 'FREE'){
      props.setIsLoading(true)
      const createIntentResponse = await createIntent(type)
      setPaymentError(createIntentResponse.error? createIntentResponse.error : null)
      if(createIntentResponse.statusCode === 200)
        props.goToPaymentSuccessful(createIntentResponse.data?.id)
      else{
        props.setErrorOccur(true)
        props.setIsLoading(false)
      }
    }
    setLoading(false);
  };

  const checkPayment = async(id, interval) =>{
    const res = await getTransactionById(id)
          if(res.data?.status === '0'){
            clearInterval(interval)
            props.goToPaymentSuccessful(id)
            props.setIsLoading(false)
          }else if (res.data?.status === '5' || res.data?.status === '4'){
            clearInterval(interval)
            props.setErrorOccur(true)
            props.setIsLoading(false)
          }else if (res.data?.status === '3'){
            setCounter(intervalTime)
            setPaymentError('Payment not complete')
          }
  }

  return (
    <div style={{padding:20, display: (props.paymentMethod ==="FREE") ? "none" : "block",alignSelf:"center", justifyContent:"center", width:"100%"}}>
      <div >
      <Row className="mt-3" >
        <Col xs="12" >
        Card number
          <CardNumberElement id="card-element"
            options={options}
        />
        </Col>
      </Row>
      <Row>
        <Col className="mt-3" xs="12" sm="6">
        Expiration date
        <CardExpiryElement id="card-element"
          options={options}
        />
        </Col>
        <Col className="mt-3">
        CVC
        <CardCvcElement id="card-element"
          options={options}
        />
        </Col>
      </Row >
      <Row className="mt-3"><Col><ErrMsg>{paymentError}</ErrMsg></Col></Row>
      </div>
      
      {loading &&
        <SpinnerDiv>
            <Spinner />
        </SpinnerDiv>}
        <Popup
          open={paynowRequest}
          inputType={'paynow'}
          title="Waiting for payment"
          message="Please make your payment to complete the transaction."
          countdown={counter}
          cancel={() => setPaynowRequest(false)}
          manualCheck ={()=>checkPayment(paymentId)}
          errorMessage={paymentError}
          paynowQRLink={paynowQRLink}
      />
    </div>
  );
};

