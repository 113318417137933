import { put, takeLatest } from "redux-saga/effects"

// Login Redux States
import { SET_SELECTED_MERCHANT, SET_USER_MERCHANTS } from "./actionTypes"

import { userMerchants, selectedMerchant } from './actions'

function* _setUserMerchants({payload}) {
    try {
        yield put(userMerchants(payload))
    } catch (error) {
        console.error(error)
    }
}

function* _setSelectedMerchant({payload}) {
    try {
        yield put(selectedMerchant(payload))
    } catch(error){
        console.error(error)
    }
}

function* merchantSaga() {
    yield takeLatest(SET_SELECTED_MERCHANT, _setSelectedMerchant)
    yield takeLatest(SET_USER_MERCHANTS, _setUserMerchants)
}

export default merchantSaga
