
import { API, graphqlOperation } from 'aws-amplify'

// graphql
import {  deleteTags, createTags } from '../../graphql/mutations'

// helpers
// import { invalidateCloudFrontCache  } from '../../helpers/apiHelper'

import amplitude from 'amplitude-js';

export const updateTags = async(originalTags, tags, promo) => {
    try {
        if (originalTags) {
            for (const tag of originalTags) {
                await API.graphql(graphqlOperation(deleteTags, {
                    input:
                    {
                        id: promo.pk,
                        tagName: tag
                    }
                }))
            }
        }

        const newTags = []
        if (tags) {
            for (const tag of tags) {
                if (tag.trim() !== '') {
                    const result = await API.graphql(graphqlOperation(createTags, {
                        input: {
                            id: promo.pk,
                            tagName: tag,
                            merchantId: promo.merchant_id,
                            tagType: 'deal'
                        }
                    }))
                    newTags.push(result.data.createTags)
                    console.log('new tag created ', result.data.createTags)
                }
            }
        }
        
        return newTags
    } catch(error) {
        amplitude.getInstance().logEventWithGroups('Error - updateTags', { 'details': error })
        console.error('error updateTags ',error)
        return originalTags
    }
}

// export const update = async (options) => {
//     try{
//     // nullify indices with empty space values. ddb will throw error. cannot have empty values in indices
//     for (const index of this.indices) {
//         if (index in this.item && this.item[index] !== null && (this.item[index] === '' || this.item[index].toLowerCase() === 'null')) {
//             this.item[index] = null
//         }
//     }
//     const result = await API.graphql(graphqlOperation(updateDealMaster, { input: this.item }))
//     this.item = result.data.updateDealMaster

//     if (options && options.invalidateCloudFrontCache) {
//         options.invalidateCloudFrontCache.forEach((key) => {
//             invalidateCloudFrontCache(this.item[key])
//         })
//     }

//     return this.item
// } catch (e) {
//     amplitude.getInstance().logEventWithGroups('Error - updateDealMaster', { 'details': e})
//     console.error('error updateDealMaster ', e)
// }
// }