import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import { useSelector } from "react-redux"

import RBAC from '../../components/Common/rbac'

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { Link, withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  const selectedMerchant = useSelector(state => state.Merchant.selectedMerchant)
  const userRole = useSelector(state => state.User.user?.roles?.merchants)?.find(merchant => merchant?.merchantId === selectedMerchant?.merchant_id)
  console.log({userRole})
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    // const pathName = props.location.pathname

    // const initMenu = () => {
    //   new MetisMenu("#side-menu")
    //   let matchingMenuItem = null
    //   const ul = document.getElementById("side-menu")
    //   const items = ul.getElementsByTagName("a")
    //   for (let i = 0; i < items.length; ++i) {
    //     console.log('items', i, items[i])
    //     if (pathName === items[i].pathname) {
    //       matchingMenuItem = items[i]
    //       break
    //     }
    //   }
    //   console.log('user', user)
    //   console.log('matchingMenuItem', matchingMenuItem)
    //   if (matchingMenuItem) {
    //     activateParentDropdown(matchingMenuItem)
    //   }
    // }
    
    // if (user) initMenu() // do not initialise until user obj is obtained, if not, rbac will hide menu items before initialising

  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  // function activateParentDropdown(item) {
  //   item.classList.add("active")
  //   const parent = item.parentElement
  //   const parent2El = parent.childNodes[1]
  //   if (parent2El && parent2El.id !== "side-menu") {
  //     parent2El.classList.add("mm-show")
  //   }
  //   if (parent) {
  //     parent.classList.add("mm-active")
  //     const parent2 = parent.parentElement

  //     if (parent2) {
  //       parent2.classList.add("mm-show") // ul tag

  //       const parent3 = parent2.parentElement // li tag

  //       if (parent3) {
  //         parent3.classList.add("mm-active") // li
  //         parent3.childNodes[0].classList.add("mm-active") //a
  //         const parent4 = parent3.parentElement // ul
  //         if (parent4) {
  //           parent4.classList.add("mm-show") // ul
  //           parent4.classList.add("mm-active") // li
  //           parent4.childNodes[0].classList.add("mm-active") //a
  //           const parent5 = parent4.parentElement
  //           if (parent5) {
  //             parent5.classList.add("mm-show") // li
  //             parent5.classList.add("mm-active") // li
  //             parent5.childNodes[0].classList.add("mm-active") // a tag
  //           }
  //         }
  //       }
  //     }
  //     scrollElement(item);
  //     return false
  //   }
  //   scrollElement(item);
  //   return false
  // }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>

      {/* <ul className="metismenu" id="metismenu">
        <li className="mm-active">
          <a className="has-arrow" href="#" aria-expanded="true">Menu 1</a>
          <ul>
            <li>1a</li>
            <li>1b</li>
          </ul>
        </li>
        <li>
          <a className="has-arrow" href="#" aria-expanded="false">Menu 2</a>
          <ul>
            <li>2a</li>
            <li>2b</li>
          </ul>
        </li>
      </ul> */}

        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <RBAC merchantId={selectedMerchant.merchant_id} roles={['admin']}>
              <li className="menu-title">{props.t("Main")} </li>
              <li>
                <Link to="/dashboard" className={`waves-effect ${('/dashboard'===props.location.pathname)?'mm-active':''}`}>
                  <i className="mdi mdi-view-dashboard"></i>
                  <span className="indention">{props.t("Dashboard")}</span>
                </Link>
              </li>
            </RBAC>

            <li className="menu-title">{props.t("Tools")}</li>

            <RBAC merchantId={selectedMerchant.merchant_id} roles={['admin']}>
              <li>
                <Link to="" className="waves-effect has-arrow">
                  <i className="mdi mdi-store" />
                  <span className="indention">{props.t("Marketplace")}</span>
                </Link>

                <ul>
                  <li>
                    <Link to="" className="waves-effect has-arrow">
                      <span className="indention">{props.t("Deal Marketplace")}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/manage-outlets" className={`waves-effect ${('/manage-outlets'===props.location.pathname)?'mm-active':''}`}>
                          <span className="indention">{props.t("Manage outlets")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/manage-promotions" className={`waves-effect ${('/manage-promotions'===props.location.pathname)?'mm-active':''}`}>
                          <span className="indention">{props.t("Manage promotions")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/sell-vouchers" className={`waves-effect ${('/sell-vouchers'===props.location.pathname)?'mm-active':''}`}>
                          <span className="indention">{props.t("Sell shop vouchers")}</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  {userRole?.features?.includes("event") ?
                  <li>
                    <Link to="" className="waves-effect has-arrow">
                      <span className="indention">{props.t("Event listing")}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/events" className={`waves-effect ${('/events'===props.location.pathname)?'mm-active':''}`}>
                          <span className="indention">{props.t("Manage events")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/events/tickets" className={`waves-effect ${('/events/tickets'===props.location.pathname)?'mm-active':''}`}>
                          <span className="indention">{props.t("Sell event tickets")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/vouchers" className={`waves-effect ${('/vouchers'===props.location.pathname)?'mm-active':''}`}>
                          <span className="indention">{props.t("Sell event vouchers")}</span>
                        </Link>
                      </li>
                    </ul>
                    </li> : null}
                </ul>
              </li>
            </RBAC>

            <li>
              <Link to="" className="has-arrow">
                <i className="mdi mdi-qrcode" />
                <span className="indention">{props.t("Redemption")}</span>
              </Link>
              <ul id="redemption-menu" className="sub-menu">
                <li>
                  <Link to="/redeem" className={`waves-effect ${('/redeem'===props.location.pathname)?'mm-active':''}`}>
                    <span className="indention">{props.t("Scanner")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/redeem/history" className={`waves-effect ${('/redeem/history'===props.location.pathname)?'mm-active':''}`}>
                    <span className="indention">{props.t("Redemption History Log")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/redeem/list" className={`waves-effect ${('/redeem/list'===props.location.pathname)?'mm-active':''}`}>
                    <span className="indention">{props.t("Redemption List")}</span>
                  </Link>
                </li>
              </ul>
            </li>

            <RBAC merchantId={selectedMerchant.merchant_id} roles={['admin']}>
              {/* <li className="menu-title">{props.t("Sales")}</li>
              <li>
                <Link to="/sales-summary" className={`waves-effect ${('/sales-summary'===props.location.pathname)?'mm-active':''}`}>
                  <i className="mdi mdi-chart-pie" />
                  <span className="indention">{props.t("Summary")}</span>
                </Link>
              </li> */}

              <li className="menu-title">{props.t("Transactions")}</li>
              <li>
                <Link to="/transactions-summary" className={`waves-effect ${('/transactions-summary'===props.location.pathname)?'mm-active':''}`}>
                  <i className="mdi mdi-chart-pie" />
                  <span className="indention">{props.t("Summary")}</span>
                </Link>
              </li>
            </RBAC>

            <li className="menu-title">{props.t("Support")}</li>
            <li>
              <Link to="/user-guide" className={`waves-effect ${('/user-guide'===props.location.pathname)?'mm-active':''}`}>
                <i className="mdi mdi-book-open" />
                <span className="indention">{props.t("User Guide")}</span>
              </Link>
            </li>
            <li>
              <Link to="/faq" className={`waves-effect ${('/faq'===props.location.pathname)?'mm-active':''}`}>
                <i className="mdi mdi-comment-question" />
                <span className="indention">{props.t("FAQ")}</span>
              </Link>
            </li>
            <li>
              <Link to="/contact-us" className={`waves-effect ${('/contact-us'===props.location.pathname)?'mm-active':''}`}>
                <i className="mdi mdi-phone"/>
                <span className="indention">{props.t("Contact Us")}</span>
              </Link>
            </li>
            
            <li className="menu-title">{props.t("Settings")}</li>
            <li>
              <Link to="/manage-profile" className={`waves-effect ${('/manage-profile'===props.location.pathname)?'mm-active':''}`}>
                <i className="mdi mdi-account" />
                <span className="indention">{props.t("Manage Profile")}</span>
              </Link>
            </li>
            <li>
              <Link to="/manage-plans" className={`waves-effect ${('/manage-plans'===props.location.pathname)?'mm-active':''}`}>
                <i className="mdi mdi-view-carousel" />
                <span className="indention">{props.t("Manage Plans")}</span>
              </Link>
            </li>

          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
