import React, { useState, useEffect, useRef, Suspense } from 'react';
import { Image, Form } from 'react-bootstrap';
import { Row, Col, CardBody, Card, Alert, Container, Spinner, InputGroup } from "reactstrap"
import {
  SubTitle, Underline, CardContainer, ToolTipIcon, GroupP, 
  ErrMsg, Sup, SearchButton, Terms, DeclineButton, SpinnerDiv, P, boxStyling, TicketType, BtnOtp, BtnVerify
} from './Components/Styled';
import { createAppUser, getEnv, getWebformUserByPhone } from 'helpers/apiHelper';
import { parseQueryString, remoteConfig } from 'utils/'
import AccessDenied from 'components/Common/accessDenied';
import Select from 'react-select';
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import QRCode from 'qrcode';
import { Auth } from 'aws-amplify';
import { initAmplifyAppUser } from 'helpers/authHelper'

const GroupMembers = (props) => {

  
  const initialValues = {
    countryCode: '+65'
}
  const eventId = props.match.params?.eventId
  const queryStrings = parseQueryString(props.location.search)
  const webFormId = props.match.params?.webFormId
  const [custom, setCustom] = useState([])
  const imagePath = `https://assets.${getEnv()}.cardspal.com/public/Events/banner/`
  const faviconPath = `https://assets.${getEnv()}.cardspal.com/public/Events/favicon/`
  
  
  const [user, setUser] = useState(null)
  const [voucherInfo, setVoucherInfo] = useState(null)
  const [enableVerify, setEnableVerify] = useState(false)
  const [verified, setVerified] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const [session, setSession] = useState(null)
  const [isAccessDenied, setIsAccessDenied] = useState(null)
  const [state] = useState(initialValues);
  const [countryCode, setCountryCode] = useState([])
  const [qrCodeURI, setQRCodeURI] = useState(null)
  const [counter, setCounter] = useState(0)
  const [nonSG, setNonSG] = useState(false)
  
  let schema
      schema = Yup.object().shape({
          countryCode: Yup.string().required("Required"),
          phoneNumber: Yup.string().required("Required"),
      })

      const { 
        register, 
        unregister, 
        getValues, 
        setError, 
        setValue, 
        handleSubmit, 
        reset, 
        clearErrors, 
        control,
        formState: { 
            errors, 
            isValid 
        } 
} = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: state,
        resolver: yupResolver(schema)
    });

  const getCustomSettings = async () => {
    let cust = await remoteConfig("b2bCustom")
    if (cust._value) {
        let parseCustJson = JSON.parse(cust._value)
        setCustom(parseCustJson[eventId])
        return (parseCustJson[eventId])
    } else return null
}

useEffect(async () => {
  initAmplifyAppUser()
  let customData = await getCustomSettings()
  getCountry(customData)
  setFirstLoad(false)
},[])

useEffect(() => {
  const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
  return () => clearInterval(timer);
}, [counter]);

useEffect(async () => {
  if(voucherInfo){
    await generateQR({"voucherUser-masterId":voucherInfo?.masterId})
  }
},[voucherInfo])

useEffect (()=>{
  // window.addEventListener('resize', handleResize)

  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = `${faviconPath}${eventId}_favicon.ico`;

  // return _ => {
  //     window.removeEventListener('resize', handleResize)
  // }
},[])

// const handleResize = () => {
//   let adjustment = window.document.body.offsetHeight
//       setDimensions({
//           height: adjustment ,
//           width: window.innerWidth
//       })
//       window.parent.postMessage({cpHeight:adjustment}, '*')
// }

const getCountry = async (customData) => {
  // let rmCountry = await remoteConfig("allowedCountry")
  // let parseCountry = JSON.parse(rmCountry._value)
  // let countryList = parseCountry
  let countryList = [{dialingCode: '+65'}, {dialingCode: "+63"}]
  let _countryCode = countryList.map(item => {
      return {
          value: `${item.dialingCode}`,
          label: `${item.dialingCode}`
      }
  })
  setCountryCode(_countryCode)
}

const verify = () => {
  const values = getValues()
  const confirmationCode = values.otpVerify
  if (session && confirmationCode?.length > 1) {
      setIsLoading(true)
      Auth.sendCustomChallengeAnswer(session, confirmationCode)
          .then(() => {
              Auth.currentAuthenticatedUser().then(async (user) => {
                  let userId = user.attributes['custom:uuid'] || user.attributes.sub
                  setIsLoading(false)
                  clearErrors("otpVerify")
                  let res = await createAppUser({ userId: userId, phoneNumber: session.username })
                  if (res.statusCode === 'User already exist' || res.statusCode === 'success') {
                      setValue("userId", userId)
                      setCounter(0)
                      setVerified(true)
                  } else {
                      setError("otpVerify", { type: 'custom', message: "Something went wrong. Please try again later" })
                  }
              })
                  .catch((err) => {
                      console.log("err" + err)
                      setIsLoading(false)
                  });

          })
          .catch((err) => {
              if (err.code === "NotAuthorizedException") {
                  if (err.message === "User is disabled.") {
                      setError('otpVerify', { type: 'custom', message: "User is blocked. Please contact support" });
                  } else {
                      setError('otpVerify', { type: 'custom', message: "Please enter a valid OTP" });
                      SendOTP()
                  }
              }
              else {
                  setError("otpVerify", { type: 'custom', message: "Something went wrong. Please try again later" })
              }
              setIsLoading(false)
          });
  } else {
      setError("otpVerify", { type: 'custom', message: "Please enter a valid OTP" })
  }
}

const SendOTP = async () => {
  const values = getValues()
  if (values?.countryCode?.length > 1 && values?.phoneNumber?.length > 1) {
      setIsLoading(true)
      const username = values.countryCode + values.phoneNumber
      Auth.signIn(username)
          .then((result) => {
              clearErrors('phoneNumber');
              setCounter(60)
              setEnableVerify(true)
              setSession(result)
              setIsLoading(false)
          })
          .catch(async (err) => {
              if (err.code === 'UserNotFoundException') {
                  setError("phoneNumber", { type: "custom", message: "No user with this number found." })
                  setIsLoading(false)
              } else if (err.message === "User is disabled.") {
                  setError("phoneNumber", { type: "custom", message: "User is blocked. Please contact support" })
                  setIsLoading(false)
              } else {
                  setError("phoneNumber", { type: "custom", message: "We've encountered an error sending you the code. Please try again later." })
                  setIsLoading(false)
              }
          })
  } else {
      setError("phoneNumber", { type: "custom", message: "Please enter a valid phone number" })
  }
}

const generateQR = async text => {
  try {
    let res = await QRCode.toDataURL(JSON.stringify(text))
    setQRCodeURI(res)
  } catch (err) {
    console.error(err)
  }
}

const phoneNumberInput = () =>{
  return (
    <Card>
        <CardBody>
          <div className='mb-4'>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Label>Phone number*</Form.Label>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Row >
                    <Col className='mb-4' xs={12} sm={'auto'}>
                        <Controller
                            name={"countryCode"}
                            control={control}
                            render={({ field: { onChange, value, name, ref } }) => {
                                const handleSelectChange = (e) => {
                                    onChange(e?.value);
                                    setValue("countryCode", e?.value)
                                    if (e?.value !== "+65") {
                                        setNonSG(true)
                                    } else {
                                        setNonSG(false)
                                    }

                                };
                                return (
                                    <Select
                                        inputRef={ref}
                                        value={countryCode.find((e) => e.value === value)}
                                        options={countryCode}
                                        placeholder='Select...'
                                        isDisabled={verified || user}
                                        onChange={handleSelectChange}
                                        styles={{
                                            control: base => ({
                                                ...base,
                                                height: 34,
                                                minHeight: 34,
                                                width: 120,
                                            })
                                        }}
                                    />
                                )
                            }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name={"countryCode"}
                            render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                        />
                    </Col>
                    <Col className='mb-4'>
                        <InputGroup>
                            <Form.Control
                                type="number"
                                name={"phoneNumber"}
                                placeholder="Enter your phone number"
                                className={errors.phoneNumber && "error"}
                                disabled={verified  || user}
                                autoComplete='off'
                                {...register("phoneNumber")}
                            />
                            {nonSG ? null :
                              <BtnOtp style={{fontSize: (counter > 0) ? 10 : 12}} disabled={verified || (counter > 0)? true : false} onClick={() => SendOTP()}>{verified ? "OTP Sent" : counter > 0 ? "Resend in " + counter + "s" : "Send OTP"}</BtnOtp>
                            }
                            </InputGroup>

                          <ErrorMessage
                              errors={errors}
                              name={"phoneNumber"}
                              render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                          />
                      

                    </Col>
                </Row>
                {nonSG ? null :
                <Row className='mb-4'>
                  <Col>
                  <InputGroup>
                    <Form.Control
                    type="number"
                    name="otpVerify"
                    placeholder="Enter OTP"
                    className={errors.otpVerify && "error"}
                    autoComplete='off'
                    disabled={verified}
                    {...register("otpVerify")}
                    />
                      {enableVerify ? 
                          <BtnVerify verified={verified} onClick={() => verify()} disabled={verified} variant="outline-primary">
                              {verified ? "Verified" : "Verify"}
                          </BtnVerify>
                      : null}
                  </InputGroup>
                  <ErrorMessage
                  errors={errors}
                  name="otpVerify"
                  render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                  />
                  {!verified && !errors.otpVerify && !props.isDisabled &&
                      <ErrMsg>Please make sure to verify your mobile number.</ErrMsg>
                  }
                  </Col>
                </Row>
                }
                <Row>
                  <Col xs={12} >
                  {(verified || nonSG) &&
                    <SearchButton
                                type="submit"
                                className="btn btn-primary btn-block"
                                disabled={
                                    !isValid || (nonSG ? false : !verified) || user ||
                                    Object.keys(errors).length > 0
                                }
                            >Check your registration
                    </SearchButton>
                  }
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </CardBody>
    </Card>
);
}

const displayUserRecord = () => {
  return (
    <Card>
        <CardBody>
        <Row className='justify-content-center mb-4'>
          <Col >
          <p>Register User</p>
          </Col>
          <Col >
          <TicketType>{user?.firstName + " " + user?.lastName}</TicketType>
          </Col>
        </Row>
        <Row className='justify-content-center mb-4'>
          <Col >
          <p>Your table number is :</p>
          </Col>
          <Col >
          <TicketType>{user?.tableNumber ?? "TBC"}</TicketType>
          </Col>
        </Row>
        <Row className='justify-content-center mb-4'>
          <Col >
          <p>QR Code:</p>
          </Col>
          <Col className='justify-content-center'  align="center">
          {qrCodeURI &&
            <Image fluid style={{minWidth: "60%", objectFit:"cover"}} src={qrCodeURI} />
          }
          </Col>
        </Row>
        </CardBody>
      </Card>
  )
}

const processSubmit = async (e) => {
  if (!verified && !nonSG) return
  let phoneNumber = `${e.countryCode}${e.phoneNumber}`
  let res = await getWebformUserByPhone(phoneNumber, webFormId)
  if (res.data?.webformUser){
    setUser(res.data.webformUser)
    setVoucherInfo(res.data.voucherInfo)
  }else{
    setError("phoneNumber", { type: "custom", message: "No user with this number found." })
  }
}

const onChange = async (e) => {
  // console.log('---onChange', e)
}

const onBlur = async (e) => {
  // OnBlur action
}

const onError = (e) => {
  console.log('---error', e)
}

  return (
    <React.Fragment>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom:"5%"}}>
          <Image fluid src={imagePath + eventId + "_banner.jpg"} style={{objectFit: "fill", width: "100%"}}></Image>
        </div>
        {isAccessDenied ? <AccessDenied screen="B2B" showBackToDashboard={false} /> :
          firstLoad ? null :
          <Row className='justify-content-center' style={{maxWidth: 1080, margin: '0 auto'}}>
            <Form onSubmit={handleSubmit(processSubmit, onError)} onChange={(e) => onChange(e)} onBlur={(e) => onBlur(e)}>
              <Col>
                <CardContainer style={boxStyling} >
                    <CardBody>
                        <SubTitle> Input your phone number to check your registration.</SubTitle>
                        {phoneNumberInput()}
                        {user && displayUserRecord()}
                    </CardBody>
                </CardContainer>
              </Col>
              </Form>
          </Row>
        }
        {isLoading &&
                        <SpinnerDiv>
                            <Spinner />
                        </SpinnerDiv>}
    </React.Fragment>
  )
}


export default GroupMembers