import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { Button, Modal, CloseButton } from 'react-bootstrap';
import styled from 'styled-components';
import Icon from '../../../../assets/images/avatar/plan-avatar-redeem.png';
import IconColor from '../../../../assets/images/avatar/plan-avatar-redeem-color.png';
// custom components
import { createScanHistoryEntries, getScanHistoryByVoucherId, updateScanHistoryEntries } from '../../../../helpers/GraphQL/scanHistory';
import { remoteConfig } from 'utils';

const RedeemModal = (props) => {

  const result = props.data;
  const user = useSelector(state => state.User.user)
  const {dealMaster, checkinTicket, purchaseDate, transaction, voucherUser, webForm, scanView, redemptionView, eventInfo, totalSold, totalVouchers} = props.data;

  const [hasMissingData, setMissingData] = useState(false);
  const [errorMsg, setErrorMsg] = useState(''); 
  const [warningMessage, setWarningMessage] = useState('');
  const [redeemedOtherVouchers, setRedeemOtherVouchers] = useState([])
  const [notRedeemedOtherVouchers, setNotRedeemOtherVouchers] = useState([])
  const [custom, setCustom] = useState(null)
  const viewRender =[]
  
  useEffect(async ()=>{
    let cust = await remoteConfig("b2bCustom")
    if (cust._value) {
        let parseCustJson = JSON.parse(cust._value)
        setCustom(parseCustJson[eventInfo?.pk])
    } 
  },[eventInfo])
  
  useEffect(() => {
    if (dealMaster && purchaseDate && transaction && voucherUser && webForm) {
      setMissingData(false);
    } else {
      setMissingData(true);
      setWarningMessage('Connection issue result in some missing data.')
    }
  }, [dealMaster, checkinTicket, purchaseDate, transaction, voucherUser, webForm]);

  useEffect(()=>{
    if(notRedeemedOtherVouchers.length > 0)
      handleRedeemClose(true)
  }, [notRedeemedOtherVouchers])


  useEffect(()=>{
    if(redeemedOtherVouchers.length > 0)
      handleRedeemClose(true)
  }, [redeemedOtherVouchers])

  const handleRedeemClose = (status) => {
    //call handleClose function in parent
    props.close({ checkinTicket, dealMaster, purchaseDate, transaction, voucherUser, webForm, errorMsg, warningMessage, showResultModal: status, totalSold, redeemedOtherVouchers, notRedeemedOtherVouchers, scanView, redemptionView, scanData :props.scanData, eventInfo}) 
  }

  const handleSubmit = async (redeemAll) => {
    let redeemed = redeemedOtherVouchers

    if(redeemAll){         
      for (let i = 0; i < totalVouchers.length; i++){
        try {
          const scanHistory = await getScanHistoryByVoucherId(totalVouchers[i].masterId)
          
          if (scanHistory.length > 0) {
            const updateResult = await updateScanHistoryEntries(scanHistory[0], webForm, totalVouchers[i], dealMaster, props.scanData)
          } else {
            const createResult = await createScanHistoryEntries(webForm, totalVouchers[i], dealMaster, props.scanData, user.email)
          }
          if(totalVouchers[i].masterId !== voucherUser.masterId) redeemed.push(totalVouchers[i])

        } catch (e) {
          console.error(e)
          setErrorMsg('Error saving scanned data') //display error message in the RedeemLandingModal
        }
      }

      await Promise.all(redeemed);
      if (redeemed.length < 1) handleRedeemClose(true)
      setRedeemOtherVouchers([...redeemed])
    }
    else
      try {
        const scanHistory = await getScanHistoryByVoucherId(voucherUser.masterId)

        if (scanHistory.length > 0) {
          const updateResult = await updateScanHistoryEntries(scanHistory[0], webForm, voucherUser, dealMaster, props.scanData)
        } else {
          const createResult = await createScanHistoryEntries(webForm, voucherUser, dealMaster, props.scanData, user.email)
        }
        if (totalSold > 1) {
          let notRedeemed = totalVouchers?.filter(vu => vu.masterId !== voucherUser.masterId)
          if (notRedeemed.length < 1) handleRedeemClose(true)   
          setNotRedeemOtherVouchers([...notRedeemed])
          return
        }

        handleRedeemClose(true) //close modal -> pass data to parent -> open result modal
      } catch (e) {
        console.error(e)
        setErrorMsg('Error saving scanned data') //display error message in the RedeemLandingModal
      }
  }

  const getConfirmationId = (result) => {
    let id = result?.voucherUser?.masterId
    return id?.substring(id?.length - 8)
  }
  
  return (
    <>
      <Modal
        show={props.show}
        onHide={() => handleRedeemClose()} 
        centered
        scrollable={true}
      >         
        <div style={{overflow:"auto"}} >
        <ModalHeader>
          <Header>
            <H2>Scanned QR - #{getConfirmationId(result)}</H2>
            <CloseButton onClick={() => handleRedeemClose()} />
          </Header>
          <ModalTitle>{custom?.groupRedemption ? result?.totalSold + " x " : ""}{result?.dealMaster?.promotion_caption} - {result?.purchaseDate}</ModalTitle>
        </ModalHeader>

        <ModalBody>
          <RedeemContainer>
            <RedeemWrap>

              {
                custom?.dealInfo[dealMaster.pk]?.skuColorCode ?
                  
                  <RedeemIconRound src={IconColor} style={{backgroundColor: custom?.dealInfo[dealMaster.pk]?.skuColorCode ?? ""}}/>
                :
                  <RedeemIcon src={Icon} />
              }
              
              {((eventInfo && eventInfo.hasCheckIn ==="1") || !eventInfo) && (
                (result?.checkinTicket) ? 
                  <CheckinButton>Checked-in</CheckinButton>
                : <NotCheckinButton>Not Checked-in</NotCheckinButton>
              )}
            </RedeemWrap>
            {(scanView && (scanView.length > 0)) ?
            <RedeemTextWrap>
            {scanView.forEach((e,i) => {    //loop through the configs set up on remote config
                viewRender.push(                
                      (result && result.webForm && result.webForm[e.field]) ? 
                      <div key={"scan_"+i}>
                        <H3>{e.label}</H3>
                        <P>{  (result?.webForm[e.field] === '1' && e.type === 'Boolean') ? "Yes" 
                              :(result?.webForm[e.field] === '0'  && e.type === 'Boolean')? "No"
                              : (e.field === 'firstName')?  result?.webForm.firstName + " " + result?.webForm.lastName
                              : result?.webForm[e.field]
                            }
                        </P> 
                      </div>  
                      :
                      <div key={"scan_"+i}>
                        <H3>{e.label}</H3>
                        <P>-</P> 
                      </div>
                      )
            })
            }
            {viewRender}
            {(eventInfo && eventInfo.hasCheckIn ==="1") && (
                (result?.checkinTicket) ? 
                <>
                  <H3>Check-in Person:</H3>
                  <P>{result?.checkinTicket?.firstName} {result?.checkinTicket?.lastName}</P>
                </>
                : 
                <>
                  <H3>Check-in Person:</H3>
                  <P>N/A</P> 
                </>
                
            )}
            </RedeemTextWrap>
            :
            <RedeemTextWrap>  
                <H3>Bought by:</H3>
                <P>{result?.webForm?.firstName} {result?.webForm?.lastName}</P> 
                <H3>Check-in Person:</H3>
                {(result?.checkinTicket) ? 
                  <P>{result?.checkinTicket?.firstName} {result?.checkinTicket?.lastName}</P>
                : <P>N/A</P> 
                }
                <H3>Event Promo Code (if any):</H3>
                <P>{result?.webForm?.eventPromoCode}</P>
            </RedeemTextWrap>
            }
          </RedeemContainer>
        </ModalBody>
        
        { Number(result?.transaction?.quantity) > 1 && custom?.groupRedemption ? 
          <ModalFooter>
            <RedeemButton variant="outline-primary" onClick={() => handleSubmit(false)}>Redeem this ticket</RedeemButton>
            <RedeemButton variant="primary" onClick={() => handleSubmit(true)}>Redeem all tickets</RedeemButton>
          </ModalFooter>
        :
          <Modal.Footer>
            <Button variant="primary" onClick={()=>handleSubmit(false)}>Proceed to Redeem</Button>
            <Button variant="secondary" onClick={() => handleRedeemClose()}>Close</Button>
          </Modal.Footer>
        }
        {hasMissingData ? <Error>Connection issue result in some missing data.</Error> : null}
        </div>
      </Modal>
    </>
  );
}

const ModalHeader = styled(Modal.Header)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

const ModalFooter = styled(Modal.Footer)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

const ModalTitle = styled(Modal.Title)`
  font-size: 14px;
  color: #000;
  margin-bottom: 0.625rem;
`

const ModalBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

const RedeemContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const RedeemWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const RedeemTextWrap = styled.div`
  display: flex;
  flex-direction: column;
`

const CheckinButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 20px;
  width: 120px;
  height: 25px;
`

const RedeemButton = styled(Button)`
  width:45%
`

const NotCheckinButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #EC536C;
  border: none;
  border-radius: 20px;
  width: 130px;
  height: 25px;

  &:hover, &:focus {
    background: #EC536C; 
  }
`

const RedeemIcon = styled.img`
  display: block; 
  width: 6rem;
  height: 6rem;
  margin-bottom: 1rem;
`

const RedeemIconRound = styled.img`
  display: block; 
  width: 6rem;
  height: 6rem;
  border-radius:80px;
  margin-bottom: 1rem;
`

const H2 = styled.h2`
  font-size: 18px;
  color: #000;
  margin-bottom: 1rem;
`

const H3 = styled.h3`
  font-size: 16px;
  color: #000;
  margin: 0;
`

const P = styled.p`
  font-size: 14px;
  color: #000;
  margin-bottom: 1rem;
`
const Error = styled.div`
  color: red;
  font-size: 12px;
  align-text: center;
  align-self: center;
`

export default RedeemModal;