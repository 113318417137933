import React, { Component } from 'react'
import { Button, Form, Modal } from "react-bootstrap"
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { publish } from './cmsModal'
import Dropzone from 'react-dropzone'

const MAX_FILE_SIZE_MB = 5 // 5 MB
const MAX_FILE_SIZE_BYTES = 1024 * 1024 * MAX_FILE_SIZE_MB // 5 MB

class ImageUploader extends Component {

    constructor(props) {
        super(props)
        this.fileInput = React.createRef()

        this.state = {

        }
    }

    async componentDidMount() {
        this.setState({
            crop: {
                unit: 'px',
                x: 0,
                y: 0,
                width: this.props.width,
                aspect: this.props.width / this.props.height
            }
        })
    }

    async setCrop(crop) {
        this.setState({
            crop: crop
        })
    }

    handleFileChange(e) {
        console.log(e)
        try {
            this.setState({
                file: null,
                url: null,
                ext: null
            })

            const file = e.target.files[0]
            console.log(e)
            console.log(file)

            if (file.size > MAX_FILE_SIZE_BYTES) {
                this.alert(`Uploaded image is too large. Try to keep it below ${MAX_FILE_SIZE_MB}MB in filesize.`)
                e.target.value = null
            } else {
                const url = URL.createObjectURL(file)
                const ext = file.name.substring(file.name.lastIndexOf('.'))
                this.setState({
                    file: file,
                    url: url,
                    ext: ext
                })
            }
        } catch(error) {
            console.error(error)
            //
        }
    }

    handleDropFileChange(e) {
        try {
            this.setState({
                file: null,
                url: null,
                ext: null
            })

            const file = e[0]

            if (file.size > MAX_FILE_SIZE_BYTES) {
                this.alert(`Uploaded image is too large. Try to keep it below ${MAX_FILE_SIZE_MB}MB in filesize.`)
                e.target.value = null
            } else {
                const url = URL.createObjectURL(file)
                const ext = file.name.substring(file.name.lastIndexOf('.'))
                this.setState({
                    file: file,
                    url: url,
                    ext: ext
                })
            }
        } catch(error) {
            console.error(error)
            //
        }
    }

    alert(msg, callback) {
        publish({
            mode: 'alert',
            msg: msg,
            onClose: callback
        })
    }

    getCroppedImg(doNotCrop) {
        return new Promise((resolve) => {

            const crop = this.state.crop
            var reader = new FileReader()
            reader.onload = () => {
                const image = document.getElementsByClassName('ReactCrop__image')[0]
                const canvas = document.createElement('canvas')
                const scaleX = image.naturalWidth / image.width
                const scaleY = image.naturalHeight / image.height
                canvas.width =  this.props.width 
                canvas.height =  this.props.height 
                const ctx = canvas.getContext('2d')

                if (doNotCrop) {
                    ctx.drawImage(image, 0, 0)
                } else {
                    ctx.drawImage(
                        image,
                        crop.x * scaleX,
                        crop.y * scaleY,
                        crop.width * scaleX,
                        crop.height * scaleY,
                        0,
                        0,
                        this.props.width,
                        this.props.height,
                    );
                }

                // As Base64 string
                const base64Image = canvas.toDataURL('image/png')
                // As a blob
                canvas.toBlob(blob => {
                    // blob.name = fileName
                    resolve({
                        url: base64Image,
                        blob: blob,
                        ext: '.png'
                    })
                }, 'image/png', 1)
            }
            reader.readAsDataURL(this.state.file);
        })
    }

    async ok(doNotCrop) {
        if (this.props.onOk) {
            this.props.onOk(await this.getCroppedImg(doNotCrop))
        }

        this.fileInput.current.value = null
        this.setState({
            url: null
        })
    }

    async cancel() {
        this.fileInput.current.value = null
        this.setState({
            url: null
        })
    }

    render() {
        return <div>
            {this.props.dropType ?
                <Dropzone style={{height:"100px"}}
                    accept="image/*"
                    disabled={this.props.disableEditing}
                    multiple={false}
                    onDrop={(e) =>this.handleDropFileChange(e)}
                    ref={this.fileInput} 
                    required={this.props.required}
                >   
                
                {({ getRootProps, getInputProps }) => (
                    <div className="dropzone dz-clickable">
                    <div
                        className="dz-message needsclick"
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        <div className="mb-3">
                        <i className="mdi mdi-cloud-upload-outline text-muted display-4"></i>
                        </div>
                        <p style={{fontSize: "13px"}}>Drop image here or click to upload. File size limit 5MB</p>
                    </div>
                    </div>
                )}
                </Dropzone>
            :
                <Form.Control ref={this.fileInput} type="file" required={this.props.required} label={this.props.label} custom="true" accept="image/*" onChange={(e) => this.handleFileChange(e)}/>
            }
                  {/* <Form.File  required={this.props.required}  label={this.props.label} custom accept="image/*" onChange={(e) => this.handleFileChange(e)}></Form.File> */}
            {/* <Form.Text>Image cropped will be scaled and cropped to display {this.props.width} by {this.props.height} pixels</Form.Text><br/>
            <Form.Text>(You may upload larger images and use the cropping tool)</Form.Text> */}



            <Modal show={this.state.url}>
                <Modal.Body>
                    <ReactCrop id="reactCrop" src={this.state.url} locked={this.props.locked} minWidth={this.props.width} crop={this.state.crop} keepSelection={true} onChange={(crop) => this.setCrop(crop)} />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.ok(this.props.doNotCrop || false)}>Done</Button>
                    <Button variant="danger" onClick={() => this.cancel()}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </div>
    }
}

export default ImageUploader