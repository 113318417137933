import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import { connect, useSelector,  useDispatch } from "react-redux";
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardImg, CardText, Spinner, Button, Badge } from "reactstrap"
import LinesEllipsis from 'react-lines-ellipsis'

import RBAC from '../../../components/Common/rbac'

// import utils
import { formatDateTime, isDealExpiring, getOutletName, openInNewTab, RemoveFreeTrial } from '../../../utils'
import { FREE_PLAN } from '../../../utils/constants'

//import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems, setAlert, setAmplitudeEvent, setConfig, setEnv, selectedMerchant  } from "../../../store/actions";

// import graphql
import { listPromotionsByMerchantId } from "../../../helpers/GraphQL/promotions"
import { listOutletsByMerchantId } from '../../../helpers/GraphQL/outlet'
import { getConfig, getEnv } from '../../../helpers/apiHelper'

// import assets
import emptyState from '../../../assets/images/empty-state.png'

// import custom components
import ImageOverlay from './imageOverlay'
import DealForm from './dealForm'
import DealStatusForm from './dealStatusForm'

//import helpers

const ManagedPromotions = (props) => {
    const dispatch = useDispatch()
    const merchantId = props.selectedMerchant.merchant_id // TODO: get from store
    const user = useSelector(state => state.User.user)
    const cmsConfig = useSelector(state => state.Config)

    const data = {
        columns: [
            {
                label: "ID",
                field: "id",
                width: 50,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            },
            {
                label: "Edit",
                field: "edit",
                sort: 'disabled',
                width: 50,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            },
            {
                label: "Deal Image",
                field: "dealImage",
                width: 80,
            },
            {
                label: "Visible on app",
                field: "visible",
                width: 70,
                attributes: {
                    "className": "centerColumnText verticalAlignHeader"
                }
            },
            {
                label: "Promotion Title",
                field: "promoTitle",
                sort: "asc",
                width: 250,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            },
            {
                label: "Promotion Details",
                field: "promoDetails",
                sort: 'asc',
                width: 250,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            },
            {
                label: "T&Cs",
                field: "tc",
                width: 250,
                sort: 'disabled',
                attributes: {
                    "className": "verticalAlignHeader"
                }
            },
            {
                label: "Status",
                field: "status",
                sort: 'disabled',
                width: 100,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            },
            {
                label: "Outlet",
                field: "outlet",
                sort: 'asc',
                width: 200,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            },
            {
                label: "Start Date/Time",
                field: "sDate",
                sort: 'disabled',
                width: 120,
                attributes: {
                    "className": "verticalAlignHeader"
                }
                
            },
            {
                label: "End Date/Time",
                field: "eDate",
                sort: 'disabled',
                width: 120,
                attributes: {
                    "className": "verticalAlignHeader"
                }
                
            },
            {
                label: "Deal Website",
                field: "website",
                sort: 'disabled',
                width: 100,
                attributes: {
                    "className": "verticalAlignHeader"
                }
                
            }
        ],
        rows: []
    }

    const breadcrumbItems = [
        { title: "CardsPal", link: "#" },
        { title: props.selectedMerchant.display_merchant_name, link: "#" },
        { title: "Tools", link: "#" },
        { title: "Manage Promotions", link: "#" },
    ]
    const [promotion, setPromotion] = useState({})
    const [outlets, setOutlets] = useState(null)
    const [loadingStatus, setLoadingStatus] = useState(true)
    const [dataTable, setDataTable] = useState(data)
    const [formTitle, setFormTitle] = useState('Create Outlet')
    const [mode, setMode] = useState()
    const [selectedIndex, setSelectedIndex] = useState()
    const [modalFormStatus, setModalFormStatus] = useState(false)
    const [modalImageStatus, setModalImageStatus] = useState(false)
    const [modalDealStatus, setModalDealStatus] = useState(false)
    const [reload, setReload] = useState('')
    const [isNoPromotion, setIsNoPromotion] = useState(true)
    const [canAddPromotion, setCanAddPromotion] = useState(false)
    const [freeTrial, setFreeTrial] = useState(null)

    useEffect(() => {
        dispatch(setAlert(''))
        props.setBreadcrumbItems('Manage Promotions', breadcrumbItems)
    }, [merchantId])

    useEffect(async () => {
        const _outlets = await listOutletsByMerchantId(merchantId)
        setOutlets([..._outlets])
    }, [merchantId])


    useEffect(async () => {
        if (!user || !merchantId || Object.keys(user).length === 0) return 

        setLoadingStatus(true)
        data.rows = [] // clear the display items on the table first
        setDataTable(data)
        
        try {
            const result = await listPromotionsByMerchantId(merchantId, {onlyPurchasable: false})
            if (result && result.length > 0) setIsNoPromotion(false)
            
            let totalValidItem = 0
            if (result && result.length > 0) {
                const promotion = result.map((item, index) => {
                    
                    if (item.valid === 'Y') totalValidItem += 1
                    
                    let outletName = getOutletName(item, outlets)
                    const _item = {...item, outlet_name: outletName}
                    if (_item.promotion_caption) {
                        return {
                            id: index + 1,
                            edit: <a href="#" onClick={() => updatePromotion(_item, index)}><i className="mdi mdi-pencil" style={{ color: "#7a6fbe" }} /></a>,
                            dealImage: <a href="#" onClick={() => viewImg(_item)}>View</a>,
                            visible: getDealStatus(_item, index),
                            promoTitle: <LinesEllipsis text={_item.promotion_caption} maxLine={3} ellipsis="..." />,
                            promoDetails: <LinesEllipsis text={_item.promotion} maxLine={3} ellipsis="..." />,
                            tc: <LinesEllipsis text={_item.terms} maxLine={3} ellipsis="..." />,
                            status: decidePromoStatus(_item),
                            outlet: <span>{_item.outlet_name}</span>,
                            sDate: formatDateTime(_item.start_timestamp).toString(),
                            eDate: formatDateTime(_item.end_timestamp).toString(),
                            website: <a href="#" onClick={() => openInNewTab(_item.website)}><LinesEllipsis text={_item.website || "-"} maxLine={3} ellipsis="..." /></a>, 
                        }
                    }
                })

                // add restriction in adding promo...FREE should only have 1 active deal
                if ((!user.subPlan || user.subPlan === FREE_PLAN) && totalValidItem>1) setCanAddPromotion(false)
                else setCanAddPromotion(true)

                // set the data table values
                data.rows = promotion
                setDataTable(data)
            } else {
                setCanAddPromotion(true)
            }

        
            let _ampTrack = {
                'merchant id': props.selectedMerchant.merchant_id,
                'merchant name':  props.selectedMerchant.merchant_name,
                'mainCat': props.selectedMerchant.mainCat
            }
            let trackEmpty = {empty:result && result.length > 0? 'N' :'Y'}
            dispatch(setAmplitudeEvent('CMS Manage promotions page - page load', {..._ampTrack, ...trackEmpty}))
            
            setLoadingStatus(false)
        } catch(e) {
            dispatch(setAlert('Something went wrong. Please logout and login again.', 'danger'))
            dispatch(setAmplitudeEvent('CMS Manage promotions page - loading promotions ', {'details': JSON.stringify(e) }))
            setLoadingStatus(false)
        }
    }, [merchantId, outlets, reload, user])

    useEffect(async () => {
        await getCMSConfig()
    }, [])

    useEffect( async()=>{
        if(user !== null) {
          let removeFreeTrial = await RemoveFreeTrial(user)
          setFreeTrial(removeFreeTrial)

          if(removeFreeTrial === true) {
            dispatch(setAlert(<span className="m-0 p-0">You need to upgrade your plan to enjoy this entitlement. Click <a style={{fontWeight: 'bold', color: '#8E3241'}} href="/manage-plans">here</a> to upgrade your plan now.</span>, 'danger'))
          }
        }
        
      },[user])
    
    async function getCMSConfig() {
        try {
            
            if (!cmsConfig || !cmsConfig.cmsv2) {
                const config = await getConfig()
                const env = getEnv()
                
                dispatch(setConfig(config))
                dispatch(setEnv(env))
            }
        } catch(error) {
            console.error('error on retrieving config file', error)
            dispatch(setAmplitudeEvent('CMS Manage promotions page - getCMSConfig function error', {'details': JSON.stringify(error) }))
        }
    }

    function createPromotions() {
        if (outlets.length===0) {
            dispatch(setAlert(<span>You need to add at least 1 outlet first. Click <a href="/manage-outlets">here</a> to add outlet</span>, 'danger'))
            return
        }
        setPromotion({})
        setMode('create')
        setFormTitle('Add Promotion')
        setModalFormStatus(true)
    }

    function updatePromotion(item, index) {
        setPromotion(item)
        setSelectedIndex(index)
        setMode('update')
        setFormTitle('Update Promotion')
        setModalFormStatus(true)
    }

    function viewImg(item) {
        setPromotion(item)
        setModalImageStatus(true)
    }

    function decidePromoStatus(item) {
        if (!item.approval) {
            return 'Approved'
        } else if (item.approval && item.approval.isApproved) {
            if (item.valid === 'N') {
                return 'Inactive'
            } else if (item.end_timestamp && isDealExpiring(item.end_timestamp)) {
                return 'Expiring'
            } else  {
                return 'Active'
            }
        } else {
            return 'Pending approval'
        }
    }

    function getDealStatus(item) {
        if (item.valid === 'Y') {
            return (
                <i className="mdi mdi-eye" style={{ color: "#58DB83" }} />
            )
        } else {
            return (
                <i className="mdi mdi-eye-off" style={{ color: "#EC536C" }} />
            )
        }
    }

    function formatTags(tags) {
        if (tags) {
            return tags.map((tag, index) =>
                <span key={tag + index}><Badge key={tag + index} className="primary-bgcolor rounded bg-primary">{tag}</Badge>{" "}</span>
            )
        } else {
            return ["-"]
        }
    }

    function showForm(status, formType=null) {
        if (formType==='imageOverlay') {
            setModalImageStatus(status)
        } else if (formType === 'dealStatus'){
            setModalDealStatus(status)
        } else {
            setModalFormStatus(status)
        }
    }

    function closeForm(item, forceReload) {
        if (forceReload) {
            setReload(new Date())
            setModalFormStatus(false)
            return
        }

        if (item) {
            if (mode === 'create') {
                const totalRows = dataTable.rows.length
                dataTable.rows.push({
                    id: totalRows + 1,
                    edit: <a href="#" onClick={() => updatePromotion(item, selectedIndex)}><i className="mdi mdi-pencil" style={{ color: "#7a6fbe" }} /></a>,
                    dealImage: <a href="#" onClick={() => viewImg(item)}>View Image</a>,
                    visible: getDealStatus(item, selectedIndex),
                    promoTitle: item.promotion_caption,
                    promoDetails: <LinesEllipsis text={item.promotion} maxLine={3} ellipsis="..." />,
                    tc: <LinesEllipsis text={item.terms} maxLine={3} ellipsis="..." />,
                    status: decidePromoStatus(item),
                    outlet: <span>{item.outlet_name}</span>,
                    sDate: formatDateTime(item.start_timestamp).toString(),
                    eDate: formatDateTime(item.end_timestamp).toString(),
                    tags: formatTags(item.TR_tags).map(item => item),
                    website: <a href="#" onClick={() => openInNewTab(item.website)}>{item.website}</a> || "-"
                })
            } else {
                // we will try to update the current display with new data for better experienced without reloading
                dataTable.rows[selectedIndex] = {
                    id: selectedIndex + 1,
                    edit: <a href="#" onClick={() => updatePromotion(item, selectedIndex)}><i className="mdi mdi-pencil" style={{ color: "#7a6fbe" }} /></a>,
                    dealImage: <a href="#" onClick={() => viewImg(item)}>View Image</a>,
                    visible: getDealStatus(item, selectedIndex),
                    promoTitle: item.promotion_caption,
                    promoDetails: <LinesEllipsis text={item.promotion} maxLine={3} ellipsis="..." />,
                    tc: <LinesEllipsis text={item.terms} maxLine={3} ellipsis="..." />,
                    status: decidePromoStatus(item),
                    outlet: <span>{item.outlet_name}</span>,
                    sDate: formatDateTime(item.start_timestamp).toString(),
                    eDate: formatDateTime(item.end_timestamp).toString(),
                    tags: formatTags(item.TR_tags).map(item => item),
                    website: <a href="#" onClick={() => openInNewTab(item.website)}>{item.website}</a>|| "-"
                }
            }

            setDataTable(dataTable)
        }
        setModalFormStatus(false)
        setModalDealStatus(false)
    }

    return (
        <React.Fragment>
            <RBAC merchantId={merchantId} roles={['admin']}>
                <MetaTags>
                    <title>Manage Promotions</title>
                </MetaTags>

                <Row>
                    <Col className="col-12">
                        <Card>
                            {loadingStatus ?
                                    <CardBody className="text-center">
                                        <Spinner />
                                    </CardBody>
                                : dataTable.rows.length > 0 ?
                                    <CardBody>
                                        <div style={{ display: "flex", justifyContent: "flex-end", top: "21px", position: "relative" }} >
                                            <Button disabled={!canAddPromotion} color="primary" type="button" size="sm" onClick={() => createPromotions()}>Add promotion</Button>
                                        </div>
                                        <MDBDataTable className="promotions" entries={10} fixed={true} displayEntries={false} hover pagesAmount={10} scrollX data={{ columns: dataTable.columns, rows: dataTable.rows }} noBottomColumns={true} />
                                    </CardBody>
                                    :
                                        <CardBody className="text-center">
                                            <CardImg className="empty-state-img" src={emptyState} />
                                            <CardText className="empty-state-title">No promotions added</CardText>
                                            { freeTrial === true ?
                                                <div>
                                                    <CardText className="empty-state-subtext"><span>Upgrade your plan to start adding <br/> promotion now </span></CardText>
                                                    <Button onClick={()=>{props.history.push("/manage-plans")}} color="primary">Upgrade my plan now!</Button>
                                                </div>
                                            :
                                                <div>
                                                    <CardText className="empty-state-subtext">Start adding promotions now!</CardText>
                                                    <Button disabled={!canAddPromotion} onClick={() => createPromotions()} color="primary">Add promotions now</Button>
                                                </div>
                                            }
                                        </CardBody>}
                        </Card>
                    </Col>

                </Row>
                {/* {modalDealOutlets?<DealOutlets promotion={promotion} modalDealOutlets={modalDealOutlets} showForm={showForm} close={(item) => closeForm(item)}/>:null} */}
                {modalDealStatus?<DealStatusForm promotion={promotion} modalDealStatus={modalDealStatus} showForm={showForm} formTitle={formTitle} close={(item) => closeForm(item)} />:null}
                {modalImageStatus?<ImageOverlay promotion={promotion} modalImageStatus={modalImageStatus} showForm={showForm}/>:null}
                {modalFormStatus ? <DealForm promotion={promotion} showForm={showForm} close={(item, forceReload) => closeForm(item, forceReload)} modalFormStatus={modalFormStatus} formTitle={formTitle} mode={mode} isNoPromotion={isNoPromotion}/>:null}
            </RBAC>
        </React.Fragment>
    )


}

ManagedPromotions.propTypes = {
    user: PropTypes.any,
    selectedMerchant: PropTypes.any,
    userMerchants: PropTypes.any,
}

const mapStatetoProps = state => {
    const { user } = state.Login
    const { selectedMerchant, userMerchants } = state.Merchant
    return { user, selectedMerchant, userMerchants }
}

export default connect(mapStatetoProps, { setBreadcrumbItems })(ManagedPromotions);