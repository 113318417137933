import React, { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Image } from "react-bootstrap"
import { Row, Col, Modal, ModalFooter, ModalHeader, ModalBody, Button, Label, Alert, Spinner, InputGroup, InputGroupText } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { fromUnixTime, getUnixTime, addDays, format as dateFnsFormat, differenceInHours, isBefore, endOfDay } from 'date-fns'
import Dropzone from 'react-dropzone'

// import components
import ImageUploader from  '../../../../components/Common/imageUploader'
import VoucherCodeTable from "./voucherCodeTable";
import VoucherTitleSuggestion from "./voucherTitleSuggestion";
import CreateTerms from './createTerms'
import ListOutlets from './listOutlets'


// import images / files
import duplicateWarningIcon from '../../../../assets/images/duplicateWarning.png'
import thumbsup from '../../../../assets/images/thumbsup.png'
 

// import graphql functions
import  { getBrandInfo } from  '../../../../helpers/GraphQL/brands'
import { listOutletsByMerchantId, create as createOutlet, getVoucherOutlet } from '../../../../helpers/GraphQL/outlet'
import { create, update } from  '../../../../helpers/GraphQL/promotions'
import * as DealApproval from '../../../../helpers/GraphQL/dealApproval'
import { checkDuplicateVoucherCodes, createVoucher, listVouchersByDealId, deleteVouchersByDeal } from '../../../../helpers/GraphQL/voucherMaster'
import { getVoucherUserByCode } from "helpers/GraphQL/voucherUser";
import { getTermsByType } from 'helpers/GraphQL/termsMaster'

// import helpers
import { getConfig, sendEmail, getEnv } from 'helpers/apiHelper'

// import utils
import { getAssetUrl, validateVoucherCodes, guidGenerator, handleImageUpload, generatePromoCodes, convertToCSV, isValidHttpUrl, isValidURL } from '../../../../utils'
import { VOUCHER_CODE_TYPE, VOUCHER_CODE_TEMPLATE_FILE, MANDATORY_TERMS, MANDATORY_TERMS_SUBTITLE_SHOPFRONT, MANDATORY_TERMS_SUBTITLE_ONLINE } from "utils/constants";


// import store actions
import { setConfig, setEnv, setAmplitudeEvent } from '../../../../store/actions'


const SellVoucherForm = (props) => {
    // console.log('SellVoucherForm ',props.sellVoucher)
    const dispatch = useDispatch()
    const selectedMerchant = useSelector(state => state.Merchant.selectedMerchant)
    const cmsConfig = useSelector(state => state.Config)
    const user = useSelector(state => state.User.user)
    const [outlets, setOutlets] = useState([])
    const [processing, setProcessing] = useState(false)
    const [transactionMessage, setTransactionMessage] = useState(null)
    const [selectedStoredIds, setSelectedStoreIds] = useState([])
    const [isAllOutletChecked, setIsAllOutletChecked] = useState(false)
    const [croppedImages, setCroppedImages] = useState({})
    const [sellVoucher, setSellVoucher] =useState({})
    const [voucherMaster, setVoucherMaster] = useState({})
    const [uploadedVoucherCodesFile, setUploadedVoucherCodesFile] = useState(null)
    const [voucherCodes, setVoucherCodes] = useState([])
    const [codeType, setSetCodeType] = useState(VOUCHER_CODE_TYPE.UPLOADED)
    const [recommendedEndDateMin, setRecommendedEndDateMin] = useState('2022-03-28')
    const [recommendedRedemptionEndDateMin, setRecommendedRedemptionEndDateMin] = useState('2022-03-28')
    const [numberOfGeneratedVoucher, setNumberOfGeneratedVoucher] = useState(1)
    const [sellingEndDateError, setSellingEndDateError] = useState(null)
    const [redemptionEndDateError, setRedemptionEndDateError] = useState()
    const [duplicateVoucherCode, setDuplicateVoucherCode] = useState([])
    const [isGeneratingCode, setIsGeneratingCode] = useState(false)
    const [isVoucherCodesValid, setIsVoucherCodesValid] = useState(true)
    const quantityInputRef = useRef(null);
    const [invalidVoucherFileUploaded, setInvalidVoucherFileUploaded] = useState(false)
    const [validUrl, setValidUrl] = useState(true)
    const [mandatoryTermsSubTitle, setMandatoryTermsSubtitle] = useState('')
    const [allTerms, setAllTerms] = useState([])
    const [additionalInfo, setAdditionalInfo] = useState([])
    // const [combineTerms, setCombineTerms] = useState('')
    const [disableEditing, setDisableEditing] = useState(false)

    useEffect(() => {
      const ignoreScroll = (e) => {
        e.preventDefault();
      };
      quantityInputRef.current && quantityInputRef.current.addEventListener("wheel", ignoreScroll);
    }, [quantityInputRef]);
    
    useEffect(async () => {
        // console.log('props.sellVoucher ', props.sellVoucher)

        const disabled = props.mode==='update' && (props.sellVoucher.valid==='Y' || !props.sellVoucher.approval || props.sellVoucher.approval.isApproved)
        setDisableEditing(disabled)
        setSellVoucher({...props.sellVoucher})
        setSelectedStoreIds(props.sellVoucher.showInOutletIds || [])
        setRecommendedEndDateMin(dateFnsFormat(addDays(new Date(), 1), "yyyy-MM-dd 00:00:00"))
        setRecommendedRedemptionEndDateMin(dateFnsFormat(addDays(new Date(), 1), "yyyy-MM-dd 23:59:59"))

        if (props.mode==='create') {
            setVoucherMaster({
                startTimestamp: props.sellVoucher.start_timestamp
            })
            setMandatoryTermsSubtitle(MANDATORY_TERMS_SUBTITLE_SHOPFRONT)
        } else {
            try {
                const {codes, startTimestamp, endTimestamp, codeType} = await getVoucherMasterByDeal(props.sellVoucher.pk)
                setNumberOfGeneratedVoucher(codes.length)
                setVoucherCodes([...codes])
                setSetCodeType(codeType)
                setVoucherMaster({startTimestamp, endTimestamp})

                if (props.sellVoucher.is_online === 'True') {
                    setMandatoryTermsSubtitle(MANDATORY_TERMS_SUBTITLE_ONLINE)
                } else {
                    setMandatoryTermsSubtitle(MANDATORY_TERMS_SUBTITLE_SHOPFRONT)
                }
                
            } catch(e) {
                console.error('error on getVoucherMasterByDeal',e)
                setTransactionMessage({msg: 'Please report to the Administrator.[Voucher retrieval issue]', type: 'danger'})
                dispatch(setAmplitudeEvent('CMS Sell Vouchers page - Error Voucher retrieval issue', {'details': JSON.stringify(e)}))
            }
        }
    }, [props.sellVoucher])

    useEffect(async () => {
        await getCMSConfig()
    }, [])
    
    // get all the list of voucher terms for a given type(shopfront/online)
    useEffect(async () => {
        if (Object.keys(sellVoucher).length === 0) return
        
        try {
            const additionalTerms = await initVoucherTerms(sellVoucher.additionalPromotionInfo || [])
            const terms = await getTermsByType(sellVoucher.is_online==='True'?'online':'shopfront')
            const temp = terms.map(item => {
                let ON = false
                let termsText = item.termsText
                if (additionalTerms[item.id]) {
                    ON = true
                    termsText = additionalTerms[item.id].termsText
                }
                return {...item, ON, termsText, totalChars: item.order === '1' ? termsText.length : `${termsText.length}/250`}
            })
            setAllTerms(terms)
            setAdditionalInfo(temp) // we will use this as session of the form manipulation on the terms switch items
        } catch(e) {
            console.error('error getting voucher list', e)
            setTransactionMessage({msg: e, type: 'danger'})
            dispatch(setAmplitudeEvent('CMS Sell Vouchers page - error initializing voucher form', {'details': JSON.stringify(e)}))
        }
    }, [sellVoucher.is_online])

    function initVoucherTerms(terms) {
        const temp = {}
        terms.forEach(item => {
            temp[item.id] = {...item}
        })
        return temp
    }

    async function getCMSConfig() {
        try {
            
            if (!cmsConfig || !cmsConfig.cmsv2) {
                // console.log('no config so retrieving from source')
                const config = await getConfig()

                const env = getEnv()
                
                dispatch(setConfig(config))
                dispatch(setEnv(env))
            }
        } catch(error) {
            console.error('error on retrieving config file', error)
            dispatch(setAmplitudeEvent('CMS Sell Vouchers page - sell voucher form getCMSConfig function error', {'details': JSON.stringify(error)}))
        }
    }

    useEffect(async () => {
        const _outlets = await listOutletsByMerchantId(selectedMerchant.merchant_id)
        setOutlets(_outlets)
    }, [selectedMerchant])

    
    async function getVoucherMasterByDeal(dealId) {
        try{
            setIsGeneratingCode(true)
            const codes = await listVouchersByDealId(dealId)
            
            let temp = []
            let codeType
            let startTimestamp
            let endTimestamp

            for (let i=0; i < codes.length; i++) {
                const item = codes[i]
                const voucherUser = await getVoucherUserByCode(item.code)
            
                if (item) {
                    temp.push({
                        id: i+1,
                        code: item.code,
                        status: item.status==='0'?<span className="success">Active</span>:<span className="danger">Sold</span>,
                        redemption: voucherUser.items && voucherUser.items.length > 0 && voucherUser.items[0].status === '1'?
                            <span className="danger">Redeemed</span>:<span className="success">Unused</span>
                    })
                }
                codeType = item.codeType //one deal has only one voucher code type
                startTimestamp = item.startTimestamp
                endTimestamp = item.endTimestamp
            }
            setIsGeneratingCode(false)
            return {codes:temp, startTimestamp, endTimestamp, codeType}
        } catch(e) {
            console.error('getVoucherMasterByDeal', e)
            dispatch(setAmplitudeEvent('CMS Sell Vouchers page - sell voucher form getVoucherMasterByDeal function error', {'details': JSON.stringify(e)}))
        }
    }

    function handleChange(e, fieldName) {
        setTransactionMessage(null)

        let item = { ...sellVoucher }
        if (e.target.type === 'checkbox') {
            if (fieldName === 'valid') {
                item[fieldName] = e.target.checked ? 'Y' : 'N'
            } else {
                item[fieldName] = e.target.checked ? 'True' : 'False';
            }
        } else {
            let value = e.target.value
            item[fieldName] = value

            if (['actualPrice', 'purchasePrice', 'discountPrice'].includes(fieldName)) {
                item = autoComputeSellFields(item, fieldName)
            }

            if (fieldName === 'is_online') {
                if (value==='False') {
                    // item.terms = SELL_VOUCHERS_TERMS_TEMPLATE_SHOPFRONT.replace(MANDATORY_TERMS, '')
                    setMandatoryTermsSubtitle(MANDATORY_TERMS_SUBTITLE_SHOPFRONT)
                    // setCombineTerms(SELL_VOUCHERS_TERMS_TEMPLATE_SHOPFRONT + MANDATORY_TERMS)
                } else {
                    // item.terms = SELL_VOUCHERS_TERMS_TEMPLATE_ONLINE.replace(MANDATORY_TERMS, '')
                    setMandatoryTermsSubtitle(MANDATORY_TERMS_SUBTITLE_ONLINE)
                    // setCombineTerms(SELL_VOUCHERS_TERMS_TEMPLATE_ONLINE + MANDATORY_TERMS)
                }
            }

            if (fieldName === 'website'){
                let inputWebsite = e.target.value
                let isValidURLA = isValidURL(inputWebsite)
                let isValidURLB = isValidHttpUrl(inputWebsite)

                if (inputWebsite.length > 0){
                    if(isValidURLA && isValidURLB){ //2 checks to be sure
                        setValidUrl(true)
                        
                    }else{
                        setValidUrl(false)
                       
                    }
                }else {
                    setValidUrl(true)
                }
            }

            item[fieldName] = value
        }
       
        setTransactionMessage(null)
        setSellVoucher(item)
    }

    function autoComputeSellFields(item, fieldName) {
        try {
            if (fieldName === 'actualPrice') {
                item['purchasePrice'] = (item['actualPrice'] - item['discountPrice']).toFixed(2)
                item['discountPrice'] = (item['actualPrice'] - item['purchasePrice']).toFixed(2)
                
            } else if (fieldName === 'purchasePrice') {
                item['discountPrice'] = (item['actualPrice'] - item['purchasePrice']).toFixed(2)
            } else if (fieldName === 'discountPrice') {
                item['purchasePrice'] = (item['actualPrice'] - item['discountPrice']).toFixed(2)
            }
            item['discount'] = ((item['actualPrice'] - item['purchasePrice']) / item['actualPrice']).toFixed(2) 
        } catch(e) {
            // ignore
        } finally {
            // eslint-disable-next-line no-unsafe-finally
            return item
        }
            
    }

    function handleOutletChange(e, storeId) {
        // console.log('handleOutletChange ', e.target.checked)
        
        if (e.target.checked) {
            if (storeId === 'all') {
                setSelectedStoreIds(outlets.map(item => item.store_id))
                setIsAllOutletChecked(true)
            } else {
                console.log('add new store id', storeId)
                setSelectedStoreIds(selectedStoredIds.concat(storeId))
                setIsAllOutletChecked(false)
            }
        } else {
            if (storeId === 'all') {
                setSelectedStoreIds([])
                setIsAllOutletChecked(false)
            } else {
                const newStoreIds = selectedStoredIds.filter(id => id !== storeId)
                // console.log('',{newStoreIds})
                setSelectedStoreIds(newStoreIds)
                setIsAllOutletChecked(false)
            }
        }
    }

    function filterTurnOnTerms (terms) {
        let filteredTerms = []
        let bulletedTerms =''
        let promotionDetails = ''
        const newLine = '\n'

        Object.keys(terms).forEach((key) => {
            let item = terms[key]
            if (item.ON) {
                filteredTerms.push( {
                    id: item.id,
                    termsText: item.termsText,
                    iconUrl: item.iconUrl
                } )
                promotionDetails += `${item.termsText} `
                bulletedTerms += (bulletedTerms?newLine:'') + '● ' + item.termsText
            }
        })
        
        return { filteredTerms, bulletedTerms, promotionDetails }
    }

    async function handleSubmit(e) {
        e.preventDefault()
        e.stopPropagation()
        
        // get the updated merchant info from DB in case webhook is updating stripe and other info and the user has not logout yet causing the store to be an old-data
        const brand = await getBrandInfo(selectedMerchant.merchant_id)
        if (!brand.stripeExpressConnectedAccountId) {
            setTransactionMessage({ msg: `Cannot ${props.mode} voucher. Please report to the Admin to resolve your account`, type: 'danger'})
            return
        }
        const isPast = isBefore(fromUnixTime(sellVoucher.start_timestamp), new Date())
        const redemptionHrsDiff = differenceInHours(fromUnixTime(voucherMaster.endTimestamp), fromUnixTime(sellVoucher.end_timestamp))
        const sellingHrsDiff = differenceInHours(fromUnixTime(sellVoucher.end_timestamp), fromUnixTime(sellVoucher.start_timestamp))
        if ((!sellVoucher.image_url && !croppedImages.dealImage) || 
            (!sellVoucher.start_timestamp || !sellVoucher.end_timestamp || !voucherMaster.startTimestamp || !voucherMaster.endTimestamp) || voucherCodes.length===0) {
            setTransactionMessage({ msg: "Missing required fields. Please check fields mark with *", type: 'danger' })
            return
        } else if (redemptionHrsDiff < 24) {
            setTransactionMessage({ msg: "Redemption End Date & Time should be at least 24hrs from Selling End Date ", type: 'danger' })
            return
        } else if (selectedStoredIds.length === 0) {
            setTransactionMessage({ msg: "Please select an outlet for this voucher", type: 'danger' })
            return
        } else if (sellingHrsDiff < 24) {
            setTransactionMessage({ msg: "Selling End Date & Time should be at least 24hrs from Selling Start Date", type: 'danger' })
            return
        } else if (isPast && props.mode==='create') {
            setTransactionMessage({ msg: "Selling Start Date & Time should be future date/time", type: 'danger' })
            return
        }

        

        const {filteredTerms, bulletedTerms, promotionDetails} = filterTurnOnTerms(additionalInfo)
        if (filteredTerms.length === 0) {
            setTransactionMessage({ msg: "Please select at least 1 Voucher Additional Details / T&C", type: 'danger' })
            return
        }

        // massage the data to be saved
        let _sellVoucher = {...sellVoucher,  additionalPromotionInfo:  filteredTerms, promotion: promotionDetails, terms: `${bulletedTerms}\n${MANDATORY_TERMS}`}
        
        setTransactionMessage({msg: "Processing...", type:'info'})
        setProcessing(true)

        try {
            if (props.mode === 'create') {
                const createdSellVoucher = await handleCreate(_sellVoucher)
                await sendEmailToFirstVoucherOfBrand(createdSellVoucher)
            } else if (props.mode === 'update') {
                await handleUpdate(_sellVoucher)
            }
            close('forceReload')
        } catch (error) {
            console.error('error on handleSubmit ', error)
            setTransactionMessage({msg: 'Please report this error to Administrator.', type: 'danger'})
            setProcessing(false)
        }
    }

    async function sendEmailToFirstVoucherOfBrand(sellVoucher) {
        try {
            // we send when this is the first time when a sell voucher is created in a brand level
            if (props.isNoSellVoucher && cmsConfig.cmsv2 && cmsConfig.cmsv2.emailReceiver) {
                const body = `New voucher has been added on ${sellVoucher.createdAt}
                                Company name: ${user.corpName}
                                Brand name: ${sellVoucher.merchant_name}
                                Business rep: ${user.firstName} ${user.lastName}
                                Deal title: ${sellVoucher.promotion_caption}`
                
                await sendEmail({
                    to: [...cmsConfig.cmsv2.emailReceiver],
                    content: body,
                    subject: `First voucher added by ${sellVoucher.merchant_name}`
                })
            } else {
                console.error('No email sent. Either not first time voucher created or config was not retrieve')
            }
        } catch (e) {
            console.error('error on sendEmail', e)
            dispatch(setAmplitudeEvent('CMS Sell Vouchers page - sell voucher form sendEmailToFirstVoucherOfBrand function error', {'details': JSON.stringify(e) }))
        }
    }

    async function handleCreate(_sellVoucher) {
        // eslint-disable-next-line no-useless-catch
        try {
            let imagePath
            let voucherFilePath

            const _outlet = await checkVoucherOutlet(_sellVoucher)

            // massage the prices to correct decimal
            _sellVoucher.actualPrice = Number(_sellVoucher.actualPrice).toFixed(2)
            _sellVoucher.purchasePrice = Number(_sellVoucher.purchasePrice).toFixed(2)
            _sellVoucher.discountPrice = Number(_sellVoucher.discountPrice).toFixed(2)
            
            _sellVoucher.showInOutletIds = selectedStoredIds
            _sellVoucher.merchant_unique_id = _outlet.merchant_unique_id
            _sellVoucher.store_id = _outlet.store_id
            // sellVoucher.terms = combineTerms

            if (croppedImages.dealImage) {
                imagePath = `DealImages/${_sellVoucher.merchant_unique_id}/${sellVoucher.pk}${croppedImages.dealImage.ext}`
                _sellVoucher.image_url = await getAssetUrl(imagePath)
            }
            if (uploadedVoucherCodesFile) {
                voucherFilePath = `VoucherCodes/${sellVoucher.merchant_unique_id}/${sellVoucher.pk}.csv`
            }
            console.log('handleCreate ', )
            const createdSellVoucher = await createPromo(_sellVoucher)

            if (imagePath) await handleImageUpload(imagePath, croppedImages.dealImage)
            if (voucherFilePath) await handleImageUpload(voucherFilePath, {blob:uploadedVoucherCodesFile})
            
            // we create voucher codes in voucher master table
            await createVoucherMaster(createdSellVoucher)

            return createdSellVoucher
        } catch (e) {
            dispatch(setAmplitudeEvent('CMS Sell Vouchers page - sell voucher form handleCreate function error', {'details': JSON.stringify(e) }))
            throw e
        }
    }

    async function handleUpdate(_sellVoucher) {
        try {
            // const _sellVoucher = { ...sellVoucher, terms: combineTerms }
            
            const invalidation = []

            let imagePath
            let voucherFilePath
 
            if (croppedImages.dealImage) {
                imagePath = `DealImages/${_sellVoucher.merchant_unique_id}/${_sellVoucher.pk}${croppedImages.dealImage.ext}`
                _sellVoucher.image_url = await getAssetUrl(imagePath)
                invalidation.push('image_url')
            }
            if (uploadedVoucherCodesFile) {
                voucherFilePath = `VoucherUploadedFile/${sellVoucher.merchant_unique_id}/${sellVoucher.pk}.csv`
            }
            _sellVoucher.showInOutletIds = selectedStoredIds
            
            // delete custom fields before updating
            delete _sellVoucher.store_ids
            delete _sellVoucher.valid_store_ids
            delete _sellVoucher.deal_ids
            delete _sellVoucher.outlet_name
            
            const updatedSellVoucher = await update(_sellVoucher, { invalidateCloudFrontCache: invalidation })

            if (imagePath) await handleImageUpload(imagePath, croppedImages.dealImage) 
            if (voucherFilePath) await handleImageUpload(voucherFilePath, {blob:uploadedVoucherCodesFile})
            
            if (!disableEditing) {
                await deleteVouchersByDeal(updatedSellVoucher.pk) // we clear the old voucher code if and ONLY if the deal has not been approved yet for them to regenerate
                await createVoucherMaster(updatedSellVoucher)
            }

            return updatedSellVoucher
        } catch (error) {
            console.error('error handleUpdate ', error)
            dispatch(setAmplitudeEvent('CMS Sell Vouchers page - sell voucher form handleUpdate function error', {'details': JSON.stringify(error) }))
            throw error
        }
    }

    async function createPromo(_sellVoucher) {
        try{
            let createdDeal = await create(_sellVoucher)

            await DealApproval.create(createdDeal.pk)

            return createdDeal;
        } catch(error){
            console.error('error createDeal ', error)
            dispatch(setAmplitudeEvent('CMS Sell Vouchers page - sell voucher form createPromo function error', {'details': JSON.stringify(error) }))
            throw error
        }
    }

    async function createVoucherMaster(createdSellVoucher) {
        // eslint-disable-next-line no-useless-catch
        try {
            for (let i =0; i < voucherCodes.length; i++) {
                const data = {
                    code: voucherCodes[i].code,
                    codeType,
                    dealId: createdSellVoucher.pk,
                    startTimestamp: voucherMaster.startTimestamp,
                    endTimestamp: voucherMaster.endTimestamp,
                    valid: "1",
                    status: "0"
                }

                await createVoucher(data)
            }
        } catch(error) {
            dispatch(setAmplitudeEvent('CMS Sell Vouchers page - sell voucher form createVoucherMaster function error', {'details': JSON.stringify(error) }))
            throw error
        }
    }
    
    async function checkVoucherOutlet(_sellVoucher) {
        let _outlet
        
        if (props.mode === 'create') {
            _outlet = await getVoucherOutlet(_sellVoucher.merchant_id)
        }

        if (!_outlet || _outlet.items.length===0) {
            const store_id =  guidGenerator()
            const newVoucherOutlet = {
                store_id,
                merchant_id: _sellVoucher.merchant_id,
                merchant_unique_id: `${_sellVoucher.merchant_id}_${store_id}`,
                merchantImageUrlV2: selectedMerchant.merchantImageUrlV2,
                merchant_name: selectedMerchant.merchant_name,
                defaultImageUrl: selectedMerchant.defaultImageUrl,
                mainCat: selectedMerchant.mainCat,
                outlet_name: `${selectedMerchant.merchant_name}`,
                purchasableOutlet: "1",
                valid: 'Y'
            }
            _outlet = await createOutlet(newVoucherOutlet)
        } else {
            _outlet = _outlet.items[0]
        }

        return _outlet
    }

    function close(action) {
        // do clean up since this is a modal and it might show old message when open again
        setProcessing(false)
        setTransactionMessage(null)
        
        if (action==='forceReload')
            props.close(sellVoucher, false, 'sellVoucherForm')
        else 
            props.close(null, false)
    }

    async function getCroppedImage(cropped, fieldName) {
        let _croppedImages = croppedImages ?? {}
        _croppedImages[fieldName] = cropped

        setCroppedImages({ ..._croppedImages })
    }

    async function downloadVoucherCodes() {
        if(voucherCodes.length>0) {

            let arrCode = []
            let objCode = {}
            voucherCodes.map((e)=>{
                let id = e.id
                let code = e.code
                let status = e.status.props.children
                let redemption = e.redemption.props.children

                objCode = {'ID': id, 'Voucher Code':code, 'Status':status, 'Redemption': redemption}
                arrCode.push(objCode)
            })
            convertToCSV(arrCode, 'voucher-code-template') 
        }   
    }

    function handleUploadVoucherCode(files) {
        try {
            setIsGeneratingCode(true)
            setInvalidVoucherFileUploaded(false)
            setTransactionMessage(null)
            setVoucherCodes([])
            const reader = new FileReader();

            reader.onload = async function(e) {
                const contents = e.target.result;
                const codes = contents.split('\n')

                const filterCodes = validateVoucherCodes(codes)

                if (filterCodes.length === 0) {
                    // invalid codes uploaded
                    setIsVoucherCodesValid(false)
                    return
                }

                setIsVoucherCodesValid(true)
                const duplicate = await checkDuplicateVoucherCodes([...filterCodes])
                setDuplicateVoucherCode([...duplicate])

                if (duplicate.length === 0) {
                    const temp = []
                    
                    for (let i=0; i < filterCodes.length; i++){
                        const item = filterCodes[i]
                        if (item) {
                            temp.push({
                                id: i+1,
                                code: item.trim(),
                                status: <span className="success">Active</span>,
                                redemption: <span className="success">Unused</span>
                            })
                        }
                    }
                    setUploadedVoucherCodesFile(files[0]);
                    setVoucherCodes([...temp])
                }
                setIsGeneratingCode(false)
            };
            if (files.length===0) {
                setInvalidVoucherFileUploaded(true)
            } else {
                reader.readAsText(files[0])
            }
        } catch(e) {
            console.error('error on handleUploadVoucherCode')
            setIsGeneratingCode(false)
            setInvalidVoucherFileUploaded(true)
        }
    }

    async function generatePromoCode() {
        try {
            // reset error flags
            setDuplicateVoucherCode([])
            setIsVoucherCodesValid(true)
            setInvalidVoucherFileUploaded(false)
            setTransactionMessage(null)
            setIsGeneratingCode(true)
            setVoucherCodes([])

            const codes = await generatePromoCodes(numberOfGeneratedVoucher)
            const duplicate = await checkDuplicateVoucherCodes([...codes])
            
            setDuplicateVoucherCode([...duplicate])

            if (duplicate.length === 0) {
                const voucherCodes = codes.map((item, index) => {
                    return {
                        id: index + 1,
                        code: item,
                        status: <span className="success">Active</span>,
                        redemption: <span className="success">Unused</span>
                    }
                })
                setUploadedVoucherCodesFile(null)
                setVoucherCodes([...voucherCodes])
            }
            setIsGeneratingCode(false)
        } catch (error) {
            console.error('error on generatePromoCode ', error)
            setTransactionMessage({msg:'Error processing. Please report to the Administrator', type: 'danger'})
        }
    }

    async function setPurchaseLimitPerCustomer(value) {
        const newValue = parseInt(sellVoucher.purchaseLimitPerCustomer) + value
        if (isNaN(newValue)) return
        if (newValue < 1) return 
        if (newValue > 100) return

        sellVoucher.purchaseLimitPerCustomer = newValue
        setSellVoucher({...sellVoucher})
    }

    async function setVoucherGenerated(value) {
        const newValue = parseInt(numberOfGeneratedVoucher) + value
        if (isNaN(newValue)) return
        if (newValue < 1) return 
        if (newValue > 1000) return

        setNumberOfGeneratedVoucher(newValue)
    }

    async function validatePriceValue(value, ctx, input, cb) {
        if (!value) {
            cb('Field is required')
        } else if (value.startsWith('0') && !value.charAt(1) && value.charAt(1)!=='.'){
            // 02 should be treated as invalid; 0.00 and 0 is OK
            cb('Invalid entry')
        } else if (value.startsWith('-')) {
            cb('Negative value is not allowed')
        } else {
            cb(true)
        }
    }

    // trigger when the user toggle the switch of each term item
    const switchTermsState = (item, index) => {
        additionalInfo[index] = item
        setAdditionalInfo([...additionalInfo])
    }

    // trigger when the user change the value for the term input boxes
    const handleInputChange = (e, item, index) => {
        const { value } = e.target;
        const _item = {...item}

        _item.termsText = value
        _item.totalChars = item.order === '1'? value.length : `${value.length}/250`
        additionalInfo[index] = _item
        setAdditionalInfo([...additionalInfo])
    }

    return (
        <Modal
            size="xl"
            isOpen={props.modalSellVoucherFormStatus}
            toggle={() => {
                close(null,false)
            }}
            centered={true}
        >   
            <AvForm className="needs-validation" onValidSubmit={(e, v) => handleSubmit(e, v)}>
                <ModalHeader toggle={() => close(null, false)}>
                    {props.mode === 'create' ? 'Sell voucher' : `Edit voucher`}
                </ModalHeader>

                <ModalBody>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Label className="groupTitle">Voucher Details</Label>
                            <p htmlFor="promotionTitle" className="subTitle m-0 p-0">Type of voucher code*</p> 
                            <p>[NEW] Both options allow URL codes upload now.</p>      
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <div className="form-check">
                                        <input
                                            disabled={disableEditing}
                                            className="form-check-input"
                                            type="radio"
                                            name="voucherType"
                                            value="False"
                                            checked={sellVoucher.is_online==='False'}
                                            onChange={(e) => handleChange(e, 'is_online')}
                                        />
                                        <label className="form-check-label" htmlFor="shopFrontRedemption">
                                            <p>Shopfront Redemption</p>
                                            <p className="fontWeightLight" style={{fontSize:"11px", marginTop: "-15px"}}>Swipe to reveal code/URL</p>
                                        </label>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>                   
                                    <div className="form-check mb-3">
                                        <input
                                            disabled={disableEditing}
                                            className="form-check-input"
                                            type="radio"
                                            name="voucherType"
                                            value="True"
                                            checked={sellVoucher.is_online==='True'}
                                            onChange={(e) => handleChange(e, 'is_online')}
                                        />
                                        <label className="form-check-label" htmlFor="shopOnlineRedemption">
                                            <p>Online Redemption</p>
                                            <p className="fontWeightLight" style={{fontSize:"11px", marginTop: "-15px"}}>Copy code or open URL</p>
                                            </label>
                                    </div>
                                </Col> 
                            </Row>
                            <Label className="subTitle">Deal Image*</Label>
                            <p>
                                <span>Image will be scaled and cropped to display 375 by 266 pixel</span><br/>
                                <span>(You may upload larger images and use the cropping tool.)</span>
                            </p>
                            <ImageUploader
                                width={375}
                                height={266}
                                required={true}
                                label="Deal Image"
                                onOk={(croppedImage) => getCroppedImage(croppedImage, 'dealImage')}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Label className="groupTitle mt-3">Deal Image Preview</Label>
                            <div>
                                {(croppedImages.dealImage && croppedImages.dealImage.url) || sellVoucher.image_url ?
                                    <Image fluid src={croppedImages.dealImage ? croppedImages.dealImage.url : `${sellVoucher.image_url}?timestamp=${Date.now()}`} />
                                    : null}
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="mt-3" xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Label htmlFor="promotion_details" className="subTitle m-0">Voucher Title*</Label>
                            <p className="subTitleLight m-0">Indicate what you are selling e.g. type of food/beverage, type of services (time limit, pax per voucher). Here are some examples for you to make your deal more attractive.</p>
                            <div className='my-1'>
                                <VoucherTitleSuggestion onClick={(e)=> sellVoucher.promotion_caption = e}/>
                            </div>
                            <AvField
                                name="voucherTitle"
                                placeholder=""
                                type="text"
                                errorMessage="This field is required"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="voucherTitle"
                                value={sellVoucher.promotion_caption}
                                onChange={(e) => handleChange(e, 'promotion_caption')}
                            />
                        
                            <Label htmlFor="promotion_details" className="subTitle mt-3">Voucher Additional Details / T&Cs *</Label>
                            <Label>Select minimum 1 and update accordingly. You may add you own description or T&Cs by selecting the ‘free text’ option. Highly recommend those clauses with purple thumbsup icon. <Image fluid src={thumbsup} /></Label>
                
                            <CreateTerms handleInputChange={handleInputChange} additionalInfo={additionalInfo} allTerms={allTerms} switchTermsState={switchTermsState} />
                        
                            <Label htmlFor="terms" className="subTitle mt-3">Mandatory Terms and Conditions*</Label>
                            <Label htmlFor="terms" className="subTitleLight">{mandatoryTermsSubTitle}</Label>
                            <AvField
                                name="mandatoryTerms"
                                placeholder=""
                                type="textarea"
                                rows={8}
                                errorMessage="This field is required"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="mandatoryTerms"
                                value={MANDATORY_TERMS}
                                disabled
                            />
                            <Label htmlFor="terms" className="subTitle mt-3">Deal website</Label>
                            <AvField
                                name="website"
                                placeholder=""
                                type="text"
                                className="form-control mb-3"
                                id="website"
                                value={sellVoucher.website}
                                onChange={(e) => handleChange(e, 'website')}
                                errorMessage={validUrl.message}
                            />
                            {!validUrl ? <p className="mt-1" style={{color:'red', fontSize: 11}}>Please make sure your link starts with http:// or https://</p> : <></>}
                        
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                                    <Label className="subTitle">Selling Start Date & Time*</Label>
                                    <InputGroup>
                                        <Flatpickr
                                            disabled={disableEditing}
                                            className={disableEditing? "form-control d-block greyBg" :"form-control d-block whiteBg"}
                                            placeholder="Select a date"
                                            value={sellVoucher.start_timestamp ? fromUnixTime(sellVoucher.start_timestamp) : ""}
                                            options={{
                                                disableMobile: true,
                                                minDate: props.mode==='create'?"today":'' ,
                                                enableTime: true,
                                                enableSeconds: true,
                                                time_24hr: true,
                                                defaultHour: 0,
                                                defaultMinute: 0,
                                                defaultSeconds: 0,
                                                dateFormat: "Y-m-d H:i:S",
                                                onChange: function (selectedDates, dateStr, instance) {
                                                    try {
                                                        const dt = selectedDates[0]
                                                        sellVoucher['start_timestamp'] = getUnixTime(new Date(dt))
                                                        setSellVoucher({...sellVoucher})
                                                        
                                                        const tomorrow = dateFnsFormat(addDays(new Date(dt), 1), "yyyy-MM-dd 23:59:59");
                                                        // console.log('tomorrow ', tomorrow)
                                                        setRecommendedEndDateMin(tomorrow)
                                                        
                                                        // set redeem start date to be the same
                                                        voucherMaster.startTimestamp = getUnixTime(new Date(dt))
                                                        setVoucherMaster({ ...voucherMaster })
                                                    } catch (e) {
                                                        console.error(e)
                                                    }
                                                }
                                            }}
                                            
                                        />
                                        <span className="input-group-text mdi mdi-calendar"></span>
                                    </InputGroup>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className='selling-end-date'>
                                    <Label className="subTitle">Selling End Date & Time*</Label> <a href="#" onClick={ () => {sellVoucher.end_timestamp=getUnixTime(endOfDay(fromUnixTime(sellVoucher.end_timestamp))); setSellVoucher(sellVoucher)} }>Default to 23:59</a>
                                    <InputGroup>
                                        <Flatpickr
                                            disabled={disableEditing}
                                            className={disableEditing ? "form-control d-block greyBg" :"form-control d-block whiteBg"}
                                            placeholder="Select a date"
                                            value={sellVoucher.end_timestamp ? fromUnixTime(sellVoucher.end_timestamp) : ""}
                                            options={{
                                                minDate: props.mode==='create'?recommendedEndDateMin:"" ,
                                                enableTime: true,
                                                enableSeconds: true,
                                                time_24hr: true,
                                                defaultHour: 23,
                                                defaultMinute: 59,
                                                defaultSeconds: 59,
                                                dateFormat: "Y-m-d H:i:S",
                                                onChange: function (selectedDates, dateStr, instance) {
                                                    try {
                                                        const dt = selectedDates[0]
                                                        sellVoucher['end_timestamp'] = getUnixTime(new Date(dt))
                                                        setSellVoucher({ ...sellVoucher })

                                                        const redemptionEndDate = dateFnsFormat(addDays(new Date(dt), 1), "yyyy-MM-dd");
                                                        setRecommendedRedemptionEndDateMin(redemptionEndDate)

                                                        voucherMaster.endTimestamp = getUnixTime(addDays(new Date(dt), 1))
                                                        setVoucherMaster({ ...voucherMaster })

                                                        const hrsDiff = differenceInHours(fromUnixTime(sellVoucher.end_timestamp), fromUnixTime(sellVoucher.start_timestamp))
                                                        if (hrsDiff<24) {
                                                            setSellingEndDateError('Date has to be after selling start date.')
                                                        } else {
                                                            setSellingEndDateError('')
                                                        }
                                                    } catch(e) {
                                                        console.error(e)
                                                        dispatch(setAmplitudeEvent('CMS Sell Vouchers page - Selling End Date & Time error', {'details': JSON.stringify(e) }))
                                                    }
                                                }
                                            }}

                                        />
                                        <span className="input-group-text mdi mdi-calendar"></span>
                                    </InputGroup>
                                    <span className="subLabelWarning">{sellingEndDateError}</span>
                                </Col>
                                
                                <Col xs={12} sm={6} md={6} lg={12} xl={6} className='mt-3'>
                                    <Label className="subTitle">Actual Price*</Label>
                                    <InputGroup>
                                        <InputGroupText style={{ height:'100%' }}>                                
                                            S$
                                        </InputGroupText>
                                        <AvField
                                            ref={quantityInputRef}
                                            onWheel={(e) => e.target.blur()}
                                            min="0"
                                            id="actualPrice"
                                            disabled={disableEditing}
                                            name="actualPrice"
                                            placeholder=""
                                            type="number"
                                            errorMessage="This field is required"
                                            className="form-control"
                                            validate={{validatePriceValue}}
                                            value={sellVoucher.actualPrice}
                                            onChange={(e) => handleChange(e, 'actualPrice')}
                                            />
                                    </InputGroup>
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={12} xl={6} className='mt-3'>
                                    <Label className="subTitle">Discount Amount*</Label>
                                    <InputGroup>
                                        <InputGroupText style={{ height:'100%' }}>
                                        S$
                                        </InputGroupText>
                                        <AvField
                                            ref={quantityInputRef}
                                            onWheel={(e) => e.target.blur()}
                                            min="0"
                                            disabled={disableEditing}
                                            id="discountPrice"
                                            name="discountPrice"
                                            placeholder=""
                                            type="number"
                                            errorMessage="This field is required"
                                            className="form-control"
                                            validate={{validatePriceValue}}
                                            value={sellVoucher.discountPrice}
                                            onChange={(e) => handleChange(e, 'discountPrice')}
                                            />
                                    </InputGroup>
                                </Col>

                                <Col xs={12} sm={6} md={6} lg={12} xl={6} className='mt-3'>
                                    <Label className="subTitle">Selling Price*</Label>
                                    <InputGroup>
                                        <InputGroupText style={{ height:'100%' }}>
                                        S$
                                        </InputGroupText>
                                            <AvField
                                            ref={quantityInputRef}
                                            onWheel={(e) => e.target.blur()}
                                            min="0"
                                            disabled={disableEditing}
                                            id="purchasePrice"
                                            name="purchasePrice"
                                            placeholder=""
                                            type="number"
                                            errorMessage="This field is required"
                                            className="form-control"
                                            validate={{validatePriceValue}}
                                            value={sellVoucher.purchasePrice}
                                            onChange={(e) => handleChange(e, 'purchasePrice')}
                                            />
                                    </InputGroup>
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={12} xl={6} className='mt-3'>
                                    <Label className="subTitle">Discount Tag</Label>
                                    <InputGroup>
                                        <AvField
                                        disabled={disableEditing}
                                        id="discount"
                                        name="discount"
                                        placeholder=""
                                        type="text"
                                        readOnly
                                        className="form-control"
                                        value={(sellVoucher.discount * 100).toFixed(2)}
                                        onChange={(e) => handleChange(e, 'discount')}
                                        />
                                        <InputGroupText style={{ height:'100%' }}>
                                        %
                                        </InputGroupText>
                                    </InputGroup>
                                </Col>
                                <Col xs={8} sm={8} md={8} lg={8} xl={8} className='mt-3'>
                                    <Label className="subTitle">Purchase Limit per customer*</Label>
                                    <InputGroup style={{ maxWidth:'196px' }} >
                                        <Button type="button" color="primary" onClick={() => { setPurchaseLimitPerCustomer(- 1)}}>
                                        <i className="mdi mdi-minus" />
                                        </Button>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={sellVoucher.purchaseLimitPerCustomer}
                                            placeholder="1"
                                            onChange={(e) => {
                                                const v = e.target.value
                                                if (isNaN(v) || v.startsWith('0')) {
                                                    setSellVoucher({...sellVoucher})
                                                } else if (parseInt(v) < 1 || parseInt(v) > 100) {
                                                    setSellVoucher({...sellVoucher})
                                                } else {
                                                    setSellVoucher({...sellVoucher, purchaseLimitPerCustomer:v})}
                                                }
                                            }
                                        />
                                        <Button type="button" onClick={() => { setPurchaseLimitPerCustomer(1)}} color="primary" >
                                        <i className="mdi mdi-plus" />
                                        </Button>
                                    </InputGroup>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className='mt-3'>
                                    {outlets ? <ListOutlets disableEditing={disableEditing} isAllOutletChecked={isAllOutletChecked} selectedStoredIds={selectedStoredIds} outlets={outlets} voucher={sellVoucher} handleOutletChange={handleOutletChange} />: <span>loading outlet list...</span>}
                                </Col>

                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className='mt-3'>
                                    <Label className="subTitle">Redemption Start Date & Time*</Label>
                                    <InputGroup className='redemption-start-date'>
                                    <Flatpickr
                                        id="redemption_start_timestamp"
                                        className="form-control d-block greyBg"
                                        placeholder="Select a date"
                                        disabled={true}
                                        value={voucherMaster.startTimestamp ? fromUnixTime(voucherMaster.startTimestamp) : ""}
                                        options={{
                                            enableTime: true,
                                            enableSeconds: true,
                                            time_24hr: true,
                                            defaultHour: 0,
                                            defaultMinute: 0,
                                            defaultSeconds: 0,
                                            dateFormat: "Y-m-d H:i:S",
                                            onChange: function (selectedDates) {
                                                try {
                                                    const dt = selectedDates[0]
                                                    voucherMaster.startTimestamp = getUnixTime(new Date(dt))
                                                    setVoucherMaster({ ...voucherMaster })
                                                } catch (e) {
                                                    console.error(e)
                                                    dispatch(setAmplitudeEvent('CMS Sell Vouchers page - Redemption Start Date & Time error', {'details': JSON.stringify(e) }))
                                                }
                                            }
                                        }}
                                        
                                    />
                                    <span className="input-group-text mdi mdi-calendar"></span>
                                    </InputGroup>
                                    <div className="form-check mt-1">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={ true }
                                            disabled={ true }
                                        />
                                        <label className="form-check-label fontWeightLight">Same as selling start date</label>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className='selling-end-date mt-3'>
                                    <Label className="subTitle m-0">Redemption End Date & Time*</Label> <a href="#" onClick={ () => {voucherMaster.endTimestamp=getUnixTime(endOfDay(fromUnixTime(voucherMaster.endTimestamp))); setVoucherMaster(voucherMaster)} }>Default to 23:59</a>
                                    <p className='m-0'>Recommended 14 days from selling end date.</p>
                                    <InputGroup>
                                        <Flatpickr
                                            id="redemption_end_timestamp"
                                            className={disableEditing ? "form-control d-block greyBg" :"form-control d-block whiteBg"}
                                            placeholder="Select a date"
                                            disabled={disableEditing}
                                            value={voucherMaster.endTimestamp ? fromUnixTime(voucherMaster.endTimestamp) : ""}
                                            options={{
                                                minDate: props.mode==='create'?recommendedRedemptionEndDateMin : '',
                                                enableTime: true,
                                                enableSeconds: true,
                                                time_24hr: true,
                                                defaultHour: 23,
                                                defaultMinute: 59,
                                                defaultSeconds: 59,
                                                dateFormat: "Y-m-d H:i:S",
                                                onChange: function (selectedDates, dateStr, instance) {
                                                    try {
                                                        voucherMaster.endTimestamp = getUnixTime(new Date(dateStr))
                                                        const hrsDiff = differenceInHours(fromUnixTime(voucherMaster.endTimestamp), fromUnixTime(sellVoucher.end_timestamp))
                                                        if (hrsDiff<24) {
                                                            setRedemptionEndDateError('Date has to be at least 24hrs from Selling End Date')
                                                        } else {
                                                            setRedemptionEndDateError('')
                                                        }
                                                        setVoucherMaster({ ...voucherMaster })
                                                    } catch(e) {
                                                        console.error(e)
                                                        dispatch(setAmplitudeEvent('CMS Sell Vouchers page - Redemption End Date & Time error', {'details': JSON.stringify(e) }))
                                                    }
                                                }
                                            }}

                                        />
                                        <span className="input-group-text mdi mdi-calendar"></span>
                                    </InputGroup>
                                    <span className="subLabelWarning">{redemptionEndDateError}</span>
                                </Col>

                                <Row className='mt-3'>
                                    <Label htmlFor="promotionTitle" className="subTitle">Voucher code*</Label>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                                        
                                        <div className="form-check">
                                            <input
                                                disabled={disableEditing}
                                                className="form-check-input"
                                                type="radio"
                                                name="codeType"
                                                value={VOUCHER_CODE_TYPE.UPLOADED} // TODO :: confirm value
                                                checked={codeType === VOUCHER_CODE_TYPE.UPLOADED}
                                                onChange={(e) => {
                                                        setSetCodeType(e.target.value)
                                                        setDuplicateVoucherCode([])
                                                    }   
                                                }
                                            />
                                            <label className="form-check-label" htmlFor="codeType">
                                                <p>Upload Promo Codes/URL links </p>
                                            </label>
                                            </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                                        <div className="form-check mb-3">
                                            <input
                                                disabled={disableEditing}
                                                className="form-check-input"
                                                type="radio"
                                                name="codeType"
                                                value= {VOUCHER_CODE_TYPE.GENERATED} // TODO :: confirm value
                                                checked={codeType === VOUCHER_CODE_TYPE.GENERATED}
                                                onChange={(e) => {
                                                    setSetCodeType(e.target.value)
                                                    // setVoucherCodes([])
                                                    setDuplicateVoucherCode([])
                                                    }
                                                }
                                            />
                                            <label className="form-check-label" htmlFor="codeType">
                                                <p>CardsPal generate promo codes</p>
                                            </label>
                                        </div>
                                    </Col>
                                </Row>

                                <Col>
                                {codeType === VOUCHER_CODE_TYPE.UPLOADED ?
                                <div>
                                    <p>Upload .CSV file for the promo codes here. <a href={VOUCHER_CODE_TEMPLATE_FILE} download >[Download .CSV file template]</a></p>
                                    <Dropzone style={{height:"100px"}}
                                        accept=".csv, application/vnd.ms-excel, text/csv"
                                        disabled={disableEditing}
                                        multiple={false}
                                        onDrop={acceptedFiles => {
                                            handleUploadVoucherCode(acceptedFiles)
                                        }}
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <div className="dropzone dz-clickable">
                                            <div
                                                className="dz-message needsclick"
                                                {...getRootProps()}
                                            >
                                                <input {...getInputProps()} />
                                                <div className="mb-3">
                                                <i className="mdi mdi-cloud-upload-outline text-muted display-4"></i>
                                                </div>
                                                <p style={{fontSize: "13px"}}>Drop files here or click to upload. File size limit 5MB</p>
                                            </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                </div>
                                :
                                    <Row>
                                        <Col xs={6} sm={6} md={6} lg={6} xl={6} className='mb-1'>
                                            <InputGroup style={{ maxWidth:'196px' }} >
                                                <Button disabled={disableEditing} type="button" color="primary" onClick={() => { setVoucherGenerated(- 1)}}>
                                                <i className="mdi mdi-minus" />
                                                </Button>
                                                <input
                                                    disabled={disableEditing}
                                                    type="text"
                                                    className="form-control"
                                                    value={numberOfGeneratedVoucher}
                                                    placeholder="1"
                                                    onChange={(e) => {
                                                        const v = e.target.value
                                                        if (isNaN(v) || v.startsWith('0')) {
                                                            setNumberOfGeneratedVoucher(numberOfGeneratedVoucher)
                                                        } else if (parseInt(v) < 1 || parseInt(v) > 1000) {
                                                            setNumberOfGeneratedVoucher(numberOfGeneratedVoucher)
                                                        } else {
                                                            setNumberOfGeneratedVoucher(v)
                                                        }
                                                    }}
                                                />
                                                <Button disabled={disableEditing} type="button" onClick={() => { setVoucherGenerated(1)}} color="primary" >
                                                <i className="mdi mdi-plus" />
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                            <Button style={{maxWidth:'196px'}} disabled={disableEditing} onClick={() => generatePromoCode()} color="primary">Generate promo code</Button>
                                        </Col>
                                    </Row>
                                }
                                </Col>
                                    
                            </Row>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}/>
                    </Row>
                    
                    <Row className="rowMarginTop">
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Label htmlFor="promotionTitle" className="subTitle">Voucher {codeType === VOUCHER_CODE_TYPE.UPLOADED?'uploaded':'generated'} <a href="#" onClick={() => downloadVoucherCodes()}>[Download codes for reference]</a></Label>
                        <p>To update voucher codes, just upload new file or generate again before submitting the entry.
                            <br/><br/>
                            Note: The redemption statuses may subject to changes from users’ input on app, especially for online vouchers. Strongly suggest merchants to track the redemption statuses on their end.</p>
                        </Col>
                       
                    </Row>
                    <Row>
                        <Col>
                            {duplicateVoucherCode.length > 0 ?
                                <div className="duplicateWarning" >
                                    <img src={duplicateWarningIcon} />
                                    <p>
                                        {`Similar codes detected in our database for your brand: ${duplicateVoucherCode.join(',')}.
                                        Please check your file and upload again. If you still wish to upload these vouchers, please contact partnership@cardspal.com.`}
                                    </p>
                                </div>
                            : !isVoucherCodesValid ?
                                <div className="duplicateWarning" >
                                    <img src={duplicateWarningIcon} />
                                    <p>
                                        {`Voucher codes did not meet the minimum requirement or has duplicate or invalid format. 
                                        Please ensure your codes are in minimum 6-digit alphanumerical format before uploading again. 
                                        If you still wish to upload these vouchers, please contact partnership@cardspal.com.`}
                                    </p>
                                </div>
                            : invalidVoucherFileUploaded ?
                                <div className="duplicateWarning" >
                                    <img src={duplicateWarningIcon} />
                                    <p>
                                        {`Invalid file uploaded. Please ensure its a csv file in correct format. 
                                        If you still wish to upload these vouchers, please contact partnership@cardspal.com.`}
                                    </p>
                                </div>
                            :
                                <VoucherCodeTable isGeneratingCode={isGeneratingCode} voucherCodes={voucherCodes || []}/>
                            }
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                </ModalBody>

                <ModalFooter>
                    {transactionMessage ?
                        <Alert color={transactionMessage.type}>
                            {transactionMessage.msg}
                        </Alert>
                        : null}

                    {processing ?
                        <Spinner />
                        : null}

                    <Button
                        disabled={processing}
                        color="primary"
                        type="submit"
                    >
                        {props.mode==='create'? 'Add voucher' :'Update voucher'}
                    </Button>
                    {' '}
                    <Button disabled={processing} onClick={() => close(null,false)}>
                        Cancel
                    </Button>
                </ModalFooter>
            </AvForm>
        </Modal>
    )
}

export default SellVoucherForm;