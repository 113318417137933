import { API, graphqlOperation } from 'aws-amplify'
import { createCMSUser, updateCMSUser } from '../../graphql/mutations'
import { getCMSUser  } from '../../graphql/queries'
import amplitude from 'amplitude-js';

export const createUser = async(user) => {
    try {
        // remove the fields not defined in schema
        delete user.stripe
        
        const result = await API.graphql(graphqlOperation(createCMSUser, { input: user }))
        return result.data.createCMSUser
    } catch (e) {
        amplitude.getInstance().logEventWithGroups('Error - createUser', { 'details': e })
        console.error('Error on createUser', e)
        throw e ? e.message : 'Please contact administrator'
    }
}


export const updateUser = async(user) => {
    try {
        const item = {...user}
        
        delete item.stripe
      
        const result = await API.graphql(graphqlOperation(updateCMSUser, { input: item }))
        return result.data.updateCMSUser
    } catch (e) {
        amplitude.getInstance().logEventWithGroups('Error - updateUser', { 'details': e })
        console.error('Error on updateUser', e)
        throw e ? e.message : 'Please contact administrator'
    }
}

export const getUser = async (email) => {
    try {
        const result = await API.graphql(graphqlOperation(getCMSUser, { email }))
        return result.data.getCMSUser
    } catch (e) {
        amplitude.getInstance().logEventWithGroups('Error - getUser', { 'details': e })
        console.error('Error on getUser', e)
        throw e ? e.message : 'Please contact administrator'
    }
}

