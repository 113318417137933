/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTags = /* GraphQL */ `
  query GetTags($id: ID!, $tagName: String!) {
    getTags(id: $id, tagName: $tagName) {
      id
      merchantId
      tagName
      tagType
      highlights
      createdAt
      updatedAt
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $id: ID
    $tagName: ModelStringKeyConditionInput
    $filter: ModelTagsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTags(
      id: $id
      tagName: $tagName
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        merchantId
        tagName
        tagType
        highlights
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDealApproval = /* GraphQL */ `
  query GetDealApproval($pk: ID!) {
    getDealApproval(pk: $pk) {
      pk
      isApproved
      deal {
        address
        aggregator
        approval {
          pk
          isApproved
          createdYearMonth
          createdAt
          updatedAt
          createdByUser
          createdByGroups
        }
        area
        bank
        brand
        mainCat
        cc_buddy_category
        ccbuddy_card_name
        country
        deal_handler
        display_merchant_name
        district
        district_name
        end
        end_timestamp
        festive
        general_location
        geohashlong
        geohashshort
        google_id
        google_placeid
        google_type
        image_path
        image_url
        is_online
        isSponsored
        sponsoredRank
        latitude
        longitude
        merchant_id
        merchant_name
        merchant_name_lowercase
        merchant_unique_id
        online_company
        phone
        pk
        postal_code
        promo_code
        promotion
        promotion_analytic
        promotion_caption
        rank
        rank_updated
        score
        sector
        start
        start_timestamp
        store_id
        subcategory
        TR_tags
        tags {
          nextToken
        }
        terms
        usable_promocode
        valid
        website
        createdAt
        createdBy
        createdByUser
        updatedAt
        updatedBy
        isPinned
        pinPageLocation
        pinOrder
        purchasable
        actualPrice
        discount
        discountPrice
        purchasePrice
        purchaseLimitPerCustomer
        showInOutletIds
        additionalPromotionInfo {
          id
          termsText
          iconUrl
        }
      }
      createdYearMonth
      createdAt
      updatedAt
      createdByUser
      createdByGroups
    }
  }
`;
export const listDealApprovals = /* GraphQL */ `
  query ListDealApprovals(
    $pk: ID
    $filter: ModelDealApprovalFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDealApprovals(
      pk: $pk
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        isApproved
        deal {
          address
          aggregator
          area
          bank
          brand
          mainCat
          cc_buddy_category
          ccbuddy_card_name
          country
          deal_handler
          display_merchant_name
          district
          district_name
          end
          end_timestamp
          festive
          general_location
          geohashlong
          geohashshort
          google_id
          google_placeid
          google_type
          image_path
          image_url
          is_online
          isSponsored
          sponsoredRank
          latitude
          longitude
          merchant_id
          merchant_name
          merchant_name_lowercase
          merchant_unique_id
          online_company
          phone
          pk
          postal_code
          promo_code
          promotion
          promotion_analytic
          promotion_caption
          rank
          rank_updated
          score
          sector
          start
          start_timestamp
          store_id
          subcategory
          TR_tags
          terms
          usable_promocode
          valid
          website
          createdAt
          createdBy
          createdByUser
          updatedAt
          updatedBy
          isPinned
          pinPageLocation
          pinOrder
          purchasable
          actualPrice
          discount
          discountPrice
          purchasePrice
          purchaseLimitPerCustomer
          showInOutletIds
        }
        createdYearMonth
        createdAt
        updatedAt
        createdByUser
        createdByGroups
      }
      nextToken
    }
  }
`;
export const getDealMaster = /* GraphQL */ `
  query GetDealMaster($pk: ID!) {
    getDealMaster(pk: $pk) {
      address
      aggregator
      approval {
        pk
        isApproved
        deal {
          address
          aggregator
          area
          bank
          brand
          mainCat
          cc_buddy_category
          ccbuddy_card_name
          country
          deal_handler
          display_merchant_name
          district
          district_name
          end
          end_timestamp
          festive
          general_location
          geohashlong
          geohashshort
          google_id
          google_placeid
          google_type
          image_path
          image_url
          is_online
          isSponsored
          sponsoredRank
          latitude
          longitude
          merchant_id
          merchant_name
          merchant_name_lowercase
          merchant_unique_id
          online_company
          phone
          pk
          postal_code
          promo_code
          promotion
          promotion_analytic
          promotion_caption
          rank
          rank_updated
          score
          sector
          start
          start_timestamp
          store_id
          subcategory
          TR_tags
          terms
          usable_promocode
          valid
          website
          createdAt
          createdBy
          createdByUser
          updatedAt
          updatedBy
          isPinned
          pinPageLocation
          pinOrder
          purchasable
          actualPrice
          discount
          discountPrice
          purchasePrice
          purchaseLimitPerCustomer
          showInOutletIds
        }
        createdYearMonth
        createdAt
        updatedAt
        createdByUser
        createdByGroups
      }
      area
      bank
      brand
      mainCat
      cc_buddy_category
      ccbuddy_card_name
      country
      deal_handler
      display_merchant_name
      district
      district_name
      end
      end_timestamp
      festive
      general_location
      geohashlong
      geohashshort
      google_id
      google_placeid
      google_type
      image_path
      image_url
      is_online
      isSponsored
      sponsoredRank
      latitude
      longitude
      merchant_id
      merchant_name
      merchant_name_lowercase
      merchant_unique_id
      online_company
      phone
      pk
      postal_code
      promo_code
      promotion
      promotion_analytic
      promotion_caption
      rank
      rank_updated
      score
      sector
      start
      start_timestamp
      store_id
      subcategory
      TR_tags
      tags {
        items {
          id
          merchantId
          tagName
          tagType
          highlights
          createdAt
          updatedAt
        }
        nextToken
      }
      terms
      usable_promocode
      valid
      website
      createdAt
      createdBy
      createdByUser
      updatedAt
      updatedBy
      isPinned
      pinPageLocation
      pinOrder
      purchasable
      actualPrice
      discount
      discountPrice
      purchasePrice
      purchaseLimitPerCustomer
      showInOutletIds
      additionalPromotionInfo {
        id
        termsText
        iconUrl
      }
    }
  }
`;
export const listDealMasters = /* GraphQL */ `
  query ListDealMasters(
    $pk: ID
    $filter: ModelDealMasterFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDealMasters(
      pk: $pk
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        address
        aggregator
        approval {
          pk
          isApproved
          createdYearMonth
          createdAt
          updatedAt
          createdByUser
          createdByGroups
        }
        area
        bank
        brand
        mainCat
        cc_buddy_category
        ccbuddy_card_name
        country
        deal_handler
        display_merchant_name
        district
        district_name
        end
        end_timestamp
        festive
        general_location
        geohashlong
        geohashshort
        google_id
        google_placeid
        google_type
        image_path
        image_url
        is_online
        isSponsored
        sponsoredRank
        latitude
        longitude
        merchant_id
        merchant_name
        merchant_name_lowercase
        merchant_unique_id
        online_company
        phone
        pk
        postal_code
        promo_code
        promotion
        promotion_analytic
        promotion_caption
        rank
        rank_updated
        score
        sector
        start
        start_timestamp
        store_id
        subcategory
        TR_tags
        tags {
          nextToken
        }
        terms
        usable_promocode
        valid
        website
        createdAt
        createdBy
        createdByUser
        updatedAt
        updatedBy
        isPinned
        pinPageLocation
        pinOrder
        purchasable
        actualPrice
        discount
        discountPrice
        purchasePrice
        purchaseLimitPerCustomer
        showInOutletIds
        additionalPromotionInfo {
          id
          termsText
          iconUrl
        }
      }
      nextToken
    }
  }
`;
export const getMerchantOutlet = /* GraphQL */ `
  query GetMerchantOutlet($merchant_id: ID!, $store_id: ID!) {
    getMerchantOutlet(merchant_id: $merchant_id, store_id: $store_id) {
      address
      deals {
        items {
          address
          aggregator
          area
          bank
          brand
          mainCat
          cc_buddy_category
          ccbuddy_card_name
          country
          deal_handler
          display_merchant_name
          district
          district_name
          end
          end_timestamp
          festive
          general_location
          geohashlong
          geohashshort
          google_id
          google_placeid
          google_type
          image_path
          image_url
          is_online
          isSponsored
          sponsoredRank
          latitude
          longitude
          merchant_id
          merchant_name
          merchant_name_lowercase
          merchant_unique_id
          online_company
          phone
          pk
          postal_code
          promo_code
          promotion
          promotion_analytic
          promotion_caption
          rank
          rank_updated
          score
          sector
          start
          start_timestamp
          store_id
          subcategory
          TR_tags
          terms
          usable_promocode
          valid
          website
          createdAt
          createdBy
          createdByUser
          updatedAt
          updatedBy
          isPinned
          pinPageLocation
          pinOrder
          purchasable
          actualPrice
          discount
          discountPrice
          purchasePrice
          purchaseLimitPerCustomer
          showInOutletIds
        }
        nextToken
      }
      mainCat
      display_merchant_name
      distance
      general_location
      geohashlong
      geohashshort
      geohashsix
      google_id
      google_placeid
      google_type
      image_path
      image_src
      latitude
      longitude
      mallId
      merchant_id
      merchant_name
      merchant_name_lowercase
      merchant_namelist
      merchant_unique_id
      online_company
      outlet_name
      phone
      postal_code
      postal_district
      postal_district_name
      postal_sector
      protectedAttributes
      store_id
      subc_online
      subcategory
      valid
      merchantImageUrl
      merchantImageUrlV2
      defaultImageUrl
      businessSummary
      outletWebsite
      mcc
      createdAt
      updatedAt
      createdByUser
      createdByGroups
      purchasableOutlet
      blkAptNum
      roadName
      buildingName
      unitNum
      country
    }
  }
`;
export const listMerchantOutlets = /* GraphQL */ `
  query ListMerchantOutlets(
    $merchant_id: ID
    $store_id: ModelIDKeyConditionInput
    $filter: ModelMerchantOutletFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMerchantOutlets(
      merchant_id: $merchant_id
      store_id: $store_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        address
        deals {
          nextToken
        }
        mainCat
        display_merchant_name
        distance
        general_location
        geohashlong
        geohashshort
        geohashsix
        google_id
        google_placeid
        google_type
        image_path
        image_src
        latitude
        longitude
        mallId
        merchant_id
        merchant_name
        merchant_name_lowercase
        merchant_namelist
        merchant_unique_id
        online_company
        outlet_name
        phone
        postal_code
        postal_district
        postal_district_name
        postal_sector
        protectedAttributes
        store_id
        subc_online
        subcategory
        valid
        merchantImageUrl
        merchantImageUrlV2
        defaultImageUrl
        businessSummary
        outletWebsite
        mcc
        createdAt
        updatedAt
        createdByUser
        createdByGroups
        purchasableOutlet
        blkAptNum
        roadName
        buildingName
        unitNum
        country
      }
      nextToken
    }
  }
`;
export const getMerchant = /* GraphQL */ `
  query GetMerchant($merchant_id: ID!) {
    getMerchant(merchant_id: $merchant_id) {
      mainCat
      outlets {
        items {
          address
          mainCat
          display_merchant_name
          distance
          general_location
          geohashlong
          geohashshort
          geohashsix
          google_id
          google_placeid
          google_type
          image_path
          image_src
          latitude
          longitude
          mallId
          merchant_id
          merchant_name
          merchant_name_lowercase
          merchant_namelist
          merchant_unique_id
          online_company
          outlet_name
          phone
          postal_code
          postal_district
          postal_district_name
          postal_sector
          protectedAttributes
          store_id
          subc_online
          subcategory
          valid
          merchantImageUrl
          merchantImageUrlV2
          defaultImageUrl
          businessSummary
          outletWebsite
          mcc
          createdAt
          updatedAt
          createdByUser
          createdByGroups
          purchasableOutlet
          blkAptNum
          roadName
          buildingName
          unitNum
          country
        }
        nextToken
      }
      display_merchant_name
      google_type
      merchant_id
      merchant_name
      merchant_name_lowercase
      online_company
      valid
      acceptCC
      merchantImageUrl
      merchantImageUrlV2
      defaultImageUrl
      pockCalMerchImage
      createdAt
      updatedAt
      createdByUser
      createdByGroups
      website
      summary
      stripeExpressConnectedAccountId
      cmsPlan
    }
  }
`;
export const listMerchants = /* GraphQL */ `
  query ListMerchants(
    $merchant_id: ID
    $filter: ModelMerchantFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMerchants(
      merchant_id: $merchant_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        mainCat
        outlets {
          nextToken
        }
        display_merchant_name
        google_type
        merchant_id
        merchant_name
        merchant_name_lowercase
        online_company
        valid
        acceptCC
        merchantImageUrl
        merchantImageUrlV2
        defaultImageUrl
        pockCalMerchImage
        createdAt
        updatedAt
        createdByUser
        createdByGroups
        website
        summary
        stripeExpressConnectedAccountId
        cmsPlan
      }
      nextToken
    }
  }
`;
export const getCMSUser = /* GraphQL */ `
  query GetCMSUser($email: ID!) {
    getCMSUser(email: $email) {
      email
      firstName
      lastName
      mobile
      roles
      marketingConsent
      agreeToTerms
      subPlan
      createdAt
      updatedAt
      profileImage
      corpName
      uen
      verified
      stripe {
        items {
          accountId
          email
          merchantId
          type
          journeyStep
          status
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listCMSUsers = /* GraphQL */ `
  query ListCMSUsers(
    $email: ID
    $filter: ModelCMSUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCMSUsers(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        firstName
        lastName
        mobile
        roles
        marketingConsent
        agreeToTerms
        subPlan
        createdAt
        updatedAt
        profileImage
        corpName
        uen
        verified
        stripe {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCMSUserStripeAccount = /* GraphQL */ `
  query GetCMSUserStripeAccount($accountId: ID!) {
    getCMSUserStripeAccount(accountId: $accountId) {
      accountId
      email
      merchantId
      type
      journeyStep
      status
      createdAt
      updatedAt
    }
  }
`;
export const listCMSUserStripeAccounts = /* GraphQL */ `
  query ListCMSUserStripeAccounts(
    $accountId: ID
    $filter: ModelCMSUserStripeAccountFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCMSUserStripeAccounts(
      accountId: $accountId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        accountId
        email
        merchantId
        type
        journeyStep
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVoucherMaster = /* GraphQL */ `
  query GetVoucherMaster($id: ID!) {
    getVoucherMaster(id: $id) {
      id
      dealId
      code
      codeType
      startTimestamp
      endTimestamp
      userId
      status
      valid
      transactionId
      retrievalTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const listVoucherMasters = /* GraphQL */ `
  query ListVoucherMasters(
    $id: ID
    $filter: ModelVoucherMasterFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVoucherMasters(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        dealId
        code
        codeType
        startTimestamp
        endTimestamp
        userId
        status
        valid
        transactionId
        retrievalTimestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVoucherUser = /* GraphQL */ `
  query GetVoucherUser($masterId: String!, $code: String!) {
    getVoucherUser(masterId: $masterId, code: $code) {
      masterId
      dealId
      userId
      code
      startTimestamp
      endTimestamp
      status
      transactionId
      redeemTimestamp
      createdAt
      updatedAt
      updatedBy
    }
  }
`;
export const listVoucherUsers = /* GraphQL */ `
  query ListVoucherUsers(
    $masterId: String
    $code: ModelStringKeyConditionInput
    $filter: ModelVoucherUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVoucherUsers(
      masterId: $masterId
      code: $code
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        masterId
        dealId
        userId
        code
        startTimestamp
        endTimestamp
        status
        transactionId
        redeemTimestamp
        createdAt
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      userId
      dealId
      quantity
      actualPrice
      discount
      discountPrice
      purchasePrice
      purchasePriceTotal
      timestamp
      paymentMethod
      status
      discountCode
      extraDiscount
      deal {
        address
        aggregator
        approval {
          pk
          isApproved
          createdYearMonth
          createdAt
          updatedAt
          createdByUser
          createdByGroups
        }
        area
        bank
        brand
        mainCat
        cc_buddy_category
        ccbuddy_card_name
        country
        deal_handler
        display_merchant_name
        district
        district_name
        end
        end_timestamp
        festive
        general_location
        geohashlong
        geohashshort
        google_id
        google_placeid
        google_type
        image_path
        image_url
        is_online
        isSponsored
        sponsoredRank
        latitude
        longitude
        merchant_id
        merchant_name
        merchant_name_lowercase
        merchant_unique_id
        online_company
        phone
        pk
        postal_code
        promo_code
        promotion
        promotion_analytic
        promotion_caption
        rank
        rank_updated
        score
        sector
        start
        start_timestamp
        store_id
        subcategory
        TR_tags
        tags {
          nextToken
        }
        terms
        usable_promocode
        valid
        website
        createdAt
        createdBy
        createdByUser
        updatedAt
        updatedBy
        isPinned
        pinPageLocation
        pinOrder
        purchasable
        actualPrice
        discount
        discountPrice
        purchasePrice
        purchaseLimitPerCustomer
        showInOutletIds
        additionalPromotionInfo {
          id
          termsText
          iconUrl
        }
      }
      user {
        userId
        emailId
        marketingConsent
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      utm_campaign
      utm_content
      utm_medium
      utm_source
      utm_term
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        dealId
        quantity
        actualPrice
        discount
        discountPrice
        purchasePrice
        purchasePriceTotal
        timestamp
        paymentMethod
        status
        discountCode
        extraDiscount
        deal {
          address
          aggregator
          area
          bank
          brand
          mainCat
          cc_buddy_category
          ccbuddy_card_name
          country
          deal_handler
          display_merchant_name
          district
          district_name
          end
          end_timestamp
          festive
          general_location
          geohashlong
          geohashshort
          google_id
          google_placeid
          google_type
          image_path
          image_url
          is_online
          isSponsored
          sponsoredRank
          latitude
          longitude
          merchant_id
          merchant_name
          merchant_name_lowercase
          merchant_unique_id
          online_company
          phone
          pk
          postal_code
          promo_code
          promotion
          promotion_analytic
          promotion_caption
          rank
          rank_updated
          score
          sector
          start
          start_timestamp
          store_id
          subcategory
          TR_tags
          terms
          usable_promocode
          valid
          website
          createdAt
          createdBy
          createdByUser
          updatedAt
          updatedBy
          isPinned
          pinPageLocation
          pinOrder
          purchasable
          actualPrice
          discount
          discountPrice
          purchasePrice
          purchaseLimitPerCustomer
          showInOutletIds
        }
        user {
          userId
          emailId
          marketingConsent
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        utm_campaign
        utm_content
        utm_medium
        utm_source
        utm_term
      }
      nextToken
    }
  }
`;
export const getTermsMaster = /* GraphQL */ `
  query GetTermsMaster($id: ID!) {
    getTermsMaster(id: $id) {
      id
      type
      iconUrl
      description
      recommended
      termsText
      placeholderText
      order
      createdAt
      updatedAt
    }
  }
`;
export const listTermsMasters = /* GraphQL */ `
  query ListTermsMasters(
    $filter: ModelTermsMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTermsMasters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        iconUrl
        description
        recommended
        termsText
        placeholderText
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCheckinTicket = /* GraphQL */ `
  query GetCheckinTicket($masterId: String!) {
    getCheckinTicket(masterId: $masterId) {
      masterId
      dealId
      firstName
      lastName
      email
      terms
      marketingConsent
      createdAt
      updatedAt
    }
  }
`;
export const listCheckinTickets = /* GraphQL */ `
  query ListCheckinTickets(
    $masterId: String
    $filter: ModelCheckinTicketFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCheckinTickets(
      masterId: $masterId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        masterId
        dealId
        firstName
        lastName
        email
        terms
        marketingConsent
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWebForm = /* GraphQL */ `
  query GetWebForm($id: String!) {
    getWebForm(id: $id) {
      id
      firstName
      lastName
      email
      eventPromoCode
      userId
      webFormId
      createdAt
      updatedAt
      bankId
      category
      dress
      tableNumber
      groupName
      status
      terms
      transactionId
      designation
      menu
      salutation
      nationality
      participants
      phoneNumber
    }
  }
`;
export const listWebForms = /* GraphQL */ `
  query ListWebForms(
    $id: String
    $filter: ModelWebFormFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWebForms(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        firstName
        lastName
        email
        eventPromoCode
        userId
        webFormId
        createdAt
        updatedAt
        bankId
        category
        dress
        tableNumber
        groupName
        status
        terms
        transactionId
        designation
        menu
        salutation
        nationality
        participants
        phoneNumber
      }
      nextToken
    }
  }
`;
export const getWebFormGroup = /* GraphQL */ `
  query GetWebFormGroup($name: String!) {
    getWebFormGroup(name: $name) {
      name
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const listWebFormGroups = /* GraphQL */ `
  query ListWebFormGroups(
    $name: String
    $filter: ModelWebFormGroupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWebFormGroups(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        name
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getScanHistory = /* GraphQL */ `
  query GetScanHistory($id: String!) {
    getScanHistory(id: $id) {
      id
      firstName
      lastName
      email
      voucherId
      scanStatus
      merchantId
      dealId
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const listScanHistories = /* GraphQL */ `
  query ListScanHistories(
    $id: String
    $filter: ModelScanHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listScanHistories(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        firstName
        lastName
        email
        voucherId
        scanStatus
        merchantId
        dealId
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($userId: ID!) {
    getUser(userId: $userId) {
      userId
      emailId
      marketingConsent
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $userId: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        emailId
        marketingConsent
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tagById = /* GraphQL */ `
  query TagById(
    $id: ID!
    $tagName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTagsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tagById(
      id: $id
      tagName: $tagName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        merchantId
        tagName
        tagType
        highlights
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTagsByTagName = /* GraphQL */ `
  query GetTagsByTagName(
    $tagName: String!
    $sortDirection: ModelSortDirection
    $filter: ModelTagsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTagsByTagName(
      tagName: $tagName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        merchantId
        tagName
        tagType
        highlights
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const dealByAggregator = /* GraphQL */ `
  query DealByAggregator(
    $aggregator: String!
    $sortDirection: ModelSortDirection
    $filter: ModelDealMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dealByAggregator(
      aggregator: $aggregator
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        address
        aggregator
        approval {
          pk
          isApproved
          createdYearMonth
          createdAt
          updatedAt
          createdByUser
          createdByGroups
        }
        area
        bank
        brand
        mainCat
        cc_buddy_category
        ccbuddy_card_name
        country
        deal_handler
        display_merchant_name
        district
        district_name
        end
        end_timestamp
        festive
        general_location
        geohashlong
        geohashshort
        google_id
        google_placeid
        google_type
        image_path
        image_url
        is_online
        isSponsored
        sponsoredRank
        latitude
        longitude
        merchant_id
        merchant_name
        merchant_name_lowercase
        merchant_unique_id
        online_company
        phone
        pk
        postal_code
        promo_code
        promotion
        promotion_analytic
        promotion_caption
        rank
        rank_updated
        score
        sector
        start
        start_timestamp
        store_id
        subcategory
        TR_tags
        tags {
          nextToken
        }
        terms
        usable_promocode
        valid
        website
        createdAt
        createdBy
        createdByUser
        updatedAt
        updatedBy
        isPinned
        pinPageLocation
        pinOrder
        purchasable
        actualPrice
        discount
        discountPrice
        purchasePrice
        purchaseLimitPerCustomer
        showInOutletIds
        additionalPromotionInfo {
          id
          termsText
          iconUrl
        }
      }
      nextToken
    }
  }
`;
export const dealByDealHandler = /* GraphQL */ `
  query DealByDealHandler(
    $deal_handler: String!
    $sortDirection: ModelSortDirection
    $filter: ModelDealMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dealByDealHandler(
      deal_handler: $deal_handler
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        address
        aggregator
        approval {
          pk
          isApproved
          createdYearMonth
          createdAt
          updatedAt
          createdByUser
          createdByGroups
        }
        area
        bank
        brand
        mainCat
        cc_buddy_category
        ccbuddy_card_name
        country
        deal_handler
        display_merchant_name
        district
        district_name
        end
        end_timestamp
        festive
        general_location
        geohashlong
        geohashshort
        google_id
        google_placeid
        google_type
        image_path
        image_url
        is_online
        isSponsored
        sponsoredRank
        latitude
        longitude
        merchant_id
        merchant_name
        merchant_name_lowercase
        merchant_unique_id
        online_company
        phone
        pk
        postal_code
        promo_code
        promotion
        promotion_analytic
        promotion_caption
        rank
        rank_updated
        score
        sector
        start
        start_timestamp
        store_id
        subcategory
        TR_tags
        tags {
          nextToken
        }
        terms
        usable_promocode
        valid
        website
        createdAt
        createdBy
        createdByUser
        updatedAt
        updatedBy
        isPinned
        pinPageLocation
        pinOrder
        purchasable
        actualPrice
        discount
        discountPrice
        purchasePrice
        purchaseLimitPerCustomer
        showInOutletIds
        additionalPromotionInfo {
          id
          termsText
          iconUrl
        }
      }
      nextToken
    }
  }
`;
export const dealByMerchantId = /* GraphQL */ `
  query DealByMerchantId(
    $merchant_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDealMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dealByMerchantId(
      merchant_id: $merchant_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        address
        aggregator
        approval {
          pk
          isApproved
          createdYearMonth
          createdAt
          updatedAt
          createdByUser
          createdByGroups
        }
        area
        bank
        brand
        mainCat
        cc_buddy_category
        ccbuddy_card_name
        country
        deal_handler
        display_merchant_name
        district
        district_name
        end
        end_timestamp
        festive
        general_location
        geohashlong
        geohashshort
        google_id
        google_placeid
        google_type
        image_path
        image_url
        is_online
        isSponsored
        sponsoredRank
        latitude
        longitude
        merchant_id
        merchant_name
        merchant_name_lowercase
        merchant_unique_id
        online_company
        phone
        pk
        postal_code
        promo_code
        promotion
        promotion_analytic
        promotion_caption
        rank
        rank_updated
        score
        sector
        start
        start_timestamp
        store_id
        subcategory
        TR_tags
        tags {
          nextToken
        }
        terms
        usable_promocode
        valid
        website
        createdAt
        createdBy
        createdByUser
        updatedAt
        updatedBy
        isPinned
        pinPageLocation
        pinOrder
        purchasable
        actualPrice
        discount
        discountPrice
        purchasePrice
        purchaseLimitPerCustomer
        showInOutletIds
        additionalPromotionInfo {
          id
          termsText
          iconUrl
        }
      }
      nextToken
    }
  }
`;
export const dealByMerchantUniqueId = /* GraphQL */ `
  query DealByMerchantUniqueId(
    $merchant_unique_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDealMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dealByMerchantUniqueId(
      merchant_unique_id: $merchant_unique_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        address
        aggregator
        approval {
          pk
          isApproved
          createdYearMonth
          createdAt
          updatedAt
          createdByUser
          createdByGroups
        }
        area
        bank
        brand
        mainCat
        cc_buddy_category
        ccbuddy_card_name
        country
        deal_handler
        display_merchant_name
        district
        district_name
        end
        end_timestamp
        eventDealType
        festive
        general_location
        geohashlong
        geohashshort
        google_id
        google_placeid
        google_type
        image_path
        image_url
        is_online
        isSponsored
        sponsoredRank
        latitude
        longitude
        merchant_id
        merchant_name
        merchant_name_lowercase
        merchant_unique_id
        online_company
        phone
        pk
        postal_code
        promo_code
        promotion
        promotion_analytic
        promotion_caption
        rank
        rank_updated
        score
        sector
        start
        start_timestamp
        store_id
        subcategory
        TR_tags
        tags {
          nextToken
        }
        terms
        usable_promocode
        valid
        website
        createdAt
        createdBy
        createdByUser
        updatedAt
        updatedBy
        isPinned
        pinPageLocation
        pinOrder
        purchasable
        actualPrice
        discount
        discountPrice
        purchasePrice
        purchaseLimitPerCustomer
        showInOutletIds
        additionalPromotionInfo {
          id
          termsText
          iconUrl
        }
      }
      nextToken
    }
  }
`;
export const getPinnedDeals = /* GraphQL */ `
  query GetPinnedDeals(
    $isPinned: String!
    $sortDirection: ModelSortDirection
    $filter: ModelDealMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPinnedDeals(
      isPinned: $isPinned
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        address
        aggregator
        approval {
          pk
          isApproved
          createdYearMonth
          createdAt
          updatedAt
          createdByUser
          createdByGroups
        }
        area
        bank
        brand
        mainCat
        cc_buddy_category
        ccbuddy_card_name
        country
        deal_handler
        display_merchant_name
        district
        district_name
        end
        end_timestamp
        festive
        general_location
        geohashlong
        geohashshort
        google_id
        google_placeid
        google_type
        image_path
        image_url
        is_online
        isSponsored
        sponsoredRank
        latitude
        longitude
        merchant_id
        merchant_name
        merchant_name_lowercase
        merchant_unique_id
        online_company
        phone
        pk
        postal_code
        promo_code
        promotion
        promotion_analytic
        promotion_caption
        rank
        rank_updated
        score
        sector
        start
        start_timestamp
        store_id
        subcategory
        TR_tags
        tags {
          nextToken
        }
        terms
        usable_promocode
        valid
        website
        createdAt
        createdBy
        createdByUser
        updatedAt
        updatedBy
        isPinned
        pinPageLocation
        pinOrder
        purchasable
        actualPrice
        discount
        discountPrice
        purchasePrice
        purchaseLimitPerCustomer
        showInOutletIds
        additionalPromotionInfo {
          id
          termsText
          iconUrl
        }
      }
      nextToken
    }
  }
`;
export const outletByMerchantId = /* GraphQL */ `
  query OutletByMerchantId(
    $merchant_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMerchantOutletFilterInput
    $limit: Int
    $nextToken: String
  ) {
    outletByMerchantId(
      merchant_id: $merchant_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        address
        deals {
          nextToken
        }
        mainCat
        display_merchant_name
        distance
        general_location
        geohashlong
        geohashshort
        geohashsix
        google_id
        google_placeid
        google_type
        image_path
        image_src
        latitude
        longitude
        mallId
        merchant_id
        merchant_name
        merchant_name_lowercase
        merchant_namelist
        merchant_unique_id
        online_company
        outlet_name
        phone
        postal_code
        postal_district
        postal_district_name
        postal_sector
        protectedAttributes
        store_id
        subc_online
        subcategory
        valid
        merchantImageUrl
        merchantImageUrlV2
        defaultImageUrl
        businessSummary
        outletWebsite
        mcc
        createdAt
        updatedAt
        createdByUser
        createdByGroups
        purchasableOutlet
        blkAptNum
        roadName
        buildingName
        unitNum
        country
      }
      nextToken
    }
  }
`;
export const outletByPostalCode = /* GraphQL */ `
  query OutletByPostalCode(
    $postal_code: String!
    $sortDirection: ModelSortDirection
    $filter: ModelMerchantOutletFilterInput
    $limit: Int
    $nextToken: String
  ) {
    outletByPostalCode(
      postal_code: $postal_code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        address
        deals {
          nextToken
        }
        mainCat
        display_merchant_name
        distance
        general_location
        geohashlong
        geohashshort
        geohashsix
        google_id
        google_placeid
        google_type
        image_path
        image_src
        latitude
        longitude
        mallId
        merchant_id
        merchant_name
        merchant_name_lowercase
        merchant_namelist
        merchant_unique_id
        online_company
        outlet_name
        phone
        postal_code
        postal_district
        postal_district_name
        postal_sector
        protectedAttributes
        store_id
        subc_online
        subcategory
        valid
        merchantImageUrl
        merchantImageUrlV2
        defaultImageUrl
        businessSummary
        outletWebsite
        mcc
        createdAt
        updatedAt
        createdByUser
        createdByGroups
        purchasableOutlet
        blkAptNum
        roadName
        buildingName
        unitNum
        country
      }
      nextToken
    }
  }
`;
export const stripeAccountByEmail = /* GraphQL */ `
  query StripeAccountByEmail(
    $email: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCMSUserStripeAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    stripeAccountByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        email
        merchantId
        type
        journeyStep
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const stripeAccountByMerchantId = /* GraphQL */ `
  query StripeAccountByMerchantId(
    $merchantId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCMSUserStripeAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    stripeAccountByMerchantId(
      merchantId: $merchantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        email
        merchantId
        type
        journeyStep
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const voucherByDealId = /* GraphQL */ `
  query VoucherByDealId(
    $dealId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelVoucherMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    voucherByDealId(
      dealId: $dealId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dealId
        code
        codeType
        startTimestamp
        endTimestamp
        userId
        status
        valid
        transactionId
        retrievalTimestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const voucherByCode = /* GraphQL */ `
  query VoucherByCode(
    $code: String!
    $sortDirection: ModelSortDirection
    $filter: ModelVoucherMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    voucherByCode(
      code: $code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dealId
        code
        codeType
        startTimestamp
        endTimestamp
        userId
        status
        valid
        transactionId
        retrievalTimestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const voucherUserByDealId = /* GraphQL */ `
  query VoucherUserByDealId(
    $dealId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelVoucherUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    voucherUserByDealId(
      dealId: $dealId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        masterId
        dealId
        userId
        code
        startTimestamp
        endTimestamp
        status
        transactionId
        redeemTimestamp
        createdAt
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const voucherByUserId = /* GraphQL */ `
  query VoucherByUserId(
    $userId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelVoucherUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    voucherByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        masterId
        dealId
        userId
        code
        startTimestamp
        endTimestamp
        status
        transactionId
        redeemTimestamp
        createdAt
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const voucherByTransactionId = /* GraphQL */ `
  query VoucherByTransactionId(
    $transactionId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelVoucherUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    voucherByTransactionId(
      transactionId: $transactionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        masterId
        dealId
        userId
        code
        startTimestamp
        endTimestamp
        status
        transactionId
        redeemTimestamp
        createdAt
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const transactionByDealId = /* GraphQL */ `
  query TransactionByDealId(
    $dealId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionByDealId(
      dealId: $dealId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        dealId
        quantity
        actualPrice
        discount
        discountPrice
        purchasePrice
        purchasePriceTotal
        timestamp
        paymentMethod
        status
        discountCode
        extraDiscount
        deal {
          address
          aggregator
          area
          bank
          brand
          mainCat
          cc_buddy_category
          ccbuddy_card_name
          country
          deal_handler
          display_merchant_name
          district
          district_name
          end
          end_timestamp
          festive
          general_location
          geohashlong
          geohashshort
          google_id
          google_placeid
          google_type
          image_path
          image_url
          is_online
          isSponsored
          sponsoredRank
          latitude
          longitude
          merchant_id
          merchant_name
          merchant_name_lowercase
          merchant_unique_id
          online_company
          phone
          pk
          postal_code
          promo_code
          promotion
          promotion_analytic
          promotion_caption
          rank
          rank_updated
          score
          sector
          start
          start_timestamp
          store_id
          subcategory
          TR_tags
          terms
          usable_promocode
          valid
          website
          createdAt
          createdBy
          createdByUser
          updatedAt
          updatedBy
          isPinned
          pinPageLocation
          pinOrder
          purchasable
          actualPrice
          discount
          discountPrice
          purchasePrice
          purchaseLimitPerCustomer
          showInOutletIds
        }
        user {
          userId
          emailId
          marketingConsent
          # createdAt
          # updatedAt
        }
        createdAt
        updatedAt
        utm_campaign
        utm_content
        utm_medium
        utm_source
        utm_term
      }
      nextToken
    }
  }
`;
export const termsMasterByType = /* GraphQL */ `
  query TermsMasterByType(
    $type: String!
    $order: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTermsMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    termsMasterByType(
      type: $type
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        iconUrl
        description
        recommended
        termsText
        placeholderText
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const checkinTicketByDealId = /* GraphQL */ `
  query CheckinTicketByDealId(
    $dealId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCheckinTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    checkinTicketByDealId(
      dealId: $dealId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        masterId
        dealId
        firstName
        lastName
        email
        terms
        marketingConsent
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const webformByUserId = /* GraphQL */ `
  query WebformByUserId(
    $userId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelWebFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    webformByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        eventPromoCode
        userId
        webFormId
        createdAt
        updatedAt
        bankId
        category
        dress
        tableNumber
        groupName
        status
        terms
        transactionId
        designation
        menu
        salutation
        nationality
        participants
        phoneNumber
      }
      nextToken
    }
  }
`;
export const webformByWebFormId = /* GraphQL */ `
  query WebformByWebFormId(
    $webFormId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelWebFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    webformByWebFormId(
      webFormId: $webFormId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        eventPromoCode
        userId
        webFormId
        createdAt
        updatedAt
        bankId
        category
        dress
        tableNumber
        groupName
        status
        terms
        transactionId
        designation
        menu
        salutation
        nationality
        participants
        phoneNumber
      }
      nextToken
    }
  }
`;
export const webformByTransactionId = /* GraphQL */ `
  query WebformByTransactionId(
    $transactionId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelWebFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    webformByTransactionId(
      transactionId: $transactionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        eventPromoCode
        userId
        webFormId
        createdAt
        updatedAt
        bankId
        category
        dress
        tableNumber
        groupName
        status
        terms
        transactionId
        designation
        menu
        salutation
        nationality
        participants
        phoneNumber
        creator
      }
      nextToken
    }
  }
`;
export const scanHistoryByVoucherId = /* GraphQL */ `
  query ScanHistoryByVoucherId(
    $voucherId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelScanHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    scanHistoryByVoucherId(
      voucherId: $voucherId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        voucherId
        scanStatus
        merchantId
        dealId
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const scanHistoryByMerchantId = /* GraphQL */ `
  query ScanHistoryByMerchantId(
    $merchantId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelScanHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    scanHistoryByMerchantId(
      merchantId: $merchantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        voucherId
        scanStatus
        merchantId
        dealId
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const scanHistoryByCreatedBy = /* GraphQL */ `
  query ScanHistoryByCreatedBy(
    $createdBy: String!
    $sortDirection: ModelSortDirection
    $filter: ModelScanHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    scanHistoryByCreatedBy(
      createdBy: $createdBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        voucherId
        scanStatus
        merchantId
        dealId
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
