import React from "react"
import { Container, Row, Col } from "reactstrap"
import { Link } from "react-router-dom"

import { openInNewTab } from "utils"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col sm={12}>
              <Link to="#" onClick={() => openInNewTab('https://cardspal.com/support/')}><span>Support Center</span></Link><span className="separator">|</span>
              <Link to="#" onClick={() => openInNewTab('https://cardspal.com/merchant_terms/')}><span>Merchant T&C</span></Link><span className="separator">|</span>
              <Link to="#" onClick={() => openInNewTab('https://cardspal.com/terms-of-use/')}><span>Terms of Use</span></Link><span className="separator">|</span>
              <Link to="#" onClick={() => openInNewTab('https://cardspal.com/privacy-policy/')}><span>Privacy Policy</span></Link>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              © {new Date().getFullYear()} CardsPal Business
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
