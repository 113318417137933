import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { useSelector, useDispatch } from "react-redux";
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardImg, Spinner, Button } from "reactstrap"
import { getUnixTime } from 'date-fns'

import RBAC from '../../../components/Common/rbac'

//import components
import StripePrompt from "../../../components/Common/stripePrompt";
import EmptyState from './Components/emptyState'
import EventForm from  './Components/EventForm'
import StripeOnboarding from "../../../components/Common/stripeOnboarding";
import sellVoucherStepImg from '../../../assets/images/sellVoucherStepsC.png'

// import utils
import { isDealExpiring, openInNewTab, guidGenerator, capitalizeFirstLetter, convertToCSV } from '../../../utils'
import { STRIPE_STATUS, ON_BOARDING } from '../../../utils/constants'
import { getStripeStatusColor } from "utils/stripe";

//import store actions
import { setBreadcrumbItems, setAlert, setConfig, setEnv, setAmplitudeEvent  } from "../../../store/actions";

// import graphql
import { listOutletsByMerchantId, getOutlet} from '../../../helpers/GraphQL/outlet'
import { getBrandInfo } from '../../../helpers/GraphQL/brands'
import { getMerchantStripeAccount, updateStripeAccount, createStripeAccount } from "../../../helpers/GraphQL/userStripeAccount"

// import helpers
import { stripeCreateOnboardingLink, stripeRetrieveKey, stripeRetrieveAccount, stripeCreateLoginLink, getConfig, getEnv, getEvents  } from '../../../helpers/apiHelper'

const Events = () => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.User.user)
    const cmsConfig = useSelector(state => state.Config)
    const selectedMerchant = useSelector(state => state.Merchant.selectedMerchant)
    const [refreshUrl, setRefreshUrl] = useState('https://biz.cardspal.com')
    const [returnUrl, setReturnUrl] = useState('https://biz.cardspal.com')
    const [createStripeButtonDisabled, setCreateStripeButtonDisabled] = useState(true)
    const [stripeKey, setStripeKey] = useState(null)
    const [stripeLink, setStripeLink] = useState()
    const [buttonLabel, setButtonLabel] = useState('Connect to our Stripe Account')
    const [stripeAccountStatus, setStripeAccountStatus] = useState('')
    const [userInfo, setUserInfo] = useState()
    const [modalStripePromptStatus, setModalStripePromptStatus] = useState(false)
    const [needPrompt, setNeedPrompt] = useState(false)
    const [stripeStatus, setStripeStatus] = useState('')
    const [statusColor, setStatusColor] = useState('')
    const [loadingStatus, setLoadingStatus] = useState(false)
    const [modalEventFormStatus, setModalEventFormStatus] = useState(false)
    const [mode, setMode] = useState('')
    const [reload, setReload] = useState('')
    const [outlets, setOutlets] =useState([])
    const [canCreateEvent, setCanCreateEvent] = useState(true)

    const [merchantUniqueIDs, setMerchantUniqueIDs] = useState([])
    const [events, setEvents] = useState([])
    const [eventForm, setEventForm] = useState({})

    const data = {
        columns: [
            {
                label: "ID",
                field: "id",
                width: 50,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            },
            {
                label: "Edit",
                field: "edit",
                sort: 'disabled',
                width: 50,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            },
            {
                label: "Visible",
                field: "visible",
                width: 70,
                attributes: {
                    "className": "verticalAlignHeader"
                }
            },
            {
                label: "Event Name",
                field: "eventName",
                width: 200,
                sort: "asc",
            },
            {
                label: "Online",
                field: "online",
                width: 100,
                sort: 'disabled',
            },
            {
                label: "Event Location",
                field: "eventLocation",
                sort: 'asc',
                width: 300,
            },
            {
                label: "Postal Code",
                field: "postalCode",
                sort: 'disabled',
                width: 100,
            }
        ],
        rows: []
    }

    const [dataTable, setDataTable] = useState(data)

    const breadcrumbItems = [
        { title: "CardsPal", link: "#" },
        { title: selectedMerchant.display_merchant_name, link: "#" },
        { title: "Tools", link: "#" },
        { title: "Manage events", link: "#" },
    ]

    useEffect(() => {
        dispatch(setAlert(''))
        dispatch(setBreadcrumbItems('Manage events', breadcrumbItems))
    }, [selectedMerchant.display_merchant_name])

    useEffect(async () => {
        if (!selectedMerchant || !selectedMerchant.merchant_id) return 

       
        
        // get the updated merchant info from DB in case webhook is updating stripe and other info and the user has not logout yet causing the store to be an old-data
        const brand = await getBrandInfo(selectedMerchant.merchant_id)
        // update the stripe attribute with latest to make sure we restrict if needed
        selectedMerchant.stripeExpressConnectedAccountId = brand.stripeExpressConnectedAccountId
        // if stripeExpressConnectedAccountId is not in merchant or empty or null, disable button to add voucher
        setCanCreateEvent(!brand.stripeExpressConnectedAccountId)
    }, [selectedMerchant])

    useEffect(() =>{
        if (cmsConfig && cmsConfig.cmsv2) setConfigVariables(cmsConfig.cmsv2.stripeVariables)
    }, [cmsConfig])

    // populate merchant unique ids based on outlets
    useEffect(async () => {
        setLoadingStatus(true)
        try {
            //this is to retrieve existing events under certain outlet
            const _outlets = await listOutletsByMerchantId(selectedMerchant.merchant_id)
            setOutlets([..._outlets])
            let merchant_unique_ids = _outlets.map((merchant)=> merchant.merchant_unique_id )

            // get new outlet for events
            const eventOutlet = await getOutlet(selectedMerchant.merchant_id,'event')
            if (eventOutlet) merchant_unique_ids.push(eventOutlet.merchant_unique_id)
            setMerchantUniqueIDs([...merchant_unique_ids])
        } catch (e) {
            dispatch(setAlert(''))
        }
    }, [selectedMerchant, user, reload])


    // Populate events based on merchants unique ids
    useEffect(async () => {
        setLoadingStatus(true)
        try {
            let list = []
            await Promise.all(  merchantUniqueIDs.map(async (muid) => {
                                const _events = await getEvents(muid, "merchant_unique_id")
                                list.push(..._events.data.events)   
            }))
            list.sort((a, b) => a.name.localeCompare(b.name))
            setEvents(list)
        } catch (e) {
            dispatch(setAlert(''))
        }
    }, [merchantUniqueIDs])


    useEffect(() => {
        setStatusColor(getStripeStatusColor(stripeStatus))
    }, [stripeStatus])

    useEffect(async () => {
        setLoadingStatus(true)
        dispatch(setAlert('Initializing...Please wait while we finish getting updates', 'info'))

        try {
            // get user info
            const user = await JSON.parse(await localStorage.getItem('user'))
            setUserInfo(user)
            
            
            // retrieve the stripe key from secret manager
            const key = await stripeRetrieveKey()
            if (!key) {
                dispatch(setAlert(`Error loading this page. Please report to admin immediately.`, 'danger'))
                return
            } else {
                setStripeKey(key.stripeSecret)
            }

            await initializeStripeLink(user, key?.stripeSecret)
            
        } catch (error) {
            console.error(error)
            setLoadingStatus(false)
            dispatch(setAlert(`Error loading this page. Please report to admin immediately.`, 'danger'))
        }
    }, [])

    useEffect(() => {
        setButtonLabel('Connect to our Stripe Account')
        initializeStripeLink(userInfo, stripeKey)
    }, [selectedMerchant])

    useEffect(async () => {
        if (!user || !selectedMerchant.merchant_id || Object.keys(user).length === 0) return 

        setLoadingStatus(true)
        data.rows = [] // clear the display items on the table first
        setDataTable(data)
  
        const dataRows = []

        
        await Promise.all(events.map(async (event, i) => {
            const _item = event
            
            
            dataRows.push({
                id: i+1,
                edit: <a href="#" onClick={() => updateEvent(_item, i)}><i className="mdi mdi-pencil" style={{ color: "#7a6fbe" }} /></a>,
                visible: getEventStatus(_item, i) ,
                online: _item.isOnline === 'True'  ? 'Yes' : 'No',
                eventName: _item.name,
                eventLocation: _item.location,
                postalCode: _item?.locationDetails?.postalCode
            })
        }))
        
        // set the data table values
        data.rows = dataRows
        setDataTable({...data})
        
        // TODO :: confirm parameters
        let _ampTrack = {
            'merchant id': selectedMerchant.merchant_id,
            'merchant name':  selectedMerchant.merchant_name,
            'mainCat': selectedMerchant.mainCat
        }
        let trackEmpty = {empty:events && events.length > 0? 'N' :'Y'}
        dispatch(setAmplitudeEvent('CMS Manage Events page - page load', {..._ampTrack, ...trackEmpty}))
        
    }, [events])

    useEffect(async () => {
        await getCMSConfig()
    }, [])
    
    useEffect(()=>{
        setLoadingStatus(false)
    },[dataTable])


    function getEventStatus(item) {
        if (item.valid === '1') {
            return (
                // <a href="#"  >
                <i className="mdi mdi-eye" style={{ color: "#58DB83" }} />
                // </a>
            )
        } else {
            return (
                // <a href="#" >
                <i className="mdi mdi-eye-off" style={{ color: "#EC536C" }} />
                // </a>
            )
        }
    }

    async function getCMSConfig() {
        try {
            
            if (!cmsConfig || !cmsConfig.cmsv2) {
                const config = await getConfig()

                const env = getEnv()
                
                dispatch(setConfig(config))
                dispatch(setEnv(env))
            }
        } catch(error) {
            console.error('error on retrieving config file', error)
            dispatch(setAmplitudeEvent('CMS Manage Events page - getCMSConfig function error', {'details': JSON.stringify(error)}))
        }
    }
    
    async function setConfigVariables(variables) {
        setRefreshUrl(variables.refreshUrl)
        setReturnUrl(variables.returnUrl)
    }

    async function initializeStripeLink(userInfo, stripeKey) {
        try {

            if (!userInfo || !stripeKey) return

            // check user on-boarding status
            const stripeAccount = await getMerchantStripeAccount(selectedMerchant.merchant_id)
            
            let stripeInfo
            let status // status: completed, pending-verification, not-completed
            let link
            
            if (stripeAccount && stripeAccount.type === ON_BOARDING) {
            
                if (stripeAccount.status === STRIPE_STATUS.COMPLETED){
                    console.debug('completed stripe account ')
                    status = STRIPE_STATUS.COMPLETED
                    
                    if (!selectedMerchant.stripeExpressConnectedAccountId) {
                        dispatch(setAlert('Cannot create event. Please report to the Admin to resolve your account', 'danger'))
                    } else {
                        dispatch(setAlert(''))
                    }
                    
                    setButtonLabel('View Stripe Platform')
                } else {
                    // means user triggered on-boarding before but did not finish, so we retrieve info from stripe api again
                    stripeInfo = await stripeRetrieveAccount({ stripeKey, accountId: stripeAccount.accountId })

                    if (!stripeInfo.charges_enabled) {
                        // dispatch(setAlert(''))
                        status = stripeAccount.status
                        console.debug('not completed so create account link')
                        const stripeInfo = await stripeCreateOnboardingLink({ email: userInfo.email, stripeKey, refreshUrl, returnUrl, merchantId: selectedMerchant.merchant_id, accountId: stripeAccount.accountId })
                        link = stripeInfo.accountLink.url
                        setNeedPrompt(true)
                    } else {
                        status = await stripeStatusLogic(stripeInfo)
                        console.debug('not completed so create login link')
                        const stripeLoginLink = await stripeCreateLoginLink({ stripeKey, accountId: stripeAccount.accountId })
                        link = stripeLoginLink.url
                    }
                    dispatch(setAlert(''))
                    
                    await updateStripeAccount({
                        accountId: stripeAccount.accountId,
                        merchantId: selectedMerchant.merchant_id,
                        email: userInfo.email,
                        type: 'on-boarding',
                        status
                    })
                }
            } else {
                // first time to on-board to stripe
                status = STRIPE_STATUS.NOT_ONBOARDED
                stripeInfo = await stripeCreateOnboardingLink({ email: userInfo.email, stripeKey, refreshUrl, returnUrl, merchantId: selectedMerchant.merchant_id })
                link = stripeInfo.accountLink.url

                setNeedPrompt(true)
                dispatch(setAlert(''))
                
                await createStripeAccount({
                    accountId: stripeInfo.account.id,
                    merchantId: selectedMerchant.merchant_id,
                    email: userInfo.email,
                    type: 'on-boarding',
                    status
                })
            }
            
            setStripeAccountStatus(status)
            setStripeLink(link)
            setStripeStatus(status)
            setCreateStripeButtonDisabled(false)

        } catch(error) {
            console.error(error)
            dispatch(setAmplitudeEvent('CMS Manage Events page - initializeStripeLink function error', {'details': JSON.stringify(error)}))
        }
    }

    function stripeStatusLogic(stripeAccount) {
        let status
        if (stripeAccount.charges_enabled) {
            // fully on-boarded
            status = STRIPE_STATUS.COMPLETED
            dispatch(setAlert(<span>Great! Stripe account set up has been approved. You may start selling vouchers for ${selectedMerchant.merchant_name} now.</span>, 'success'))
            setButtonLabel('View Stripe Platform')
        } else if (stripeAccount.details_submitted) {
            // completed onboarding, pending stripe verification
            status = STRIPE_STATUS.PENDING_VERIFICATION
            dispatch(setAlert(<span>Stripe set up is pending approval <b>within the next 48 hours.</b> We will alert you when the set up is done.</span>, 'info'))
            setButtonLabel('View Stripe Platform')
        } else if (!stripeAccount.details_submitted) {
            // on-boarding not completed, need to create login link
            status = STRIPE_STATUS.IN_PROGRESS
            dispatch(setAlert(''))
        }
        return status
    }

    function openStripeLink() {
        if (needPrompt) {
            setModalStripePromptStatus(true)
        } else {
            setCreateStripeButtonDisabled(true)
            openInNewTab(stripeLink)
        }
    }

    function closeForm(item, state) {
        setModalStripePromptStatus(state || false)
        setModalEventFormStatus(state || false)
        
        if (item) {
            setReload(new Date())
        }
    }

    function createEvents() {
        if (outlets.length===0) {
            dispatch(setAlert(<span>You need to add at least 1 outlet first. Click <a href="/manage-outlets">here</a> to add outlet</span>, 'danger'))
            return
        }
        setModalEventFormStatus(true)
        setMode('create')
        const pk = guidGenerator()
        setEventForm({
            isOnline:  'False',
            pk: pk,
            addOnDeal:"",
            addOnDealDescription:"",
            addOnDeals:[],
            addOnDealTitle:"",
            canCancel:"0",
            categories:["Activities"],
            description:"",
            emailData:{ additionalInfo:"",
                        dropOffSubject:"",
                        emailSubject :"",
                        footerBanner :"",
                        footerBannerLink:"",
                        footerDropOffBanner:"",
                        footerDropOffLink:""},
            endTimestamp:"",
            faq:"",
            featuring:[{}],
            hasCheckIn:"0",
            hasPdf:"1",
            isLegalLimitPrompt:"0",
            latitude:"",
            location:"",
            locationDetails:{   address:"",
                                country:"",
                                locality:"",
                                name:"",
                                postalCode:"",
                                region:""},
            longitude:"",
            merchantUniqueId:"",
            name:"",
            purchaseableDeals:[],
            recommendedDeals:[],
            registrationEnd:[],
            socialMedia:[],
            startTimestamp:"",
            terms:"",
            tz:"",
            valid: '1',
            webFormId: "SELFSERVE-"+pk
        })
    }

    function updateEvent(_item) {
        if (!selectedMerchant.stripeExpressConnectedAccountId) {
            dispatch(setAlert('Cannot edit event. Please report to the Admin to resolve your account.', 'danger'))
            dispatch(setAmplitudeEvent('CMS Manage Events page - updateEvent function error', {'details': 'stripeExpressConnectedAccountId field is missing', ..._item}))
        } else {
            setModalEventFormStatus(true)
            setMode('update')
            setEventForm(_item)
        }
    }

    return (
        <React.Fragment>
            <RBAC merchantId={selectedMerchant.merchant_id} roles={['admin']}>

                <MetaTags>
                    <title>Manage Events</title>
                </MetaTags>


                {
                    loadingStatus ?
                    <Row style={{minHeight:"400px", justifyContent:"center", alignContent:"center"}}>
                        <Spinner />
                    </Row>
                    : stripeAccountStatus !== STRIPE_STATUS.COMPLETED ?
                        <Row className="justify-content-center">
                            <Col xs={12} sm={12} md={6} lg={5} xl={5}>
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardImg
                                                src={sellVoucherStepImg}
                                                top
                                                width="100%"
                                                style={{ objectFit:'contain', marginTop:'20px' }}
                                            />
                                        </Card>
                                    </Col>
                                </Row>
                                            
                                <Row>
                                    <Col>
                                        {[STRIPE_STATUS.PENDING_VERIFICATION, STRIPE_STATUS.IN_PROGRESS].includes(stripeAccountStatus) ?
                                        <div>
                                            <StripeOnboarding
                                                stripeStatus={stripeStatus}
                                                statusColor={statusColor}
                                                disabled={createStripeButtonDisabled}
                                                buttonLabel={buttonLabel}
                                                cta="manage events page"
                                                onClick={() => openStripeLink()}
                                                outline = {true}
                                            />
                                        </div>:

                                        <div>
                                            <StripeOnboarding
                                                stripeStatus={stripeStatus}
                                                statusColor={statusColor}
                                                disabled={createStripeButtonDisabled}
                                                buttonLabel={buttonLabel}
                                                cta="manage events page"
                                                onClick={() => openStripeLink()}
                                                outline = {false}
                                            />
                                        </div>}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    : dataTable.rows.length > 0 ?
                    <Row>
                    <Col className="col-12">
                        <Card>
                        <CardBody>
                        
                                <div style={{ display: "flex", justifyContent: "flex-end", top: "21px", position: "relative" }} >
                                    <Button color="primary" disabled={canCreateEvent} type="button" size="sm" onClick={() => createEvents()}>Add Event</Button>
                                </div>
                            
                                <MDBDataTable className="outlets" fixed={true} entries={10} displayEntries={false} hover pagesAmount={10} scrollX  data={{ columns: dataTable.columns, rows: dataTable.rows }} noBottomColumns={true} />
                        </CardBody>
                        </Card>
                    </Col>
                    </Row>
                    :
                    <EmptyState disabled={canCreateEvent} createEvents={createEvents}/> 
                    
                }
                {modalStripePromptStatus ? <StripePrompt cta="manage events page" disableStripeButtonLInk={() => setCreateStripeButtonDisabled(true)} stripeLink={stripeLink} modalStripePromptStatus={modalStripePromptStatus} close={(item, state, type) => closeForm(item, state, type)} /> : null}
                {modalEventFormStatus ? <EventForm event={eventForm} mode={mode} modalEventFormStatus={modalEventFormStatus} close={(item, state, type) => closeForm(item, state, type)} /> : null}
            </RBAC>
        </React.Fragment>
    )

}


export default Events;