/* eslint-disable no-useless-catch */
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"

const RBAC = (props) => {
    const user = useSelector(state => state.User.user)

    if (user && props.merchantId && props.roles) {
        const matchedUsers = user?.roles?.merchants?.filter((merchant) => {
            return merchant.merchantId === props.merchantId && props.roles.includes(merchant.roles)
        })
        if (matchedUsers.length > 0) {
            return <React.Fragment>
                {props.children}
            </React.Fragment>
        }
    }

    return null

    // return (
    //     <React.Fragment>
    //         {
    //             props.merchantId && props.roles && (user.roles.merchants?.filter((merchant) => {
    //                 return merchant.merchantId === props.merchantId && props.roles.includes(merchant.roles)
    //             }))?.length > 0 ? props.children : null 
    //         }
    //     </React.Fragment>
    // )
}

export default RBAC;
