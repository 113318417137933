import React from "react"
import { Card, CardImg, Modal, ModalHeader } from "reactstrap"

const ImageOverlay = (props) => {

    return (
        <Modal
            size="lg"
            isOpen={props.state}
            toggle={() => {
                props.close(null, false, 'imageOverlay')
            }}
            centered={true}
            backdrop={true}
        >
            {props.caption?<ModalHeader toggle={() => props.close(null,false, 'imageOverlay')}>{props.caption}</ModalHeader>:null}
            <Card>
                <CardImg
                    alt=""
                    src={props.imageUrl}
                    // width="100%"
                />
            </Card>
        </Modal>
    )
}

export default ImageOverlay;