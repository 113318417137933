import React, { useState, useEffect } from "react"
import { Row, Col, CardBody, Card, Alert, Container, Spinner } from "reactstrap"
import MetaTags from 'react-meta-tags';
import { Form } from 'react-bootstrap';
import { ErrorMessage } from '@hookform/error-message';
import {
  SubTitle, Underline, CardContainer, ToolTipIcon, GroupP, RowGap15,
  ErrMsg, Sup, SubmitButton, Terms, DeclineButton, SpinnerDiv, P
} from './Styled';
import { useForm, Controller } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Auth} from 'aws-amplify'

const DnD_Login = (props) => {

  const [isLoginValid, setIsLoginValid] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const schema = Yup.object().shape({
    username: Yup.string().required("Required"),
    password: Yup.string().required("Required")
  })

  const { 
    register, 
    setValue,
    handleSubmit,
    formState: { 
        errors, 
        isSubmitSuccessful, 
        isValid 
    } 
  } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(schema)
    });
  
  useEffect(() => {
    setValue('username', 'dnd-admin@sc.co')
    setValue('password', 'Dndadmin.12345')
  }, [])

  const processSubmit = async (e) => {
    try {
      setIsLoading(true)
      const user = await Auth.signIn(e.username, e.password)
      const currentUser = await Auth.currentAuthenticatedUser();
      const { attributes } = currentUser;
      console.log(attributes);
      if (user?.attributes?.profile === 'admin' || user?.signInUserSession?.idToken?.payload?.profile === 'admin') {
        setIsLoginValid(true)
        setIsLoading(false)
        props.onSubmit(user)
      } else {
        console.log('processSubmit:',{user})
        setIsLoginValid(false)  
      }
    } catch (e) {
      console.error(e)
      setIsLoginValid(false)
    }
    setIsLoading(false)
  }

  const onChange = () => {
    setIsLoginValid(true)
  }

  const onError = () => {
  }

  return (
    <React.Fragment>
      <meta 
        name='viewport' 
        content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' 
      />
      <MetaTags>
        <title>Login | CardsPal Business</title>
      </MetaTags>
      <div className="account-pages dnd-login-background">
        <Container style={{}}>
          <Row className='justify-content-center' style={{top: '40%', left: '38%', position: 'absolute'}}>
            <Col>
                <Form onSubmit={handleSubmit(processSubmit, onError)} onChange={onChange}>
                  <CardContainer style={props.styling ?? { backgroundColor: "#fff", width: 400 }} >
                      <CardBody>
                          <SubTitle>Please login.</SubTitle>
                          <Row>
                            <Col xs={12} sm={12} md={6} lg={6} xl={4}>
                                <Form.Label>Username<Sup>*</Sup></Form.Label>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={8}>
                                <Form.Control
                                    disabled={false}
                                    type="text"
                                    name={"username"}
                                    placeholder="Enter your username"
                                    className={errors.username && "error"}
                                    autoComplete='off'
                                    {...register("username")}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name={"username"}
                                    render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                />
                            </Col>
                          </Row>
                          <RowGap15 className='mb-3' />
                          <Row>
                            <Col xs={12} sm={12} md={6} lg={6} xl={4}>
                                <Form.Label>Password<Sup>*</Sup></Form.Label>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={8}>
                                <Form.Control
                                    disabled={false}
                                    type="password"
                                    name={"password"}
                                    placeholder="Enter your password"
                                    className={errors.username && "error"}
                                    autoComplete='off'
                                    {...register("password")}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name={"password"}
                                    render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                />
                            </Col>
                          </Row>
                        
                          <Underline className='mb-3' />
                          
                          {!isLoginValid && <Row><span style={{color:"red", textAlign:"center"}}>Invalid login credentials</span></Row>}

                          <Row className='justify-content-center' style={{ marginTop: 10 }}>
                            <SubmitButton
                                type="submit"
                                className="btn btn-primary btn-block"
                                disabled={
                                    !isValid || !isLoginValid ||
                                    Object.keys(errors).length > 0
                                }
                            >Login
                            </SubmitButton>
                          </Row>
                      </CardBody>
                  </CardContainer>
                  {isLoading &&
                  <SpinnerDiv>
                      <Spinner />
                  </SpinnerDiv>}
                </Form>
            </Col>
          </Row>
          
        </Container>
      </div>
    </React.Fragment>
  )
}


export default DnD_Login