import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { Form, InputGroup } from 'react-bootstrap';
import { Row, Col, CardBody, Spinner } from "reactstrap"
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import styled from 'styled-components';
import * as Yup from 'yup';
import {
    SubTitle, Underline, SpinnerDiv, CardContainer, ErrMsg, PriceLabel, boxStylingNoPadding
} from './Styled';
import { verifyDiscountCode, reserveDiscountCode, releaseDiscountCode } from 'helpers/apiHelper'
import { AiOutlinePlusCircle, AiOutlineMinusCircle, AiFillCloseCircle } from 'react-icons/ai';
import { HiTicket } from 'react-icons/hi';

const ContactInfo = (props) => {

    const schema = Yup.object().shape({
        promoCode: Yup.string()
    })

    const initialValues = {
        promoCode: props.prefill?.promoCode ?? ''
    }

    const [state] = useState(initialValues);
    const [isLoading, setIsLoading] = useState(false)



    const { register, getValues, setValue, setError, clearErrors, formState: { errors } } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: state,
        resolver: yupResolver(schema)
    });

    const redirect = (URL) => {
        window.location.href = URL
    }
    const decreaseQty = () => {
        let { qty, dealInfo } = props

        if (qty > dealInfo?.data?.minQtyTx) {
            let newQty = qty - 1
            let newTotalPrice = dealInfo.data.purchasePrice * newQty
            let newPaymentPrice = newTotalPrice
            props.setQty(newQty)
            clearCode()
            props.setTotalPrice(newTotalPrice.toFixed(2))
            props.setPaymentPrice(newPaymentPrice.toFixed(2))

        }
    }
    const increaseQty = () => {
        let { qty, dealInfo } = props

        if (qty < dealInfo?.data?.purchaseLimitPerCustomer) {
            let newQty = qty + 1
            let newTotalPrice = dealInfo.data.purchasePrice * newQty
            let newPaymentPrice = newTotalPrice
            props.setQty(newQty)
            clearCode()
            props.setTotalPrice(newTotalPrice.toFixed(2))
            props.setPaymentPrice(newPaymentPrice.toFixed(2))
        }
    }

    const verifyCode = async () => {
        let { contactInfo, qty, setDiscountCampaign, setDiscountCodeReserved } = props
        setIsLoading(true)
        let code = getValues("promoCode")
        let data = {
            "dealId": props.dealId,
            "userId": contactInfo.userId,
            "code": code,
            "totalSpend": props.totalPrice,
            "qty": qty
        }

        try {
            let response = await verifyDiscountCode(data)
            if (response.data) {
                const error = response.data.error;
                if (!error && response.data.discountCampaign) {
                    const discountCampaign = response.data.discountCampaign;
                    let obj = { userId: contactInfo.userId, dealId: props.dealId, campaignId: discountCampaign.campaignId, qty: 1 }
                    let reserveResponse = await reserveDiscountCode(obj)
                    const newDiscountCodes = reserveResponse?.data ? JSON.parse(reserveResponse?.data) : null
                    setDiscountCodeReserved(newDiscountCodes?.codes[0])
                    setDiscountCampaign(discountCampaign)
                    calculateAmountPayable(discountCampaign)
                    setIsLoading(false)
                } else {
                    setError('promoCode', { type: 'custom', message: error });
                    setIsLoading(false)
                }
            }
        } catch (err) {
            console.error('Error _onVerifyCodeClicked ', err);
            setError('promoCode', { type: 'custom', message: 'Error getting discount code' });
            setIsLoading(false)
        }
        setValue('promoCode', code);
    }

    const clearCode = async () => {
        let { contactInfo, discountCampaign, setDiscountCampaign, setDiscountCodeReserved, discountCodeReserved } = props
        setDiscountCampaign(null);
        setDiscountCodeReserved(null)
        calculateAmountPayable(null)
        setValue('promoCode', '');
        clearErrors('promoCode');
        if (discountCampaign) {
            let obj = { userId: contactInfo.userId, campaignId: discountCampaign.campaignId, discountCodes: discountCodeReserved.toString() }
            let res = await releaseDiscountCode(obj);
        }

    }

    const calculateAmountPayable = (discountCampaign) => {
        let totalAmount = props.totalPrice;
        let _discountAmount = Number(discountCampaign?.discountAmountComputed) || 0
        totalAmount = totalAmount - _discountAmount;
        props.setDiscountAmt(_discountAmount.toFixed(2))
        props.setPaymentPrice(totalAmount.toFixed(2))
    }
    return (
        <React.Fragment>
            <Row className='justify-content-center'>
                <Col>
                    <Form onChange={(e) => props.onChange(e)} onSubmit={e => { e.preventDefault() }}>
                        <fieldset disabled={props.isDisabled}>
                            <CardContainer style={props.styling ?? { backgroundColor: "#fff" }}>
                                {props.step === "payment" ?
                                    <div style={{ margin: 10 }}>
                                        <SubTitle className='m-1'>{props.eventInfo?.data?.name + ": " + props.dealInfo?.data?.promotion_caption}</SubTitle>
                                        <Form.Label style={{ marginInline: 5 }}>{props.dealInfo?.data?.display_merchant_name}</Form.Label>
                                    </div>
                                    : null}
                                <CardBody>
                                    {props.type === 'bundle' ? null :
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Form.Label>Quantity</Form.Label>
                                                {/* {props.type === 'vip' || props.type === 'free' || props.step === 'payment' ? null :
                                                    <div style={{ position: "absolute" }}>
                                                        <div style={{ color: "#6B6C7E", fontSize: 10 }}> {"Min. " + (props.dealInfo?.data?.minQtyTx ? props.dealInfo?.data?.minQtyTx : 1) + " and max. " + props.dealInfo?.data?.purchaseLimitPerCustomer + " quantities per transaction"} </div>
                                                    </div>
                                                } */}
                                            </Col>
                                            {props.step === 'payment' ?
                                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <PriceLabel>{props.qty}</PriceLabel>
                                                </Col>
                                                :
                                                (props.isQtyFixed ?
                                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                                            <Form.Label style={{ backgroundColor: "#F1F2F5", borderRadius: 8, paddingInline: 32, paddingBlock: 5, marginTop: 5 }}>{props.qty}</Form.Label>
                                                        </Col>
                                                    :
                                                    <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                                        <MinusIcon onClick={() => decreaseQty()} />
                                                        <Form.Label style={{ backgroundColor: "#F1F2F5", borderRadius: 8, paddingInline: 12, paddingBlock: 5, marginTop: 5 }}>{props.qty}</Form.Label>
                                                        <PlusIcon onClick={() => increaseQty()} />
                                                    </Col>
                                                )
                                            }
                                        </Row>
                                    }
                                    {props.type === 'bundle' ? null : <Underline className='mb-3' />}
                                    <Row>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <Form.Label>Total Price</Form.Label>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <PriceLabel>{"S$" + props.totalPrice}</PriceLabel>
                                        </Col>
                                    </Row>
                                    <Underline className='mb-3' />
                                    {props.step === "payment" ?
                                        props.type === 'free' ? null :
                                        <Row>
                                            {props.type === 'vip' ?
                                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <Form.Label>Promo Code*</Form.Label>
                                                    <div style={{ marginBottom: 5 }}>
                                                        <div style={{ color: "#6B6C7E", fontSize: 10 }}> {'Enter "Promo Code" to complete purchase.'} </div>
                                                    </div>
                                                </Col>
                                                :
                                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <Form.Label>Promo Code</Form.Label>
                                                </Col>}

                                            <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginBottom: errors.promoCode ? 10 : 0 }}>
                                                <div>
                                                    <InputGroup style={{ maxWidth: 200 }}>
                                                        <InputGroup.Text style={{ backgroundColor: "#F4F5F6", borderWidth: 0 }}>
                                                            <CouponIcon />
                                                        </InputGroup.Text>
                                                        {props.discountCampaign?.code ?
                                                            <Form.Control
                                                                style={{ color: "#2AD2C9", backgroundColor: "#F4F5F6", borderWidth: 0 }}
                                                                type="text"
                                                                disabled={true}
                                                                value={props.discountCampaign?.code}
                                                            /> :
                                                            <Form.Control
                                                                style={{ backgroundColor: "#F4F5F6", borderWidth: 0 }}
                                                                type="text"
                                                                name="promoCode"
                                                                placeholder="Enter Promo Code"
                                                                className={errors.promoCode && "error"}
                                                                {...register("promoCode")}
                                                            />
                                                        }
                                                        {getValues("promoCode").length > 0 || props.discountCampaign?.code ?
                                                            <InputGroup.Text style={{ backgroundColor: "#F4F5F6", borderWidth: 0 }} onClick={() => clearCode()}>
                                                                <CrossIcon />
                                                            </InputGroup.Text>
                                                            : null}
                                                    </InputGroup>
                                                    <div style={{ position: "absolute", marginTop: 5 }}>
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="promoCode"
                                                            render={({ message }) => <ErrMsg>{message}</ErrMsg>}
                                                        />
                                                    </div>
                                                </div>
                                                {props.discountCampaign?.code ?
                                                    <PriceLabel style={{ marginBottom: 0, marginLeft: 10 }}>{"-S$" + props.discountAmt}</PriceLabel>
                                                    :
                                                    <Form.Label onClick={() => props.isDisabled || getValues("promoCode").trim().length < 1 ? null : verifyCode()} style={{ backgroundColor: "#2AD2C9", color: "#fff", borderRadius: 6, paddingInline: 20, paddingBlock: 5, marginInline: 10, marginTop: 5 }}>Apply</Form.Label>
                                                }
                                            </Col>
                                            <Underline className='mb-3' />
                                        </Row> : null}
                                    <Row>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <Form.Label>Amount payable</Form.Label>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <PriceLabel style={{ color: "#722ED1", fontSize: 16 }}>{"S$" + props.paymentPrice}</PriceLabel>
                                        </Col>
                                    </Row>
                                </CardBody>
                                {isLoading &&
                                    <SpinnerDiv>
                                        <Spinner />
                                    </SpinnerDiv>}
                            </CardContainer>
                        </fieldset>
                    </Form>
                </Col>
            </Row>
        </React.Fragment >

    )
}

const PlusIcon = styled(AiOutlinePlusCircle)`
  color: #722ED1;
  margin-inline: 10px;
  font-size: 18px;
`
const MinusIcon = styled(AiOutlineMinusCircle)`
  color: #722ED1;
  margin-inline: 10px;
  font-size: 18px;
`
const CouponIcon = styled(HiTicket)`
  color: #D3ADF7;
  font-size: 18px;
`
const CrossIcon = styled(AiFillCloseCircle)`
  color: #272833;
  font-size: 18px;
`
export default ContactInfo