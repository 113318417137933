import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { useSelector, useDispatch } from "react-redux";
import { Container, Row} from "reactstrap"

// import components
import FreeTrialPlan from "./Components/freeTrialPlan";
import StarterPlan from "./Components/starterPlan";
import ProfessionalPlan from "./Components/professionalPlan";
import ModalPlanLimit from "./Components/modalPlanLimit";
import OldPlanOverlay from "./Components/oldPlanOverlay";

import RBAC from '../../../components/Common/rbac'

// import actions
import { setAlert } from "store/alerts/actions";
import { setBreadcrumbItems, setAmplitudeEvent, setConfig, setEnv  } from "../../../store/actions";

// import helpers
import { stripeRetrieveKey, stripeCreatePaymentLink, getConfig, getEnv } from '../../../helpers/apiHelper'

// import utils
import { openInNewTab  } from "../../../utils";
import { FREE_PLAN, STARTER_PLAN_1, STARTER_PLAN_2, STARTER_PLAN_3, PROFESSIONAL_PLAN, WAIVER_PLAN } from "../../../utils/constants";

// import graphql
import { getUser } from '../../../helpers/GraphQL/user'

const ManagePlans = () => {
    const dispatch = useDispatch()
    const cmsConfig = useSelector(state => state.Config)
    const selectedMerchant = useSelector(state => state.Merchant.selectedMerchant)
    const userStore = useSelector(state => state.User.user)
    const [priceIds, setPriceIds] = useState([])
    const [userDB, setUserDB] = useState({})
    const [stripeKey, setStripeKey] = useState('')
    const [paymentLinks, setPaymentLinks] = useState({})
    const [isProcessing, setIsProcessing] = useState(true)
    const [showSetupModal, setShowSetupModal] = useState(false) 
    const [isOldPlanShow, setIsOldPlanShow] = useState(false)
    const allPlans = [FREE_PLAN, STARTER_PLAN_1, STARTER_PLAN_2, STARTER_PLAN_3, PROFESSIONAL_PLAN]
    const exemptedPlans = [WAIVER_PLAN]
    const oldPlanAlert =  (
        <span>Oh no, your current plan <b>has been phased out</b>! Explore new plans below or view your old entitlements <a href="#" onClick={() => setIsOldPlanShow(!isOldPlanShow)}><b>here</b></a>.</span>
    )
    const breadcrumbItems = [
        { title: "CardsPal", link: "#" },
        { title: selectedMerchant.display_merchant_name, link: "#" },
        { title: "Settings", link: "#" },
        { title: "Manage Plans", link: "#" },
    ]

    useEffect(() => {
        dispatch(setBreadcrumbItems('Manage Plans', breadcrumbItems))
    },[selectedMerchant])

    useEffect(() => {
        if (!cmsConfig || !cmsConfig.cmsv2) return
        setPriceIds(cmsConfig.cmsv2.stripePriceIds[cmsConfig.env])
    }, [cmsConfig])
    
    useEffect(async () => {
        if (!userStore) {
            dispatch(setAlert('Error loading this page. Please contact Administrator', 'danger'))
        } else if (![...allPlans,...exemptedPlans].includes(userStore.subPlan)) {
            dispatch(setAlert(oldPlanAlert, 'danger'))
            await getStripeKey()
        } else {
            dispatch(setAlert('Initializing...Please wait while we finish getting updates', 'info'))
            await getStripeKey()
        }
    }, [userStore])

    useEffect(async () => {
        if (!userStore) return
        // we should get the latest user object in case the plan has been updated
        const user = await getUserByEmail(userStore.email)
        setUserDB({ ...user })              // update the local variable
        // dispatch(setUser({...user}))         // update the store
        localStorage.setItem('user', JSON.stringify(user)) // update the local storage when user tries to do hard refresh so we load again the store
    }, [userStore])

    useEffect(async () => {
        if (!userStore) return
        if (!stripeKey || !userDB || Object.keys(userDB).length===0 || !selectedMerchant || Object.keys(selectedMerchant).length===0) return

        await createLinks()
        setIsProcessing(false)
    }, [stripeKey])

    useEffect(async () => {
        await getCMSConfig()
      }, [])
    
    async function getCMSConfig() {
        try {
            if (!cmsConfig || !cmsConfig.cmsv2) {
            
            const config = await getConfig()

            const env = getEnv()
            
            dispatch(setConfig(config))
            dispatch(setEnv(env))
            }
        } catch(error) {
            console.error('error on retrieving config file', error)
            dispatch(setAmplitudeEvent('CMS Manage Plans - get cms config error', {'details': JSON.stringify(error)} ))
        }
    }

    async function getUserByEmail(email) {
        try {
            return await getUser(email)
        } catch(error) {
            dispatch(setAlert(error, 'danger'))
        }
    }

    async function createLinks() {
        try {
            const linkObj = await stripeCreatePaymentLink({
                stripeKey,
                email: userDB.email,
                priceIds: {
                    [STARTER_PLAN_1]: priceIds[STARTER_PLAN_1], //'price_1KdsPhCzYifcXYvg1lJFviID',
                    [STARTER_PLAN_2]: priceIds[STARTER_PLAN_2], //'price_1KdsPGCzYifcXYvgQ31dtnIW',
                    [STARTER_PLAN_3]: priceIds[STARTER_PLAN_3], //'price_1KdsOhCzYifcXYvglwZfktJD',
                    [PROFESSIONAL_PLAN]: priceIds[PROFESSIONAL_PLAN] //'price_1KdsO0CzYifcXYvg10LZp8g2'
                }
            })
            setPaymentLinks({...linkObj})
            if ([...allPlans,...exemptedPlans].includes(userStore.subPlan)) dispatch(setAlert(''))
            else dispatch(setAlert(oldPlanAlert, 'danger'))

        } catch (error) {
            console.error('error on create link ', error)
            dispatch(setAlert(`Error loading this page. Please report to admin immediately.`, 'danger'))
            dispatch(setAmplitudeEvent('CMS Manage Plans - create stripe link error', {'details': JSON.stringify(error)} ))
        }
    }


    const starterPlan = () => {
        setShowSetupModal(true)
    }

    async function getStripeKey() {
        const key = await stripeRetrieveKey()
        if (!key) {
            dispatch(setAlert(`Error loading this page. Please report to admin immediately.`, 'danger'))
        } else {
            setStripeKey(key.stripeSecret)
        }
        return key?.stripeSecret
    }

    const selectedStarterPlan = async (selected) => {
        try {
            let url
            switch (selected){
                case STARTER_PLAN_1:
                    url = paymentLinks[STARTER_PLAN_1].url
                    break
                case STARTER_PLAN_2:
                    url = paymentLinks[STARTER_PLAN_2].url
                    break
                case STARTER_PLAN_3:
                    url = paymentLinks[STARTER_PLAN_3].url
                    break
            }

            if (!url) return 

            openInNewTab(url)
        } catch (error) {
            console.error(error)
            dispatch(setAmplitudeEvent('CMS Manage Plans - selected starter plan error', {'details': JSON.stringify(error)} ))
        }
    }

    const professionalPlan = () => {
        try {
            openInNewTab(paymentLinks[PROFESSIONAL_PLAN].url)
        } catch(error) {
            console.error(error)
            dispatch(setAmplitudeEvent('CMS Manage Plans - open profession plan error', {'details': JSON.stringify(error)} ))
        }
    }

    const toggleShowSetupModal = (e, selectedPlan) => {
        setShowSetupModal(!showSetupModal)
        
        if(e === 'proceed') {
            selectedStarterPlan(selectedPlan)
        }
      }

      useEffect(() => {
        let _ampTrack = {
            'merchant id': selectedMerchant.merchant_id,
            'merchant name':  selectedMerchant.merchant_name,
            'mainCat': selectedMerchant.mainCat,
            'current plan': userStore.subPlan
          }
       dispatch(setAmplitudeEvent('CMS Manage Plans - page load', {..._ampTrack} ))
      }, [])
      

    return (
        <React.Fragment>
            <RBAC merchantId={selectedMerchant.merchant_id} roles={['admin']}>
                <MetaTags>
                    <title>Manage Plans</title>
                </MetaTags>
                
                <div>
                    <Container>
                        <Row xs={1} sm={1} md={2} lg={3} xl={3} className="mt-3">
                            <div>
                                <FreeTrialPlan subPlan={userDB.subPlan}/>
                            </div>
                            <div>
                                <StarterPlan isProcessing={isProcessing} subPlan={userDB.subPlan} getStarted ={()=> starterPlan()}/>
                            </div>
                            <div>
                                <ProfessionalPlan isProcessing={isProcessing} subPlan={userDB.subPlan} getStarted ={()=> professionalPlan()}/>
                            </div>
                        </Row>
                    </Container>
                    
                    {isOldPlanShow ? <OldPlanOverlay modalImageStatus={isOldPlanShow} showForm={(state) => setIsOldPlanShow(state)}/> : null}
                    <ModalPlanLimit
                        toggleModal={(e, selected) => toggleShowSetupModal(e, selected)}
                        showModal={showSetupModal}
                    />
                </div>
            </RBAC>
        </React.Fragment>
    )

}


export default ManagePlans;