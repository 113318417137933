/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from "react"
import MetaTags from 'react-meta-tags';
import { useSelector, useDispatch } from "react-redux";
import { Card, CardBody } from "reactstrap"
import { setBreadcrumbItems, setAlert } from "../../../store/actions";
import FAQ from "components/Common/faq";

const Faq = () => {
    const dispatch = useDispatch()
    const selectedMerchant = useSelector(state => state.Merchant.selectedMerchant)

    const breadcrumbItems = [
        { title: "CardsPal", link: "#" },
        { title: selectedMerchant.display_merchant_name, link: "#" },
        { title: "Support", link: "#" },
        { title: "FAQ", link: "#" },
    ]

    useEffect(() => {
        dispatch(setBreadcrumbItems('FAQ', breadcrumbItems))
        dispatch(setAlert(''))
    },[selectedMerchant])

    return (
        <React.Fragment>

            <MetaTags>
                <title data-testid="pageTitle">FAQ</title>
            </MetaTags>
            <Card>
                <CardBody>
                    <FAQ/>
                </CardBody>
            </Card>
            
        </React.Fragment>
    )

}


export default Faq;