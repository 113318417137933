import React, { useEffect, useState } from "react"
import { connect } from "react-redux";
import { Card, CardImg, Modal, ModalHeader } from "reactstrap"

const ImageOverlay = (props) => {
    const [promotion, setPromotion] = useState({})

    useEffect(() => {
        setPromotion(props.promotion)
    }, [props.promotion])

    return (
        <Modal
            size="lg"
            isOpen={props.modalImageStatus}
            toggle={() => {
                props.showForm(false, 'imageOverlay')
            }}
            centered={true}
            backdrop={true}
        >
            <ModalHeader toggle={() => props.showForm(false, 'imageOverlay')}>{promotion.promotion_caption}</ModalHeader>
            <Card>
                <CardImg
                    alt=""
                    src={promotion.image_url}
                    width="100%"
                />
            </Card>
        </Modal>
    )
}

export default connect(null, {})(ImageOverlay);