import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect } from "react"
import { Row, Col, Alert, Card, CardBody, Container, Spinner } from "reactstrap"
import { connect, useSelector, useDispatch } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { userForgetPassword, setAmplitudeEvent } from "../../store/actions"
import logoLightPng from "../../assets/images/logo-onboarding.png"
import { getAmplifyBackend } from "helpers/authHelper";
import { amplitudeUserProperty, openInNewTab } from 'utils';

const ForgetPasswordReset = props => {
  const [otp, setOtp] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [otpCountDown, setOtpCountDown] = useState(30)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const amplifyBackend = getAmplifyBackend()
  const useSelEmail = useSelector(state => state.ForgetPassword)
  const dispatch = useDispatch()

  const handleValidSubmit = async() => {
    try{
    setLoading(true)
    let response = await amplifyBackend.forgetPasswordSubmit(useSelEmail.email, otp, newPassword)
    if (response === 'SUCCESS') {
      props.history.push('/login')
      dispatch(setAmplitudeEvent('CMS reset password page - reset button', {'registration status': 'Y'})) 
      amplitudeUserProperty('registration status', 'Y')
    }else {
      setError(response.message)
      setLoading(false)
      dispatch(setAmplitudeEvent('CMS reset password page - reset button', {'registration status': 'Pending'})) 
      amplitudeUserProperty('registration status', 'Pending')
   }
  } catch(error) {
    setLoading(false)
    setError('Error submitting this form. Please report to the Administrator.')
    dispatch(setAmplitudeEvent('Error - forget password reset', { 'details': error }))
  }
  }

  useEffect(() => {
    const timer = otpCountDown > 0 && setInterval(() => setOtpCountDown(otpCountDown - 1), 1000);
     return () => clearInterval(timer);
    }, [otpCountDown]);

    useEffect(() => {
      window.onbeforeunload = function() {
          return true;
      };
  
      return () => {
          window.onbeforeunload = null;
      };
  }, []);

  const renderResend = () =>{
    return(
      <div className='m-0'>
        {otpCountDown > 0?
        <span>
          OTP Resend ({otpCountDown}s)
        </span>:  
        <span>
          OTP <Link to='#' onClick={()=>resendCode()}>Resend</Link>
        </span>}
      </div>
    )
  }

  const resendCode = async() =>{
    setOtpCountDown(30)
    await amplifyBackend.forgetPassword(useSelEmail.email)
    setError(null)
    dispatch(setAmplitudeEvent('CMS reset password page - resend code button', {'registration status': 'Y'})) 
    amplitudeUserProperty('registration status', 'Y')
  }

  const gotoSignIn = () =>{
    dispatch(setAmplitudeEvent('CMS reset password page - sign in here button', {'registration status': 'Y'})) 
    amplitudeUserProperty('registration status', 'Y')
  }

  return (
    <React.Fragment>
      <MetaTags>
      <title>Reset Password | CardsPal Business</title>
      </MetaTags>
      <div className="account-pages login-background" >
        <div style={{ position:'relative' }}>
          <a href='#' onClick={()=>openInNewTab('https://cardspal.com/wp-content/uploads/2022/03/CardsPal-Merchant-Guide_Mar-2022.pdf')} className="viewUserGuide">View merchant guide</a>
        </div>
        <Container>
          <Row className="justify-content-center vertical-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden mt-5">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-2">
                    <Link to="/" className="d-block auth-logo">
                      <img src={logoLightPng} alt="" height="50" className="auth-logo-dark" />
                    </Link>
                  </h3>
                  <div className="p-1">
                  <h4 className="text-muted text-center mb-3">Reset Password</h4>
                    <Alert color="info">
                        <p className="mb-0">
                          Enter your OTP and new password.
                        </p>
                    </Alert>
                    {typeof error === "string" ? (
                        <Alert color="danger">{error}</Alert>
                      ) : null}
                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      <div className="mb-3">
                        <AvField
                          name="otp"
                          label={renderResend()}
                          className="form-control"
                          type="text"
                          validate={{number:true}}
                          required
                          errorMessage="This field is required"
                          value={otp}
                          onChange={(e)=>setOtp(e.target.value)}
                          autoComplete = 'off'
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="newPassword"
                          label="New password"
                          className="form-control"
                          type="password"
                          required
                          errorMessage="This field is required"
                          value={newPassword}
                          onChange={(e)=>setNewPassword(e.target.value)}
                          autoComplete = 'off'
                        />
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                        {loading?<Spinner size="sm" style={{"marginRight":"10px"}}/>:null}
                          <button disabled={loading}
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                           Reset
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Remember It ?{" "}
                  <Link to="login" className="text-primary" onClick={()=>gotoSignIn()}>
                    Sign In Here
                  </Link>{" "}
                </p>
                <p>
                  <Link to="#" onClick={()=>openInNewTab('https://cardspal.com/support/')}><span>Support Center</span></Link><span className="separator">|</span>
                  <Link to="#" onClick={()=>openInNewTab('https://cardspal.com/merchant_terms/')}><span>Merchant T&C</span></Link><span className="separator">|</span>
                  <Link to="#" onClick={()=>openInNewTab('https://cardspal.com/terms-of-use/')}><span>Terms of Use</span></Link><span className="separator">|</span>
                  <Link to="#" onClick={()=>openInNewTab('https://cardspal.com/privacy-policy/')}><span>Privacy Policy</span></Link>
                </p>
                <p>
                © {new Date().getFullYear()} CardsPal Business
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordReset.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func
}

const mapStatetoProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword
  return { forgetError, forgetSuccessMsg }
}

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(ForgetPasswordReset)
)
