import React, { Component } from 'react'
import { Modal, Button } from "react-bootstrap"
import { Subject } from 'rxjs'

// The Main Subject/Stream to be listened on.
const subject = new Subject()
// This function is used to publish data to the Subject via next().
export const publish = (data) => subject.next(data)
export class CMSModal extends Component {

    // Used for unsubscribing when our component unmounts
    unsub = null

    constructor(props) {
        super(props)

        this.state = {
        }

        this.unsub = subject.subscribe(data => this.setState({ ...data, show: true }))
    }

    async componentDidMount() {
    }

    componentWillUnmount() {
        this.unsub.unsubscribe()
    }

    onClose() {
        this.setState({
            show: false
        })

        if (this.state.onClose) {
            this.state.onClose()
        }
    }

    onConfirm() {
        this.setState({
            show: false
        })

        if (this.state.onConfirm) {
            this.state.onConfirm()
        }
    }

    onCancel() {
        this.setState({
            show: false
        })

        if (this.state.onCancel) {
            this.state.onCancel()
        }
    }

    render() {
        return <Modal show={this.state.show} enforceFocus={false} onHide={() => this.onClose()} centered >
            <Modal.Body>
                {this.state.msg}
            </Modal.Body>

            {this.state.mode === 'confirm' ?
                <Modal.Footer>
                    <Button onClick={() => this.onConfirm()}>Yes</Button>
                    <Button onClick={() => this.onCancel()}>No</Button>
                </Modal.Footer>
                :
                <Modal.Footer>
                    <Button onClick={() => this.onClose()}>Got It!</Button>
                </Modal.Footer>
            }
        </Modal>
    }
}
