/* eslint-disable no-useless-escape */
import { API } from 'aws-amplify'
import awsExports from '../aws-exports'
import amplitude from 'amplitude-js';
import { parseQueryString } from '../utils';

const https = require('https')

export const getEnv = () => {
  // have to retrieve amplify env by parsing from resource names
  const REGEX = /.*-(\w+)/
  const env = awsExports.aws_user_files_s3_bucket.match(REGEX)[1]
  return env
}

export const getConfig = async () => {
  try {
    const env = getEnv()
    const config = (await(await fetch(`https://assets.${env}.cardspal.com/.config/cms.config.json?timestamp=${Date.now()}`)).json())
    return config
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - getConfig', { 'details': error })
    console.error('error on getConfig', error)
    throw JSON.stringify(error)
  }
}

export const invalidateCloudFrontCache = async (path) => {
  try {
    const regex = /https:\/\/[^\/]*(\/.*)\./
    const cfPath = `${regex.exec(path)[1]}*`

    const invalidationResult = await API.post('cardspalcmsv2api', '/cloudfront/cache/invalidate', {
      body: {
        path: cfPath
      }
    })
    return invalidationResult
  } catch(error) {
    amplitude.getInstance().logEventWithGroups('Error - invalidateCloudFrontCache', { 'details': error })
  }
}

export const stripeCreateOnboardingLink = async (body) => {
  try {
    const res = await API.post('cardspalcmsv2api', '/stripe/account/create', { body })
    if (res.error) {
      throw JSON.parse(res.error)
    } else {
      return res.data
    }
} catch(error) {
  amplitude.getInstance().logEventWithGroups('Error - stripeCreateOnboardingLink', { 'details': error })
  console.error('stripeCreateOnboardingLink ', error)
}

}

export const stripeRetrieveAccount = async (body) => {
  try {
  const res = await API.post('cardspalcmsv2api', '/stripe/account/retrieve', { body })

  if (res.error) {
    throw JSON.parse(res.error)
  } else {
    return res.data
  }
} catch(error) {
  amplitude.getInstance().logEventWithGroups('Error - stripeRetrieveAccount', { 'details': error })
  console.error('stripeRetrieveAccount ', error)
}

}

export const stripeCreateLoginLink = async (body) => {
  try {
  const res = await API.post('cardspalcmsv2api', '/stripe/account/create/login', { body })
  
  if (res.error) {
    throw JSON.parse(res.error)
  } else {
    return res.data
  }
} catch(error) {
  amplitude.getInstance().logEventWithGroups('Error - stripeCreateLoginLink', { 'details': error })
  console.error('stripeCreateLoginLink ', error)
}
}

export const stripeRetrieveKey = async (body) => {
  try {
  const res = await API.get('cardspalcmsv2api', '/stripe/key/retrieve', { body })

  if (res.error) {
    throw JSON.parse(res.error)
  } else {
    return res.data ? res.data.stripeKey : null
  }
} catch(error) {
  amplitude.getInstance().logEventWithGroups('Error - stripeCreateLoginLink', { 'details': error })
  console.error('stripeRetrieveKey ', error)
}
  
}

export const stripeCreatePaymentLink = async(body) => {
  try {
    const res = await API.post('cardspalcmsv2api', '/stripe/payment/create-link', { body })

    if (res.error) {
      throw JSON.parse(res.error)
    } else {
      return res.data || null
    }
  } catch(error) {
    amplitude.getInstance().logEventWithGroups('Error - stripeCreatePaymentLink', { 'details': error })
    console.error('stripeCreatePaymentLink', error)
  } 
}

export const sendEmail = async (body) => {
  try {
  const res = await API.post('cardspalcmsv2api', '/email/sender', { body })
  // console.log('sendEmail ',{res})
  if (res.error) {
    throw JSON.parse(res.error)
  } else {
    return res.data || null
  }
} catch(error) {
  amplitude.getInstance().logEventWithGroups('Error - sendEmail', { 'details': error })
  console.error('sendEmail', error)
} 
}


export const getAddressSuggestion = async (params) => {
  try {
  const res = await API.get('cardspalcmsv2api', '/googlemap/address/autocomplete', { queryStringParameters: { ...params } })
  if (res.error) {
    throw JSON.parse(res.error)
  } else {
    return JSON.parse(res.data) || null
  }
} catch(error) {
  amplitude.getInstance().logEventWithGroups('Error - getAddressSuggestion', { 'details': error })
  console.error('getAddressSuggestion', error)
} 
}

export const getLatLng = async (params) => {
  // IMPT :: should not have body object, otherwise api will throw InvalidSignatureException
  // http://5.9.10.113/48086977/aws-amplify-react-native-throwing-403-invalidsignatureexception-when-passing-da
  try {
  const result = await API.get('cardspalcmsv2api', '/googlemap/address/geocode', { queryStringParameters: {...params} })

  if (result.error) {
    throw JSON.parse(result.error)
  } else {
    return JSON.parse(result.data) || null
  }

  // if (result.status === 'OK') {
  //   const loc = result.results[0].geometry.location
  //   console.log('loc', loc)
  //   return loc
  // }
} catch(error) {
  amplitude.getInstance().logEventWithGroups('Error - getLatLng', { 'details': error })
  console.error('getLatLng', error)
} 
}

export const getWebFormGroup = async (category) => {
  try {
    const res = await API.get('cardspalcmsv2api', `/webform/${category}/group`)
    if (res.error) {
      throw JSON.parse(res.error)
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - getWebFormGroup', { 'details': error })
    console.error('getWebFormGroup ', JSON.stringify(error))
    return { error: error.message }
  }
}

export const getPeopleInWebForm = async (webFormId, search) => {
  try {
    const queryString = parseQueryString(search)
    const {hash, userId} = queryString
    const res = await API.get('cardspalcmsv2api', `/webform/users/${webFormId}?hash=${hash}&userId=${userId}`)
    
    if (res.error) {
      throw JSON.parse(res.error)
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - getPeopleInGroup', { 'details': error })
    console.error('getPeopleInGroup ', error)
    return { error: error.message }
  }
}

export const checkDuplicateEmail = async (webFormId, emailList, eventsId) => {
  try {
    let queryString = ''
    if (eventsId) {
      queryString = `eventsId=${eventsId}`
    }
    if (emailList) {
      console.log('emailList ', emailList)
      queryString = `${queryString}&emailList=${encodeURIComponent(JSON.stringify(emailList))}`
    }
    const res = await API.get('cardspalcmsv2api', `/webform/users/check-duplicate/${webFormId}?${queryString}`)
    
    if (res.error) {
      throw new Error(res.error)
    } else {
      if ('isDuplicate' in res) {
        // if this is available, means the computation is done in the server to reduce response payload
        return res.isDuplicate || false
      } else {
        const data = JSON.parse(res.body)
        for(let x=0; x<data.length;x++){
          if(data[x].status ==='1'){
            if (data[x].email){
              if(emailList.includes(data[x].email)){
              return true
              }
            }
            if (data[x].participants){
              for(let y=0; y<data[x].participants.length;y++){
                  if(emailList.includes(data[x].participants[y].email)){
                    return true
                  }
              }
            }
          }
        }
      
        return false
      }
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - checkDuplicateEmail', { 'details': error })
    console.error('checkDuplicateEmail ', error)
    return { error: error.message }
  }
}

export const getCanPurchase = async (values) => {
  try {
    const { userId, dealId, qty } = values
    let params = new URLSearchParams(values);
    let queryString = params.toString();
    const res = await API.get('cardspalcmsv2api', `/users/verify/purchase?${queryString}`)
    if (res.error && typeof(res.error) === 'object') {
      throw JSON.parse(res.error)
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - getCanPurchase', { 'details': error })
    console.error('getCanPurchase ', error)
    return { error: error.message }
  }
}

export const verifyFamilyDay = async (values) => {
  try {
    const { bankId, email, phoneNumber, webFormId, dealId, userId } = values
    let queryString = `webFormId=${webFormId}&dealId=${dealId}&email=${email}&bankId=${bankId}&phoneNumber=${encodeURIComponent(phoneNumber)}&userId=${userId}`
    const res = await API.get('cardspalcmsv2api', `/familyday/verify/purchase?${queryString}`)
    if (res.error && typeof (res.error) === 'object') {
      throw JSON.parse(res.error)
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - getCanPurchase', { 'details': error })
    console.error('getCanPurchase ', error)
    return { error: error.message }
  }
}

export const verifyDnD = async (values) => {
  try {
    let params = new URLSearchParams(values);
    params.set('phoneNumber', encodeURIComponent(values.phoneNumber));
    let queryString = params.toString();
    console.log('verifyDnD ', queryString)
    const res = await API.get('cardspalcmsv2api', `/dnd/verify/purchase?${queryString}`)
    if (res.error) {
      console.error('error on verifyDnD ', res)
      amplitude.getInstance().logEventWithGroups('Error - DnD getCanPurchase', { 'details': res.error })
    } 
    return res
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - getCanPurchase', { 'details': error })
    console.error('error on verifyDnD ', error)
    return { error: error.message }
  }
}

export const getDealInfo = async(dealId) => {
  try {
    const res = await API.get('cardspalcmsv2api', `/deal/${dealId}`)
    
    if (res.error) {
      throw JSON.parse(res.error)
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - getDealInfo', { 'details': error })
    console.error('getDealInfo ', error)
    return { error: error.message }
  }
}

export const reserveVoucher = async (values, forceReservation= false) => {
  try {
   const res = await API.post('cardspalcmsv2api', '/vouchers/reserve', {
      body: {...values, forceReservation}
    })
    return res
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - reserveVoucher', { 'details': JSON.stringify(error) })
    console.error('reserveVoucher ', error)
    return { error: error.message }
  }
}

export const B2BRegistration = async (values) => {
  try {
    const res = await API.post('cardspalcmsv2api', '/vouchers/confirm', {
      body: values
    })
    if (res.error) {
      throw JSON.parse(res.error)
    } else {
      return res
    }
    
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - B2BRegistration', { 'details': error })
    console.error('B2BRegistration ', error)
    return { error: error.message }
  }

}

export const createWebformData = async (values) => {
  try {
    const res = await API.put('cardspalcmsv2api', '/webform/create', {
      body: values
    })
    if (res.error) {
      throw JSON.parse(res.error)
    } else {
      return res
    }
    
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - createWebformData', { 'details': error })
    console.error('createWebformData ', error)
    return { error: error.message }
  }
}

export const retrieveWebformData = async (formPK) => {
  try {
    const res = await API.get('cardspalcmsv2api', `/webform/retrieve/${formPK}`)
    if (res.error) {
      throw JSON.parse(res.error)
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - retrieveWebformData', { 'details': error })
    console.error('retrieveWebformData ', error)
    return { error: error.message }
  }
}

export const updateAbandonCartLog = async (action,values) => {
  try {
    const res = await API.put('cardspalcmsv2api', `/abandon-cart/log/${action}`,{body: values})
    if (res.error) {
      throw res.error
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - updateAbandonCartLog', { 'details': error })
    console.error('updateAbandonCartLog ', error)
    return { error: error.message }
  }
}

export const B2BValidate = async (values) => {
  try {
    const res = await API.post('cardspalcmsv2api', `/b2b/validate`, {
      body: values
    })
    if (res.error) {
      throw JSON.parse(res.error)
    } else {
      return res
    }
    
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - B2BValidate', { 'details': error })
    console.error('B2BValidate ', error)
    return { error: error.message }
  }
}

export const createAppUser = async (params) => {
  try {
    const res = await API.get('cardspalcmsv2api', '/appUser/createUser', { queryStringParameters: { ...params } })
    if (res.error) {
      //console.log(res.error)
      throw JSON.parse(res.error)
    } else {
      //console.log(res)
      return res
    }

  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - createAppUser', { 'details': error })
    console.error('createAppUser ', error)
    return { error: error.message }
  }
}


export const retrieveGKey = async () => {
  try {
      const res = await API.get('cardspalcmsv2api', `/google/key/retrieve`)
    if (res.error) {
      throw JSON.parse(res.error)
    } else {
      return res
    }
    
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - retrieveGKey', { 'details': error })
    console.error('retrieveGKey ', error)
    return { error: error.message }
  }
}

export const cancelReserveVoucher = async (values) => {
  try {
    const res = await API.post('cardspalcmsv2api', '/vouchers/release', {
      body: values
    })
    if (res.error) {
      throw JSON.parse(res.error)
    } else {
      return res
    }
    
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - cancelReserveVoucher', { 'details': error })
    console.error('cancelReserveVoucher ', error)
    return { error: error.message }
  }

}

export const reserveDiscountCode = async (values) => {
  try {
   const res = await API.post('cardspalcmsv2api', '/discountCode/reserve', {
      body: values
    })
    if (res.error) {
      throw JSON.parse(res.error)
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - reserveDiscountCode', { 'details': error })
    console.error('reserveDiscountCode ', error)
    return { error: error.message }
  }
}

export const releaseDiscountCode = async (values) => {
  try {
   const res = await API.post('cardspalcmsv2api', '/discountCode/release', {
      body: values
    })
    if (res.error) {
      throw JSON.parse(res.error)
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - releaseDiscountCode', { 'details': error })
    console.error('releaseDiscountCode ', error)
    return { error: error.message }
  }
}

export const verifyDiscountCode = async (values) => {
  try {
   const res = await API.post('cardspalcmsv2api', '/discountCode/verify', {
      body: values
    })
    if (res.error) {
      throw JSON.parse(res.error)
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - verifyDiscountCode', { 'details': error })
    console.error('verifyDiscountCode ', error)
    return { error: error.message }
  }
}

export const completeTransaction = async (values) => {
  try {
   const res = await API.post('cardspalcmsv2api', '/complete-transaction', {
      body: values
    })
    console.log('completeTransaction ', res)
    if (res.error) {
      throw JSON.parse(res.error)
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - completeTransaction', { 'details': error })
    console.error('completeTransaction ', error)
    return { error: error.message }
  }
}

export const createPaymentIntent = async (values) => {
  try {
    //console.log('createPaymentIntent ', {values})
    const res = await API.post('cardspalcmsv2api', '/payment-intent', {
      body: values
    })
    
    if (res.error) {
      throw res.error
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - createPaymentIntent', { 'details': error })
    console.error('error on createPaymentIntent helper ', error)
    return { error }
  }
}


export const getTransactionById = async (id) => {
  try {
    //console.log('getTransactionById ', {id})
    const res = await API.get('cardspalcmsv2api', '/transactions/' + id)
    //console.log('getTransactionById ', res)
    if (res.error) {
      throw res.error
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - getTransactionById', { 'details': error })
    console.error('error on getTransactionById helper ', error)
    return { error }
  }
}

export const postToActivEdge = async (values) => {
  try {
    //console.log('createPaymentIntent ', {values})
    const res = await API.post('cardspalcmsv2api', '/activedge/users', {
      body: values
    })
    
    if (res.error) {
      throw res.error
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - postToActivEdge', { 'details': error })
    console.error('error on postToActivEdge helper ', error)
    return { error }
  }
}

// depreciated
export const getAllEvents = async (merchantUniqueId) =>{
  try {
    const res = await API.get('cardspalcmsv2api', `/events/${merchantUniqueId}`)
    
    if (res.error) {
      throw JSON.parse(res.error)
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - getAllEvents', { 'details': error })
    console.error('getAllEvents ', error)
    return { error: error.message }
  }
}

// depreciated
// export const getEventInfo = async(eventId) => {
//   try {
//     const res = await API.get('cardspalcmsv2api', `/event/${eventId}`)
    
//     if (res.error) {
//       throw JSON.parse(res.error)
//     } else {
//       console.log('getEventInfo ', res)
//       return res
//     }
//   } catch (error) {
//     amplitude.getInstance().logEventWithGroups('Error - getEventInfo', { 'details': error })
//     console.error('getEventInfo ', error)
//     return { error: error.message }
//   }
// }

// type can be 'events_id' or 'merchant_unique_id'
export const getEvents = async(id, type='events_id') => {
  try {
    const res = await API.get('cardspalcmsv2api', `/events?id=${id}&type=${type}`)
    if (res.error) {
      if (res.statusCode ==='404') {
        return res.error
      } else {
        throw new Error(res.error)
      }
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - getEvents', { 'details': error })
    console.error('getEvents ', error)
    return { error: error.message }
  }
}

export const verifyEventDuplicateString = async(uniqueString, eventId) => {
  try {
    const type = 'events_unique_string'
    const res = await API.get('cardspalcmsv2api', `/events?id=${uniqueString}&type=${type}&eventId=${eventId}`)
    
    if (res.error) {
      if (res.statusCode ==='404') {
        return res.error
      } else {
        throw new Error(res.error)
      }
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - verifyEventDuplicateString', { 'details': error })
    console.error('verifyEventDuplicateString ', error)
    return { error: error.message }
  }
}

export const createNewEvents = async(values) => {
  try {
    // payload structure
    // { events: {}, voucherGroups: [] }
    if (!values.events || !values.voucherGroups) {
      throw new Error('invalid_payload')
    }

    const res = await API.post('cardspalcmsv2api', `/events`, {
      body: {...values}
    })
    
    if (res.error) {
      if (res.statusCode ==='404') {
        return res.error
      } else {
        throw new Error(res.error)
      }
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - createNewEvents', { 'details': error })
    console.error('createNewEvents ', error)
    return { error: error.message }
  }
}

export const updateEvents = async(values) => {
  try {
    // payload structure
    // { events: {}, voucherGroups: [] }
    if (!values.events || !values.voucherGroups) {
      throw new Error('invalid_payload')
    }
    if (!values.events.pk) {
      throw new Error('missing_events_id')
    }
    
    const res = await API.put('cardspalcmsv2api', `/events`, {
      body: {...values}
    })
    
    if (res.error) {
      if (res.statusCode ==='404') {
        return res.error
      } else {
        throw new Error(res.error)
      }
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - updateEvents', { 'details': error })
    console.error('updateEvents ', error)
    return { error: error.message }
  }
}

export const verifyAbandonEmailPurchase = async (webFormPK) => {
  try {
    const res = await API.get('cardspalcmsv2api', `/abandon-email/verify/purchase?webFormPK=${webFormPK}`)
    if (res.error) {
      throw res.error
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - verifyAbandonEmailPurchase', { 'details': error })
    console.error('verifyAbandonEmailPurchase ', error)
    throw error
  }
}

export const reserveVoucherOnTheFly = async (values, forceReservation= false) => {
  try {
    // values = {
    //     "dealId": "9e5ddbd1-38fd-68ac-f5ee-68108906d5fd",
    //     "userId": "dennisjade@gmail.com",
    //     "numberOfVouchers": "1"
    // }
   const res = await API.post('cardspalcmsv2api', '/vouchers/reserve-on-the-fly', {
      body: {...values}
    })
    if (res.error) {
      throw res
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - reserveVoucher', { 'details': error })
    console.error('reserveVoucherOnTheFly ', error)
    return error?.response?.data?.error || error
  }
}


export const getCanPurchaseVoucherGroupDeal = async (values) => {
  try {
    const { userId, dealId, qty } = values
    const res = await API.get('cardspalcmsv2api', `/voucher-group/verify/purchase?userId=${userId}&dealId=${dealId}&qty=${qty}`)
    if (res.error) {
      throw res.error
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - getCanPurchaseVoucherGroupDeal', { 'details': error })
    console.error('getCanPurchaseVoucherGroupDeal ', error)
    return { error: error }
  }
}


// type can be 'events_id' or 'deal_id'
export const getVoucherGroupDeals = async(id, type='events_id') => {
  try {
    const res = await API.get('cardspalcmsv2api', `/voucher-group-deals?id=${id}&type=${type}`)
    
    if (res.error) {
      if (res.statusCode ==='404') {
        return res.error
      } else {
        throw new Error(res.error)
      }
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - getVoucherGroupDeals', { 'details': error })
    console.error('getVoucherGroupDeals ', error)
    return { error: error.message }
  }
}

export const updateVoucherGroupDeals = async(values) => {
  try {
    // make sure values.pk is not null
    if (!values.pk) {
      throw new Error('missing VoucherGroupDeals id to update')
    }
    
    const res = await API.put('cardspalcmsv2api', `/voucher-group-deals`, {
      body: {...values}
    })
    
    if (res.error) {
      throw new Error(res.error)
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - updateVoucherGroupDeals', { 'details': error })
    console.error('updateVoucherGroupDeals ', error)
    return { error: error.message }
  }
}

export const createNewVoucherGroupDeals = async(values) => {
  try {
    // make sure values dont have pk else it will just do an update
    if (values.id) {
      throw new Error('values should not have id')
    }

    const res = await API.post('cardspalcmsv2api', `/voucher-group-deals`, {
      body: {...values}
    })
    
    if (res.error) {
      throw new Error(res.error)
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - createNewVoucherGroupDeals', { 'details': error })
    console.error('createNewVoucherGroupDeals ', error)
    return { error: error.message }
  }
}

// type can be 'id' or 'events_id' or 'deal_id
export const getVoucherGroup = async(id, type='events_id') => {
  try {
    const res = await API.get('cardspalcmsv2api', `/voucher-group?id=${id}&type=${type}`)
    
    if (res.error) {
      if (res.statusCode ==='404') {
        return res.error
      } else {
        throw new Error(res.error)
      }
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - getVoucherGroup', { 'details': error })
    console.error('getVoucherGroup ', error)
    return { error: error.message }
  }
}

export const getAllGroups = async(category) => {
  try {
    const res = await API.get('cardspalcmsv2api', `/webform/${category}/group`)
    
    if (res.statusCode === 200) {
      return JSON.parse(res.body)
    } else {
      throw new Error(res.error)
    }
    
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - getVoucherGroup', { 'details': error })
    console.error('getVoucherGroup ', error)
    return { error: error.message }
  }
}

export const updateVoucherGroup = async(values) => {
  try {
    // make sure values.pk is not null
    if (!values.pk) {
      throw new Error('missing voucher group id to update')
    }
    
    const res = await API.put('cardspalcmsv2api', `/voucher-group`, {
      body: {...values}
    })
    
    if (res.error) {
      throw new Error(res.error)
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - updateVoucherGroup', { 'details': error })
    console.error('updateVoucherGroup ', error)
    return { error: error.message }
  }
}

export const createNewVoucherGroup = async(values) => {
  try {
    // make sure values dont have pk else it will just do an update
    if (values.id) {
      throw new Error('values should not have id')
    }

    const res = await API.post('cardspalcmsv2api', `/voucher-group`, {
      body: {...values}
    })
    
    if (res.error) {
      throw new Error(res.error)
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - createNewVoucherGroup', { 'details': error })
    console.error('createNewVoucherGroup ', error)
    return { error: error.message }
  }
}


////////////////////////// Event Ticket /////////////////////////////
export const getEventTicket = async(qs) => {
  try {
    // create a query string format from qs object
    const queryString = Object.entries(qs)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&')

    // sample query string 
    //{ dealId: xxxx}
    //{ uniqueString: xxxx}
    const res = await API.get('cardspalcmsv2api', `/events-ticket?${queryString}`)
    
    if (res.error) {
      throw new Error(res.error)
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - getEventTicket', { 'details': error })
    console.error('getEventTicket ', error)
    return { error: error.message }
  }
}

export const updateEventTicket = async(values) => {
  try {
    if (!values.ticket || !values.voucherGroupDeals) {
      throw new Error('missing_payload')
    }
    console.log('updateEventTicket', values)
    const res = await API.put('cardspalcmsv2api', `/events-ticket`, {
      body: {...values}
    })
    
    if (res.error) {
      throw new Error(res.error)
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - updateVoucherGroupDeals', { 'details': error })
    console.error('updateVoucherGroupDeals ', error)
    return { error: error.message }
  }
}

export const createEventTicket = async(values) => {
  try {
    // make sure values dont have pk else it will just do an update
    if (!values.ticket || !values.voucherGroupDeals) {
      throw new Error('missing_payload')
    }

    const res = await API.post('cardspalcmsv2api', `/events-ticket`, {
      body: {...values}
    })
    
    if (res.error) {
      throw new Error(res.error)
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - createEventTicket', { 'details': error })
    console.error('createEventTicket ', error)
    return { error: error.message }
  }
}

export const getGeoCodeFromLatLong = async(value) =>{
  try {
    let gkey
    let dataString=''

    let res = await retrieveGKey()
    if (res.success === "200")
        gkey = res.data.GOOGLE_API_KEY

    const response = await new Promise((resolve) => {
      const req = https.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${value.lat},${value.long}&location_type=ROOFTOP&result_type=street_address&key=${gkey}`, (res) => {
        res.on('data', chunk => {
          dataString += chunk;
        });
        res.on('end', () => {
          // console.log(dataString);
          resolve(dataString)
        });
      });
  
      req.on('error', (e) => {
        console.error(e)
        resolve(dataString)
      })
    })
    if (response.error) {
      throw new Error(response.error)
    } else {
      return response
    }
       
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - getLocationDetailsFromLatLong', { 'details': error })
    console.error('getLocationDetailsFromLatLong ', error)
    return { error: error.message }
  }

}

export const getWebFormByTransactionId = async (transactionId) => {
  try {
    const res = await API.get('cardspalcmsv2api', `/webform/transaction/${transactionId}`)
    if (res.error) {
      throw new Error(res.error)
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - getWebFormByTransactionId', { 'details': error })
    console.error('getWebFormByTransactionId ', error)
    return { error: error.message }
  }
}



export const updateWebFormData = async (data) => {
  try {
    const res = await API.put('cardspalcmsv2api', `/webform/update/${data.transactionId}`, {
      body: data
    })
    if (res.error) {
      amplitude.getInstance().logEventWithGroups('Error - updateWebFormData', { 'details': res.error })
    } 
    return res
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - updateWebFormData', { 'details': error })
    console.error('updateWebFormData ', error)
    return { error: error.message }
  }
}

export const getGroupMembers = async (groupName, webFormId) => {
  try {
    const res = await API.get('cardspalcmsv2api', `/webform/group-members/${groupName}?webFormId=${webFormId}`)
    if (res.error) {
      throw new Error(res.error)
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - getGroupMembers', { 'details': error })
    console.error('getGroupMembers ', error)
    return { error: error.message }
  }
}

export const cancelTransaction = async (transactionId) => {
  try {
    const res = await API.post('cardspalcmsv2api', `/cancel-ticket/${transactionId}`)
    if (res.error) {
      throw new Error(res.error)
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - cancelTransaction', { 'details': error })
    console.error('cancelTransaction ', error)
    return { error: error.message }
  }
}

export const updateWebFormGroup = async (data) => {
  try {
    const res = await API.put('cardspalcmsv2api', `/webform-group/${data.name}`, {
      body: data
    })
    if (res.error) {
      amplitude.getInstance().logEventWithGroups('Error - updateWebFormGroup', { 'details': res.error })  
    } 
    return res
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - updateWebFormGroup', { 'details': error })
    console.error('error on updateWebFormGroup ', error.message)
    return { statusCode:500, error: error.message }
  }
}

export const searchWebFormGroup = async (webFormId, searchTerm) => {
  try {
    const res = await API.get('cardspalcmsv2api', `/webform/search/?webFormId=${webFormId}&searchTerm=${encodeURIComponent(searchTerm)}`)
    if (res.error) {
      throw new Error(res.error)
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - searchWebFormGroup', { 'details': error })
    console.error('searchWebFormGroup ', error)
    return { error: error.message }
  }
}

export const getWebformUserByPhone = async (phoneNumber, webFormId) => {
  try {
    const res = await API.get('cardspalcmsv2api', `/user/phone-number-search?mobile=${encodeURIComponent(phoneNumber)}&webFormId=${webFormId}`)
    if (res.error) {
      throw new Error(res.error)
    } else {
      return res
    }
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - getWebformUserByPhone', { 'details': error })
    console.error('getWebFormByTransactionId ', error)
    return { error: error.message }
  }
}


export const getRemainingTickets = async (dealId) => {
  try {
    const res = await API.get('cardspalcmsv2api', `/tickets/remaining?dealId=${dealId}`)
    if (res.error) {
      amplitude.getInstance().logEventWithGroups('Error - getWebformUserByPhone', { 'details': res.error })
    }
    return res
  } catch (error) {
    amplitude.getInstance().logEventWithGroups('Error - getWebformUserByPhone', { 'details': error })
    console.error('getWebFormByTransactionId ', error)
    return { error: error.message }
  }
}

