//
export const COUNT = "/country"

export const getUTMParams = (location) => {
    const searchParams = new URLSearchParams(location.search);
    const utmParams = {};
  
    for (let param of searchParams.entries()) {
      if (param[0].startsWith('utm_')) {
        utmParams[param[0]] = param[1];
      }
    }
  
    return utmParams;
}

export const getUTMQueryString = (utmParams) => {
    // prep utm query strings if any from caller page
    const utmKeys = Object.keys(utmParams)
    const kv = utmKeys.map((key) => {
        return `${key}=${utmParams[key]}`
    })
    return kv.join('&')
}