import { API, graphqlOperation } from 'aws-amplify'
import { createMerchantOutlet, updateMerchantOutlet } from '../../graphql/mutations'
import { listMerchantOutlets, outletByMerchantId, getMerchantOutlet } from '../../graphql/queries'
import amplitude from 'amplitude-js';

const indices = ['geohashlong', 'geohashsix', 'geohashshort', 'subc_online', 'mallId', 'merchant_name_lowercase', 'postal_code', 'google_placeid']
import {GENERIC_ERROR_MESSAGE} from '../../utils/constants'

export const create = async(outlet) => {
    try {
        const item = { ...outlet }
        item.protectedAttributes = item.protectedAttributes ? ((typeof item.protectedAttributes === 'string') ? item.protectedAttributes : JSON.stringify(item.protectedAttributes)) : '{}'
        
        // remove indices with null values. ddb will throw error. cannot have null values in indices
        for (const index of indices) {
            if (index in item && (item[index] === undefined || item[index] === null || item[index] === '')) {
                delete item[index]
            }
        }

        const result = await API.graphql(graphqlOperation(createMerchantOutlet, { input: item }))
        return result.data.createMerchantOutlet
    } catch (e) {
        amplitude.getInstance().logEventWithGroups('Error - createMerchantOutlet', { 'details': e })
        console.error('error on Create outlet', e)
        throw e ? e.message : `${GENERIC_ERROR_MESSAGE}[Create Outlet]`
    }
}

export const update = async (outlet) => {
    try {
        // console.log('update outlet',{ outlet })
        const item = { ...outlet }
        delete item.deals
        item.protectedAttributes = item.protectedAttributes ? ((typeof item.protectedAttributes === 'string') ? item.protectedAttributes : JSON.stringify(item.protectedAttributes)) : '{}'
        
        // nullify indices with empty space values. ddb will throw error. cannot have empty values in indices
        for (const index of indices) {
            if (index in item && item[index] !== null && (item[index] === '' || item[index].toLowerCase() === 'null')) {
                item[index] = null
            }
        }
        // console.log('update outlet ',{ item })
        const result = await API.graphql(graphqlOperation(updateMerchantOutlet, { input: item }))
        return result.data.updateMerchantOutlet
    } catch (e) {
        amplitude.getInstance().logEventWithGroups('Error - updateMerchantOutlet', { 'details': e })
        console.error('error on outlet update ', e)
        throw e ? e.message : `${GENERIC_ERROR_MESSAGE}[Update Outlet]`
    }
}   

export const listOutletsByMerchantId = async(merchantId) => {
    try{

    let result = []
    const params = {
        merchant_id: merchantId,
        filter: {
            or: [
                { purchasableOutlet: { eq: "0" }},
                { purchasableOutlet: { attributeExists: false }},
                { purchasableOutlet: { eq: null } }
            ]
        }
    }

    //we get all outlets of the merchants
    do {   
        const outlets = await API.graphql(graphqlOperation(listMerchantOutlets, params))
        const items = outlets.data.listMerchantOutlets.items
        
        if (items.length > 0) {
            result = result.concat(items)
        }

        const nextToken = outlets.data.listMerchantOutlets.nextToken
        params.nextToken = nextToken
    } while (params.nextToken)
    
    // default sort by outlet name
    result.sort((a, b) => {
        if (a && b && a.outlet_name.toLowerCase() < b.outlet_name.toLowerCase()) {
            return -1
        } else if (a && b && a.outlet_name.toLowerCase() > b.outlet_name.toLowerCase()) {
            return 1
        }

        return 0
    })
    return result
} catch (e) {
    amplitude.getInstance().logEventWithGroups('Error - listOutletsByMerchantId', { 'details': e })
    console.error('error on listOutletsByMerchantId ', e)
}
}

export const listOutletsByStoreId = async(merchantId, storeIds) => {
    try{

    let result = []

    for (let i=0; i < storeIds.length; i++) {
        const storeId = storeIds[i]
        const params = {
            merchant_id: merchantId,
            store_id: {
                eq: storeId
            }
        }
        const outlets = await API.graphql(graphqlOperation(listMerchantOutlets, params))
        const items = outlets.data.listMerchantOutlets.items
        
        if (items.length > 0) {
            result = result.concat(items)
        }
    }

    // default sort by outlet name
    result.sort((a, b) => {
        if (a && b && a.outlet_name.toLowerCase() < b.outlet_name.toLowerCase()) {
            return -1
        } else if (a && b && a.outlet_name.toLowerCase() > b.outlet_name.toLowerCase()) {
            return 1
        }

        return 0
    })
    return result
} catch (e) {
    amplitude.getInstance().logEventWithGroups('Error - listOutletsByStoreId ', { 'details': e })
    console.error('error on listOutletsByStoreId ', e)
}
}


export const getOutlet = async(merchantId, storeId) => {
    try {
        const params = {
            merchant_id: merchantId,
            store_id: storeId
        }
        console.log({params})
        const result = await API.graphql(graphqlOperation(getMerchantOutlet, params))
        console.log('getOutlet ',result)
        return result.data.getMerchantOutlet
    } catch (e) {
        amplitude.getInstance().logEventWithGroups('Error - getOutlet', { 'details': e })
        console.error('error on getOutlet ', e)
        throw e ? e.message : `${GENERIC_ERROR_MESSAGE}[Retrieve Common Outlet]`
    }
}

export const getVoucherOutlet = async(merchantId) => {
    try {
        const params = {
            merchant_id: merchantId,
            filter: {
                purchasableOutlet: {
                    eq: "1"
                }
            }
        }
        
        const result = await API.graphql(graphqlOperation(outletByMerchantId, params))
        // console.log('getVoucherOutlet ', result.data.outletByMerchantId)
        return result.data.outletByMerchantId
    } catch (e) {
        amplitude.getInstance().logEventWithGroups('Error - getVoucherOutlet', { 'details': e })
        console.error('error on getVoucherOutlet ', e)
        throw e ? e.message : `${GENERIC_ERROR_MESSAGE}[Retrieve Voucher Outlet]`
    }
}

export const updatePurchasableOutlet = async (input, storeId) => {
    try {
        const condition = {
            store_id: {
                eq: {
                    storeId
                }
            }
        }

        const result = await API.graphql(graphqlOperation(updateMerchantOutlet, { input, condition }))
        return result.data.updateMerchantOutlet
    } catch(e) {
        amplitude.getInstance().logEventWithGroups('Error - updatePurchasableOutlet', { 'details': e })
        console.error('error on updatePurchasableOutlet ', e)
        throw e ? e.message : `${GENERIC_ERROR_MESSAGE}[Update Purchasable Outlet]`
    }
}