import React from "react"
import { Row, Col, Container } from "reactstrap"
import { Accordion  } from 'react-bootstrap'

const FAQ = () => {

    return(
        <Container>
        <Row>
            <Col>
            
                <Row>
                    <Col xs={12} sm={12} md={12} lg={3} xl={2}><h3 style={{ display:'inline-block' }}>General</h3></Col>
                    <Col xs={12} sm={12} md={12} lg={9} xl={10}>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>What is CardsPal?</Accordion.Header>
                                <Accordion.Body>
                                    CardsPal is a deals aggregator, lifestyle platform. For merchants, you can list your offers/deals on our app to create awareness and generate sales!  
                                </Accordion.Body>
                            </Accordion.Item>
                        
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>What is CardsPal for Business?</Accordion.Header>
                                <Accordion.Body>
                                    CardsPal for Business is for merchants; you can list your offers/deals on our app to create awareness and generate sales!
                                </Accordion.Body>
                            </Accordion.Item>
                        
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Where is CardsPal being used? </Accordion.Header>
                                <Accordion.Body>
                                    Currently, the app is only available in Singapore.
                                </Accordion.Body>
                            </Accordion.Item>
                        
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>How can CardsPal help your business grow?</Accordion.Header>
                                <Accordion.Body>
                                    Promote your latest deals to our user base of 400,000 and counting. Increase your brand exposure, traffic, engagement and revenue for your business. 
                                </Accordion.Body>
                            </Accordion.Item>
                        
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>I want to offer discounts to work with banks. Can CardsPal help to arrange? </Accordion.Header>
                                <Accordion.Body>
                                    CardsPal is bank agnostic. You can simply list the deals/discounts for all CardsPal users to enjoy. 
                                </Accordion.Body>
                            </Accordion.Item>
                        
                            {/* <Accordion.Item eventKey="5">
                                <Accordion.Header>What is the upside for me in joining CardsPal?</Accordion.Header>
                                <Accordion.Body>
                                    There are no additional costs. All you need is 15 minutes to set up an account to list a deal to reach out to  our user base which currently stands at over  305k 
                                </Accordion.Body>
                            </Accordion.Item> */}
                        </Accordion>
                    </Col>
                </Row>
                <Row className="faq-section-margin">
                    <Col xs={12} sm={12} md={12} lg={3} xl={2}><h3 style={{ display:'inline-block' }}>Marketplace</h3></Col>
                    <Col xs={12} sm={12} md={12} lg={9} xl={10}>
                        <Accordion>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>What is CardsPal Marketplace?</Accordion.Header>
                                <Accordion.Body>
                                    CardsPal’s exclusive platform allows our users to pre-purchase attractively priced vouchers.  
                                </Accordion.Body>
                            </Accordion.Item>
                        
                            <Accordion.Item eventKey="7">
                                <Accordion.Header>What kind of deals can be posted in CardsPal for Business?</Accordion.Header>
                                    <Accordion.Body>
                                        CardsPal can host promotional deals and vouchers to end users.
                                    </Accordion.Body>
                            </Accordion.Item>
                        
                            <Accordion.Item eventKey="8">
                                <Accordion.Header>What is the difference between CardsPal promotional deals and Marketplace voucher deals?</Accordion.Header>
                                <Accordion.Body>
                                    CardsPal deals are promotion deals that anyone can enjoy. It helps you to create merchant brand awareness and drive revenue/traffic to your website or physical store. <br/><br/>
                                    Marketplace deals are pre-purchasable deals, ie vouchers offered at great discounts to entice users to buy them first and use them later. You get to receive payments per the payout schedule after the voucher is sold. 
                                </Accordion.Body>
                            </Accordion.Item>
                        
                            <Accordion.Item eventKey="9">
                                <Accordion.Header>Are there any fees involved for listing my deals</Accordion.Header>
                                <Accordion.Body>
                                    As a merchant with a subscription plan, there are no additional fees for listing the purchasable deals. However, a small fee will be levied on each successful transaction in the Marketplace. You may reach out to us at <u>partnership@cardspal.com</u> for more information.
                                </Accordion.Body>
                            </Accordion.Item>
                        
                            <Accordion.Item eventKey="10">
                                <Accordion.Header>What are the fees and charges to sell vouchers on Marketplace?</Accordion.Header>
                                <Accordion.Body>
                                    Simply drop us an email at <u>partnership@cardspal.com</u>, and one of our team members will reach out to you for further discussion.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
                <Row className="faq-section-margin">
                    <Col xs={12} sm={12} md={12} lg={3} xl={2}><h3 style={{ display:'inline-block' }}>CardsPal for Business Plan</h3></Col>
                    <Col xs={12} sm={12} md={12} lg={9} xl={10}>
                        <Accordion>
                            <Accordion.Item eventKey="11">
                                <Accordion.Header>I don’t need any Marketing / I’m only interested in Marketplace.</Accordion.Header>
                                <Accordion.Body>
                                    Sure, we do have an alternative for you. You can be onboarded as a “deal marketplace only merchant”. In this instance, you don’t have to subscribe to our paying plans. You only need to pay a fee for each successful transaction, ie you only pay when our users purchase your product/services/voucher/promo codes. 
                                </Accordion.Body>
                            </Accordion.Item>
                        
                            <Accordion.Item eventKey="12">
                                <Accordion.Header>Will I receive an invoice for my subscription plan with CardsPal?</Accordion.Header>
                                <Accordion.Body>
                                    Yes, an invoice will be issued after receiving your payment. If you need an invoice prior to making payment, please inform us, and we will make the necessary arrangement.
                                </Accordion.Body>
                            </Accordion.Item>
                        
                            <Accordion.Item eventKey="13">
                                <Accordion.Header>Where to find the charges/fees for the payment?</Accordion.Header>
                                <Accordion.Body>
                                    Simply drop us an email at <u>partnership@cardspal.com</u>, and one of our team members will reach out to you with relevant details.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
                <Row className="faq-section-margin">
                    <Col xs={12} sm={12} md={12} lg={3} xl={2}><h3 style={{display:'inline-block'}}>Deals Featuring</h3></Col>
                    <Col xs={12} sm={12} md={12} lg={9} xl={10}>
                        <Accordion>
                            <Accordion.Item eventKey="14">
                                <Accordion.Header>How will I be featured in the app? </Accordion.Header>
                                <Accordion.Body>
                                    You will be placed under the relevant business categories and sub-categories (Food, Shopping, Activities, Travel, Transport, Wellness). Users will be able to discover through search or nearby deals (note: nearby deals only applicable for  merchants without only stores).  
                                </Accordion.Body>
                            </Accordion.Item>
                        
                            <Accordion.Item eventKey="15">
                                <Accordion.Header>Must our deals be tied to credit cards?</Accordion.Header>
                                <Accordion.Body>
                                    No, you are free to feature your existing deals or even offer exclusive deals to CardsPal users.
                                </Accordion.Body>
                            </Accordion.Item>
                        
                            <Accordion.Item eventKey="16">
                                <Accordion.Header>I wish to get more Marketing support, how do I go about it? OR How can I explore a sponsorship / collaboration / marketing opportunity with CardsPal?</Accordion.Header>
                                <Accordion.Body>
                                    Simply drop us an email at <u>partnership@cardspal.com</u> and one of our team members will reach out to you for further discussion. 
                                </Accordion.Body>
                            </Accordion.Item>
                        
                            <Accordion.Item eventKey="17">
                                <Accordion.Header>How can we track the response of our listing of CardsPal deals?</Accordion.Header>
                                <Accordion.Body>
                                    For online merchants: you can track by including a unique promo code.
                                    For offline merchants: you can track by getting customers to flash the app (include this in promo details).
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
                <Row className="faq-section-margin">
                    <Col xs={12} sm={12} md={12} lg={3} xl={2}><h3 style={{display:'inline-block'}}>CardsPal for Business Onboarding</h3></Col>
                    <Col xs={12} sm={12} md={12} lg={9} xl={10}>
                        <Accordion>
                            <Accordion.Item eventKey="18">
                                <Accordion.Header>How do I sign up for CardsPal for Business account? How do I sign up for CardsPal for Business or Marketplace account?</Accordion.Header>
                                <Accordion.Body>
                                    Simply visit: <a target="_blank" href="https://biz.cardspal.com" rel="noreferrer">https://biz.cardspal.com</a> and follow steps in our <a  href="/user-guide">onboarding guide</a>
                                </Accordion.Body>
                            </Accordion.Item>
                        
                            <Accordion.Item eventKey="19">
                                <Accordion.Header>Why do I need to sign up for a Stripe Express account?</Accordion.Header>
                                <Accordion.Body>
                                    This is to facilitate users to make payment for the vouchers that they wish to purchase. At the same time, this will allow CardsPal to pay you for the vouchers sold.
                                </Accordion.Body>
                            </Accordion.Item>
                        
                            <Accordion.Item eventKey="20">
                                <Accordion.Header>Why do I need to provide my Registered Business name and number?</Accordion.Header>
                                <Accordion.Body>
                                    This is to help us conduct the necessary verification checks that we need on our end. 
                                </Accordion.Body>
                            </Accordion.Item>
                        
                            <Accordion.Item eventKey="21">
                                <Accordion.Header>I already have a Stripe Express account. Can the same account be re-used?</Accordion.Header>
                                <Accordion.Body>
                                    Existing Stripe Express cannot be re-used. A merchant will need to sign up for a new Stripe Express account to connect to the CardsPal platform and receive the proceeds from the vouchers. Merchants are required to verify and submit the details to open a new Stripe Express connected account. 
                                </Accordion.Body>
                            </Accordion.Item>
                        
                            <Accordion.Item eventKey="22">
                                <Accordion.Header>How do I create an entirely separate Stripe login account for CardsPal?</Accordion.Header>
                                <Accordion.Body>
                                    Stripe accounts are individually identified in email ID and phone number pairs. If an entirely separate account is required, different combination of email ID/phone number can be used. 
                                </Accordion.Body>
                            </Accordion.Item>
                        
                            <Accordion.Item eventKey="23">
                                <Accordion.Header>I am unable to login as Stripe throws a message that, email is already in use.</Accordion.Header>
                                <Accordion.Body>
                                    Users can login using right combination of email ID and phone number which they used to create the account. Alternatively, Stripe support can be reached using <a target="_blank" href="https://stripe.com/en-sg/contact" rel="noreferrer">https://stripe.com/en-sg/contact</a>  
                                </Accordion.Body>
                            </Accordion.Item>
                        
                            <Accordion.Item eventKey="24">
                                <Accordion.Header>I do not know/forgot what phone number and email were used?</Accordion.Header>
                                <Accordion.Body>
                                    Stripe support can be reached using <a target="_blank" href="https://stripe.com/en-sg/contact" rel="noreferrer">https://stripe.com/en-sg/contact</a>
                                </Accordion.Body>
                            </Accordion.Item>
                        
                            <Accordion.Item eventKey="25">
                                <Accordion.Header>How do I update my Stripe email ID and password? </Accordion.Header>
                                <Accordion.Body>
                                    Users need to log in using login link from CardsPal for Business portal and access the profile to update the email ID and mobile number. 
                                </Accordion.Body>
                            </Accordion.Item>
                    
                            <Accordion.Item eventKey="27">
                                <Accordion.Header>Can I create multiple Stripe Express accounts on CardsPal platform?</Accordion.Header>
                                <Accordion.Body>
                                    Merchants can create different Stripe Express accounts for each of their brand. This helps merchant to manage each brand’s accounting and tracking efficiently. If all brands payment are expected to go into same bank account, it is still recommended to create a separate express connect account for each brand with same underlying bank details 
                                </Accordion.Body>
                            </Accordion.Item>
                        
                            <Accordion.Item eventKey="28">
                                <Accordion.Header>I entered wrong details during onboarding, how do I correct the details?</Accordion.Header>
                                <Accordion.Body>
                                    Users are given an option to review details before submission of account creation details. Users can correct the details at this point. Stripe allows users to update the details any time after successful onboarding but please note existing payout and account status may change based on the details being updated and can also trigger an verification process.
                                </Accordion.Body>
                            </Accordion.Item>
                        
                            <Accordion.Item eventKey="29">
                                <Accordion.Header>What does Stripe Express account status “Restricted” mean?</Accordion.Header>
                                <Accordion.Body>
                                    Restricted means the account has payouts or payments disabled. Additional information usually needs to be collected to enable these accounts. 
                                </Accordion.Body>
                            </Accordion.Item>
                        
                            <Accordion.Item eventKey="30">
                                <Accordion.Header>What does Stripe Express account status “Restricted soon” mean?</Accordion.Header>
                                <Accordion.Body>
                                    Restricted soon means the account has a due date for providing additional information. 
                                </Accordion.Body>
                            </Accordion.Item>
                        
                            <Accordion.Item eventKey="31">
                                <Accordion.Header>What does Stripe Express account status “Pending” mean?</Accordion.Header>
                                <Accordion.Body>
                                    Pending means the account is being reviewed or verified by Stripe. Payouts can be enabled or disabled for accounts with a pending status and requires no action on your part. Stripe automatically updates the account’s status when the review finishes. 
                                </Accordion.Body>
                            </Accordion.Item>
                        
                            <Accordion.Item eventKey="32">
                                <Accordion.Header>What does Stripe Express account status “Enabled” mean?</Accordion.Header>
                                <Accordion.Body>
                                    Enabled means the account is in good standing, though additional information might be required if another payment volume threshold is reached 
                                </Accordion.Body>
                            </Accordion.Item>
                        
                            <Accordion.Item eventKey="33">
                                <Accordion.Header>What does Stripe Express account status “Complete” mean?</Accordion.Header>
                                <Accordion.Body>
                                    Complete means the account provided all the required information and is in good standing. 
                                </Accordion.Body>
                            </Accordion.Item>
                        
                            <Accordion.Item eventKey="34">
                                <Accordion.Header>What does Stripe Express account status “Rejected” mean?</Accordion.Header>
                                <Accordion.Body>
                                    Rejected means the platform or Stripe rejected the connected account. In general, accounts are rejected if they are suspicions of fraud or any illegal conduct.
                                </Accordion.Body>
                            </Accordion.Item>
                        
                            <Accordion.Item eventKey="35">
                                <Accordion.Header>Why was my previously Enabled account changed to Restricted or Rejected now? </Accordion.Header>
                                <Accordion.Body>
                                    When the account transitions to Restricted, a certain payment volume threshold has been reached and additional information usually needs to be collected to enable these accounts. In this scenario, either payments or payouts are paused. 
                                    <br/><br/>
                                    When the account transitions to Rejected, it is likely that Stripe has rejected the connected account due to suspicion of fraudulent activity. Merchants can login to their accounts and reach out to Stripe support to provide dispute evidence. 
                                </Accordion.Body>
                            </Accordion.Item>
                        
                            <Accordion.Item eventKey="36">
                                <Accordion.Header>What should I do if my account is in Rejected status?</Accordion.Header>
                                <Accordion.Body>
                                    If the account transitions to Rejected, it is likely that Stripe has rejected the connected account due to suspicion of fraudulent activity. Merchants can login to their accounts and reach out to Stripe support to provide dispute evidence. 
                                </Accordion.Body>
                            </Accordion.Item>
                        
                            <Accordion.Item eventKey="36">
                                <Accordion.Header>How will I know if my Stripe Express account status is completed?</Accordion.Header>
                                <Accordion.Body>
                                    User need to log in to the platform to see the status if their account status is completed. 
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
                <Row className="faq-section-margin">
                    <Col xs={12} sm={12} md={12} lg={3} xl={2}><h3 style={{display:'inline-block'}}>CardsPal for Business portal</h3></Col>
                    <Col xs={12} sm={12} md={12} lg={9} xl={10}>
                        <Accordion>
                            <Accordion.Item eventKey="37">
                                <Accordion.Header>How do I sign up for CardsPal for Business account? How do I sign up for CardsPal for Business or Marketplace account?</Accordion.Header>
                                <Accordion.Body>
                                    Refer to <b><a href="/user-guide">user guide</a></b> for more details
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="38">
                                <Accordion.Header>How do I track my listing? How can I track my sales, performance and redemption?  How to view my sales numbers in CardsPal Dashboard?</Accordion.Header>
                                <Accordion.Body>
                                    Dashboard and Summary pages in CardsPal for Business provides details on how your listing is performing.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="39">
                                <Accordion.Header>Do I need to have multiple CardsPal for Business accounts for different brands?</Accordion.Header>
                                <Accordion.Body>
                                    It depends on your company structure. You may use one CardsPal account to manage the different brands. You may also create different CardsPal accounts for each brands.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="40">
                                <Accordion.Header>Is the login limited to 1 user? Can I create multiple users to access the same account? </Accordion.Header>
                                <Accordion.Body>
                                    CardsPal gives the flexibility on how merchant wants to manage their business. If you wish to have more than 1 user to manage your business, kindly reach out to our team to make the arrangement.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
                <Row className="faq-section-margin">
                    <Col xs={12} sm={12} md={12} lg={3} xl={2}><h3 style={{display:'inline-block'}}>Selling Vouchers in CardsPal App</h3></Col>
                    <Col xs={12} sm={12} md={12} lg={9} xl={10}>
                        <Accordion>
                            <Accordion.Item eventKey="41">
                                <Accordion.Header>How long will it take for the voucher to be available in app? </Accordion.Header>
                                <Accordion.Body>
                                    Voucher will be available in app within 2 business days
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="42">
                                <Accordion.Header>What is the frequency of voucher approval?</Accordion.Header>
                                <Accordion.Body>
                                    Vouchers are approved twice to thrice a day
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="43">
                                <Accordion.Header>After the voucher has been approved, how will I be informed?</Accordion.Header>
                                <Accordion.Body>
                                    Merchants will not be informed when deals have been approved. Please log in to your account to check if the deal is available in-app by looking out for the green eye logo. 
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="44">
                                <Accordion.Header>Can CardsPal help to create the Promo codes?</Accordion.Header>
                                <Accordion.Body>
                                    Yes, CardsPal can help you to create a set of unique codes so that each user will have a unique promo code to redeem. This will avoid duplicate redemption and the service is currently complimentary. 
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="45">
                                <Accordion.Header>I listed a voucher deal wrongly and it gets sold. Can I cancel it?</Accordion.Header>
                                <Accordion.Body>
                                    Unfortunately, you cannot cancel and have to honour the deal as it is. This is in-line with the user’s T&Cs that they cannot request a refund once a voucher purchase has been completed. <u>Therefore, please check all your deals before you list them.</u> 
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="46">
                                <Accordion.Header>How do I view transaction details of my Stripe Express connected account? </Accordion.Header>
                                <Accordion.Body>
                                    User can access their transaction details and reports from <u>CardsPal for Business Dashboard and Sales summary pages.</u>
                                </Accordion.Body>
                            </Accordion.Item>

                        </Accordion>
                    </Col>
                </Row>
                
                <Row className="faq-section-margin">
                    <Col xs={12} sm={12} md={12} lg={3} xl={2}><h3 style={{display:'inline-block'}}>Payments & Payouts</h3></Col>
                    <Col xs={12} sm={12} md={12} lg={9} xl={10}>
                        <Accordion>
                            <Accordion.Item eventKey="47">
                                <Accordion.Header>When will I receive my payments into my bank account?</Accordion.Header>
                                <Accordion.Body>
                                    First payout from CardsPal platform takes about 7-14 days after initiating the first payout. For subsequent payouts, all payouts happen according to payout set by <b>CardsPal</b> platform 
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="48">
                                <Accordion.Header>How long will the payout take to reflect in my bank account? </Accordion.Header>
                                <Accordion.Body>
                                    It depends on country setup of the Stripe Express connect account. For Singapore, it is 7 calendar days 
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="49">
                                <Accordion.Header>What should be minimum available amount in Express connected for payout to happen? </Accordion.Header>
                                <Accordion.Body>
                                    Stripe Express connected account should have minimum of SGD 1 to initiate payout 
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="50">
                                <Accordion.Header>Why is my first payout taking too long to arrive?</Accordion.Header>
                                <Accordion.Body>
                                    The first payout for every new Stripe express account is typically paid out 7 days after the first successful payment is received. This waiting period can be up to 14 days for businesses in certain industries. This delay allows Stripe to mitigate some of the risks inherent in providing credit services.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="51">
                                <Accordion.Header>Why do I see negative payouts?</Accordion.Header>
                                <Accordion.Body>
                                    Payout reflects your available account balance at the time it was created. In some cases, you may have a negative account balance. For example, if you receive 100 USD in payments but refund 200 USD of previous payments, your account balance would be -100 USD. If you don’t receive further payments to balance out the negative amount, Stripe creates a payout that debits your bank account.
                                    <br/><br/>
                                    Your bank account must support both credit and debit transactions so that Stripe can perform any required payouts.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="52">
                                <Accordion.Header>What are the possible reasons for payout failure?</Accordion.Header>
                                <Accordion.Body>
                                    <b>account_closed</b> : The bank account has been closed.<br />
                                    <b>account_frozen</b> : The bank account has been frozen.<br />
                                    <b>bank_account_restricted</b> : The bank account has restrictions on either the type, or the number, of payouts allowed. This normally indicates that the bank account is a savings or other non-checking account.<br />
                                    <b>bank_ownership_changed</b> : The destination bank account is no longer valid because its branch has changed ownership.<br />
                                    <b>could_not_process</b> : The bank could not process this payout.<br />
                                    <b>debit_not_authorized</b> : Debit transactions are not approved on the bank account. (Stripe requires bank accounts to be set up for both credit and debit payouts.)<br />
                                    <b>declined</b> : The bank has declined this transfer. Please contact the bank before retrying.<br />
                                    <b>insufficient_funds</b> : Your Stripe express account has insufficient funds to cover the payout.<br />
                                    <b>invalid_account_number</b> : The routing number seems correct, but the account number is invalid.<br />
                                    <b>incorrect_account_holder_name</b> : Your bank notified us that the bank account holder name on file is incorrect.<br />
                                    <b>incorrect_account_holder_address</b> : Your bank notified us that the bank account holder address on file is incorrect.<br />
                                    <b>incorrect_account_holder_tax_id</b> : Your bank notified us that the bank account holder tax ID on file is incorrect.<br />
                                    <b>invalid_currency</b > : The bank was unable to process this payout because of its currency. This is probably because the bank account cannot accept payments in that currency.<br />
                                    <b>no_account</b > : The bank account details on file are probably incorrect. No bank account could be located with those details.<br />
                                    <b>unsupported_card</b > : The bank no longer supports payouts to this card.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="53">
                                <Accordion.Header>How will I know if a payout has failed?</Accordion.Header>
                                <Accordion.Body>
                                    For any payout failures, account holder will be informed via email and same can be viewed in dashboard 
                                </Accordion.Body>
                            </Accordion.Item>


                            <Accordion.Item eventKey="54">
                                <Accordion.Header>My payouts were in paid status, is it possible it can fail later? </Accordion.Header>
                                <Accordion.Body>
                                    When a payout fails, it’s possible that its state initially shows as paid but then changes to failed (within 5 business days). 
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="55">
                                <Accordion.Header>What should be done if a payment fails?</Accordion.Header>
                                <Accordion.Body>
                                    Check for payout failure reason and take necessary corrective action. Once correction are made, payouts can be resumed by clicking on Resume Payout. Alternative you can wait for next payout cycle.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="56">
                                <Accordion.Header>What should I do if my payouts are not available in bank accounts?</Accordion.Header>
                                <Accordion.Body>
                                    Please contact Stripe support with necessary details via <a target="_blank" href="https://support.stripe.com/contact" rel="noreferrer">https://support.stripe.com/contact</a>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="57">
                                <Accordion.Header>Why is my payment amount different from what was expected?</Accordion.Header>
                                <Accordion.Body>
                                    Check the payout in your Dashboard to see all transactions included in an automatic payout. Some reasons the amount could be different than you were expecting could be<br/>
                                    <ul>
                                        <li>Your payout schedule has changed, and thus a different group of transactions are being paid out in accordance to that new schedule.</li>
                                        <li> Transactions might not be included because they were processed after the 00:00 UTC cutoff for payout creation.</li>
                                        <li>Refunds and/or disputes impact the balance. </li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="58">
                                <Accordion.Header>How do I find what transactions were included in or impacted a payout amount</Accordion.Header>
                                <Accordion.Body>
                                    To see all of the transactions that were included in a specific automatic payout:<br /><br />

                                    1. Log into your <a target="_blank" href="https://stripe.com/en-sg" rel="noreferrer">Stripe Dashboard</a>.<br/>
                                    2. In the menu on the left, click Balance.<br />
                                    3. Underneath Balance, click Payouts.<br />
                                    4. Click on the payout you would like to see the transactions for.<br />
                                    5. Scroll down to the Summary and Transactions sections for details<br />
                                    6. Payout summary and transaction list<br />
                                    7. For all payouts, you can view:<br /><br />
                                    <b>Summary</b>: The sums for all charges, refunds, and adjustments in the payout; includes their gross amounts, fees, and final amounts.<br/>
                                    <b>Transactions</b>: A breakdown of each individual transaction included in the payout. Includes individual transaction type, gross amounts, fees, final amounts, charge details, and date of transaction. 

                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="59">
                                <Accordion.Header>What currency payouts is currently supported in CardsPal?</Accordion.Header>
                                <Accordion.Body>
                                    <b>CardsPal</b> currently <u>only</u> supports payouts in SGD
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="60">
                                <Accordion.Header>Will my stripe express account have negative balance?</Accordion.Header>
                                <Accordion.Body>
                                    Stripe balance may go into the negative if the cost of refunds, disputes or Stripe fees is greater than the existing balance. 
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="61">
                                <Accordion.Header>What will happen to payouts if bank account details are updated when payouts are “in transit”? </Accordion.Header>
                                <Accordion.Body>
                                    You can update your bank account at any time. However, if your payout is already in transit, it will be paid out to your old bank account and not to the new account.<br /><br />
                                    Stripe creates payouts in advance of their post date to ensure that funds reach your bank account on the date that they are expected. If there is a payout showing in your Dashboard as in transit or paid, updates made to your bank account information will not retroactively apply to these payouts and will only apply to the next payout. 
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="61">
                                <Accordion.Header>What will happen to payouts if bank account details are updated when payouts are “in transit” and old bank account is closed?</Accordion.Header>
                                <Accordion.Body>
                                    If the bank account your payout was sent to is closed, the payout will return to Stripe and be paid out to your new account. This can only happen when the funds actually arrived back to Stripe.
                                    <br/><br/>
                                    If you updated your bank account while a payout is in transit, wait 5 business days before getting in touch as it is highly likely your payout will return to Stripe.
                                </Accordion.Body>
                            </Accordion.Item>


                            <Accordion.Item eventKey="62">
                                <Accordion.Header>What happens to payouts when bank account details are wrong?</Accordion.Header>
                                <Accordion.Body>
                                    If incorrect bank account details were added in your Stripe express account, your payouts will have been sent to that bank account.<br /><br />
                                    You should contact your old bank to understand what will happen to the funds. You will need to provide the following information: bank account details, date expected, exact amount, and payor (Stripe).<br /><br />
                                    If that bank account does not exist, the payout(s) will be returned to your Stripe balance. If your funds have not returned to your Stripe balance after 5 business days, contact Stripe Support(<a target="_blank" href="https://support.stripe.com/contact" rel="noreferrer">https://support.stripe.com/contact</a>) .  
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="63">
                                <Accordion.Header>How do I know which account was payout sent to? </Accordion.Header>
                                <Accordion.Body>
                                    Users can go to their dashboard and then to Balances {">>"} Payouts and then click on respective payout to view the details 
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="64">
                                <Accordion.Header>What happens to Bank-issued Notification of Change?</Accordion.Header>
                                <Accordion.Body>
                                    If Stripe sends a payout to a bank account and the bank cannot deposit the funds using the account information provided, the bank will commonly send Stripe a Notification of Change, requesting that Stripe update the bank account details.<br /><br />
                                    When Stripe receives this notification, we notify the account owner by email of the bank’s response and provide a direct link to update the bank account details in the Dashboard. 
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="65">
                                <Accordion.Header>How do I update my bank account details in stripe? </Accordion.Header>
                                <Accordion.Body>
                                    To update their bank account details, an Express user should head to the Account Tab in their Dashboard. Under Payout and Professional Details, they will see the platforms tied to their account. Once a platform is selected, they should head to “Payout Details” to update:<br/><br/>
                                    <ul>
                                        <li>Where to send their payouts (debit card or bank account).</li>
                                        <li>Their bank account or card details</li>
                                    </ul>
                                    If the external account (debit card or bank account) in question has previously received a successful payout, Express users will be required to enter their previous external bank account details and a 6-digit SMS verification code to make any edits.<br/><br/>
                                    Note that keeping payout details up to date helps to prevent any delay in receiving payouts. To avoid delays, we strongly encourage Express users to update their payout information in advance of any changes and to update their debit card details before the card expires. 
                                </Accordion.Body>
                            </Accordion.Item>

                        </Accordion>
                    </Col>
                </Row>

                <Row className="faq-section-margin">
                    <Col xs={12} sm={12} md={12} lg={3} xl={2}><h3 style={{display:'inline-block'}}>Support & Complaints</h3></Col>
                    <Col xs={12} sm={12} md={12} lg={9} xl={10}>
                        <Accordion>
                            <Accordion.Item eventKey="66">
                                <Accordion.Header>Will the users be able to contact merchants directly?</Accordion.Header>
                                <Accordion.Body>
                                    CardsPal manages all user queries and complaints. We may reach out to merchant on case to case basis to resolve user issues
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="67">
                                <Accordion.Header>What happens if a user wants a refund?</Accordion.Header>
                                <Accordion.Body>
                                    <u>The T&Cs currently</u> do not allow a user to request a refund of any vouchers that are sold. Should there be any escalated complaints, it will be handled on a case-to-case basis. 
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="68">
                                <Accordion.Header>What should I do if I accidentally redeemed a transaction? (example: a user performs a redemption and merchant swipes redeem but eventually, merchant is not able to fulfil due to stock issue.)</Accordion.Header>
                                <Accordion.Body>
                                    Please reach out to <b>support@cardspal.com</b>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="69">
                                <Accordion.Header>What should I do if user is unable to swipe redeem?</Accordion.Header>
                                <Accordion.Body>
                                    Merchant should request user email support helpline at <b>support@cardspal.com</b> with relevant details 
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="70">
                                <Accordion.Header>What should I do if customer presents an expired voucher?</Accordion.Header>
                                <Accordion.Body>
                                    As per standard T&Cs, a CardsPal user will not be able to redeem an expired voucher. 
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
        
            </Col>
        </Row>
        </Container>
    )

}


export default FAQ