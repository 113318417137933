import {
    SET_ALERT_MESSAGE
} from './actionTypes';

const initialState = {
    message: '',
    color: 'info'
}

const setup = (state = initialState, action) => {
    switch (action.type) {
        case SET_ALERT_MESSAGE:
            return {
                ...state,
                message: action.payload.message,
                color: action.payload.color,
            };

        default:
            state = { ...state };
            break;
    }
    return state;
}

export default setup;