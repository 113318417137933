/* eslint-disable react/no-unescaped-entities */
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Row, Col, CardBody, Card, Alert, Container, Spinner } from "reactstrap"
import { AvForm, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import { registerUser, apiError, registerUserFailed, setAmplitudeEvent } from "../../store/actions"
import { connect, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import logoLightPng from "../../assets/images/logo-onboarding.png"
import { getAmplifyBackend } from "helpers/authHelper";
import { REGISTER_USER_EXIST, FREE_PLAN, OTP_EMAIL_EXCEED, CODE_DELIVERY_FAILURE_EXCEPTION } from "utils/constants";
import { amplitudeUserProperty, openInNewTab } from "utils";

const initValues = {
  firstName:'',
  lastName:'',
  mobile:'',
  email:'',
  password:'',
  roles:'',
  subPlan: '',
  agreeToTerms: false,
  marketingConsent: false,
}

const errPasswordMsg = 'Password must be at least 8 characters \nPassword must have uppercase characters \nPassword must have numeric characters \nPassword must have symbol characters'

const Register = props => {
  const [fieldValues, setFieldValues] = useState(initValues)
  const [checkAgree, setCheckAgree] = useState(false)
  const [checkConsent, setCheckConsent] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const dispatch = useDispatch()
  const amplifyBackend = getAmplifyBackend()

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFieldValues({
      ...fieldValues,
      [name]: value,
    });

    if (name === 'agreeToTerms') {
      setCheckAgree(e.target.checked)
      dispatch(setAmplitudeEvent('CMS registration page - Terms & Conditions checkbox',{ 'checkbox': e.target.checked, 'registration status': 'N' }))
    }else if (name === 'marketingConsent') {
      setCheckConsent(e.target.checked)
      dispatch(setAmplitudeEvent('CMS registration page - marketing consent checkbox',{ 'checkbox': e.target.checked, 'registration status': 'N' }))
    }
    
  };

  const handleValidSubmit = async(event, values) => {
    try {
      let user = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        mobile: values.mobile,
        roles: JSON.stringify({merchants:[]}),
        agreeToTerms: checkAgree ? "1" : "0",
        marketingConsent: checkConsent ? "1" : "0",
        subPlan: FREE_PLAN,
        password: values.password
      }
      
      setLoading(true)
      let signupResponse = await amplifyBackend.signup(values.email, values.password, values.firstName, values.lastName, values.mobile, user.agreeToTerms, user.marketingConsent)
      console.log('amplify signup response ', signupResponse)

      if (signupResponse.user) {
        dispatch(registerUser(user))
        dispatch(setAmplitudeEvent('CMS registration page - register button', { 'registration status': 'Pending' }))
        amplitudeUserProperty('registration status', 'Pending')
        props.history.push('/email-verification');
      } else {
        let objRes = { signupResponse }
        let errorRes = objRes.signupResponse
        if(errorRes.code === 'InvalidPasswordException'){
          let splitMessage = errorRes?.message.split(':')
          let errorMessage = splitMessage[1].trim()
          //Password not long enough | Password must have uppercase characters | Password must have numeric characters | Password must have symbol characters
          if (errorMessage === 'Password not long enough'){
            setError('Password must be at least 8 characters')
          }else{
            setError(errorMessage)
          }
        }else {
          setError(errorRes.message)
        }
        setLoading(false)
        dispatch(setAmplitudeEvent('CMS registration page - register button', { 'registration status': 'N' }))
        amplitudeUserProperty('registration status', 'N')
      }
    } catch (error) {
      setLoading(false)
      setError('Server error: Cannot proceed with registration')
      dispatch(setAmplitudeEvent('Error - registration', { 'details': JSON.stringify(error) }))  
    }
    
  }

  useEffect(() => {
    props.apiError("")
    dispatch(setAmplitudeEvent('CMS registration page - page load', {'registration status': 'N'}))
    amplitudeUserProperty('registration status', 'N')
  }, []);

  const gotoSignIn = () => {
    dispatch(setAmplitudeEvent('CMS email verification page - login button', {'registration status': 'N'}))
    amplitudeUserProperty('registration status','N')
  }

  const renderErrorMessage = () => {
    return(
      <span>{error === 'InvalidPasswordException'? <Alert color="danger" style={{ whiteSpace:'pre' }}>{errPasswordMsg}</Alert>
      :<Alert color="danger">{error}</Alert>}
      </span>
    )
  }
  return (
    <React.Fragment>
      <MetaTags>
      <title>Register | CardsPal Business</title>
      </MetaTags>

      <div className="account-pages  login-background" >
      <div style={{ position:'relative' }}>
          <a href='#' onClick={()=>openInNewTab('https://cardspal.com/wp-content/uploads/2022/03/CardsPal-Merchant-Guide_Mar-2022.pdf')} className="viewUserGuide">View merchant guide</a>
        </div>
        <Container>
          <Row className="justify-content-center vertical-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden mt-5">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-2">
                    <Link to="#" className="d-block auth-logo">
                      <img src={logoLightPng} alt="" height="50" className="auth-logo-dark" />
                    </Link>
                  </h3>
                  <div className="px-3">
                    <h4 className="text-muted font-size-18 mb-1 text-center">Registration</h4>
                    <p className="text-muted text-center">Get your free CardsPal Biz account now.</p>
                    <div>
                      <Alert color="info">
                        <p className="mb-0">
                        Thank you for your interest in registering for a <strong>CardsPal Business Account</strong>
                         <p className="mb-0 mt-3">List of information required after registration:</p>
                         <ul className="mb-0">
                          <li>Registered company name in ACRA</li>
                          <li>UEN Number</li>
                        </ul>  
                        </p>
                      </Alert>
                      {typeof error === "string" ? (
                        <Alert color="danger">{error}</Alert>
                      ) : null}
                    </div>
                    <AvForm //autoComplete='Off'
                      className="form-horizontal mt-4"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      
                      <div className="mb-3">
                        <Row xs='2'>
                          <AvField
                            name="firstName"
                            label="First Name"
                            className="form-control"
                            type="text"
                            required
                            errorMessage="This field is required"
                            value={fieldValues.firstName}
                            onChange={handleInputChange}
                            autoComplete = 'off'
                          />
                          <AvField
                            name="lastName"
                            label="Last Name"
                            className="form-control"
                            type="text"
                            required
                            errorMessage="This field is required"
                            value={fieldValues.lastName}
                            onChange={handleInputChange}
                            autoComplete = 'off'
                          />
                        </Row>
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="mobile"
                          label="Mobile Number"
                          className="form-control"
                          type="text"
                          required
                          errorMessage="This field is required"
                          value={fieldValues.mobile}
                          onChange={handleInputChange}
                          autoComplete = 'off'
                         />
                      </div>
                      <div className="mb-3">
                        <AvField
                          id="email"
                          name="email"
                          label="Email Address"
                          className="form-control"
                          type="email"
                          required
                          errorMessage="This field is required"
                          value={fieldValues.email}
                          onChange={handleInputChange}
                          autoComplete = 'off'
                          autoCapitalize="none"
                         />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="password"
                          label={<span>Password<span> <br />(Password must be at least 8 characters, must have uppercase, lowercase, numeric and symbol characters)</span></span>}
                          type="password"
                          className="form-control"
                          required
                          errorMessage="This field is required"
                          value={fieldValues.password}
                          onChange={handleInputChange}
                          autoComplete = 'off'
                        />
                       
                      </div>
                      
                      <div className="col-12 mt-4">
                        <AvCheckboxGroup name="agreeToTerms" 
                            //value={fieldValues.agreeToTerms}
                            onChange={handleInputChange}
                            checked={checkAgree}
                            required
                            errorMessage="This field is required" className='mb-2'>
                          <AvCheckbox label= {<p className="text-muted mb-0">By continuing, you agree to <a href="https://cardspal.com/merchant_terms/" target="__cardspal_terms" className="badge-primary">CardsPal's Merchant Terms & Conditions</a> and <a href="https://cardspal.com/privacy-policy/" target="__cardspal_terms">Privacy Policy</a></p>} value="" />
                        </AvCheckboxGroup>

                        <AvCheckboxGroup inline name="marketingConsent"
                            //value={fieldValues.marketingConsent}
                            onChange={handleInputChange} 
                            checked={checkConsent}
                            >
                          <AvCheckbox label= {<p className="text-muted">I consent to receiving marketing emails regarding CardsPal's products, services and events. I am aware that I can unsubscribe at anytime.</p>} value=""/>
                        </AvCheckboxGroup>
                        
                      </div>
                      <div className="mb-3 row mt-4">
                        <div className="col-12 text-end">
                        {loading?<Spinner size="sm" style={{"marginRight":"10px"}}/>:null}
                          <button disabled={loading} className="btn btn-primary w-md waves-effect waves-light" type="submit">Register</button>
                        </div>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" onClick={()=>gotoSignIn()} className="text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>
                  <Link to="#" onClick={()=>openInNewTab('https://cardspal.com/support/')}><span>Support Center</span></Link><span className="separator">|</span>
                  <Link to="#" onClick={()=>openInNewTab('https://cardspal.com/merchant_terms/')}><span>Merchant T&C</span></Link><span className="separator">|</span>
                  <Link to="#" onClick={()=>openInNewTab('https://cardspal.com/terms-of-use/')}><span>Terms of Use</span></Link><span className="separator">|</span>
                  <Link to="#" onClick={()=>openInNewTab('https://cardspal.com/privacy-policy/')}><span>Privacy Policy</span></Link><span className="separator">|</span>
                  <Link to="/faqs"><span>FAQ</span></Link>
                </p>
                <p>
                  © {new Date().getFullYear()} CardsPal Business 
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Register.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Register)
