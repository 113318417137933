import React, { useEffect } from "react"
import { Row, Col, Modal, ModalFooter, ModalHeader, ModalBody, Button, Alert, Spinner } from "reactstrap"

// import graphql helpers
import { listOutletsByMerchantId } from '../../../../helpers/GraphQL/outlet'

const VoucherOutlets = (props) => {
    const [sellVoucher, setSellVoucher] = React.useState({})
    const [processing, setProcessing] = React.useState(true)
    const [transactionMessage, setTransactionMessage] = React.useState(null)
    const [outlets, setOutlets] = React.useState([])

    useEffect(async () => {
        setSellVoucher(props.sellVoucher)
        const _outlets = await getOutlets(props.sellVoucher.merchant_id)
        if (_outlets) {
            setOutlets(_outlets)
            setProcessing(false)
        }
    }, [props.sellVoucher])

    async function getOutlets(merchantId) {
        try {
            let outlets = []
            if (merchantId) {
                outlets = await listOutletsByMerchantId(merchantId)
            }
            
            return outlets
        } catch (error) {
            console.error(error)
            setTransactionMessage(JSON.stringify(error))
        }
    }

    function isChecked(outlet) {
        return sellVoucher.showInOutletIds.includes(outlet.store_id)
    }


    function listOutlets(outlets) {
        if (outlets) {
            let outletListLeft = []
            let outletListRight = []
            const totalOutlets = outlets.length

            for (let i = 0; i < totalOutlets; i++) {
                // we will alternately display the outlet side by side
                if (i % 2) {
                    outletListLeft.push(
                        <div className="form-check" key={i}>
                            <input type="checkbox" disabled key={i + outlets[i].store_id} className="form-check-input" checked={isChecked(outlets[i])} />
                            <label key={i} className="form-check-label" >{outlets[i].outlet_name}</label>
                        </div>
                    )
                } else {
                    outletListRight.push(
                        <div className="form-check" key={i}>
                            <input type="checkbox" disabled key={i + outlets[i].store_id} className="form-check-input" checked={isChecked(outlets[i])} />
                            <label key={i} className="form-check-label">{outlets[i].outlet_name}</label>
                        </div>
                    )   
                }
            }

            return(
                <div>
                    <Row className="scrollview scrollbar scrollbar-primary" style={{ maxHeight: "400px" }}>
                        <Col>{outletListRight}</Col>
                        <Col>{outletListLeft}</Col>
                        
                    </Row>
                </div>
            )
        }
    }

    return (
        <Modal
            size="md"
            isOpen={props.modalVoucherOutletStatus}
            toggle={() => {
                props.close(null, false, 'voucherOutlets')
            }}
            centered={true}
        >
            <ModalHeader toggle={() => props.close(null, false, 'dealsOutlets')}>
                View applicable outlets
            </ModalHeader>
            <ModalBody>
                {processing ? <Spinner /> : listOutlets(outlets)}
            </ModalBody>
            <ModalFooter>
                {transactionMessage ?
                    <Alert color={transactionMessage.type}>
                        {transactionMessage.msg}
                    </Alert>
                    : null}

                {processing ?
                    <Spinner />
                    : null}

                <Button data-testid="doneBtn" color="primary" disabled={processing} onClick={() => props.close(null, false, 'voucherOutlets')}>
                    Done
                </Button>
            </ModalFooter>
        </Modal>
    )

}

export default VoucherOutlets