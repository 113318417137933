import { API, graphqlOperation } from 'aws-amplify'
import { getCheckinTicket, checkinTicketByDealId } from '../../graphql/queries'

export const getCheckinTicketByMasterId = async (masterId) => {
    try {
        const result = await API.graphql(graphqlOperation(getCheckinTicket, { masterId }))
        return result.data.getCheckinTicket
    } catch (e) {
        console.error('error getCheckinTicket ', e)
        throw e ? e.message : e
    }
}

export const getCheckinTickets = async (dealId) => {
    let data = []
    let result = {}
    let params = {dealId: dealId}

    try {
        do{
            result = await API.graphql(graphqlOperation(checkinTicketByDealId, params))
            const nextToken = result.data.checkinTicketByDealId.nextToken
            params.nextToken = nextToken
            const items = result.data.checkinTicketByDealId.items
            if(items.length > 0) data = data.concat(items)
        } while (result?.data?.checkinTicketByDealId?.nextToken)
        return data
    } catch (e) {
        console.error('error getCheckinTickets ', e)
        throw e ? e.message : e
    }
}