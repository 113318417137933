/* eslint-disable react/no-unescaped-entities */
import React from "react"
import { Card, CardBody, Button, CardTitle} from "reactstrap"
import { FREE_PLAN, STARTER_PLAN_1, STARTER_PLAN_2, STARTER_PLAN_3, PROFESSIONAL_PLAN } from "utils/constants"
import professionalPlanImg from '../../../../assets/images/professionalPlan.png'
import checkSubPlan from '../../../../assets/images/checkSubPlan.png'

const ProfessionalPlan = (props)=> {
    const { isProcessing } = props
    const previousPlans = [FREE_PLAN, STARTER_PLAN_1, STARTER_PLAN_2, STARTER_PLAN_3, ]
    const allPlans = [FREE_PLAN, STARTER_PLAN_1, STARTER_PLAN_2, STARTER_PLAN_3, PROFESSIONAL_PLAN]

    return(
        
        <Card className="text-center plan-card-height">
            <div className="mt-5">
            <img style={{ width:'60px', height:'80px', objectFit:'cover' }} src={professionalPlanImg}/>
            <div style={{ position: "absolute", right: 0, left:0, top: -20 }}>
                {props.subPlan === PROFESSIONAL_PLAN &&
                    <img style={{ height:'45px', width:'45px'}} src={checkSubPlan}/>}
                </div>
            </div>
            <CardBody>
                <CardTitle><strong>Professional Plan</strong></CardTitle>
                <p>Best for businesses with 4 brands or more</p>

                <div style={{ textAlign:'left' }}>
                    <p>✅ Listing of <strong>unlimited deals</strong> on CardsPal app (unlimited brands)</p>
                    <p>✅ Be featured in <strong>&apos;Nearby Deals&apos;</strong></p>
                    <p>✅ Be featured in relevant <strong>categories and search</strong> results</p>
                    <p>✅ Additional shoutout for <strong>new merchants</strong></p>
                    <p>✅ Be informed of the <strong>latest campaigns + priority</strong> to engage our users</p>
                    <p>✅ <strong>Welcome gift</strong> - 1x feature in EDM (non-dedicated) & 1x feature in &quot;Deal of the day&quot; for each brand</p>
                    <p>✅ Total entitlements <strong>worth S$2,599</strong></p>
                </div>
                {(previousPlans.includes(props.subPlan) || !allPlans.includes(props.subPlan))?
                <Button color='primary' disabled={isProcessing} onClick={()=> props.getStarted()} >Get Started</Button>
                :<></>}
            </CardBody>
        </Card>
             
    )
}

export default ProfessionalPlan