import React, { useState, useEffect, useRef }  from 'react'
import { remoteConfig, getHappeningOn } from 'utils';
import {  getDealInfo, getEvents, reserveVoucher, getEnv } from 'helpers/apiHelper';
import AccessDenied from 'components/Common/accessDenied';
import amplitude from 'amplitude-js';
import EventTicket from "../../components/Common/EventTicket"
import styled from "styled-components"
import { Row, Col, Container } from "reactstrap"
import { Image } from 'react-bootstrap';
import FooterImage from '../../assets/images/PoweredByFooter.png'




 const ExternalComponent =  (props) => {
    
    const eventId = props.match.params.eventId;
    const dealId = props.match.params.dealId
    const imagePath = `https://assets.${getEnv()}.cardspal.com/public/Events/banner/`

    const [isBusy, setIsBusy] = useState(false)
    const [dealInfo, setDealInfo] = useState([])
    const [tickets, setTickets] = useState([])
    const [eventInfo, setEventInfo] = useState([])
    const [isAccessDenied, setAccessDenied] = useState(null);
    const [happeningOn, setHappeningOn] = useState('')
    const [invalid, setInvalid] = useState(true)
    const [dimensions, setDimensions] = useState({ 
        height: window.innerHeight,
        width: window.innerWidth
      })
    const [custom, setCustom] = useState([])
    
      const getCustomSettings = async () => {
            let cust = await remoteConfig("b2bCustom")
            if (cust._value) {
                let parseCustJson = JSON.parse(cust._value)
                setCustom(parseCustJson[eventId])
                return (parseCustJson[eventId])
            } else return null
        }

    const MOBILE_WIDTH = 768

    const handleResize = () => {
        let adjustment = window.document.body.offsetHeight
            setDimensions({
                height: adjustment ,
                width: window.innerWidth
            })
            window.parent.postMessage({cpHeight:adjustment}, '*')
    }
    
    useEffect (()=>{
        window.addEventListener('resize', handleResize)
        return _ => {
            window.removeEventListener('resize', handleResize)
        }
    },[])

    useEffect( async() => {
        setIsBusy(true)
        await loadEventDeal()
    }, [])


    const loadEventDeal = async() => {

        let customData = await getCustomSettings()
        let event = await getEvents(eventId)
        setEventInfo(event?.data?.events)

        if (event.statusCode === '200') {
            let _datetime = getHappeningOn(event?.data?.events?.startTimestamp, event?.data?.events?.endTimestamp)
            setHappeningOn(_datetime)
            let ticketList = await Promise.all(event.data?.events?.listOfTickets.map(async (item, index) => {
                let deal = await getDealInfo(item)
                let dealCustom = customData?.dealInfo[deal.data.pk]
                deal?.data ? deal.data.dealLink= `/webform/0004/${eventId}/${deal.data.pk}` :null
                return  <EventTicket key={`ticket-${index}`}  deal={deal?.data} bundle={dealCustom?.type === 'bundle'}/>
              }))

            setTickets(ticketList)
            setAccessDenied(false)
        } else {
            setAccessDenied(true)
        }
        setIsBusy(false)
        setInvalid(false)
    }

    const loadTickets = (tickets) =>{
        let render = []
        for (let x=0; x < tickets.length; x+=2){
            x+1 ===  tickets.length ? 
            render.push( <Row key={"tixRow-"+x} ><Col xs={12} sm={12} md={6} lg={6} xl={6}>{tickets[x]}</Col><Col></Col></Row>)
            :
            render.push( <Row  key={"tixRow-"+x}><Col style={{marginBottom:"2%"}} xs={12} sm={12} md={6} lg={6} xl={6}>{tickets[x]}</Col><Col style={{marginBottom:"2%"}} xs={12} sm={12} md={6} lg={6} xl={6}>{tickets[x+1]}</Col></Row>)
        }
        return render
    }

    return (
        <React.Fragment>
            {(isAccessDenied === null) || invalid  ? <></> :
            ( isAccessDenied ? <AccessDenied screen="B2B" showBackToDashboard={false}/> : 
            <FrameContainer id='cpframeContainer'>
                <BannerContainer id="cpBannerContainer">
                    <Row>
                        {dimensions.width > MOBILE_WIDTH ?
                        <>
                            <Col className='col-8'>
                                            <Title mobileWidth={MOBILE_WIDTH}> {eventInfo?.name} </Title>
                                <Caption mobileWidth={MOBILE_WIDTH}> {happeningOn} </Caption>
                                </Col>
                            <Col className='col-4'><Image fluid src={imagePath+"6851009f-558a-db0c-8ebc-0904f7a345dc_banner.png"}/></Col>
                        </>
                        :
                        <>
                            <Col >
                                            <Title mobileWidth={MOBILE_WIDTH}> {eventInfo?.name} </Title>
                                <Caption mobileWidth={MOBILE_WIDTH}> {happeningOn} </Caption>
                            </Col>
                        </>
                    }
                       
                    </Row>
                    <Divider></Divider>
                </BannerContainer>
                <Container fluid style={{marginTop:0,flexDirection: 'row',flexGrow:1}}>
                    {loadTickets(tickets)}
                </Container>
            <FooterContainer id="cpFooterContainer" >
                {dimensions.width > MOBILE_WIDTH ?
                    <Image onLoad={handleResize} fluid style={{alignSelf:"start", width:"40%"}}  src={FooterImage}/>
                :
                    <Image onLoad={handleResize} fluid style={{alignSelf:"start", width:"60%"}} src={FooterImage}/>
                }
            </FooterContainer>
            </FrameContainer>
            )
            }

            </React.Fragment>

      )
 }

 const FrameContainer = styled.div`
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 30px;
        height:100%;
        padding :2%;
        flex:1;
    `
    const FooterContainer = styled.div`
        margin-top:2%;
        margin-bottom:4%;
        padding-left: 1%;
        display:flex;
        flex-direction:column;
        justify-content:flex-end;

    `
    const BannerContainer = styled.div`
        display: inline-block;
        margin-top: 1%;
        padding-top: 1%;
        padding-bottom: 1%;
        padding-left: 2%;
        padding-right: 2%;
        width: 100%;
        cursor: pointer;
    `

    const Title = styled.div`
        font-size: 3vw;
        color:black;
        font-weight:600;
        font-style:normal;
        font-family:Rubik;
        @media (max-width: ${props => props.mobileWidth}px) {
            text-align:center;
            font-size: 4.5vw;
        }
    `
    const Caption = styled.div`
        font-size: 2.8vw;
        color:black;
        font-weight:400;
        font-style:normal;
        font-family:Rubik;
        @media (max-width: ${props => props.mobileWidth}px) {
            text-align:center;
            font-size: 4vw;
        }
    `
    const Divider = styled.div`
        color: #E7E7ED;
        background-color: #E7E7ED;
        height: 5px;
        width: 100%;
        margin-bottom:5%;
        margin-top:3%;
    `

  export default ExternalComponent;