import React from "react"
import {Card, CardBody, CardImg, CardText, Button, CardTitle, CardSubtitle } from "reactstrap"

const Guide = (props) => {
    
    return (
        <Card style={{minHeight: "380px"}}>
            <CardImg
            src={props.image}
            top
            width="100%"
            height="100%"
            style={{ objectFit:'cover' }}
            />
            <CardBody>
                <CardTitle tag="h6">
                    {props.title}
                </CardTitle>
                <CardSubtitle
                    className="mb-2 text-muted"
                    style={{ fontSize:12 }}
                >
                    {props.subTitle}
                </CardSubtitle>
                <CardText className="my-3" style={{ fontSize:12 }}>
                    {props.description}
                </CardText>
                <Button className="primary-bgcolor" onClick={()=>props.onClick()}>
                    {props.buttonName}
                </Button>
            </CardBody>
        </Card>
    )
}


export default Guide