import React, { useState, useEffect, Suspense } from 'react';
import { Form, Image, Button } from 'react-bootstrap';
import { Row, Col, CardBody, Container, Spinner, CardTitle } from "reactstrap"
import styled from 'styled-components';
import moment from 'moment'
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { BiEditAlt } from 'react-icons/bi';
import { GoCheck } from 'react-icons/go';
import { FaWallet } from 'react-icons/fa';
import { IoIosPaper } from 'react-icons/io';
import { AiFillInfoCircle } from 'react-icons/ai';
import { getUTMParams, getUTMQueryString } from 'helpers/urlHelper';
import { v4 as uuidv4 } from 'uuid';

// custom components
import CountDown from './Components/CountDown';
import { remoteConfig, getHappeningOn, getHappeningOn24 } from 'utils';
import ContactInfo from './Components/ContactInfo_ActivEdge';
import DealInfoComponent from './Components/DealInfo_ActivEdge';
import WarningPopup from './Components/WarningPopup';
import {
    SubTitle, CardContainer, HeaderLine, PriceLabel,
    SpinnerDiv, SubmitButton, Terms, Description, DateTimeData, SeatingInfo, Underline, TicketType, boxStyling, boxStylingNoPadding, Caption
} from './Components/Styled';
import { TIME_IS_UP, NO_SLOT, STOP_TIMER, START_TIMER, RESET_TIMER, PROCESSING_ERROR, RESERVE_ERROR } from 'utils/constants';
import {
    getCanPurchase, getDealInfo,  reserveVoucher, B2BRegistration, getEvents,
    stripeRetrieveKey, stripeCreatePaymentLink, cancelReserveVoucher, getEnv, B2BValidate,
    retrieveGKey, completeTransaction, releaseDiscountCode, postToActivEdge, createWebformData, retrieveWebformData, updateAbandonCartLog, 
    verifyAbandonEmailPurchase
} from 'helpers/apiHelper';
import AccessDenied from 'components/Common/accessDenied';
import { setAlert } from "store/alerts/actions";
const sha512 = require('crypto-js/sha512');
import footerImg from '../../assets/images/PoweredByCardsPal.png'
import { initAmplifyAppUser } from 'helpers/authHelper'
import {loadStripe} from '@stripe/stripe-js';
const LazyBanner = React.lazy(() => import('./Components/Banner'))


const B2B_ActivEdge = (props) => {

    const [timerStatus, setTimerStatus] = useState(STOP_TIMER)
    const [nationality, setNationality] = useState([])
    const [countryCode, setCountryCode] = useState([])
    const [canPurchase, setCanPurchase] = useState(false)
    const [warningMessage, setWarningMessage] = useState({ show: false, type: '', message: '' })
    const [isBusy, setIsBusy] = useState(false)
    const [dealInfo, setDealInfo] = useState([])
    const [eventInfo, setEventInfo] = useState([])
    const [reserveVoucherInfo, setReserveVoucherInfo] = useState([])
    const [initialCount, setInitialCount] = useState(0)
    const [contactInfo, setContactInfo] = useState({})
    const [happeningOn, setHappeningOn] = useState('')
    const [isAccessDenied, setAccessDenied] = useState(null);
    const [prefill, setPrefill] = useState(null)
    const [custom, setCustom] = useState(null)
    const [mapKey, setMapKey] = useState('')
    const [invalid, setInvalid] = useState(true)
    const [qty, setQty] = useState(null)
    const [step, setStep] = useState("details")
    const [isQtyFixed, setIsQtyFixed] = useState(false)
    const [discountCampaign, setDiscountCampaign] = useState(null);
    const [discountAmt, setDiscountAmt] = useState(null)
    const [totalPrice, setTotalPrice] = useState(null)
    const [paymentPrice, setPaymentPrice] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [discountCodeReserved, setDiscountCodeReserved] = useState(null);
    const [stripeKey, setStripeKey] = useState('')
    const [publishableKey, setPublishableKey] = useState('')
    const [paymentLinks, setPaymentLinks] = useState({})
    const [priceId, setPriceId] = useState()
    const [userId, setUserId] = useState(null)
    const [msg, setMsg] = useState({ show: false, type: '', message: '' })
    const [stripePromise, setStripePromise] = useState("")
    const [prefillParams, setPrefillParams] = useState({})
    const [webForm, setWebForm] = useState(null)


    const idempotencyKey = uuidv4()
    const eventId = props.match.params.eventId;
    const dealId = props.match.params.dealId
    const webFormId = props.match.params.webFormId
    const params = new URLSearchParams(window.location.search);
    const payload = props.match.params.payload ? decodeURIComponent(props.match.params.payload) : null
    const imagePath = `https://assets.${getEnv()}.cardspal.com/public/Events/banner/`
    const faviconPath = `https://assets.${getEnv()}.cardspal.com/public/Events/favicon/`
    
    const location = props.location;
    const utmParams = getUTMParams(location);
    const utmQueryString = getUTMQueryString(utmParams)

    useEffect(async () => {
        getStripeKey()
        initAmplifyAppUser()
        setIsBusy(true)
        payload ? B2BValidatePayLoad(payload) : setInvalid(false)
        getKey()
        let customData = await getCustomSettings()
        await loadEventDealInfo(customData)
        getCountry(customData)
        setIsLoading(false)
        // const searchParams = new URLSearchParams(window.location.search)
        // if (searchParams.get.length>0) 
        //     setPrefill( Object.fromEntries([...searchParams]))

        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = `${faviconPath}${eventId}_favicon.ico`;
    }, [])

    useEffect( async() =>{
        if (custom?.dealInfo[dealId]?.isfixedQty && custom?.dealInfo[dealId]?.issueQty && (qty === custom?.dealInfo[dealId]?.issueQty)) 
            if (params.get("formPK") && params.get("emailLogsPK")){
                await prefillWebForm(params.get("formPK"), params.get("emailLogsPK"))
                setPrefillParams({formPK:params.get("formPK"), emailLogsPK:params.get("emailLogsPK")})
            }    
    },[qty])

    useEffect( async() =>{
        if (publishableKey) setStripePromise(()=> loadStripe(publishableKey))
    },[publishableKey])

    useEffect(() => {
        if (discountCampaign && discountCampaign.code) {
            try {
                // console.log('discount campaign', discountCampaign)
                const priceId = custom.dealInfo[dealId]['stripePriceIds'][discountCampaign?.code?.toUpperCase()]
                setPriceId(priceId)
            } catch (error) {
                setPriceId(null)
                setPaymentLinks({})
            }
        } else {
            //console.log('no discount campaign')
            setPriceId(null)
            setPaymentLinks({})
        }
    }, [discountCampaign])

    // TODO: Not used
    useEffect(() => {
        if (!priceId) {
            setPriceId(null)
            setPaymentLinks({})
        }
    }, [priceId])

    useEffect(() => {
        if (eventInfo?.name) {
            document.title = eventInfo.name;
        }
    }, [eventInfo]);

    useEffect(() => {

        if (canPurchase) {
            window.history.pushState(null, "", window.location.href);
            window.onpopstate = () => {
                window.history.pushState(null, "", window.location.href);
                // cancelReservation()
                setCanPurchase(false)
            }

        }

        return () => {
            if (canPurchase) {
                setCanPurchase(false)
            }
        }

    }, [canPurchase]);

    useEffect(()=>{
        setWarningMessage(msg)
    }, [msg])


    const loadEventDealInfo = async (customData) => {
        let deal = await getDealInfo(dealId)
        setDealInfo(deal)
        let updatedQty = 1
        if (customData?.dealInfo[dealId]?.isfixedQty && customData?.dealInfo[dealId]?.issueQty) {
            updatedQty = customData?.dealInfo[dealId]?.issueQty
            setQty(updatedQty)
            setIsQtyFixed(true)
        }
        else if(customData?.dealInfo[dealId]?.isfixedQty && customData?.dealInfo[dealId]?.fixQty) {
            updatedQty = customData?.dealInfo[dealId]?.fixQty
            setQty(updatedQty)
            setIsQtyFixed(true)
        }

        setTotalPrice((deal.data.purchasePrice * updatedQty).toFixed(2))
        setPaymentPrice((deal.data.purchasePrice * updatedQty).toFixed(2))

        let event = await getEvents(eventId)
        setEventInfo(event?.data?.events)

        if (deal.statusCode === '200' && event.statusCode === '200') {
            let _datetime = getHappeningOn24(event?.data?.events?.startTimestamp, event?.data?.events?.endTimestamp)
            setAccessDenied(false)
            setHappeningOn(_datetime)
        } else {
            setAccessDenied(true)
        }
        setIsBusy(false)
    }

    const getCountry = async (customData) => {
        let rmCountry = await remoteConfig("country")
        let parseCountry = JSON.parse(rmCountry._value)
        let countryList = parseCountry.filter(item => {
            return !!item.nationality
        })
        countryList.sort((a, b) => (a.priority != null ? a.priority : Infinity) - (b.priority != null ? b.priority : Infinity))
        const _nationality = countryList.map(item => {
            return {
                value: item.nationality,
                label: item.nationality,
            }
        })

        let _countryCode = countryList.map(item => {
            return{
                value: `+${item.code}`,
                label: `+${item.code}`
            }
        })

         _countryCode = _countryCode.filter((x) => x.value.includes(customData?.countryRestriction))

        setNationality(_nationality)
        setCountryCode(_countryCode)
    }

    const B2BValidatePayLoad = async (payload) => {
        let res = await B2BValidate({
            "encryptedData": payload
        })
        if (res.statusCode === 200) {
            setInvalid(false)
            setPrefill(JSON.parse(res.body))
            // JSON.parse(res.body).email ? cancelReservation(JSON.parse(res.body).email) : null
        }
    }

    const getKey = async () => {
        let res = await retrieveGKey()
        if (res.success === "200")
            setMapKey(res.data.GOOGLE_API_KEY)
    }

    const getCustomSettings = async () => {
        let cust = await remoteConfig("b2bCustom")
        if (cust._value) {
            let parseCustJson = JSON.parse(cust._value)
            setCustom(parseCustJson[eventId])
            return (parseCustJson[eventId])
        } else return null
    }

    const getPlatformFee = async (dealId, quantity, purchasePriceTotal) => {
        let flatFees_custom = await remoteConfig("stripeFlatFees")
        let percentageFees_custom = await remoteConfig("stripePercentageFees")
        let flatFees = JSON.parse(flatFees_custom._value)
        let percentageFees = JSON.parse(percentageFees_custom._value)

        let flatFee = null
        let percentageFee = null
        let platformFee = 0.00
        for (let type in flatFees) {
            if (flatFees[type].pks?.includes(dealId)) {
                flatFee = parseFloat(flatFees[type].fee) * parseFloat(quantity)
            }
        }
        for (let type in percentageFees) {
            if (percentageFees[type].pks?.includes(dealId)) {
                percentageFee = parseFloat(purchasePriceTotal) * parseFloat(percentageFees[type].fee)
            }
        }
        if (flatFee && percentageFee) {
            platformFee = parseFloat((flatFee + percentageFee) * 1.08).toFixed(2)
        } else if (flatFee) {
            platformFee = flatFee
        } else if (percentageFee) {
            platformFee = percentageFee
        }
        // console.log(flatFee, percentageFee, platformFee)
        return platformFee * 100
    }

    const reservation = async (data) => {
        const forceReservation = true // for seedly, we force reservation to avoid checking if user has already reserved or purchased
        let reserveV = await reserveVoucher(data, forceReservation)
        if (reserveV.statusCode === 200 && Object.entries(reserveV?.data)?.length > 0) {
            setInitialCount(reserveV?.data?.minsToKickout * 60)
            setReserveVoucherInfo(reserveV)
            //console.log('reserve vouchers: ', reserveV)
            setCanPurchase(true)
            setTimerStatus(START_TIMER)
            setStep('payment')
            setIsBusy(false)
        } else if (reserveV.error) {
            setWarningMessage({ show: true, type: RESERVE_ERROR, message: reserveV.error })
        }

    }

    const onSubmitContactInfo = async (e) => {
        setIsBusy(true)
        let phoneNumber = e.phoneNumber ? `${e.countryCode}${e.phoneNumber}` : '+65'
        
        Object.keys(e).forEach((key) => {   //remove empty values
            if(!e[key] && typeof e[key] !== 'boolean') delete e[key]
        })
        
        e['phoneNumber'] = phoneNumber

        //remove non saving form values
        delete e['countryCode']
        delete e['otpVerify']
        delete e['otpCountryCode']
        delete e['otpPhoneNumber']
        

        if( qty && qty > 1){
            e['participants'] = []
            for(let x=2; x<= qty ; x++){
                let phoneNumberTemp = e['phoneNumber'+x] ? `${e['countryCode'+x]}${e['phoneNumber'+x]}` : '+65'
                e['phoneNumber'+x] = phoneNumberTemp
                let details= {
                    "firstName": e['firstName'+x],
                    "lastName": e['lastName'+x],
                    "phoneNumber": e['phoneNumber'+x],
                    "email": e['email'+x]
                }
                delete e['firstName'+x]
                delete e['lastName'+x]
                delete e['phoneNumber'+x]
                delete e['email'+x]
                e.participants.push(details)
                delete e['countryCode'+x]
            }
        }

        await onSubmitForPayment(e.email, e.userId)

        // we create the webform data after we reserved the voucher for the user in case the user abandon the payment
        let webFormData = {
            "webFormId": webFormId, 
            ...e,
            dealId: dealId,
            eventsId: eventId,
            status: '0'
        }
        const webFormResult = await createWebformData(webFormData)
        let body = webFormResult.body
        if (body && typeof(body) === 'string') {
            body = JSON.parse(body)
        }
        setWebForm(body)
        setIsBusy(false) 
        setUserId(e.userId)
        setContactInfo({ ...e })
    }

    const prefillWebForm = async (formPK, emailLogsPK) =>{
        try{
            setIsBusy(true)
            const verifyAbandonEmailPurchaseResult = await verifyAbandonEmailPurchase(formPK)
            const webFormData = verifyAbandonEmailPurchaseResult.webFormData
            if (verifyAbandonEmailPurchaseResult.statusCode === 200 && verifyAbandonEmailPurchaseResult.hasPurchase) {
                // user has already purchased from this email thus forward to success page

                let _eventInfo = eventInfo
                if (!eventInfo?.pk) {
                    _eventInfo = await getEvents(eventId)
                }
                const queryString = `?dealId=${dealId}&eventId=${_eventInfo?.data?.events?.pk}&qty=${qty}&userId=${webFormData?.userId}&email=${webFormData?.email}`
                window.location.href = `/webform/${webFormData?.webFormId}/B2BPaymentSuccessful${queryString}`
            } else {
                    await updateAbandonCartLog("user-click-email",{formPK:formPK, emailLogsPK:emailLogsPK})
                    await onSubmitForPayment(webFormData.email, webFormData.userId)
                    setUserId(webFormData.userId)
                    setContactInfo({ ...webFormData })
                    setIsBusy(false)
            }

        }catch (error) {
            setWarningMessage({ show: true, type: PROCESSING_ERROR })
            console.error('prefillWebForm', error)
        }

    } 

    const onSubmitForPayment = async (email,userId) => {
        try {
            window.gtag("event", "submitForPayment", { 'debug_mode':true });
            setIsBusy(true)
            let data = { userId: userId ? userId : email, dealId: dealId, qty: qty }
            let getUserCanPurchase = await getCanPurchase(data)
            if (getUserCanPurchase.statusCode === 200) {
                if (getUserCanPurchase?.canPurchase) {
                    let obj = { dealId: dealId, userId: userId ? userId : email, numberOfVouchers: qty }
                    await reservation(obj)
                } else {
                    setWarningMessage({ show: true, type: NO_SLOT })
                }
            }
        } catch (error) {
            setWarningMessage({ show: true, type: PROCESSING_ERROR })
            console.error('onSubmitContactInfo', error)
        }
    }
     
    async function getStripeKey() {
        const key = await stripeRetrieveKey()
        if (!key) {
            setAlert(`Error loading this page. Please report to admin immediately.`, 'danger')
        } else {
            setStripeKey(key.stripeSecret)
            setPublishableKey(key.publishableKey)
        }
        return key.stripeSecret
    }



    const onChangeContactInfo = (e) => {
        const { value, name } = e.target
    }
    const onBlurContactInfo = async (e) => {
        const { value, name } = e.target
    }

    const goToPaymentSuccessful = async () => {          
            if (prefillParams) await updateAbandonCartLog("user-make-purchase", prefillParams)
            dealInfo.data.happeningOn = happeningOn
        const queryString = `?dealId=${dealId}&eventId=${eventInfo?.pk}&qty=${qty}&userId=${userId}&email=${contactInfo?.email}`
            
            setIsBusy(false)
            window.location.href = `/webform/${webFormId}/B2BPaymentSuccessful${queryString}&${utmQueryString}`
    
                
    }

    const tryAgain = () => {
        setIsBusy(true)
        setWarningMessage({ show: false, type: '', message: '' })
        setTimerStatus(STOP_TIMER)
        setInitialCount(0)
        setStep("details")
        setIsBusy(false)
    }

    const getTimerStatus = (e) => {
        if (e === 'Stopped') {
            setWarningMessage({ show: true, type: TIME_IS_UP })
        }
    }

    const RenderDetailsPage = () => {
        let dealCustom = custom?.dealInfo[dealId]
        return (
            <div>
                <CardContainer style={boxStyling}>
                    <div>
                            <Row>
                                <Col><Image style={{ minHeight:"10vh", maxHeight:"40vh", borderRadius: "14px"}} fluid src={dealInfo?.data?.image_url}/></Col>
                                <Col style={{marginLeft:0, paddingLeft:0}}>
                                <div style={{ marginInline: 10 }}>
                                    <div style={{ color: "#272833", fontWeight: 500, fontSize: 15, marginInline: 5, marginBottom: 5 }}>{eventInfo?.name}</div>
                                <div style={{ color: "#272833", fontWeight: 800, fontSize: 17, marginInline: 5 }}>{dealInfo?.data?.promotion_caption}</div>
                            {/* {dealCustom?.type === 'bundle' ?
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ color: "#414858", fontWeight: 800, fontSize: 20, marginInline: 5 }}>{"S$" + (Number(dealInfo.data.purchasePrice) * qty).toFixed(2)}</div>
                                    <div style={{ color: "#414858", fontWeight: 800, fontSize: 14, alignSelf: "center", marginTop: 3 }}>FOR {qty}</div>
                                </div>
                                : dealCustom?.type === 'vip' || dealCustom?.type === 'free' ?
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ color: "#414858", fontWeight: 800, fontSize: 21, marginInline: 5 }}>{"S$0.00"}</div>
                                    </div>
                                    :
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ color: "#414858", fontWeight: 800, fontSize: 21, marginInline: 5 }}>{"S$" + Number(dealInfo.data.purchasePrice).toFixed(2)}</div>
                                        <div style={{ color: "#414858", fontWeight: 800, fontSize: 15, alignSelf: "center", marginTop: 3 }}>EACH</div>
                                    </div>
                            } */}
                            {custom?.dealInfo[dealId]?.info1 &&
                                <div style={{ display: "flex", flexDirection: "row", margin: 5 }}>
                                    <CheckIcon style={{ color: dealCustom?.color }} />
                                    <div style={{ color: "#6B6C7E", fontSize: 12, marginInlineStart: 10, marginInlineEnd: 5 }}>{custom?.dealInfo[dealId]?.info1}</div>
                                </div>
                            }
                            {custom?.dealInfo[dealId]?.info2 &&
                            <div style={{ display: "flex", flexDirection: "row", margin: 5 }}>
                                <CheckIcon style={{ color: dealCustom?.color }} />
                                <div style={{ color: "#6B6C7E", fontSize: 12, marginInlineStart: 10, marginInlineEnd: 5 }}>{custom?.dealInfo[dealId]?.info2}</div>
                            </div>
                            }
                            {custom?.dealInfo[dealId]?.info3 &&
                            <div style={{ display: "flex", flexDirection: "row", margin: 5 }}>
                                <CheckIcon style={{ color: dealCustom?.color }} />
                                <div style={{ color: "#6B6C7E", fontSize: 12, marginInlineStart: 10, marginInlineEnd: 5 }}>{custom?.dealInfo[dealId]?.info3}</div>
                            </div>
                            }
                        </div>
                        {dealCustom?.showTicketInfo ?
                        <>
                            {dealCustom?.type === 'vip' ?
                            <div style={{ margin: 10 }}>
                                <div style={{ color: "#A7A9BC", fontSize: 12, marginInlineStart: 10, marginInlineEnd: 5 }}>- Each unique promo code is eligible for one redemption only.</div>
                                <div style={{ color: "#A7A9BC", fontSize: 12, marginInlineStart: 10, marginInlineEnd: 5 }}>- Enter your promo code at the payment page to enjoy one complimentary ticket.</div>
                            </div>
                            : dealCustom?.type === 'free' ?
                                <div style={{ margin: 10 }} />
                                :
                            <div className="d-none d-sm-none d-xs-none d-md-block" style={{ margin: 10 }}>
                                <div style={{ color: "#A7A9BC", fontSize: 16, marginInlineStart: 10, marginInlineEnd: 5 }}>{dealInfo?.data?.promotion}</div>
                                <br/>
                                <div style={{ color: "#A7A9BC", fontSize: 12, marginInlineStart: 10, marginInlineEnd: 5 }}>- Limited tickets, while stock last</div>
                                <div style={{ color: "#A7A9BC", fontSize: 12, marginInlineStart: 10, marginInlineEnd: 5 }}>- Have a promo code? Enter it at the payment page to enjoy further discount! </div>
                            </div>
                            }
                        </>:
                        <div className="d-none d-sm-none d-xs-none d-md-block" style={{ margin: 10, marginTop:24}}>
                            <div style={{ color: "#A7A9BC", fontSize: 22, marginInlineStart: 10, marginInlineEnd: 5 }}>{dealInfo?.data?.promotion}</div>
                        </div>
                        }

                        </Col>
                        </Row>
                        <Row className="d-xl-none d-lg-none d-md-none d-sm-block d-xs-none">
                            <div style={{marginTop:10, marginBottom:10}}>
                                <div style={{ color: "#A7A9BC", fontSize: 16, marginInlineStart: 10, marginInlineEnd: 5 }}>{dealInfo?.data?.promotion}</div>
                                {dealCustom?.showTicketInfo &&
                                <>
                                    <br/>
                                    <div style={{ color: "#A7A9BC", fontSize: 12, marginInlineStart: 10, marginInlineEnd: 5 }}>- Limited tickets, while stock last</div>
                                    <div style={{ color: "#A7A9BC", fontSize: 12, marginInlineStart: 10, marginInlineEnd: 5 }}>- Have a promo code? Enter it at the payment page to enjoy further discount! </div>
                                </>}
                            </div>   
                        </Row>

                    </div>

                </CardContainer>
                <CardContainer style={boxStyling}>

                <Row >
                    <Col>
                        <p>Location</p>
                    </Col>
                    <Col className='d-flex justify-content-end'>
                            <TicketType>{eventInfo?.location}</TicketType>
                    </Col>
                </Row>
                <Underline/>
                <Row >
                    <Col>
                        <p>Date Time</p>
                    </Col>
                    <Col className='d-flex justify-content-end'>
                        <DateTimeData>{happeningOn}{custom?.seatingInfoText ? <SeatingInfo>{custom?.seatingInfoText}</SeatingInfo> : null}</DateTimeData>
                    </Col>
                </Row>
                <Underline/>
                <Row>
                    <Col>
                        <p>Ticket Type</p>
                    </Col>
                    <Col className='d-flex justify-content-end'>
                        <TicketType>{dealInfo?.data?.ticketType}</TicketType>
                    </Col>
                </Row>

                </CardContainer>
                {publishableKey &&
                <DealInfoComponent
                    publishableKey={publishableKey}
                    dealInfo={dealInfo}
                    dealId={dealId}
                    countryCode={countryCode}
                    onChange={(e) => onChangeContactInfo(e)}
                    prefill={prefill}
                    type={custom?.dealInfo[dealId]?.type}
                    isDisabled={isBusy}
                    qty={qty}
                    isQtyFixed={isQtyFixed}
                    totalPrice={totalPrice}
                    paymentPrice={paymentPrice}
                    setQty={(qty) => setQty(qty)}
                    setTotalPrice={(price) => setTotalPrice(price)}
                    setPaymentPrice={(price) => setPaymentPrice(price)}
                    discountCampaign={discountCampaign}
                    setDiscountCampaign={(campaign) => setDiscountCampaign(campaign)}
                    discountAmt={discountAmt}
                    setDiscountAmt={(amt) => setDiscountAmt(amt)}
                    contactInfo={contactInfo}
                    step={step}
                    eventInfo={eventInfo}
                    discountCodeReserved={discountCodeReserved}
                    setDiscountCodeReserved={(obj) => setDiscountCodeReserved(obj)}
                    styling={boxStylingNoPadding}
                    stripePromise={stripePromise}
                    utm_source={utmParams.utm_source}
                    utm_medium={utmParams.utm_medium}
                    utm_campaign={utmParams.utm_campaign}
                    utm_term={utmParams.utm_term}
                    utm_content={utmParams.utm_content}              
                    webForm={webForm}
                    idempotencyKey={idempotencyKey}
                    webFormId={webFormId}
                    hasAccount={'true'}
                />
                }
                
                <ContactInfo
                    countryCode={countryCode}
                    onSubmit={(e) => onSubmitContactInfo(e)}
                    onChange={(e) => onChangeContactInfo(e)}
                    onBlur={(e) => onBlurContactInfo(e)}
                    prefill={prefill}
                    custom={custom}
                    isDisabled={isBusy}
                    contactInfo={contactInfo}
                    styling={boxStylingNoPadding}
                    cpSignIn={true}                   
                    issueQty= {custom?.dealInfo[dealId]?.issueQty}
                    webFormId={webFormId}
                    eventsId={eventId}
                />
                <div>
                </div>
            </div>
        )
    }
    const RenderPaymentPage = () => {
        let dealType = custom?.dealInfo[dealId]?.type
        return (
            <div>
                <DealInfoComponent
                    publishableKey={publishableKey}
                    dealInfo={dealInfo}
                    dealId={dealId}
                    countryCode={countryCode}
                    onChange={(e) => onChangeContactInfo(e)}
                    prefill={prefill}
                    type={dealType}
                    isDisabled={isBusy}
                    qty={qty}
                    isQtyFixed={isQtyFixed}
                    totalPrice={totalPrice}
                    paymentPrice={paymentPrice}
                    setQty={(qty) => setQty(qty)}
                    setTotalPrice={(price) => setTotalPrice(price)}
                    setPaymentPrice={(price) => setPaymentPrice(price)}
                    discountCampaign={discountCampaign}
                    setDiscountCampaign={(campaign) => setDiscountCampaign(campaign)}
                    discountAmt={discountAmt}
                    setDiscountAmt={(amt) => setDiscountAmt(amt)}
                    contactInfo={contactInfo}
                    step={step}
                    eventInfo={eventInfo}
                    discountCodeReserved={discountCodeReserved}
                    userId={userId}
                    reserveVouchers={reserveVoucherInfo?.data?.vouchers}
                    setDiscountCodeReserved={(obj) => setDiscountCodeReserved(obj)}
                    goToPaymentSuccessful={(transactionId) => goToPaymentSuccessful(transactionId)}
                    warningPopup={(e) => setWarningMessage(e)}
                    setIsBusy={(e)=>setIsBusy(e)}
                    styling={boxStylingNoPadding}
                    stripePromise={stripePromise}
                    utm_source={utmParams.utm_source}
                    utm_medium={utmParams.utm_medium}
                    utm_campaign={utmParams.utm_campaign}
                    utm_term={utmParams.utm_term}
                    utm_content={utmParams.utm_content}              
                    webForm={webForm}
                    webFormId={webFormId}
                    hasAccount={'true'}
                    idempotencyKey={idempotencyKey}
                />
                {/* <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    {!isWalletPayment &&
                    <>
                        <div style={{ textAlign: "center" }}>By clicking this button, you accept the <a target="_blank" rel='noreferrer' href="https://stripe.com/en-sg/legal"> Stripe Services Agreement</a>, <a href='https://cardspal.com/ticket-sales-terms-and-conditions/' target='_blank' rel='noreferrer'>CardsPal Terms of Use</a> and <a href='https://cardspal.com/privacy-policy/' target='_blank' rel='noreferrer'>CardsPal Privacy Policy</a>.</div>
                        <SubmitButton style={{ marginTop: 20 }}
                            title="Please provide a valid promo code before continue to payment."
                            disabled={dealType === "vip" && paymentPrice > 0}
                            onClick={() => gotoRegistrationSuccessful()}
                            type="submit"
                            className="btn btn-primary btn-block"
                        >Confirm Purchase
                        </SubmitButton>
                    </>
                    }
                </div>           */}
            </div >
        )
    }

    return (
        <React.Fragment>
            {isBusy ? <SpinnerDiv><Spinner /></SpinnerDiv> :
            (isAccessDenied === null) || invalid ? <></> :
                (isAccessDenied ? <AccessDenied screen="B2B" showBackToDashboard={false} /> :
                <>
                    <div style={{ backgroundColor: "#fff" }}>
                        <div  style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Suspense fallback={<SpinnerDiv><Spinner /></SpinnerDiv>}>
                            <LazyBanner imagePath= {imagePath + eventId + "_banner.jpg"} custom={custom} />
                        </Suspense>
                        </div>
                        <Container style={{ marginTop: 50 }}>
                                    {eventInfo?.registrationEnd <= moment().unix() ?
                                <CardContainer className="mt-3">
                                    <CardBody>
                                        <CardTitle>Registration closed</CardTitle>
                                        <p>Thank you for your interest. Unfortunately, the registration is now closed. We will make sure to keep you informed for future events.</p>
                                        <p>See you next time!</p>
                                    </CardBody>
                                </CardContainer>
                                :
                                <Row className="justify-content-center mt-3">
                                    {isLoading ? null :
                                        custom?.dealInfo[dealId].active ?
                                        <Col>
                                                {eventInfo?.name ?
                                                <div>
                                                    <Description style={{ fontSize: 14 }}>{eventInfo?.name + " > " + dealInfo?.data?.promotion_caption + " > Purchase Details"}</Description>
                                                    <div style={{ maxWidth: 400, margin: "auto" }}>
                                                        <div style={{ display: "flex", flexDirection: "row", marginInline: 30, marginBottom: 10, marginTop: 25, justifyContent: "center" }}>
                                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                <Circle>
                                                                    <TickIcon style={{ color: "#fff", marginBlock: 5, marginInline: 4 }} />
                                                                </Circle>
                                                            </div>
                                                            <div style={{ width: 80, border: "0.5px solid #CDCED9", marginTop: 13, marginInline: 10, alignSelf: "center" }} className='mb-3' />
                                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                <Circle style={{ backgroundColor: step === 'payment' ? "#2AD2C9" : "#722ED1" }}>
                                                                    <EditIcon style={{ color: "#fff", marginBlock: 4, marginInline: 4 }} />
                                                                </Circle>
                                                            </div>
                                                            <div style={{ width: 80, border: "0.5px solid #CDCED9", marginTop: 13, marginInline: 10, alignSelf: "center" }} className='mb-3' />
                                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                <Circle style={{ backgroundColor: step === 'payment' ? "#722ED1" : "#BFBFC1" }}>
                                                                    <WalletIcon style={{ color: "#fff", marginBlock: 5, marginInline: 5 }} />
                                                                </Circle>
                                                            </div>
                                                            <div style={{ width: 80, border: "0.5px solid #CDCED9", marginTop: 13, marginInline: 10, alignSelf: "center" }} className='mb-3' />
                                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                <Circle style={{ backgroundColor: "#BFBFC1" }}>
                                                                    <NotesIcon style={{ color: "#fff", marginBlock: 5, marginInline: 5 }} />
                                                                </Circle>
                                                            </div>
                                                        </div>
                                                        <div style={{ display: "flex", flexDirection: "row", marginInline: 30, marginBottom: 30, justifyContent: "center" }}>
                                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                <div style={{ color: "#6B6C7E", fontSize: 11, width: 90, marginLeft: -30 }}>Select Tickets</div>
                                                            </div>
                                                            <div style={{ width: 80, marginTop: 13, alignSelf: "center" }} className='mb-3' />

                                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                <div style={{ color: "#6B6C7E", fontSize: 11, width: 60 }}>Edit info</div>
                                                            </div>
                                                            <div style={{ width: 80, marginTop: 13, alignSelf: "center" }} className='mb-3' />

                                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                <div style={{ color: "#6B6C7E", fontSize: 11, width: 50 }}>Payment</div>
                                                            </div>
                                                            <div style={{ width: 80, marginTop: 13, alignSelf: "center" }} className='mb-3' />

                                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                <div style={{ color: "#6B6C7E", fontSize: 11, width: 50 }}>Confirmation</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className='text-center'>
                                                    <SubTitle className='m-1'>Ticket Registration</SubTitle>
                                                    <p>Please fill out the following form in order to reserve a ticket.</p>
                                                </div>
                                            }

                                            <div className='d-flex flex-row justify-content-between align-items-center'>
                                                <SubTitle style={{ marginLeft: 10 }}>{step === 'payment' ? "Complete Payment" : "Event"}</SubTitle>
                                                {step === "payment" ?
                                                    <div className='d-flex flex-row'>
                                                        <p className='px-3 align-self-center'>Pay within</p>
                                                        <CountDown
                                                            initialCount={initialCount}
                                                            setTimerStatus={timerStatus}
                                                            getTimerStatus={(e) => getTimerStatus(e)} />
                                                    </div> : null}
                                            </div>
                                            {step === 'details' ?
                                                <RenderDetailsPage />
                                                :
                                                <RenderPaymentPage />
                                            }
                                            </Col>   
                                            :
                                            <CardContainer style={{ backgroundColor: "#fff", maxWidth: 570, justifyContent: "center" }}>
                                                <div style={{ marginInline: 10, marginBlock: 20 }}>
                                                    <div style={{ color: "#000", fontWeight: 800, fontSize: 20, marginBlock: 10, textAlign: "center" }}>SOLD OUT</div>
                                                    <div style={{ fontWeight: 400, fontSize: 14, marginInline: 5, marginBlock: 15 }}>Thank you for your interest. Unfortunately, the tickets have been sold out.</div>
                                                    <div style={{ fontWeight: 400, fontSize: 14, marginInline: 5, marginBlock: 15 }}>For any queries, please email <p style={{ color: "#2B70C7", textDecorationLine: "underline", textDecorationColor: "#2B70C7", display: "inline" }}>support@seedly.sg</p></div>
                                                    <div style={{ fontWeight: 400, fontSize: 14, marginInline: 5, marginBlock: 15 }}>See you next time!</div>
                                                </div>
                                            </CardContainer>
                                    }
                                </Row>
                            }

                        <Row className="justify-content-center mt-3">
                            <Col className="col-sm-6 pd-3" align="center">
                                <Image style={{minHeight:"3vh", maxHeight:"5vh",marginBottom:"5vh"}} fluid src={footerImg}/>
                            </Col>
                        </Row>

                            <WarningPopup
                                open={warningMessage.show}
                                warningType={warningMessage.type}
                                message={warningMessage.message}
                                tryAgain={() => tryAgain()}
                                onOpened={() => { setTimerStatus(STOP_TIMER) }}
                            />
                            {isBusy &&
                                <SpinnerDiv>
                                    <Spinner />
                                 </SpinnerDiv>}
                        </Container>
                    </div>
                    <div>
                    <Row className="justify-content-center mt-5" style={{marginLeft:0, marginRight:0}} >Our Partners</Row>
                    <Row className="justify-content-center mt-3" style={{marginLeft:0, marginRight:0}} >
                        <Col className="col-sm-6 pd-3" align="center">
                            <Image style={{maxHeight:"10vh",marginBottom:"3vh"}} fluid src={imagePath + eventId + "_footer.png"}/>
                        </Col>
                    </Row>
                    </div>
                    </>
                )
            }
        </React.Fragment>

    )
}
const CheckIcon = styled(BsFillCheckCircleFill)`
  color: #3158d6;
  font-size: 16px;
`
const TickIcon = styled(GoCheck)`
  color: #3158d6;
  font-size: 16px;
`
const EditIcon = styled(BiEditAlt)`
  color: #3158d6;
  font-size: 16px;
`
const WalletIcon = styled(FaWallet)`
  color: #3158d6;
  font-size: 14px;
`
const NotesIcon = styled(IoIosPaper)`
color: #3158d6;
font-size: 14px;
`
const InfoIcon = styled(AiFillInfoCircle)`
color: #fff;
font-size: 16px;
`
const Circle = styled.div`
    background: #2AD2C9;
    width: 25px;
    height: 25px;
    border-radius: 50%;
`

export default B2B_ActivEdge