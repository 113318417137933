import { Auth, Amplify } from 'aws-amplify'
import amplitude from 'amplitude-js';
import { getEnv, getConfig } from '../helpers/apiHelper'
class AmplifyAuth {

    constructor(config) {
        Amplify.configure(config)
        const env = getEnv()
        
        Amplify.configure({
            Auth: {
                identityPoolId: config.aws_cognito_identity_pool_id, //REQUIRED - Amazon Cognito Identity Pool ID
                region: config.aws_cognito_region, // REQUIRED - Amazon Cognito Region
                userPoolId: config.aws_user_pools_id, //OPTIONAL - Amazon Cognito User Pool ID
                userPoolWebClientId: config.aws_user_pools_web_client_id, //OPTIONAL - Amazon Cognito Web Client ID
            },
            Storage: {
                AWSS3: {
                    bucket: `cardspal-assets-${env}`, // might need to change when we have preprod
                    region: config.aws_project_region
                }
            }
        });
    }

    signin = async (email, password) => {
        try {
            const user = await Auth.signIn(email, password)

            // temporary code below to handle new user not confirm yet. will be remove later
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                const { requiredAttributes } = user.challengeParam
                await Auth.completeNewPassword(user, password, requiredAttributes)
            }

            return user.signInUserSession.idToken.payload
        } catch (e) {
            amplitude.getInstance().logEventWithGroups('Error - signin', { 'details': e })
            return e.code
        }
        
    }

    signup = async (email, password, firstname, lastname, mobile, agree, consent) => {
        try {
            
            const result = await Auth.signUp({
                username: email,
                password: password,
                attributes: {
                    email: email,
                    given_name: firstname,
                    family_name: lastname,
                    "custom:mobile": mobile,
                    "custom:agree_terms": agree,
                    "custom:marketing_consent": consent
                }
            })

            return result
        } catch (e) {
            amplitude.getInstance().logEventWithGroups('Error - signup', { 'details': e })
            return e
        }
    }

    register =async () => {
        // TODO: create logic for user registration
    }

    // updateProfile = async() => {
    //     // TODO: create logic for update profile
    // }

    forgetPassword = async (email) => {
        try {
            const result = await Auth.forgotPassword(email)
            return result
        } catch (e) {
            amplitude.getInstance().logEventWithGroups('Error - forgetPassword', { 'details': e })
            return e
            // throw e.message
            
        }
    }

    forgetPasswordSubmit = async (email, code, password) => {
        // console.log(email, code, password)
        try {
            const result = await Auth.forgotPasswordSubmit(email, code, password)
            return result
        } catch (e) {
            amplitude.getInstance().logEventWithGroups('Error - forgetPasswordSubmit', { 'details': e })
            return e
            // throw e.message
        }
    }


    getAuthenticatedUser = async () => {
        try {
            const user = await Auth.currentUserInfo()
            return user.attributes
        } catch (e) {
            amplitude.getInstance().logEventWithGroups('Error - getAuthenticatedUser', { 'details': e })
            throw e.message
        }
    }

    verifyCode = async (email, code) => {
        try {
            const result = await Auth.confirmSignUp(email, code)
            return result
        } catch (e) {
            amplitude.getInstance().logEventWithGroups('Error - verifyCode', { 'details': e })
            return e
            // throw e.message
        }
    }

    resendCode = async (email) => {
        try {
            const result = await Auth.resendSignUp(email);
            // console.log('resendCode ', result)
            return result
        } catch (e) {
            amplitude.getInstance().logEventWithGroups('Error - resendCode', { 'details': e })
            return e
            // throw e.message
        }
    }

    signOut = async () => {
        try {
            await Auth.signOut();
        } catch (error) {
            amplitude.getInstance().logEventWithGroups('Error - signOut', { 'details': error })
            console.error('error signing out: ', error);
        }
    }
}

let _amplifyBackend = null
/**
 * Init the backend
 * @param {*} config
 */
const initAmplifyBackend = config => {
    if (!_amplifyBackend) {
        _amplifyBackend = new AmplifyAuth(config)
    }
    return _amplifyBackend
}
const initAmplifyAppUser = async () => {
    const config = await getConfig()
    if (config?.cmsv2?.appCognitoConfig) {
        const appCognitoConfig = config.cmsv2.appCognitoConfig
        Amplify.configure({
            Auth: {
                identityPoolId: appCognitoConfig.aws_cognito_identity_pool_id, //REQUIRED - Amazon Cognito Identity Pool ID
                region: appCognitoConfig.aws_cognito_region, // REQUIRED - Amazon Cognito Region
                userPoolId: appCognitoConfig.aws_user_pools_id, //OPTIONAL - Amazon Cognito User Pool ID
                userPoolWebClientId: appCognitoConfig.aws_user_pools_web_client_id, //OPTIONAL - Amazon Cognito Web Client ID
            }
        });
    }
}
/**
 * Returns the firebase backend
 */
const getAmplifyBackend = () => {
    return _amplifyBackend
}


export { initAmplifyBackend, initAmplifyAppUser, getAmplifyBackend }