import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, CloseButton } from 'react-bootstrap';
import { useSelector } from "react-redux";
import styled from 'styled-components';
import lottie from 'lottie-web';
import { createScanHistoryEntries, getScanHistoryByVoucherId, updateScanHistoryEntries } from '../../../../helpers/GraphQL/scanHistory';
import { remoteConfig } from 'utils';

const RedeemLandingModal = (props) => {
  const result = props.data;
  //console.log('ResultInitial', props)

  const container1 = useRef(null);
  const container2 = useRef(null);
  const viewRender =[]
  const {dealMaster, voucherUser, webForm, scanView, redemptionView, eventInfo, totalSold, totalVouchers, redeemedOtherVouchers, notRedeemedOtherVouchers, scanData} = props.data;
  const user = useSelector(state => state.User.user)
  const [redeemOtherList, setRedeemOtherList] = useState(redeemedOtherVouchers ? [...redeemedOtherVouchers] : [] )
  const [custom, setCustom] = useState(null)

  useEffect(async () => {
    lottie.loadAnimation({
      name: 'success',
      container: container1.current, 
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('../../../../assets/lottie/tick.json')
    });

    lottie.loadAnimation({
      name: 'error',
      container: container2.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('../../../../assets/lottie/fail.json')
    });
    return () => lottie.destroy(); //unmount component
  }, [])

  useEffect(async ()=>{
    let cust = await remoteConfig("b2bCustom")
    if (cust._value) {
        let parseCustJson = JSON.parse(cust._value)
        setCustom(parseCustJson[eventInfo?.pk])
    } 
  },[eventInfo])

  const handleClose = (params) => {
    props.close({...result, ...params})
  }

  const getConfirmationId = (masterId) => {
    return masterId?.substring(masterId?.length - 8) || "-"
  }

  const handleRedeemRemaining = async () => {

    let redeemed = redeemOtherList
    for (let i = 0; i < notRedeemedOtherVouchers.length; i++){
      try {
        const scanHistory = await getScanHistoryByVoucherId(notRedeemedOtherVouchers[i].masterId)
        if (scanHistory.length > 0) {
          const updateResult = await updateScanHistoryEntries(scanHistory[0], webForm, notRedeemedOtherVouchers[i], dealMaster, scanData)
          if (updateResult) redeemed.push(notRedeemedOtherVouchers[i])
        } else {
          const createResult = await createScanHistoryEntries(webForm, notRedeemedOtherVouchers[i], dealMaster, scanData, user.email)
          if (createResult) redeemed.push(notRedeemedOtherVouchers[i])
        }

      } catch (e) {
        console.error(e)
        //setErrorMsg('Error saving scanned data') //display error message in the RedeemLandingModal
      }
    }

    const results = await Promise.all(redeemed);
    setRedeemOtherList([...redeemed])
    
  }
  
  return (
    <>
      <Modal
        show={props.show}
        onHide={() => handleClose({showResultModal: false})} //pass param to parent when close
        centered
        scrollable={true}
      >     

        <div style={{ paddingLeft : custom?.dealInfo[dealMaster.pk]?.skuColorCode ? "20px" : "0px",
                      paddingRight :custom?.dealInfo[dealMaster.pk]?.skuColorCode ? "20px" : "0px",
                      backgroundColor: custom?.dealInfo[dealMaster.pk]?.skuColorCode ?? "white", overflow:"auto"}}>
        <ModalHeader>
          <Header>
            <TitleWrap>
              <H3>{custom?.groupRedemption ? result?.totalSold+ " x " : ""}{result?.dealMaster?.promotion_caption} - {result?.purchaseDate}</H3>
            </TitleWrap>
            <CloseWrap style={{paddingRight :custom?.dealInfo[dealMaster.pk]?.skuColorCode ? "20px" : "0px"}}>
              <CloseButton onClick={() => handleClose({showResultModal: false})} />
            </CloseWrap>
          </Header>
          {result?.errorMsg ? <Animation2 ref={container2} /> : <Animation1 ref={container1} />}
          {redeemOtherList?.length > 0 ? 
            <H2 style={{textAlign:"center"}}> Confirmation ID:
                <br/>
                #{getConfirmationId(result?.voucherUser?.masterId)}
                {redeemOtherList.map((vu,i) => {
                  return(
                   <div key={"redeemed_"+i}>#{getConfirmationId(vu.masterId)}</div>
                   )
                  })}
                  
            </H2>
          :
           <H2> Confirmation ID: #{getConfirmationId(result?.voucherUser?.masterId)}</H2>
          }

          {((result?.eventInfo &&  result?.eventInfo.hasCheckIn ==='1') || !result?.eventInfo) && (
            (result?.checkinTicket) ? 
            <CheckinButton>Checked-in</CheckinButton>
          : <NotCheckinButton>Not Checked-in</NotCheckinButton>
          )}
          {result?.errorMsg ? 
            <ModalErrorTitle>Redemption Failed! <Error>{result?.errorMsg}</Error></ModalErrorTitle>
          : 
          <SuccessMsgContainer>
            <ModalTitle>Redemption Successful!</ModalTitle>
            {result?.warningMessage ? <Warning>({result.warningMessage})</Warning>:null}
          </SuccessMsgContainer>
          }
        </ModalHeader>
        
        {(redemptionView && (redemptionView.length > 0))  ?
          <ModalBody key={"redeemBody"}>
            <ModalCard>
              {redemptionView.forEach((e,i) => {
                  viewRender.push(                
                    result && result.webForm && result.webForm[e.field] ? 
                        <div key={"redeem_"+i}>
                          <H3>{e.label}</H3>
                          <P>{  (result?.webForm[e.field] === '1'  && e.type === 'Boolean') ? "Yes" 
                                :(result?.webForm[e.field] === '0' && e.type === 'Boolean') ? "No" 
                                : (e.field === 'firstName')?  result?.webForm.firstName + " " + result?.webForm.lastName
                                :result?.webForm[e.field]
                              }
                          </P> 
                        </div>  
                    :
                        <div key={"h_redeem_"+i}>
                          <H3>{e.label}</H3>
                          <P>-</P> 
                        </div>
                  )
              })
              }
              {viewRender}
              {(eventInfo &&  eventInfo.hasCheckIn ==='1') && (
              (result?.checkinTicket) ? 
                <>
                  <H3>Check-in Person:</H3>
                  <P>{result?.checkinTicket?.firstName ?? "-"} {result?.checkinTicket?.lastName ?? "-"}</P> 
                </>
              : 
                <>
                  <H3>Check-in Person:</H3>
                  <P>N/A</P>
                </>
              )}
            </ModalCard>
          </ModalBody>
        :
          <ModalBody>
            <ModalCard>
              <H3>Bought by:</H3>
              <P>{webForm?.firstName} {webForm?.lastName}</P>
              <H3>Check-in Person:</H3>
              {(result?.checkinTicket) ? 
                <P>{result?.checkinTicket?.firstName ?? "-"} {result?.checkinTicket?.lastName ?? "-"}</P> 
              : <P>N/A</P>
              }
              <H3>Event Promo Code (if any):</H3>
              <P>{webForm?.eventPromoCode}</P>
            </ModalCard>
          </ModalBody>
        }
        { Number(totalSold) > 1 && !result?.errorMsg && custom?.groupRedemption ? 
          <ModalFooter>
            <RedeemButton variant="primary" disabled={((redeemOtherList.length) === notRedeemedOtherVouchers.length) || (notRedeemedOtherVouchers.length < 1)} onClick={handleRedeemRemaining}>Redeem all tickets in same transaction</RedeemButton>
            <Button variant="secondary" onClick={() => handleClose({scanNext: true, showResultModal: false})}>Scan next code</Button>
          </ModalFooter>
        :
          <ModalFooter>
            <Button variant="secondary" onClick={() => handleClose({scanNext: true, showResultModal: false})}>Scan next code</Button>
          </ModalFooter>
        }
        </div>
      </Modal>
    </>
  );
}

const ModalHeader = styled(Modal.Header)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;

`

const CloseBtn = styled(CloseButton)`
  position:absolue;

`

const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0.625rem;
`

const SKUColorTop = styled.div`
  width: 20px;
  height: 100%;
  margin-bottom: 0.125rem;
`

const SKUColorBottom = styled.div`
  width: 100%;
  height: 20px;
  margin-top: 0.125rem;
`

const TitleWrap = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  margin: auto; //center title
`

const CloseWrap = styled.div`
  display: block;
  position:absolute;
  right:5%;
`

const ModalTitle = styled(Modal.Title)`
  color: #5ACA75;
  font-size: 18px;
  font-weight: bold;

  @media (max-width: 375px) {
    font-size: 16px;
  }
`

const ModalErrorTitle = styled(Modal.Title)`
  color: #EC536C;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5rem;
  width: 55%;
  text-align: center;

  @media (max-width: 430px) {
    width: 70%;
  }

  @media (max-width: 375px) {
    font-size: 16px;
  }

  @media (max-width: 320px) {
    width: 90%;
  }
`

const Error = styled.div`
  margin-top: 0.3rem;
  font-size: 18px;

  @media (max-width: 375px) {
    font-size: 16px;
  }
`

const Warning = styled.div`
  margin-top: 0.3rem;
  font-size: 12px;
  color: #EC536C;
  @media (max-width: 375px) {
    font-size: 12px;
  }
`

const ModalBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  background: #fff;
  background-color: white;
`

const ModalCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: #F8F9FA;
  border-radius: 0.3125rem;
  color: #000;
  padding: 1.25rem;
`

const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
`

const RedeemButton = styled(Button)`
  width:98%;
  
`

const Animation1 = styled.div`
  width: 100px;
  height: 100px;
`

const Animation2 = styled.div`
  width: 70px;
  height: 70px;
  margin-top: 0.625rem;
`

const CheckinButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 20px;
  width: 120px;
  height: 25px;
  margin-bottom: 1rem;
`

const NotCheckinButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #EC536C;
  border: none;
  border-radius: 20px;
  width: 130px;
  height: 25px;
  margin-bottom: 1rem;

  &:hover, &:focus {
    background: #EC536C;
  }
`

const H2 = styled.h2`
  font-size: 16px;
  font-weight: bold;
  color: #000;
  margin: 1rem 0;
`

const H3 = styled.h3`
  font-size: 14px;
  color: #000;
  margin: 0;
`

const P = styled.p`
  font-size: 14px;
  color: #000;
  margin-bottom: 1rem;
`

const SuccessMsgContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
`

export default RedeemLandingModal;