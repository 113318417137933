import {
  REGISTER_USER
} from "./actionTypes"

const initialState = {
  user:{}
}

const account = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER:
      return {
        ...state,
        user: action.payload.user
      }
    
    default:
      state = { ...state }
      break
  }
  return state
}

export default account
