import React, { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Image, Tooltip, OverlayTrigger } from "react-bootstrap"
import { Row, Col, Modal, ModalFooter, ModalHeader, ModalBody, Button, Label, Alert, Spinner, InputGroup, InputGroupText } from "reactstrap"
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from "availity-reactstrap-validation"
import { ErrorMessage } from '@hookform/error-message';
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Editor } from '@tinymce/tinymce-react';
import { fromUnixTime, getUnixTime, addDays, format as dateFnsFormat, differenceInHours, isBefore, endOfDay } from 'date-fns'

// import components
import ImageUploader from  '../../../../components/Common/imageUploader'

 
// import graphql functions
import  { getBrandInfo } from  '../../../../helpers/GraphQL/brands'
import { create as createOutlet, getOutlet } from '../../../../helpers/GraphQL/outlet'


// import helpers
import { getConfig, getEnv, createNewEvents, updateEvents, getEvents, getGeoCodeFromLatLong, getVoucherGroup, invalidateCloudFrontCache, verifyEventDuplicateString } from 'helpers/apiHelper'

// import utils
import { getAssetUrl, handleImageUpload} from '../../../../utils'
import { LATLONG_REGEX, URL_REGEX, ALPHANUMERIC_REGEX} from "utils/constants";

// import store actions
import { setConfig, setEnv, setAmplitudeEvent } from '../../../../store/actions'



const EventForm = (props) => {
    // console.log('EventForm ',props.event)
    const dispatch = useDispatch()
    const selectedMerchant = useSelector(state => state.Merchant.selectedMerchant)
    const cmsConfig = useSelector(state => state.Config)
    const user = useSelector(state => state.User.user)
    const editorRef = useRef(null);
    const [processing, setProcessing] = useState(false)
    const [transactionMessage, setTransactionMessage] = useState(null)
    const [croppedImages, setCroppedImages] = useState({})
    const [event, setEvent] = useState({})
    const [recommendedEndDateMin, setRecommendedEndDateMin] = useState('2022-03-28')
    const [recommendedEventEndDateMin, setRecommendedEventEndDateMin] = useState('2022-03-28')
    const [eventEndDateError, setEventEndDateError] = useState(null)
    const [disableEditing, setDisableEditing] = useState(false)

    const newCat = { categoryName:"", totalQuantity:1, id:"GID-1"}
    const [categoryDetails, setCategoryDetails] = useState([newCat])
    const CONSTANTS = {INCREMENT : "increment", DECREMENT : "decrement", QUANTITY : "totalQuantity"}
    const [updateMinCat, setUpdateMinCat] = useState(0)

    const tooltip = () => (
        <Tooltip id="tooltip" style={{width:"300px", textAlign:"left"}}>
          <strong>1. Open Google Maps on a new tab.</strong>
          <br/>
          <strong>2. Search for the location you wish to pin. Right-click the place or area on the map. This will open a pop-up window. You can find your latitude and longitude in decimal format at the top.</strong>
          <br/>
          <strong>3.To copy the coordinates automatically, left click on the latitude and longitude.</strong>

        </Tooltip>
      );
    


    useEffect( async()=>{
        await getCMSConfig()
    }, [])

    useEffect(async () => {
        const disabled = props.mode==='update' && (props.event.valid==='Y')
        setDisableEditing(disabled)
        setEvent({...props.event})
        setRecommendedEndDateMin(dateFnsFormat(addDays(new Date(), 1), "yyyy-MM-dd 00:00:00"))
        setRecommendedEventEndDateMin(dateFnsFormat(addDays(new Date(), 1), "yyyy-MM-dd 23:59:59"))

        if (props.mode==='create') {
          // console.log("creating")
        } else {
            try {
                const voucherGroups = await getVoucherGroup(props.event.pk)
                setCategoryDetails([...voucherGroups.data])
                setUpdateMinCat(voucherGroups.data.length)        
            } catch(e) {
                 console.error('error on getVoucherGroup',e)
                 setTransactionMessage({msg: 'Please report to the Administrator.[Voucher group retrieval issue]', type: 'danger'})
                 dispatch(setAmplitudeEvent('CMS Events page - Error Voucher Group retrieval issue', {'details': JSON.stringify(e)}))
            }
        }
    }, [props.event])

    
    async function getCMSConfig() {
        try {
            if (!cmsConfig || !cmsConfig.cmsv2) {
                // console.log('no config so retrieving from source')
                const config = await getConfig()

                const env = getEnv()
                dispatch(setConfig(config))
                dispatch(setEnv(env))
            }
        } catch(error) {
            console.error('error on retrieving config file', error)
            dispatch(setAmplitudeEvent('CMS Events page - event form getCMSConfig function error', {'details': JSON.stringify(error)}))
        }
    }
    
    async function handleChange(e, fieldName) {
        setTransactionMessage(null)

        let item = { ...event }
        if (e.target.type === 'checkbox') {
            if (fieldName === 'valid') {
                item[fieldName] = e.target.checked ? '1' : '0'
            }else if (fieldName === 'isOnline') {
                item[fieldName] = e.target.checked ? 'True' : 'False';

                if(e.target.checked){
                    item.locationDetails['postalCode'] = ""
                    item.locationDetails['locality'] = ""
                    item.locationDetails['country'] = ""
                    item.locationDetails['region'] = ""
                    item.locationDetails['address'] = ""
                    item.locationDetails['name'] = ""
                    item['latitude'] = ""
                    item['longitude'] = ""
                    item['location'] = "Online"
                }else{
                    if( item['location'] === "Online") item['location'] = ""
                }

            } else {
                item[fieldName] = e.target.checked ? 'True' : 'False';
            }
        } else {
            let value = e.target.value

            if(fieldName === 'address'){
                item.locationDetails['name'] = value
            }else if(fieldName === 'latlong'){
                item.locationDetails['postalCode'] = ""
                item.locationDetails['locality'] = ""
                item.locationDetails['country'] = ""
                item.locationDetails['region'] = ""
                item.locationDetails['address'] = ""
                item['latitude'] = ""
                item['longitude'] = ""
                let latlngReg = new RegExp(LATLONG_REGEX)
                if(latlngReg.exec(value)){
                    let latlng = value.split(',')
                    const res = await getGeoCodeFromLatLong({lat:latlng[0].trim(), long:latlng[1].trim()})
                    const resObj = JSON.parse(res)
                    item['latitude'] = latlng[0].trim()
                    item['longitude'] = latlng[1].trim()

                    if(resObj.status === "ZERO_RESULTS") return
                    
                    const postalCode = resObj?.results[0]?.address_components?.filter(component => component.types[0] ==='postal_code')
                    const locality = resObj?.results[0]?.address_components?.filter(component => component.types[0] ==='locality')
                    const country = resObj?.results[0]?.address_components?.filter(component => component.types[0] ==='country')


                    item.locationDetails['postalCode'] = postalCode[0]?.long_name || ""
                    item.locationDetails['locality'] = locality[0]?.short_name || ""
                    item.locationDetails['country'] = country[0]?.short_name || ""
                    item.locationDetails['region'] = country[0]?.long_name || ""
                    item.locationDetails['address'] = resObj.results[0]?.formatted_address || ""

                }
               
            }else if(fieldName === 'description'){
                item[fieldName] = e.target.getContent()
            }else if(fieldName==='name'){
                item[fieldName] = value
                item['uniqueString'] = value.replaceAll(" ", "-").replaceAll(/[^a-zA-Z0-9-]/gi, '')
                
            }else if(fieldName==='uniqueString'){
                item[fieldName] = value     
            }
            else item[fieldName] = value
        }
        setTransactionMessage(null)
        setEvent(item)
    }

    async function handleSubmit(e) {
        e.preventDefault()
        e.stopPropagation()
        
        // get the updated merchant info from DB in case webhook is updating stripe and other info and the user has not logout yet causing the store to be an old-data
        const brand = await getBrandInfo(selectedMerchant.merchant_id)

        try{
            const checkEventUniqueString = await verifyEventDuplicateString(event.uniqueString , event.pk)
            if (!(checkEventUniqueString === 'no_data_found') && !(checkEventUniqueString?.data?.events?.pk === event.pk)){
                setTransactionMessage({ msg: "Site address already exist, please manually input a new site address", type: 'danger' })
                return
            }
        }catch(error){
            //
        }


        if (!brand.stripeExpressConnectedAccountId) {
            setTransactionMessage({ msg: `Cannot ${props.mode} voucher. Please report to the Admin to resolve your account`, type: 'danger'})
            return
        }
        const isPast = isBefore(fromUnixTime(event.startTimestamp), new Date())
        const eventHrsDiff = differenceInHours(fromUnixTime(event.endTimestamp), fromUnixTime(event.startTimestamp))
        if ((!event.eventBannerUrl && !croppedImages.bannerImage) || 
            (!event.startTimestamp || !event.endTimestamp )) {
            setTransactionMessage({ msg: "Missing required fields. Please check fields mark with *", type: 'danger' })
            return
        } else if (eventHrsDiff < 24) {
            setTransactionMessage({ msg: "Event End Date & Time should be at least 24hrs from Event Start Date", type: 'danger' })
            return
        } else if (isPast && props.mode==='create') {
            setTransactionMessage({ msg: "Event Start Date & Time should be future date/time", type: 'danger' })
            return
        } 

        // Event details for savings
        let _event = {...event}
        let _voucherGroups = categoryDetails


        const uniqueCategoryName = new Set(_voucherGroups.map(v => v.categoryName))
        const uniqueGroupId = new Set(_voucherGroups.map(v => v.id))
        if ((uniqueCategoryName.size < _voucherGroups.length)) {
            setTransactionMessage({msg: "Duplicate Category Name found ", type:'danger'})
            return
        }else if(uniqueGroupId.size < _voucherGroups.length){
            setTransactionMessage({msg: "Duplicate Category groupid found ", type:'danger'})
            return
        }
        
        setTransactionMessage({msg: "Processing...", type:'info'})
        setProcessing(true)

        try {
            if (props.mode === 'create') {
                const createdEvent = await handleCreate(_event, _voucherGroups)
               
            } else if (props.mode === 'update') {
                await handleUpdate(_event, _voucherGroups)
            }
            close('forceReload')
        } catch (error) {
            console.error('error on handleSubmit ', error)
            setTransactionMessage({msg: 'Please report this error to Administrator.', type: 'danger'})
            setProcessing(false)
        }
    }

    async function handleCreate(_event, _voucherGroups) {
        // eslint-disable-next-line no-useless-catch
        try {
            let imagePath, emailImagePath
            
            const _outlet = await checkEventOutlet(_event)

            _event.merchantUniqueId = _outlet.merchant_unique_id

            if (croppedImages.bannerImage) {
                imagePath = `Events/banner/${_event.merchantUniqueId}/${event.pk}_banner${croppedImages.bannerImage.ext}`
                emailImagePath = `Events/email/${event.pk}/header${croppedImages.bannerImage.ext}`
                _event.eventBannerUrl = await getAssetUrl(imagePath)
            }
            const createdEvent = await createNewEvents({events: _event, voucherGroups: _voucherGroups})
            if (imagePath) await handleImageUpload(imagePath, croppedImages.bannerImage)
            if (emailImagePath) await handleImageUpload(emailImagePath, croppedImages.bannerImage)
            return createdEvent
        } catch (e) {
            dispatch(setAmplitudeEvent('CMS Events page - event form handleCreate function error', {'details': JSON.stringify(e) }))
            throw e
        }
    }

    async function handleUpdate(_event, _voucherGroups) {
        try {

            let imagePath, emailImagePath ,imageAssetPath, emailImageAssetPath
 
            if (croppedImages.bannerImage) {
                imagePath = `Events/banner/${_event.merchantUniqueId}/${event.pk}_banner${croppedImages.bannerImage.ext}`
                emailImagePath = `Events/email/${event.pk}/header${croppedImages.bannerImage.ext}`
                imageAssetPath = await getAssetUrl(imagePath)
                emailImageAssetPath = await getAssetUrl(emailImagePath)
                _event.eventBannerUrl = imageAssetPath
            }
            const updatedEvent = await updateEvents({events: _event, voucherGroups: _voucherGroups})
            if (imagePath) {
                await handleImageUpload(imagePath, croppedImages.bannerImage)
                await invalidateCloudFrontCache(imageAssetPath)
            }
            if (emailImagePath){
                await handleImageUpload(emailImagePath, croppedImages.bannerImage)
                await invalidateCloudFrontCache(emailImageAssetPath)
            } 
            
            return updatedEvent
        } catch (error) {
            console.error('error handleUpdate ', error)
            dispatch(setAmplitudeEvent('CMS Events page - event form handleUpdate function error', {'details': JSON.stringify(error) }))
            throw error
        }
    }

    async function checkEventOutlet(_event) {
        let _outlet
        
        if (props.mode === 'create') {
            _outlet = await getOutlet(selectedMerchant.merchant_id, 'event')
        }

        if (!_outlet) {
            const newEventOutlet = {
                store_id : 'event',
                merchant_id: selectedMerchant.merchant_id,
                merchant_unique_id: `${selectedMerchant.merchant_id}_event`,
                merchantImageUrlV2: selectedMerchant.merchantImageUrlV2,
                merchant_name: selectedMerchant.merchant_name,
                defaultImageUrl: selectedMerchant.defaultImageUrl,
                mainCat: selectedMerchant.mainCat,
                outlet_name: `${selectedMerchant.merchant_name}`,
                purchasableOutlet: "1",
                valid: '1'
            }
            _outlet = await createOutlet(newEventOutlet)
        }

        return _outlet
    }

    function close(action) {
        // do clean up since this is a modal and it might show old message when open again
        setProcessing(false)
        setTransactionMessage(null)
        
        if (action==='forceReload')
            props.close(event, false, 'eventForm')
        else 
            props.close(null, false)
    }

    async function getCroppedImage(cropped, fieldName) {
        let _croppedImages = croppedImages ?? {}
        _croppedImages[fieldName] = cropped

        setCroppedImages({ ..._croppedImages })
    }



    function updateCategoryDetail(index, attribute, value) {
        let categories = categoryDetails

        if(!categories[index]) return 
 
        if(attribute === CONSTANTS.QUANTITY || attribute === CONSTANTS.LIMIT){
            if(value === CONSTANTS.INCREMENT){
                categories[index][attribute] = categories[index][attribute] + 1
            }else if(value === CONSTANTS.DECREMENT){
                if(categories[index][attribute] > 0) categories[index][attribute] = categories[index][attribute] - 1
            }else if(!isNaN(value) &&  (parseInt(value) > 1 || parseInt(value) < 1000)){
                categories[index][attribute] = parseInt(value)
            }
        }else{
           categories[index][attribute] = value
        }

        setCategoryDetails([...categories])
        

    }

    function setCategoryGenerated(value) {
        
        if (value === "") return

        let categories = categoryDetails
            if(value === CONSTANTS.INCREMENT){
                if(categories.length < 1000){
                    categories.push({...newCat, id:`GID-${categories.length+1}`})
                }
            }else if(value === CONSTANTS.DECREMENT){
                if(categories.length > 1 ) categories.pop()
            }else if(!isNaN(value) &&  (parseInt(value) > 1 || parseInt(value) < 1000)){
                if(categories.length < value)
                    for(let x=categories.length;x < value; x++){
                        categories.push({...newCat, id:`GID-${x+1}`})
                    }
                else
                for(let x=categories.length;x > value; x){
                    categories.pop()
                }
            }
        setCategoryDetails([...categories])
    }


    return (
        <Modal
            size="xl"
            isOpen={props.modalEventFormStatus}
            toggle={() => {
                close(null,false)
            }}
            centered={true}
        >   
            <AvForm className="needs-validation" onValidSubmit={(e, v) => handleSubmit(e, v)}>
                <ModalHeader toggle={() => close(null, false)}>
                    {props.mode === 'create' ? 'Create event' : `Edit event`}
                </ModalHeader>

                <ModalBody>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Label className="groupTitle">Event Details</Label>

                            <Row className='mt-3'>
                                <Label htmlFor="eventTitle" className="subTitle">Event Title*</Label>
                                <Col xs={12}>
                                    <AvField
                                        name="name"
                                        placeholder=""
                                        type="text"
                                        errorMessage="This field is required"
                                        className="form-control"
                                        validate={{ required: { value: true} }}
                                        id="name"
                                        value={event.name}
                                        onChange={(e) => handleChange(e, 'name')}
                                    />
                                </Col>
                            </Row>

                            <Row className='mt-3'>
                                <Label htmlFor="uniqueString" className="subTitle">Event Site URL*</Label>
                                <Label className="subTitleLight">This link is generated based on the event title. Replace the text below if you wish to change the URL.</Label>
                                <Col xs={12}>
                                <AvGroup>
                                    <InputGroup>
                                    <InputGroupText className="mb-3 url-label">{`https://biz.${cmsConfig.env}.cardspal.com/event/`}</InputGroupText>
                                        <AvInput
                                            name="uniqueString" 
                                            placeholder="{your-event-title}" 
                                            value={props.mode==='update' ? event.uniqueString  : event.name ? event.name.replaceAll(" ", "-").replaceAll(/[^a-zA-Z0-9-]/gi, '') : ""} 
                                            required
                                            id="uniqueString"
                                            className={"mb-3"}
                                            style={{borderTopRightRadius : "0.25em", borderBottomRightRadius: "0.25em"}}
                                            onKeyDown={(e)=>{
                                                if (!ALPHANUMERIC_REGEX.test(e.key)) {
                                                    e.preventDefault();
                                                    return false;
                                                }
                                            }}
                                            onChange={(e) => handleChange(e, 'uniqueString')} />
                                            <AvFeedback style={{textAlign:"right", top:"30px"}}>This field is required</AvFeedback>
                                    </InputGroup>
                                </AvGroup>

                                </Col>


                            </Row>

                            <Label className="subTitle">Event Banner Image* <span className="subTitleLightPrimary">Landscape orientation recommended.</span></Label>
                            <p>
                                <span>Image will be scaled and cropped to display 1920 by 750 pixels.</span><br/>
                                <span>(You may upload larger images and use the cropping tool.)</span>
                            </p>
                            <ImageUploader
                                width={1440}
                                height={650}
                                required={true}
                                label="Event Banner Image"
                                onOk={(croppedImage) => getCroppedImage(croppedImage, 'bannerImage')}
                                dropType={true}
                                //disableEditing={disableEditing}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Label className="groupTitle mt-3">Banner Preview</Label>
                            <div>
                                {(croppedImages.bannerImage && croppedImages.bannerImage.url) || event.eventBannerUrl ?
                                    <Image fluid src={croppedImages.bannerImage ? croppedImages.bannerImage.url : `${event.eventBannerUrl}?timestamp=${Date.now()}`} />
                                    : null}
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                                    <Label className="subTitle">Event Start Date & Time*</Label>
                                    <InputGroup>
                                        <Flatpickr
                                            className={"form-control d-block whiteBg"}
                                            placeholder="Select a date"
                                            value={event.startTimestamp ? fromUnixTime(event.startTimestamp) : ""}
                                            options={{
                                                disableMobile: true,
                                                minDate: props.mode==='create'?"today":'' ,
                                                enableTime: true,
                                                enableSeconds: true,
                                                time_24hr: true,
                                                defaultHour: 0,
                                                defaultMinute: 0,
                                                defaultSeconds: 0,
                                                dateFormat: "Y-m-d H:i:S",
                                                onChange: function (selectedDates, dateStr, instance) {
                                                    try {
                                                        const dt = selectedDates[0]
                                                        event['startTimestamp'] = getUnixTime(new Date(dt)).toString()
                                                        setEvent({...event})
                                                        
                                                        const tomorrow = dateFnsFormat(addDays(new Date(dt), 1), "yyyy-MM-dd 23:59:59");
                                                        // console.log('tomorrow ', tomorrow)
                                                        setRecommendedEndDateMin(tomorrow)
                                                        
                                                    } catch (e) {
                                                        console.error(e)
                                                    }
                                                }
                                            }}
                                            
                                        />
                                        <span className="input-group-text mdi mdi-calendar"></span>
                                    </InputGroup>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} >
                                    <Label className="subTitle">Event End Date & Time*</Label> <a href="#" onClick={ () => {event.endTimestamp=getUnixTime(endOfDay(fromUnixTime(event.endTimestamp))); setEvent(()=> {return event})} }>Default to 23:59</a>
                                    <InputGroup>
                                        <Flatpickr
                                            className={"form-control d-block whiteBg"}
                                            placeholder="Select a date"
                                            value={event.endTimestamp ? fromUnixTime(event.endTimestamp) : ""}
                                            options={{
                                                minDate: props.mode==='create'?recommendedEndDateMin:"" ,
                                                enableTime: true,
                                                enableSeconds: true,
                                                time_24hr: true,
                                                defaultHour: 23,
                                                defaultMinute: 59,
                                                defaultSeconds: 59,
                                                dateFormat: "Y-m-d H:i:S",
                                                onChange: function (selectedDates, dateStr, instance) {
                                                    try {
                                                        const dt = selectedDates[0]
                                                        event['endTimestamp'] = getUnixTime(new Date(dt)).toString()
                                                        setEvent({ ...event })

                                                        const eventEndDate = dateFnsFormat(addDays(new Date(dt), 1), "yyyy-MM-dd");
                                                        setRecommendedEventEndDateMin(eventEndDate)

                                                    } catch(e) {
                                                        console.error(e)
                                                        dispatch(setAmplitudeEvent('CMS Events page - Event End Date & Time error', {'details': JSON.stringify(e) }))
                                                    }
                                                }
                                            }}

                                        />
                                        <span className="input-group-text mdi mdi-calendar"></span>
                                    </InputGroup>
                                    <span className="subLabelWarning">{eventEndDateError}</span>
                                </Col>

                                <Row className='mt-3'>
                                    <Label htmlFor="eventLocationTitle" className="subTitle">Event Location*</Label>    
                                    <Col xs={12} >
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="isOnline"
                                                onChange={(e) => handleChange(e, 'isOnline')}
                                                checked={event.isOnline === 'False' ? false : true}
                                            />
                                            <label className="form-check-label" htmlFor="isOnline">
                                                <p>Online</p>
                                            </label>
                                        </div>
                                    </Col>

                                    <Col xs={12}>
                                        <p className="subTitleLight m-0">Landmark name</p>
                                        <AvField
                                            name="location"
                                            placeholder=""
                                            type="text"
                                            errorMessage="This field is required"
                                            className="form-control"
                                            validate={{ required: { value: event.isOnline === 'True' ? false : true } }}
                                            disabled={ event.isOnline === 'True' ? true : false }
                                            id="location"
                                            value={event.location}
                                            onChange={(e) => handleChange(e, 'location')}
                                        />

                                        <p className="subTitleLight m-0">Address</p>
                                        <AvField
                                            name="address"
                                            placeholder=""
                                            type="text"
                                            errorMessage="This field is required"
                                            className="form-control"
                                            validate={{ required: { value: event.isOnline === 'True' ? false : true } }}
                                            disabled={ event.isOnline === 'True' ? true : false }
                                            id="address"
                                            value={event.locationDetails?.name}
                                            onChange={(e) => handleChange(e, 'address')}
                                        />
                                        <Row>
                                            <Col xs={11} lg={8} style={{width: '320px'}}><p className="subTitleLight m-0 p-0">Insert longtitude and latitude for map redirection </p></Col>
                                            <Col><OverlayTrigger placement="right" overlay={tooltip()}>
                                                    <i style={{fontSize:"1em"}} className="highlight mdi mdi-information"></i>
                                                </OverlayTrigger>
                                            </Col>
                                        </Row>

                                        <AvField
                                            name="latlong"
                                            placeholder=""
                                            type="text"
                                            errorMessage={"This field is required"}
                                            className="form-control"
                                            validate={{ required: { value: event.isOnline === 'True' ? false : true },
                                                        pattern: {value: LATLONG_REGEX, errorMessage: 'Not a proper lat long format'}}}
                                            disabled={ event.isOnline === 'True' ? true : false }
                                            id="lat&long"
                                            value={ event.isOnline === 'True' ? "" : `${event.latitude ? event.latitude+", " : ""}${event.longitude}`}
                                            onChange={(e) => handleChange(e, 'latlong')}
                                        />
                                    </Col>
                                </Row>
                                
                                <Row className='mt-3'>
                                    <Label htmlFor="promotionTitle" className="subTitle">Event Information*</Label>
                                    <Col xs={12}>
                                    <Editor
                                        tinymceScriptSrc="https://cdn.tiny.cloud/1/no-api-key/tinymce/5/tinymce.min.js" 
                                        onInit={(evt, editor) => editorRef.current = editor}
                                        initialValue= {props.event.description || "<p>Type here for event information.</p>"}
                                        init={{
                                            height: 500,
                                            menubar: false,
                                            branding: false,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],
                                            toolbar: 'undo redo | formatselect | ' +
                                            'bold italic backcolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                            statusbar: false,
                                            paste_data_images: false,
                                            paste_block_drop: true,
                                            autohide:true,
                                            toolbar_mode: 'sliding',
                                            smart_paste: false,
                                            paste_as_text: true,
                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                        }}
                                        onEditorChange = {(e)=> event.description = e}
                                        required
                                    />
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Label htmlFor="numerOfCategories" className="subTitle">Number of Ticketing Categories<br/><p className="subTitleLight m-0 p-0">Maximum of 20 categories allowed</p></Label>
                                    <Col>
                                    <Row>
                                        <Col xs={6} sm={6} md={6} lg={6} xl={6} className='mb-1'>
                                            <InputGroup style={{ maxWidth:'196px' }} >
                                                <Button disabled={disableEditing && (updateMinCat >= categoryDetails.length)} type="button" color="primary" onClick={() => { setCategoryGenerated(CONSTANTS.DECREMENT)}}>
                                                <i className="mdi mdi-minus" />
                                                </Button>
                                                <input
                                                    type="text"
                                                    className="form-control white-bg"
                                                    value={categoryDetails.length}
                                                    placeholder="1"
                                                    readOnly
                                                    onChange={(e) => {
                                                        const v = e.target.value
                                                        if (isNaN(v) || v.startsWith('0')) {
                                                            return
                                                        } else if (parseInt(v) < 1 || parseInt(v) > 20) {
                                                            return
                                                        } else {
                                                            if(v > updateMinCat)
                                                                setCategoryGenerated(v)
                                                        }
                                                    }}
                                                />
                                                <Button type="button" onClick={() => { setCategoryGenerated(CONSTANTS.INCREMENT)}} color="primary" >
                                                <i className="mdi mdi-plus" />
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                
                                </Col>
                                </Row>

                                
                                    
                            </Row>
                        </Col>
                    </Row>
                    {(categoryDetails.length > 0) &&  categoryDetails.map((el, i) => {
                        return  <Row key={"category"+i} className="mt-3" >
                                <Col xs={12} sm={12} md={12} lg={5} xl={5}>
                                    <Row>
                                        <Col xs={12} sm={12} md={7} lg={7} xl={7}>
                                        <p className="subTitle m-0">Category {i+1}*</p>
                                            <AvField
                                                name={`category${i}`}
                                                placeholder=""
                                                type="text"
                                                errorMessage={"This field is required"}
                                                className="form-control"
                                                validate={{ required: { value: true }}}
                                                id={`category${i}`}
                                                value={el.categoryName ?? ""}
                                                onChange={(e) => {
                                                    const v = e.target.value
                                                    updateCategoryDetail(i,'categoryName', v)
                                                }}
                                            />                        
                                        </Col>

                                        <Col xs={6} sm={6} md={5} lg={5} xl={5} className='mb-1'>
                                            <p className="subTitle m-0">QTY</p>
                                                <InputGroup style={{ maxWidth:'196px' }} >
                                                    <Button type="button" color="primary" onClick={() => { updateCategoryDetail(i,CONSTANTS.QUANTITY, CONSTANTS.DECREMENT)}}>
                                                    <i className="mdi mdi-minus" />
                                                    </Button>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={el.totalQuantity ?? 1}
                                                        placeholder="1"
                                                        onChange={(e) => {
                                                            const v = e.target.value
                                                            if (isNaN(v) || v.startsWith('0')) {
                                                                return
                                                            } else if (parseInt(v) < 1 || parseInt(v) > 10001) {
                                                                return
                                                            } else {
                                                                updateCategoryDetail(i,CONSTANTS.QUANTITY, v)
                                                            }
                                                        }}
                                                    />
                                                    <Button type="button" onClick={() => { updateCategoryDetail(i,CONSTANTS.QUANTITY, CONSTANTS.INCREMENT)}} color="primary" >
                                                    <i className="mdi mdi-plus" />
                                                    </Button>
                                                </InputGroup>
                                            </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                    <Row>
                                        <Col xs={12} sm={12} md={7} lg={7} xl={7}>
                                            <p className="subTitle m-0">Grouping ID {i+1}</p>
                                                <AvInput
                                                    disabled={true}
                                                    name={`groupingID${i}`}
                                                    placeholder=""
                                                    type="text"
                                                    errorMessage="This field is required"
                                                    className="form-control"
                                                    validate={{ required: { value: true }}}
                                                    id={`groupingID${i}`}
                                                    value={(i < updateMinCat) ? el.id.substring(el.id.indexOf('_')+1) : el.id}
                                                    onKeyDown={(e)=>{
                                                        if (!ALPHANUMERIC_REGEX.test(e.key)) {
                                                            e.preventDefault();
                                                            return false;
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        const v = e.target.value
                                                            updateCategoryDetail(i,'id', v)
                                                    }}
                                                />                        
                                        </Col>
                                        {/* <Col xs={12} sm={12} md={5} lg={5} xl={5} className='mb-1'>
                                            <p className="subTitle m-0">Purchase limit per customer (if any)</p>
                                                <InputGroup style={{ maxWidth:'196px' }} >
                                                    <Button type="button" color="primary" onClick={() => { updateCategoryDetail(i,CONSTANTS.LIMIT, CONSTANTS.DECREMENT)}}>
                                                    <i className="mdi mdi-minus" />
                                                    </Button>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={el.purchaseLimitPerUser ?? 1}
                                                        placeholder="1"
                                                        onChange={(e) => {
                                                            const v = e.target.value
                                                            if (isNaN(v) || v.startsWith('0')) {
                                                                return
                                                            } else if (parseInt(v) < 1 || parseInt(v) > 1000) {
                                                                return
                                                            } else {
                                                                updateCategoryDetail(i,CONSTANTS.LIMIT, v)
                                                            }
                                                        }}
                                                    />
                                                    <Button type="button" onClick={() => { updateCategoryDetail(i,CONSTANTS.LIMIT, CONSTANTS.INCREMENT)}} color="primary" >
                                                    <i className="mdi mdi-plus" />
                                                    </Button>
                                                </InputGroup>
                                        </Col> */}
                                    </Row>
                                </Col>
                                </Row>
                        }
                    )}
                    <Row className="rowMarginTop">
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Label htmlFor="promotionTitle" className="subTitle">Website for redirection</Label>
                        <p className="subTitleLight m-0">Upon successful transaction, this link will open in new tab for users viewing.</p>
                            <AvField
                                name="redirectionWebsite"
                                placeholder=""
                                type="text"
                                className="form-control"
                                validate={{ pattern: { value: URL_REGEX, errorMessage: 'Not a proper URL'}}}
                                id="redirectionWebsite"
                                value={event.redirectionWebsite}
                                onChange={(e) => handleChange(e, 'redirectionWebsite')}
                            />                        
                        </Col>
                       
                    </Row>

                    <Row className="rowMarginTop">
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="valid"
                                    onChange={(e) => handleChange(e, 'valid')}
                                    checked={event.valid === '' ? false : true}
                                />
                                <label className="form-check-label" htmlFor="valid">
                                    <p>Visible on app</p>
                                </label>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>

                <ModalFooter>
                    {transactionMessage ?
                        <Alert color={transactionMessage.type}>
                            {transactionMessage.msg}
                        </Alert>
                        : null}

                    {processing ?
                        <Spinner />
                        : null}

                    <Button
                        disabled={processing}
                        color="primary"
                        type="submit"
                    >
                        {props.mode==='create'? 'Add event' :'Update event'}
                    </Button>
                    {' '}
                    <Button disabled={processing} onClick={() => close(null,false)}>
                        Cancel
                    </Button>
                </ModalFooter>
            </AvForm>
        </Modal>
    )
}

export default EventForm;